import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import PropTypes from "prop-types";

import DataTable, { rowActionTypes } from "../../components/DataTable";
import InputSearch from "../../components/base/InputSearch";
import { fetchUsersAction } from "../../redux/reducers/userReducer";
import { userTrustedAction } from "../../redux/reducers/userTrustedReducer";
import { startActionWithPromise } from "../../helpers/saga-promise-helpers";

const propTypes = {
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  refreshData: PropTypes.func,
};

const defaultProps = {
  modal: false,
  toggle: () => {},
  refreshData: () => {},
};

const UserAdd = props => {
  const dispatch = useDispatch();

  const { modal, toggle, refreshData } = props;

  const users = useSelector(state => state.user?.users);
  const fetching = false; // useSelector(state => state.user?.fetching);

  const activeUsers = users.filter(user => user.active);

  const [state, setState] = useState({
    modalUpdate: false,
    modalDelete: false,
    row: null,
  });

  const [selectedRows, setSelectedRows] = useState([]);

  const [filters, setFilters] = useState({
    pageSize: 100,
    pageNumber: 1,
    sort: "firstName",
    sortReal: "",
    order: "asc",
    keywords: "",
    reload: false,
  });

  const onColumnSort = (field, order) => {
    const realSort = field === "fullname" ? "firstName" : field;
    if (realSort === filters.sortReal && order === filters.order) return;

    setFilters({
      ...filters,
      sort: field,
      sortReal: realSort,
      order,
      reload: true,
    });
  };

  const tableColumns = [
    {
      dataField: "fullname",
      text: "Fullname",
      sort: true,
      onSort: (field, order) => {
        onColumnSort(field, order);
      },
      sortFunc: () => {},
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      onSort: (field, order) => {
        onColumnSort(field, order);
      },
      sortFunc: () => {},
    },
  ];

  const rowActions = [];

  const onSelectedChange = selected => {
    setSelectedRows(selected);
  };

  const onSearchSubmit = keywords => {
    // if (filters.keyword === keyword) return;
    setFilters({ ...filters, pageNumber: 1, keywords, reload: true });
  };

  const onActionButtonClick = (action, row) => {
    switch (action.type) {
      case rowActionTypes.EDIT:
        setState({ ...state, modalUpdate: true, row });
        break;
      default:
    }
  };

  const addSuccessCallback = () => {
    if (refreshData) refreshData();
  };

  const onSubmit = async () => {
    if (!selectedRows || selectedRows.length === 0) return;

    const { userId } = selectedRows[0];
    if (!userId) return;

    try {
      await startActionWithPromise(
        userTrustedAction.create,
        {
          data: { enabled: true, userId },
          successCallback: addSuccessCallback,
          failedCallback: () => {},
        },
        dispatch
      );
    } catch {}
  };

  const fetchData = () => {
    (async () => {
      try {
        const params = {
          pageSize: filters.pageSize,
          pageNumber: filters.pageNumber - 1,
          keywords: filters.keywords,
          sort: {
            dir: `${filters.order.toUpperCase()}`,
            fieldName: `${filters.sortReal||filters.sort}`
          }
        };
        if (!filters.keywords) delete params.sort;

        await startActionWithPromise(
          fetchUsersAction,
          {
            params,
            successCallback: () => {},
            failedCallback: () => {},
          },
          dispatch
        );
      } catch {}
    })();
  }

  useEffect(() => {
    if (filters.reload) {
      fetchData();
      setFilters({ ...filters, reload: false });
    }
  }, [filters.reload]);

  useEffect(() => {
    fetchData();

    return () => {
      setState({});
    };
  }, []);

  return (
    <Modal
      isOpen={modal}
      toggle={() => toggle("modalAdd")}
      centered
      size="lg"
      className="add-trusted-modal"
    >
      <ModalHeader>Add publisher to whitelist</ModalHeader>
      <ModalBody className="pt-0 pb-0">
        <Card className="w-100 mb-0">
          <CardHeader className="">
            <Row>
              <Col lg="6" xs="6" />
              <Col lg="6" xs="6" className="d-flex justify-content-end">
                <InputSearch
                  value={filters.keywords}
                  onSubmit={onSearchSubmit}
                  placeHolder="Search for..."
                  disabled={fetching}
                />
              </Col>
            </Row>
          </CardHeader>
          <CardBody className="pt-0 pb-0 data-list position-relative">
            {fetching && (
              <div className="text-center w-100 bg-white p-4 position-static h-100">
                <Spinner />
              </div>
            )}
            {!fetching && (
              <div className="">
                <DataTable
                  keyField="userId"
                  single
                  data={activeUsers}
                  columns={tableColumns}
                  sort={filters.sort}
                  order={filters.order}
                  actions={rowActions}
                  onActionClick={onActionButtonClick}
                  onSelectedChange={onSelectedChange}
                />
              </div>
            )}
          </CardBody>
        </Card>
      </ModalBody>
      <ModalFooter>
        <Button
          color="secondary"
          disabled={fetching}
          onClick={() => toggle("modalAdd")}
        >
          Close
        </Button>
        <Button
          className="ml-2"
          color="primary"
          disabled={fetching}
          onClick={onSubmit}
        >
          Add to whitelist
        </Button>
      </ModalFooter>
    </Modal>
  );
};

UserAdd.propTypes = propTypes;
UserAdd.defaultProps = defaultProps;

export default UserAdd;
