import React from "react";
import { useDispatch } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import PropTypes from "prop-types";

import { roleGroupActions } from "../../../redux/reducers/domainRoleGroupReducer";
import { startActionWithPromise } from "../../../helpers/saga-promise-helpers";
import { useWaitingStatus } from "../../../hooks/useWaiter";

const propTypes = {
  row: PropTypes.object.isRequired,
  modal: PropTypes.bool,
  toggle: PropTypes.func,
};

const defaultProps = {
  row: {},
  modal: false,
  toggle: () => {},
};

const RoleDelete = props => {
  const dispatch = useDispatch();

  const isDeleting = useWaitingStatus(roleGroupActions.delete.id);

  const toggle = () => {
    if (props.toggle) props.toggle("modalDelete");
  };

  const successCallback = () => {
    if (props.refreshData) props.refreshData();
    toggle();
  };

  const failedCallback = () => {
  };

  const handleDelete = async () => {
    try {
      await startActionWithPromise(
        roleGroupActions.delete,
        { id: props.row?.id, successCallback, failedCallback },
        dispatch
      );
    } catch {}
  };

  return (
    <Modal
      isOpen={props.modal}
      toggle={toggle}
      centered
      size="sm"
      className="comfirmation-modal"
    >
      <ModalHeader toggle={toggle}>Confirmation</ModalHeader>
      <ModalBody className="m-3">
        <div>
          <p className="font-weight-bold">Following group will be deleted:</p>
          <p className="font-italic">"{props.row?.name}"</p>
          <p className="font-weight-bold">Do you want to continue?</p>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" disabled={isDeleting} onClick={toggle}>
          No
        </Button>{" "}
        <Button
          color="primary"
          disabled={isDeleting}
          onClick={() => handleDelete(true)}
        >
          Yes
        </Button>
      </ModalFooter>
    </Modal>
  );
};

RoleDelete.propTypes = propTypes;
RoleDelete.defaultProps = defaultProps;

export default React.memo(RoleDelete);
