import axios from "axios";
import authHeader from "../helpers/authHeader";
import { post, postFormdata, get, request } from "../helpers/apiHelper";
import * as ENDPOINTS from "../config/endpoints";

async function login(username, password, rememberMe = false) {
  return await post(ENDPOINTS.AUTH_PATH, {
    username,
    password,
    rememberMe,
  });
}

function logout() {
  localStorage.removeItem("user");
}

async function forceLogout(userId) {
  return await request(`users/${userId}/authentication`, null, "delete");
}

async function changePassword(userId, data) {
  const result = await request(`users/${userId}/password`, data, "put");
  return result;
}

async function resetPassword(userId) {
  return await request(`users/${userId}/password`, null, "delete");
}

async function deleteUserRoles(userId) {
  return await request(`users/${userId}/roles`, null, "delete");
}

async function fetchUsers(params) {
  if (
    params &&
    (params.keywords ||
      params.userId ||
      params.deleted ||
      params.hasOwnProperty("enabled") ||
      params.sort)
  ) {
    return await post("users/search", params);
  }
  return await get("users", params);
}

async function fetchUsersWithStorage(params) {
  return await get("users/usersWithStorage", params);
}

async function fetchMailboxes(params) {
  return await post("users/mailboxes", params);
}

async function exportMailboxes(params) {
  return await post("users/csv", params, true);
}

async function sendEmail(params) {

  const { senderName, sender, recipients, subject, content, csvFile } = params;

  const stringifiedMessage = JSON.stringify({ senderName, sender, recipients, subject, content });
  const formData = new FormData();
  formData.append('stringifiedEmail', stringifiedMessage);
  if (csvFile)
    formData.append('csvFile', csvFile);
  return await postFormdata("users/emails", formData);
}

async function getProfile(email) {
  try {
    const authen = authHeader();
    const options = {
      method: "POST",
      url: `${ENDPOINTS.backoffice}users/profile`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        ...authen,
      },
      data: { email },
    };

    const res = await axios(options);
    const profile = await res.data;
    return { profile };
  } catch (error) {
    return { error: error.response };
  }
}

async function getRolesByEmail(email) {
  const options = {
    method: "POST",
    url: `${ENDPOINTS.backoffice}roles/users/search`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
    data: { email },
  };

  const res = await axios(options);
  if (res && res.status === 200) {
    const roles = await res.data;
    return { roles };
  }

  return {};
}

async function enable(userId) {
  return await request(`users/${userId}/enable`, null, "patch");
}

async function disable(userId) {
  return await request(`users/${userId}/disable`, null, "patch");
}

async function confirmation(email) {
  return await post(`users/confirmation`, { email });
}

async function importUsers(users) {
  return await post(`users/import`, users);
}

async function deleteUser(userId, reason) {
  return await request(`users/${userId}`, reason, "delete");
}

async function syncUserStoragePackage(userId) {
  return await request(`subscriptions/userStorages/${userId}`, null, "put");
}

async function fetchContactMessages(params) {
  return await post("contactUs/search", params);
}

async function updateMessageNoteAndStatus(params) {
  const { messageId } = params;
  return await request(`contactUs/${messageId}`, params, "put");
}

export default {
  login,
  logout,
  forceLogout,
  resetPassword,
  changePassword,
  deleteUserRoles,
  fetchUsers,
  fetchUsersWithStorage,
  fetchMailboxes,
  exportMailboxes,
  sendEmail,
  getProfile,
  getRolesByEmail,
  enable,
  disable,
  confirmation,
  importUsers,
  deleteUser,
  syncUserStoragePackage,
  fetchContactMessages,
  updateMessageNoteAndStatus
};
