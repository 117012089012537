import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Spinner,
  Button
} from "reactstrap";
import classNames from "classnames";
import _ from "lodash";
import * as Icon from "react-feather";

import DataTable from "../../components/DataTable";
import useMergeState from '../../hooks/mergeState';
import { useAuth } from "../../hooks/useAuth";

import ConfirmModal from "../../components/base/ConfirmModal";
import Selectbox from '../../components/Selectbox/Selectbox';

import { getProductsAction, getPlanOptionTypesAction } from "../../redux/reducers/subscriptionReducer";
import { startActionWithPromise } from "../../helpers/saga-promise-helpers";
import "./index.scss";
import { getTableColumns } from "./TableColumns";
import ProductPlanModal from "./ProductPlanModal";
import PlanOptionModal from "./PlanOptionModal";

const ProductPlans = ({user}) => {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    modalConfirm: false,
    modalProductPlan: false,
    modalOption: false,
    action: '',
    message: '',
    row: null,
  });

  const [filters, setFilters] = useMergeState();

  const [waiting, setWaiting] = useState(false);
  
  const auth = useAuth();
  const isAuthorized = auth.roles &&
    (auth.roles.indexOf('SYSTEM_ADMINS') !== -1 ||
    auth.roles.indexOf('SYSTEM_OPERATORS') !== -1);
  const fetching = false;
  const [serviceProducts, setServiceProducts] = useState([]);
  const [selectedServiceProduct, setSelectedServiceProduct] = useState({});
  const [selectedProductPlans, setSelectedProductPlans] = useState([]);
  const isBoAdmin = useSelector(state => state.bouser.isBoAdmin);

  const handleUpdateProductPlan = (row, event) => {
    (async () => {
      try {
        const resp = await startActionWithPromise(
          getPlanOptionTypesAction,
          {successCallback: () => {}, failedCallback: () => {}},
          dispatch
        );

        const options = [];
        if (resp) {
          resp.map(it => options.push ({
            id: null,
            optionType: it.name,
            listingOrderNumber: it.listingOrderNumber,
            value: it.defaultValue,
            valueUnit: it.defaultValueUnit,
            description: '',
            active: it.active,
          }));
        }
        
        const productPlan = _.clone(row);
        productPlan.options = _.unionBy(productPlan.options, options, "optionType"); // merge the base option types to the product plan's options

        setState({
          ...state,
          modalProductPlan: true,
          row: productPlan
        });
      } catch {}
    })();

  }

  const handleOption = (row, event) => {
    setState({
      ...state,
      modalOption: true,
      row
    });
  }

  const tableColumns = getTableColumns(isBoAdmin, handleUpdateProductPlan, handleOption);

  const toggleModal = (modal) => {
    if (state[modal]) setState({ ...state, [modal]: !state[modal] });
    else setState({ ...state, [modal]: !state[modal], row: null });
  };

  const refreshDatatable = () => {
    setFilters({ ...filters, reload: true });
  };

  const handleServiceProductChange = (selectedOption, actionMeta) => {
    setSelectedServiceProduct(selectedOption);
    setSelectedProductPlans(selectedOption.plans);
  };

  const fetchProducts = () => {
    (async () => {
      try {
        // setWaiting(true);
        setServiceProducts([]); // clear it

        const resp = await startActionWithPromise(
          getProductsAction,
          {successCallback: () => {}, failedCallback: () => {}},
          dispatch
        );

        setServiceProducts(resp);
        if ( resp.length > 0 ) {
          setSelectedServiceProduct(resp[0]);
          setSelectedProductPlans(resp[0].plans);
        } else {
          setSelectedServiceProduct({});
          setSelectedProductPlans([]);
        }
      } catch {}
      setWaiting(false);
      // setFilters({ ...filters, reload: false });
    })();
  };

  useEffect(() => {
    if (filters.reload) {
      fetchProducts();
      setFilters({ ...filters, reload: false });
    }
  }, [filters.reload]);

  useEffect(() => {
    fetchProducts();
  }, []);

  ////////// Table's action handlers ///////////

  const createProductPlan = () => {

    (async () => {
      try {
        const resp = await startActionWithPromise(
          getPlanOptionTypesAction,
          {successCallback: () => {}, failedCallback: () => {}},
          dispatch
        );

        const options = [];
        if (resp) {
          resp.map(it => options.push ({
            id: null,
            optionType: it.name,
            listingOrderNumber: it.listingOrderNumber,
            value: it.defaultValue,
            valueUnit: it.defaultValueUnit,
            description: '',
            active: it.active,
          }));
        }
        
        const newProductPlan = {
          id: null,
          parentProductId: selectedServiceProduct.productId,
          options:  options
        };
        
        setState({
          ...state,
          modalProductPlan: true,
          row: newProductPlan
        });
      } catch {}
    })();

  };

  const onActionConfirm = () => {
    
  };

  ////////////////////

  return (
    <Container fluid className="p-0">
      <h1 className="page-title">Product Plans</h1>
      <Row>
        <Col lg="12" className="d-flex">
        <>
          <Card className={classNames('w-100 mb-0 datatable-wrap')}>
            <CardHeader className="">
            <div className="d-flex align-items-left float-left ml-3">
                <label htmlFor="serviceProduct" className="mb-0 text-nowrap text-right" style={{width: '140px'}}>Products: </label>
                <div className="d-inline-block ml-2 item-filter__control" style={{width: '250px'}}>
                  <Selectbox
                    name="serviceProduct"
                    options={serviceProducts}
                    isClearable={false}
                    value={selectedServiceProduct}
                    onChange={handleServiceProductChange}
                    getOptionValue={(option) => option.productId}
                    getOptionLabel={(option) => option.productName}
                  />
                </div>
              </div>
              <div className="d-flex align-items-right float-right">
                <Button
                  className="ml-2"
                  size=""
                  disabled={!selectedServiceProduct.productId || fetching}
                  onClick={(e) => createProductPlan(e)}
                  style={{minWidth: '86px'}}
                  color="success"
                >
                  <Icon.Plus style={{ width: '15px', height: '15px', marginTop: '-2px' }} /> Create
                </Button>
              </div>
            </CardHeader>
            <CardBody>
              { waiting ? (
                <div className="loading-content text-center p-4 border-top">
                  <Spinner size="sm" />
                </div>
              ) : (
                <>
                  <DataTable
                    keyField="id"
                    data={selectedProductPlans}
                    columns={tableColumns}
                    hideSelectColumn={true}
                    style={{width:"40%", height:"60%"}}
                  />
                </>
              )}
            </CardBody>
          </Card>
          <ConfirmModal
            modal={state.modalConfirm}
            toggle={toggleModal}
            row={state.row}
            onConfirm={onActionConfirm}
            on
            message={state.message}
          />
          {state.modalProductPlan && (
            <ProductPlanModal
              modal={state.modalProductPlan}
              toggle={toggleModal}
              refreshData={refreshDatatable}
              row={state.row}
              updateMode={state.row.id !== null}
            />
          )}
          {state.modalOption && (
            <PlanOptionModal
              modal={state.modalOption}
              toggle={toggleModal}
              row={state.row}
            />
          )}
        </>
        </Col>
      </Row>
    </Container>
  );
};

export default ProductPlans;
