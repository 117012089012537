import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Badge,
  Spinner,
  UncontrolledTooltip
} from "reactstrap";
import * as Icon from "react-feather";
import moment from "moment";
import classNames from "classnames";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";

import DataTable from "../../components/DataTable";
import PageSizeSelector from "../../components/base/PageSizeSelector";
import Paging from "../../components/base/Paging";
import config from "../../config/config";

import VideoRetranscode from "./VideoRetranscode";
import VideoWithdraw from "./VideoWithdraw";
import VideoDelete from "./VideoDelete";
import VideoFilter from "./VideoFilter";

import { searchVideoAction } from "../../redux/reducers/videoReducer";
import { startActionWithPromise } from "../../helpers/saga-promise-helpers";
import "./VideoList.scss";
import Avatar from "../../components/Avatar";
import {
  STATUSES,
  STATUSES_COPY,
  STATUSES_COLOR,
  SCOPES_COPY,
  CATEGORY_COPY,
} from "./constants";

const Actions = {
  RETRANSCODE: 'Re-transcode',
  WITHDRAW: 'Withdraw'
};

const VideoList = () => {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    modalRetranscode: false,
    modalWithdraw: false,
    modalConfirm: false,
    modalDelete: false,
    action: '',
    message: '',
    row: null,
  });

  const [filters, setFilters] = useState({
    pageSize: config.DEFAULT_PAGESIZE,
    pageNumber: 1,
    sort: 'createdDate',
    sortReal: '',
    order: 'desc',
    keywords: '',
    videoId: '',
    videoOwner: '',
    videoUser: '',
    excludeIRLVideo: true,
    reload: false,
  });

  const [isShowSearch, setIsShowSearch] = useState(true);
  const [waitingSearch, setWaitingSearch] = useState(true);
  const [waiting, setWaiting] = useState(false);
  const [nodata, setNodata] = useState(false);
  
  const isBoAdmin = useSelector(state => state.bouser.isBoAdmin);
  const videos = useSelector(state => state.video?.list) || [];
  const fetching = useSelector(state => state.video?.fetching);
  const totalItems = useSelector(state => state.video.total);
  const totalPages = useSelector(state => state.video.totalPages);
  const pageNumber = useSelector(state => state.video.pageNumber);

  const numItems = totalItems > filters.pageSize ? filters.pageSize : totalItems;
  const beginItem = numItems * (filters.pageNumber - 1);
  const endItem = numItems * filters.pageNumber;
  const showDatatable = (nodata || (!waitingSearch && videos.length === 0)) ? false : waitingSearch ? false : true;

  const dataList = videos.map(item => ({
    ...item,
    action_col: uuidv4(),
  }));

  const tableColumns = [
    {
      dataField: "title",
      text: "Video",
      sort: false,
      onSort: (field, order) => {
        onColumnSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div>
          <div><span className="font-weight-bold" title={row?.title}>{_.truncate(row?.title, {'length': 80,})}</span></div>
          <div><span className="font-weight-bold" title={row?.subject}>{_.truncate(row?.subject, {'length': 80,})}</span></div>
          <div><span className="font-weight-bold" title={row?.topic}>{_.truncate(row?.topic, {'length': 80,})}</span></div>
          <div><span title={row?.uid} style={{fontStyle: 'italic'}}>{row?.uid}</span></div>
        </div>
      ),
      headerStyle: () => {
        return { width: "25%" };
      },
    },
    {
      dataField: "version",
      text: "Meta",
      sort: false,
      formatter: (cell, row) => (
        <div>
          <div><span className="font-weight-bold">Version:</span> {row.version}</div>
          <div><span className="font-weight-bold">Visibility:</span> {SCOPES_COPY[row.visibilityScope]}</div>
          <div><span className="font-weight-bold">Categories:</span> {row.categories ? row.categories.map(x => CATEGORY_COPY[x]).join(', ') : ''}</div>
        </div>
      ),
      headerStyle: () => {
        return { width: "20%" };
      },
    },
    {
      dataField: "author",
      text: "Author",
      sort: false,
      onSort: (field, order) => {
        onColumnSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div title={row?.author}>
          <Avatar className="inline mr-2" image={row.avatar} fullname={row.author} width="30" height="30" />
          <span>{row?.author}</span>
        </div>
      ),
    },
    {
      dataField: "videoUser.userId",
      text: "Video user",
      sort: false,
      onSort: (field, order) => {
        onColumnSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div>
          {row.videoUser && (
            <>
              <Avatar className="inline mr-2" image={row.videoUser?.avatar?.image} fullname={row.videoUser?.fullName} width="30" height="30" />
              <span>{row.videoUser?.fullName}</span>
            </>
          )}
        </div>
      ),
    },
    {
      dataField: "status",
      text: "Status",
      sort: false,
      onSort: (field, order) => {
        onColumnSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div>
          <div className="">
            <Badge
              color={STATUSES_COLOR[row.status]}
              className="mr-1 mb-1"
            >
              {STATUSES_COPY[row.status]}
            </Badge>
          </div>
        </div>
      ),
      headerStyle: () => {
        return { width: "85px" };
      },
    },
    {
      dataField: "createdDate",
      text: "Created at",
      sort: false,
      onSort: (field, order) => {
        onColumnSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div>
          {row.createdDate ? moment(row.createdDate).format('HH:mm:ss') : ''}<br/>
          {row.createdDate ? moment(row.createdDate).format('YYYY/MM/DD') : ''}
        </div>
      ),
      headerStyle: () => {
        return { width: "140px" };
      },
    },
    
    {
      dataField: "action_col",
      text: "Actions",
      sort: false,
      formatter: (cell, row, rowIndex, formatExtraData) => (
        <div className="actions-col">
          {!row.derivedUserScaffolding && (
            <>
              <a href="#" className="mr-2" onClick={e => formatExtraData.onRetranscode(row, e)} id={'retranscode-' + row.action_col}>
                <Icon.Cpu className="text-dark" style={{ width: "15px" }} />
                <UncontrolledTooltip target={'retranscode-' + row.action_col}>
                  Re-transcode
                </UncontrolledTooltip>
              </a>
              {(row.status === STATUSES.ready || row.status === STATUSES.published) && (
                <a href="#" className="mr-2" onClick={e => formatExtraData.onWithdraw(row, e)} id={'unpublish-' + row.action_col}>
                  <Icon.VideoOff className="text-dark" style={{ width: "15px" }} />
                  <UncontrolledTooltip target={'unpublish-' + row.action_col}>
                    Un-publish
                  </UncontrolledTooltip>
                </a>
              )}
            </>
          )}
          {isBoAdmin && (
            <a href="#" className="mr-2" onClick={e => formatExtraData.onDelete(row, e)} id={'delete-' + row.action_col}>
              <Icon.Trash2 className="text-dark" style={{ width: "15px" }} />
              <UncontrolledTooltip target={'delete-' + row.action_col}>
                Delete
              </UncontrolledTooltip>
            </a>
          )}
        </div>
      ),
      formatExtraData: {
        onRetranscode: (row, e) => {
          setState({ ...state, modalRetranscode: true, row });
        },
        onWithdraw: (row, e) => {
          setState({ ...state, modalWithdraw: true, row });
        },
        onDelete: (row, e) => {
          setState({ ...state, modalDelete: true, row });
        },
      },
      headerStyle: () => {
        return { width: "130px" };
      },
    },
  ];

  const onSizeChange = (size) => {
    setFilters({ ...filters, pageSize: size, pageNumber: 1, reload: true });
  };

  const onPageChange = (p) => {
    setFilters({ ...filters, pageNumber: p, reload: true });
  };

  const onColumnSort = (field, order) => {
    let realSort = '';
    switch (field) {
      default:
        realSort = field;
    }
    if (realSort === filters.sortReal && order === filters.order) return;

    setFilters({
      ...filters,
      sort: field,
      sortReal: realSort,
      order,
      reload: true,
    });
  };

  const onActionButtonClick = (action, row) => {
    switch (action.type) {
      case Actions.RETRANSCODE:
        setState({ ...state, modalRetranscode: true, row });
        break;
      case Actions.WITHDRAW:
        setState({ ...state, modalWithdraw: true, row });
        break;
      default:
    }
  };

  const toggleModal = (modal) => {
    if (state[modal]) setState({ ...state, [modal]: !state[modal] });
    else setState({ ...state, [modal]: !state[modal], row: null });
  };

  const refreshDatatable = () => {
    setFilters({ ...filters, reload: true });
  };

  const fetchData = (params) => {
    (async () => {
      try {
        setWaiting(true);
        await startActionWithPromise(
          searchVideoAction,
          {params: params, successCallback: () => {}, failedCallback: () => {}},
          dispatch
        );
      } catch {}
      setWaiting(false);
      setWaitingSearch(false);
    })();
  };

  const handleSubmitFilter = (filtersValues) => {
    setFilters({ ...filters, pageNumber: 1, ...filtersValues, reload: true });
  }

  useEffect(() => {
    if (filters.reload) {
      const params = {
        searchTerm: filters.videoId ? '' : filters.keywords,
        // videoOwnerId: filters.videoOwnerId || '',
        videoOwnerName: filters.videoOwner,
        videoId: filters.videoId || '',
        videoUserId: filters.videoUser,
        pageNumber: filters.pageNumber - 1,
        pageSize: filters.pageSize,
        sort: {
          dir: 'DESC',
          fieldName: 'createdDate'
        },
        excludeIRLVideo: filters.excludeIRLVideo
        // scope: 'PUBLIC'
      };

      fetchData(params);
      setFilters({ ...filters, reload: false });
    }
  }, [filters.reload]);

  return (
    <>
      <Card className={classNames('w-100 mb-0 video-review-list', { 'search-form-hidden': !isShowSearch })}>
        <CardHeader className="">
          <VideoFilter
            onSubmitFilter={handleSubmitFilter}
            loading={fetching}
            onToggleShow={(show) => setIsShowSearch(show) }
            paging={{
              beginItem,
              endItem,
              totalItems,
              showDatatable
            }}
          />
        </CardHeader>
        <CardBody className="pt-0 data-list">
          {/* {!fetching && ( */}
          { (nodata || (!waitingSearch && videos.length === 0)) ? (
            <div className="loading-content text-center p-4 border-top">
              <p>No video found.</p>
            </div>
          ) : waiting ? (
            <div className="loading-content text-center p-4 border-top">
              <Spinner size="sm" />
            </div>
          ) : waitingSearch ? (
            <div className="loading-content text-center p-4 border-top">
              {/* <p>Please search the video by keywords or video id!</p> */}
            </div>
          ) : (
            <>
              <DataTable
                keyField="action_col"
                data={dataList}
                columns={tableColumns}
                sort={filters.sort}
                order={filters.order}
                onActionClick={onActionButtonClick}
              />
              <Row className="mt-3">
                <Col lg="3" md="4" className="d-flex">
                  <PageSizeSelector
                    size={filters.pageSize}
                    onChange={onSizeChange}
                  />
                  
                </Col>
                <Col lg="9" md="8" className="d-flex justify-content-end">
                  <Paging
                    totalPages={totalPages}
                    current={pageNumber}
                    show={5}
                    onSelect={onPageChange}
                  />
                </Col>
              </Row>
            </>
          )}
          {/* )} */}
        </CardBody>
      </Card>
      <VideoRetranscode
        modal={state.modalRetranscode}
        toggle={toggleModal}
        row={state.row}
        refreshData={refreshDatatable}
      />
      <VideoWithdraw
        modal={state.modalWithdraw}
        toggle={toggleModal}
        row={state.row}
        refreshData={refreshDatatable}
      />
      <VideoDelete
        modal={state.modalDelete}
        toggle={toggleModal}
        row={state.row}
        refreshData={refreshDatatable}
      />
    </>
  );
};

export default VideoList;
