import React from "react";

import {
  Col,
  Row
} from "reactstrap";

import DRP from "../../components/DateRangePicker";

const Header = ({ onDateChange, onRefresh, initStart, initEnd }) => {
  const handleDateChange = (start, end) => {
    if (onDateChange) onDateChange(start, end);
  }

  return (
    <Row className="mb-2 mb-xl-4">
      <Col xs="auto" className="d-none d-sm-block">
      </Col>
      <Col xs="auto" className="ml-auto text-right mt-n1">
        <div className="d-flex position-relative">
          <DRP onChange={handleDateChange}
            initStart={initStart}
            initEnd={initEnd}
          />
        </div>
      </Col>
    </Row>
  );
};

export default Header;
