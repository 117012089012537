import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  FormGroup,
  Label,
} from "reactstrap";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";

import {
  bouserRoleActions,
  fetchRolesAction,
} from "../../redux/reducers/bouserReducer";
import { startActionWithPromise } from "../../helpers/saga-promise-helpers";

const propTypes = {
  row: PropTypes.object,
  modal: PropTypes.bool,
  toggle: PropTypes.func,
};

const defaultProps = {
  row: {},
  modal: false,
  toggle: () => {},
};

const UserRoles = props => {
  // const {user} = props;
  const dispatch = useDispatch();
  const submitButton = useRef(null);

  const { modal, toggle, row } = props;

  const { register, handleSubmit } = useForm();

  const roles = useSelector(state => state.bouser?.roles);
  const userRoles = useSelector(state => state.bouser?.userRoles);
  const loggedUserRoles = useSelector(state => state.bouser?.loggedUserRoles);

  var disableUpdate = true;
  if (loggedUserRoles.indexOf('SYSTEM_ADMINS') !== -1) {
    disableUpdate = false;
  } else if (loggedUserRoles.indexOf('SYSTEM_OPERATORS') !== -1) {
    if (userRoles.indexOf('SYSTEM_ADMINS') === -1) disableUpdate = false;
  } else if (loggedUserRoles.indexOf('BO_ADMINS') !== -1) {
    if (userRoles.indexOf('SYSTEM_OPERATORS') === -1 && userRoles.indexOf('SYSTEM_ADMINS') === -1) disableUpdate = false;
  }

  var availableRoles = roles;
  if (loggedUserRoles.indexOf('SYSTEM_ADMINS') !== -1) {
    availableRoles = roles;
  } else if (loggedUserRoles.indexOf('SYSTEM_OPERATORS') !== -1) {
    availableRoles = roles.filter(role => role !== 'SYSTEM_ADMINS');
  } else {
    availableRoles = roles.filter(role => role !== 'SYSTEM_ADMINS' && role !== 'SYSTEM_OPERATORS');
  }
  
  const [pristine, setPristine] = useState(true);
  const [waiting, setWaiting] = useState(false);

  const [checkedRoles, setCheckedRoles] = useState([...userRoles]);

  const toggleModal = () => {
    if (toggle) toggle("modalRoles");
  };

  const triggerSubmitForm = () => {
    if (submitButton.current) submitButton.current.click();
  };

  const onCheckedChangeRole = (e, role) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    setPristine(false);

    const chk = checkedRoles.find(x => x === role);
    if (chk) {
      const roles = checkedRoles.filter(x => x !== role);
      setCheckedRoles(roles);
    } else {
      setCheckedRoles([...checkedRoles, role]);
    }
  };

  const onSubmit = async () => {
    setWaiting(true);

    try {
      await startActionWithPromise(
        bouserRoleActions.update,
        {
          id: props.row.userId,
          data: checkedRoles,
          successCallback,
          failedCallback,
        },
        dispatch
      );
    } catch (error) {
    }
    setWaiting(false);
  };

  const successCallback = () => {
    setWaiting(false);
    if (props.refreshData) props.refreshData();
    toggleModal();
  };

  const failedCallback = () => {
    setWaiting(false);
  };

  useEffect(() => {
    if (props.row?.userId && modal) {
      dispatch(bouserRoleActions.fetch.start({ id: props.row.userId }));


    }
  }, [dispatch, modal, props.row]);

  useEffect(() => {
    setCheckedRoles(userRoles);
  }, [userRoles]);

  useEffect(() => {
    dispatch(fetchRolesAction.start());
  }, [dispatch]);

  return (
    <Modal
      isOpen={modal}
      toggle={toggleModal}
      centered
      size="md"
      onClosed={() => setPristine(true)}
    >
      <ModalHeader toggle={toggleModal}>
        Update BO user roles
      </ModalHeader>
      <ModalBody className="">
        <div className="user-detail p-3 border bg-light rounded">
          {row && (
            <>
              <div className="title mb-2">
                <div className="font-weight-bold">Username: {row.username}</div>
                <div className=""></div>
              </div>
              <div className="title mb-2">
                <div className="font-weight-bold">Email: {row.email}</div>
                <div className=""></div>
              </div>
              <div className="title">
                <div className="font-weight-bold">Fullname: {row ? row?.fullname : ''}</div>
                <div className=""></div>
              </div>
            </>
          )}
        </div>
        <form onSubmit={handleSubmit(onSubmit)} id="form-bouser" className="mt-3">
          <Row className="d-none">
            <Col lg="12">
              <FormGroup>
                <FormGroup check inline className="">
                  <Label check>
                    <input
                      className="form-check-input"
                      name="enabled"
                      type="checkbox"
                      defaultChecked
                      ref={register}
                      onChange={() => setPristine(false)}
                    />
                    Enabled
                  </Label>
                </FormGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <Row>
                {availableRoles &&
                  availableRoles.map(role => (
                    <Col lg="6" key={`col-${role}`}>
                      <FormGroup key={role} check>
                        <Label check>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            disabled={disableUpdate}
                            checked={
                              !!(
                                checkedRoles &&
                                checkedRoles.indexOf(role) !== -1
                              )
                            }
                            onChange={e => {
                              onCheckedChangeRole(e, role);
                            }}
                          />
                          {role}
                        </Label>
                      </FormGroup>
                    </Col>
                  ))}
              </Row>
            </Col>
          </Row>

          <Button
            className="d-none"
            disabled={pristine}
            type="submit"
            innerRef={submitButton}
          >
            Submit
          </Button>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" disabled={waiting} onClick={toggleModal}>
          Cancel
        </Button>
        <Button
          className="ml-2"
          color="primary"
          disabled={pristine || waiting || disableUpdate}
          onClick={() => triggerSubmitForm(true)}
        >
          Save changes
        </Button>
      </ModalFooter>
    </Modal>
  );
};

UserRoles.propTypes = propTypes;
UserRoles.defaultProps = defaultProps;

export default UserRoles;
