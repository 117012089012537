import { takeLatest, call, put, delay } from "redux-saga/effects";

import { sagaPromise } from "../../helpers/saga-promise-helpers";
import {
  fetchBoUsersAction,
  bouserActions,
  forceLogoutAction,
  changePwAction,
  changeMyPwAction,
  resetPwAction,
  forgotPwAction,
  revokeRolesAction,
  fetchRolesAction,
  bouserRoleActions,
  getProfileAction,
  updateProfileAction,
  fetchLoggedUserRolesAction,
  enableAction,
  disableAction,
  searchBoUsersAction
} from "../reducers/bouserReducer";
import bouserServices from "../../services/bouserService";
import { alertTypes } from "../constants";
import { BO_USER_MESSAGES } from "../../config/messages";

function* fetchWorker(action) {
  try {
    const { data, error } = yield call(
      bouserServices.fetch,
      action.payload?.params
    );
    if (error) {
      yield put(fetchBoUsersAction.error(error));
    } else {
      yield put(fetchBoUsersAction.success({ ...action, data }));
    }
  } catch (err) {
    yield put(fetchBoUsersAction.error(err));
  }
}

function* addWorker(action) {
  const { data, error } = yield call(
    bouserServices.register,
    action.payload.data
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      yield put({
        type: alertTypes.ERROR,
        message: error.headers["x-message-info"],
      });
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    yield put({
      type: alertTypes.SUCCESS,
      message: BO_USER_MESSAGES.addSuccess,
    });
    return data;
  }

  return false;
}

function* updateWorker(action) {
  const { data, error } = yield call(
    bouserServices.updateBoUser,
    action.payload.id,
    action.payload.data
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      if (!action.payload?.hideAlert) {
        yield put({
          type: alertTypes.ERROR,
          message: error.headers["x-message-info"],
        });
        throw new Error(error.headers["x-message-info"]);
      }
      return { message: error.headers["x-message-info"] };
    }
  } else {
    if (!action.payload?.hideAlert)
      yield put({
        type: alertTypes.SUCCESS,
        message: BO_USER_MESSAGES.updateSuccess,
      });
    return data;
  }

  return false;
}

function* deleteWorker(action) {
  const { data, error } = yield call(
    bouserServices.deleteBoUser,
    action.payload.id
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      yield put({
        type: alertTypes.ERROR,
        message: error.headers["x-message-info"],
      });
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    yield put({
      type: alertTypes.SUCCESS,
      message: BO_USER_MESSAGES.deleteSuccess,
    });
    return data;
  }

  return false;
}

function* logoutWorker(action) {
  const { data, error } = yield call(
    bouserServices.forceLogout,
    action.payload.id
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      yield put({
        type: alertTypes.ERROR,
        message: error.headers["x-message-info"],
      });
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    yield put({
      type: alertTypes.SUCCESS,
      message: BO_USER_MESSAGES.logoutSuccess,
    });
    return data;
  }

  return false;
}

function* changePasswordWorker(action) {
  const { data, error } = yield call(
    bouserServices.changePassword,
    action.payload.id,
    action.payload.data
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      yield put({
        type: alertTypes.ERROR,
        message: error.headers["x-message-info"],
      });
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    yield put({
      type: alertTypes.SUCCESS,
      message: BO_USER_MESSAGES.changePwSuccess,
    });
    return data;
  }

  return false;
}

function* changeMyPasswordWorker(action) {
  const { data, error } = yield call(
    bouserServices.changeMyPassword,
    action.payload.data
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      yield put({
        type: alertTypes.ERROR,
        message: error.headers["x-message-info"],
      });
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    yield put({
      type: alertTypes.SUCCESS,
      message: BO_USER_MESSAGES.changeMyPwSuccess,
    });
    return data;
  }

  return false;
}

function* resetPasswordWorker(action) {
  const { data, error } = yield call(
    bouserServices.resetPassword,
    action.payload.id
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      yield put({
        type: alertTypes.ERROR,
        message: error.headers["x-message-info"],
      });
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    yield put({
      type: alertTypes.SUCCESS,
      message: BO_USER_MESSAGES.resetPwSuccess,
    });
    return data;
  }

  return false;
}

function* forgotPasswordWorker(action) {
  const { data, error } = yield call(
    bouserServices.forgotPassword,
    action.payload.email
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      yield put({
        type: alertTypes.ERROR,
        message: error.headers["x-message-info"],
      });
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    yield put({
      type: alertTypes.SUCCESS,
      message: BO_USER_MESSAGES.forgotPwSuccess,
    });
    return data;
  }

  return false;
}

function* revokeRolesWorker(action) {
  const { data, error } = yield call(
    bouserServices.deleteUserRoles,
    action.payload.id
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      yield put({
        type: alertTypes.ERROR,
        message: error.headers["x-message-info"],
      });
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    yield put({
      type: alertTypes.SUCCESS,
      message: BO_USER_MESSAGES.revokeRolesSuccess,
    });
    return data;
  }

  return false;
}

/**
 * fetch all available roles
 */
function* fetchRolesWorker(action) {
  try {
    const { data, error } = yield call(bouserServices.fetchRoles);
    if (error) {
      yield put(fetchRolesAction.error(error));
    } else {
      yield put(fetchRolesAction.success({ ...action, data }));
    }
  } catch (err) {
    yield put(fetchRolesAction.error(err));
  }
}

/**
 * fetch roles of user
 */
function* fetchUserRolesWorker(action) {
  try {
    const { data, error } = yield call(
      bouserServices.fetchUserRoles,
      action.payload?.id
    );
    if (error) {
      yield put(bouserRoleActions.fetch.error(error));
    } else {
      yield put(bouserRoleActions.fetch.success({ ...action, data }));
    }
  } catch (err) {
    yield put(bouserRoleActions.fetch.error(err));
  }
}

/**
 * fetch roles of user
 */
function* fetchLoggedUserRolesWorker(action) {
  try {
    const { data, error } = yield call(
      bouserServices.fetchMyRoles
    );
    if (error) {
      yield put(fetchLoggedUserRolesAction.error(error));
    } else {
      yield put(fetchLoggedUserRolesAction.success({ ...action, data }));
    }
  } catch (err) {
    yield put(fetchLoggedUserRolesAction.error(err));
  }
}

/**
 * Update user roles
 */
function* updateUserRolesWorker(action) {
  const { data, error } = yield call(
    bouserServices.setUserRoles,
    action.payload?.id,
    action.payload?.data
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      yield put({
        type: alertTypes.ERROR,
        message: error.headers["x-message-info"],
      });
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    yield put({
      type: alertTypes.SUCCESS,
      message: BO_USER_MESSAGES.updateUserRoleSuccess,
    });
    return data;
  }

  return false;
}

/**
 * Get user profile
 */
function* getProfileWorker(action) {
  try {
    const { data, error } = yield call(
      bouserServices.getProfile,
      action.payload?.id
    );
    if (error) {
      yield put(getProfileAction.error(error));
    } else {
      yield put(getProfileAction.success({ ...action, data }));
    }
  } catch (err) {
    yield put(getProfileAction.error(err));
  }
}

/**
 * Update user profile
 */
function* updateProfileWorker(action) {
  const { data, error } = yield call(
    bouserServices.updateProfile,
    action.payload?.id,
    action.payload?.data
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      yield put({
        type: alertTypes.ERROR,
        message: error.headers["x-message-info"],
      });
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    yield put({
      type: alertTypes.SUCCESS,
      message: BO_USER_MESSAGES.updateProfileSuccess,
    });
    return data;
  }

  return false;
}

function* enableWorker(action) {
  const { data, error } = yield call(
    bouserServices.enable,
    action.payload?.userId,
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      yield put({
        type: alertTypes.ERROR,
        message: error.headers["x-message-info"],
      });
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    yield put({
      type: alertTypes.SUCCESS,
      message: BO_USER_MESSAGES.enableSuccess,
    });
    return data;
  }

  return false;
}

function* disableWorker(action) {
  const { data, error } = yield call(
    bouserServices.disable,
    action.payload?.userId,
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      yield put({
        type: alertTypes.ERROR,
        message: error.headers["x-message-info"],
      });
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    yield put({
      type: alertTypes.SUCCESS,
      message: BO_USER_MESSAGES.disableSuccess,
    });
    return data;
  }

  return false;
}

function* searchUserWorker(action) {
  yield delay(1000);

  const { data, error } = yield call(
    bouserServices.fetch,
    action.payload.params
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    return data;
  }

  return false;
}

function* bouserSaga() {
  yield takeLatest(fetchBoUsersAction.start, fetchWorker);
  // yield takeLatest(
  //   fetchBoUsersAction.start,
  //   fetchBoUsersAction.waiterActionForSaga(sagaPromise(fetchWorker))
  // );

  yield takeLatest(fetchRolesAction.start, fetchRolesWorker);
  yield takeLatest(bouserRoleActions.fetch.start, fetchUserRolesWorker);
  yield takeLatest(fetchLoggedUserRolesAction.start, fetchLoggedUserRolesWorker);

  yield takeLatest(
    bouserActions.create.start,
    bouserActions.create.waiterActionForSaga(sagaPromise(addWorker))
  );
  yield takeLatest(
    bouserActions.update.start,
    bouserActions.update.waiterActionForSaga(sagaPromise(updateWorker))
  );
  yield takeLatest(
    bouserActions.delete.start,
    bouserActions.delete.waiterActionForSaga(sagaPromise(deleteWorker))
  );

  yield takeLatest(
    forceLogoutAction.start,
    forceLogoutAction.waiterActionForSaga(sagaPromise(logoutWorker))
  );
  yield takeLatest(
    changePwAction.start,
    changePwAction.waiterActionForSaga(sagaPromise(changePasswordWorker))
  );
  yield takeLatest(
    changeMyPwAction.start,
    changeMyPwAction.waiterActionForSaga(sagaPromise(changeMyPasswordWorker))
  );
  yield takeLatest(
    resetPwAction.start,
    resetPwAction.waiterActionForSaga(sagaPromise(resetPasswordWorker))
  );
  yield takeLatest(
    forgotPwAction.start,
    forgotPwAction.waiterActionForSaga(sagaPromise(forgotPasswordWorker))
  );

  yield takeLatest(
    bouserRoleActions.update.start,
    bouserRoleActions.update.waiterActionForSaga(
      sagaPromise(updateUserRolesWorker)
    )
  );

  yield takeLatest(
    revokeRolesAction.start,
    revokeRolesAction.waiterActionForSaga(sagaPromise(revokeRolesWorker))
  );

  yield takeLatest(getProfileAction.start, getProfileWorker);
  yield takeLatest(
    updateProfileAction.start,
    updateProfileAction.waiterActionForSaga(sagaPromise(updateProfileWorker))
  );

  yield takeLatest(
    enableAction.start,
    enableAction.waiterActionForSaga(sagaPromise(enableWorker))
  );

  yield takeLatest(
    disableAction.start,
    disableAction.waiterActionForSaga(sagaPromise(disableWorker))
  );

  yield takeLatest(
    searchBoUsersAction.start,
    searchBoUsersAction.waiterActionForSaga(sagaPromise(searchUserWorker))
  );
}

export default bouserSaga;
