import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Spinner
} from "reactstrap";
import classNames from "classnames";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";

import DataTable from "../../components/DataTable";
import PageSizeSelector from "../../components/base/PageSizeSelector";
import Paging from "../../components/base/Paging";
import config from "../../config/config";
import useMergeState from '../../hooks/mergeState';
import { useAuth } from "../../hooks/useAuth";

import FormFilter from "./FormFilter";
import ConfirmModal from "../../components/base/ConfirmModal";

import { searchConnectedAccountsAction, refreshConnectedAccountAction, removeConnectedAccountAction } from "../../redux/reducers/subscriptionReducer";
import { startActionWithPromise } from "../../helpers/saga-promise-helpers";
import "./index.scss";
import { getTableColumns, rowActionTypes } from "./TableColumns";

const ConnectedAccounts = ({user}) => {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    modalConfirm: false,
    action: '',
    message: '',
    row: null,
  });

  const defaultFilters = {
    pageSize: config.DEFAULT_PAGESIZE,
    pageNumber: 1,
    userId: user ? user.userId : '',
    defaultAccount: '',
    submitCompleted: '',
    transfersEnabled: '',
    payoutsEnabled: '',
    sort: {
      dir: `desc`,
      fieldName: `defaultAccount`,
    },
    reload: false,
  };
  const [filters, setFilters] = useMergeState(defaultFilters);

  const [waiting, setWaiting] = useState(false);
  
  const auth = useAuth();
  const isAuthorized = auth.roles &&
    (auth.roles.indexOf('SYSTEM_ADMINS') !== -1 ||
    auth.roles.indexOf('SYSTEM_OPERATORS') !== -1);
  const fetching = false;
  const [connectedAccounts, setConnectedAccounts] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const isBoAdmin = useSelector(state => state.bouser.isBoAdmin);

  const numItems = totalItems > filters.pageSize ? filters.pageSize : totalItems;
  const beginItem = numItems * (filters.pageNumber - 1);
  const endItem = numItems * filters.pageNumber;

  const onSizeChange = (size) => {
    setFilters({ ...filters, pageSize: size, pageNumber: 1, reload: true });
  };

  const onPageChange = (p) => {
    setFilters({ ...filters, pageNumber: p, reload: true });
  };

  const handleColumnSort = (field, order) => {
    setFilters({
      ...filters,
      sort: {
        dir: order,
        fieldName: field,
      },
      reload: true,
    });
  };

  const tableColumns = getTableColumns(handleColumnSort, isBoAdmin, state, setState);

  const toggleModal = (modal) => {
    if (state[modal]) setState({ ...state, [modal]: !state[modal] });
    else setState({ ...state, [modal]: !state[modal], row: null });
  };

  const refreshDatatable = () => {
    setFilters({ ...filters, reload: true });
  };

  const fetchConnectedAccounts = () => {
    (async () => {
      try {
        // setWaiting(true);
        let params = {
          ...filters,
          pageNumber: filters.pageNumber - 1,
          sort: {
            dir: filters.sort.dir.toUpperCase(),
            fieldName: filters.sort.fieldName,
          },
        };
        delete params.reload;

        if (!filters.userId) delete params.userId;
        if (!filters.defaultAccount) delete params.defaultAccount;
        if (!filters.submitCompleted) delete params.submitCompleted;
        if (!filters.transfersEnabled) delete params.transfersEnabled;
        if (!filters.payoutsEnabled) delete params.payoutsEnabled;

        const resp = await startActionWithPromise(
          searchConnectedAccountsAction,
          {params, successCallback: () => {}, failedCallback: () => {}},
          dispatch
        );
        setConnectedAccounts(resp.content);
        setTotalItems(resp.total);
        setTotalPages(Math.ceil(resp.total / resp.pageSize) || 0);
        setPageNumber(resp.pageNumber + 1 || 1);

      } catch {}
      setWaiting(false);
      // setFilters({ ...filters, reload: false });
    })();
  };

  useEffect(() => {
    if (filters.reload) {
      fetchConnectedAccounts();
      setFilters({ ...filters, reload: false });
    }
  }, [filters.reload]);

  useEffect(() => {
    fetchConnectedAccounts();
  }, []);

  ////////// Table's action handlers ///////////

  const onActionConfirm = () => {
    switch (state.action) {
      case rowActionTypes.REFRESH_ACCOUNT:
        refreshAccount(state.row);
        break;
      case rowActionTypes.REMOVE_ACCOUNT:
        removeAccount(state.row);
        break;
      default:
    }
  };

  const refreshAccount = (row) => {
    const params = {
      accountId: row.accountId
    };

    (async () => {
      try {
        await startActionWithPromise(refreshConnectedAccountAction,
          { ...params, successCallback: () => {
            setState({ ...state, modalConfirm: false});
          }, failedCallback: () => {} },
          dispatch
        );
      } catch {};
      refreshDatatable();
    })();
  };

  const removeAccount = (row) => {
    const params = {
      accountId: row.accountId
    };

    (async () => {
      try {
        await startActionWithPromise(removeConnectedAccountAction,
          { ...params, successCallback: () => {
            setState({ ...state, modalConfirm: false});
          }, failedCallback: () => {} },
          dispatch
        );
      } catch {};
      refreshDatatable();
    })();
  };

  ////////////////////

  return (
    <Container fluid className="p-0">
      <h1 className="page-title">Connected Accounts</h1>
      <Row>
        <Col lg="12" className="d-flex">
        <>
          <Card className={classNames('w-100 mb-0 datatable-wrap')}>
            <CardHeader className="">
              <FormFilter
                loading={fetching}
                filters={filters}
                mergeFilters={setFilters}
                paging={{
                  beginItem,
                  endItem,
                  totalItems
                }}
                user={user}
              />
            </CardHeader>
            <CardBody className="pt-0 data-list">
              { waiting ? (
                <div className="loading-content text-center p-4 border-top">
                  <Spinner size="sm" />
                </div>
              ) : (
                <>
                  <DataTable
                    keyField="id"
                    data={connectedAccounts}
                    columns={tableColumns}
                    order={filters.sort?.dir}
                    sort={filters.sort?.fieldName}
                    hideSelectColumn={true}
                  />
                  <Row className="mt-3">
                    <Col lg="3" md="4" className="d-flex">
                      <PageSizeSelector size={filters.pageSize} onChange={onSizeChange} />
                    </Col>
                    <Col lg="9" md="8" className="d-flex justify-content-end">
                      <Paging totalPages={totalPages} current={pageNumber} show={5} onSelect={onPageChange} />
                    </Col>
                  </Row>
                </>
              )}
            </CardBody>
          </Card>
          <ConfirmModal
            modal={state.modalConfirm}
            toggle={toggleModal}
            row={state.row}
            onConfirm={onActionConfirm}
            on
            message={state.message}
          />
        </>
        </Col>
      </Row>
    </Container>
  );
};

export default ConnectedAccounts;
