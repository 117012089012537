import { takeLatest, takeEvery, call, put } from "redux-saga/effects";
import { DOMAIN_ROLE_MESSAGES } from "../../config/messages";
import { sagaPromise } from "../../helpers/saga-promise-helpers";
import { roleActions } from "../reducers/domainRoleReducer";
import domainService from "../../services/domainService";
import { alertTypes } from "../constants";

function* fetchRolesWorker(action) {
  const { data, error } = yield call(
    domainService.getDomainRoles,
    action.payload?.params
  );
  if (error) {
    yield put(roleActions.fetch.error(error));
    if (error?.headers["x-message-info"]) {
      throw new Error(error?.headers["x-message-info"]);
    }
  } else {
    yield put(roleActions.fetch.success(data));
    return data;
  }

  return false;
}

function* addRoleWorker(action) {
  const { data, error } = yield call(
    domainService.addRole,
    action.payload.data
  );
  if (error) {
    if (error?.headers["x-message-info"]) {
      yield put({
        type: alertTypes.ERROR,
        message: error?.headers["x-message-info"],
      });
      throw new Error(error?.headers["x-message-info"]);
    }
  } else {
    yield put({
      type: alertTypes.SUCCESS,
      message: DOMAIN_ROLE_MESSAGES.addSuccess,
    });
    return data;
  }

  return false;
}

function* updateRoleWorker(action) {
  const { data, error } = yield call(
    domainService.updateRole,
    action.payload.id,
    action.payload.data
  );
  if (error) {
    if (error?.headers["x-message-info"]) {
      if (!action.payload?.hideAlert) {
        yield put({
          type: alertTypes.ERROR,
          message: error?.headers["x-message-info"],
        });
        throw new Error(error?.headers["x-message-info"]);
      }
      return { message: error?.headers["x-message-info"] };
    }
  } else {
    if (!action.payload?.hideAlert)
      yield put({
        type: alertTypes.SUCCESS,
        message: DOMAIN_ROLE_MESSAGES.updateSuccess,
      });
    return data;
  }

  return false;
}

function* deleteRoleWorker(action) {
  const { data, error } = yield call(
    domainService.deleteRole,
    action.payload.id
  );
  if (error) {
    if (error?.headers["x-message-info"]) {
      yield put({
        type: alertTypes.ERROR,
        message: error?.headers["x-message-info"],
      });
      throw new Error(error?.headers["x-message-info"]);
    }
  } else {
    yield put({
      type: alertTypes.SUCCESS,
      message: DOMAIN_ROLE_MESSAGES.deleteSuccess,
    });
    return data;
  }

  return false;
}

function* domainRoleSaga() {
  yield takeLatest(roleActions.fetch.start, fetchRolesWorker);
  yield takeLatest(
    roleActions.create.start,
    roleActions.create.waiterActionForSaga(sagaPromise(addRoleWorker))
  );
  yield takeEvery(
    roleActions.update.start,
    roleActions.update.waiterActionForSaga(sagaPromise(updateRoleWorker))
  );
  yield takeLatest(
    roleActions.delete.start,
    roleActions.delete.waiterActionForSaga(sagaPromise(deleteRoleWorker))
  );
}

export default domainRoleSaga;
