import {
  createReducer,
  createActionResources,
} from "redux-waiters";
import { RESET_REDUCER } from "../constants";

export const searchVideoAction = createActionResources("videos/search");
export const deleteVideoAction = createActionResources("videos/delete");
export const searchVideo2Action = createActionResources("videos/search2");

const initialState = {
  fetching: false,
  list: [],
  total: 0,
  totalPages: 0,
  pageNumber: 1,
  user: {},
  error: {},
};

export default createReducer(
  {
    [searchVideoAction.start]: state => {
      return { ...state, fetching: true };
    },
    [searchVideoAction.success]: (state, action) => {
      return {
        ...state,
        list: action.data?.content,
        pageNumber: action.data?.pageNumber + 1,
        total: action.data?.total,
        totalPages: Math.ceil(action.data?.total / action.data?.pageSize),
        fetching: false,
      };
    },
    [searchVideoAction.error]: state => {
      return { ...state, fetching: false };
    },

    [RESET_REDUCER]: () => initialState,
  },
  initialState
);
