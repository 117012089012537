import React from "react";
import { Container, Row, Col } from "reactstrap";
import VideoStatisticsList from "./VideoStatisticsList";

const VideosStatistics = () => {
  return (
    <Container fluid className="p-0">
      <h1 className="page-title">Video Statistics</h1>
      <Row>
        <Col lg="12" className="d-flex">
          <VideoStatisticsList />
        </Col>
      </Row>
    </Container>
  );
};

export default VideosStatistics;
