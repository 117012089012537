import { get, post, request } from "../helpers/apiHelper";

async function search(params) {
  return await post("advertising/search", params);
}

async function resync() {
  return await get("advertising/synchronization");
}

async function setFeatured(params) {
  return await post(`advertising/add`, params);
}

async function updateFeatured(params) {
  return await post(`advertising/update`, params);
}

async function removeFeatured(videoId) {
  return await request(`advertising/${videoId}`, null, 'delete');
}

async function enable(videoId) {
  return await request(`advertising/${videoId}/enable`, null, 'put');
}

async function disable(videoId) {
  return await request(`advertising/${videoId}/disable`, null, 'put');
}

export default {
  search,
  resync,
  setFeatured,
  updateFeatured,
  removeFeatured,
  enable,
  disable,
};

