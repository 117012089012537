import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import PropTypes from "prop-types";

import { recloneAction } from "../../redux/reducers/orderReducer";
import { startActionWithPromise } from "../../helpers/saga-promise-helpers";

const propTypes = {
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  order: PropTypes.object,
};

const defaultProps = {
  modal: false,
  toggle: () => {},
};

const RecloneVideo = props => {
  const dispatch = useDispatch();
  const { modal, toggle, order } = props;
  let [waiting, setWaiting] = useState(false);

  const toggleModal = () => {
    if (toggle) toggle('modalReclone');
  };

  const handleConfirm = async () => {
    try {
      let params = {
        orderId: order.orderId,
        userId: order.buyerId,
        videoId: order.productId,
      };
      setWaiting(true);
      await startActionWithPromise(
        recloneAction,
        {params, successCallback: () => {}, failedCallback: () => {}},
        dispatch
      );
      setWaiting(false);
      toggleModal();
    } catch {}
  }

  return (
    <>
      <Modal isOpen={modal} toggle={toggleModal} centered size="sm">
        <ModalHeader toggle={toggleModal}>
          Confirmation
        </ModalHeader>
        <ModalBody className="">
          <div>
            <p className="font-weight-bold">Video "{order.productTitle}" will be re-clone to {order.buyerFullname} ({order.buyerEmailAddress}). </p>
            <p className="font-weight-bold">Do you want to continue?</p>
          </div>
          
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            disabled={waiting}
            onClick={toggleModal}
          >
            No
          </Button>
          <Button
            className="ml-2"
            color="primary"
            disabled={waiting}
            onClick={handleConfirm}
          >
            Yes
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

RecloneVideo.propTypes = propTypes;
RecloneVideo.defaultProps = defaultProps;

export default RecloneVideo;
