import { takeLatest, call, put } from "redux-saga/effects";
import domainService from "../../services/domainService";
import {
  fetchDomainsAction,
  fetchActionsAction,
} from "../reducers/domainReducer";

function* fetchDomainsWorker() {
  try {
    const { data, error } = yield call(domainService.fetchDomains);
    if (error) {
      yield put(
        fetchDomainsAction.error({ message: error?.response?.statusText })
      );
    } else {
      yield put(fetchDomainsAction.success(data));
    }
  } catch (error) {
    yield put(fetchDomainsAction.error({ message: error.message }));
  }
}

function* fetchActionsWorker(action) {
  try {
    const { data, error } = yield call(
      domainService.getDomainActions,
      action.payload
    );
    if (error) {
      yield put(
        fetchActionsAction.error({ message: error?.response?.statusText })
      );
    } else {
      yield put(fetchActionsAction.success(data));
    }
  } catch (error) {
    yield put(fetchActionsAction.error({ message: error.message }));
  }
}

function* domainSaga() {
  // tasks
  yield takeLatest(fetchDomainsAction.start, fetchDomainsWorker);
  yield takeLatest(fetchActionsAction.start, fetchActionsWorker);
}

export default domainSaga;
