import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import PropTypes from "prop-types";

const propTypes = {
  row: PropTypes.object,
  modal: PropTypes.bool,
  message: PropTypes.string,
  toggle: PropTypes.func,
};

const defaultProps = {
  row: {},
  modal: false,
  message: "",
  toggle: () => {},
};

const InfoModal = props => {

  const toggle = () => {
    if (props.toggle) props.toggle("modalInfo");
  };

  return (
    <Modal
      isOpen={props.modal}
      toggle={toggle}
      centered
      size="sm"
      className="comfirmation-modal"
    >
      <ModalHeader toggle={toggle}>Information</ModalHeader>
      <ModalBody className="m-3">
        <div className="" dangerouslySetInnerHTML={{ __html: props.message }} />
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={toggle}>
          OK
        </Button>
      </ModalFooter>
    </Modal>
  );
};

InfoModal.propTypes = propTypes;
InfoModal.defaultProps = defaultProps;

export default React.memo(InfoModal);
