import React from "react";
import { Badge } from "reactstrap";
import * as Icon from "react-feather";
import classNames from "classnames";
import Avatar from "../../components/Avatar";
import { formatBytes } from "../../helpers/utils";

export const getTableColumns = (handleSort = () => {}, handleReclone) => {
  return [
    {
      dataField: "fullname",
      text: "User",
      sort: false,
      onSort: (field, order) => {
        handleSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div className="d-flex">
          <div className="user-avatar d-flex" style={{minWidth: '48px'}}>
            {row.avatar?.image ? (
              <Avatar className="user-avatar d-inline-block align-self-center mr-2" image={row?.avatar?.image} fullname={row.fullname} width="40" height="40" />
            ) : (
              <Avatar className="user-avatar d-inline-block align-self-center mr-2" image={row?.avatar?.image} fullname={row.fullname} width="40" height="40" />
            )}
          </div>
          <div className="d-inline-block fullname align-self-center">
            <p className="mb-0" style={{textDecoration: row.deleted ? 'line-through' : ''}}>{row.fullname}</p>
            <p className="mb-0">{row.email}</p>
            <p className="mb-0">{row.phone}</p>
            <p className="mb-0 font-italic" style={{fontSize: '0.75rem'}}>{row.userId}</p>
          </div>
        </div>
      ),
    },
    {
      dataField: "used",
      text: "Storage",
      sort: true,
      onSort: (field, order) => {
        handleSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div className="text-center font-weight-bold">
          <span
            className={!row.unlimited && row.capacityInGB && row.usedInGB > row.capacityInGB ? "text-danger" : ""}
            title={!row.unlimited && row.capacityInGB && row.usedInGB > row.capacityInGB ? "Full" : ""}
          >
            {row.capacityInGB ? `${formatBytes(row.used)} / ${row.unlimited ? ' UNLIMITED' : row.capacityInGB + ' GB'}` : '---'}
          </span>
        </div>
      ),
      headerStyle: () => {
        return { width: "200px", textAlign: "center" };
      },
    },
    {
      dataField: "active",
      text: "Activated",
      sort: false,
      onSort: (field, order) => {
        handleSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div className="text-center">
          <span
            color={row.active ? "success" : "secondary"}
            className={classNames('mr-1 mb-1', row.active ? "text-success" : "text-gray")}
          >
            {row.active ? <Icon.CheckSquare width="15" /> : <Icon.Square width="15" />}
          </span>
        </div>
      ),
      headerStyle: () => {
        return { width: "100px", textAlign: "center" };
      },
    },
    {
      dataField: "enabled",
      text: "Status",
      sort: false,
      onSort: (field, order) => {
        handleSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div>
          <Badge
            color={row.enabled ? "success" : "gray"}
            className="mr-1 mb-1"
          >
            {row.enabled ? "Enabled" : "Disabled"}
          </Badge>
        </div>
      ),
      headerStyle: () => {
        return { width: "100px" };
      },
    },
    {
      dataField: "joiningDate",
      text: "Join At",
      sort: false,
      onSort: (field, order) => {
        handleSort(field, order);
      },
      sortFunc: () => {
      },
      headerStyle: () => {
        return { width: "150px" };
      },
    },
    // {
    //   dataField: "action_col",
    //   text: "Actions",
    //   sort: false,
    //   formatter: (cell, row, rowIndex, formatExtraData) => (
    //     <div className="actions-col text-center">
          
    //     </div>
    //   ),
    //   formatExtraData: {
        
    //   },
    //   headerStyle: () => {
    //     return { width: "130px", textAlign: "center" };
    //   },
    // },
  ];
}