import React, { useState } from "react";
import Select from 'react-select';

const selectStyles = {
  control: provided => (
    { ...provided, 
      borderWidth: '1px', 
      minHeight:'31px', 
      borderRadius: '2px', 
      // boxShadow: '0 0 0 1px #ced4da',
      boxShadow: 'none',
      borderColor: '#ced4da',
      "&:hover": {
        borderColor: '#ced4da'
      }
    }),
  option: (provided, state) => {
    return {
      ...provided,
      backgroundColor: state.isSelected ? "#86C9CF" : provided.backgroundColor,
      "&:active": {
        backgroundColor: "#86C9CF"
      },
      padding: '3px 8px'
    }
  },
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '3px'
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0px 8px'
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: '3px'
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: '3',
    borderRadius: '2px',
  }),
};

const dropUpStyles = {
  control: provided => (
    { ...provided, 
      borderWidth: '1px', 
      minHeight:'31px', 
      borderRadius: '2px', 
      // boxShadow: '0 0 0 1px #ced4da',
      boxShadow: 'none',
      borderColor: '#ced4da',
      "&:hover": {
        borderColor: '#ced4da'
      }
    }),
  option: (provided, state) => {
    return {
      ...provided,
      backgroundColor: state.isSelected ? "#86C9CF" : provided.backgroundColor,
      "&:active": {
        backgroundColor: "#86C9CF"
      },
      padding: '3px 8px'
    }
  },
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '3px'
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0px 8px'
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: '3px'
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: '3',
    borderRadius: '2px',
    top: 'auto',
    bottom: '100%',
    marginTop: '0px',
    marginBottom: '-1px',
    borderBottomRightRadius: '0px',
    borderBottomLeftRadius: '0px',
    borderTopLeftRadius: '2px',
    borderTopRightRadius: '2px',
    // boxShadow: 'none',
  }),
};

const Selectbox = ({options, onChange, dropUp, defaultValue, ...rest}) => {
  const [selected, setSelected] = useState(defaultValue);

  const handleChange = (option, actionMeta) => {
    setSelected(option);
    if (onChange) onChange(option, actionMeta);
  }

  return (
    <Select
      options={options}
      isClearable={true}
      value={selected}
      styles={dropUp ? dropUpStyles : selectStyles}
      onChange={handleChange}
      {...rest}
    />
  )
}

export default React.memo(Selectbox);