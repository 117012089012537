import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";

import { useAuth } from "../hooks/useAuth";

const propTypes = {
  children: PropTypes.array,
};

const defaultProps = {
  children: [],
};

function PrivateRoute({ children, ...rest }) {
  const auth = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.user ? (
          children
        ) : (
          <Redirect
            to={{ pathname: "/auth/sign-in", state: { from: location } }}
          />
        )
      }
    />
  );
}

PrivateRoute.propTypes = propTypes;
PrivateRoute.defaultProps = defaultProps;

export default PrivateRoute;
