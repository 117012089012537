import React, { useState } from "react";
import { Row, Col, Button } from "reactstrap";
import _ from 'lodash';
import * as Icon from "react-feather";
import classNames from "classnames";
import moment from "moment";

import "./FormFilter.scss";
import DRP from "../../components/DateRangePicker";
import SearchUserInput from "./SearchUserInput";
import Selectbox from '../../components/Selectbox/Selectbox';
import { ORDER_STATUS } from "./constants";
import { ucFirst } from "../../helpers/utils";

let StatusOptions = [{
  label: 'Status',
  value: '',
}];
Object.entries(ORDER_STATUS).forEach(([key, value]) => {
  StatusOptions.push({
    label: ucFirst(value),
    value: value,
  });
});

const LogFilter = ({ loading, paging, mergeFilters, user, initStart, initEnd }) => {

  const [filters, setFilters] = useState({
    startDate: initStart,
    endDate: initEnd,
    videoId: '',
    sellerId: user ? user.userId : '',
    buyerId: '',
    status: '',
    reload: false,
  });

  const handleDateChange = (start, end) => {
    setFilters({ ...filters, startDate: start, endDate: end });
  }

  const handleSellerChange = (option) => {
    setFilters({ ...filters, sellerId: option ? option.value : '' });
  }

  const handleBuyerChange = (option) => {
    setFilters({ ...filters, buyerId: option ? option.value : '' });
  }

  const handleStatusChange = (option) => {
    if (option && option.value) setFilters({ ...filters, status: option.value, reload: true });
    else setFilters({ ...filters, status: '', reload: true });
  }

  const handleVideoChange = (option) => {
    setFilters({ ...filters, videoId: option ? option.value : '' });
  }

  const submitFilter = () => {
    // console.log(mergeFilters);
    mergeFilters({ ...filters, reload: true });
  }

  return (
    <>
      <Row className={classNames('video-review-filter')} style={{ transition: 'visibility 0.5s, opacity 0.5s linear' }}>
        <Col lg="12" md="12" xs="12" className="">
          <div className="item-filter float-right">
            <div className="d-flex align-items-center">

              <div className="d-none">
                <label htmlFor="" className="mb-0" style={{ width: '50px' }}>Seller</label>
                <div className="d-inline-block ml-2 item-filter__control" style={{ width: '200px' }}>
                  <SearchUserInput
                    onChange={handleSellerChange}
                    className="w-100"
                    isClearable={true}
                    name="sellerId"
                  />
                </div>
              </div>
              <div className="">
                <label htmlFor="" className="mb-0" style={{ width: '50px' }}>Buyer</label>
                <div className="d-inline-block ml-2 item-filter__control" style={{ width: '240px' }}>
                  <SearchUserInput
                    onChange={handleBuyerChange}
                    className="w-100"
                    isClearable={true}
                    name="buyerId"
                  />
                </div>
              </div>
              <div className="d-flex ml-3">
                <label htmlFor="" className="mb-0 mt-1">Status</label>
                <div className="ml-2 " style={{ width: '140px' }}>
                  <Selectbox
                    className="w-100"
                    name="search_status"
                    placeholder="Select status"
                    options={StatusOptions}
                    onChange={handleStatusChange}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center">
                <label htmlFor="" className="mb-0 mr-2">Time</label>
                <DRP onChange={handleDateChange}
                  showApplyButton={false}
                  initStart={filters.startDate}
                  initEnd={filters.endDate}
                />
              </div>
              <div className="ml-2">
                <Button
                  className="ml-2"
                  size=""
                  disabled={loading}
                  onClick={(e) => submitFilter(e)}
                  style={{ minWidth: '86px' }}
                >
                  <Icon.Search style={{ width: '15px', height: '15px', marginTop: '-2px' }} /> Search
                </Button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default React.memo(LogFilter);