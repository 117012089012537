import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { FormGroup, Label } from "reactstrap";
import AsyncSelect from 'react-select/async';
import PropTypes from "prop-types";

import { searchUsersAction }  from "../../redux/reducers/userReducer";
import { startActionWithPromise } from "../../helpers/saga-promise-helpers";

const selectStyles = {
  container: provided => (
    { ...provided, 
      width: '230px'
    }),
  control: provided => (
    { ...provided, 
      borderWidth: '0px', 
      minHeight:'31px', 
      borderRadius: '2px', 
      boxShadow: '0 0 0 1px #ced4da',
      "&:hover": {
        borderColor: '#ced4da'
      }
    }),
  option: (provided, state) => {
    return {
      ...provided,
      backgroundColor: state.isSelected ? "#86C9CF" : provided.backgroundColor,
      "&:active": {
        backgroundColor: "#86C9CF"
      },
      padding: '3px 8px'
    }
  },
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '4px'
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '1px 8px'
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: '4px'
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: '3',
    borderRadius: '2px',
  }),
};

const successCallback = () => {
  
}
const failedCallback = () => {

}

const SearchUserInput = ({onChange, ...rest}) => {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState(false);

  const loadUsersOptions = async (inputValue) => {
    try {
      // await delay(5000);
      const data = await startActionWithPromise(
        searchUsersAction,
        { params: {keywords: inputValue, pageSize: 20, pageNumber: 0}, successCallback, failedCallback },
        dispatch
      );

      const list = data.content?.filter(user => user.active).map(item => ({
        label: `${item.firstName} ${item.lastName} (${item.username})`,
        value: item.userId
      })) || [];
      return list;
    } catch (err) {
    }
    return [];
  }

  const handleInputChange = (inputValue) => {
    // console.log(inputValue);
  }

  const onOptionChange = (option) => {
    setSelected(option);
    if (onChange) onChange(option);
  }

  return (
    <>
      <AsyncSelect
          styles={selectStyles}
          name="mm_user"
          // cacheOptions
          defaultOptions
          isSearchable
          value={selected ? selected : false}
          loadOptions={loadUsersOptions}
          placeholder=""
          onInputChange={handleInputChange}
          onChange={(option) => onOptionChange(option)}
          {...rest}
        />
    </>
  );
}

export default SearchUserInput;