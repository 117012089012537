import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  FormGroup,
  Label,
} from "reactstrap";
import _ from "lodash";
import * as Icon from "react-feather";

import PropTypes from "prop-types";

import { roleActions } from "../../../redux/reducers/domainRoleReducer";
import { startActionWithPromise } from "../../../helpers/saga-promise-helpers";
import Selectbox from "../../../components/base/Selectbox";
import useMergeState from "../../../hooks/mergeState";

const propTypes = {
  row: PropTypes.object,
  modal: PropTypes.bool,
  toggle: PropTypes.func,
};

const defaultProps = {
  row: {
    id: 0,
  },
  modal: false,
  toggle: () => {},
};

const COMMON_ACIONS = ['READ', 'CREATE', 'WRITE', 'DELETE'];
const SORTED_ACTIONS = [
  'READ',
  'CREATE',
  'WRITE',
  'DELETE',
  'PUBLISH',
  'LOCK',
  'UNLOCK',
  'REDISTRIBUTE',
  'WITHDRAW',
];
const COLUMNS = [
  { name: 'READ', width: '50px' },
  { name: 'CREATE', width: '60px' },
  { name: 'WRITE', width: '53px' },
  { name: 'DELETE', width: '60px' },
  { name: 'PUBLISH', width: '81px' },
  { name: 'LOCK', width: '73px' },
  { name: 'UNLOCK', width: '79px' },
  { name: 'REDISTRIBUTE', width: '120px' },
  { name: 'WITHDRAW', width: '105px' },
];

const ANY_ACTION = 'ANY';

export const getSortPermssions = perms => {
  const sorted = _.sortBy(perms, function (item) {
    return SORTED_ACTIONS.indexOf(item.action?.toUpperCase());
  });

  return sorted;
};

const havePermChecked = (permissions, group_perms) => {
  return _.find(group_perms, (p) => {
      return _.find(permissions, {id: p.id});
    }
  )  ? true : false;
}

const getPermGroupColumns = perms => {
  const columns = [];
  _.forEach(COLUMNS, col => {
    const perm = perms.find(x => x.action === col.name) || false;
    columns.push({ ...col, perm });
  });
  let anyAction = null;
  _.forEach(perms, perm => {
    const item = columns.find(x => x.name === perm.action) || false;
    if (perm.action === ANY_ACTION) anyAction = { name: perm.action, width: 'auto', perm };

    if (!item && perm.action !== ANY_ACTION) columns.push({ name: perm.action, width: 'auto', perm });
  });

  if (anyAction) columns.push(anyAction);
  return columns;
};

const RoleUpdate = ({ modal, row: currentRole, toggle, refreshData }) => {
  const dispatch = useDispatch();

  const rowAddObject = useRef();

  // selectors
  const updating = useSelector(state => state.domainPermissions.updating);
  const permissions = useSelector(state => state.domainPermissions.permissions);
  const roleGroups = useSelector(state => state.domainRoleGroups.groups);
  const domains = useSelector(state => state.domain.domains);

  const submitButton = useRef(null);

  const { name='', description='', group, enabled=true, weight=0 } = currentRole || {};
  const [addGroup, setAddGroup] = useState(null);

  const [pristine, setPristine] = useState(true);
  const [messages, setMessages] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [groupList, setGroupList] = useState([]);
  const [errors, setErrors] = useState(null);

  const [formState, setFormState] = useMergeState({
    id: currentRole.id || 0,
    enabled: enabled,
    name: name,
    weight: weight,
    description: description,
    group: group,
    permissions: currentRole.permissions || [],
  });

  /**
   * prepare permission list by group
   */
  const mapGroupListData = () => {
    let groupList = [];
    const groups = _.groupBy(permissions, "type");
    if (groups) {
      _.forOwn(groups, function (gr, key) {
        if (gr) {
          groupList.push({
            type: key,
            perms: _.sortBy(gr, ["action"]),
          });
        }
      });
    }
    groupList = groupList ? _.sortBy(groupList, ["type"]) : [];

    /* Group permissions by common actions */
    groupList = groupList.map(gr => {
      // checked permissions
      let perms = gr.perms.map(perm => {
        let checked = _.find(currentRole.permissions, {id: perm.id});
        return {
          ...perm,
          checked: checked ? true : false
        }
      });
      return {
        ...gr,
        perms: getSortPermssions(perms),
        columns: getPermGroupColumns(gr.perms),
        show: havePermChecked(currentRole.permissions, gr.perms)
      }
    });

    setGroupList(groupList);
    /* End Group permissions by common actions */
  }

  const getDomainName = type => {
    const domain = domains.find(x => x.name === type);
    return domain ? domain.text : type;
  };

  const handleToggle = () => {
    if (modal) {
      // reset state when close modal
      setMessages([]);
    }
    if (toggle) toggle("modalUpdate");
  };

  const handleSelectGroup = (option) => {
    // const group = option && groupList.find(x => x.type === option.value);
    setAddGroup(option);
  }

  const handleAddGroup = () => {
    if (!addGroup) return;

    let list = groupList.map(group => ({
      ...group,
      show: addGroup.value === group.type ? true : group.show
    }));
    setGroupList(list);
    setAddGroup(false);

    if (rowAddObject.current) {
      setTimeout(() => {
        rowAddObject.current.scrollIntoView({ behavior: 'smooth' });
      }, 500);
    }
  }

  const handleHideGroup = (type) => {
    let list = groupList.map(group => ({
      ...group,
      show: type === group.type ? false : group.show
    }));
    setGroupList(list);
    setPristine(false);
  }

  const handleCheckedChange = (item) => {
    setPristine(false);
    let isChecked = formState.permissions.find(x => x.id === item.id);
    let checkedList = isChecked ? formState.permissions.filter(x => x.id !== item.id) : [...formState.permissions, item];

    if (!isChecked && item.action === ANY_ACTION) {
      // uncheck other perms at same group
      let filterOuts = checkedList.filter(x => x.action !== ANY_ACTION && x.type === item.type);
      checkedList = checkedList.filter(x => !filterOuts.find(item => item.id === x.id));
    }

    // uncheck ANY if check others
    let hasAnyInGroup = checkedList.find(x => x.type === item.type && x.action === ANY_ACTION);
    if (hasAnyInGroup && item.action !== ANY_ACTION && !isChecked) {
      checkedList = checkedList.filter(x => x.id !== hasAnyInGroup.id);
    }

    setFormState({permissions: checkedList});
  }

  const triggerSubmitForm = () => {
    if (submitButton.current) submitButton.current.click();
  };

  
  const handleChangeInput = (e) => {
    let inputValue = e.target.type === 'number' ? parseInt(e.target.value) : e.target.value;
    setFormState({[e.target.name]: inputValue});
    setPristine(false);
  }

  const validation = () => {
    const validateErrors = [];
    if (!formState.name) {
      validateErrors.push("Please input role name.");
    } else if (!formState.group) {
      validateErrors.push("Please choose a group.");
    }

    return validateErrors;
  }

  const successCallback = () => {
    if (refreshData) refreshData();
    handleToggle();
  };

  const failedCallback = () => {};

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    // check for roles change
    const validateErrors = validation();

    if (validateErrors.length) {
      setMessages(validateErrors);
      return;
    }

    setWaiting(true);
    try {
      if (formState.id) {
        await startActionWithPromise(
          roleActions.update,
          {
            id: formState.id,
            data: formState,
            successCallback,
            failedCallback,
          },
          dispatch
        );
      } else {
        await startActionWithPromise(
          roleActions.create,
          {
            data: formState,
            successCallback,
            failedCallback,
          },
          dispatch
        );
      }
    } catch {}
    setWaiting(false);
    return false;
  };

  useEffect(() => {
    if (modal) {
      mapGroupListData();
    }
  }, [modal]);

  return (
    <Modal isOpen={modal} toggle={handleToggle} centered size="lg">
      <ModalHeader toggle={handleToggle}>
        {currentRole && currentRole.id ? "Update Role" : "Add Role"}
      </ModalHeader>
      <ModalBody className="">
        <form onSubmit={handleSubmitForm} id="form-permission">
          <Row>
            <Col lg="12">
              <FormGroup>
                <FormGroup check inline className="">
                  <Label check>
                    <input
                      className="form-check-input"
                      name="enabled"
                      type="checkbox"
                      checked={formState.enabled}
                      onChange={() => setFormState({enabled: !formState.enabled})}
                    />
                    Enabled
                  </Label>
                </FormGroup>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <FormGroup>
                <Label for="name">Name</Label>
                <input
                  className="form-control"
                  name="name"
                  type="text"
                  required
                  value={formState.name||''}
                  onChange={handleChangeInput}
                />
                {errors && errors.name && (
                  <div className="invalid-feedback">
                    Please input role name.
                  </div>
                )}
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <FormGroup>
                <Label for="name">Description</Label>
                <textarea
                  className="form-control"
                  name="description"
                  type="text"
                  rows="3"
                  value={formState.description||''}
                  onChange={handleChangeInput}
                >
                </textarea>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <FormGroup>
                <Label for="weight">Weight</Label>
                <input
                  className="form-control"
                  name="weight"
                  type="number"
                  min="0"
                  value={formState.weight}
                  onChange={handleChangeInput}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <FormGroup>
                <div>
                  <Label className="font-weight-bold" for="domain">
                    Group
                  </Label>
                </div>
                {roleGroups &&
                  roleGroups.map(item => (
                    <FormGroup
                      check
                      inline
                      className="mr-4"
                      key={`role-group--${item.id}`}
                    >
                      <Label check>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="groupId"
                          value={item.id}
                          checked={formState.group?.id == item.id}
                          onChange={() => setFormState({group: item})}
                          style={{verticalAlign: 'middle'}}
                        />
                        {item.name}
                      </Label>
                    </FormGroup>
                  ))}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <FormGroup>
                <div>
                  <Label className="font-weight-bold" for="domain">
                    Permissions
                  </Label>
                </div>

                {groupList &&
                  groupList.map(permGroup => permGroup.show && (
                    <div
                      className="perm-group mb-3"
                      key={`perm-group--${permGroup.type}`}
                    >
                      <div className="perm-group__name font-weight-bold">
                        {getDomainName(permGroup.type)}
                        <span
                          className="ml-2"
                          style={{cursor: 'pointer'}}
                          title={`Remove`}
                          onClick={() => handleHideGroup(permGroup.type)}
                          ><Icon.Delete width="15" /></span>
                      </div>
                      <div className="perm-group-items mt-1">
                        {permGroup.columns &&
                          permGroup.columns.map((column, index) =>  (
                            <React.Fragment
                              key={`perm-item-wrap--${currentRole.id}-${index}`}
                            >
                              {column.perm ? (
                                <FormGroup
                                  check
                                  inline
                                  className="mr-4"
                                  key={`perm-item--${column.perm.id}`}
                                >
                                  <Label check>
                                    <input
                                      className="form-check-input"
                                      name="permissions"
                                      type="checkbox"
                                      // defaultChecked={_.find(
                                      //   currentRole.permissions,
                                      //   {
                                      //     id: column.perm.id,
                                      //   }
                                      // )}
                                      value={column.perm.id}
                                      checked={_.find(formState.permissions, {id: column.perm.id}) || false}
                                      onChange={() => handleCheckedChange(column.perm)}
                                      // ref={register}
                                    />
                                    <span>
                                    {_.upperFirst(
                                      column.perm.action?.toLowerCase()
                                    )}
                                    </span>
                                  </Label>
                                </FormGroup>
                              ) : (
                                <>
                                  {COMMON_ACIONS.indexOf(column.name) !==
                                    -1 && (
                                    <FormGroup
                                      check
                                      inline
                                      className="mr-4"
                                      key={`perm-item--empty-${currentRole.id}-${index}`}
                                      style={{
                                        width:
                                          COMMON_ACIONS.indexOf(column.name) !==
                                          -1
                                            ? column.width
                                            : "",
                                      }}
                                    />
                                  )}
                                </>
                              )}
                            </React.Fragment>
                          ))}
                      </div>
                    </div>
                  ))}
              </FormGroup>
            </Col>
          </Row>
          <hr/>
          <Row id="row--add-object">
            <Col lg="12">
              <FormGroup>
                <div>
                  <Label className="font-weight-bold" for="domain">
                    Add object to permissions
                  </Label>
                </div>

                <div className="d-flex">
                  <Selectbox
                      options={groupList.filter(x => x.show === false).map(x => ({
                        label: getDomainName(x.type),
                        value: x.type
                      }))}
                      value={addGroup?addGroup:''}
                      isClearable={true}
                      placeholder=""
                      onChange={handleSelectGroup}
                      menuPlacement="top"
                      className="w-50"
                    />
                  <Button
                    className="d-inline ml-2 border"
                    // disabled={pristine}
                    color="light"
                    type="button"
                    onClick={handleAddGroup}
                    title="Add to permissions"
                    style={{padding:'3px 10px'}}
                  >
                    <Icon.ArrowUp width="15" />
                  </Button>
                </div>
              </FormGroup>
            </Col>
          </Row>
          <Button
            className="d-none"
            disabled={pristine}
            type="submit"
            innerRef={submitButton}
          >
            Submit
          </Button>
          <div id="bottom_nail" ref={rowAddObject}></div>
        </form>
      </ModalBody>
      <ModalFooter>
        <div></div>
        <div className="">
          {messages &&
            messages.map((error, i) => (
              <span key={`errors-${i}`} className="text-danger mb-0 mr-1">
                {error}
              </span>
            ))}
        </div>
        <Button color="secondary" disabled={waiting} onClick={handleToggle}>
          Cancel
        </Button>
        <Button
          className="ml-2"
          color="primary"
          disabled={pristine || updating}
          onClick={() => triggerSubmitForm(true)}
        >
          Save changes
        </Button>
      </ModalFooter>
    </Modal>
  );
};

RoleUpdate.propTypes = propTypes;
RoleUpdate.defaultProps = defaultProps;

export default RoleUpdate;
