import React from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import PropTypes from "prop-types";

const propTypes = {
  current: PropTypes.number,
  show: PropTypes.number,
  totalPages: PropTypes.number,
  onSelect: PropTypes.func,
};

const Paging = props => {
  // let [current, setCurrent] = useState(props.current || 1);
  const { current } = props;

  const gotoPage = (p, e) => {
    e.preventDefault();
    if (props.onSelect) props.onSelect(p);
  };

  const show = props.show ? parseInt(props.show) : 3; // default show 3 items before and after current page
  const totalPages = parseInt(props.totalPages);
  const Pages = [];
  const start = current - show > 0 ? current - show : 1;
  const end = current + show > totalPages ? totalPages : current + show;

  for (let i = start; i <= end; i++) {
    Pages.push(
      <PaginationItem key={i} active={current === i}>
        <PaginationLink href="#" onClick={e => gotoPage(i, e)}>
          {i}
        </PaginationLink>
      </PaginationItem>
    );
  }

  return (
    <>
      {totalPages > 1 && (
        <Pagination aria-label="pagination" size="sm">
          <PaginationItem disabled={current === 1}>
            <PaginationLink
              previous
              href="#"
              onClick={e => gotoPage(current - 1, e)}
            />
          </PaginationItem>
          {Pages}
          <PaginationItem disabled={current === totalPages}>
            <PaginationLink
              next
              href="#"
              onClick={e => gotoPage(current + 1, e)}
            />
          </PaginationItem>
        </Pagination>
      )}
    </>
  );
};

Paging.propTypes = propTypes;

export default Paging;
