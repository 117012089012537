import React, { useState, useRef } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Spinner,
} from "reactstrap";

const UnlockForm = props => {
  const emailInput = useRef(null);
  const passwordInput = useRef(null);

  const { logining, message } = props || false;

  const last_username = localStorage.getItem("last_username");

  const [username, setUsername] = useState(last_username);
  const [password, setPassword] = useState("");

  const handleSubmit = e => {
    e.preventDefault();

    if (!username) {
      if (emailInput.current) emailInput.current.focus();
      return;
    }
    if (!password) {
      if (passwordInput.current) passwordInput.current.focus();
      return;
    }

    if (props.onSubmit) props.onSubmit(username, password, false);
  };

  const hangeChange = e => {
    const { name, value } = e.target;
    if (name === "email") setUsername(value);
    else if (name === "password") setPassword(value);
  };

  // mounted hook
  React.useEffect(() => {
    if (localStorage.username) setUsername(localStorage.username);
  }, []);

  return (
    <Form onSubmit={handleSubmit} className="login-form" method="post">
      <FormGroup>
        <Label>Username</Label>
        <Input
          bsSize="lg"
          type="text"
          name="email"
          placeholder="Enter your username"
          value={username}
          onChange={hangeChange}
          innerRef={emailInput}
          required
        />
      </FormGroup>
      <FormGroup>
        <Label>Password</Label>
        <Input
          bsSize="lg"
          type="password"
          name="password"
          placeholder="Enter your password"
          value={password}
          onChange={hangeChange}
          innerRef={passwordInput}
          required
        />
      </FormGroup>
      {message && (
        <div
          className="text-danger text-center font-weight-bold"
          role="alert"
          style={{ padding: "0rem" }}
        >
          {message}
        </div>
      )}
      <div className="text-center mt-3">
        <Button
          className="btn-submit"
          data-test="btn-submit"
          color="primary"
          size="lg"
          disabled={logining}
        >
          Unlock
          {logining && <Spinner color="secondary" size="sm" className="ml-2" />}
        </Button>
      </div>
    </Form>
  );
};

export default UnlockForm;
