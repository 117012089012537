import {
  createReducer,
  createActionResources,
} from "redux-waiters";

import { RESET_REDUCER } from "../constants";

export const retranscodeAction = createActionResources("video/retranscode-video");
export const getVideoInfoAction = createActionResources("video/detail");
export const withdrawAction = createActionResources("video/withdraw-video");


const initialState = {
  loading: false,
};

export default createReducer(
  {
    [retranscodeAction.start]: state => {
      return { ...state, loading: true };
    },
    [retranscodeAction.success]: (state, action) => {
      return {
        ...state,
        loading: false,
      };
    },
    [retranscodeAction.error]: state => {
      return { ...state, loading: false };
    },

    [RESET_REDUCER]: () => initialState,
  },
  initialState
);
