import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Spinner
} from "reactstrap";
import classNames from "classnames";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";

import DataTable from "../../components/DataTable";
import PageSizeSelector from "../../components/base/PageSizeSelector";
import Paging from "../../components/base/Paging";
import config from "../../config/config";
import useMergeState from '../../hooks/mergeState';
import { useAuth } from "../../hooks/useAuth";
import { toDateString } from "../../helpers/utils";

import FormFilter from "./FormFilter";

import { searchFundsTransfersAction } from "../../redux/reducers/subscriptionReducer";
import { startActionWithPromise } from "../../helpers/saga-promise-helpers";
import "./index.scss";
import { getTableColumns } from "./TableColumns";

const FundsTransfers = ({user}) => {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    modalConfirm: false,
    action: '',
    message: '',
    row: null,
  });

  const defaultFilters = {
    pageSize: config.DEFAULT_PAGESIZE,
    pageNumber: 1,
    startYearMonth: '',
    endYearMonth: '',
    userId: user ? user.userId : '',
    status: '',
    transferDate: '',
    transactionReference: '',
    sort: {
      dir: `desc`,
      fieldName: `transferDate`,
    },
    reload: false,
  };
  const [filters, setFilters] = useMergeState(defaultFilters);

  const [waiting, setWaiting] = useState(false);
  
  const auth = useAuth();
  const isAuthorized = auth.roles &&
    (auth.roles.indexOf('SYSTEM_ADMINS') !== -1 ||
    auth.roles.indexOf('SYSTEM_OPERATORS') !== -1);
  const fetching = false;
  const [transfers, setTransfers] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const isBoAdmin = useSelector(state => state.bouser.isBoAdmin);

  const numItems = totalItems > filters.pageSize ? filters.pageSize : totalItems;
  const beginItem = numItems * (filters.pageNumber - 1);
  const endItem = numItems * filters.pageNumber;

  const onSizeChange = (size) => {
    setFilters({ ...filters, pageSize: size, pageNumber: 1, reload: true });
  };

  const onPageChange = (p) => {
    setFilters({ ...filters, pageNumber: p, reload: true });
  };

  const handleColumnSort = (field, order) => {
    setFilters({
      ...filters,
      sort: {
        dir: order,
        fieldName: field,
      },
      reload: true,
    });
  };

  const tableColumns = getTableColumns(handleColumnSort, isBoAdmin, state, setState);

  const refreshDatatable = () => {
    setFilters({ ...filters, reload: true });
  };

  const dateToYearMonth = (date) => {
    if (!date) return;
  
    return parseInt(`${date.getFullYear()}${_.padStart(date.getMonth() + 1, 2, 0)}`);
  }

  const fetchFundsTransfers = () => {
    (async () => {
      try {
        // setWaiting(true);
        let params = {
          ...filters,
          pageNumber: filters.pageNumber - 1,
          sort: {
            dir: filters.sort.dir.toUpperCase(),
            fieldName: filters.sort.fieldName,
          },
        };
        if ( params.startYearMonth ) {
          params.startYearMonth = dateToYearMonth(params.startYearMonth);
        }
        if ( params.endYearMonth ) {
          params.endYearMonth = dateToYearMonth(params.endYearMonth);
        }
        if ( params.transferDate ) {
          params.transferDate = toDateString(params.transferDate);
        }
        if ( !params.transactionReference || !params.transactionReference.trim() ) {
          delete params.transactionReference;
        } else {
          params.transactionReference = params.transactionReference.trim();
        }
        delete params.reload;

        if (!filters.userId) delete params.userId;
        if (!filters.status) delete params.status;

        const resp = await startActionWithPromise(
          searchFundsTransfersAction,
          {params, successCallback: () => {}, failedCallback: () => {}},
          dispatch
        );
        setTransfers(resp.content);
        setTotalItems(resp.total);
        setTotalPages(Math.ceil(resp.total / resp.pageSize) || 0);
        setPageNumber(resp.pageNumber + 1 || 1);

      } catch {}
      setWaiting(false);
      // setFilters({ ...filters, reload: false });
    })();
  };

  useEffect(() => {
    if (filters.reload) {
      fetchFundsTransfers();
      setFilters({ ...filters, reload: false });
    }
  }, [filters.reload]);

  useEffect(() => {
    fetchFundsTransfers();
  }, []);

  ////////// Table's action handlers ///////////
  
  ////////////////////

  return (
    <Container fluid className="p-0">
      <h1 className="page-title">Funds Transfers</h1>
      <Row>
        <Col lg="12" className="d-flex">
        <>
          <Card className={classNames('w-100 mb-0 datatable-wrap')}>
            <CardHeader className="">
              <FormFilter
                loading={fetching}
                filters={filters}
                mergeFilters={setFilters}
                paging={{
                  beginItem,
                  endItem,
                  totalItems
                }}
                user={user}
              />
            </CardHeader>
            <CardBody className="pt-0 data-list">
              { waiting ? (
                <div className="loading-content text-center p-4 border-top">
                  <Spinner size="sm" />
                </div>
              ) : (
                <>
                  <DataTable
                    keyField="id"
                    data={transfers}
                    columns={tableColumns}
                    order={filters.sort?.dir}
                    sort={filters.sort?.fieldName}
                    hideSelectColumn={true}
                  />
                  <Row className="mt-3">
                    <Col lg="3" md="4" className="d-flex">
                      <PageSizeSelector size={filters.pageSize} onChange={onSizeChange} />
                    </Col>
                    <Col lg="9" md="8" className="d-flex justify-content-end">
                      <Paging totalPages={totalPages} current={pageNumber} show={5} onSelect={onPageChange} />
                    </Col>
                  </Row>
                </>
              )}
            </CardBody>
          </Card>
        </>
        </Col>
      </Row>
    </Container>
  );
};

export default FundsTransfers;
