import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import PropTypes from "prop-types";
import config from "../config/config";
import UnlockForm from "./UnlockForm";
import { useAuth } from "../hooks/useAuth";
import { INVALID_LOGIN } from "../config/messages";

const propTypes = {
  modal: PropTypes.bool,
  handleClose: PropTypes.func,
  handleLogout: PropTypes.func,
  remainingTime: PropTypes.string,
};

const defaultProps = {
  modal: false,
  handleClose: () => {},
  handleLogout: () => {},
  remainingTime: "",
};

const DEFAULT_REMAIN_TIME = config.SESSION_TIMEOUT * 60;

const secondsToTime = (secs) => {
  let hours = Math.floor(secs / (60 * 60));

  let divisor_for_minutes = secs % (60 * 60);
  let minutes = Math.floor(divisor_for_minutes / 60);

  let divisor_for_seconds = divisor_for_minutes % 60;
  let seconds = Math.ceil(divisor_for_seconds);

  let result = {
    "h": hours,
    "m": minutes,
    "s": seconds
  };
  return result;
}

const zeroPad = (num, places) => String(num).padStart(places, '0');

const IdleTimeoutModal = ({
  modal,
  handleClose,
  handleLogout,
}) => {
  const auth = useAuth();

  const [logining, setLogining] = useState(false);
  const [message, setMessage] = useState("");

  let [eslapsedTime, setEslapsedTime] = useState(DEFAULT_REMAIN_TIME);
  let [showUnlock, setShowUnlock] = useState(false);

  const timeRemain = secondsToTime(eslapsedTime);

  const getTimeLeft = () => {
    if (eslapsedTime === 0 || showUnlock) return 0;

    setTimeout(() => {
      const remained = eslapsedTime ? eslapsedTime - 1 : 0;
      setEslapsedTime(remained);
    }, 1000);
    return eslapsedTime;
  }

  const login = (username, password) => {
    setLogining(true);

    auth.signin(username, password).then(res => {
      setLogining(false);
      if (res.user) {
        setShowUnlock(false);
        handleClose();
      } else if (res.error) {
        if (res.error.status === 401) setMessage(INVALID_LOGIN);
        else if (res.error?.headers["x-message-info"]) {
          setMessage(res.error?.headers["x-message-info"]);
        }
      }
    });
  };

  const handleUnlock = () => {
    // seconds = UNLOCK_REMAIN_TIME;
    // startCountdown();
    setEslapsedTime(DEFAULT_REMAIN_TIME);
    setShowUnlock(true);
  }

  useEffect(() => {
    if (!modal) return;

    getTimeLeft();

    if (eslapsedTime === 0) {
      setEslapsedTime(DEFAULT_REMAIN_TIME);
      handleLogout();
    }
  }, [eslapsedTime]);

  useEffect(() => {
    if (modal) {
      getTimeLeft();
      auth.getLocked(); // set locked status
    }
  }, [modal]);

  return (
    <Modal
      isOpen={modal}
      centered
      size="sm"
      className="comfirmation-modal lock-modal"
      backdropClassName="lock-modal--backdrop"
    >
      <ModalBody className="m-3">
        <div className={showUnlock?'countdown d-none':'countdown'}>
          <div className="text-center">
            <p className="font-weight-bold mb-1">You have been idle!</p>
            <p className="font-weight-bold mb-1">Your session will be time out in {zeroPad(timeRemain.m,2)}:{zeroPad(timeRemain.s,2)}</p>
            {/* <p className="font-weight-bold">Do you want to stay?</p> */}
          </div>
          <div className="actions mt-4 text-center">
            <Button color="secondary" onClick={handleUnlock}>
              Unlock
            </Button>
          </div>
        </div>
        <div className={showUnlock?'unlock-wrap':'unlock-wrap d-none'}>
          {/* <div className="text-center font-weight-bold">Enter your password to unlock!</div> */}
          <div className="unlock-form mt-4">
            <UnlockForm onSubmit={login} logining={logining} message={message} />
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

IdleTimeoutModal.propTypes = propTypes;
IdleTimeoutModal.defaultProps = defaultProps;

export default IdleTimeoutModal;
