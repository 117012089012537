import React from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

import { ucFirst } from "../../helpers/utils";
import SettingList from "./SettingList";

const Setting = () => {
  const { service } = useParams();

  return (
    <Container fluid className="p-0">
      <h1 className="page-title">{`${ucFirst(service)} Settings`}</h1>
      <Row>
        <Col lg="12" className="d-flex">
          <SettingList serviceName={service} />
        </Col>
      </Row>
    </Container>
  );
};

export default Setting;
