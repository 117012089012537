import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import Select from 'react-select';

const propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  onChange: PropTypes.func
}

const defaultProps = {
  startDate: '',
  endDate: '',
  onChange: () => { },
}

const selectStyles = {
  control: provided => (
    {
      ...provided,
      minHeight: '30px',
      borderRadius: '2px',
      borderWidth: '1px',
      borderColor: '#ced4da',
      // boxShadow: '0 0 0 1px #ced4da',
      boxShadow: 'none',
      "&:hover": {
        borderColor: '#ced4da'
      },
      paddingLeft: '25px',
      minWidth: '155px'
    }),
  option: (provided, state) => {
    return {
      ...provided,
      backgroundColor: state.isSelected ? "#86C9CF" : provided.backgroundColor,
      "&:active": {
        backgroundColor: "#86C9CF",
      },
      color: state.isSelected ? '#FFFFFF' : provided.color,
      padding: '3px 8px',
      textAlign: 'left'
    }
  },
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '3px'
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0px 8px'
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: '3px'
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: '3',
    borderRadius: '2px',
  }),
};



const getOptions = () => {
  const Today = `${moment().format('MM/DD/YYYY')}-${moment().format('MM/DD/YYYY')}`;
  const Yesterday = `${moment().subtract(1, 'days').format('MM/DD/YYYY')}-${moment().subtract(1, 'days').format('MM/DD/YYYY')}`;
  const Last7Days = `${moment().subtract(7, 'days').format('MM/DD/YYYY')}-${moment().format('MM/DD/YYYY')}`;
  const Last30days = `${moment().subtract(30, 'days').format('MM/DD/YYYY')}-${moment().format('MM/DD/YYYY')}`;
  const LastMonth = `${moment().subtract(1, 'month').startOf('month').format('MM/DD/YYYY')}-${moment().subtract(1, 'month').endOf('month').format('MM/DD/YYYY')}`;
  const ThisMonth = `${moment().startOf('month').format('MM/DD/YYYY')}-${moment().format('MM/DD/YYYY')}`;
  return [
    {
      label: 'Today',
      value: Today
    },
    {
      label: 'Yesterday',
      value: Yesterday
    },
    {
      label: 'Last 7 days',
      value: Last7Days
    },
    {
      label: 'Last 30 days',
      value: Last30days
    },
    {
      label: 'Last month',
      value: LastMonth
    },
    {
      label: 'This month',
      value: ThisMonth
    },
    {
      label: 'Custom',
      value: 'custom',
      isDisabled: true
    },
  ]
};

const Ranges = ({ startDate, endDate, onChange }) => {
  const dateRange = `${startDate}-${endDate}`;
  const options = getOptions();
  const initOpt = options.find(x => x.value === dateRange) || options[options.length - 1];  
  const [selected, setSelected] = useState(initOpt);

  const handleChange = (option) => {
    setSelected(option);
    if (option.value === 'custom') return;

    const tmp = option.value.split('-');
    if (tmp.length > 1 && onChange) onChange(tmp[0], tmp[1]);
  }

  useEffect(() => {
    const opt = options.find(x => x.value === dateRange) || options[options.length - 1];
    setSelected(opt);
  }, [startDate, endDate]);

  return (
    <div className="drp-ranges d-inline-block mr-2">
      <Select
        name="ranges"
        options={options}
        value={selected}
        styles={selectStyles}
        placeholder=""
        onChange={handleChange}
      />
    </div>
  )
}

Ranges.propTypes = propTypes;
Ranges.defaultProps = defaultProps;

export default Ranges;