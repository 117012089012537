import React, { useEffect, useState, useRef, useCallback } from "react";
import { Row, Col, Button, Label } from "reactstrap";
import * as Icon from "react-feather";
import classNames from "classnames";

import DebounceInput from "../../components/DebounceInput";
import { isUUID } from "../../helpers/utils";
import Select from "react-select";


import COUNTRY_OPTIONS from "../../config/countries";

const EMPTY_COUNTRY_LABEL = '[EMPTY]';
const EMPTY_COUNTRY_VALUE = 'NONE';
const COUNTRIES = [{ value: EMPTY_COUNTRY_VALUE, label: EMPTY_COUNTRY_LABEL }].concat(COUNTRY_OPTIONS);

const ACTIVATION_STATES = [
  { value: 'ACTIVE', label: 'Activated' },
  { value: 'INACTIVE', label: 'Un-activated' },
  { value: 'BOTH', label: 'Both' },
];

const USER_STATES = [
  { value: 'ENABLED', label: 'Enabled' },
  { value: 'DISABLED', label: 'Disabled' },
  { value: 'BOTH', label: 'Both' },
]

const ONLINE_STATES = [
  { value: 'ONLINE', label: 'Online' },
  { value: 'OFFLINE', label: 'Offline' },
  { value: 'BOTH', label: 'Both' },
]

const FormFilter = ({ onSubmitFilter, loading, onToggleShow, paging }) => {
  var userIdInput = useRef(null);
  var keywordInput = useRef(null);

  const [cancelDebounce, setCancelDebounce] = useState(false);
  const [validateErrors, setValidateErrors] = useState({});

  const [filters, setFilters] = useState({
    userId: '',
    keywords: '',
    deleted: false,
    activationStatus: '',
    userStatus: '',
    onlineStatus: '',
    countries: [],
    reload: false
  });


  const submitFilter = () => {
    if (validateErrors && validateErrors.userId) {
      if (userIdInput.current) userIdInput.current.focus();

      return;
    }
    setCancelDebounce(true);
    if (onSubmitFilter) onSubmitFilter(filters);
  };

  useEffect(() => {
    if (filters.reload) {
      submitFilter();
      setFilters({ ...filters, reload: false });
    }
  }, [filters.reload]);


  const handleUserIdChange = (value, name, target) => {
    if (value && !isUUID(value)) {
      setValidateErrors({
        ...validateErrors,
        userId: 'Please enter user id in uuid format.'
      });
      if (target) target.setCustomValidity('Please enter user id in uuid format.');
    } else {
      let errors = Object.assign({}, validateErrors);
      delete errors.userId;

      setValidateErrors({
        ...errors
      });
    }

    setFilters({ ...filters, userId: value });
    setCancelDebounce(false);
  }

  const handleUserIdSubmit = (userId) => {
    setFilters({ ...filters, userId: userId, reload: true });
  };

  const handleInputChange = (value, name) => {
    setCancelDebounce(false);
    setFilters({ ...filters, [name]: value });
  }

  const handleInputSubmit = (value, name) => {
    setFilters({ ...filters, [name]: value, reload: true });
  }

  const handleDeletedChange = () => {
    setFilters({ ...filters, deleted: !filters.deleted, reload: true });
  }

  const handleUserStatusChange = useCallback((option) => {
    setFilters({ ...filters, userStatus: option.value });
  }, [filters]);

  const handleActivationStatusChange = useCallback((option) => {
    setFilters({ ...filters, activationStatus: option.value });
  }, [filters]);

  const handleOnlineStatusChange = useCallback((option) => {
    setFilters({ ...filters, onlineStatus: option.value });
  }, [filters]);

  const handleCountriesChange = useCallback((value) => {
    setFilters({ ...filters, countries: value });
  }, [filters]);

  return (
    <>
      <Row className={classNames('video-review-filter')} style={{ transition: 'visibility 0.5s, opacity 0.5s linear' }}>
        <Col lg="12" xs="12" className="d-flex justify-content-end">
          <div className="d-flex align-items-center justify-content-start flex-wrap w-100">
            <div
              className="d-flex align-items-center pr-3 mb-2"
              style={{ width: "30%", minWidth: 275 }}
            >
              <label
                htmlFor="userId"
                className="mb-0 mr-1 d-none d-xl-block flex-shrink-0"
              >
                User id
              </label>
              <div className="input-wrap d-flex w-100">
                <DebounceInput
                  name="userId"
                  className={classNames("", {
                    "is-invalid": validateErrors.userId ? true : false,
                  })}
                  placeholder="Search by user id (uuid format)"
                  debounceTime={3000}
                  cancelDebounce={cancelDebounce}
                  onChange={handleUserIdChange}
                  onSubmit={handleUserIdSubmit}
                  innerRef={userIdInput}
                  maxLength="36"
                  required
                // pattern="/^[0-9A-Fa-f]{8}(?:-[0-9A-Fa-f]{4}){3}-[0-9A-Fa-f]{12}$/"
                />
              </div>
            </div>
            <div
              className="d-flex align-items-center pr-3 mb-2"
              style={{ width: "20%", minWidth: 275 }}
            >
              <label htmlFor="" className="mb-0 mr-1 d-none d-xl-block text-nowrap">
                Account status
              </label>
              <div className="input-wrap d-flex w-100">
                <Select
                  isMulti={false}
                  className="w-100 react-select-container"
                  classNamePrefix="react-select"
                  placeholder=""
                  closeMenuOnSelect={true}
                  options={USER_STATES}
                  value={USER_STATES.filter(option => option.value == filters.userStatus)}
                  onChange={handleUserStatusChange}
                />
              </div>
            </div>
            <div
              className="d-flex align-items-center pr-3 mb-2"
              style={{ width: "20%", minWidth: 275 }}
            >
              <label htmlFor="" className="mb-0 mr-1 d-none d-xl-block text-nowrap">
                Activation status
              </label>
              <div className="input-wrap d-flex w-100">
                <Select
                  isMulti={false}
                  className="w-100 react-select-container"
                  classNamePrefix="react-select"
                  placeholder=""
                  closeMenuOnSelect={true}
                  options={ACTIVATION_STATES}
                  value={ACTIVATION_STATES.filter(option => option.value == filters.activationStatus)}
                  onChange={handleActivationStatusChange}
                />
              </div>
            </div>
            <div className="filter-deleted d-flex align-items-center ml-3" >
              <Label htmlFor="show_deleted">Including deleted users</Label>
              <input
                className="form-check-input mb-3"
                type="checkbox"
                name="show_deleted"
                id="show_deleted"
                defaultChecked={false}
                value={filters.deleted}
                onChange={handleDeletedChange}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg="12" xs="12" className="d-flex justify-content-end">
          <div className="d-flex align-items-center justify-content-start flex-wrap w-100">

            <div
              className="d-flex align-items-center pr-3 mb-2"
              style={{ width: "33%", minWidth: 275 }}
            >
              <label htmlFor="" className="mb-0 mr-1 d-none d-xl-block">
                Countries
              </label>
              <div className="input-wrap d-flex w-100">
                <Select
                  isMulti
                  className="w-100 react-select-container"
                  classNamePrefix="react-select"
                  placeholder="Search by country"
                  closeMenuOnSelect={false}
                  options={COUNTRIES}
                  value={filters.countries}
                  onChange={handleCountriesChange}
                />
              </div>
            </div>
            <div
              className="d-flex align-items-center pr-3 mb-2"
              style={{ width: "20%", minWidth: 275 }}
            >
              <label htmlFor="" className="mb-0 mr-1 d-none d-xl-block text-nowrap">
                Online status
              </label>
              <div className="input-wrap d-flex w-100">
                <Select
                  isMulti={false}
                  className="w-100 react-select-container"
                  classNamePrefix="react-select"
                  placeholder=""
                  closeMenuOnSelect={true}
                  options={ONLINE_STATES}
                  value={ONLINE_STATES.filter(option => option.value == filters.onlineStatus)}
                  onChange={handleOnlineStatusChange}
                />
              </div>
            </div>
            <div className="d-flex align-items-center" style={{ width: '40%', minWidth: '275px' }}>
              <label htmlFor="" className="mb-0 mr-1 d-none d-xl-block">Keywords</label>
              <div className="input-wrap d-flex w-100" >
                <DebounceInput
                  name="keywords"
                  className={classNames('w-100', 'd-flex', { 'is-invalid': validateErrors.keywords ? true : false })}
                  placeholder="Search by name, email"
                  debounceTime={3000}
                  cancelDebounce={cancelDebounce}
                  onChange={handleInputChange}
                  onSubmit={handleInputSubmit}
                  innerRef={keywordInput}
                />
              </div>
            </div>

            <div>
              <Button
                className="ml-3 d-flex"
                size=""
                disabled={loading}
                onClick={(e) => { setCancelDebounce(true); submitFilter(e); }}
              >
                <Icon.Search className="" style={{ width: '15px', height: '15px', marginTop: '3px', marginRight: '2px' }} /> Search
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default React.memo(FormFilter);