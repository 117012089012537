import React from "react";
import { useDispatch } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import PropTypes from "prop-types";

import { permissionActions } from "../../../redux/reducers/domainPermissionReducer";
import { startActionWithPromise } from "../../../helpers/saga-promise-helpers";
import { useWaitingStatus } from "../../../hooks/useWaiter";

const propTypes = {
  row: PropTypes.object,
  modal: PropTypes.bool,
  toggle: PropTypes.func,
};

const defaultProps = {
  row: {},
  modal: false,
  toggle: () => {},
};

const PermissionDelete = props => {
  const dispatch = useDispatch();

  const isDeleting = useWaitingStatus(permissionActions.delete.id);

  const toggle = () => {
    if (props.toggle) props.toggle("modalDelete");
  };

  const successCallback = () => {
    if (props.refreshData) props.refreshData();
    toggle();
  };

  const failedCallback = () => {
  };

  const handleDelete = async () => {
    try {
      await startActionWithPromise(
        permissionActions.delete,
        { id: props.row?.id, successCallback, failedCallback },
        dispatch
      );
    } catch {}
  };

  return (
    <Modal
      isOpen={props.modal}
      toggle={toggle}
      centered
      size="sm"
      className="comfirmation-modal"
    >
      <ModalHeader toggle={toggle}>Confirmation</ModalHeader>
      <ModalBody className="m-3">
        <div>
          <p className="font-weight-bold">
            Following permission will be deleted:
          </p>
          <p className="font-italic">"{props.row?.displayName}"</p>
          <p className="font-weight-bold">Do you want to continue?</p>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" disabled={isDeleting} onClick={toggle}>
          No
        </Button>{" "}
        <Button
          color="primary"
          disabled={isDeleting}
          onClick={() => handleDelete(true)}
        >
          Yes
        </Button>
      </ModalFooter>
    </Modal>
  );
};

PermissionDelete.propTypes = propTypes;
PermissionDelete.defaultProps = defaultProps;

export default React.memo(PermissionDelete);
