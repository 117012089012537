import React from "react";
import Select from 'react-select';

const selectStyles = {
  control: provided => (
    { ...provided, 
      borderWidth: '0px', 
      minHeight:'31px', 
      borderRadius: '2px', 
      boxShadow: '0 0 0 1px #ced4da',
      "&:hover": {
        borderColor: '#ced4da'
      }
    }),
  option: (provided, state) => {
    return {
      ...provided,
      padding: '3px 8px',
      backgroundColor: state.isSelected ? "#86C9CF" : "transparent",
      "&:hover": {
        backgroundColor: "#deebff"
      }
    }
  },
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '4px'
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '1px 8px'
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: '4px'
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: '3',
    borderRadius: '2px',
    menuPlacement: 'top'
  }),
};

const Selectbox = ({options, onChange, placeholder, ...rest}) => {
  const handleChange = (option) => {
    if (onChange) onChange(option);
  }
  
  return (
    <Select
      options={options}
      styles={selectStyles}
      placeholder={placeholder}
      onChange={handleChange}
      {...rest}
    />
  );
}

export default Selectbox;