import { takeLatest, call, put } from "redux-saga/effects";

import { sagaPromise } from "../../helpers/saga-promise-helpers";
import { retranscodeAction, getVideoInfoAction, withdrawAction } from "../reducers/toolReducer";
import toolService from "../../services/toolService";
import { alertTypes } from "../constants";
import { TOOL_MESSAGES } from "../../config/messages";

function* retranscodeVideoWorker(action) {
  const { data, error } = yield call(
    toolService.retranscodeVideo,
    action.payload.id
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      yield put({
        type: alertTypes.ERROR,
        message: error.headers["x-message-info"],
      });
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    yield put({
      type: alertTypes.SUCCESS,
      message: TOOL_MESSAGES.retranscodeSuccess,
    });
    return data;
  }

  return false;
}

function* getVideoInfoWorker(action) {
  const { data, error } = yield call(
    toolService.getVideoInfo,
    action.payload.id
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      yield put({
        type: alertTypes.ERROR,
        message: error.headers["x-message-info"],
      });
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    return data;
  }

  return false;
}

function* withdrawVideoWorker(action) {
  const { data, error } = yield call(
    toolService.withdrawVideo,
    action.payload.id,
    action.payload.reason
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      yield put({
        type: alertTypes.ERROR,
        message: error.headers["x-message-info"],
      });
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    yield put({
      type: alertTypes.SUCCESS,
      message: TOOL_MESSAGES.unpublishSuccess,
    });
    return data;
  }

  return false;
}

function* toolSaga() {
  yield takeLatest(
    retranscodeAction.start,
    retranscodeAction.waiterActionForSaga(sagaPromise(retranscodeVideoWorker))
  );
  yield takeLatest(
    getVideoInfoAction.start,
    getVideoInfoAction.waiterActionForSaga(sagaPromise(getVideoInfoWorker))
  );
  yield takeLatest(
    withdrawAction.start,
    withdrawAction.waiterActionForSaga(sagaPromise(withdrawVideoWorker))
  );
}

export default toolSaga;
