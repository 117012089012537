import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Badge,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";
import PropTypes from "prop-types";
import _ from "lodash";

import DataTable from "../../components/DataTable";

import { TRUE_FALSE_LABELS, TRUE_FALSE_COLORS } from "./constants";

const propTypes = {
  modal: PropTypes.bool,
  toggle: PropTypes.func,
};

const defaultProps = {
  modal: false,
  toggle: () => {},
};

const PlanOptionModal = props => {
  const dispatch = useDispatch();
  const { modal, toggle, row } = props;
  const [waiting, setWaiting] = useState(false);

  const toggleModal = () => {
    if (toggle) toggle('modalOption');
  };

  const tableColumns = [
    {
      dataField: "optionType",
      text: "Option Type",
      sort: false,
      onSort: (field, order) => {
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div>
          <span>{row.optionType}</span>
        </div>
      ),
      headerStyle: () => {
        return { width: "35%" };
      },
    },
    {
      dataField: "listingOrderNumber",
      text: "Listing Order",
      sort: false,
      onSort: (field, order) => {
        
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div>
          <span>{row.listingOrderNumber}</span>
        </div>
      ),
      headerStyle: () => {
        return { textAlign: "center", width: "10%" };
      },
      style: () => {
        return { textAlign: "center" };
      },
    },
    {
      dataField: "value",
      text: "Value",
      sort: false,
      onSort: (field, order) => {
        
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div>
          <span>{row.value}</span>
        </div>
      ),
      headerStyle: () => {
        return { width: "10%" };
      },
    },
    {
      dataField: "valueUnit",
      text: "Value Unit",
      sort: false,
      onSort: (field, order) => {
        
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div>
          <span>{row.valueUnit}</span>
        </div>
      ),
      headerStyle: () => {
        return { width: "10%" };
      },
    },
    {
      dataField: "description",
      text: "Description",
      sort: false,
      onSort: (field, order) => {
        
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div>
          <span className="font-weight-bold" title={row?.description}>{_.truncate(row?.description, {'length': 65,})}</span>
        </div>
      ),
      headerStyle: () => {
        return { width: "25%" };
      },
    },
    {
      dataField: "active",
      text: "Active",
      sort: false,
      onSort: (field, order) => {
        
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div>
          <div className="">
            <Badge
              color={TRUE_FALSE_COLORS[row.active]}
              className="mr-1 mb-1"
            >
              {TRUE_FALSE_LABELS[row.active]}
            </Badge>
          </div>
        </div>
      ),
      headerStyle: () => {
        return { textAlign: "center", width: "10%" };
      },
      style: () => {
        return { textAlign: "center" };
      },
    },
  ];

  return (
    <Modal isOpen={modal} toggle={toggleModal} centered size="xl">
      <ModalHeader>Plan Options ( of the plan <font color="red">{row.planName}</font>)</ModalHeader>
      <ModalBody className="m-3">
        <Card className="w-100 mb-0">
          <CardBody className="pt-0 pb-0 data-list position-relative">
              <div className="">
                <DataTable
                  keyField="id"
                  single
                  data={row.options}
                  columns={tableColumns}
                  hideSelectColumn={true}
                />
              </div>
          </CardBody>
        </Card>
        
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggleModal}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

PlanOptionModal.propTypes = propTypes;
PlanOptionModal.defaultProps = defaultProps;

export default PlanOptionModal;
