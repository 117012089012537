import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  FormGroup,
  Label,
} from "reactstrap";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";

import { bouserActions } from "../../redux/reducers/bouserReducer";
import { startActionWithPromise } from "../../helpers/saga-promise-helpers";
import { validEmail } from "../../helpers/utils";

const propTypes = {
  row: PropTypes.object,
  modal: PropTypes.bool,
  toggle: PropTypes.func,
};

const UserUpdate = props => {
  const dispatch = useDispatch();
  const submitButton = useRef(null);

  const { modal, toggle, row, refreshData } = props;

  const { register, handleSubmit, errors } = useForm();

  const userRoles = useSelector(state => state.bouser.userRoles) || [];

  const [pristine, setPristine] = useState(true);
  const [messages, setMessages] = useState(false);
  const [waiting, setWaiting] = useState(false);

  const toggleModal = () => {
    if (toggle) toggle("modalUpdate");
  };

  const triggerSubmitForm = () => {
    if (submitButton.current) submitButton.current.click();
  };

  const onSubmit = async data => {
    // check for roles change
    const validate_errors = [];
    if (!data.firstName) {
      validate_errors.push("Please enter first name.");
    } else if (!data.lastName) {
      validate_errors.push("Please enter last name.");
    } else if (props.row?.userId && !data.username) {
      validate_errors.push("Please enter username.");
    } else if (!data.email) {
      validate_errors.push("Please enter email.");
    } else if (!validEmail(data.email)) {
      validate_errors.push("Please provide an valid email.");
    }

    if (validate_errors.length) {
      setMessages(validate_errors);
      return;
    }
    setMessages(validate_errors);

    const postData = {
      userId: props.row?.userId ? props.row?.userId : "",
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      username: data.username,
      phone: data.phone,
    };
    // console.log(postData); return;

    setWaiting(true);
    try {
      if (props.row?.userId) {
        await startActionWithPromise(
          bouserActions.update,
          {
            id: props.row.userId,
            data: postData,
            successCallback,
            failedCallback,
          },
          dispatch
        );
      } else {
        delete postData.userId;
        await startActionWithPromise(
          bouserActions.create,
          { data: postData, successCallback, failedCallback },
          dispatch
        );
      }
    } catch (error) {
      console.log("startActionWithPromise error", error);
    }
    setWaiting(false);
  };

  const successCallback = () => {
    setWaiting(false);
    if (refreshData) refreshData();
    toggleModal();
  };

  const failedCallback = () => {
    setWaiting(false);
  };

  const isNumberKey = (evt) => {
    if (evt.keyCode === 8 || (evt.charCode >= 48 && evt.charCode <= 57)) {
      return true;
    } else {
      evt.preventDefault();
      return;
    }
  }

  useEffect(() => {
    if (row) {
      // dispatch(getUserRoles(props.row.userId));
    }
  }, [row]);

  useEffect(() => {
    // setCheckedRoles(userRoles);
  }, [userRoles]);

  useEffect(() => {
    // dispatch(fetchRoles());
  }, []);

  return (
    <Modal isOpen={modal} toggle={toggleModal} centered size="md">
      <ModalHeader toggle={toggleModal}>
        {props.row?.id ? "Update BO User" : "Add BO User"}
      </ModalHeader>
      <ModalBody className="m-3">
        <form onSubmit={handleSubmit(onSubmit)} id="form-bouser">
          <Row className="d-none">
            <Col lg="12">
              <FormGroup>
                <FormGroup check inline className="">
                  <Label check>
                    <input
                      className="form-check-input"
                      name="enabled"
                      type="checkbox"
                      defaultChecked
                      ref={register}
                      onChange={() => setPristine(false)}
                    />
                    Enabled
                  </Label>
                </FormGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <FormGroup>
                <Label for="firstName" className="">
                  First name
                </Label>
                <input
                  className={`form-control${
                    errors.firstName ? " is-invalid" : ""
                  }`}
                  type="text"
                  id="firstName"
                  name="firstName"
                  placeholder=""
                  defaultValue={row ? row.firstName : ""}
                  onChange={() => setPristine(false)}
                  ref={register({ required: "First name is required." })}
                />
                <p className="text-danger">{errors.firstName?.message}</p>
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <Label for="lastName" className="">
                  Last name
                </Label>
                <input
                  type="text"
                  className={`form-control${
                    errors.lastName ? " is-invalid" : ""
                  }`}
                  id="lastName"
                  name="lastName"
                  placeholder=""
                  defaultValue={row ? row.lastName : ""}
                  onChange={() => setPristine(false)}
                  ref={register({ required: "Last name is required." })}
                />
                <p className="text-danger">{errors.lastName?.message}</p>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <FormGroup>
                <Label for="username" className="">
                  Username
                </Label>
                <input
                  type="text"
                  className={`form-control${
                    errors.username ? " is-invalid" : ""
                  }`}
                  name="username"
                  id="username"
                  placeholder=""
                  defaultValue={row ? row.username : ""}
                  onChange={() => setPristine(false)}
                  ref={register({ required: "Username is required." })}
                />
                <p className="text-danger">{errors.username?.message}</p>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <FormGroup>
                <Label for="email" className="">
                  Email
                </Label>
                <input
                  type="text"
                  className={`form-control${errors.email ? " is-invalid" : ""}`}
                  name="email"
                  id="email"
                  placeholder=""
                  defaultValue={row ? row.email : ""}
                  onChange={() => setPristine(false)}
                  ref={register({
                    required: true,
                    pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  })}
                />
                <p className="text-danger">
                  {errors.email?.type === "required" ? "Email is required." : ""}
                  {errors.email?.type === "pattern"
                    ? "Invalid email address."
                    : ""}
                </p>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <FormGroup>
                <Label for="phone" className="">
                  Phone
                </Label>
                <input
                  type="number"
                  className="form-control"
                  id="phone"
                  name="phone"
                  placeholder=""
                  onKeyPress={(e) => isNumberKey(e)}
                  defaultValue={row ? row.phone : ""}
                  onChange={() => setPristine(false)}
                  ref={register}
                />
                <p className="text-danger">{errors.phone?.message}</p>
              </FormGroup>
            </Col>
          </Row>

          <Button
            className="d-none"
            disabled={pristine}
            type="submit"
            innerRef={submitButton}
          >
            Submit
          </Button>
        </form>
      </ModalBody>
      <ModalFooter>
        <div className="">
          {messages &&
            messages.map((error, i) => (
              <span key={`errors-${i}`} className="text-danger mb-0 mr-1">
                {error}
              </span>
            ))}
        </div>
        <Button color="secondary" disabled={waiting} onClick={toggleModal}>
          Cancel
        </Button>
        <Button
          className="ml-2"
          color="primary"
          disabled={pristine || waiting}
          onClick={() => triggerSubmitForm(true)}
        >
          Save changes
        </Button>
      </ModalFooter>
    </Modal>
  );
};

UserUpdate.propTypes = propTypes;

export default UserUpdate;
