import React from "react";
import { Badge, UncontrolledTooltip } from "reactstrap";
import * as Icon from "react-feather";

import { ACTIVE_LABELS, ACTIVE_COLORS } from "./constants";

export const getTableColumns = (isBoAdmin, handleUpdate) => {
  
  return [
    {
      dataField: "name",
      text: "Package Name",
      sort: false,
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div>{row.name}</div>
      ),
      headerStyle: () => {
        return { width: '25%' };
      },
    },
    {
      dataField: "description",
      text: "Description",
      sort: false,
      formatter: (cell, row) => (
        <div>{row.description}</div>
      ),
      headerStyle: () => {
        return { width: '30%' };
      },
    },
    {
      dataField: "size",
      text: "Size",
      sort: false,
      formatter: (cell, row) => (
        <div>{row.size}</div>
      ),
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: "unit",
      text: "Unit",
      sort: false,
      formatter: (cell, row) => (
        <div>{row.unit}</div>
      ),
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: "packageType",
      text: "Package Type",
      sort: false,
      formatter: (cell, row) => (
        <div>{row.packageType}</div>
      ),
      headerStyle: () => {
        return { width: '7%' };
      },
    },
    {
      dataField: "enabled",
      text: "Enabled",
      sort: false,
      formatter: (cell, row) => (
        <div className="">
          <Badge
            color={ACTIVE_COLORS[row.enabled]}
            className="mr-1 mb-1"
          >
            {ACTIVE_LABELS[row.enabled]}
          </Badge>
        </div>
      ),
      headerStyle: () => {
        return { textAlign: "center", width: '5%' };
      },
      style: () => {
        return { textAlign: "center" };
      },
    },
    
    {
      dataField: "action_col",
      text: "Actions",
      sort: false,
      formatter: (cell, row, rowIndex, formatExtraData) => (
        <div className="actions-col">
          {isBoAdmin && (row.packageType !== 'UNLIMITED') && (
            <a href="#" className="mr-2" onClick={e => handleUpdate(row, e)} id={'edit-' + row.action_col}>
              <Icon.Edit className="text-dark" style={{ width: "15px" }} />
              <UncontrolledTooltip target={'edit-' + row.action_col}>
                Edit
              </UncontrolledTooltip>
            </a>
          )}
        </div>
      ),
      formatExtraData: {
        
      },
      headerStyle: () => {
        return { textAlign: "center", width: "130px" };
      },
      style: () => {
        return { textAlign: "center" };
      },
    },
  ];
}