import React, { useEffect, useState, useCallback, useRef } from "react";
import { Input } from "reactstrap";
import _ from "lodash";

const DebounceInput = ({value, onSubmit, onChange, debounceTime, cancelDebounce, clearInput = false, ...rest}) => {
  let inputRef = useRef(null);
  const [inputValue, setInputValue] = useState('');
  const [isInputClear, setInputClear] = useState(false);

  const onDebounce = useCallback(
    _.debounce(() => {
      if (onSubmit) onSubmit(inputValue, inputRef?.current?.props?.name);
    }, debounceTime || 3000),
    [inputValue]
  );

  const handleChange = (e) => {
    setInputValue(e.target.value);
    if (onChange) onChange(e.target.value, e.target.name, e.target);
  }

  const handleKeyUp = (e) => {
    if (e.keyCode === 13) {
      setInputValue(e.target.value);
      if (onSubmit) {
        onSubmit(e.target.value, e.target.name, e.target);
        onDebounce.cancel();
      }
    }
  };

  const isFirstRun = useRef(true);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    if (isInputClear) {
      setInputClear(false);
      return;
    }

    if (debounceTime) onDebounce();

    return onDebounce.cancel;
  }, [inputValue, onDebounce]);

  useEffect(() => {
    if (cancelDebounce) {
      onDebounce.cancel();
    }

    return onDebounce.cancel;
  }, [cancelDebounce, onDebounce]);

  useEffect(() => {
    if (clearInput) {
      setInputClear(true);
      setInputValue('');
    }
  }, [clearInput]);

  return (
    <Input
      value={inputValue}
      onKeyUp={e => handleKeyUp(e)}
      onChange={handleChange}
      {...rest}
      ref={inputRef}
      // styles={{boxShadow: '0 0 0 1px #ced4da', border: 'none'}}
    />
  )
}

export default DebounceInput;