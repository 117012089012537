import {
  createReducer,
  createActionResources,
  createActionCRUDResources,
} from "redux-waiters";

import { RESET_REDUCER } from "../constants";

export const settingsAction = createActionCRUDResources("settings");

export const fetchTypes = createActionResources("settings/types/fetch");
export const fetchServices = createActionResources("settings/services/fetch");

const initialState = {
  fetching: false,
  settings: [],
  services: [],
  types: [],
  total: 0,
  totalPages: 0,
  pageNumber: 1,
};

export default createReducer(
  {
    [settingsAction.fetch.start]: state => {
      return { ...state, fetching: true };
    },
    [settingsAction.fetch.success]: (state, action) => {
      return {
        ...state,
        settings: action.data?.content,
        pageNumber: action.data?.pageNumber + 1,
        total: action.data?.total,
        totalPages: Math.ceil(action.data?.total / action.data?.pageSize),
        fetching: false,
      };
    },
    [settingsAction.fetch.error]: state => {
      return { ...state, fetching: false };
    },

    [fetchTypes.start]: state => {
      return { ...state, fetching: true };
    },
    [fetchTypes.success]: (state, action) => {
      return {
        ...state,
        types: action.data,
        fetching: false,
      };
    },

    [fetchServices.start]: state => {
      return { ...state, fetching: true };
    },
    [fetchServices.success]: (state, action) => {
      return {
        ...state,
        services: action.data,
        fetching: false,
      };
    },

    [RESET_REDUCER]: () => initialState,
  },
  initialState
);
