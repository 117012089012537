import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  FormGroup,
  Label,
} from "reactstrap";
import _ from "lodash";

import { useForm } from "react-hook-form";
import PropTypes from "prop-types";

import { roleGroupActions } from "../../../redux/reducers/domainRoleGroupReducer";
import { startActionWithPromise } from "../../../helpers/saga-promise-helpers";

const propTypes = {
  row: PropTypes.object,
  modal: PropTypes.bool,
  toggle: PropTypes.func,
};

const RoleUpdate = props => {
  const dispatch = useDispatch();

  // selectors
  const updating = useSelector(state => state.domainPermissions.updating);
  const permissions = useSelector(state => state.domainPermissions.permissions);

  const submitButton = useRef(null);

  const { name, description } = props.row || {};

  const { register, handleSubmit, errors } = useForm();

  const [pristine, setPristine] = useState(true);
  const [messages, setMessages] = useState(false);
  const [waiting, setWaiting] = useState(false);

  /**
   * prepare permission list by group
   */
  let permGroups = [];
  const _group = _.groupBy(permissions, "type");
  if (_group) {
    _.forOwn(_group, function (group, key) {
      if (group) {
        permGroups.push({
          type: key,
          perms: _.sortBy(group, ["action"]),
        });
      }
    });
  }
  permGroups = permGroups ? _.sortBy(permGroups, ["type"]) : [];

  const toggle = () => {
    if (props.modal) {
      // reset state when close modal
      setMessages([]);
    }
    if (props.toggle) props.toggle("modalUpdate");
  };

  const triggerSubmitForm = () => {
    if (submitButton.current) submitButton.current.click();
  };

  const onSubmit = async data => {
    // check for roles change
    const validate_errors = [];
    if (!data.name) {
      validate_errors.push("Please input group name.");
    }

    if (validate_errors.length) {
      setMessages(validate_errors);
      return;
    }

    const postData = {
      id: props.row?.id ? props.row?.id : 0,
      name: data.name,
      description: data.description,
    };

    setWaiting(true);
    try {
      if (props.row?.id) {
        await startActionWithPromise(
          roleGroupActions.update,
          { id: props.row.id, data: postData, successCallback, failedCallback },
          dispatch
        );
      } else {
        await startActionWithPromise(
          roleGroupActions.create,
          { data: postData, successCallback, failedCallback },
          dispatch
        );
      }
    } catch {}
    setWaiting(false);
  };

  const successCallback = () => {
    if (props.refreshData) props.refreshData();
    toggle();
  };

  const failedCallback = () => {
  };

  return (
    <Modal isOpen={props.modal} toggle={toggle} centered size="lg">
      <ModalHeader toggle={toggle}>
        {props.row?.id ? "Update Role Group" : "Add Role Group"}
      </ModalHeader>
      <ModalBody className="m-3">
        <form onSubmit={handleSubmit(onSubmit)} id="form-permission">
          <Row>
            <Col lg="12">
              <FormGroup>
                <Label for="name">Name</Label>
                <input
                  className="form-control"
                  name="name"
                  type="text"
                  required
                  defaultValue={name}
                  ref={register({ required: true })}
                  onChange={() => setPristine(false)}
                />
                {errors && errors.name && (
                  <div className="invalid-feedback">
                    Please input role name.
                  </div>
                )}
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <FormGroup>
                <Label for="name">Description</Label>
                <input
                  className="form-control"
                  name="description"
                  type="text"
                  defaultValue={description}
                  ref={register}
                  onChange={() => setPristine(false)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Button
            className="d-none"
            disabled={pristine}
            type="submit"
            innerRef={submitButton}
          >
            Submit
          </Button>
        </form>
      </ModalBody>
      <ModalFooter>
        <div className="">
          {messages &&
            messages.map((error, i) => (
              <span key={`errors-${i}`} className="text-danger mb-0 mr-1">
                {error}
              </span>
            ))}
        </div>
        <Button color="secondary" disabled={waiting} onClick={toggle}>
          Cancel
        </Button>
        <Button
          className="ml-2"
          color="primary"
          disabled={pristine || updating}
          onClick={() => triggerSubmitForm(true)}
        >
          Save changes
        </Button>
      </ModalFooter>
    </Modal>
  );
};

RoleUpdate.propTypes = propTypes;

export default RoleUpdate;
