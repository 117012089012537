import { takeLatest, call, put } from "redux-saga/effects";

import orderServices from "../../services/orderService";
import reportServices from "../../services/reportService";
import { alertTypes } from "../constants";
import {
  orderActions,
  getOrderAction,
  userOrderAction,
  fetchOrdersAction
} from "../reducers/orderReducer";
import { sagaPromise } from "../../helpers/saga-promise-helpers";
import { ORDER_MESSAGES } from "../../config/messages";

function* fetchWorker(action) {
  try {
    const { data, error } = yield call(
      orderServices.fetch,
      action.payload?.params
    );
    if (error) {
      yield put(orderActions.fetch.error(error));
    } else {
      yield put(orderActions.fetch.success({ ...action, data }));
    }
  } catch (err) {
    yield put(orderActions.fetch.error(err));
  }
}

function* fetchOrdersWorker(action) {
  const { data, error } = yield call(
    reportServices.revenues,
    action.payload?.params
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      yield put({
        type: alertTypes.ERROR,
        message: error.headers["x-message-info"],
      });
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    return data;
  }
  return false;
}

function* addWorker(action) {
  const { data, error } = yield call(
    orderServices.create,
    action.payload.data
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      yield put({
        type: alertTypes.ERROR,
        message: error.headers["x-message-info"],
      });
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    yield put({
      type: alertTypes.SUCCESS,
      message: ORDER_MESSAGES.addSuccess,
    });
    return data;
  }
  return false;
}

function* updateWorker(action) {
  const { data, error } = yield call(
    orderServices.update,
    action.payload.id,
    action.payload.data
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      if (!action.payload?.hideAlert) {
        yield put({
          type: alertTypes.ERROR,
          message: error.headers["x-message-info"],
        });
        throw new Error(error.headers["x-message-info"]);
      }
      return { message: error.headers["x-message-info"] };
    }
  } else {
    if (!action.payload?.hideAlert)
      yield put({
        type: alertTypes.SUCCESS,
        message: ORDER_MESSAGES.updateSuccess,
      });

    return data;
  }

  return false;
}

function* deleteWorker(action) {
  const { data, error } = yield call(
    orderServices.remove,
    action.payload.id
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      yield put({
        type: alertTypes.ERROR,
        message: error.headers["x-message-info"],
      });
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    yield put({
      type: alertTypes.SUCCESS,
      message: ORDER_MESSAGES.deleteSuccess,
    });
    return data;
  }

  return false;
}

function* updateStatusWorker(action) {
  const { data, error } = yield call(
    orderServices.enable,
    action.payload.id
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      yield put({
        type: alertTypes.ERROR,
        message: error.headers["x-message-info"],
      });
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    yield put({
      type: alertTypes.SUCCESS,
      message: ORDER_MESSAGES.updateStatus,
    });
    return data;
  }

  return false;
}

function* userOrdersWorker(action) {
  const { data, error } = yield call(
    orderServices.fetch,
    action.payload.params
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      yield put({
        type: alertTypes.ERROR,
        message: error.headers["x-message-info"],
      });
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    return data;
  }

  return false;
}

function* getOrderWorker(action) {
  try {
    const { data, error } = yield call(
      orderServices.getOrder,
      action.payload?.id
    );
    if (error) {
      yield put(getOrderAction.error(error));
    } else {
      yield put(getOrderAction.success({ ...action, data }));
    }
  } catch (err) {
    yield put(getOrderAction.error(err));
  }
}

function* orderSaga() {
  yield takeLatest(orderActions.fetch.start, fetchWorker);
  yield takeLatest(getOrderAction.start, getOrderWorker);

  yield takeLatest(
    orderActions.create.start,
    orderActions.create.waiterActionForSaga(sagaPromise(addWorker))
  );
  yield takeLatest(
    orderActions.update.start,
    orderActions.update.waiterActionForSaga(sagaPromise(updateWorker))
  );
  yield takeLatest(
    orderActions.delete.start,
    orderActions.delete.waiterActionForSaga(sagaPromise(deleteWorker))
  );
  yield takeLatest(
    userOrderAction.start,
    userOrderAction.waiterActionForSaga(sagaPromise(userOrdersWorker))
  );
  yield takeLatest(
    fetchOrdersAction.start,
    fetchOrdersAction.waiterActionForSaga(sagaPromise(fetchOrdersWorker))
  );
}

export default orderSaga;
