import React, { useState } from "react";
import { Row, Col, Button, UncontrolledTooltip } from "reactstrap";
import _ from 'lodash';
import * as Icon from "react-feather";
import classNames from "classnames";

import "./FormFilter.scss";
import SearchUserInput from "./SearchUserInput";
import Selectbox from '../../components/Selectbox/Selectbox';
import { SUBMIT_COMPLETED_VALUES, SUBMIT_COMPLETED_LABELS, TRUE_FALSE_VALUES, TRUE_FALSE_LABELS } from "./constants";

let SubmitCompletedOptions = [{
  label: 'Submit Completed?',
  value: '',
}];
Object.entries(SUBMIT_COMPLETED_VALUES).forEach(([key, value]) => {
  SubmitCompletedOptions.push({
    label: SUBMIT_COMPLETED_LABELS[value],
    value: value,
  });
});

let DefaultAccountOptions = [{
  label: 'Default Account?',
  value: '',
}];
Object.entries(TRUE_FALSE_VALUES).forEach(([key, value]) => {
  DefaultAccountOptions.push({
    label: TRUE_FALSE_LABELS[value],
    value: value,
  });
});

let TransfersEnabledOptions = [{
  label: 'Transfer Enabled?',
  value: '',
}];
Object.entries(TRUE_FALSE_VALUES).forEach(([key, value]) => {
  TransfersEnabledOptions.push({
    label: TRUE_FALSE_LABELS[value],
    value: value,
  });
});

let PayoutsEnabledOptions = [{
  label: 'Payout Enabled?',
  value: '',
}];
Object.entries(TRUE_FALSE_VALUES).forEach(([key, value]) => {
  PayoutsEnabledOptions.push({
    label: TRUE_FALSE_LABELS[value],
    value: value,
  });
});

const FormFilter = ({loading, paging, mergeFilters, user}) => {
  
  const [filters, setFilters] = useState({
    userId: user ? user.userId : '',
    defaultAccount: '',
    submitCompleted: '',
    transfersEnabled: '',
    payoutsEnabled: '',
    reload: false,
  });

  const handleUserChange = (option) => {
    setFilters({ ...filters, userId: option ? option.value : ''});
  }

  const handleSubmitCompletedChange = (option) => {
    if (option && (option.value == true || option.value == false) ) 
        setFilters({ ...filters, submitCompleted: option.value, reload: true });
    else 
        setFilters({ ...filters, submitCompleted: '', reload: true });
  }

  const handleDefaultAccountChange = (option) => {
    if (option && (option.value == true || option.value == false) ) 
        setFilters({ ...filters, defaultAccount: option.value, reload: true });
    else 
        setFilters({ ...filters, defaultAccount: '', reload: true });
  }

  const handleTransfersEnabledChange = (option) => {
    if (option && (option.value == true || option.value == false) ) 
        setFilters({ ...filters, transfersEnabled: option.value, reload: true });
    else 
        setFilters({ ...filters, transfersEnabled: '', reload: true });
  }

  const handlePayoutsEnabledChange = (option) => {
    if (option && (option.value == true || option.value == false) ) 
        setFilters({ ...filters, payoutsEnabled: option.value, reload: true });
    else 
        setFilters({ ...filters, payoutsEnabled: '', reload: true });
  }

  const submitFilter = () => {
    // console.log(mergeFilters);
    mergeFilters({...filters, reload: true});
  }

  return (
    <>
      <Row className={classNames('video-review-filter')} style={{transition: 'visibility 0.5s, opacity 0.5s linear'}}>
        <Col lg="12" md="12" xs="12" className="">
        <div className="item-filter float-right">
            <div className="d-flex align-items-center">
              
              <div className="d-flex align-items-center ml-3">
                <label htmlFor="" className="mb-0 text-nowrap text-right" style={{width: '80px'}}>User</label>
                <div className="d-inline-block ml-2 item-filter__control" style={{width: '200px'}}>
                  <SearchUserInput
                    onChange={handleUserChange}
                    className="w-100"
                    isClearable={true}
                    name="userId"
                    />
                </div>
              </div>
              <div className="filter-status ml-3" style={{width: '200px'}}>
                <Selectbox
                  name="submit_completed"
                  placeholder="Submit Completed?"
                  options={SubmitCompletedOptions}
                  onChange={handleSubmitCompletedChange}
                />
              </div>
              <div className="filter-status ml-3" style={{width: '200px'}}>
                <Selectbox
                  name="default_account"
                  placeholder="Default Account?"
                  options={DefaultAccountOptions}
                  onChange={handleDefaultAccountChange}
                />
              </div>

              <div className="filter-status ml-3" style={{width: '200px'}}>
                <Selectbox
                  name="transfers_enabled"
                  placeholder="Transfer Enabled?"
                  options={TransfersEnabledOptions}
                  onChange={handleTransfersEnabledChange}
                />
              </div>
              <div className="filter-status ml-3" style={{width: '200px'}}>
                <Selectbox
                  name="payouts_enabled"
                  placeholder="Payout Enabled?"
                  options={PayoutsEnabledOptions}
                  onChange={handlePayoutsEnabledChange}
                />
              </div>
              <div className="d-flex align-items-center">
                <Button
                  className="ml-2"
                  size=""
                  disabled={loading}
                  onClick={(e) => submitFilter(e)}
                  style={{minWidth: '86px'}}
                >
                  <Icon.Search style={{ width: '15px', height: '15px', marginTop: '-2px' }} /> Search
                </Button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default React.memo(FormFilter);