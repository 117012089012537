
import React, { useEffect, useState, useCallback, useRef } from "react";
import { Input } from "reactstrap";
import _ from "lodash";

const CustomInput = ({value, onSubmit, onChange, debounceTime, cancelDebounce, ...rest}) => {
  let [inputValue, setInputValue] = useState('');
  let inputRef = useRef(null);

  const onDebounce = useCallback(
    _.debounce(() => {
      // if (onSubmit) onSubmit(inputValue, inputRef?.current?.props?.name);
    }, debounceTime || 3000),
    [inputValue]
  );

  const handleChange = (e) => {
    setInputValue(e.target.value);
    if (onChange) onChange(e.target.value, e.target.name);
  }

  const handleKeyUp = (e) => {
    if (e.keyCode === 13) {
      setInputValue(e.target.value);
      if (onSubmit) {
        onSubmit(e.target.value, e.target.name);
        onDebounce.cancel();
      }
    }
  };

  const isFirstRun = useRef(true);
  useEffect (() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    if (debounceTime) onDebounce();

    return onDebounce.cancel;
  }, [inputValue, onDebounce]);

  useEffect (() => {
    if (cancelDebounce) {
      onDebounce.cancel();
    }

    return onDebounce.cancel;
  }, [cancelDebounce, onDebounce]);

  return (
    <Input
      value={inputValue}
      onKeyUp={e => handleKeyUp(e)}
      onChange={handleChange}
      {...rest}
      ref={inputRef}
      // styles={{boxShadow: '0 0 0 1px #ced4da', border: 'none'}}
    />
  )
}

export default CustomInput;