import { post, get, request } from "../helpers/apiHelper";

async function fetchRoles() {
  return await get("users/roles");
}

async function fetchUserRoles(userId) {
  return await get(`users/${userId}/roles`);
}

async function setUserRoles(userId, roles) {
  return await request(
    `users/${userId}/roles`,
    roles,
    "put"
  );
}

async function deleteUserRoles(userId) {
  return await request(
    `users/${userId}/roles`,
    null,
    "delete"
  );
}

async function addUserRoles(userId, roles) {
  return await post(
    `users/${userId}/roles/addition`,
    roles
  );
}

async function subUserRoles(userId, roles) {
  return await post(
    `users/${userId}/roles/subtraction`,
    roles
  );
}

export default {
  fetchRoles,
  fetchUserRoles,
  setUserRoles,
  deleteUserRoles,
  addUserRoles,
  subUserRoles,
};
