import { createReducer, createActionCRUDResources } from "redux-waiters";
import _ from "lodash";

import { RESET_REDUCER } from "../constants";

export const permissionActions = createActionCRUDResources(
  "domain/permissions"
);

const initialState = {
  loading: false,
  permissions: [],
  permGroups: [],
  total: 0,
  totalPages: 0,
  pageNumber: 1,
  permission: false,
  updating: false,
};

export default createReducer(
  {
    [permissionActions.fetch.start]: state => {
      return { ...state, loading: true };
    },
    [permissionActions.fetch.success]: (state, data) => {
      // grouping permissions to group
      const permissions = data && data.content ? data.content : [];
      let permGroups = [];
      const groups = _.groupBy(permissions, "type");
      if (groups) {
        _.forOwn(groups, function (group, key) {
          if (group) {
            permGroups.push({
              type: key,
              perms: _.sortBy(group, ["action"]),
            });
          }
        });
      }
      permGroups = permGroups ? _.sortBy(permGroups, ["type"]) : [];

      return {
        ...state,
        permissions,
        permGroups,
        pageNumber: data?.pageNumber + 1,
        totalPages: Math.ceil(data?.total / data?.pageSize),
        loading: false,
      };
    },
    [permissionActions.fetch.error]: state => {
      return { ...state, loading: false };
    },
    [permissionActions.create.success]: state => {
      return { ...state, updating: false };
    },
    [permissionActions.update.success]: state => {
      return { ...state, updating: false };
    },
    [RESET_REDUCER]: () => initialState,
  },
  initialState
);
