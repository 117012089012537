import { createReducer, createActionResources } from "redux-waiters";
import { RESET_REDUCER } from "../constants";

export const fetchRolesAction = createActionResources("users/fetch-roles");
export const userRolesAction = createActionResources("users/roles");
export const setRolesAction = createActionResources("users/set-roles");
export const addRolesAction = createActionResources("users/add-roles");
export const subRolesAction = createActionResources("users/sub-roles");
export const deleteRolesAction = createActionResources("users/delete-roles");

const initialState = {
  fetching: false,
  roles: [],
  userRoles: []
};

export default createReducer(
  {
    [fetchRolesAction.start]: state => {
      return { ...state, fetching: true };
    },
    [fetchRolesAction.success]: (state, action) => {
      return {
        ...state,
        roles: action.data || [],
        fetching: false,
      };
    },
    [fetchRolesAction.error]: state => {
      return { ...state, fetching: false };
    },
    [RESET_REDUCER]: () => initialState,
  },
  initialState
);
