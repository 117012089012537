import React, { useState, useEffect, useRef } from "react";
import { Bar } from "react-chartjs-2";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import PropTypes from "prop-types";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Spinner
} from "reactstrap";

import {
  newUsersAction,
} from "../../../redux/reducers/reportReducer";
import { startActionWithPromise } from "../../../helpers/saga-promise-helpers";

const propTypes = {
  dateRange: PropTypes.string,
  refresh: PropTypes.number
}

const defaultProps = {
  dateRange: '',
  refresh: () => { }
}

const DAILY = "daily";
const WEEKLY = "weekly";
const MONTHLY = "monthly";

const getDayDiff = (dateRange) => {
  let tmp = dateRange.split('-');
  let dtStart = moment(tmp[0], 'MM/DD/YYYY');
  let dtEnd = moment(tmp[1], 'MM/DD/YYYY');

  return dtEnd.diff(dtStart, 'days');
}

const UserRegistrationWidget = ({ dateRange, refresh }) => {
  const dispatch = useDispatch();

  const dayButton = useRef(null);
  const weekButton = useRef(null);
  const monthButton = useRef(null);

  const userStats = useSelector(state => state.report.users);

  const [timeline, setTimeline] = useState(DAILY);
  const [waiting, setWaiting] = useState(false);

  const getLabels = () => {
    const source = userStats[timeline] || [];
    return (source && source.map(item => item.date)) || [];
  };

  const getData = () => {
    const source = userStats[timeline] || [];
    return (source && source.map(item => item.userActiveCount)) || [];
  };

  const getDataInactive = () => {
    const source = userStats[timeline] || [];
    return (source && source.map(item => item.userInactiveCount)) || [];
  };

  const data = {
    labels: getLabels(),
    datasets: [
      {
        label: "Registered",
        data: getDataInactive(),
        backgroundColor: "#F2B34B",
        maxBarThickness: 15
      },
      {
        label: "Activated",
        data: getData(),
        backgroundColor: "rgb(54, 162, 235)",
        maxBarThickness: 15
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: true,
      labels: {
        boxWidth: 13
      }
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            display: true,
          },
          stacked: false,
          ticks: {
            beginAtZero: true,
            // stepSize: 20
          },
        },
      ],
      xAxes: [
        {
          stacked: false,
          gridLines: {
            color: "transparent",
          },
        },
      ],
    },
  };

  const fetchData = (start, end) => {
    (async () => {
      try {
        setWaiting(true);
        await startActionWithPromise(
          newUsersAction,
          {
            params: {
              startDate: start,
              endDate: end
            },
            successCallback: () => { },
            failedCallback: () => { },
          },
          dispatch
        );
        setWaiting(false);

        const dateDiff = getDayDiff(dateRange);
        if (dateDiff >= 180 && monthButton.current) monthButton.current.click();
        else if (dateDiff > 30 && weekButton.current) weekButton.current.click();
        else if (dateDiff <= 30 && dayButton.current) dayButton.current.click();
      } catch { }
    })();
  }

  useEffect(() => {
    if (refresh) {
      const dates = dateRange.split('-');
      if (dates.length === 2) {
        fetchData(dates[0], dates[1]);
      }
    }
  }, [refresh]);

  useEffect(() => {
    const dates = dateRange.split('-');
    if (dates.length === 2) {
      fetchData(dates[0], dates[1]);
    }

  }, []);

  return (
    <Card className="flex-fill w-100 border">
      <CardHeader>
        <div className="float-right report-date-range">
          <div className="card-actions d-flex justify-content-end">
            <Button
              className="mr-1"
              color={timeline === DAILY ? "secondary" : "light"}
              size="sm"
              onClick={() => setTimeline(DAILY)}
              innerRef={dayButton}
            >
              Daily
            </Button>
            <Button
              className="mr-1"
              color={timeline === WEEKLY ? "secondary" : "light"}
              size="sm"
              onClick={() => setTimeline(WEEKLY)}
              innerRef={weekButton}
            >
              Weekly
            </Button>
            <Button
              color={timeline === MONTHLY ? "secondary" : "light"}
              size="sm"
              onClick={() => setTimeline(MONTHLY)}
              innerRef={monthButton}
            >
              Monthly
            </Button>
          </div>
        </div>
        <CardTitle tag="h5" className="mb-0">
          <span className="border-bottom">User registration</span>
        </CardTitle>
      </CardHeader>
      <CardBody className="pt-0 pb-0">
        <div className="chart chart-sm">
          {waiting ? (
            <div className="loading text-center p-2"><Spinner size="sm" /></div>
          ) : (
            <Bar data={data} options={options} />
          )}
        </div>
      </CardBody>
    </Card>
  );
};

UserRegistrationWidget.propTypes = propTypes;
UserRegistrationWidget.defaultProps = defaultProps;

export default UserRegistrationWidget;
