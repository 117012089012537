import { post, request } from "../helpers/apiHelper";

async function fetch(params) {
  const data = {
    video: params.video || '',
    requester: params.requester || '',
    reviewerId: params.reviewerId || '',
    pageNumber: params.pageNumber,
    pageSize: params.pageSize,
    statuses: params.statuses || [],
    sort: {
      dir: params.dir || 'DESC',
      fieldName: params.orderBy || 'requestDate'
    }
  };
  const result = await post("publishings/search", data);
  return result;
}

/**
 * Assign review request to user
 * @param {number} id - publishings request id
 */
async function assign(id, userId) {
  const result = await request(`publishings/${id}/assignment/${userId}`, null, "put");
  return result;
}

/**
 * Assign review request to myself
 * @param {number} id - publishings request id
 */
async function selfAssign(id) {
  const result = await request(`publishings/${id}/assignment`, null, 'put');
  return result;
}

async function removeAssign(id) {
  const result = await request(`publishings/${id}/assignment`, null, "delete");
  return result;
}

async function approval(data) {
  const result = await post(`publishings/approval`, data);
  return result;
}

async function reject(data) {
  const result = await post(`publishings/reject`, data);
  return result;
}

export default {
  fetch,
  assign,
  selfAssign,
  removeAssign,
  approval,
  reject,
};
