import { post, get, request } from "../helpers/apiHelper";

async function fetchDomains() {
  const result = await get("domain");
  return result;
}

async function getDomainActions(type) {
  const result = await get(`domain/${type}/actions`);
  return result;
}

async function getDomainPermissions(parrams) {
  const result = await get("domain/permissions", parrams);
  return result;
}

async function addPermission(data) {
  const result = await post("domain/permissions", data);
  return result;
}

async function updatePermission(permId, data) {
  const result = await request(`domain/permissions/${permId}`, data, "put");
  return result;
}

async function deletePermission(permId) {
  const result = await request(`domain/permissions/${permId}`, null, "delete");
  return result;
}

async function getDomainRoles(parrams) {
  const result = await get("domain/roles", parrams);
  return result;
}

async function addRole(data) {
  const result = await post("domain/roles", data);
  return result;
}

async function updateRole(roleId, data) {
  const result = await request(`domain/roles/${roleId}`, data, "put");
  return result;
}

async function deleteRole(roleId) {
  const result = await request(`domain/roles/${roleId}`, null, "delete");
  return result;
}

async function fetchGroups(parrams) {
  const result = await get("domain/roleGroups", parrams);
  return result;
}

async function addGroup(data) {
  const result = await post("domain/roleGroups", data);
  return result;
}

async function updateGroup(id, data) {
  const result = await request(`domain/roleGroups/${id}`, data, "put");
  return result;
}

async function deleteGroup(id) {
  const result = await request(`domain/roleGroups/${id}`, null, "delete");
  return result;
}

export default {
  fetchDomains,
  getDomainActions,
  getDomainPermissions,
  addPermission,
  updatePermission,
  deletePermission,
  getDomainRoles,
  addRole,
  updateRole,
  deleteRole,
  fetchGroups,
  addGroup,
  updateGroup,
  deleteGroup,
};
