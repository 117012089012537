import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import Avatar from "../../components/Avatar";
import DataTable from "../../components/DataTable";
import PageSizeSelector from "../../components/base/PageSizeSelector";
import Paging from "../../components/base/Paging";
import config from "../../config/config";
import VideoStatisticsFilter from "./VideoStatisticsFilter";
import {
  fetchVideoStatisticsAction
} from "../../redux/reducers/videoStatisticsReducer";
import { startActionWithPromise } from "../../helpers/saga-promise-helpers";
import { formatPlayedTime } from "../../helpers/utils";
import { AGG_PERIOD } from "./consts";
import StatisticsInsightModal from "./VideoStatisticsInsight";

const VideoStatisticsList = () => {
  const dispatch = useDispatch();

  const videoStats = useSelector(state => state.videoStats?.videoStats) || [];
  const fetching = useSelector(state => state.videoStats?.fetching);
  const totalPages = useSelector(state => state.videoStats.totalPages);
  const pageNumber = useSelector(state => state.videoStats.pageNumber);

  const [filters, setFilters] = useState({
    pageSize: config.DEFAULT_PAGESIZE,
    pageNumber: 1,
    sort: 'TOTALVIEWS',
    order: 'DESC',
    author: '',
    videoText: '',
    reload: false,
  });

  const [insightCtx, setInsightCtx] = useState({});
  const [showModal, setShowModal] = useState(false);

  const defEnd = new Date();
  const defStart = new Date();
  defStart.setDate(defEnd.getDate() - 30); // last 30 days
  const [dataScope, setDataScope] = useState({
    unit: AGG_PERIOD.DAILY,
    startDate: defStart,
    endDate: defEnd,
  });

  const rememberLastScope = (unit, startDate, endDate) => {
    setDataScope({ unit, startDate, endDate });
  }

  const toggleModal = (row) => {
    setShowModal(!showModal);
    if (row)
      setInsightCtx(row);
  }

  const tableColumns = [
    {
      dataField: "title",
      text: "Video",
      sort: true,
      onSort: (field, order) => {
        onColumnSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div className="d-inline-block align-self-center">
          <p className="mb-0" style={{ fontWeight: 600 }}>{row.title}</p>
          <p className="mb-0">{row.subject}</p>
          <p className="mb-0">{row.topic}</p>
          <p className="mb-0 font-italic" style={{ fontSize: '0.75rem' }}>{row.videoId}</p>
        </div>
      ),
      headerStyle: () => {
        return { width: "18%" };
      },
    },
    {
      dataField: "author",
      text: "Author",
      sort: true,
      onSort: (field, order) => {
        onColumnSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div className="d-flex">
          <div className="user-avatar d-flex" style={{ minWidth: '48px' }}>
            <Avatar className="user-avatar d-inline-block align-self-center mr-2" image={row.author.avatar} width="40" height="40" />
          </div>
          <div className="d-inline-block align-self-center">
            <p className="mb-0">{row.author.fullName}</p>
          </div>
        </div>
      ),
      headerStyle: () => {
        return { width: "14%" };
      },
    },
    {
      dataField: "likes",
      text: "Likes",
      type: "number",
      sort: true,
      onSort: (field, order) => {
        onColumnSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div className="align-self-center text-center">
          <p className="mb-0">{row.likes}</p>
        </div>
      ),
      headerStyle: () => {
        return { textAlign: "center", width: "100px" };
      },
    },
    {
      dataField: "views",
      text: "Views",
      type: "number",
      sort: true,
      onSort: (field, order) => {
        onColumnSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div className="align-self-center text-center">
          {row.views}
        </div>
      ),
      headerStyle: () => {
        return { textAlign: "center", width: "100px" };
      },
    },
    {
      dataField: "rollupViews",
      text: "Rollup Views",
      type: "number",
      sort: true,
      onSort: (field, order) => {
        onColumnSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div className="align-self-center text-center">
          {row.rollupViews}
        </div>
      ),
      headerStyle: () => {
        return { textAlign: "center", width: "100px" };
      },
    },
    {
      dataField: "totalViews",
      text: "Total Views",
      type: "number",
      sort: true,
      onSort: (field, order) => {
        onColumnSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div className="align-self-center text-center">
          {row.totalViews}
        </div>
      ),
      headerStyle: () => {
        return { textAlign: "center", width: "100px" };
      },
    },
    {
      dataField: "duration",
      text: "Played Duration",
      type: "number",
      sort: true,
      onSort: (field, order) => {
        onColumnSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div className="align-self-center text-center" onClick={() => toggleModal(row)}>
          <a href="#">{formatPlayedTime(row.playedTime)}</a>
        </div>
      ),
      headerStyle: () => {
        return { textAlign: "center", width: "10%" };
      },
    },
    {
      dataField: "yesterdayDur",
      text: "Yesterday hrs",
      type: "number",
      sort: true,
      onSort: (field, order) => {
        onColumnSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div className="align-self-center text-center">
          {formatPlayedTime(row.yesterdayDur)}
        </div>
      ),
      headerStyle: () => {
        return { textAlign: "center", width: "120px" };
      },
    },
    {
      dataField: "todayDur",
      text: "Today hrs",
      type: "number",
      sort: true,
      onSort: (field, order) => {
        onColumnSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div className="align-self-center text-center">
          {formatPlayedTime(row.todayDur)}
        </div>
      ),
      headerStyle: () => {
        return { textAlign: "center", width: "120px" };
      },
    },
  ];

  const onColumnSort = (field, order) => {
    if (field === filters.sort && order === filters.order) return;
    setFilters({
      ...filters,
      sort: field,
      order,
      reload: true,
    });
  };

  const onSelectedChange = () => { };

  const onSizeChange = size => {
    setFilters({ ...filters, pageSize: size, pageNumber: 1, reload: true });
  };

  const onPageChange = p => {
    setFilters({ ...filters, pageNumber: p, reload: true });
  };

  const handleSubmitFilter = (filtersValues) => {
    setFilters({ ...filters, pageNumber: 1, ...filtersValues, reload: true });
  }

  const fetchData = (params) => {
    (async () => {
      try {
        await startActionWithPromise(
          fetchVideoStatisticsAction,
          {
            params,
            successCallback: () => { },
            failedCallback: () => { },
          },
          dispatch
        );
      } catch { }
    })();
  };

  useEffect(() => {
    if (filters.reload) {
      const params = {
        pageSize: filters.pageSize,
        pageNumber: filters.pageNumber - 1,
      };
      if (filters.sort && filters.order) {
        params.sortColumn = filters.sort.toUpperCase();
        params.sortDir = filters.order.toUpperCase();
      }


      params.owner = filters.author;
      params.videoText = filters.videoText;

      fetchData(params);
      if (filters.reload)
        setFilters({ ...filters, reload: false });
    }
  }, [filters.reload]);

  useEffect(() => {
    const params = {
      pageSize: filters.pageSize,
      pageNumber: filters.pageNumber - 1,
    };
    if (filters.sort && filters.order) {
      params.sortColumn = filters.sort.toUpperCase();
      params.sortDir = filters.order.toUpperCase();
    }
    fetchData(params);
  }, []);

  return (
    <>
      <Card className="w-100 mb-0">
        <CardHeader>
          <VideoStatisticsFilter
            onSubmitFilter={handleSubmitFilter}
            loading={fetching}
          />
        </CardHeader>
        <CardBody className="pt-0 data-list position-relative">
          {fetching && (
            <div className="text-center w-100 bg-white p-4 position-static h-100">
              <Spinner />
            </div>
          )}
          {!fetching && (
            <>
              <DataTable
                keyField="videoId"
                data={videoStats}
                columns={tableColumns}
                sort={filters.sort}
                order={filters.order}
                onSelectedChange={onSelectedChange}
                hideSelectColumn={true}
              />
              <Row className="mt-3">
                <Col lg="6" md="6" className="d-flex">
                  <PageSizeSelector
                    size={filters.pageSize}
                    onChange={onSizeChange}
                  />
                </Col>
                <Col lg="6" md="6" className="d-flex justify-content-end">
                  <Paging
                    totalPages={totalPages}
                    current={pageNumber}
                    show={5}
                    onSelect={onPageChange}
                  />
                </Col>
              </Row>
            </>
          )}
        </CardBody>
      </Card>
      {showModal &&
        <StatisticsInsightModal
          toggleModal={toggleModal}
          rememberLastScope={rememberLastScope}
          dataScope={dataScope}
          insightCtx={insightCtx} />}
    </>
  );
};

export default VideoStatisticsList;
