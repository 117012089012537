export const TRANSFER_STATUSES = {
  completed: 'COMPLETED',
}

export const TRANSFER_STATUS_LABELS = {
  [TRANSFER_STATUSES.completed]: 'Completed',
}

export const TRANSFER_STATUS_COLORS = {
  [TRANSFER_STATUSES.completed]: 'success',
}