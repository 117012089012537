import axios from "axios";
import authHeader from "../helpers/authHeader";
import { post, get, request } from "../helpers/apiHelper";
import * as ENDPOINTS from "../config/endpoints";

async function searchUserSubscriptions(params) {
  if (params && (params.startDate || params.endDate || params.subscriberId || params.sort)) {
    return await post("subscriptions/search", params);
  }
  return await get("subscriptions", params);
}

async function syncWithKillbill(subscriptionId, data) {
  const result = await request(`subscriptions/${subscriptionId}/syncWithKillbill`, data, "put");
  return result;
}

async function cancelUserSubscription(subscriptionId, data) {
  const result = await request(`subscriptions/${subscriptionId}`, null, "delete");
  return result;
}

async function searchMonthlyRevenues(params) {
  if (params && (params.startYearMonth || params.endYearMonth || params.userId || params.transferDate || params.sort)) {
    return await post("subscriptions/monthlyRevenues/search", params);
  }
}

async function transferRevenue(params) {
  return await post("subscriptions/monthlyRevenues/transfer", params);
}

async function aggregateMonthlyRevenues(yearMonth) {
  if ( yearMonth ) {
    return await post(`subscriptions/monthlyRevenues/aggregate?yearMonth=${yearMonth}`, null);
  } else {
    return await post("subscriptions/monthlyRevenues/aggregate", null);
  }
}

async function searchRevenueRecords(params) {
  return await post("reports/revenues", params);
}

async function transferMonthlyRevenues(params) {
  return await post("subscriptions/monthlyRevenues/transferMonthlyRevenues", params);
}

async function searchFundsTransfers(params) {
  if (params && (params.startYearMonth || params.endYearMonth || params.userId || params.transferDate || params.sort)) {
    return await post("subscriptions/fundsTransfers/search", params);
  }
}

async function searchConnectedAccounts(params) {
  if (params && (params.startYearMonth || params.endYearMonth || params.userId || params.transferDate || params.sort)) {
    return await post("subscriptions/connectedAccounts/search", params);
  }
}

async function refreshConnectedAccount(accountId) {
  const result = await request(`subscriptions/connectedAccounts/${accountId}`, null, "put");
  return result;
}

async function removeConnectedAccount(accountId) {
  const result = await request(`subscriptions/connectedAccounts/${accountId}`, null, "delete");
  return result;
}

async function getCatalogs() {
  return await get("subscriptions/catalogs");
}

async function submitJsonCatalog(params) {
  if (params && params.effectiveDate) {
    return await post("subscriptions/catalogs/submitJsonCatalog", params);
  }
}

async function getPlanOptionTypes() {
  return await get("subscriptions/planOptionTypes");
}

async function createPlanOptionType(params) {
  if (params && params.name) {
    return await post("subscriptions/planOptionTypes", params);
  }
}

async function updatedPlanOptionType(params) {
  const result = await request(`subscriptions/planOptionTypes/${params.name}`, params, "put");
  return result;
}

async function getProducts() {
  return await get("subscriptions/products");
}

async function createProductPlan(params) {
  if (params && params.parentProductId) {
    return await post(`subscriptions/products/${params.parentProductId}/productPlans`, params);
  } else {
    return null;
  }
}

async function updateProductPlan(params) {
  const result = await request(`subscriptions/products/${params.parentProductId}/productPlans/${params.id}`, params, "put");
  return result;
}

async function createProduct(params) {
  if (params && params.kbProductName) {
    return await post(`subscriptions/products`, params);
  } else {
    return null;
  }
}

async function updateProduct(params) {
  const result = await request(`subscriptions/products/${params.productId}`, params, "put");
  return result;
}

async function getStoragePackages() {
  return await get("subscriptions/storagePackages");
}

async function createStoragePackage(params) {
  if (params && params.name) {
    return await post(`subscriptions/storagePackages`, params);
  } else {
    return null;
  }
}

async function updateStoragePackage(params) {
  const result = await request(`subscriptions/storagePackages/${params.name}`, params, "put");
  return result;
}

async function sendConnectedAccountSetupRequest(userId) {
  if (userId) {
    return await post(`subscriptions/users/${userId}/connectedAccountSetup`, null);
  } else {
    console.log("Missing user id");
  }
}

export default {
  searchUserSubscriptions,
  syncWithKillbill,
  cancelUserSubscription,
  searchMonthlyRevenues,
  transferRevenue,
  aggregateMonthlyRevenues,
  searchRevenueRecords,
  transferMonthlyRevenues,
  searchFundsTransfers,
  searchConnectedAccounts,
  refreshConnectedAccount,
  removeConnectedAccount,
  getCatalogs,
  submitJsonCatalog,
  getPlanOptionTypes,
  createPlanOptionType,
  updatedPlanOptionType,
  getProducts,
  createProduct,
  updateProduct,
  createProductPlan,
  updateProductPlan,
  getStoragePackages,
  createStoragePackage,
  updateStoragePackage,
  sendConnectedAccountSetupRequest
};
