import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import waiter from "redux-waiters";
import rootReducer from "../reducers/index";

import rootSaga from "../saga";

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(waiter, sagaMiddleware))
);

sagaMiddleware.run(rootSaga);

export default store;
