import React from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import PropTypes from "prop-types";

import { deleteUserOrphanedVideosAction } from "../../redux/reducers/reportReducer";
import { useWaitingStatus } from "../../hooks/useWaiter";

const propTypes = {
  row: PropTypes.object,
  modal: PropTypes.bool,
  message: PropTypes.string,
  toggle: PropTypes.func,
  onConfirm: PropTypes.func,
};

const defaultProps = {
  row: {},
  modal: false,
  message: "",
  toggle: () => {},
  onConfirm: () => {},
};

const ConfirmModal = props => {
  const isWaiting = useWaitingStatus(deleteUserOrphanedVideosAction.id);
  const isDeleting = useSelector(state => state.report.deleting);

  const toggle = () => {
    if (props.toggle) props.toggle("modalConfirm");
  };

  return (
    <Modal
      isOpen={props.modal}
      toggle={toggle}
      backdrop="static"
      centered
      size="sm"
      className="comfirmation-modal"
    >
      <ModalHeader toggle={toggle}>Confirmation</ModalHeader>
      <ModalBody className="m-3">
        <div className="" dangerouslySetInnerHTML={{ __html: props.message }} />
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" disabled={isWaiting || isDeleting} onClick={toggle}>
          No
        </Button>{" "}
        <Button color="primary" disabled={isWaiting || isDeleting} onClick={props.onConfirm}>
          {(isWaiting || isDeleting) && (
            <Spinner color="secondary" size="sm" className="ml-2" />
          )}{" "}
          Yes
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ConfirmModal.propTypes = propTypes;
ConfirmModal.defaultProps = defaultProps;

export default React.memo(ConfirmModal);
