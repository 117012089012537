
export const RESOURCE_TYPE_PDF = {
    name: "PDF-PPT files",
    code: "PDF_PPT",
}

export const RESOURCE_TYPE_CLASS = {
    name: "Live classes",
    code: "LIVE_CLASS",
}

export const RESOURCE_TYPES = [RESOURCE_TYPE_PDF, RESOURCE_TYPE_CLASS];

export const RESOURCE_SUBTYPES = [
    {
        name: "Text book",
        code: "TEXTBOOK"
    },
    {
        name: "Slide",
        code: "SLIDE"
    },
    {
        name: "Exercise",
        code: "EXERCISE"
    }
]
