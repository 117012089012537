import { post, request } from "../helpers/apiHelper";

async function fetch(params) {
  return await post("logs/filter", params);
}

async function deleteLog(logId) {
  return await request(`logs/${logId}`, null, 'delete');
}

export default {
  fetch,
  deleteLog
};
