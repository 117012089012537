const items = [];
for (let i = 1; i <= 20; i++) {
  items.push({
    id: i,
    videoId: '6e467840-3784-4dfa-baff-ce5c3d6609c5',
    buyerId: 'xxxxx1',
    ownerId: 'xxxxx2',
    total: 5,
    commission: 1,
    video: {
      uid: '6e467840-3784-4dfa-baff-ce5c3d6609c5',
      title: 'Physics ' + i,
    },
    buyer: {
      uid: 'xxxxx1',
      fullname: 'John Doe',
      email: 'johndoe@gmail.com'
    },
    owner: {
      uid: 'xxxxx2',
      fullname: 'Jack Sparow',
      email: 'jacksparow@gmail.com'
    },
    payment_method: 'STRIPE',
    status: 'COMPLETED',
    createdAt: '2021-06-29T14:19:13Z',
  });
}

export const orders = {
  "grossRevenue": 20,
  "totalCommission": 3,
  "data": items,
  "total": 15,
  "pageNumber": 0,
  "pageSize": 10
}