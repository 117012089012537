import _ from "lodash";
import moment from "moment";

const tenPct = 0.1; // 10%
const msPerMinute = 60 * 1000;
const msPerHour = msPerMinute * 60;
const msPerDay = msPerHour * 24;
const msPerMonth = msPerDay * 30;
const msPerYear = msPerDay * 365;

export function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function getPermsGroup(permissions) {
  const permGroups = [];
  const _group = _.groupBy(permissions, "type");

  if (_group) {
    _.forOwn(_group, function (group, key) {
      if (group) {
        permGroups.push({
          type: key,
          perms: _.sortBy(group, ["action"]),
        });
      }
    });
  }
  return permGroups ? _.sortBy(permGroups, ["type"]) : [];
}

export function validEmail(email) {
  const emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  return emailRegex.test(email);
}

export function validPhone(phone) {
  const phoneRegex = /^(\+91-|\+91|0)?\d{10}$/; // Change this regex based on requirement
  return phoneRegex.test(phone);
}

export function ucFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export function getMimeTypeFromBuffer(byteArray) {
  try {
    var arr = (new Uint8Array(byteArray)).subarray(0, 4);
    var header = '';
    for (var i = 0; i < arr.length; i++) {
      header += arr[i].toString(16);
    }

    // Check the file signature against known types
    var type = 'unknown';
    switch (header) {
      case '89504e47':
        type = 'image/png';
        break;
      case '47494638':
        type = 'image/gif';
        break;
      case 'ffd8ffe0':
      case 'ffd8ffe1':
      case 'ffd8ffe2':
        type = 'image/jpeg';
        break;
      case '25504446':
        type = 'application/pdf';
        break;
      default:
        break;
    }
    return type;
  } catch { }
  return 'unknown';
}

export function getInitials(name, fallback = '?') {
  if (!name || typeof name !== 'string') return fallback
  return name
    .replace(/\s+/, ' ')
    .split(' ') // Repeated spaces results in empty strings
    .slice(0, 2)
    .map(v => v && v[0].toUpperCase()) // Watch out for empty strings
    .join('')
}

export function formatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export function numberToUSD(num) {
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return formatter.format(num);
  // return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export function isUUID(uuid) {
  let s = "" + uuid;

  s = s.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
  if (s === null) {
    return false;
  }
  return true;
}

export const DateFormats = {
  YYYYMMDD: 'YYYY/MM/DD',
  MMDDYYYY: 'MM/DD/YYYY',
}

export function toDateString(date, format = DateFormats.MMDDYYYY) {
  if (!date) return;

  if (format === DateFormats.YYYYMMDD)
    return `${date.getFullYear()}/${_.padStart(date.getMonth() + 1, 2, 0)}/${_.padStart(date.getDate(), 2, 0)}`;
  return `${_.padStart(date.getMonth() + 1, 2, 0)}/${_.padStart(date.getDate(), 2, 0)}/${date.getFullYear()}`;
}

export function toDatetimeString(date) {
  return;
}

// convert mm/dd/yyyy string to date at start of day
export function mmddyyyyToSod(mmddyyyy) {
  const numbers = mmddyyyy.split('/');
  const year = parseInt(numbers[2]);
  const monthIndex = parseInt(numbers[0]) - 1;
  const day = parseInt(numbers[1]);
  return new Date(year, monthIndex, day);
}

// convert mm/dd/yyyy string to date at end of day
export function mmddyyyyToEod(mmddyyyy) {
  const numbers = mmddyyyy.split('/');
  const year = parseInt(numbers[2]);
  const monthIndex = parseInt(numbers[0]) - 1;
  const day = parseInt(numbers[1]);
  return new Date(year, monthIndex, day, 23, 59, 59, 999);
}

export function addDays(srcDate, numbOfDays) {
  const newDate = new Date();
  newDate.setDate(srcDate.getDate() + numbOfDays);
  return newDate;
}

export function daysDiff(date1, date2) {
  const timeDifference = date2.getTime() - date1.getTime();
  const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
  return daysDifference;
}

export function numberToMoney(num) {
  let a = num ? Math.round(num * 100) / 100 : 0;
  return a.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function formatPlayedTime(playedSeconds) {
  if (!playedSeconds)
    return '';
  const totalSeconds = Number(playedSeconds);
  if (totalSeconds <= 0)
    return '';

  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor(totalSeconds % 3600 / 60);
  const seconds = Math.floor(totalSeconds % 3600 % 60);

  return (hours < 10 ? String(hours).padStart(2, "0") : String(hours)) + ":" +
    String(minutes).padStart(2, "0") + ":" +
    String(seconds).padStart(2, "0");
}

export function printPrice(price) {
  if (!price)
    return '';
  if (price.currency.symbolPosition === 'LEFT')
    return `${price.currency.symbol}${price.amount}`
  return `${price.amount}${price.currency.symbol}`;
}

export function printResourceType(type) {
  if (type === 'LIVE_CLASS')
    return 'Live class';
  return 'PDF - PPT';
}


function utcToLocalTime(aDate) {
  const local = new Date();
  const offset = local.getTimezoneOffset();
  const date = new Date(aDate);
  return (new Date(date.getTime() - offset * 60000));
}

export function printRelativeTime(aDate) {
  const localDate = new Date(aDate) ; //utcToLocalTime(aDate);

  const current = new Date();
  const elapsed = current.valueOf() - localDate.valueOf();
  let relativeTime = new moment(localDate).format("YYYY-MM-DD HH:mm:ss");

  if (elapsed < msPerDay) {
    if (elapsed <= msPerMinute * tenPct)
      relativeTime = "Just now";
    else if (elapsed < msPerMinute) {
      const value = Math.round(elapsed / 1000);
      relativeTime = value <= 1 ? 'a second ago' : value + ' seconds ago';
    } else if (elapsed <= msPerHour * tenPct) {
      relativeTime = 'A few minutes ago';
    } else if (elapsed < msPerHour) {
      const value = Math.round(elapsed / msPerMinute);
      relativeTime = value <= 1 ? 'a minute ago' : value + ' minutes ago';
    } else if (elapsed < msPerDay) {
      const value = Math.round(elapsed / msPerHour);
      relativeTime = value <= 1 ? 'an hour ago' : value + ' hours ago';
    }
  }
  return relativeTime;
}

export function printDatePart(aDate){
  const localDate = new Date(aDate) ; //utcToLocalTime(aDate);
  return new moment(localDate).format("YYYY-MM-DD");
}


