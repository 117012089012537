import React, { useState, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";

import { Badge, Collapse } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Settings as SettingsIcon } from "react-feather";

import { fetchServices } from "../redux/reducers/settingReducer";

import { ucFirst } from "../helpers/utils";
import Setting from "../pages/setting";
import routes from "../routes/index";
import logo from "../assets/img/fuvi.svg";

const initOpenRoutes = (location, filteredRoutes) => {
  /* Open collapse element that matches current url */
  const pathName = location.pathname;

  let _routes = {};

  filteredRoutes.forEach((route, index) => {
    const isActive = pathName.indexOf(route.path) === 0;
    const isOpen = route.open;
    const isHome = !!(route.containsHome && pathName === "/");

    _routes = { ..._routes, [index]: isActive || isOpen || isHome };
  });

  return _routes;
};

const SidebarCategory = withRouter(
  ({
    name,
    badgeColor,
    badgeText,
    icon: Icon,
    isOpen,
    children,
    onClick,
    location,
    to,
  }) => {
    const getSidebarItemClass = path => {
      return location.pathname.indexOf(path) !== -1 ||
        (location.pathname === "/" && path === "/dashboard")
        ? "active"
        : "";
    };

    return (
      <li className={`sidebar-item ${getSidebarItemClass(to)}`}>
        <span
          data-toggle="collapse"
          className={`sidebar-link ${!isOpen ? "collapsed" : ""}`}
          onClick={onClick}
          aria-expanded={isOpen ? "true" : "false"}
        >
          <Icon size={18} className="align-middle mr-3" />
          <span className="align-middle">{name}</span>
          {badgeColor && badgeText ? (
            <Badge color={badgeColor} size={18} className="sidebar-badge">
              {badgeText}
            </Badge>
          ) : null}
        </span>
        <Collapse isOpen={isOpen}>
          <ul id="item" className="sidebar-dropdown list-unstyled">
            {children}
          </ul>
        </Collapse>
      </li>
    );
  }
);

const SidebarItem = withRouter(
  ({ name, badgeColor, badgeText, icon: Icon, location, to }) => {
    const getSidebarItemClass = path => {
      return location.pathname === path ? "active" : "";
    };

    return (
      <li className={`sidebar-item ${getSidebarItemClass(to)}`}>
        <NavLink to={to} className="sidebar-link" activeClassName="active">
          {Icon ? <Icon size={18} className="align-middle mr-3" /> : null}
          {name}
          {badgeColor && badgeText ? (
            <Badge color={badgeColor} size={18} className="sidebar-badge">
              {badgeText}
            </Badge>
          ) : null}
        </NavLink>
      </li>
    );
  }
);

const Sidebar = ({ location, sidebar, layout }) => {
  const dispatch = useDispatch();

  /** get services and include to sidebar menu at Setting */
  const isBoAdmin = useSelector(state => state.bouser.isBoAdmin);
  const services = useSelector(state => state.setting.services);

  var childs = [
  ];

  if (services && services.length > 0) {
    for (const service of services) {
      let checkExist = childs.find(x => x.name.toLowerCase() === service.toLowerCase());
      if (!checkExist)
        childs.push({
          path: `/setting/${service.toLowerCase()}`,
          name: ucFirst(service),
          component: Setting,
        });
    }
  }

  const settingRoutes = {
    path: "/setting",
    name: "Settings",
    icon: SettingsIcon,
    badgeColor: "secondary",
    badgeText: "",
    children: childs,
    requireBoAdmin: true,
  };

  const settingIndex = routes.findIndex(route => route.name === "Settings");
  if (settingIndex === -1) {
    routes.push(settingRoutes);
  } else {
    routes[settingIndex].children = childs;
  }

  var filteredRoutes = routes.filter(route => {
    return !route.requireBoAdmin || isBoAdmin;
  });
  /** end get services and include to sidebar menu at Setting */

  const [openRoutes, setOpenRoutes] = useState(() => initOpenRoutes(location, filteredRoutes));

  const toggle = index => {
    // Collapse all elements
    Object.keys(openRoutes).forEach(
      item =>
        openRoutes[index] ||
        setOpenRoutes(openRoutes => ({ ...openRoutes, [item]: false }))
    );

    // Toggle selected element
    setOpenRoutes(openRoutes => ({
      ...openRoutes,
      [index]: !openRoutes[index],
    }));
  };

  useEffect(() => {
    dispatch(fetchServices.start());
  }, [dispatch]);

  useEffect(() => {
    setOpenRoutes(() => initOpenRoutes(location, filteredRoutes));
  }, [isBoAdmin]);

  return (
    <nav
      className={`sidebar${!sidebar.isOpen ? " toggled" : ""}${sidebar.isSticky ? " sidebar-sticky" : ""
        }`}
    >
      <div className="sidebar-content">
        <PerfectScrollbar>
          <a className="sidebar-brand" href="/">
            <img
              src={logo}
              alt="Fuvi BackOffice"
              className="img-fluid"
              width="120"
            />
          </a>

          <ul className="sidebar-nav">
            {filteredRoutes.map((category, index) => {
              return (
                <React.Fragment key={index}>
                  {category.header ? (
                    <li className="sidebar-header">{category.header}</li>
                  ) : null}

                  {category.children ? (
                    <SidebarCategory
                      name={category.name}
                      badgeColor={category.badgeColor}
                      badgeText={category.badgeText}
                      icon={category.icon}
                      to={category.path}
                      isOpen={openRoutes[index]}
                      onClick={() => toggle(index)}
                    >
                      {category.children.map((route, index) => (
                        <SidebarItem
                          key={index}
                          name={route.name}
                          to={route.path}
                          badgeColor={route.badgeColor}
                          badgeText={route.badgeText}
                        />
                      ))}
                    </SidebarCategory>
                  ) : (
                    <SidebarItem
                      name={category.name}
                      to={category.path}
                      icon={category.icon}
                      badgeColor={category.badgeColor}
                      badgeText={category.badgeText}
                    />
                  )}
                </React.Fragment>
              );
            })}
          </ul>
        </PerfectScrollbar>
      </div>
    </nav>
  );
};

export default withRouter(
  connect(store => ({
    sidebar: store.sidebar,
    layout: store.layout,
  }))(Sidebar)
);
