import React, { useState } from "react";
import { useDispatch } from "react-redux";
import AsyncSelect from 'react-select/async';
import PropTypes from "prop-types";

import { searchVideo2Action }  from "../../redux/reducers/videoReducer";
import { startActionWithPromise } from "../../helpers/saga-promise-helpers";

const selectStyles = {
  control: provided => (
    { ...provided, 
      borderWidth: '0px', 
      minHeight:'31px', 
      borderRadius: '2px', 
      boxShadow: '0 0 0 1px #ced4da',
      "&:hover": {
        borderColor: '#ced4da'
      }
    }),
  option: (provided, state) => {
    return {
      ...provided,
      backgroundColor: state.isSelected ? "#86C9CF" : provided.backgroundColor,
      "&:active": {
        backgroundColor: "#86C9CF"
      },
      padding: '3px 8px'
    }
  },
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '4px'
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '1px 8px'
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: '4px'
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: '3',
    borderRadius: '2px',
  }),
};

const propTypes = {
  userId: PropTypes.string,
  onChange: PropTypes.func
}
const defaultProps = {
  userId: '',
  onChange: () => {}
}

const SearchVideoInput = ({ userId, onChange, initialValue, ...rest}) => {
  const dispatch = useDispatch();
  const [videoId, setVideoId] = useState(initialValue);

  const loadOptions = async (inputValue) => {
    try {
      // await delay(5000);
      let params = {
        pageSize: 50,
        pageNumber: 0,
        videoId: videoId,
        videoOwnerId: userId,
        videoOwner: '',
        videoUser: '',
        searchTerm: inputValue || ''
      };
      if ( !inputValue ) { // nothing for searching
        return [];
      }

      const data = await startActionWithPromise(
        searchVideo2Action,
        { params: params, successCallback: () => {}, failedCallback: () => {} },
        dispatch
      );
      const list = data.content?.map(item => ({
        label: `${item.title}`,
        value: item.uid
      })) || [];
      return list;
    } catch {}
    return [];
  }

  const handleInputChange = (inputValue) => {
    // console.log(inputValue);
  }

  const onOptionChange = (option) => {
    setVideoId(option);
    if (onChange) onChange(option);
  }

  return (
    <>
      <AsyncSelect
          styles={selectStyles}
          name="video"
          // cacheOptions
          defaultOptions
          isSearchable
          value={videoId ? videoId : false}
          loadOptions={loadOptions}
          placeholder=""
          onInputChange={handleInputChange}
          onChange={(option) => onOptionChange(option)}
          {...rest}
        />
    </>
  );
}

SearchVideoInput.propTypes = propTypes;
SearchVideoInput.defaultProps = defaultProps;

export default SearchVideoInput;