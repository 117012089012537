import { takeLatest, call, put } from "redux-saga/effects";

import userTrustedServices from "../../services/userTrustedService";
import { alertTypes } from "../constants";
import {
  userTrustedAction,
  disableAction,
  enableAction,
  getAction,
} from "../reducers/userTrustedReducer";
import { sagaPromise } from "../../helpers/saga-promise-helpers";
import { TRUSTED_USER_MESSAGES } from "../../config/messages";

function* fetchWorker(action) {
  try {
    const { data, error } = yield call(
      userTrustedServices.fetch,
      action.payload?.params
    );
    if (error) {
      yield put(userTrustedAction.fetch.error(error));
    } else {
      yield put(userTrustedAction.fetch.success({ ...action, data }));
    }
  } catch (err) {
    yield put(userTrustedAction.fetch.error(err));
  }
}

function* addWorker(action) {
  const { data, error } = yield call(
    userTrustedServices.create,
    action.payload.data
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      yield put({
        type: alertTypes.ERROR,
        message: error.headers["x-message-info"],
      });
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    yield put({
      type: alertTypes.SUCCESS,
      message: TRUSTED_USER_MESSAGES.addSuccess,
    });
    return data;
  }

  return false;
}

function* updateWorker(action) {
  const { data, error } = yield call(
    userTrustedServices.update,
    action.payload.id,
    action.payload.data
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      if (!action.payload?.hideAlert) {
        yield put({
          type: alertTypes.ERROR,
          message: error.headers["x-message-info"],
        });
        throw new Error(error.headers["x-message-info"]);
      }
      return { message: error.headers["x-message-info"] };
    }
  } else {
    if (!action.payload?.hideAlert)
      yield put({
        type: alertTypes.SUCCESS,
        message: TRUSTED_USER_MESSAGES.updateSuccess,
      });

    return data;
  }

  return false;
}

function* deleteWorker(action) {
  const { data, error } = yield call(
    userTrustedServices.remove,
    action.payload.id
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      yield put({
        type: alertTypes.ERROR,
        message: error.headers["x-message-info"],
      });
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    yield put({
      type: alertTypes.SUCCESS,
      message: TRUSTED_USER_MESSAGES.deleteSuccess,
    });
    return data;
  }

  return false;
}

function* disableWorker(action) {
  const { data, error } = yield call(
    userTrustedServices.disable,
    action.payload.id
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      yield put({
        type: alertTypes.ERROR,
        message: error.headers["x-message-info"],
      });
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    yield put({
      type: alertTypes.SUCCESS,
      message: TRUSTED_USER_MESSAGES.disableSuccess,
    });
    return data;
  }

  return false;
}

function* enableWorker(action) {
  const { data, error } = yield call(
    userTrustedServices.enable,
    action.payload.id
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      yield put({
        type: alertTypes.ERROR,
        message: error.headers["x-message-info"],
      });
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    yield put({
      type: alertTypes.SUCCESS,
      message: TRUSTED_USER_MESSAGES.enableSuccess,
    });
    return data;
  }

  return false;
}

function* getWorker(action) {
  try {
    const { data, error } = yield call(
      userTrustedServices.getUserTrusted,
      action.payload?.id
    );
    if (error) {
      yield put(getAction.error(error));
    } else {
      yield put(getAction.success({ ...action, data }));
    }
  } catch (err) {
    yield put(getAction.error(err));
  }
}

function* userTrustedSaga() {
  yield takeLatest(userTrustedAction.fetch.start, fetchWorker);
  yield takeLatest(getAction.start, getWorker);

  // can add more task here for userSaga
  yield takeLatest(
    userTrustedAction.create.start,
    userTrustedAction.create.waiterActionForSaga(sagaPromise(addWorker))
  );
  yield takeLatest(
    userTrustedAction.update.start,
    userTrustedAction.update.waiterActionForSaga(sagaPromise(updateWorker))
  );
  yield takeLatest(
    userTrustedAction.delete.start,
    userTrustedAction.delete.waiterActionForSaga(sagaPromise(deleteWorker))
  );
  yield takeLatest(
    disableAction.start,
    disableAction.waiterActionForSaga(sagaPromise(disableWorker))
  );
  yield takeLatest(
    enableAction.start,
    enableAction.waiterActionForSaga(sagaPromise(enableWorker))
  );
}

export default userTrustedSaga;
