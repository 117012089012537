import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  CardBody,
} from "reactstrap";

import * as Icon from "react-feather";

const MESSAGE_STATES = [
  { value: 'PENDING', label: 'Pending' },
  { value: 'PROCESSING', label: 'Processing' },
  { value: 'CLOSED', label: 'Closed' },
];

const MessageNotes = (props) => {

  const { modal, message, onToggle, onSubmitNote, loading } = props;

  const [newStatus, setNewStatus] = useState();
  const [newNote, setNewNote] = useState('');


  useEffect(() => {
    setNewStatus(message?.status);
  }, [message?.status]);

  const handleFormSubmit = () => {

    const params = {
      messageId: message?.id,
      status: newStatus,
      content: newNote,
    };
    onSubmitNote && onSubmitNote(params);
    onToggle();
  };

  const onStatusChange = (e) => {
    setNewStatus(e.target.value);
  }

  const handleTextChange = (e) => {
    setNewNote(e.target.value);
  }

  return (
    <Modal
      isOpen={modal}
      toggle={() => onToggle("emailModal")}
      centered
      size="lg"
      backdrop="static"
      keyboard={false}
    >
      <ModalHeader>
        <span className="">Update message</span>
      </ModalHeader>
      <ModalBody className="pt-0 pb-0">
        <Card className="w-100 mb-0">
          <CardBody className="pt-0 pb-0 data-list position-relative">
            <div className="mt-3">
              <Row>
                <Col className='d-flex'>
                  <span className="mr-2">Sender:</span>
                  <span className="font-weight-bold">{message?.firstName} {message?.lastName}, {message?.sendAs}, {message.country}. Email address: {message?.emailAddress}</span>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <div>
                    <span className="mr-2">Message:</span>
                  </div>
                  <div>
                    <textarea
                      name="message"
                      id="message"
                      className="w-100"
                      rows={5}
                      readOnly
                      value={message?.message}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className='d-flex'>
                  <span className="mr-2">Status:</span>
                  {
                    MESSAGE_STATES.map(state => (
                      <div className="d-flex align-items-center mr-2">
                        <input
                          className="mr-1 mb-2"
                          type="radio"
                          name={`rad_${state.value}`}
                          id={`rad_${state.value}`}
                          key={`rad_${state.value}`}
                          checked={newStatus === state.value}
                          value={state.value}
                          onChange={(e) => onStatusChange(e)}
                        />
                        <label htmlFor={`rad_${state.value}`}>
                          {state.label}
                        </label>
                      </div>
                    ))
                  }
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <div>
                    {
                      message?.notes.map((note) => (
                        <p className="ml-2 ">
                          {`[${note.ownerName} - ${note.createdDate} - ${note.status}] `} <i>{note.content}</i>
                        </p>
                      ))
                    }
                  </div>
                  <div>
                    <span className="mr-2">Notes:</span>
                  </div>
                  <div>
                    <textarea
                      name="content"
                      id="content"
                      className="w-100"
                      rows={5}
                      value={newNote}
                      onChange={handleTextChange}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
      </ModalBody>
      <ModalFooter>
        <Button
          color="secondary"
          disabled={loading}
          onClick={() => onToggle()}
        >
          Close
        </Button>
        <Button
          className="ml-2"
          color="primary"
          disabled={loading}
          onClick={handleFormSubmit}
        >
          <Icon.Save
            className=""
            style={{
              width: "15px",
              height: "15px",
              //marginTop: "3px",
              marginRight: "2px",
            }}
          />{" "}
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default MessageNotes;
