import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import { authRoutes, dashboard as dashboardRoutes } from "./index";
import DefaultLayout from "../layouts/Default";
import AuthLayout from "../layouts/Auth";
// import Page404 from "../pages/auth/Page404";
import ScrollToTop from "../components/ScrollToTop";
import { ProvideAuth } from "../hooks/useAuth";
import PrivateRoute from "./PrivateRoute";

const childRoutes = (Layout, routes) =>
  routes.map(({ children, path, component: Component }, index) =>
    children ? (
      // Route item with children
      children.map(({ path, component: Component }, index) => (
        <Route
          key={`route--${index}`}
          path={path}
          exact
          render={props => (
            <Layout>
              <Component {...props} />
            </Layout>
          )}
        />
      ))
    ) : (
      // Route item without children
      <Route
        key={`route--${index}`}
        path={path}
        exact
        render={props => (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
      />
    )
  );

const Routes = () => {
  return (
    <ProvideAuth>
      <Router>
        <ScrollToTop>
          <Switch>
            {childRoutes(AuthLayout, [authRoutes])}
            <PrivateRoute path="/">
              {childRoutes(DefaultLayout, dashboardRoutes)}
              <Route
                exact
                path="/"
                render={() => <Redirect to="/dashboard" />}
              />
            </PrivateRoute>
          </Switch>
        </ScrollToTop>
      </Router>
    </ProvideAuth>
  );
};

export default Routes;
