import { takeLatest, call, put } from "redux-saga/effects";

import { sagaPromise } from "../../helpers/saga-promise-helpers";
import roleServices from "../../services/roleService";
import { alertTypes } from "../constants";
import {
  userRolesAction,
  fetchRolesAction,
  setRolesAction,
  addRolesAction,
  subRolesAction,
  deleteRolesAction
} from "../../redux/reducers/roleReducer";
import { ROLE_MESSAGES } from "../../config/messages";

/** user roles */
function* fetchRolesWorker(action) {
  try {
    const { data, error } = yield call(
      roleServices.fetchRoles,
    );
    if (error) {
      yield put(fetchRolesAction.error(error));
    } else {
      yield put(fetchRolesAction.success({ ...action, data }));
    }
  } catch (err) {
    yield put(fetchRolesAction.error(err));
  }
}

function* getUserRolesWorker(action) {
  const { data, error } = yield call(
    roleServices.fetchUserRoles,
    action.payload.userId
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      yield put({
        type: alertTypes.ERROR,
        message: error.headers["x-message-info"],
      });
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    // yield put({
    //   type: alertTypes.SUCCESS,
    //   message: ROLE_MESSAGES.updateSuccess,
    // });
    return data;
  }

  return false;
}

function* setRolesWorker(action) {
  const { data, error } = yield call(
    roleServices.setUserRoles,
    action.payload.userId,
    action.payload.roles,
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      yield put({
        type: alertTypes.ERROR,
        message: error.headers["x-message-info"],
      });
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    yield put({
      type: alertTypes.SUCCESS,
      message: ROLE_MESSAGES.updateSuccess,
    });
    return data;
  }

  return false;
}

function* addRolesWorker(action) {
  const { data, error } = yield call(
    roleServices.addUserRoles,
    action.payload.userId,
    action.payload.roles,
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      yield put({
        type: alertTypes.ERROR,
        message: error.headers["x-message-info"],
      });
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    yield put({
      type: alertTypes.SUCCESS,
      message: ROLE_MESSAGES.updateSuccess,
    });
    return data;
  }

  return false;
}

function* subRolesWorker(action) {
  const { data, error } = yield call(
    roleServices.subUserRoles,
    action.payload.userId,
    action.payload.roles,
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      yield put({
        type: alertTypes.ERROR,
        message: error.headers["x-message-info"],
      });
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    yield put({
      type: alertTypes.SUCCESS,
      message: ROLE_MESSAGES.updateSuccess,
    });
    return data;
  }

  return false;
}

function* deleteRolesWorker(action) {
  const { data, error } = yield call(
    roleServices.deleteUserRoles,
    action.payload.userId,
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      yield put({
        type: alertTypes.ERROR,
        message: error.headers["x-message-info"],
      });
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    yield put({
      type: alertTypes.SUCCESS,
      message: ROLE_MESSAGES.deleteSuccess,
    });
    return data;
  }

  return false;
}


function* roleSaga() {
  yield takeLatest(fetchRolesAction.start, fetchRolesWorker);

  yield takeLatest(
    userRolesAction.start,
    userRolesAction.waiterActionForSaga(sagaPromise(getUserRolesWorker))
  );
  yield takeLatest(
    setRolesAction.start,
    setRolesAction.waiterActionForSaga(sagaPromise(setRolesWorker))
  );
  yield takeLatest(
    addRolesAction.start,
    addRolesAction.waiterActionForSaga(sagaPromise(addRolesWorker))
  );
  yield takeLatest(
    subRolesAction.start,
    subRolesAction.waiterActionForSaga(sagaPromise(subRolesWorker))
  );
  yield takeLatest(
    deleteRolesAction.start,
    deleteRolesAction.waiterActionForSaga(sagaPromise(deleteRolesWorker))
  );
}

export default roleSaga;
