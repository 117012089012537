import React, { useState, useEffect } from "react";
import {
    Container,
    Row,
    Col,
    Button
} from "reactstrap";

import * as Icon from "react-feather";

import SearchUserInput from "./SearchUserInput";
import DRP from "../../components/DateRangePicker";

const VideoSubsFilterForm = ({ loading, subscriberId, expired, initStart, initEnd, mergeFilters }) => {

    const [filters, setFilters] = useState({
        subscriberId: subscriberId,
        expired: expired,
        startDate: initStart,
        endDate: initEnd,
        reload: false,
    });

    const handleSubscriberChange = (option) => {
        setFilters({ ...filters, subscriberId: option ? option.value : undefined });
    }
    
    const handleDateChange = (start, end) => {
        setFilters({ ...filters, startDate: start, endDate: end });
    }

    const handleFilterFormSubmit = () => {
        mergeFilters && mergeFilters({ ...filters, reload: true });
    }

    return (
        <Container fluid className="p-0">
            <Row>
                <h3>Filters</h3>
            </Row>
            <Row className="align-items-end">
                <Col lg="3" >
                    <label htmlFor="subsId" className="mb-1">Subscriber</label>
                    <SearchUserInput
                        onChange={handleSubscriberChange}
                        className="w-100"
                        isClearable={true}
                        name="subsId"
                    />
                </Col>
                <Col lg="4">
                    <div>
                        <div>
                            <label className="mb-1" >Purchased on</label>
                        </div>
                        <div className="d-inline-block item-filter__control">
                            <div className="d-flex position-relative">
                                <DRP onChange={handleDateChange}
                                    showApplyButton={false}
                                    initStart={filters.startDate}
                                    initEnd={filters.endDate}
                                />
                            </div>
                        </div>
                    </div>
                </Col>
                <Col>
                    <Button
                        className=""
                        size=""
                        disabled={loading}
                        onClick={handleFilterFormSubmit}
                        style={{ minWidth: '86px' }}
                    >
                        <Icon.Search style={{ width: '15px', height: '15px', marginTop: '-2px' }} /> Search
                    </Button>
                </Col>
            </Row>
        </Container >
    );
};

export default VideoSubsFilterForm;
