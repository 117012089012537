import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Row,
  Col,
  FormGroup,
  Label
} from "reactstrap";
import PropTypes from "prop-types";

import { startActionWithPromise } from "../../helpers/saga-promise-helpers";
import { deleteUserAction } from "../../redux/reducers/userReducer";

const propTypes = {
  row: PropTypes.object,
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  refreshData: PropTypes.func,
};

const defaultProps = {
  row: {},
  modal: false,
  toggle: () => {},
  refreshData: () => {},
};

const UserDelete = ({ modal, row, toggle, refreshData }) => {
  const dispatch = useDispatch();
  const [isWaiting, setWaiting] = useState(false);
  const [reason, setReason] = useState('');
  const [errors, setErrors] = useState(false);

  const toggleModal = () => {
    if (toggle) toggle('modalDelete');
  }

  const handleTextChange = (event) => {
    setReason(event.target.value);
    if (event.target.value) setErrors({});
  }

  const handleConfirm = async () => {
    if (!row) return;

    let validateErrors = null;

    if (!reason) {
      validateErrors = {
        reason: 'Reason is required.'
      };
    }

    if (validateErrors) {
      setErrors(validateErrors);
      return;
    }

    setWaiting(true);
    try {
      await startActionWithPromise(
        deleteUserAction,
        {
          userId: row.userId,
          reason: reason,
          successCallback,
          failedCallback: () => {},
        },
        dispatch
      );
    } catch {}
    setWaiting(false);
  };

  const successCallback = () => {
    if (refreshData) refreshData();
    toggleModal();
  };

  return (
    <Modal
      isOpen={modal}
      toggle={toggleModal}
      centered
      size="md"
    >
      <ModalHeader>Delete user</ModalHeader>
      <ModalBody className="">
        <Row>
          <Col lg="12">
            <p className="font-weight-bold mb-1">User information: </p>
            {row && (
              <div className="request-detail p-2 border bg-light rounded">
                <div className="" style={{whiteSpace: 'pre-wrap', wordBreak: 'break-word'}}>
                  <p className="mb-0">Fullname: <span>{row?.fullname}</span></p>
                  <p className="mb-0">Username: <span>{row?.username}</span></p>
                  <p className="mb-0">Email: <span>{row?.email}</span></p>
                </div>
              </div>
            )}
          </Col>
        </Row>
        <Row className="mt-2">
          <Col lg="12">
            <FormGroup>
              <Label for="videoId" className="">
                Delete reason (*)
              </Label>
              <textarea
                className={`form-control${
                  errors.reason ? " is-invalid" : ""
                }`}
                id="withdraw_reason"
                name="withdraw_reason"
                placeholder=""
                rows="5"
                defaultValue={reason}
                onChange={handleTextChange}
              />
              {errors.reason && (<p className="text-danger">{errors.reason}</p>)}
            </FormGroup>
          {/* <p className="font-weight-bold mt-2">Do you want to continue?</p> */}
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          color="secondary"
          disabled={isWaiting}
          onClick={toggleModal}
        >
          Cancel
        </Button>
        <Button
          className="ml-2"
          color="primary"
          disabled={isWaiting}
          onClick={handleConfirm}
        >
          Submit
          {isWaiting && (
            <Spinner color="white" size="sm" className="ml-2" />
          )}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

UserDelete.propTypes = propTypes;
UserDelete.defaultProps = defaultProps;

export default UserDelete;
