import React from "react";
import { Badge, UncontrolledTooltip } from "reactstrap";
import * as Icon from "react-feather";

import { ACTIVE_LABELS, ACTIVE_COLORS } from "./constants";

export const getTableColumns = (isBoAdmin, handleUpdate) => {
  
  return [
    {
      dataField: "productId",
      text: "Product Id",
      sort: false,
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div>{row.productId}</div>
      ),
      headerStyle: () => {
        return { width: '25%' };
      },
    },
    {
      dataField: "productName",
      text: "Product Name",
      sort: false,
      formatter: (cell, row) => (
        <div>{row.productName}</div>
      ),
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: "kbProductName",
      text: "KillBill Product Name",
      sort: false,
      formatter: (cell, row) => (
        <div>{row.kbProductName}</div>
      ),
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: "description",
      text: "Description",
      sort: false,
      formatter: (cell, row) => (
        <div>{row.description}</div>
      ),
      headerStyle: () => {
        return { width: '30%' };
      },
    },
    {
      dataField: "type",
      text: "Product Type",
      sort: false,
      formatter: (cell, row) => (
        <div>{row.type}</div>
      ),
      headerStyle: () => {
        return { width: '7%' };
      },
    },
    {
      dataField: "active",
      text: "Active",
      sort: false,
      formatter: (cell, row) => (
        <div className="">
          <Badge
            color={ACTIVE_COLORS[row.active]}
            className="mr-1 mb-1"
          >
            {ACTIVE_LABELS[row.active]}
          </Badge>
        </div>
      ),
      headerStyle: () => {
        return { textAlign: "center", width: '5%' };
      },
      style: () => {
        return { textAlign: "center" };
      },
    },
    
    {
      dataField: "action_col",
      text: "Actions",
      sort: false,
      formatter: (cell, row, rowIndex, formatExtraData) => (
        <div className="actions-col">
          {isBoAdmin && (
            <a href="#" className="mr-2" onClick={e => handleUpdate(row, e)} id={'edit-' + row.action_col}>
              <Icon.Edit className="text-dark" style={{ width: "15px" }} />
              <UncontrolledTooltip target={'edit-' + row.action_col}>
                Edit
              </UncontrolledTooltip>
            </a>
          )}
        </div>
      ),
      formatExtraData: {
        
      },
      headerStyle: () => {
        return { textAlign: "center", width: "130px" };
      },
      style: () => {
        return { textAlign: "center" };
      },
    },
  ];
}