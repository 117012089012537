import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

export function useWaitingStatus(waiter) {
  const waiters = useSelector(state => state.waiter.waiters);
  const [isWaiting, setIsWaiting] = useState(false);

  useEffect(() => {
    if (waiters && waiters.includes(waiter)) {
      setIsWaiting(true);
    } else {
      setIsWaiting(false);
    }
  }, [waiters, waiter]);

  return isWaiting;
}
