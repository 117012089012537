import React from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from "reactstrap";
import PropTypes from "prop-types";
import moment from "moment";

const propTypes = {
  modal: PropTypes.bool,
  toggle: PropTypes.func,
};

const defaultProps = {
  modal: false,
  toggle: () => {},
};

const boldStyles = {
  marginBottom: '0.5rem',
  fontWeight: '600',
  lineHeight: '1.2',
  color: '#000',
}

const SourceSystemsCopy = {
  MM_WEB: 'Web',
  MM_IOS: 'iOS',
  MM_ANDROID: 'Android',
  MM_DESKTOP: 'Desktop',
}

const LogDetail = props => {
  const { modal, toggle, row: log } = props;

  const toggleModal = () => {
    if (toggle) toggle('modalDetail');
  };

  return (
    <Modal isOpen={modal} toggle={toggleModal} centered size="md">
      <ModalHeader toggle={toggleModal}>
        Log detail
      </ModalHeader>
      <ModalBody className="">
        
        <Row>
          <Col lg="12">
            { log && (
              <div className="request-detail">
                <p className="mb-2"><span style={boldStyles}>Summary:</span> {log.summary}</p>
                <p className="mb-2"><span style={boldStyles}>Error code:</span> {log.errorCode}</p>
                <p className="mb-2"><span style={boldStyles}>Platform:</span> {SourceSystemsCopy[log.sourceSystem]||''} {log.platformVersion && <span className="version">{`- ${log.platformVersion}`}</span>}</p>
                <p className="mb-2"><span style={boldStyles}>User id:</span> {log.userId}</p>
                <p className="mb-2"><span style={boldStyles}>Occured at:</span> {log.occuredTimestamp ? moment(log.occuredTimestamp).utc().format('YYYY/MM/DD HH:mm:ss') : ''}</p>
                
                <h5 className="mb-1">Request:</h5>
                <p className="mb-3">{log.details}</p>

                <h5 className="mb-1">Body:</h5>
                {/* <p></p> */}
                <textarea className="form-control" name="" id="" cols="30" readOnly rows="10" defaultValue={log.body}></textarea>
                
                
              </div>
            )}
          </Col>
        </Row>
        
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggleModal}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

LogDetail.propTypes = propTypes;
LogDetail.defaultProps = defaultProps;

export default LogDetail;
