import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Button } from "reactstrap";
import * as Icon from "react-feather";
import classNames from "classnames";

import CustomInput from "./CustomInput";
import SearchUserInput from "./SearchUserInput";
import { isUUID } from "../../helpers/utils";

const VideoFilter = ({onSubmitFilter, loading}) => {

  var videoIdInput = useRef(null);
  var keywordInput = useRef(null);

  const [cancelDebounce, setCancelDebounce] = useState(false);
  const [validateErrors, setValidateErrors] = useState({});

  const [filters, setFilters] = useState({
    keywords: '',
    videoId: '',
    videoOwner: '',
    videoUser: '',
    order: '',
    reload: false
  });

  const fetching = false; // useSelector(state => state.bouser?.fetching);

  const handleKeywordChange = (value) => {
    setFilters({ ...filters, keywords: value});
    setCancelDebounce(false);
  }

  const handleKeywordSubmit = (keywords) => {
    setFilters({ ...filters, keywords: keywords, reload: true});
  };

  const handleVideoIdChange = (value) => {
    if (value && !isUUID(value)) {
      setValidateErrors({
        ...validateErrors,
        videoId: 'Video id must be in uuid format.'
      });
    } else {
      let errors = Object.assign({}, validateErrors);
      delete errors.videoId;

      setValidateErrors({
        ...errors
      });
    }
    setCancelDebounce(false);
    setFilters({ ...filters, videoId: value});
  }

  const handleVideoIdSubmit = (videoId) => {
    setCancelDebounce(true);
    setFilters({ ...filters, videoId: videoId, reload: true});
  };

  const handleInputChange = (value, name) => {
    setFilters({ ...filters, [name]: value});
  }

  const handleInputSubmit = (value, name) => {
    setCancelDebounce(true);
    setFilters({ ...filters, [name]: value, reload: true});
  }

  const handleVideoUserChange = (option) => {
    setFilters({ ...filters, videoUser: option ? option.value : ''});
  }

  const submitFilter = () => {
    if (!filters.keywords && !filters.videoId && !filters.videoOwner && !filters.videoUser) {
      if (keywordInput.current) keywordInput.current.focus();

      return;
    }

    if (validateErrors && validateErrors.videoId) {
      if (videoIdInput.current) videoIdInput.current.focus();

      return;
    }

    setCancelDebounce(true);
    if (onSubmitFilter) onSubmitFilter(filters);
  };

  useEffect(() => {
    if (filters.reload) {
      submitFilter();
      setFilters({ ...filters, reload: false });
    }
  }, [filters.reload]);

  return (
    <>
      <Row className={classNames('video-review-filter')} style={{transition: 'visibility 0.5s, opacity 0.5s linear'}}>
        <Col lg="6" md="12" xs="12" className="mt-1 mb-1">
          <div className="item-filter reviewer-filter">
            <label htmlFor="" className="mb-0">Video id</label>
            <div className="d-inline-block ml-2 item-filter__control">
              <CustomInput
                name="videoId"
                // value={filters.videoId}
                className={classNames('w-100', {'is-invalid': validateErrors.videoId ? true : false})}
                placeholder=""
                disabled={fetching}
                debounceTime={3000}
                cancelDebounce={cancelDebounce}
                onChange={handleVideoIdChange}
                onSubmit={handleVideoIdSubmit}
                innerRef={videoIdInput}
                maxLength="36"
              />
            </div>
          </div>
        </Col>
        
        <Col lg="6" md="12" xs="12" className="mt-1 mb-1">
          <div className="item-filter video-type-filter">
            <label htmlFor="" className="mb-0">Video owner</label>
            <div className="d-inline-block ml-2 item-filter__control">
              <CustomInput
                name="videoOwner"
                value={filters.videoOwner}
                className="w-100"
                placeholder=""
                disabled={fetching}
                debounceTime={3000}
                cancelDebounce={cancelDebounce}
                onChange={handleInputChange}
                onSubmit={handleInputSubmit}
                />
            </div>
          </div>
        </Col>
        <Col lg="6" md="12" xs="12" className="mt-1 mb-1">
          <div className="item-filter">
            <label htmlFor="" className="mb-0">Keywords</label>
            <div className="d-inline-block item-filter__control ml-2">
              <CustomInput
                name="keywords"
                value={filters.keywords}
                className="w-100"
                placeholder="Title, subject, topic..."
                disabled={fetching}
                debounceTime={3000}
                cancelDebounce={cancelDebounce}
                onChange={handleKeywordChange}
                onSubmit={handleKeywordSubmit}
                innerRef={keywordInput}
              />
            </div>
          </div>
        </Col>
        <Col lg="6" md="12" xs="12" className="mt-1 mb-1 d-flex justify-content-end">
          <Button
            className="ml-3"
            size=""
            disabled={loading}
            // color="secondary"
            onClick={(e) => {setCancelDebounce(true); submitFilter(e);}}
          >
            <Icon.Search className="" style={{ width: '15px', height: '15px', marginTop: '-2px' }} /> Search video
          </Button>
          <div className="item-filter video-type-filter d-none">
            <label htmlFor="" className="mb-0">Video user</label>
            <div className="d-inline-block ml-2 item-filter__control">
              <SearchUserInput
                onChange={handleVideoUserChange}
                className="w-100"
                isClearable={true}
                />
            </div>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default React.memo(VideoFilter);