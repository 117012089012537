import React from "react";
import moment from "moment";
import { UncontrolledTooltip, Row, Col } from "reactstrap";
import * as Icon from "react-feather";
import { MinusCircle, PlusCircle } from "react-feather";
import ReactJson from 'react-json-view';

import { AUDIT_TYPE_COPY } from "./constants";

const ObjectView = ({obj}) => {
  try {
    if (typeof obj === 'object') {
      if (Array.isArray(obj)) {
        return (
          <>
            {obj.map((item, i) => 
              <ObjectView key={i} obj={item} />
            )}
          </>
        )
      }
      return (
        <div className="">
          {
            Object.keys(obj).sort().map((key, i) => (
              <ObjectField key={i} fieldName={key} fieldValue={obj[key]} />
            ))
          }
        </div>
      )
    } else {
      return (
        <>
          <div className="field-value">{String(obj)}</div>
        </>
      )
    }
  } catch {}
}
const ObjectField = ({fieldName, fieldValue}) => {
  let valueType = typeof fieldValue;
  switch(valueType) {
    case 'string':
    case 'number':
      return (
        <div className="field-group mb-1">
          <div className="field-name">{fieldName}: <span className="field-value font-italic">{fieldValue}</span></div>
          
        </div>
      )
    case 'boolean':
      return (
        <div className="field-group mb-1">
          <div className="field-name">{fieldName}: <span className="field-value font-italic">{fieldValue ? 'true' : 'false'}</span></div>
          
        </div>
      )
    case 'object':
      return (
        <div className="field-group mb-1">
          <div className="field-name">{fieldName}:</div>
          <div className="field-value sub-values" style={{paddingLeft: '10px'}}>
            <ObjectView obj={fieldValue} />
          </div>
        </div>
      )
    default:
      return <></>
  }
}

export const getTableColumns = (handleSort = () => {}, handleDetailClick) => {
  return [
    {
      dataField: "type",
      text: "Type",
      sort: true,
      formatter: (cell, row) => (
        <div className="">
          {AUDIT_TYPE_COPY[row.entityType] || row.entityType}
        </div>
      ),
      // headerStyle: () => {
      //   return { textAlign: "center" };
      // },
    },
    {
      dataField: "action",
      text: "Action",
      sort: false,
      formatter: (cell, row) => (
        <div>
          {row.action}
        </div>
      ),
      // headerStyle: () => {
      //   return { width: "10%" };
      // },
    },
    {
      dataField: "createdBy",
      text: "User",
      sort: true,
      onSort: (field, order) => {
        handleSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div>
          <div className="sum">{row.createdBy}</div>
          {row.reason && (
            <div className="reason" style={{fontStyle:'italic'}}>"{row.reason}"</div>
          )}
        </div>
      ),
    },
    {
      dataField: "createdDate",
      text: "Audited at",
      sort: true,
      onSort: (field, order) => {
        handleSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div className="">
          {row.createdDate ? moment(row.createdDate, 'YYYY-MM-DDTHH:mm:ssZ').format('HH:mm:ss') : ''}<br/>
          {row.createdDate ? moment(row.createdDate, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY/MM/DD') : ''}
        </div>
      ),
      headerStyle: () => {
        return { width: 120 };
      },
    },
    {
      dataField: "target",
      text: "Target",
      sort: false,
      formatter: (cell, row) => (
        <div>
          {/* {row.targetInfo && <TargetInfo row={row} />} */}
          { row.targetInfo && <ObjectView obj={row.targetInfo} /> }
        </div>
      ),
      headerStyle: () => {
        return { width: "20%" };
      },
    },
    /*
    {
      dataField: "fromValue",
      text: "From value",
      sort: false,
      headerStyle: () => {
        return {  };
      },
      formatter: (cell, row) => (
        <div>
          {row.fromValue && <FromValue log={row}/>}
        </div>
      ),
    },
    {
      dataField: "toValue",
      text: "To value",
      sort: false,
      formatter: (cell, row) => (
        <div className="">
          {row.toValue && <ToValue log={row}/>}
        </div>
      ),
      headerStyle: () => {
        return {  };
      },
    },
    */
    {
      dataField: "action_col",
      text: "Actions",
      sort: false,
      formatter: (cell, row, rowIndex, formatExtraData) => (
        <div className="actions-col text-center">
          <a href="#" className="mr-2" onClick={e => formatExtraData.showLogDetail(row, e)} id={'detail-' + row.id}>
            <Icon.Eye className="text-dark" style={{ width: "15px" }} />
            <UncontrolledTooltip target={'detail-' + row.id}>
              Detail
            </UncontrolledTooltip>
          </a>
        </div>
      ),
      formatExtraData: {
        showLogDetail: handleDetailClick ? handleDetailClick : () => {},
      },
      headerStyle: () => {
        return { width: "130px", textAlign: "center" };
      },
    },
  ];
}

export const expandRow = {
  renderer: row => (
    <Row>
      <Col lg="4">
        <div className="col-title font-weight-bold">Target</div>
        <div className="col-body">
          <div className="json-viewer">
            <ReactJson src={row.target || {}} />
          </div>
        </div>
      </Col>
      <Col lg="4">
        <div className="col-title font-weight-bold">From value</div>
        <div className="col-body">
          <div className="json-viewer">
            <ReactJson src={row.fromValue || {}} />
          </div>
        </div>
      </Col>
      <Col lg="4">
        <div className="col-title font-weight-bold">To value</div>
        <div className="col-body">
          <div className="json-viewer">
            <ReactJson src={row.toValue || {}} />
          </div>
        </div>
      </Col>
    </Row>
  ),
  showExpandColumn: true,
  expandHeaderColumnRenderer: ({ isAnyExpands }) =>
    isAnyExpands ? (
      <MinusCircle width={16} height={16} />
    ) : (
      <PlusCircle width={16} height={16} />
    ),
  expandColumnRenderer: ({ expanded }) =>
    expanded ? (
      <MinusCircle width={16} height={16} />
    ) : (
      <PlusCircle width={16} height={16} />
    )
};