import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Spinner,
  FormGroup,
  Label,
} from "reactstrap";
import * as Icon from "react-feather";
import _ from "lodash";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import { useForm } from "react-hook-form";

import { roleGroupActions } from "../../redux/reducers/domainRoleGroupReducer";
import { fetchDomainsAction } from "../../redux/reducers/domainReducer";
import { roleActions } from "../../redux/reducers/domainRoleReducer";
import { permissionActions } from "../../redux/reducers/domainPermissionReducer";
import { startActionWithPromise } from "../../helpers/saga-promise-helpers";
import { alertTypes } from "../../redux/constants";
import CustomDropdown from "../../components/base/CustomDropdown";

const COMMON_ACIONS = ['READ', 'CREATE', 'WRITE', 'DELETE'];
const SORTED_ACTIONS = [
  'READ',
  'CREATE',
  'WRITE',
  'DELETE',
  'PUBLISH',
  'LOCK',
  'UNLOCK',
  'REDISTRIBUTE',
  'WITHDRAW',
];
const COLUMNS = [
  { name: 'READ', width: '50px' },
  { name: 'CREATE', width: '60px' },
  { name: 'WRITE', width: '53px' },
  { name: 'DELETE', width: '60px' },
  { name: 'PUBLISH', width: '81px' },
  { name: 'LOCK', width: '73px' },
  { name: 'UNLOCK', width: '79px' },
  { name: 'REDISTRIBUTE', width: '120px' },
  { name: 'WITHDRAW', width: '105px' },
];

// const isPermissionGroupShow = (role, permGroup) => {
//   var types = [];
//   if (role.group) {
//     types = role.group.name.toLowerCase().indexOf('video') !== -1 ? VideoSharingTypes : role.group.name.toLowerCase().indexOf('chapter') !== -1 ? ChapterSharingTypes : AllTypes;
//   }

//   return types.indexOf(permGroup.type) !== -1;
// }

const havePermChecked = (permissions, group_perms) => {
  return _.find(group_perms, (p) => {
      return _.find(permissions, {id: p.id});
    }
  )  ? true : false;
}

export const getSortPermssions = perms => {
  const sorted = _.sortBy(perms, function (item) {
    return SORTED_ACTIONS.indexOf(item.action?.toUpperCase());
  });

  return sorted;
};

const getPermGroupColumns = perms => {
  const columns = [];
  _.forEach(COLUMNS, col => {
    const perm = perms.find(x => x.action === col.name) || false;
    columns.push({ ...col, perm });
  });
  _.forEach(perms, perm => {
    const item = columns.find(x => x.name === perm.action) || false;
    if (!item) columns.push({ name: perm.action, width: 'auto', perm });
  });
  return columns;
};

/**
 * Check 2 array of object difference or not
 */
const isDifference = (arr1, arr2) => {
  if (!arr1 || !arr2) return false;

  try {
    const dif = _.differenceWith(arr1, arr2, _.isEqual);
    const dif2 = _.differenceWith(arr2, arr1, _.isEqual);

    if (dif.length || dif2.length) return true;
  } catch {}

  return false;
};

const CustomTables = () => {
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();

  const submitButton = useRef(null);

  const [pristine, setPristine] = useState(true);
  const [waiting, setWaiting] = useState(false);

  const domains = useSelector(state => state.domain.domains);
  const permissions = useSelector(state => state.domainPermissions.permissions);
  var permGroups = useSelector(state => state.domainPermissions.permGroups);
  const roles = useSelector(state => state.domainRoles.roles);
  const roleGroups = useSelector(state => state.domainRoleGroups.groups);

  const [filteredGroup, setFilteredGroup] = useState();
  const [filteredRole, setFilteredRole] = useState();

  permGroups = permGroups.map(group => {
    const perms = getSortPermssions(group.perms);
    const gr = { ...group, perms: perms };
    return gr;
  });

  permGroups = permGroups.map(group => {
    const columns = getPermGroupColumns(group.perms);
    const gr = { ...group, columns: columns };
    return gr;
  });

  const filterRoles = roles.filter(role => {
    if (!filteredGroup) return true;

    return role.group?.id === filteredGroup.id;
  });

  const displayRoles = roles.filter(role => {
    if (filteredRole) {
      return filteredRole.id === role.id;
    }
    if (filteredGroup) {
      return role.group?.id === filteredGroup.id;
    }
    return true;
  });

  const getDomainName = type => {
    const domain = domains.find(x => x.name === type);
    return domain ? domain.text : type;
  };

  const fetchData = () => {
    (async () => {
      try {
        await startActionWithPromise(
          permissionActions.fetch,
          {
            params: { pageSize: 1000 }, // to get all permissions
            successCallback: () => {},
            failedCallback: () => {},
          },
          dispatch
        );
      } catch {}
    })();

    dispatch(roleActions.fetch.start({ params: { pageSize: 1000 } }));
  };

  /**
   * update a role
   */
  const updateRole = async role => {
    try {
      const response = await startActionWithPromise(
        roleActions.update,
        { id: role.id, data: role, hideAlert: true },
        dispatch
      );
      if (response && response.message) return response.message;
      return false;
    } catch (error) {
      return error.message;
    }
  };

  /**
   * Handle form submit
   */
  const onSubmit = async data => {
    const successArr = [];
    const errorArr = [];
    const updateQueues = [];

    setWaiting(true);
    _.forEach(roles, role => {
      const perms = data.permissions[role.id] || [];
      const selectedPerms = permissions.filter(
        perm => _.indexOf(perms, perm.id.toString()) !== -1
      );
      const isDif = isDifference(role.permissions, selectedPerms);

      if (isDif || (!role.permissions?.length && selectedPerms.length)) {
        // permissions changed -> update role
        const roleToUpdate = { ...role, permissions: selectedPerms };

        // only update role in filtered list
        if (_.find(displayRoles, { id: roleToUpdate.id }))
          updateQueues.push(roleToUpdate);
      }
    });

    if (updateQueues.length) {
      for (let i = 0; i < updateQueues.length; i += 1) {
        const roleToUpdate = updateQueues[i];
        const errorMessage = await updateRole(roleToUpdate);
        if (errorMessage) {
          errorArr.push({
            id: roleToUpdate.id,
            name: roleToUpdate.name,
            message: errorMessage,
          });
        } else {
          successArr.push(roleToUpdate);
        }
      }
    }

    if (successArr.length) {
      dispatch({
        type: alertTypes.SUCCESS,
        message: `${
          successArr.length > 1 ? "Role is updated successfully." : "Role is updated successfully."
        }`,
      });
    }

    if (errorArr.length) {
      // let roleNames = errorArr.map(error => error.name).join(", ");
      const errorItems = errorArr
        .map(error => `<li>${error.name}: ${error.message}</li>`)
        .join("");
      const errorMessages = `${errorArr.length} ${
        errorArr.length > 1 ? "Role" : "Role"
      } is update failed. <ul class="pl-3">${errorItems}</ul>`;
      dispatch({ type: alertTypes.ERROR, message: errorMessages });
    }

    if (successArr.length) fetchData(); // refresh data if have a role updated

    setWaiting(false);
  };

  const triggerSubmitForm = () => {
    if (submitButton.current) submitButton.current.click();
  };

  const onGroupChanged = item => {
    setFilteredGroup(item);
    setFilteredRole(false);
  };

  const onRoleChanged = item => {
    setFilteredRole(item);
  };

  useEffect(() => {
    fetchData();
    dispatch(fetchDomainsAction.start());
    dispatch(roleGroupActions.fetch.start({ pageSize: 100 }));
  }, []);

  return (
    <>
      <Card className="w-100 roles-perms mb-0">
        <CardHeader className="">
          <Row>
            <Col lg="6" xs="6" className="d-flex">
              <div className="filters d-inline">
                <label
                  htmlFor="filter_group"
                  className="mr-1 mb-0 font-weight-bold"
                >
                  Group
                </label>
                <CustomDropdown
                  id="filter_group"
                  label="All"
                  textField="name"
                  className="mr-4"
                  size="sm"
                  color="light"
                  items={roleGroups}
                  onChange={onGroupChanged}
                />

                <label
                  htmlFor="filter_role"
                  className="mr-1 mb-0 font-weight-bold"
                >
                  Role
                </label>
                <CustomDropdown
                  id="filter_role"
                  label="All"
                  textField="name"
                  className="mr-2"
                  size="sm"
                  color="light"
                  items={filterRoles}
                  onChange={onRoleChanged}
                />
              </div>
            </Col>
            <Col lg="6" xs="6" className="d-flex justify-content-end">
              <div className="bulk-actions">
                <Button
                  color="primary"
                  size="sm"
                  disabled={pristine || waiting}
                  onClick={() => triggerSubmitForm(true)}
                >
                  {waiting ? (
                    <Spinner color="secondary" size="sm" className="ml-2" />
                  ) : (
                    <Icon.Save height="18" />
                  )}
                  SAVE CHANGES
                </Button>
              </div>
            </Col>
          </Row>
        </CardHeader>
        <CardBody className="mb-0 pt-0">
          <SimpleBar className="custom-scrollbar" autoHide={false}>
            <form onSubmit={handleSubmit(onSubmit)} id="form-permission">
              {displayRoles.map(role => (
                <div
                  className="role-group border"
                  key={`role-group--${role.id}`}
                >
                  <div className="role-group__head pt-3 pl-3 pr-3 pb-2 border-bottom bg-light">
                    <h3 className="role-group__title">{role.name}</h3>
                    <div className="role-group__desc">{role.description}</div>
                  </div>
                  <div className="role-group__perms pt-2 pl-3 pr-3 pb-0">
                    <h4 className="">Permissions:</h4>
                    {permGroups && 
                      permGroups.map(group => havePermChecked(role.permissions, group.perms) && (
                        <div
                          className="perm-group mb-3"
                          key={`perm-group--${group.type}`}
                        >
                          <div className="perm-group__name font-weight-bold">
                            {getDomainName(group.type)}
                          </div>
                          <div className="perm-group-items mt-1">
                            {group.columns &&
                              group.columns.map((column, index) => (
                                <React.Fragment
                                  key={`perm-item-wrap--${role.id}-${index}`}
                                >
                                  {column.perm ? (
                                    <FormGroup
                                      check
                                      inline
                                      className="mr-4"
                                      key={`perm-item--${column.perm.id}`}
                                    >
                                      <Label check>
                                        <input
                                          className="form-check-input"
                                          name={`permissions[${role.id}][]`}
                                          type="checkbox"
                                          defaultChecked={_.find(
                                            role.permissions,
                                            { id: column.perm.id }
                                          )}
                                          value={column.perm.id}
                                          onClick={() => setPristine(false)}
                                          ref={register}
                                        />
                                        {_.upperFirst(
                                          column.perm.action?.toLowerCase()
                                        )}
                                      </Label>
                                    </FormGroup>
                                  ) : (
                                    <>
                                      {COMMON_ACIONS.indexOf(column.name) !==
                                        -1 && (
                                        <FormGroup
                                          check
                                          inline
                                          className="mr-4"
                                          key={`perm-item--empty-${role.id}-${index}`}
                                          style={{
                                            width:
                                              COMMON_ACIONS.indexOf(
                                                column.name
                                              ) !== -1
                                                ? column.width
                                                : "",
                                          }}
                                        />
                                      )}
                                    </>
                                  )}
                                </React.Fragment>
                              ))}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              ))}

              <Button
                className="d-none"
                disabled={pristine}
                type="submit"
                innerRef={submitButton}
              >
                Submit
              </Button>
            </form>
          </SimpleBar>
          <Row className="">
            <Col lg="6" className="d-flex" />
            <Col lg="6" xs="6" className="d-flex justify-content-end">
              <div
                className="bulk-actions position-fixed d-none"
                style={{ bottom: "50%", right: "60px" }}
              >
                <Button
                  color="primary"
                  size="sm"
                  disabled={pristine || waiting}
                  onClick={() => triggerSubmitForm(true)}
                >
                  {waiting ? (
                    <Spinner color="secondary" size="sm" className="ml-2" />
                  ) : (
                    <Icon.Save height="18" />
                  )}
                  SAVE CHANGES
                </Button>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

const RolesPermissions = () => {
  return (
    <Container fluid className="p-0">
      <h1 className="page-title">Roles - Permissions</h1>
      <Row>
        <Col lg="12" className="d-flex">
          <CustomTables />
        </Col>
      </Row>
    </Container>
  );
};

export default RolesPermissions;
