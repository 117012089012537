import React, { useState } from "react";
import { Row, Col, Button } from "reactstrap";
import _ from 'lodash';
import * as Icon from "react-feather";
import classNames from "classnames";
import moment from "moment";

import "./FormFilter.scss";
import DRP from "../../components/DateRangePicker";
import SearchUserInput from "./SearchUserInput";

const FormFilter = ({ loading, paging, mergeFilters, user, initStart, initEnd }) => {

  const [filters, setFilters] = useState({
    startDate: initStart,
    endDate: initEnd,
    sellerId: user ? user.userId : '',
    reload: false,
  });

  const handleSellerChange = (option) => {
    setFilters({ ...filters, sellerId: option ? option.value : '' });
  }

  const handleDateChange = (start, end) => {
    setFilters({ ...filters, startDate: start, endDate: end });
  }

  const submitFilter = () => {
    mergeFilters({ ...filters, reload: true });
  }

  return (
    <>
      <Row className={classNames('video-review-filter')} style={{ transition: 'visibility 0.5s, opacity 0.5s linear' }}>
        <Col lg="12" md="12" xs="12" className="">
          <div className="item-filter float-right">
            <div className="d-flex align-items-center">

              <div className="d-flex align-items-center ml-3">
                <label htmlFor="" className="mb-0 text-nowrap text-right" style={{ width: '80px' }}>Seller</label>
                <div className="d-inline-block ml-2 item-filter__control" style={{ width: '200px' }}>
                  <SearchUserInput
                    onChange={handleSellerChange}
                    className="w-100"
                    isClearable={true}
                    name="sellerId"
                  />
                </div>
              </div>
              <div className="d-flex align-items-center">
                <label htmlFor="" className="mb-0" style={{ width: '90px' }}>Date range</label>
                <div className="d-inline-block item-filter__control ml-2">
                  <div className="d-flex position-relative">
                    <DRP onChange={handleDateChange}
                      initStart={initStart}
                      initEnd={initEnd}
                      showApplyButton={false} />
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <Button
                  className="ml-2"
                  size=""
                  //disabled={loading}
                  onClick={(e) => submitFilter(e)}
                  style={{ minWidth: '86px' }}
                >
                  <Icon.Search style={{ width: '15px', height: '15px', marginTop: '-2px' }} /> Search
                </Button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default React.memo(FormFilter);