import React from "react";
import moment from "moment";

export const rowActionTypes = {

};

export const getTableColumns = (handleSort = () => { }, isBoAdmin, state, setState) => {

  return [
    {
      dataField: "orderId",
      text: "Order",
      sort: true,
      onSort: (field, order) => {
        handleSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div className="">
          <div>#{row.orderId}</div>
        </div>
      ),
      headerStyle: () => {
        return { width: '100px' };
      },
    },
    {
      dataField: "seller.fullname",
      text: "Seller",
      sort: false,
      onSort: (field, order) => {
        handleSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div className="d-inline-block fullname align-self-center">
          <p className="mb-0">{row.seller.fullname}</p>
          <p className="mb-0">{row.seller.emailAddress}</p>
          <p className="mb-0 font-italic" style={{ fontSize: '0.75rem' }}>{row.seller.userId}</p>
        </div>
      ),
      headerStyle: () => {
        return { textAlign: 'Center', minWidth: '15%' };
      },
    },
    {
      dataField: "productId",
      text: "Item(s)",
      sort: false,
      formatter: (cell, row) => (
        <div className="" id="{row.productId}">
          <div>{row.productTitle}</div>
        </div>
      ),
      headerStyle: () => {
        return { textAlign: 'Center', minWidth: '15%' };
      },
    },
    {
      dataField: "unitPrice",
      text: "Unit Price",
      sort: true,
      formatter: (cell, row) => (
        <div>
          ${row.unitPrice}
        </div>
      ),
      headerStyle: () => {
        return { textAlign: 'Center', width: '80px' };
      },
      style: () => {
        return { textAlign: 'Right', marginRight: '4px' };
      }
    },
    {
      dataField: "quantity",
      text: "Quantity",
      sort: true,
      formatter: (cell, row) => (
        <div>
          {row.quantity}
        </div>
      ),
      headerStyle: () => {
        return { textAlign: 'Center', width: '100px' };
      },
      style: () => {
        return { textAlign: 'Right', marginRight: '4px' };
      }
    },
    {
      dataField: "seller.earningAmount",
      text: "Income",
      sort: true,
      formatter: (cell, row) => (
        <div>
          ${row.seller.earningAmount}
        </div>
      ),
      headerStyle: () => {
        return { textAlign: 'Center', width: '100px' };
      },
      style: () => {
        return { textAlign: 'Right', marginRight: '4px' };
      }
    },
    {
      dataField: "transactionFee",
      text: "Transaction Fee",
      sort: true,
      formatter: (cell, row) => (
        <div>${row.transactionFee}</div>
      ),
      headerStyle: () => {
        return { textAlign: 'Center', width: '100px' };
      },
      style: () => {
        return { textAlign: 'Right', marginRight: '4px' };
      }
    },
    {
      dataField: "videoOwner.earningAmount",
      text: "Video Commission",
      sort: true,
      formatter: (cell, row) => (
        <div>{row.videoOwner ? '$' : ''}{row.videoOwner?.earningAmount}</div>
      ),
      headerStyle: () => {
        return { textAlign: 'Center', width: '120px' };
      },
      style: () => {
        return { textAlign: 'Right', marginRight: '4px' };
      }
    },
    {
      dataField: "textbookOwner.earningAmount",
      text: "Textbook Commission",
      sort: true,
      formatter: (cell, row) => (
        <div>{row.textbookOwner ? '$' : ''}{row.textbookOwner?.earningAmount}</div>
      ),
      headerStyle: () => {
        return { textAlign: 'Center', width: '120px' };
      },
      style: () => {
        return { textAlign: 'Right', marginRight: '4px' };
      }
    },
    {
      dataField: "platform.earningAmount",
      text: "Platform Commission",
      sort: true,
      formatter: (cell, row) => (
        <div>{row.platform ? '$' : ''}{row.platform?.earningAmount}</div>
      ),
      headerStyle: () => {
        return { textAlign: 'Center', width: '120px' };
      },
      style: () => {
        return { textAlign: 'Right', marginRight: '4px' };
      }
    },
    {
      dataField: "purchasedDate",
      text: "Purchased date",
      sort: true,
      onSort: (field, order) => {
        handleSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div className="">
          {row.purchasedDate ? moment(row.purchasedDate, 'YYYY-MM-DD').format('YYYY/MM/DD') : ''}
        </div>
      ),
      headerStyle: () => {
        return { width: '100px' };
      },
    },

  ];
}