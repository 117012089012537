import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
} from "reactstrap";
import _ from "lodash";
import PropTypes from "prop-types";

import { updateProductAction, createProductAction } from "../../redux/reducers/subscriptionReducer";
import { startActionWithPromise } from "../../helpers/saga-promise-helpers";
import useMergeState from "../../hooks/mergeState";

import Selectbox from '../../components/Selectbox/Selectbox';

const propTypes = {
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  refreshData: PropTypes.func,
};

const defaultProps = {
  modal: false,
  toggle: () => {},
  refreshData: () => {},
};

const ProductModal = props => {
  const dispatch = useDispatch();

  const { modal, toggle, refreshData, row, updateMode, isBoAdmin } = props;

  const fetching = false; // useSelector(state => state.user?.fetching);
  const [waiting, setWaiting] = useState(false);

  const productTypeOptions = [
    {
      label: 'BASE',
      value: 'BASE',
    },
    {
      label: 'ADD_ON',
      value: 'ADD_ON',
    },
    {
      label: 'STANDALONE',
      value: 'STANDALONE',
    }
  ];

  const [formState, setFormState] = useMergeState({
    id: row ? row.id : null,
    productId: row && row.productId ? row.productId : '',
    kbProductName: row && row.kbProductName ? row.kbProductName : '',
    productName: row && row.productName ? row.productName : '',
    description: row && row.description ? row.description : '',
    type: row && row.type ? row.type : '',
    active: row && row.active !== undefined ? row.active : false
  });

  const [errorMessage, setErrorMessage] = useState('');

  const handleInputChange = (e) => {
    let inputValue = e.target.type === 'number' ? parseInt(e.target.value) : e.target.value;
    setFormState({[e.target.name]: inputValue});
  }

  const handleSelectboxChange = (selectedOption, actionMeta) => {
    setFormState({[actionMeta.name]: selectedOption.value});
  }

  const addSuccessCallback = () => {
    if (refreshData) refreshData();
    toggle("modalProduct");
  };

  const handleSubmit = async () => {
    setErrorMessage('');

    if (!formState.kbProductName) {
      setErrorMessage('Please enter KillBill Product Name');
      return;
    }

    let regex = new RegExp("^[a-zA-Z-]+$");
    if (!regex.test(formState.kbProductName)) {
      setErrorMessage('Please just use alphabets and hyphen (-) for KillBill Product Name!');
      return;
    }
    
    if (!formState.productName ) {
      setErrorMessage('Please enter Product Name');
      return;
    }

    if (!formState.type ) {
      setErrorMessage('Please select Type');
      return;
    }

    setWaiting(true);
    try {
      await startActionWithPromise(
        updateMode? updateProductAction : createProductAction,
        {
          params: formState,
          successCallback: addSuccessCallback,
          failedCallback: () => {},
        },
        dispatch
      );
    } catch {}
  };

  return (
    <Modal
      isOpen={modal}
      toggle={() => toggle("modalProduct")}
      centered
      size="lg"
      className="add-trusted-modal add-featured-video-modal"
    >
      <ModalHeader>{ updateMode? 'Update' : 'Create' } Service Product</ModalHeader>
      <ModalBody className="pt-0 pb-0">
        <Card className="w-100 mb-0">
          <CardHeader className="">
            
          </CardHeader>
          <CardBody className="pt-0 pb-0 data-list position-relative">
            <Row>
              <Col lg="6">
                <FormGroup>
                  <Label for="productName" className="">
                    Product Name <font color="red">*</font>
                  </Label>
                  <input
                    className="form-control"
                    name="productName"
                    id="productName"
                    type="text"
                    value={formState.productName}
                    onChange={handleInputChange}
                    maxLength={255}
                  />
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <Label for="type" className="">
                    Product Type <font color="red">*</font>
                  </Label>
                  <Selectbox
                    name="type"
                    placeholder="Select..."
                    options={productTypeOptions}
                    onChange={handleSelectboxChange}
                    defaultValue={productTypeOptions.find(it => it.value === formState.type)}
                    isClearable={false}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <FormGroup>
                  <Label for="kbProductName" className="">
                    KillBill Product Name <font color="red">*</font>
                  </Label>
                  <input
                    className="form-control"
                    name="kbProductName"
                    id="kbProductName"
                    type="text"
                    pattern="^[a-zA-Z-]+$"
                    value={formState.kbProductName}
                    onChange={handleInputChange}
                    maxLength={255}
                    width="30px"
                  />
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <div>
                    <FormGroup inline check>
                      <Label>
                        <input
                          className="form-check-input"
                          name="active"
                          id="active"
                          type="checkbox"
                          value={formState.active}
                          checked={formState.active}
                          onChange={() => setFormState({active: !formState.active})}
                        />
                        <span>Active</span>
                      </Label>
                    </FormGroup>
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg="12">
                <FormGroup>
                  <Label for="description" className="">
                    Description
                  </Label>
                  <input
                    className="form-control"
                    name="description"
                    id="description"
                    type="text"
                    value={formState.description}
                    onChange={handleInputChange}
                    maxLength={255}
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </ModalBody>
      <ModalFooter>
        {errorMessage && (
          <div className="float-left text-danger">
            {errorMessage}
          </div>
        )}
        <Button
          color="secondary"
          disabled={!isBoAdmin || fetching}
          onClick={() => toggle("modalProduct")}
        >
          Cancel
        </Button>
        <Button
          className="ml-2"
          color="primary"
          disabled={waiting}
          onClick={handleSubmit}
        >
          { updateMode? 'Update' : 'Create' }
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ProductModal.propTypes = propTypes;
ProductModal.defaultProps = defaultProps;

export default ProductModal;
