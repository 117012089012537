export const SUBMIT_COMPLETED_VALUES = {
  yes: true,
  no: false
}

export const SUBMIT_COMPLETED_LABELS = {
  [SUBMIT_COMPLETED_VALUES.yes]: 'Done',
  [SUBMIT_COMPLETED_VALUES.no]: 'Not yet',
}

export const SUBMIT_COMPLETED_COLORS = {
  [SUBMIT_COMPLETED_VALUES.yes]: 'success',
  [SUBMIT_COMPLETED_VALUES.no]: 'warning',
}

export const TRUE_FALSE_VALUES = {
  yes: true,
  no: false
}

export const TRUE_FALSE_LABELS = {
  [TRUE_FALSE_VALUES.yes]: 'Yes',
  [TRUE_FALSE_VALUES.no]: 'No',
}

export const TRUE_FALSE_COLORS = {
  [TRUE_FALSE_VALUES.yes]: 'success',
  [TRUE_FALSE_VALUES.no]: 'warning',
}
