import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import PropTypes from "prop-types";

import DataTable, { rowActionTypes } from "../../components/DataTable";
import InputSearch from "../../components/base/InputSearch";
import { fetchUsersAction } from "../../redux/reducers/userReducer";
import { userTrustedAction } from "../../redux/reducers/userTrustedReducer";
import { startActionWithPromise } from "../../helpers/saga-promise-helpers";
import UserOrders from "../user-orders";

const propTypes = {
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  refreshData: PropTypes.func,
};

const defaultProps = {
  modal: false,
  toggle: () => {},
  refreshData: () => {},
};

const ModalUserOrders = props => {
  const dispatch = useDispatch();

  const { modal, toggle, refreshData, user } = props;

  const fetchData = () => {
    (async () => {
      try {
        
      } catch {}
    })();
  };

  useEffect(() => {
    fetchData();

    return () => {
      
    };
  }, []);

  return (
    <Modal
      isOpen={modal}
      toggle={() => toggle("modalOrders")}
      centered
      size="xl"
      className="orders-modal"
    >
      <ModalHeader toggle={() => toggle("modalOrders")}>{user.fullname} ›› Orders</ModalHeader>
      <ModalBody className="pt-0 pb-0" style={{height: '75vh'}}>
        <UserOrders user={user} />
      </ModalBody>
    </Modal>
  );
};

ModalUserOrders.propTypes = propTypes;
ModalUserOrders.defaultProps = defaultProps;

export default ModalUserOrders;
