import { alertTypes, RESET_REDUCER } from "../constants";

export default function reducer(state = {}, action) {
  switch (action.type) {
    case alertTypes.SUCCESS:
      return {
        type: "success",
        message: action.message,
      };
    case alertTypes.ERROR:
      return {
        type: "error",
        message: action.message,
      };
    case alertTypes.CLEAR:
    case RESET_REDUCER:
      return {};
    default:
      return state;
  }
}
