import React, { useState, useRef } from "react";
import IdleTimer from "react-idle-timer";
import PropTypes from "prop-types";

import IdleTimeoutModal from "./IdleTimeoutModal";
import config from "../config/config";

const propTypes = {
  handleLogout: PropTypes.func,
};

const defaultProps = {
  handleLogout: () => {},
};

const IdleTracker = ({ handleLogout }) => {
  const idleTimer = useRef(null);

  const initState = {
    timeout: 1000 * 60 * config.SESSION_TIMEOUT,
    userLoggedIn: false,
    isTimedOut: false,
  };

  const [state, setState] = useState(initState);
  const [showModal, setShowModal] = useState(false);

  const onIdle = e => {
    const { isTimedOut } = state;
    if (isTimedOut) {
      console.log("you has been timeouted");
    } else {
      setShowModal(true);
      setState({ ...state, isTimedOut: true });
    }
  };

  const onAction = () => {
    // setIsIdle(false);
    if (idleTimer && idleTimer.current)
      setState({ ...state, isTimedOut: false });
  };

  const onActive = () => {
    setState({ ...state, isTimedOut: false });
    // if (idleTimer && idleTimer.current)
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <IdleTimer
        ref={idleTimer}
        element={document}
        onActive={onActive}
        onIdle={onIdle}
        onAction={onAction}
        debounce={250}
        timeout={state.timeout}
      />

      <IdleTimeoutModal
        modal={showModal}
        handleLogout={handleLogout}
        handleClose={handleClose}
      />
    </>
  );
};

IdleTracker.propTypes = propTypes;
IdleTracker.defaultProps = defaultProps;

export default IdleTracker;
