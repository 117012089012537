import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Spinner,
  Button
} from "reactstrap";
import classNames from "classnames";
import _ from "lodash";
import * as Icon from "react-feather";

import DataTable from "../../components/DataTable";
import useMergeState from '../../hooks/mergeState';
import { useAuth } from "../../hooks/useAuth";

import { getStoragePackagesAction } from "../../redux/reducers/subscriptionReducer";
import { startActionWithPromise } from "../../helpers/saga-promise-helpers";
import "./index.scss";
import { getTableColumns } from "./TableColumns";
import StoragePackageModal from "./StoragePackageModal";

const StoragePackages = ({user}) => {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    modalConfirm: false,
    modalStoragePackage: false,
    action: '',
    message: '',
  });

  const defaultFilters = {
    reload: false,
  };

  const [filters, setFilters] = useMergeState(defaultFilters);

  const [waiting, setWaiting] = useState(false);
  
  const auth = useAuth();
  const isAuthorized = auth.roles &&
    (auth.roles.indexOf('SYSTEM_ADMINS') !== -1 ||
    auth.roles.indexOf('SYSTEM_OPERATORS') !== -1);
  const fetching = false;
  const [storagePackages, setStoragePackages] = useState([]);
  const isBoAdmin = useSelector(state => state.bouser.isBoAdmin);

  const handleUpdateIcon = (row, event) => {
    setState({
      ...state,
      modalStoragePackage: true,
      row
    });
  }

  const tableColumns = getTableColumns(isBoAdmin, handleUpdateIcon);

  const toggleModal = (modal) => {
    if (state[modal]) setState({ ...state, [modal]: !state[modal] });
    else setState({ ...state, [modal]: !state[modal], row: null });
  };

  const refreshDatatable = () => {
    setFilters({ ...filters, reload: true });
  };
  
  const fetchStoragePackages = () => {
    (async () => {
      try {
        // setWaiting(true);
        const resp = await startActionWithPromise(
          getStoragePackagesAction,
          {successCallback: () => {}, failedCallback: () => {}},
          dispatch
        );
        
        if (resp) {
          setStoragePackages(resp);
        } else {
          setStoragePackages([]);
        }
      } catch {}
      setWaiting(false);
    })();
  };

  useEffect(() => {
    if (filters.reload) {
      fetchStoragePackages();
      setFilters({ ...filters, reload: false });
    }
  }, [filters.reload]);

  useEffect(() => {
    fetchStoragePackages();
  }, []);

  ////////// Table's action handlers ///////////
  const handleCreateButton = () => {
    setState({
      ...state,
      modalStoragePackage: true,
      row: {
        id: null,
        unit: 'GB',
        packageType: 'CUSTOM',
        enabled: true
      }
    });
  };
  
  ////////////////////

  return (
    <Container fluid className="p-0">
      <h1 className="page-title">Storage Packages</h1>
      <Row>
        <Col lg="12" className="d-flex">
        <>
          <Card className={classNames('w-100 mb-0 datatable-wrap')}>
            <CardHeader className="">
              <div className="d-flex align-items-right float-right">
                <Button
                  className="ml-2"
                  size=""
                  disabled={!isBoAdmin || fetching}
                  onClick={(e) => handleCreateButton(e)}
                  style={{minWidth: '86px'}}
                  color="success"
                >
                  <Icon.Plus style={{ width: '15px', height: '15px', marginTop: '-2px' }} /> Create
                </Button>
              </div>
            </CardHeader>
            <CardBody className="pt-0 data-list">
              <div className="d-flex align-items-center ml-3">
                &nbsp;
              </div>
              { waiting ? (
                <div className="loading-content text-center p-4 border-top">
                  <Spinner size="sm" />
                </div>
              ) : (
                <>
                  <DataTable
                    keyField="id"
                    data={storagePackages}
                    columns={tableColumns}
                    hideSelectColumn={true}
                  />
                </>
              )}
            </CardBody>
          </Card>
          {state.modalStoragePackage && (
            <StoragePackageModal
              modal={state.modalStoragePackage}
              toggle={toggleModal}
              row={state.row}
              refreshData={refreshDatatable}
              updateMode={state.row.id !== null}
              isBoAdmin={isBoAdmin}
            />
          )}
        </>
        </Col>
      </Row>
    </Container>
  );
};

export default StoragePackages;
