import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Collapse,
  Navbar,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Form,
  Input,
} from "reactstrap";
import {
  User,
} from "react-feather";

import { toggleSidebar } from "../redux/actions/sidebarActions";
import defaultAvatar from "../assets/img/avatars/avatar.svg";
import { useAuth } from "../hooks/useAuth";
import IdleTracker from "./IdleTracker";
import config from "../config/config";
import { fetchLoggedUserRolesAction, getProfileAction } from "../redux/reducers/bouserReducer";
import NewVersionModal from "../components/NewVersionModal";

// const NavbarDropdown = ({
//   children,
//   count,
//   showBadge,
//   header,
//   footer,
//   icon: Icon,
// }) => (
//   <UncontrolledDropdown nav inNavbar className="mr-2">
//     <DropdownToggle nav className="nav-icon dropdown-toggle">
//       <div className="position-relative">
//         <Icon className="align-middle" size={18} />
//         {showBadge ? <span className="indicator">{count}</span> : null}
//       </div>
//     </DropdownToggle>
//     <DropdownMenu right className="dropdown-menu-lg py-0">
//       <div className="dropdown-menu-header position-relative">
//         {count} {header}
//       </div>
//       <ListGroup>{children}</ListGroup>
//       <DropdownItem header className="dropdown-menu-footer">
//         <span className="text-muted">{footer}</span>
//       </DropdownItem>
//     </DropdownMenu>
//   </UncontrolledDropdown>
// );

// const NavbarDropdownItem = ({ icon, title, description, time, spacing }) => (
//   <ListGroupItem>
//     <Row noGutters className="align-items-center">
//       <Col xs={2}>{icon}</Col>
//       <Col xs={10} className={spacing ? "pl-2" : null}>
//         <div className="text-dark">{title}</div>
//         <div className="text-muted small mt-1">{description}</div>
//         <div className="text-muted small mt-1">{time}</div>
//       </Col>
//     </Row>
//   </ListGroupItem>
// );
const NavbarComponent = ({ dispatch }) => {
  const auth = useAuth();
  let [versionInterval, setVersionInterval] = useState(null);
  let [versionModal, setVersionModal] = useState(false);

  const logout = () => {
    auth.signout();
  };

  const fetchCurrentRoles = () => {
    dispatch(fetchLoggedUserRolesAction.start({ id: auth.user?.userId }));
  }



  useEffect(() => {
    let isCancelled = false;
    if (window.performance) {
      if (performance.navigation.type === 1) {
        // console.log( "This page is reloaded" );
        if (auth.locked) auth.signout();
      }
    }

    dispatch(getProfileAction.start({ id: auth.user?.userId }));
    fetchCurrentRoles();

    const checkAppVersion = () => {
      fetch('/app.json', {
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
      })
        .then(response => response.json())
        .then(data => {
          if (data.version && window.APP_VERSION !== '%REACT_APP_VERSION%' && window.APP_VERSION !== data.version) {
            if (isCancelled) return;

            setVersionModal(true);
          }
        });
    }

    // check app version interval
    checkAppVersion();
    let vInterval = setInterval(() => {
      checkAppVersion()
    }, config.VERSION_INTERVAL || 30000);
    setVersionInterval(vInterval);

    return () => {
      isCancelled = true;
      if (versionInterval) clearInterval(versionInterval);
      setVersionInterval(null);
    }
  }, []);

  return (
    <>
      <Navbar color="white" light expand>
        <span
          className="sidebar-toggle d-flex mr-2"
          onClick={() => {
            dispatch(toggleSidebar());
          }}
        >
          <i className="hamburger align-self-center" />
        </span>

        <Form inline>
          <Input
            type="text"
            placeholder="Search projects..."
            aria-label="Search"
            className="form-control-no-border mr-sm-2 d-none"
          />
        </Form>

        <Collapse navbar>
          <div className="ml-auto">
              <h1>Mind Mastery Back Office</h1>            
          </div>
          <Nav className="ml-auto" navbar>
            {/* <NavbarDropdown
              header="New Messages"
              footer="Show all messages"
              icon={MessageCircle}
              count={messages.length}
              showBadge
            >
              {messages.map((item, key) => {
                return (
                  <NavbarDropdownItem
                    key={key}
                    icon={
                      <img
                        className="avatar img-fluid rounded-circle"
                        src={item.avatar}
                        alt={item.name}
                      />
                    }
                    title={item.name}
                    description={item.description}
                    time={item.time}
                    spacing
                  />
                );
              })}
            </NavbarDropdown> */}

            {/* <NavbarDropdown
              header="New Notifications"
              footer="Show all notifications"
              icon={BellOff}
              count={notifications.length}
            >
              {notifications.map((item, key) => {
                let icon = <Bell size={18} className="text-warning" />;

                if (item.type === "important") {
                  icon = <AlertCircle size={18} className="text-danger" />;
                }

                if (item.type === "login") {
                  icon = <Home size={18} className="text-primary" />;
                }

                if (item.type === "request") {
                  icon = <UserPlus size={18} className="text-success" />;
                }

                return (
                  <NavbarDropdownItem
                    key={key}
                    icon={icon}
                    title={item.title}
                    description={item.description}
                    time={item.time}
                  />
                );
              })}
            </NavbarDropdown> */}

            <UncontrolledDropdown nav inNavbar>
              <span className="d-none d-sm-inline-block">
                <DropdownToggle nav caret>
                  <img
                    src={defaultAvatar}
                    className="avatar img-fluid rounded-circle mr-1"
                    alt=""
                  />
                  <span className="text-dark">
                    {`${auth.user?.firstName} ${auth.user?.lastName}`}
                  </span>
                </DropdownToggle>
              </span>
              <DropdownMenu right>
                <DropdownItem>
                  <Link to="/profile">
                    <User size={18} className="align-middle mr-2" />
                    Profile
                  </Link>
                </DropdownItem>
                <DropdownItem onClick={logout}>Sign out</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Navbar>

      <IdleTracker handleLogout={logout} />
      <NewVersionModal modal={versionModal} toggle={() => setVersionModal(!versionModal)} />
    </>
  );
};

export default connect(store => ({
  app: store.app,
}))(NavbarComponent);
