export const REVENUE_TRANSFER_STATES = {
  yes: true,
  no: false
}

export const REVENUE_TRANSFER_STATE_LABELS = {
  [REVENUE_TRANSFER_STATES.yes]: 'Done',
  [REVENUE_TRANSFER_STATES.no]: 'Not yet',
}

export const REVENUE_TRANSFER_STATE_COLORS = {
  [REVENUE_TRANSFER_STATES.yes]: 'success',
  [REVENUE_TRANSFER_STATES.no]: 'warning',
}

export const READY_FOR_TRANSFER_STATUSES = {
  yes: true,
  no: false
}

export const READY_FOR_TRANSFER_STATUS_LABELS = {
  [READY_FOR_TRANSFER_STATUSES.yes]: 'Ready',
  [READY_FOR_TRANSFER_STATUSES.no]: 'Request to setup',
}

export const READY_FOR_TRANSFER_STATUS_COLORS = {
  [READY_FOR_TRANSFER_STATUSES.yes]: 'success',
  [READY_FOR_TRANSFER_STATUSES.no]: 'warning',
}