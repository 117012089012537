import React, { useEffect, useState, useRef, useCallback } from "react";
import { Row, Col, Button, Label, FormGroup } from "reactstrap";
import * as Icon from "react-feather";
import classNames from "classnames";
import Select from "react-select";
import useMergeState from "../../hooks/mergeState";

import DebounceInput from "../../components/DebounceInput";
import { isUUID } from "../../helpers/utils";
import COUNTRY_OPTIONS from "../../config/countries";

const MailFilter = ({ onSubmitFilter, loading }) => {

  const EMPTY_COUNTRY_LABEL = '[EMPTY]';
  const EMPTY_COUNTRY_VALUE = 'NONE';
  const COUNTRIES = [{ value: EMPTY_COUNTRY_VALUE, label: EMPTY_COUNTRY_LABEL }].concat(COUNTRY_OPTIONS);

  const ACTIVATION_STATES = [
    { value: 'ACTIVE', label: 'Activated' },
    { value: 'INACTIVE', label: 'Un-activated' },
    { value: 'BOTH', label: 'Both' },
  ];

  const USER_STATES = [
    { value: 'ENABLED', label: 'Enabled' },
    { value: 'DISABLED', label: 'Disabled' },
    { value: 'BOTH', label: 'Both' },
  ]

  var userIdInput = useRef(null);
  var keywordInput = useRef(null);

  const [cancelDebounce, setCancelDebounce] = useState(false);
  const [validateErrors, setValidateErrors] = useState({});

  const [filters, setFilters] = useMergeState({
    keywords: "",
    userId: "",
    countries: [],
    userStatus: 'ENABLED',
    activationStatus: 'ACTIVE',
    reload: false,
  });

  const handleUserIdChange = useCallback(
    (value, name, target) => {
      if (value && !isUUID(value)) {
        setValidateErrors({
          ...validateErrors,
          userId: "Please enter user id in uuid format.",
        });
        if (target)
          target.setCustomValidity("Please enter user id in uuid format.");
      } else {
        let errors = Object.assign({}, validateErrors);
        delete errors.userId;

        setValidateErrors({
          ...errors,
        });
      }

      setFilters({ userId: value });
      setCancelDebounce(false);
    },
    [validateErrors]
  );

  const handleUserIdSubmit = useCallback((userId) => {
    setFilters({ userId: userId, reload: true });
  }, []);

  const handleInputChange = useCallback((value, name) => {
    setCancelDebounce(false);
    setFilters({ [name]: value });
  }, []);

  const handleCountriesChange = useCallback((value) => {
    setFilters({ countries: value });
  }, []);

  const handleActivationStatusChange = useCallback((option) => {
    setFilters({ activationStatus: option.value });
  }, []);

  const handleUserStatusChange = useCallback((option) => {
    setFilters({ userStatus: option.value });
  }, []);

  const handleInputSubmit = useCallback((value, name) => {
    setFilters({ [name]: value, reload: true });
  }, []);

  const submitFilter = useCallback(() => {
    if (validateErrors && validateErrors.userId) {
      if (userIdInput.current) userIdInput.current.focus();

      return;
    }
    setCancelDebounce(true);
    if (onSubmitFilter) onSubmitFilter(filters);
  }, [userIdInput, validateErrors, filters]);

  useEffect(() => {
    if (filters.reload) {
      submitFilter();
      setFilters({ ...filters, reload: false });
    }
  }, [filters.reload]);

  return (
    <>
      <Row>
        <Col lg="12" md="12" xs="12" className="d-flex justify-content-start">
          <div className="d-flex align-items-center justify-content-start flex-wrap w-100">
            <div
              className="d-flex align-items-center pr-3 mb-2"
              style={{ width: "30%", minWidth: 275 }}
            >
              <label
                htmlFor="userId"
                className="mb-0 mr-1 d-none d-xl-block flex-shrink-0"
              >
                User id
              </label>
              <div className="input-wrap d-flex w-100">
                <DebounceInput
                  name="userId"
                  className={classNames("", {
                    "is-invalid": validateErrors.userId ? true : false,
                  })}
                  placeholder="Search by user id (uuid format)"
                  debounceTime={3000}
                  cancelDebounce={cancelDebounce}
                  onChange={handleUserIdChange}
                  onSubmit={handleUserIdSubmit}
                  innerRef={userIdInput}
                  maxLength="36"
                  required
                // pattern="/^[0-9A-Fa-f]{8}(?:-[0-9A-Fa-f]{4}){3}-[0-9A-Fa-f]{12}$/"
                />
              </div>
            </div>
            <div
              className="d-flex align-items-center pr-3 mb-2"
              style={{ width: "20%", minWidth: 275 }}
            >
              <label htmlFor="" className="mb-0 mr-1 d-none d-xl-block text-nowrap">
                Account status
              </label>
              <div className="input-wrap d-flex w-100">
                <Select
                  isMulti={false}
                  className="w-100 react-select-container"
                  classNamePrefix="react-select"
                  placeholder=""
                  closeMenuOnSelect={true}
                  options={USER_STATES}
                  value={USER_STATES.filter(option => option.value == filters.userStatus)}
                  onChange={handleUserStatusChange}
                />
              </div>
            </div>
            <div
              className="d-flex align-items-center pr-3 mb-2"
              style={{ width: "20%", minWidth: 275 }}
            >
              <label htmlFor="" className="mb-0 mr-1 d-none d-xl-block text-nowrap">
                Activation status
              </label>
              <div className="input-wrap d-flex w-100">
                <Select
                  isMulti={false}
                  className="w-100 react-select-container"
                  classNamePrefix="react-select"
                  placeholder=""
                  closeMenuOnSelect={true}
                  options={ACTIVATION_STATES}
                  value={ACTIVATION_STATES.filter(option => option.value == filters.activationStatus)}
                  onChange={handleActivationStatusChange}
                />
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg="10" md="10" xs="10">
          <div className="d-flex align-items-center justify-content-start flex-wrap w-100">
            <div
              className="d-flex align-items-center pr-3 mb-2"
              style={{ width: "45%", minWidth: 275 }}
            >
              <label htmlFor="" className="mb-0 mr-1 d-none d-xl-block">
                Countries
              </label>
              <div className="input-wrap d-flex w-100">
                <Select
                  isMulti
                  className="w-100 react-select-container"
                  classNamePrefix="react-select"
                  placeholder="Search by country"
                  closeMenuOnSelect={false}
                  options={COUNTRIES}
                  value={filters.countries}
                  onChange={handleCountriesChange}
                />
              </div>
            </div>
            <div
              className="filter-input d-flex align-items-center pr-3 mb-2"
              style={{ width: "50%", minWidth: 275 }}
            >
              <label htmlFor="keywords" className="mb-0 mr-1 d-none d-xl-block">
                Keywords
              </label>
              <div className="input-wrap d-flex w-100">
                <DebounceInput
                  name="keywords"
                  className={classNames("w-100", "d-flex", {
                    "is-invalid": validateErrors.keywords ? true : false,
                  })}
                  placeholder="Search by name, email"
                  debounceTime={3000}
                  cancelDebounce={cancelDebounce}
                  onChange={handleInputChange}
                  onSubmit={handleInputSubmit}
                  innerRef={keywordInput}
                />
              </div>
            </div>

          </div>
        </Col>
        <Col lg="2" md="2" xs="2">
          <div className="d-flex justify-content-end flex-wrap">
            <div>
              <Button
                className="d-flex"
                size=""
                disabled={loading}
                onClick={(e) => {
                  setCancelDebounce(true);
                  submitFilter(e);
                }}
              >
                <Icon.Search
                  className=""
                  style={{
                    width: "15px",
                    height: "15px",
                    marginTop: "3px",
                    marginRight: "2px",
                  }}
                />{" "}
                Search
              </Button>

            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default React.memo(MailFilter);
