import React from "react";
import moment from "moment";
import { UncontrolledTooltip } from "reactstrap";
import * as Icon from "react-feather";

export const getTableColumns = (handleSort = () => {}, handleReclone) => {
  return [
    {
      dataField: "orderId",
      text: "Order",
      sort: true,
      onSort: (field, order) => {
        handleSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div className="">
          <div>#{row.orderId}</div>
        </div>
      ),
      headerStyle: () => {
        return { width: '7%' };
      },
    },
    {
      dataField: "productId",
      text: "Item(s)",
      sort: true,
      formatter: (cell, row) => (
        <div className="">
          <div>{row.productTitle}</div>
          <div>({row.productId})</div>
        </div>
      ),
      headerStyle: () => {
        return { width: '15%' };
      },
    },
    {
      dataField: "unitPrice",
      text: "Unit Price",
      sort: true,
      formatter: (cell, row) => (
        <div>
          ${row.unitPrice}
        </div>
      ),
      headerStyle: () => {
        return { width: 80 };
      },
    },
    {
      dataField: "quantity",
      text: "Quantity",
      sort: true,
      formatter: (cell, row) => (
        <div>
          {row.quantity}
        </div>
      ),
      headerStyle: () => {
        return { width: 80 };
      },
    },
    {
      dataField: "seller.earningAmount",
      text: "Income",
      sort: true,
      formatter: (cell, row) => (
        <div>
          ${row.seller.earningAmount}
        </div>
      ),
      headerStyle: () => {
        return { width: 80 };
      },
    },
    {
      dataField: "transactionFee",
      text: "Transaction Fee",
      sort: true,
      formatter: (cell, row) => (
        <div>${row.transactionFee}</div>
      ),
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: "videoOwner.earningAmount",
      text: "Video Commission",
      sort: true,
      formatter: (cell, row) => (
        <div>{row.videoOwner? '$' : ''}{row.videoOwner?.earningAmount}</div>
      ),
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: "textbookOwner.earningAmount",
      text: "Textbook Commission",
      sort: true,
      formatter: (cell, row) => (
        <div>{row.textbookOwner? '$' : ''}{row.textbookOwner?.earningAmount}</div>
      ),
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: "platform.earningAmount",
      text: "Platform Commission",
      sort: true,
      formatter: (cell, row) => (
        <div>{row.platform? '$' : ''}{row.platform?.earningAmount}</div>
      ),
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: "purchasedDate",
      text: "Purchased date",
      sort: true,
      onSort: (field, order) => {
        handleSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div className="">
          {row.purchasedDate ? moment(row.purchasedDate, 'YYYY-MM-DD').format('YYYY/MM/DD') : ''}
        </div>
      ),
      headerStyle: () => {
        return { width: 100 };
      },
    },
    {
      dataField: "action_col",
      text: "Actions",
      sort: false,
      formatter: (cell, row, rowIndex, formatExtraData) => row.productType === 'VIDEO' ? (
        <div className="actions-col text-center">
          <a className="mr-2" onClick={e => formatExtraData.handleReclone(row, e)} id={'reclone-' + row.id}>
            <Icon.ArrowDownCircle className="text-dark" style={{ width: "15px" }} />
            <UncontrolledTooltip target={'reclone-' + row.id}>
              Re-clone video
            </UncontrolledTooltip>
          </a>
        </div>
      ) : (<div></div>),
      formatExtraData: {
        handleReclone: handleReclone ? handleReclone : () => {},
      },
      headerStyle: () => {
        return { width: "100px", textAlign: "center" };
      },
    },
  ];
}