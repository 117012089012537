import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Spinner,
  FormGroup,
  Label,
} from "reactstrap";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";

import { startActionWithPromise } from "../../helpers/saga-promise-helpers";
import { changePwAction } from "../../redux/reducers/bouserReducer";

const propTypes = {
  row: PropTypes.object,
  modal: PropTypes.bool,
  toggle: PropTypes.func,
};

const defaultProps = {
  row: {},
  modal: false,
  toggle: () => {},
};

const UserChangePassword = ({ modal, row, toggle }) => {
  const dispatch = useDispatch();
  const submitButton = useRef(null);

  const password = useRef({});

  const { register, handleSubmit, watch, errors } = useForm();
  password.current = watch("password", "");

  const [pristine, setPristine] = useState(true);
  const [isWaiting, setWaiting] = useState(false);

  const toggleModal = () => {
    if (toggle) toggle('modalChangePassword');
  }

  const triggerSubmitForm = () => {
    if (submitButton.current) submitButton.current.click();
  };

  const successCallback = () => {
    toggleModal();
  };

  const onSubmit = async values => {
    if (!row) return;

    const postData = {
      newPwd: values.password,
      userId: row.userId,
    };

    setWaiting(true);
    try {
      await startActionWithPromise(
        changePwAction,
        {
          id: row.userId,
          data: postData,
          successCallback,
          failedCallback: () => {},
        },
        dispatch
      );
    } catch (error) {}
    setWaiting(false);
  };

  return (
    <Modal
      isOpen={modal}
      toggle={toggleModal}
      centered
      size="sm"
    >
      <ModalHeader>Change BO user password</ModalHeader>
      <ModalBody className="">
        <div className="user-detail p-2 border bg-light rounded">
          {row && (
            <>
              {row.avatar?.image && (
                <div className="text-center mb-2">
                  <img
                    className="rounded-circle border"
                    width="60"
                    height="auto"
                    src={row?.avatar?.image}
                    alt=""
                  />
                </div>
              )}
              <div className="text-center title mb-2">
                <div className="font-weight-bold">Email: {row.email}</div>
                <div className=""></div>
              </div>
              <div className="text-center title mb-2">
                <div className="font-weight-bold">Fullname: {row ? row?.fullname : ''}</div>
                <div className=""></div>
              </div>
            </>
          )}
        </div>
        <Form
          onSubmit={handleSubmit(onSubmit)}
          className="change-pw-form mt-4"
          method="post"
        >
          <FormGroup>
            <Label>New password</Label>
            <input
              className="form-control"
              size="lg"
              type="password"
              name="password"
              placeholder=""
              onChange={() => setPristine(false)}
              ref={register({
                required: "New password is required.",
                minLength: 6,
              })}
            />
            {errors.password && (
              <p className="text-danger">
                {errors.password?.type === "required"
                  ? "New password is required."
                  : ""}
                {errors.password?.type === "minLength"
                  ? "Password must have at least 6 characters."
                  : ""}
              </p>
            )}
          </FormGroup>
          <FormGroup className="mb-0">
            <Label>Confirm new password</Label>
            <input
              className="form-control"
              size="lg"
              type="password"
              name="password_confirm"
              placeholder=""
              onChange={() => setPristine(false)}
              ref={register({
                validate: value =>
                  value === password.current || "Passwords do not match.",
              })}
            />
            {errors.password_confirm && (
              <p className="text-danger">{errors.password_confirm.message}</p>
            )}
          </FormGroup>

          <div className="">
            <Button
              className="d-none"
              // disabled={pristine}
              type="submit"
              innerRef={submitButton}
            >
              Submit
            </Button>
          </div>
        </Form>
        
      </ModalBody>
      <ModalFooter>
        <Button
          color="secondary"
          disabled={isWaiting}
          onClick={toggleModal}
        >
          Close
        </Button>
        <Button
          className="ml-2"
          color="primary"
          disabled={pristine || isWaiting}
          onClick={() => triggerSubmitForm(true)}
        >
          Submit
          {isWaiting && (
            <Spinner color="white" size="sm" className="ml-2" />
          )}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

UserChangePassword.propTypes = propTypes;
UserChangePassword.defaultProps = defaultProps;

export default UserChangePassword;
