import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  FormGroup,
  Label,
} from "reactstrap";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import moment from "moment";

import { rejectVideoAction } from "../../redux/reducers/reviewReducer";
import { startActionWithPromise } from "../../helpers/saga-promise-helpers";

const propTypes = {
  row: PropTypes.object,
  modal: PropTypes.bool,
  toggle: PropTypes.func,
};

const VideoReject = (props) => {
  const dispatch = useDispatch();
  const submitButton = useRef(null);

  const { modal, toggle, row, refreshData } = props || {};

  const { register, handleSubmit, errors } = useForm();

  const [pristine, setPristine] = useState(true);
  const [waiting, setWaiting] = useState(false);

  const toggleModal = () => {
    if (toggle) toggle("modalReject");
  };

  const triggerSubmitForm = () => {
    if (submitButton.current) submitButton.current.click();
  };

  const onSubmit = async (data) => {
    setWaiting(true);
    try {
      if (row) {
        await startActionWithPromise(
          rejectVideoAction,
          {
            data: {
              id: row.id,
              feedback: data.feedback
            },
            successCallback,
            failedCallback,
          },
          dispatch
        );
      }
    } catch {}
    setWaiting(false);
  };

  const successCallback = () => {
    setWaiting(false);
    if (refreshData) refreshData();
    toggleModal();
  };

  const failedCallback = () => {
    setWaiting(false);
  };

  return (
    <Modal isOpen={modal} toggle={toggleModal} centered size="md">
      <ModalHeader>
        Reject Publishing Request
      </ModalHeader>
      <ModalBody className="">
        <div className="request-detail p-2 border bg-light rounded">
          <div className="title mb-2">
            <div className="font-weight-bold">Requester</div>
            <div className="">{row ? row.requester?.fullName : ''}</div>
          </div>
          <div className="title mb-2">
            <div className="font-weight-bold">Video</div>
            <div className="" style={{whiteSpace: 'pre-wrap', wordBreak: 'break-word'}}>
              <p className="mb-0">Title: <span>{row ? row.video?.title : ''}</span></p>
              <p className="mb-0">Subject: <span>{row ? row.video?.subject : ''}</span></p>
              <p className="mb-0">Topic: <span>{row ? row.video?.topic : ''}</span></p>
              <p className="mb-0">Version: <span>{row ? row.video?.version : ''}</span></p>
            </div>
          </div>
          <div className="title mb-2">
            <div className="font-weight-bold">Request at</div>
            <div className="">{row && row.requestDate ? moment(row.requestDate, 'MM/DD/YYYY HH:mm:ss Z').format('YYYY/MM/DD HH:mm:ss') : ''}</div>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} id="form-update" className="mt-3">
          <Row>
            <Col lg="12">
              <FormGroup>
                <Label for="feedback" className="font-weight-bold">
                  Feedback <span className="text-danger">*</span>
                </Label>
                <textarea
                  className="form-control"
                  name="feedback"
                  id="feedback"
                  cols="30"
                  rows="10"
                  defaultValue={""}
                  onChange={() => setPristine(false)}
                  ref={register({ required: "Feedback is required." })}
                ></textarea>
                
                <p className="text-danger">{errors.feedback?.message}</p>
              </FormGroup>
            </Col>
          </Row>

          <Button
            className="d-none"
            disabled={pristine}
            type="submit"
            innerRef={submitButton}
          >
            Submit
          </Button>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" disabled={waiting} onClick={toggleModal}>
          Cancel
        </Button>{" "}
        <Button
          color="primary"
          disabled={pristine || waiting}
          onClick={() => triggerSubmitForm(true)}
        >
          Reject
        </Button>
      </ModalFooter>
    </Modal>
  );
};

VideoReject.propTypes = propTypes;

export default VideoReject;
