import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Input,
  FormGroup,
  Label,
} from "reactstrap";
import PropTypes from "prop-types";

import DEFAULT_AVATAR from "../../assets/img/avatars/avatar.svg";
import { getAction } from "../../redux/reducers/userTrustedReducer";

const propTypes = {
  row: PropTypes.object,
  modal: PropTypes.bool,
  toggle: PropTypes.func,
};

const defaultProps = {
  row: {},
  modal: false,
  toggle: () => {},
};

const UserUpdate = props => {
  const dispatch = useDispatch();

  const { modal, row } = props;

  const fetching = useSelector(state => state.role.fetching);
  const user = useSelector(state => state.userTrusted.user);

  const toggle = () => {
    if (props.toggle) props.toggle("modalUpdate");
  };

  const handleChange = () => {
  };

  const onSubmit = () => {};

  useEffect(() => {
    if (row && row.userProfile?.userId && modal) {
      dispatch(getAction.start({ id: row.userProfile?.userId }));
    }
  }, [dispatch, modal, row]);

  return (
    <Modal isOpen={modal} toggle={toggle} centered size="md">
      <ModalHeader toggle={toggle}>Publisher profile</ModalHeader>
      <ModalBody className="m-3">
        <Row>
          <Col lg="12" className="">
            <Row>
              <Col lg="12">
                <FormGroup>
                  <Label for="avatar" className="d-none">
                    Avatar
                  </Label>
                  <div className="text-center img-fluid rounded-circle">
                    <img
                      className="rounded-circle"
                      width="100"
                      height="auto"
                      src={
                        user && user.userProfile?.avatar?.image
                          ? user.userProfile.avatar.image
                          : DEFAULT_AVATAR
                      }
                      alt=""
                    />
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <FormGroup>
                  <div>
                    <Label for="enabled" className="">
                      Status
                    </Label>
                  </div>
                  <FormGroup check inline>
                    <Label check>
                      <Input
                        type="radio"
                        value="0"
                        disabled
                        name="enabled"
                        checked={(user && user.enabled) || false}
                        onChange={() => true}
                      />
                      Enabled
                    </Label>
                  </FormGroup>
                  <FormGroup check inline>
                    <Label check>
                      <Input
                        type="radio"
                        value="1"
                        disabled
                        name="enabled"
                        checked={(user && !user.enabled) || false}
                        onChange={() => true}
                      />
                      Disabled
                    </Label>
                  </FormGroup>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <FormGroup>
                  <Label for="firstName" className="">
                    First name
                  </Label>
                  <Input
                    type="text"
                    id="firstName"
                    name="firstName"
                    placeholder="First name"
                    disabled
                    value={user.userProfile?.firstName || ""}
                    onChange={e => handleChange(e)}
                  />
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <Label for="lastName" className="">
                    Last name
                  </Label>
                  <Input
                    type="text"
                    id="lastName"
                    name="lastName"
                    placeholder="Last name"
                    disabled
                    value={user.userProfile?.lastName || ""}
                    onChange={e => handleChange(e)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <FormGroup>
                  <Label for="email" className="">
                    Email
                  </Label>
                  <Input
                    type="text"
                    name="email"
                    id="email"
                    placeholder="Email"
                    disabled
                    value={user.userProfile?.email || ""}
                    onChange={e => handleChange(e)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <FormGroup>
                  <Label for="phone">Phone</Label>
                  <Input
                    type="text"
                    id="phone"
                    name="phone"
                    placeholder="Phone"
                    disabled
                    value={user.userProfile?.phone || ""}
                    onChange={e => handleChange(e)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <FormGroup>
                  <Label for="phone" className="">
                    Trusted On
                  </Label>
                  <Input
                    type="text"
                    id="trustedOn"
                    name="trustedOn"
                    placeholder="Trusted On"
                    disabled
                    value={(user && user.trustedOn) || ""}
                    onChange={e => handleChange(e)}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" disabled={fetching} onClick={toggle}>
          Close
        </Button>
        <Button
          className="ml-2 d-none"
          color="primary"
          disabled={fetching}
          onClick={onSubmit}
        >
          Save changes
        </Button>
      </ModalFooter>
    </Modal>
  );
};

UserUpdate.propTypes = propTypes;
UserUpdate.defaultProps = defaultProps;

export default UserUpdate;
