import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { startActionWithPromise } from "../../helpers/saga-promise-helpers";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";

import styles from './Theme.module.scss';
import { AGG_PERIOD } from "./consts";
import DRP from "../../components/DateRangePicker";
import { Bar } from "react-chartjs-2";
import { mmddyyyyToSod, mmddyyyyToEod } from "../../helpers/utils";

import { fetchVideoStatsAggAction } from "../../redux/reducers/videoStatisticsReducer";

const StatisticsInsightModal = props => {

    const { dataScope, insightCtx, toggleModal, rememberLastScope } = props;

    const [unit, setUnit] = useState(dataScope.unit);
    const [dateRange, setDateRange] = useState({
        startDate: dataScope.startDate,
        endDate: dataScope.endDate
    });

    const saveLastScope = () => {
        rememberLastScope(unit, dateRange.startDate, dateRange.endDate);
    }

    const handleUnitChange = (selectedUnit) => {
        setUnit(selectedUnit);        
    }

    const handleDateChange = (start, end) => {
        const _startDate = mmddyyyyToSod(start);
        const _endDate = mmddyyyyToEod(end);
        setDateRange({ startDate: _startDate, endDate: _endDate });
    };

    const dispatch = useDispatch();
    const fetchData = (params) => {
        (async () => {
            try {
                await startActionWithPromise(
                    fetchVideoStatsAggAction,
                    {
                        params,
                        successCallback: (data) => { transformData(data); },
                        failedCallback: () => { },
                    },
                    dispatch
                );
            } catch { }
        })();
    };

    const transformData = (source) => {
        const newSeries = {
            labels: ['No data'],
            datasets: [
                {
                    label: "Login users",
                    data: [],
                    backgroundColor: "rgb(54, 162, 235)",
                    maxBarThickness: 15
                },
                {
                    label: "Non-login users",
                    data: [],
                    backgroundColor: "rgb(100, 100, 235)",
                    maxBarThickness: 15
                }
            ]
        };

        if (source.videoStatistics?.length > 0) {
            const videoStatistics = source.videoStatistics[0];
            newSeries.labels = videoStatistics.statistics.map(stats => stats.label);
            newSeries.datasets[0].data = videoStatistics.statistics.map(stats => stats.viewDuration);
            newSeries.datasets[1].data = videoStatistics.statistics.map(stats => stats.visitorViewDuration);
        }

        setSeries(newSeries);
    }

    const formatDuration = (given_seconds) => {
        const hours = Math.floor(given_seconds / 3600);
        const minutes = Math.floor((given_seconds - (hours * 3600)) / 60);
        const seconds = given_seconds - (hours * 3600) - (minutes * 60);

        const timeString = hours.toString().padStart(2, '0') + ':' +
            minutes.toString().padStart(2, '0') + ':' +
            seconds.toString().padStart(2, '0');
        return timeString;
    }


    useEffect(() => {
        const params = {
            aggType: unit.code,
            startDate: dateRange.startDate,
            endDate: dateRange.endDate,
            videoIds: [insightCtx.videoId]
        };
        fetchData(params);
        return () => saveLastScope();
    }, [dateRange, unit]);


    const [series, setSeries] = useState({
        labels: ["No data"],
        datasets: [
            {
                label: "Login",
                data: [],
                backgroundColor: "rgb(54, 162, 235)",
                maxBarThickness: 15
            },
            {
                label: "Non-login",
                data: [],
                backgroundColor: "rgb(100, 100, 235)",
                maxBarThickness: 15
            }
        ]
    });

    const options = {        
        tooltips: {
            callbacks: {
                label: (tooltipItem, data) => { return 'Duration: ' + formatDuration(tooltipItem.yLabel); },
            }
        },
        maintainAspectRatio: false,
        legend: {
            display: true,            
        },
        scales: {
            yAxes: [
                {
                    stacked: false,
                    gridLines: {
                        display: true,
                    },
                    ticks: {
                        beginAtZero: true,                        
                        callback: function (value, index, ticks) {
                            return formatDuration(value);
                        }
                    },
                },
            ],
            xAxes: [
                {
                    stacked: false,
                    gridLines: {
                        display: false,
                        color: "transparent",
                    },
                },
            ],
        },
    };

    const dayButton = useRef(null);
    const weekButton = useRef(null);
    const monthButton = useRef(null);

    return (
        <Modal
            isOpen={true}
            centered
            size="xl"
            className={styles.insightModal}
        >
            <ModalHeader><b>{"Played Duration Insight"}</b></ModalHeader>
            <ModalBody className={`pt-0 pb-0 ${styles.modalBody}`}>
                <Card className="w-100 h-100 mb-0">
                    <CardHeader className="">
                        <Row>
                            <Col lg="6">
                                <p><b>{insightCtx.title}</b></p>
                                <p>{insightCtx.subject}</p>
                            </Col>
                            <Col lg="6">
                                <div className="d-flex float-right">
                                    <DRP onChange={handleDateChange}
                                        initStart={dataScope.startDate}
                                        initEnd={dataScope.endDate}
                                        showApplyButton={false}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="float-right report-date-range">
                                    <div className="card-actions d-flex justify-content-end">
                                        <Button
                                            className="mr-1"
                                            color={unit.code === AGG_PERIOD.DAILY.code ? "secondary" : "light"}
                                            size="sm"
                                            onClick={() => handleUnitChange(AGG_PERIOD.DAILY)}
                                            innerRef={dayButton}
                                        >
                                            {AGG_PERIOD.DAILY.name}
                                        </Button>
                                        <Button
                                            className="mr-1"
                                            color={unit.code === AGG_PERIOD.WEEKLY.code ? "secondary" : "light"}
                                            size="sm"
                                            onClick={() => handleUnitChange(AGG_PERIOD.WEEKLY)}
                                            innerRef={weekButton}
                                        >
                                            {AGG_PERIOD.WEEKLY.name}
                                        </Button>
                                        <Button
                                            color={unit.code === AGG_PERIOD.MONTHLY.code ? "secondary" : "light"}
                                            size="sm"
                                            onClick={() => handleUnitChange(AGG_PERIOD.MONTHLY)}
                                            innerRef={monthButton}
                                        >
                                            {AGG_PERIOD.MONTHLY.name}
                                        </Button>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                    </CardHeader>
                    <CardBody className="pt-0 pb-0 h-100 position-relative">
                        <Row className='h-100'>
                            <Col lg="12" className='h-100'>
                                <Bar
                                    height={300}
                                    data={series}
                                    options={options} />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="secondary"
                    onClick={() => toggleModal()}
                >
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    );
}
export default StatisticsInsightModal;