import React, { useState, useRef } from "react";
import {
  Row,
  Col,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  CardBody,
} from "reactstrap";

import Selectbox from "../../components/Selectbox/Selectbox";
import * as Icon from "react-feather";
import JoditEditor from "jodit-react";

const MailComposer = (props) => {
  const editor = useRef(null);

  const { modal, onToggle, onSubmitEmailMessage, loading } = props;

  const FROMS = {
    NO_REPLY: {
      senderName: "No-Reply",
      sender: "noreply@ifuvi.com",
    },
    FUVI: {
      senderName: "FUVI",
      sender: "admin@ifuvi.com",
    },
  };

  let senderOptions = [
    {
      label: FROMS.FUVI.senderName,
      value: FROMS.FUVI.sender,
    },
    {
      label: FROMS.NO_REPLY.senderName,
      value: FROMS.NO_REPLY.sender,
    },
  ];

  const [state, setState] = useState({
    senderName: FROMS.FUVI.senderName,
    sender: FROMS.FUVI.sender,
    recipients: "",
    csvFile: undefined,
    subject: "",
    content: "",
  });

  const [errors, setErrors] = useState({});

  const validateEmailMessage = () => {
    const { recipients, subject, csvFile } = state;
    const content = editor.current.value;

    let validData = true;
    const _errors = {};

    if ((!recipients || recipients.trim() == "") && !csvFile) {
      _errors["recipients"] = "Empty recipients";
      validData = false;
    }

    if (!subject || subject.trim() == "") {
      _errors["subject"] = "Empty subject";
      validData = false;
    }

    const trimContent = content ? content.trim() : "";
    if (trimContent == "" || trimContent == "<p><br></p>") {
      _errors["content"] = "Empty email content";
      validData = false;
    }

    setErrors(_errors);

    return validData;
  };

  const handleMessageSubmit = () => {
    if (validateEmailMessage()) {
      const { senderName, sender, recipients, subject, csvFile } = state;
      const content = editor.current.value;
      const params = {
        senderName,
        sender,
        recipients,
        subject,
        content,
      };
      if (csvFile) params["csvFile"] = csvFile;

      onSubmitEmailMessage && onSubmitEmailMessage(params);

      onToggle("emailModal");
    }
  };

  const handleInputChange = (name, value) => {
    if (value) setErrors({ ...errors, [name]: undefined });
    setState({ ...state, [name]: value });
  };

  const handleSenderChange = (option) => {
    setState({ ...state, senderName: option.label, sender: option.value });
  };

  const handleCSVChange = (e) => {
    if (e.target.value.length === 0) {
      setState((current) => {
        const { csvFile, ...rest } = current;
        return rest;
      });
    } else {
      setErrors({ ...errors, recipients: undefined });
      setState({ ...state, csvFile: e.target.files[0] });
    }
  };

  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/docs/,
    toolbar: true,
    minHeight: 400,
    //disablePlugins: "video,file,print",
    colorPickerDefaultTab: "color",
  };

  return (
    <Modal
      isOpen={modal}
      toggle={() => onToggle("emailModal")}
      centered
      size="lg"
      backdrop="static"
      keyboard={false}
    >
      <ModalHeader>
        <span className="">New email message</span>
      </ModalHeader>
      <ModalBody className="pt-0 pb-0">
        <Card className="w-100 mb-0">
          <CardBody className="pt-0 pb-0 data-list position-relative">
            <div className="mt-3">
              <Row className="mb-3">
                <Col md="2">
                  <span>From:</span>
                </Col>
                <Col md="2">
                  <Selectbox
                    name="from"
                    placeholder=""
                    isClearable={false}
                    defaultValue={senderOptions[0]}
                    options={senderOptions}
                    onChange={handleSenderChange}
                  />
                </Col>
                <Col>
                  <Input
                    type="text"
                    name="from"
                    value={state.sender}
                    readOnly
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md="2">
                  <span>To:</span>
                </Col>
                <Col>
                  <Input
                    type="textarea"
                    name="recipients"
                    id="recipients"
                    rows={2}
                    value={state.recipients}
                    onChange={(e) =>
                      handleInputChange("recipients", `${e.target.value}`)
                    }
                  />
                  <p className="text-danger">{errors.recipients}</p>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md="2"></Col>
                <Col md="2">
                  <span>Contacts from file:</span>
                </Col>
                <Col>
                  <Input
                    type="file"
                    name="csv"
                    accept=".csv"
                    onChange={(e) => handleCSVChange(e)}
                  />
                </Col>
              </Row>
              <Row className="mb-2">
                <Col md="2">
                  <span>Subject:</span>
                </Col>
                <Col>
                  <Input
                    type="text"
                    name="subject"
                    id="subject"
                    value={state.subject}
                    onChange={(e) =>
                      handleInputChange("subject", `${e.target.value}`)
                    }
                  />
                  <p className="text-danger">{errors.subject}</p>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <JoditEditor
                    name="content"
                    id="content"
                    ref={editor}
                    config={config}
                    tabIndex={0} // tabIndex of textarea
                  />
                  <p className="text-danger">{errors.content}</p>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
      </ModalBody>
      <ModalFooter>
        <Button
          color="secondary"
          disabled={loading}
          onClick={() => onToggle("emailModal")}
        >
          Close
        </Button>
        <Button
          className="ml-2"
          color="primary"
          disabled={loading}
          onClick={handleMessageSubmit}
        >
          <Icon.Send
            className=""
            style={{
              width: "15px",
              height: "15px",
              marginTop: "3px",
              marginRight: "2px",
            }}
          />{" "}
          Send
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default MailComposer;
