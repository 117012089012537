import React from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import PropTypes from "prop-types";
import moment from "moment";

import { deleteVideoAction } from "../../redux/reducers/videoReducer";
import { startActionWithPromise } from "../../helpers/saga-promise-helpers";
import { useWaitingStatus } from "../../hooks/useWaiter";
import Avatar from "../../components/Avatar";
import {
  SCOPES_COPY,
  CATEGORY_COPY,
} from "./constants";

const propTypes = {
  row: PropTypes.object,
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  refreshData: PropTypes.func,
};

const defaultProps = {
  row: {},
  modal: false,
  toggle: () => {},
  refreshData: () => {},
};

const VideoDelete = props => {
  const dispatch = useDispatch();
  const isWaiting = useWaitingStatus(deleteVideoAction.id);

  const { modal, row: video, refreshData, toggle: toggleModal } = props;

  const toggle = () => {
    if (toggleModal) toggleModal('modalDelete');
  };

  const successCallback = () => {
    if (refreshData) refreshData();
    toggle();
  };

  const failedCallback = () => {};

  const handleConfirm = async () => {
    try {
      await startActionWithPromise(
        deleteVideoAction,
        { videoId: video.uid, userId: video.owner, successCallback, failedCallback },
        dispatch
      );
    } catch {}
  };

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      centered
      size="md"
      className="comfirmation-modal"
    >
      <ModalHeader toggle={toggle}>Confirmation</ModalHeader>
      <ModalBody className="">
        <div>
          <p className="font-weight-bold">
            Following video will be deleted:
          </p>
          { video && (
            <div className="request-detail p-2 border bg-light rounded">
              <div className="" style={{whiteSpace: 'pre-wrap', wordBreak: 'break-word'}}>
                <p className="mb-0">Title: <span>{video?.title}</span></p>
                <p className="mb-0">Subject: <span>{video?.subject}</span></p>
                <p className="mb-0">Topic: <span>{video?.topic}</span></p>
                <p className="mb-0">Version: <span>{video?.version}</span></p>
                <div className="mb-0 d-flex">
                  <div className="info-label mr-2 align-self-center">Author:</div>
                  <Avatar className="mr-1 align-self-center" image={video?.avatar} fullname={video?.author} width="25" height="25" />
                  <span className="align-self-center">{video.author}</span>
                </div>
                {video.videoUser && (
                  <div className="mb-0 d-flex">
                    <div className="info-label mr-2 align-self-center">Video user:</div>
                    <Avatar className="mr-1 align-self-center" image={video.videoUser?.avatar?.image} fullname={video.videoUser?.fullName} width="25" height="25" />
                    <div className="align-self-center">{video.videoUser?.fullName}</div>
                  </div>
                )}
                <div className="mb-0">Visibility: <span>{SCOPES_COPY[video.visibilityScope]}</span></div>
                <div className="mb-0">Categories: <span>{video.categories ? video.categories.map(x => CATEGORY_COPY[x]).join(', ') : ''}</span></div>
                <p className="mb-0">Created at: <span>{video.createdDate ? moment(video.createdDate).utc().format('YYYY/MM/DD HH:mm:ss') : ''}</span></p>
              </div>
            </div>
          )}
          <p className="font-weight-bold mt-2">Do you want to continue?</p>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" disabled={isWaiting} onClick={toggle}>
          No
        </Button>
        <Button
          className="ml-2"
          color="primary"
          disabled={isWaiting}
          onClick={() => handleConfirm(true)}
        >
          {isWaiting && (
            <>
              <Spinner color="secondary" size="sm" className="ml-2" />
              &nbsp;
            </>
          )}
          Yes
        </Button>
      </ModalFooter>
    </Modal>
  );
};

VideoDelete.propTypes = propTypes;
VideoDelete.defaultProps = defaultProps;

export default React.memo(VideoDelete);
