import { post, get, request } from "../helpers/apiHelper";

async function fetch(params) {
  return await post("orders/history", params);
}

async function create(data) {
  return await post(`orders`, data);
}

async function update(orderId, data) {
  return await request(`orders/${orderId}`, data, "put");
}

async function remove(orderId) {
  return await request(
    `orders/${orderId}`,
    null,
    "delete"
  );
}

async function getOrder(orderId) {
  return await get(`orders/${orderId}`);
}

export default {
  fetch,
  create,
  update,
  remove,
  getOrder
};
