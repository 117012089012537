import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Spinner,
  Badge,
} from "reactstrap";
import * as Icon from "react-feather";
import classNames from "classnames";
import DataTable from "../../components/DataTable";
import PageSizeSelector from "../../components/base/PageSizeSelector";
import Paging from "../../components/base/Paging";
import config from "../../config/config";
import ConfirmModal from "../../components/base/ConfirmModal";
import UserChangePassword from "./UserChangePassword";
import ModalUserOrders from "./ModalUserOrders";
import UserRoles from "./UserRoles";
import UserFilter from "./UserFilter";
import UserDelete from "./UserDelete";
import ActionList, { rowActionTypes } from "./ActionList";
import {
  fetchUsersAction,
  forceLogoutAction,
  resetPwAction,
  revokeRolesAction,
  enableAction,
  disableAction,
  resendActivationAction,
  syncStoragePackageAction,
} from "../../redux/reducers/userReducer";
import { startActionWithPromise } from "../../helpers/saga-promise-helpers";
import Avatar from "../../components/Avatar";
import { printDatePart, printRelativeTime, formatPlayedTime } from "../../helpers/utils";

const UserList = () => {
  const dispatch = useDispatch();

  const isBoAdmin = useSelector((state) => state.bouser.isBoAdmin);
  const users = useSelector((state) => state.user?.users) || [];
  const fetching = false; // useSelector(state => state.user?.fetching);
  const totalPages = useSelector((state) => state.user.totalPages);
  const pageNumber = useSelector((state) => state.user.pageNumber);
  const totalFound = useSelector((state) => state.user.total);

  const [state, setState] = useState({
    modalUpdate: false,
    modalDelete: false,
    modalConfirm: false,
    modalRoles: false,
    modalChangePassword: false,
    modalOrders: false,
    row: null,
    message: "",
  });

  const [filters, setFilters] = useState({
    pageSize: config.DEFAULT_PAGESIZE,
    pageNumber: 1,
    total: 0,
    sort: "",
    sortReal: "accessLog.lastActiveTimestamp",
    order: "DESC",

    keywords: "",
    enabled: "",
    userId: "",
    activationStatus: "",
    userStatus: "",
    onlineStatus: "",

    reload: false,
  });

  const onColumnSort = (field, order) => {
    const realSort =
      field === "fullname"
        ? "firstName"
        : field === "active"
          ? "emailVerification.emailVerified"
          : field === "joiningDate"
            ? "emailVerification.emailVerifiedDate"
            : field;
    if (realSort === filters.sortReal && order === filters.order) return;

    setFilters({
      ...filters,
      sort: field,
      sortReal: realSort,
      order,
      reload: true,
    });
  };

  const tableColumns = [
    {
      dataField: "fullname",
      text: "User",
      sort: true,
      onSort: (field, order) => {
        onColumnSort(field, order);
      },
      sortFunc: () => { },
      formatter: (cell, row) => (
        <div className="d-flex">
          <div className="user-avatar d-flex" style={{ minWidth: "48px" }}>
            {row.avatar?.image ? (
              <Avatar
                className="user-avatar d-inline-block align-self-center mr-2"
                image={row?.avatar?.image}
                fullname={row.fullname}
                width="40"
                height="40"
              />
            ) : (
              <Avatar
                className="user-avatar d-inline-block align-self-center mr-2"
                image={row?.avatar?.image}
                fullname={row.fullname}
                width="40"
                height="40"
              />
            )}
          </div>
          <div className="d-inline-block fullname align-self-center">
            <p
              className="mb-0"
              style={{ textDecoration: row.deleted ? "line-through" : "" }}
            >
              {row.fullname}
            </p>
            <p className="mb-0">{row.email}</p>
            <p className="mb-0">{row.phone}</p>
            <p className="mb-0 font-italic" style={{ fontSize: "0.75rem" }}>
              {row.userId}
            </p>
          </div>
        </div>
      ),
      headerStyle: () => {
        return { width: "25%" };
      },
    },
    {
      dataField: "accessLog.lastActiveTimestamp",
      text: "Online / Time Online",
      sort: true,
      onSort: (field, order) => {
        onColumnSort(field, order);
      },
      sortFunc: () => { },
      formatter: (cell, row) => (
        <div className="text-center">
          <div>
            <span
              className={row.online ? "text-success" : "text-gray"}
              title={row.lastActiveTimestamp ? "Last activity: " + printRelativeTime(row.lastActiveTimestamp) : ''}
            >
              {row.online ? (
                <Icon.CheckCircle width="30" />
              ) : (
                <Icon.Circle width="30" />
              )}
            </span>
          </div>
          {row.lastActiveTimestamp && (
            <div title={row.lastDayTimestamp ? "Session started at: " + printRelativeTime(row.lastDayTimestamp) : ''}>
              {row.lastDayDuration ? formatPlayedTime(row.lastDayDuration / 1000) : ''}
              <small>
                <div>({printDatePart(row.lastActiveTimestamp)})</div>
              </small>
            </div>
          )}
        </div>
      ),
      headerStyle: () => {
        return { width: "12%", textAlign: "center" };
      },
    },
    {
      dataField: "country",
      text: "Country",
      sort: false,
      onSort: (field, order) => {
        onColumnSort(field, order);
      },
      sortFunc: () => { },
      formatter: (cell, row) => (
        <div className="text-center">
          <div>{row.country}</div>
          <div>{row.location}</div>
        </div>
      ),
      headerStyle: () => {
        return { width: "8%", textAlign: "center" };
      },
    },
    {
      dataField: "enabled",
      text: "Status",
      sort: false,
      onSort: (field, order) => {
        onColumnSort(field, order);
      },
      sortFunc: () => { },
      formatter: (cell, row) => (
        <div className="text-center">
          <Badge color={row.enabled ? "success" : "gray"}>
            {row.enabled ? "Enabled" : "Disabled"}
          </Badge>
        </div>
      ),
      headerStyle: () => {
        return { width: "8%", textAlign: "center" };
      },
    },
    {
      dataField: "joiningDate",
      text: "Activated",
      sort: true,
      onSort: (field, order) => {
        onColumnSort(field, order);
      },
      sortFunc: () => { },
      formatter: (cell, row) => (
        <div className="text-center">
          <div>
            {row.active ? (
              <Icon.CheckSquare width="15" />
            ) : (
              <Icon.Square width="15" />
            )}
          </div>
          <small>
            <div>{row.joiningDate && printRelativeTime(row.joiningDate)} </div>
            {(row.device || row.platform) && (
              <div>
                {row.device}&nbsp;{row.platform}
              </div>
            )}
          </small>
        </div>
      ),
      headerStyle: () => {
        return { width: "9%" };
      },
    },
    {
      dataField: "audience",
      text: "Registered as",
      sort: true,
      onSort: (field, order) => {
        onColumnSort(field, order);
      },
      sortFunc: () => { },
      headerStyle: () => {
        return { width: "9%" };
      },
    },
    {
      dataField: "createdDate",
      text: "Registered at",
      sort: true,
      onSort: (field, order) => {
        onColumnSort(field, order);
      },
      sortFunc: () => { },
      formatter: (cell, row) => (
        <div className="text-center">
          <div>
            {row.registeringDate && printRelativeTime(row.registeringDate)}{" "}
          </div>
          {row.regMethod && (
            <div>
              <small>{`with ${row.regMethod}`}</small>
            </div>
          )}
        </div>
      ),
      headerStyle: () => {
        return { width: "9%" };
      },
    },
    {
      dataField: "action_col",
      text: "Actions",
      sort: false,
      formatter: (cell, row) => (
        <div className="actions-col">{renderRowActions(row)}</div>
      ),

      headerStyle: () => {
        return { width: "20%" };
      },
    },
  ];

  const rowActions = [
    {
      name: "Force logout",
      type: rowActionTypes.LOGOUT,
      classes: "text-dark",
    },
    {
      name: "Change password",
      type: rowActionTypes.CHANGE_PW,
      classes: "text-dark",
    },
    {
      name: "Reset password",
      type: rowActionTypes.RESET_PW,
      classes: "text-dark",
    },
    {
      name: "User roles",
      type: rowActionTypes.SET_ROLES,
      classes: "text-dark",
    },
    {
      name: "Revoke all roles",
      type: rowActionTypes.REVOKE_ALL_ROLES,
      classes: "text-dark",
    },
    {
      name: "Enable",
      type: rowActionTypes.ENABLE,
      classes: "text-dark",
      toggleField: "enabled",
    },
    {
      name: "Disable",
      type: rowActionTypes.DISABLE,
      classes: "text-dark",
      toggleField: "enabled",
    },
    {
      name: "Resend activation email",
      type: rowActionTypes.RESEND_EMAIL,
      classes: "text-dark",
      // toggleField: "active",
    },
    {
      name: "View orders",
      type: rowActionTypes.USER_ORDERS,
      classes: "text-dark",
    },
    {
      name: "Sync Storage Package",
      type: rowActionTypes.SYNC_STORAGE_PACKAGE,
      classes: "text-dark",
    },
  ];

  const renderRowActions = (row) => {
    let actions = [...rowActions];
    if (row.enabled)
      actions = actions.filter((x) => x.type !== rowActionTypes.ENABLE);
    else actions = actions.filter((x) => x.type !== rowActionTypes.DISABLE);

    if (!row.enabled || row.active)
      actions = actions.filter((x) => x.type !== rowActionTypes.RESEND_EMAIL);
    if (row.deleted) actions = [];

    if (isBoAdmin) {
      actions.push({
        name: "Delete user",
        type: rowActionTypes.DELETE,
        classes: "text-dark",
      });
    }

    return (
      <div className="col-action">
        {/*  
        {row.deleted ? (
          <span className="text-danger"></span>
        ) : (
          <ActionList actions={actions} row={row} onClick={onActionButtonClick} />
        )}
         */}
        <ActionList actions={actions} row={row} onClick={onActionButtonClick} />
      </div>
    );
  };

  const onSelectedChange = () => { };

  const onSizeChange = (size) => {
    setFilters({ ...filters, pageSize: size, pageNumber: 1, reload: true });
  };

  const onPageChange = (p) => {
    setFilters({ ...filters, pageNumber: p, reload: true });
  };

  const onActionButtonClick = (action, row) => {
    let message = "";
    switch (action.type) {
      case rowActionTypes.EDIT:
        setState({ ...state, modalUpdate: true, row });
        break;
      case rowActionTypes.LOGOUT:
        message = `<p class="font-weight-bold">Following user will be logged out: </p>\
        <p class="font-italic">"${`${row.username} / ${row.email}`}"</p>\
        <p class="font-weight-bold">Do you want to continue?</p>`;
        setState({
          ...state,
          modalConfirm: true,
          row,
          message,
          action: rowActionTypes.LOGOUT,
        });
        break;
      case rowActionTypes.CHANGE_PW:
        setState({ ...state, modalChangePassword: true, row });
        break;
      case rowActionTypes.SET_ROLES:
        setState({ ...state, modalRoles: true, row });
        break;
      case rowActionTypes.RESET_PW:
        message = `<p class="font-weight-bold">The password of following user will be reset: </p>\
        <p class="font-italic">"${`${row.username} / ${row.email}`}"</p>\
        <p class="font-weight-bold">Do you want to continue?</p>`;
        setState({
          ...state,
          modalConfirm: true,
          row,
          message,
          action: rowActionTypes.RESET_PW,
        });
        break;
      case rowActionTypes.REVOKE_ALL_ROLES:
        message = `<p class="font-weight-bold">All roles of following user will be deleted: </p>\
        <p class="font-italic">"${`${row.username} / ${row.email}`}"</p>\
        <p class="font-weight-bold">Do you want to continue?</p>`;
        setState({
          ...state,
          modalConfirm: true,
          row,
          message,
          action: rowActionTypes.REVOKE_ALL_ROLES,
        });
        break;
      case rowActionTypes.ENABLE:
        message = `<p class="font-weight-bold">Following user will be enabled: </p>\
        <p class="font-italic">"${`${row.username} / ${row.email}`}"</p>\
        <p class="font-weight-bold">Do you want to continue?</p>`;
        setState({
          ...state,
          modalConfirm: true,
          row,
          action: rowActionTypes.ENABLE,
          message: message,
        });
        break;
      case rowActionTypes.DISABLE:
        message = `<p class="font-weight-bold">Following user will be disabled: </p>\
        <p class="font-italic">"${`${row.username} / ${row.email}`}"</p>\
        <p class="font-weight-bold">Do you want to continue?</p>`;
        setState({
          ...state,
          modalConfirm: true,
          row,
          action: rowActionTypes.DISABLE,
          message: message,
        });
        break;
      case rowActionTypes.RESEND_EMAIL:
        message = `<p class="font-weight-bold">The activation email of this user will be resent: </p>\
        <p class="font-italic">"${`${row.username} / ${row.email}`}"</p>\
        <p class="font-weight-bold">Do you want to continue?</p>`;
        setState({
          ...state,
          modalConfirm: true,
          row,
          action: rowActionTypes.RESEND_EMAIL,
          message: message,
        });
        break;
      case rowActionTypes.DELETE:
        setState({
          ...state,
          modalDelete: true,
          row,
        });
        break;
      case rowActionTypes.USER_ORDERS:
        setState({
          ...state,
          modalOrders: true,
          row,
        });
        break;
      case rowActionTypes.SYNC_STORAGE_PACKAGE:
        message = `<p class="font-weight-bold">Sync user storage package for: </p>\
        <p class="font-italic">"${`${row.username}`}"</p>\
        <p class="font-weight-bold">Do you want to continue?</p>`;
        setState({
          ...state,
          modalConfirm: true,
          row,
          action: rowActionTypes.SYNC_STORAGE_PACKAGE,
          message: message,
        });
        break;
      default:
    }
  };

  const toggleModal = (modal) => {
    if (state[modal]) setState({ ...state, [modal]: !state[modal] });
    else setState({ ...state, [modal]: !state[modal], row: null });
  };

  const forceLogoutCallback = () => {
    setState({
      ...state,
      modalConfirm: false,
    });
  };

  const forceLogoutUser = (user) => {
    (async () => {
      try {
        if (!user) return;

        await startActionWithPromise(
          forceLogoutAction,
          {
            id: user.userId,
            successCallback: forceLogoutCallback,
            failedCallback: () => { },
          },
          dispatch
        );
      } catch { }
    })();
  };

  const resetPwCallback = () => {
    setState({
      ...state,
      modalConfirm: false,
    });
  };

  const resetUserPassword = (user) => {
    (async () => {
      try {
        if (!user) return;

        await startActionWithPromise(
          resetPwAction,
          {
            id: user.userId,
            successCallback: resetPwCallback,
            failedCallback: () => { },
          },
          dispatch
        );
      } catch { }
    })();
  };

  const revokeRolesCallback = () => {
    setState({
      ...state,
      modalConfirm: false,
    });
  };

  const revokeRoles = (user) => {
    (async () => {
      try {
        if (!user) return;

        await startActionWithPromise(
          revokeRolesAction,
          {
            id: user.userId,
            successCallback: revokeRolesCallback,
            failedCallback: () => { },
          },
          dispatch
        );
      } catch { }
    })();
  };

  const onToggleStatusSuccess = () => {
    setState({
      ...state,
      modalConfirm: false,
    });
    setFilters({ ...filters, reload: true });
  };

  const toggleUserStatus = (row, status) => {
    const params = {
      userId: row.userId,
      status: status,
    };

    (async () => {
      try {
        await startActionWithPromise(
          status ? enableAction : disableAction,
          {
            ...params,
            successCallback: onToggleStatusSuccess,
            failedCallback: () => { },
          },
          dispatch
        );
      } catch { }
    })();
  };

  const resendActivationEmail = (row) => {
    const params = {
      email: row.email,
    };

    (async () => {
      try {
        await startActionWithPromise(
          resendActivationAction,
          {
            ...params,
            successCallback: () => {
              setState({ ...state, modalConfirm: false });
            },
            failedCallback: () => { },
          },
          dispatch
        );
      } catch { }
    })();
  };

  const syncUserStoragePackage = (row) => {
    const params = {
      userId: row.userId,
    };

    (async () => {
      try {
        await startActionWithPromise(
          syncStoragePackageAction,
          {
            ...params,
            successCallback: () => {
              setState({ ...state, modalConfirm: false });
            },
            failedCallback: () => { },
          },
          dispatch
        );
      } catch { }
    })();
  };

  const onActionConfirm = () => {
    switch (state.action) {
      case rowActionTypes.LOGOUT:
        forceLogoutUser(state.row);
        break;
      case rowActionTypes.RESET_PW:
        resetUserPassword(state.row);
        break;
      case rowActionTypes.REVOKE_ALL_ROLES:
        revokeRoles(state.row);
        break;
      case rowActionTypes.ENABLE:
        toggleUserStatus(state.row, true);
        break;
      case rowActionTypes.DISABLE:
        toggleUserStatus(state.row, false);
        break;
      case rowActionTypes.RESEND_EMAIL:
        resendActivationEmail(state.row, false);
        break;
      case rowActionTypes.SYNC_STORAGE_PACKAGE:
        syncUserStoragePackage(state.row, false);
        break;
      default:
    }
  };

  const handleSubmitFilter = (filtersValues) => {
    setFilters({ ...filters, pageNumber: 1, ...filtersValues, reload: true });
  };

  const refreshDatatable = () => {
    setFilters({ ...filters, reload: true });
  };

  const fetchData = (params) => {
    (async () => {
      try {
        await startActionWithPromise(
          fetchUsersAction,
          {
            params,
            successCallback: () => { },
            failedCallback: () => { },
          },
          dispatch
        );
      } catch { }
    })();
  };

  useEffect(() => {
    if (filters.reload) {
      const params = {
        pageSize: filters.pageSize,
        pageNumber: filters.pageNumber - 1,
      };

      if (filters.userId) params.userId = filters.userId;

      if (filters.keywords) params.keywords = filters.keywords;

      if (filters.activationStatus)
        params.activationStatus = filters.activationStatus;

      if (filters.userStatus) params.userStatus = filters.userStatus;

      if (filters.onlineStatus) params.onlineStatus = filters.onlineStatus;

      if (filters.countries && filters.countries.length > 0)
        params.countries = filters.countries.map((option) => option.value);

      if (filters.deleted) params.deleted = filters.deleted;

      if (filters.sortReal && filters.order)
        params.sort = {
          dir: filters.order.toUpperCase(),
          fieldName: filters.sortReal,
        };
      fetchData(params);
      setFilters({ ...filters, reload: false });
    }
  }, [filters.reload]);

  useEffect(() => {
    const params = {
      pageSize: filters.pageSize,
      pageNumber: filters.pageNumber - 1,
      sort: {
        dir: filters.order.toUpperCase(),
        fieldName: filters.sortReal,
      },
    };

    fetchData(params);

    setFilters({
      ...filters,
      sort: "createdDate",
    });

    return () => {
      setState({});
    };
  }, []);

  return (
    <>
      <Card className="w-100 mb-0">
        <CardHeader>
          <UserFilter onSubmitFilter={handleSubmitFilter} loading={fetching} />
        </CardHeader>
        <CardBody className="py-0 data-list initial-scrollbar position-relative">
          {fetching && (
            <div className="text-center w-100 bg-white p-4 position-static h-100">
              <Spinner />
            </div>
          )}
          {!fetching && (
            <>
              <DataTable
                keyField="userId"
                data={users}
                columns={tableColumns}
                sort={filters.sort}
                order={filters.order}
                actions={rowActions}
                onActionClick={onActionButtonClick}
                onSelectedChange={onSelectedChange}
                hideSelectColumn={true}
              />
            </>
          )}
        </CardBody>
        <Row className="my-3 px-4">
          <Col lg="2" md="2" className="d-flex">
            <PageSizeSelector size={filters.pageSize} onChange={onSizeChange} />
          </Col>
          <Col lg="4" md="4" className="d-flex justify-content-center">
            {totalFound && (
              <span>
                <b>{totalFound}</b> user(s) found.
              </span>
            )}
          </Col>
          <Col lg="6" md="6" className="d-flex justify-content-end">
            <Paging
              totalPages={totalPages}
              current={pageNumber}
              show={5}
              onSelect={onPageChange}
            />
          </Col>
        </Row>
      </Card>
      <UserChangePassword
        modal={state.modalChangePassword}
        toggle={toggleModal}
        row={state.row}
      />
      <UserRoles
        modal={state.modalRoles}
        toggle={toggleModal}
        row={state.row}
      />
      <ConfirmModal
        modal={state.modalConfirm}
        toggle={toggleModal}
        row={state.row}
        onConfirm={onActionConfirm}
        message={state.message}
      />
      <UserDelete
        modal={state.modalDelete}
        toggle={toggleModal}
        row={state.row}
        refreshData={refreshDatatable}
      />
      {state.modalOrders && (
        <ModalUserOrders
          modal={state.modalOrders}
          toggle={toggleModal}
          user={state.row}
          refreshData={refreshDatatable}
        />
      )}
    </>
  );
};

export default UserList;
