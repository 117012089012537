import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Badge,
} from "reactstrap";
import _ from "lodash";
// import moment from "moment";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import styled from "styled-components";

import DataTable from "../../components/DataTable";
import config from "../../config/config";
import Avatar from "../../components/Avatar";
import { searchVideoAction } from "../../redux/reducers/videoReducer";
import { featuredVideoAction } from "../../redux/reducers/videoFeaturedReducer";
import { startActionWithPromise } from "../../helpers/saga-promise-helpers";
import VideoFilter from "./VideoFilter";
import useMergeState from "../../hooks/mergeState";
import {
    STATUSES,
    STATUSES_COPY,
    STATUSES_COLOR,
    MARKETS
  } from "./constants";

const propTypes = {
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  refreshData: PropTypes.func,
};

const defaultProps = {
  modal: false,
  toggle: () => {},
  refreshData: () => {},
};

const StyledDatetimeDiv = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
  }
`;

const getDatetimeStr = (date) => {
  if (!date) return '';
  return `${date.getFullYear()}-${_.padStart(date.getMonth() + 1, 2, 0)}-${_.padStart(date.getDate(), 2, 0)}T${_.padStart(date.getHours(), 2, 0)}:${_.padStart(date.getMinutes(), 2, 0)}:00.000+00:00`;
}

const VideoFeaturedAdd = props => {
  const dispatch = useDispatch();

  const { modal, toggle, refreshData, row } = props;

  const fetching = false; // useSelector(state => state.user?.fetching);
  const videos = useSelector(state => state.video?.list) || [];
  const [searched, setSearched] = useState(false);
  const [waitingSearch, setWaitingSearch] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [pristine, setPristine] = useState(true);
  const defaultMarkets = MARKETS ? [MARKETS[0].value] : [];

  const [formState, setFormState] = useMergeState({
    enabled: row ? row.enabled : true,
    ownerEmail: row ? row.ownerEmail : '',
    ownerId: row ? row.ownerName : '',
    rankingLevel: row ? row.rankingLevel : 0,
    showCount: row ? row.showCount : 0,
    uid: row ? row.uid : '',
    validStartDate: row ? row.validStartDate : '',
    validThruDate: row ? row.validThruDate : '',
    markets: row && row.markets ? row.markets : defaultMarkets,
  });

  let dataList = videos.filter(x => x.visibilityScope === 'PUBLIC');

  const nonSelects = dataList.filter(x => x.featured).map(x => x.uid);
  const tableColumns = [
    {
      dataField: "title",
      text: "Video",
      sort: false,
      onSort: (field, order) => {
        onColumnSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div>
          <div><span className="font-weight-bold" title={row?.title}>{_.truncate(row?.title, {'length': 80,})}</span></div>
          <div><span className="font-weight-bold" title={row?.subject}>{_.truncate(row?.subject, {'length': 80,})}</span></div>
          <div><span className="font-weight-bold" title={row?.topic}>{_.truncate(row?.topic, {'length': 80,})}</span></div>
          {row?.course && (
            <div><span className="font-weight-bold" title={row?.course.code}>{_.truncate(row?.course.code, {'length': 80,})} - #{row?.course.serial}</span></div>  
          )}
          <div><span title={row?.uid} style={{fontStyle: 'italic'}}>{row?.uid}</span></div>
        </div>
      ),
      headerStyle: () => {
        // return { width: "35%" };
      },
    },
    {
      dataField: "author",
      text: "Author",
      sort: false,
      onSort: (field, order) => {
        onColumnSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div title={row?.author}>
          <Avatar className="inline mr-2" image={row.avatar} fullname={row.author} width="30" height="30" />
          <span>{row?.author}</span>
        </div>
      ),
      headerStyle: () => {
        return { width: "20%" };
      },
    },
    {
      dataField: "status",
      text: "Status",
      sort: false,
      onSort: (field, order) => {
        onColumnSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div>
          <div className="">
            <Badge
              color={STATUSES_COLOR[row.status]}
              className="mr-1 mb-1"
            >
              {STATUSES_COPY[row.status]}
            </Badge>
          </div>
        </div>
      ),
      headerStyle: () => {
        return { width: "85px" };
      },
    },
    {
      dataField: "createdDate",
      text: "Created at",
      sort: false,
      onSort: (field, order) => {
        onColumnSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div>
          {row.createdDate ? moment(row.createdDate).format('HH:mm:ss') : ''}<br/>
          {row.createdDate ? moment(row.createdDate).format('YYYY/MM/DD') : ''}
        </div>
      ),
      headerStyle: () => {
        return { width: "140px" };
      },
    },
  ];

  const [selectedRows, setSelectedRows] = useState([]);
  const [minDate, setMinDate] = useState('');
  const [maxDate, setMaxDate] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [filters, setFilters] = useState({
    pageSize: 50,
    pageNumber: 1,
    sort: 'createdDate',
    sortReal: '',
    order: 'desc',
    keywords: '',
    videoId: '',
    videoOwner: '',
    videoUserId: '',
    reload: false,
  });

  const onColumnSort = (field, order) => {
    const realSort = field === "fullname" ? "firstName" : field;
    if (realSort === filters.sortReal && order === filters.order) return;

    setFilters({
      ...filters,
      sort: field,
      sortReal: realSort,
      order,
      reload: true,
    });
  };

  const handleChangeInput = (e) => {
    let inputValue = e.target.type === 'number' ? parseInt(e.target.value) : e.target.value;
    setFormState({[e.target.name]: inputValue});
    setPristine(false);
  }

  const handleCheckedChange = (value) => {
    setPristine(false);
    let isChecked = formState.markets.find(x => x === value);
    let checkedList = isChecked ? formState.markets.filter(x => x !== value) : [...formState.markets, value];

    setFormState({markets: checkedList});
  }

  const handleSubmitFilter = (filtersValues) => {
    setFilters({ ...filters, pageNumber: 1, ...filtersValues, reload: true });
  }

  const addSuccessCallback = () => {
    if (refreshData) refreshData();
    setWaiting(false);
    toggle("modalAdd");
  };

  const handleSubmit = async () => {
    setErrorMessage('');

    if (!selectedRows || selectedRows.length === 0) {
      setErrorMessage('Please select a video.');
      return;
    }

    if (!formState.validStartDate) {
      setErrorMessage('Please set valid start date.');
      return;
    }

    // if (!formState.validThruDate) {
    //   setErrorMessage('Please set valid to date.');
    //   return;
    // }

    if (!formState.markets || formState.markets.length === 0) {
      setErrorMessage('Please select market.');
      return;
    }

    const { uid, authorEmail: ownerEmail, authorId: ownerId, author: ownerName, subject, title, topic } = selectedRows[0];
    const postData = {
      uid,
      ownerEmail,
      ownerId,
      ownerName,
      rankingLevel: formState.rankingLevel,
      showCount: 0,
      subject,
      title,
      topic,
      enabled: formState.enabled,
      markets: formState.markets,
      validStartDate: getDatetimeStr(formState.validStartDate),
      validThruDate: formState.validThruDate ? getDatetimeStr(formState.validThruDate) : '',
    };

    setWaiting(true);
    try {
      await startActionWithPromise(
        featuredVideoAction.create,
        {
          data: postData,
          successCallback: addSuccessCallback,
          failedCallback: () => {},
        },
        dispatch
      );
    } catch {}
  };

  const fetchData = (params) => {
    (async () => {
      try {
        setWaitingSearch(true);
        await startActionWithPromise(
          searchVideoAction,
          {params: params, successCallback: () => {}, failedCallback: () => {}},
          dispatch
        );
      } catch {}
      setWaitingSearch(false);
      setSearched(true);
    })();
  };

  useEffect(() => {
    if (filters.reload) {
      const params = {
        searchTerm: filters.videoId ? '' : filters.keywords,
        // videoOwnerId: filters.videoOwnerId || '',
        videoOwnerName: filters.videoOwner,
        videoId: filters.videoId || '',
        videoUserId: filters.videoUser,
        pageNumber: filters.pageNumber - 1,
        pageSize: filters.pageSize,
        sort: {
          dir: 'DESC',
          fieldName: 'createdDate'
        },
        masterVideo: false,
        scope: 'PUBLIC',
        markFeature: true
      };

      fetchData(params);
      setFilters({ ...filters, reload: false });
      setPristine(false);
    }
  }, [filters.reload]);

  return (
    <Modal
      isOpen={modal}
      toggle={() => toggle("modalAdd")}
      centered
      size="lg"
      className="add-trusted-modal add-featured-video-modal"
    >
      <ModalHeader>Add featured video</ModalHeader>
      <ModalBody className="pt-0 pb-0">
        <Card className="w-100 mb-0">
          <CardHeader className="">
            <VideoFilter
              onSubmitFilter={handleSubmitFilter}
              loading={fetching}
            />
          </CardHeader>
          <CardBody className="pt-0 pb-0 data-list position-relative">
            {waitingSearch && (
              <div className="text-center w-100 bg-white p-4 position-static" style={{height: '30vh'}}>
                <Spinner size="sm" />
              </div>
            )}

            {!waitingSearch && (
              <div className="">
                <DataTable
                  keyField="uid"
                  single
                  data={dataList}
                  columns={tableColumns}
                  sort={filters.sort}
                  order={filters.order}
                  actions={[]}
                  onSelectedChange={(selected) => setSelectedRows(selected)}
                  nonSelects={nonSelects}
                />
              </div>
            )}
            <hr />
            <Row className="mt-3">
              <Col>
                <FormGroup>
                  <div>
                    <FormGroup inline check>
                      <Label>
                        <input
                          className="form-check-input"
                          name="enabled"
                          id="enabled"
                          type="checkbox"
                          value={formState.enabled}
                          checked={formState.enabled}
                          onChange={() => setFormState({enabled: !formState.enabled})}
                        />
                        <span>Enabled</span>
                      </Label>
                    </FormGroup>
                    
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label for="rankingLevel" className="">
                    Ranking level
                  </Label>
                  <input
                    className="form-control"
                    name="rankingLevel"
                    id="rankingLevel"
                    type="number"
                    min="0"
                    value={formState.rankingLevel}
                    onChange={handleChangeInput}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="phone" className="">
                    Valid start date
                  </Label>
                  <StyledDatetimeDiv>
                    <DatePicker
                      selected={formState.validStartDate}
                      onChange={(date) => {console.log(moment(date).toDate()); setFormState({validStartDate: date}); setMinDate(date); }}
                      maxDate={maxDate}
                      timeInputLabel="Time:"
                      dateFormat="MM/dd/yyyy HH:mm"
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      // showTimeSelect
                      className="w-100"
                      showTimeInput
                    />
                  </StyledDatetimeDiv>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="phone" className="">
                    Valid through date
                  </Label>
                  <StyledDatetimeDiv>
                    <DatePicker
                      selected={formState.validThruDate}
                      onChange={(date) => {setFormState({validThruDate: date}); setMaxDate(date);}}
                      minDate={minDate}
                      timeInputLabel="Time:"
                      dateFormat="MM/dd/yyyy HH:mm"
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      // showTimeSelect
                      className="w-100"
                      showTimeInput
                    />
                  </StyledDatetimeDiv>
                  
                </FormGroup>
              </Col>
              {/* <Col>
                <FormGroup>
                  <Label for="phone" className="">
                    Market
                  </Label>
                  <Selectbox
                    options={MARKETS}
                    onChange={handleMarketChange}
                    dropUp
                    isClearable={false}
                    defaultValue={MARKETS[0]}
                    placeholder="" />
                </FormGroup>
              </Col> */}
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label>
                    Markets
                  </Label>
                  <div>
                    {MARKETS.map(item => (
                      <FormGroup
                        check
                        inline
                        className="mr-4"
                        key={`market-check--${item.value}`}
                      >
                        <Label check>
                          <input
                            className="form-check-input"
                            name="markets"
                            type="checkbox"
                            // defaultChecked={_.find(
                            //   currentRole.permissions,
                            //   {
                            //     id: column.perm.id,
                            //   }
                            // )}
                            value={item.value}
                            checked={formState.markets.find(x => x === item.value) || false}
                            onChange={() => handleCheckedChange(item.value)}
                            // ref={register}
                          />
                          <span>
                          {item.label}
                          </span>
                        </Label>
                      </FormGroup>
                    ))}
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </ModalBody>
      <ModalFooter>
        {errorMessage && (
          <div className="float-left text-danger">
            {errorMessage}
          </div>
        )}
        <Button
          color="secondary"
          disabled={fetching}
          onClick={() => toggle("modalAdd")}
        >
          Cancel
        </Button>
        <Button
          className="ml-2"
          color="primary"
          disabled={waiting}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};

VideoFeaturedAdd.propTypes = propTypes;
VideoFeaturedAdd.defaultProps = defaultProps;

export default VideoFeaturedAdd;
