import styled from 'styled-components';

import noAvatar from "../../assets/img/avatars/avatar.svg";

export const StyledAvatar = styled.div`
  position: relative;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  background-color: ${props => props.color};
  background-image: url(${props => props.image || noAvatar});
  background-size: auto 100%;
  span {
    font-size: 1rem;
    font-weight: 700;
    top: 0px;
    position: absolute;
    display: flex;
    line-height: 1;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
`;