import React, { useEffect, useState, useRef, useCallback } from "react";
import { Row, Col, Form, Button, Label, FormGroup } from "reactstrap";
import * as Icon from "react-feather";
import useMergeState from "../../hooks/mergeState";

const MessageFilter = ({ onSubmitFilter, loading, selectedStates }) => {

  const MESSAGE_STATES = [
    { value: 'PENDING', label: 'Pending' },
    { value: 'PROCESSING', label: 'Processing' },
    { value: 'CLOSED', label: 'closed' },
  ];

  const [filters, setFilters] = useMergeState({
    countries: [],
    selectedStates: selectedStates,
  });

  function removeElement(array, elem) {
    var index = array.indexOf(elem);
    if (index > -1) {
      array.splice(index, 1);
    }
  }

  const onCheckChange = (e) => {
    const cloned = [...filters.selectedStates];
    if (e.target.checked) {
      cloned.push(e.target.value);
    } else {
      removeElement(cloned, e.target.value)
    }
    setFilters({ ...filters, selectedStates: cloned });
  }

  return (
    <>
      <Row>
        <Col className="d-flex justify-content-start">

        </Col>
        <Col lg="2" md="2" xs="2" className="d-flex justify-content-end">
          <label htmlFor="" className="mb-0 mr-2">
            Message status:
          </label>
        </Col>
        <Col lg="3" md="3" xs="3" >
          <div
            className="d-flex align-items-center pr-3 mb-2"
          >
            {
              MESSAGE_STATES.map(state => (
                <div className="d-flex align-items-center mr-2">
                  <input
                    className="mr-1 mb-2"
                    type="checkbox"
                    name={`chk_${state.value}`}
                    id={`chk_${state.value}`}
                    key={`chk_${state.value}`}
                    checked={filters.selectedStates.includes(state.value)}
                    value={state.value}
                    onChange={(e) => onCheckChange(e)}
                  />
                  <label htmlFor={`chk_${state.value}`}>
                    {state.label}
                  </label>
                </div>
              ))
            }
          </div>
        </Col>
        <Col lg="1" md="1" xs="1">
          <div className="d-flex justify-content-end flex-wrap">
            <div>
              <Button
                className="d-flex"
                size=""
                disabled={loading}
                onClick={(e) => {
                  onSubmitFilter(filters);
                }}
              >
                <Icon.Search
                  className=""
                  style={{
                    width: "15px",
                    height: "15px",
                    marginTop: "3px",
                    marginRight: "2px",
                  }}
                />{" "}
                Search
              </Button>

            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default React.memo(MessageFilter);
