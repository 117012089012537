import React from "react";
import { Container, Row, Col } from "reactstrap";
import GroupList from "./GroupList";

const Roles = () => {
  return (
    <Container fluid className="p-0">
      <h1 className="page-title">Role Groups</h1>
      <Row>
        <Col lg="12" className="d-flex">
          <GroupList />
        </Col>
      </Row>
    </Container>
  );
};

export default Roles;
