import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Row, Col, FormGroup, Label, Button } from "reactstrap";
import _ from 'lodash';
import Select, { components } from 'react-select';
import * as Icon from "react-feather";
import classNames from "classnames";
import {
  useLocation
} from "react-router-dom";

import { useAuth } from "../../hooks/useAuth";
import "./VideoReviewerFilter.scss";
import CustomInput from "./CustomInput";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const STATUSES = {
  pending: 'PENDING',
  processing: 'PROCESSING',
  rejected: 'REJECTED',
  approved: 'APPROVED',
  canceled: 'CANCELED',
}

const filterStatuses = [
  {
    label: 'Pending',
    value: STATUSES.pending,
  },
  {
    label: 'Processing',
    value: STATUSES.processing,
  },
  {
    label: 'Rejected',
    value: STATUSES.rejected,
  },
  {
    label: 'Approved',
    value: STATUSES.approved,
  },
  {
    label: 'Canceled',
    value: STATUSES.canceled,
  },
];

const defaultStatuses = [STATUSES.pending, STATUSES.processing];

const selectStyles = {
  control: provided => (
    { ...provided, 
      borderWidth: '0px', 
      minHeight:'31px', 
      borderRadius: '2px', 
      boxShadow: '0 0 0 1px #ced4da',
      "&:hover": {
        borderColor: '#ced4da'
      }
    }),
  option: (provided, state) => {
    return {
      ...provided,
      backgroundColor: state.isSelected ? "#86C9CF" : provided.backgroundColor,
      "&:active": {
        backgroundColor: "#86C9CF"
      },
      padding: '3px 8px'
    }
  },
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '4px'
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '1px 8px'
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: '4px'
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: '3',
    borderRadius: '2px',
  }),
};

const Menu = (props) => {
  return (
    <>
      <components.Menu {...props}>
        <div>
          {props.selectProps.fetchingData ? (
            <span className="fetching">Fetching data...</span>
          ) : (
            <div>{props.children}</div>
          )}
          <div
            className="menu-help text-center font-italic"
            style={{
            padding: '2px 5px',
            background: '#F7F9FC',
            color: '#9b9d9e'
          }}>Or type a name to search...</div>
        </div>
      </components.Menu>
    </>
  );
};

const VideoReviewFilter = ({onSubmitFilter, loading, onToggleShow, activeReviewerId}) => {
  const auth = useAuth();

  let query = useQuery();


  const users = useSelector((state) => state.bouser?.users) || [];
  const isBoAdmin = useSelector((state) => state.bouser?.isBoAdmin);

  const [cancelDebounce, setCancelDebounce] = useState(false);
  const [showFilter, setShowFilter] = useState(true);

  const [filters, setFilters] = useState({
    requester: '',
    reviewerId: query.get('reviewer') === 'me' ? auth.user?.userId : '',
    statuses: [STATUSES.pending, STATUSES.processing],
    order: '',
    video: '',
    reload: false
  });

  const fetching = false; // useSelector(state => state.bouser?.fetching);

  var requesterOptions = isBoAdmin ? users.filter(user => auth.user && auth.user.userId !== user.userId).map(user => ({
    label: `${user.fullname} (${user.username})`,
    text: `${user.fullname}`,
    value: user.userId
  })) : [];

  if (auth.user)
    requesterOptions.unshift({
      label: `Myself (${auth.user.firstName} ${auth.user.lastName})`,
      text: `${auth.user.firstName} ${auth.user.lastName}`,
      value: auth.user.userId
    });

  const activeReviewer = activeReviewerId ? requesterOptions.find(x => x.value === auth.user?.userId) : null;
  const [selectedReviewer, setSelectedReviewer] = useState(activeReviewer);

  const onChangeReviewer = (item) => {
    setSelectedReviewer(item);
    setFilters({
      ...filters,
      reviewerId: item ? item.value : null,
      reload: true
    });
  }

  const toggleSearchForm = () => {
    setShowFilter(!showFilter);
    if (onToggleShow) onToggleShow(!showFilter);
  }

  const handleVideoChange = (value) => {
    setFilters({ ...filters, video: value});
    setCancelDebounce(false);
  }

  const handleVideoSubmit = (keyword) => {
    if (filters.keyword === keyword) return;
    setFilters({ ...filters, video: keyword, reload: true});
  };

  const handleRequesterChange = (value) => {
    setFilters({ ...filters, requester: value});
    setCancelDebounce(false);
  };

  const handleRequesterSubmit = (value) => {
    setFilters({ ...filters, requester: value});
    submitFilter();
  }

  const handleStatusChange = (e) => {
    if (e.target.checked) {
      const list = [...filters.statuses, e.target.value];
      setFilters({ ...filters, statuses: list, reload: true});
    } else {
      const list = filters.statuses.filter(item => item !== e.target.value);
      setFilters({ ...filters, statuses: list, reload: true});
    }
  }

  const submitFilter = () => {
    if (onSubmitFilter) onSubmitFilter(filters);
  };

  useEffect(() => {
    if (filters.reload) {
      submitFilter();
      setFilters({ ...filters, reload: false });
    }
  }, [filters.reload]);

  return (
    <>
      <Row className={classNames('video-review-filter', { 'd-none': !showFilter })} style={{transition: 'visibility 0.5s, opacity 0.5s linear'}}>
        <Col lg="6" md="12" xs="12" className="mt-1 mb-1">
          <div className="item-filter">
            <label htmlFor="" className="mb-0">Requester</label>
            <div className="d-inline-block item-filter__control ml-2">
              <CustomInput
                name="requester"
                value={filters.requester}
                className="w-100"
                placeholder="First name, last name..."
                disabled={fetching}
                debounceTime={3000}
                cancelDebounce={cancelDebounce}
                onChange={handleRequesterChange}
                onSubmit={handleRequesterSubmit}
              />
            </div>
            {/* <Input
              placeholder="First name, last name..."
              value={requester}
              onChange={handleRequesterChange}
              onKeyUp={e => handleKeyUp(e)}
              // style={{width: '180px'}}
              className="d-inline-block ml-2 item-filter__control"
            /> */}
          </div>
        </Col>
        <Col lg="6" md="12" xs="12" className="mt-1 mb-1">
          <div className="item-filter reviewer-filter">
            <label htmlFor="" className="mb-0">Reviewer</label>
            <div className="d-inline-block ml-2 item-filter__control">
              <Select
                name="reviewer"
                options={requesterOptions}
                isClearable={true}
                // isDisabled={!isBoAdmin}
                value={selectedReviewer}
                styles={selectStyles}
                placeholder="Select reviewer..."
                onChange={onChangeReviewer}
                components={{ Menu }}
              />
            </div>
          </div>
        </Col>
        <Col lg="6" md="12" xs="12" className="mt-1 mb-1">
          <div className="item-filter status-filter">
            <label htmlFor="" className="mb-0">Status</label>
            <div className="status-filter__list d-inline-block ml-2">
              {filterStatuses.map(status => (
                <FormGroup
                  
                  className="mr-3 d-inline"
                  key={`status-item--${status.value}`}
                >
                  <Label>
                    <input
                      className="mr-1"
                      name={`statuses[]`}
                      type="checkbox"
                      defaultChecked={_.indexOf(defaultStatuses, status.value) !== -1}
                      value={status.value}
                      onChange={handleStatusChange}
                      // onClick={() => setPristine(false)}
                      // ref={register}
                    />
                    <span>{status.label}</span>
                  </Label>
                </FormGroup>
              ))}
            </div>
            
            {/* <Select
              defaultValue={[filterStatuses[0], filterStatuses[1]]}
              isMulti
              name="colors"
              options={filterStatuses}
              className="d-inline-block basic-multi-select item-filter__control ml-2"
              classNamePrefix="select"
              isClearable={false}
              styles={selectStyles}
              onChange={onChangeStatus}
            /> */}
            {/* <CustomDropdown
              className="ml-2 rounded"
              items={filterStatuses}
              label="All"
              color="light"
              onChange={onChangeStatus}
              /> */}
          </div>
        </Col>
        <Col lg="6" md="12" xs="12" className="mt-1 mb-1">
          <div className="item-filter">
            <label htmlFor="" className="mb-0">Video</label>
            <div className="d-inline-block item-filter__control ml-2">
              <CustomInput
                name="video"
                value={filters.video}
                className="w-100"
                placeholder="Search by video..."
                disabled={fetching}
                debounceTime={3000}
                cancelDebounce={cancelDebounce}
                onChange={handleVideoChange}
                onSubmit={handleVideoSubmit}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row className="mt-1">
        <Col lg="12" className="d-flex justify-content-end">
          <div
            className="d-inline mr-2 text-info pt-1"
            style={{cursor: 'pointer'}}
            onClick={toggleSearchForm}
          >
            {!showFilter ? (
              <>Expand <Icon.ChevronDown className="" style={{ width: '15px', height: '15px', marginTop: '-2px' }} /></>
            ) : (
              <>Collapse <Icon.ChevronUp className="" style={{ width: '15px', height: '15px', marginTop: '-2px' }} /></>
            )}
          </div>
          <Button
            size=""
            disabled={loading}
            // color="secondary"
            onClick={(e) => {setCancelDebounce(true); submitFilter(e);}}
          >
            <Icon.Search className="" style={{ width: '15px', height: '15px', marginTop: '-2px' }} /> Search
          </Button>
          
        </Col>
      </Row>
    </>
  )
}

export default React.memo(VideoReviewFilter);