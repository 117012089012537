import React from "react";
import { ucFirst } from "../../helpers/utils";

export const getTableColumns = () => {
  
  return [
    {
      dataField: "planName",
      text: "Plan Id",
      sort: false,
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div>{row.planName}</div>
      ),
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: "product",
      text: "Product",
      sort: false,
      formatter: (cell, row) => (
        <div>{row.product}</div>
      ),
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: "category",
      text: "Category",
      sort: false,
      formatter: (cell, row) => (
        <div>{ucFirst(row.category)}</div>
      ),
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: "billingPeriod",
      text: "Billing Period",
      sort: false,
      formatter: (cell, row) => (
        <div>{ucFirst(row.billingPeriod).replace(/_/g, ' ')}</div>
      ),
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: "price",
      text: "Price",
      sort: false,
      formatter: (cell, row) => (
        <div>{row.price} {row.currency}</div>
      ),
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: "trial",
      text: "Trial",
      sort: false,
      formatter: (cell, row) => (
        <div>{row.trialLength > 0 ? row.trialLength + ' ' + ucFirst(row.trialTimeUnit) : row.trialTimeUnit}</div>
      ),
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    
  ];
}