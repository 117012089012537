import React, { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  FormGroup,
  Label,
  Spinner
} from "reactstrap";
import PropTypes from "prop-types";
import moment from "moment";
import _ from "lodash";

import { retranscodeAction, getVideoInfoAction } from "../../redux/reducers/toolReducer";
import { startActionWithPromise } from "../../helpers/saga-promise-helpers";
import Avatar from "../../components/Avatar";

const propTypes = {
  modal: PropTypes.bool,
  toggle: PropTypes.func,
};

const defaultProps = {
  modal: false,
  toggle: () => {},
};

const VideoRetranscode = props => {
  const dispatch = useDispatch();

  const { modal, toggle } = props;

  const [pristine, setPristine] = useState(true);
  const [waiting, setWaiting] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [video, setVideo] = useState(null);
  const [videoId, setVideoId] = useState('');
  const [errors, setErrors] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [isNodata, setIsNoData] = useState(false);

  const handleChange = (event) => {
    setVideoId(event.target.value);
    if (event.target.value) setErrors({});
  }

  const handleKeyUp = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.keyCode === 13) { // enter key
      if (debounceGetVideoInfo) debounceGetVideoInfo.cancel();
      await getVideoInfo(true);
    }
    return false;
  }

  const handleConfirmationChange = (event) => {
    setIsConfirm(event.target.checked);
    setPristine(!event.target.checked);
  }

  const toggleModal = () => {
    if (toggle) toggle('modalRetranscode');
  };

  const successCallback = result => {
    setWaiting(false);
    toggleModal();
  };

  const failedCallback = error => {
    setWaiting(false);
  };

  const handleSubmit = async () => {
    let validateErrors = null;

    if (!isConfirm) {
      validateErrors = {
        confirmation: 'Please confirm the video is correct.'
      };
    }

    if (validateErrors) {
      setErrors(validateErrors);
      return;
    }

    setWaiting(true);
    try {
      await startActionWithPromise(
        retranscodeAction,
        {
          id: video.uid,
          successCallback,
          failedCallback,
        },
        dispatch
      );
    } catch {}
    setWaiting(false);
  };

  const getVideoInfo = async () => {
    setIsConfirm(false);
    setPristine(true);

    let validateErrors = null;
    if (!videoId) {
      validateErrors = {
        videoId: 'Video id is required.'
      };
    }

    if (validateErrors) {
      setErrors(validateErrors);
      return;
    }

    setFetching(true);
    setIsNoData(false);
    try {
      let data = await startActionWithPromise(
        getVideoInfoAction,
        {
          id: videoId,
          successCallback: () => {},
          failedCallback: () => {},
        },
        dispatch
      );

      if (data && data.uid) setVideo(data);
      else setIsNoData(true);

    } catch (error) {
      setErrors({
        video: 'No video found.'
      });
    }
    setFetching(false);
  }

  const debounceGetVideoInfo = useCallback(
    _.debounce(() => {
      (async() => getVideoInfo())();
    }, 3000),
    [videoId]
  );

  useEffect(() => {
    if (modal) debounceGetVideoInfo();

    return debounceGetVideoInfo.cancel;
  }, [videoId]);

  useEffect(() => {
    if (!modal) {
      setVideo(null);
      setIsNoData(false);
      setIsConfirm(false);
      setPristine(true);
    }
  }, [modal]);

  return (
    <Modal isOpen={modal} toggle={toggleModal} centered size="md">
      <ModalHeader toggle={toggleModal}>
        Re-transcode Video
      </ModalHeader>
      <ModalBody className="m-3">
        
        <Row>
          <Col lg="12">
            <FormGroup>
              <Label for="videoId" className="">
                Video id
              </Label>
              <input
                className={`form-control${
                  errors.videoId ? " is-invalid" : ""
                }`}
                type="text"
                id="videoId"
                name="videoId"
                placeholder=""
                onChange={handleChange}
                onKeyUp={e => handleKeyUp(e)}
              />
              {errors?.videoId && <p className="text-danger">{errors.videoId}</p>}
              {errors?.video && <p className="text-danger">{errors.video}</p>}
            </FormGroup>
          </Col>
        </Row>
        {fetching ? (
          <div className="loading w-100 text-center p-2"><span><Spinner size="sm" /></span></div>
        ) : isNodata ? (
          <div className="no-data">No video found!</div>
        ) : video && (
          <Row>
            <Col lg="12">
              <div className="mb-2">
                <div className="font-weight-bold">Video information</div>
                <div className="request-detail p-2 border bg-light rounded">
                  <div className="" style={{whiteSpace: 'pre-wrap', wordBreak: 'break-word'}}>
                    <p className="mb-0">Title: <span>{video?.title}</span></p>
                    <p className="mb-0">Subject: <span>{video?.subject}</span></p>
                    <p className="mb-0">Topic: <span>{video?.topic}</span></p>
                    <p className="mb-0">Version: <span>{video?.version}</span></p>
                    <div className="mb-0">Author: <div className="d-inline-block" style={{height: '30px', verticalAlign: 'middle'}}><Avatar className="d-inline-block" image={video?.avatar} fullname={video?.author} width="30" height="30" /></div> <span>{video?.author}</span></div>
                    <div className="mb-0">Visibility: <span>{video?.visibilityScope}</span></div>
                    <p className="mb-0">Created at: <span>{video.createdDate ? moment(video.createdDate).utc().format('YYYY/MM/DD HH:mm:ss') : ''}</span></p>
                  </div>
                </div>
              </div>
              <FormGroup check>
                <Label check>
                  <input
                    className="form-check-input"
                    name="confirmation"
                    type="checkbox"
                    value={isConfirm}
                    onClick={() => setPristine(false)}
                    onChange={handleConfirmationChange}
                    // ref={register({ required: "Please confirm the video is correct." })}
                  />
                  <span>Confirm (*)</span>
                </Label>
              </FormGroup>
              {errors?.confirmation && <p className="text-danger">{errors.confirmation}</p>}
            </Col>
          </Row>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" disabled={waiting} onClick={toggleModal}>
          Cancel
        </Button>
        <Button
          className="ml-2"
          color="primary"
          disabled={pristine || waiting}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};

VideoRetranscode.propTypes = propTypes;
VideoRetranscode.defaultProps = defaultProps;

export default VideoRetranscode;
