export const STATUSES = {
  initializing: 'CVS_INITIALIZING',
  composing: 'CVS_COMPOSING',
  publishing: 'CVS_PUBLISHING',
  published: 'CVS_PUBLISHED',
  ready: 'CVS_READY',
  failed: 'CVS_FAILED',
  updating: 'CVS_UPDATING',
}

export const STATUSES_COPY = {
  [STATUSES.initializing]: 'Initializing',
  [STATUSES.composing]: 'Composing',
  [STATUSES.publishing]: 'Publishing',
  [STATUSES.published]: 'Ready',
  [STATUSES.ready]: 'Ready',
  [STATUSES.failed]: 'Failed',
  [STATUSES.updating]: 'Updating',
}

export const STATUSES_COLOR = {
  [STATUSES.initializing]: 'dark',
  [STATUSES.composing]: 'info',
  [STATUSES.publishing]: 'warning',
  [STATUSES.published]: 'success',
  [STATUSES.ready]: 'success',
  [STATUSES.failed]: 'danger',
  [STATUSES.updating]: 'secondary',
}

const SCOPES = {
  private: 'PRIVATE',
  protected: 'PROTECTED',
  public: 'PUBLIC',
}
export const SCOPES_COPY = {
  [SCOPES.private]: 'Private',
  [SCOPES.protected]: 'Protected',
  [SCOPES.public]: 'Public',
}

const CATEGORIES = {
  createVideo: 'CREATE_VIDEO',
  brainHiveVideo: 'BRAINHIVE_VIDEO',
  homeVideo: 'HOME_VIDEO',
  publishedVideo: 'PUBLISHED_VIDEO',
}
export const CATEGORY_COPY = {
  [CATEGORIES.createVideo]: 'Create Video',
  [CATEGORIES.brainHiveVideo]: 'BrainHive Video',
  [CATEGORIES.homeVideo]: 'Home Video',
  [CATEGORIES.publishedVideo]: 'Published Video',
}

export const MARKETS = [
  {label: 'Global', value: 'GLOBAL'},
  {label: 'US', value: 'US'},
  {label: 'Vietnam', value: 'VIETNAM'},
];