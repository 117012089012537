import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Card, CardBody, Media, Row, Spinner } from "reactstrap";
import _ from "lodash";
import PropTypes from "prop-types";

import { DollarSign, Users } from "react-feather";

import { totalUsersAction, totalRevenuesAction } from "../../redux/reducers/reportReducer";
import { startActionWithPromise } from "../../helpers/saga-promise-helpers";
import { formatNumber } from "../../helpers/utils";

const propTypes = {
  dateRange: PropTypes.string,
  refresh: PropTypes.number
}

const defaultProps = {
  dateRange: '',
  refresh: () => { }
}

const Statistics = ({ startDate, endDate, dateRange, refresh }) => {
  const dispatch = useDispatch();

  const statData = useSelector(state => state.report.statistics);
  const [waiting, setWaiting] = useState(false);
  const [totalUsers, setTotalUsers] = useState({
    userActiveCount: 0,
    userInactiveCount: 0
  });
  const [totalRevenues, setTotalRevenues] = useState(0);

  const getTotalUsersReport = async () => {
    try {
      let data = await startActionWithPromise(
        totalUsersAction,
        {
          params: {},
          successCallback: () => { },
          failedCallback: () => { },
        },
        dispatch
      );
      if (data) {
        setTotalUsers(data);
      }
    } catch { }
  }

  const getTotalRevenuesReport = async () => {
    try {
      let data = await startActionWithPromise(
        totalRevenuesAction,
        {
          params: {
            startDate: '',
            endDate: ''
          },
          successCallback: () => { },
          failedCallback: () => { },
        },
        dispatch
      );
      if (data) setTotalRevenues(data);
    } catch { }
  }

  const fetchData = (start, end) => {
    (async () => {
      await getTotalUsersReport();
    })();
    (async () => {
      await getTotalRevenuesReport();
    })();
  }

  useEffect(() => {
    fetchData(startDate, endDate);
  }, [refresh]);

  useEffect(() => {
    fetchData(startDate, endDate);
  }, []);

  return (
    <Row>
      <Col md="3">
        <Card className="flex-fill border">
          <CardBody className="pt-3 pb-4">
            <Media style={{ height: '62px' }}>
              <div className="d-inline-block mt-2 mr-3">
                <Users className="feather-lg text-success" />
              </div>
              <Media body>
                {waiting ? (
                  <div className="loading mb-2"><Spinner size="sm" /></div>
                ) : (
                  <div className="">                    
                    <div className="mb-0">
                      <div className="d-flex font-weight-bold mb-1" style={{ fontSize: '1rem' }}>
                        <div className="">Total users</div>
                        <div className="ml-auto">{formatNumber((totalUsers.userActiveCount + totalUsers.userInactiveCount) || 0)}</div>
                      </div>
                      <div className="d-flex">
                        <div>→ Active</div>
                        <div className="ml-auto">{formatNumber(totalUsers.userActiveCount || 0)}</div>
                      </div>
                      <div className="d-flex">
                        <div>→ Inactive</div>
                        <div className="ml-auto">{formatNumber(totalUsers.userInactiveCount || 0)}</div>
                      </div>
                    </div>
                  </div>
                )
                }

              </Media>
            </Media>
          </CardBody>
        </Card>
      </Col>
      <Col md="3">
        <Card className="flex-fill border">
          <CardBody className="py-4">
            <Media>
              <div className="d-inline-block mt-2 mr-3">
                <DollarSign className="feather-lg text-primary" />
              </div>
              <Media body>
                {waiting ? (
                  <div className="loading mb-2"><Spinner size="sm" /></div>
                ) : (
                  <h3 className="mb-2 font-weight-bold">{formatNumber(totalRevenues || 0)}</h3>
                )
                }
                <div className="mb-0 font-weight-bold">Total revenues</div>
              </Media>
            </Media>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

Statistics.propTypes = propTypes;
Statistics.defaultProps = defaultProps;

export default Statistics;
