export const TRUE_FALSE_VALUES = {
  yes: true,
  no: false
}

export const TRUE_FALSE_LABELS = {
  [TRUE_FALSE_VALUES.yes]: 'Yes',
  [TRUE_FALSE_VALUES.no]: 'No',
}

export const TRUE_FALSE_COLORS = {
  [TRUE_FALSE_VALUES.yes]: 'success',
  [TRUE_FALSE_VALUES.no]: 'warning',
}
