import {
    createReducer,
    createActionResources,
    createActionCRUDResources
  } from "redux-waiters";
  import { RESET_REDUCER } from "../constants";
  
  export const searchFeaturedVideoAction = createActionResources("videos-featured/search");
  export const pinVideoAction = createActionResources("videos-featured/set-pin");
  export const featuredVideoAction = createActionCRUDResources("videos-featured");
  export const enableAction = createActionResources("videos-featured/enable");
  export const disableAction = createActionResources("videos-featured/disable");
  export const resyncAction = createActionResources("videos-featured/resync");
  
  const initialState = {
    fetching: false,
    list: [],
    total: 0,
    totalPages: 0,
    pageNumber: 1,
    user: {},
    error: {},
  };
  
  export default createReducer(
    {
      [featuredVideoAction.fetch.start]: state => {
        return { ...state, fetching: true };
      },
      [featuredVideoAction.fetch.success]: (state, action) => {
        return {
          ...state,
          list: action.data?.content,
          pageNumber: action.data?.pageNumber + 1,
          total: action.data?.total,
          totalPages: Math.ceil(action.data?.total / action.data?.pageSize),
          fetching: false,
        };
      },
      [featuredVideoAction.fetch.error]: state => {
        return { ...state, fetching: false };
      },
  
      [RESET_REDUCER]: () => initialState,
    },
    initialState
  );
  