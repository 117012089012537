import { takeLatest, call, put } from "redux-saga/effects";

import { sagaPromise } from "../../helpers/saga-promise-helpers";
import {
  settingsAction,
  fetchTypes,
  fetchServices,
} from "../reducers/settingReducer";
import settingService from "../../services/settingService";
import { alertTypes } from "../constants";
import { SETTING_MESSAGES } from "../../config/messages";

function* fetchWorker(action) {
  try {
    const { data, error } = yield call(
      settingService.fetch,
      action.payload?.params
    );
    if (error) {
      yield put(settingsAction.fetch.error(error));
    } else {
      yield put(settingsAction.fetch.success({ ...action, data }));
    }
  } catch (err) {
    yield put(settingsAction.fetch.error(err));
  }
}

function* addWorker(action) {
  const { data, error } = yield call(
    settingService.create,
    action.payload.data
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      yield put({
        type: alertTypes.ERROR,
        message: error.headers["x-message-info"],
      });
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    yield put({
      type: alertTypes.SUCCESS,
      message: SETTING_MESSAGES.addSuccess,
    });
    return data;
  }

  return false;
}

function* updateWorker(action) {
  const { data, error } = yield call(
    settingService.update,
    action.payload.id,
    action.payload.data
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      if (!action.payload?.hideAlert) {
        yield put({
          type: alertTypes.ERROR,
          message: error.headers["x-message-info"],
        });
        throw new Error(error.headers["x-message-info"]);
      }
      return { message: error.headers["x-message-info"] };
    }
  } else {
    if (!action.payload?.hideAlert)
      yield put({
        type: alertTypes.SUCCESS,
        message: SETTING_MESSAGES.updateSuccess,
      });
    return data;
  }

  return false;
}

function* deleteWorker(action) {
  const { data, error } = yield call(
    settingService.deleteSetting,
    action.payload.id
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      yield put({
        type: alertTypes.ERROR,
        message: error.headers["x-message-info"],
      });
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    yield put({
      type: alertTypes.SUCCESS,
      message: SETTING_MESSAGES.deleteSuccess,
    });
    return data;
  }

  return false;
}

function* fetchTypesWorker(action) {
  try {
    const { data, error } = yield call(settingService.getTypes);
    if (error) {
      yield put(fetchTypes.error(error));
    } else {
      yield put(fetchTypes.success({ ...action, data }));
    }
  } catch (err) {
    yield put(fetchTypes.error(err));
  }
}

function* fetchServicesWorker(action) {
  try {
    const { data, error } = yield call(settingService.getServices);
    if (error) {
      yield put(fetchServices.error(error));
    } else {
      yield put(fetchServices.success({ ...action, data }));
    }
  } catch (err) {
    yield put(fetchServices.error(err));
  }
}

function* settingSaga() {
  yield takeLatest(settingsAction.fetch.start, fetchWorker);
  yield takeLatest(fetchTypes.start, fetchTypesWorker);
  yield takeLatest(fetchServices.start, fetchServicesWorker);

  yield takeLatest(
    settingsAction.create.start,
    settingsAction.create.waiterActionForSaga(sagaPromise(addWorker))
  );
  yield takeLatest(
    settingsAction.update.start,
    settingsAction.update.waiterActionForSaga(sagaPromise(updateWorker))
  );
  yield takeLatest(
    settingsAction.delete.start,
    settingsAction.delete.waiterActionForSaga(sagaPromise(deleteWorker))
  );
}

export default settingSaga;
