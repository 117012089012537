import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Button } from "reactstrap";
import * as Icon from "react-feather";
import classNames from "classnames";
import styled from "styled-components";
import DatePicker from "react-datepicker";

import "./FormFilter.scss";
import CustomInput from "./CustomInput";
import SearchUserInput from "./SearchUserInput";
import { isUUID } from "../../helpers/utils";

const StyledDatetimeDiv = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker__input-container {
    input {
      width: 230px;
    }
    
  }
`;

const FormFilter = ({onSubmitFilter, onResyncFeaturedVideos, loading, handleAdd, bulkAction}) => {

  var videoIdInput = useRef(null);
  var keywordInput = useRef(null);

  const [cancelDebounce, setCancelDebounce] = useState(false);
  const [validateErrors, setValidateErrors] = useState({});

  const [validFrom, setValidFrom] = useState('');
  const [validTo, setValidTo] = useState('');
  const [minDate, setMinDate] = useState('');
  const [maxDate, setMaxDate] = useState('');

  const [filters, setFilters] = useState({
    enabled: '',
    ownerId: '',
    ownerKeyword: '',
    videoId: '',
    videoKeyword: '',
    validStartDate: '',
    validThruDate: '',
    reload: false
  });

  const fetching = false; // useSelector(state => state.bouser?.fetching);

  const handleVideoIdChange = (value) => {
    if (value && !isUUID(value)) {
      setValidateErrors({
        ...validateErrors,
        videoId: 'Video id must be in uuid format.'
      });
    } else {
      let errors = Object.assign({}, validateErrors);
      delete errors.videoId;

      setValidateErrors({
        ...errors
      });
    }
    setCancelDebounce(false);
    setFilters({ ...filters, videoId: value});
  }

  const submitFilter = () => {
    /*
    if (!filters.keywords && !filters.videoOwner) {
      if (keywordInput.current) keywordInput.current.focus();
      return;
    }
    */

    setCancelDebounce(true);
    if (onSubmitFilter) onSubmitFilter(filters);
  };

  const resyncFeaturedVideos = (e) => {
    onResyncFeaturedVideos && onResyncFeaturedVideos();
  }

  useEffect(() => {
    if (filters.reload) {
      submitFilter();
      setFilters({ ...filters, reload: false });
    }
  }, [filters.reload]);

  return (
    <>
      <Row className={classNames('form-filter')} style={{transition: 'visibility 0.5s, opacity 0.5s linear'}}>
        { bulkAction && (
          <Col lg="2">
            <div className="bulk-actions">
              <Button color="success" size="sm" onClick={handleAdd}>
                <Icon.Plus height="18" />
                Add New
              </Button>
            </div>
          </Col>
        )}
        
        <Col lg={bulkAction ? 10 : 12} className="">
          <Row>
            <Col lg="12 d-flex flex-nowrap justify-content-end">
              <div className="d-flex align-items-center">
                <label htmlFor="" className="mb-0 text-nowrap text-right" style={{width: '90px'}}>Video id</label>
                <div className="d-inline-block ml-2 item-filter__control">
                  <CustomInput
                    name="videoId"
                    // value={filters.videoId}
                    className={classNames('', {'is-invalid': validateErrors.videoId ? true : false})}
                    placeholder=""
                    disabled={fetching}
                    debounceTime={3000}
                    cancelDebounce={cancelDebounce}
                    onChange={handleVideoIdChange}
                    onSubmit={(videoId) => {
                      setCancelDebounce(true);
                      setFilters({ ...filters, videoId: videoId, reload: true});}
                    }
                    innerRef={videoIdInput}
                    maxLength="36"
                    style={{width: '230px'}}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center ml-3">
                <label htmlFor="" className="mb-0 text-right" style={{width: '90px'}}>Keywords</label>
                <div className="d-inline-block item-filter__control ml-2">
                  <CustomInput
                    name="keywords"
                    value={filters.keywords}
                    className=""
                    placeholder="Title, subject, topic..."
                    disabled={fetching}
                    debounceTime={3000}
                    cancelDebounce={cancelDebounce}
                    onChange={(keywords) => {
                      setFilters({ ...filters, videoKeyword: keywords});
                      setCancelDebounce(false);
                    }}
                    onSubmit={(keywords) => setFilters({ ...filters, videoKeyword: keywords, reload: true})}
                    innerRef={keywordInput}
                    style={{width: '230px'}}
                    />
                </div>
              </div>
              <div className="filter-input d-flex ml-3">
                <div className="d-flex align-items-center">
                  <label htmlFor="" className="mb-0 text-nowrap text-right" style={{width: '100px'}}>Owner keyword</label>
                  <div className="d-inline-block ml-2 item-filter__control">
                    <CustomInput
                      name="videoOwner"
                      value={filters.videoOwner}
                      className=""
                      placeholder="Name, email..."
                      disabled={fetching}
                      debounceTime={3000}
                      cancelDebounce={cancelDebounce}
                      onChange={(value) => setFilters({ ...filters, ownerKeyword: value})}
                      onSubmit={(value) => {
                        setCancelDebounce(true);
                        setFilters({ ...filters, ownerKeyword: value, reload: true});
                      }}
                      style={{width: '230px'}}
                      />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col lg="12 d-flex flex-nowrap justify-content-end">
              <div className="d-flex align-items-center">
                <label htmlFor="" className="mb-0 text-nowrap text-right" style={{width: '90px'}}>Owner</label>
                <div className="d-inline-block ml-2 item-filter__control">
                  <SearchUserInput
                    onChange={(option) => setFilters({ ...filters, ownerId: option ? option.value : ''})}
                    isClearable={true}
                    />
                </div>
              </div>

              <div className="d-flex align-items-center ml-3">
                <label htmlFor="" className="mb-0 text-nowrap text-right" style={{width: '90px'}}>Valid from</label>
                <StyledDatetimeDiv className="d-inline-block item-filter__control ml-2">
                  <DatePicker
                    selected={filters.validStartDate}
                    onChange={(date) => {setFilters({...filters, validStartDate: date}); setMinDate(date)}}
                    maxDate={maxDate}
                    timeInputLabel="Time:"
                    dateFormat="MM/dd/yyyy HH:mm"
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    // showTimeSelect
                    // className="w-100"
                    style={{width: '230px'}}
                    showTimeInput
                  />
                </StyledDatetimeDiv>
              </div>

              <div className="d-flex align-items-center ml-3">
                <label htmlFor="" className="mb-0 text-nowrap text-right" style={{width: '100px'}}>Valid to</label>
                <StyledDatetimeDiv className="d-inline-block item-filter__control ml-2">
                  <DatePicker
                    selected={filters.validThruDate}
                    onChange={(date) => {setFilters({...filters, validThruDate: date}); setMaxDate(date)}}
                    minDate={minDate}
                    timeInputLabel="Time:"
                    dateFormat="MM/dd/yyyy HH:mm"
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    // showTimeSelect
                    // className="w-100"
                    showTimeInput
                    style={{width: '230px'}}
                  />
                </StyledDatetimeDiv>
              </div>
            </Col>
          </Row>
          

          
        </Col>
      </Row>
      <Row className="mt-2">
        <Col lg="6" className="d-flex">
          
        </Col>
        <Col lg="6" className="d-flex justify-content-end">
        <Button
            className="ml-3"
            size=""
            disabled={loading}
            onClick={(e) => {setCancelDebounce(true); resyncFeaturedVideos(e);}}          >
            <Icon.RefreshCcw className="" style={{ width: '15px', height: '15px', marginTop: '-2px' }} /> Re-sync
          </Button>
          <Button
            className="ml-3"
            size=""
            disabled={loading}
            onClick={(e) => {setCancelDebounce(true); submitFilter(e);}}
          >
            <Icon.Search className="" style={{ width: '15px', height: '15px', marginTop: '-2px' }} /> Search
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default React.memo(FormFilter);