import { post, get, request } from "../helpers/apiHelper";

async function fetch(params) {
  if (params && params.keyword) {
    const data = {
      callerId: "",
      keywords: params.keyword,
      pageNumber: params.pageNumber,
      pageSize: params.pageSize,
      // sort: {
      //   dir: params.order,
      //   fieldName: params.sort
      // }
    };
    return await post("bousers/search", data);
  }
  return await get("bousers", params);
}

async function fetchRoles() {
  return await get("bousers/roles");
}

async function fetchMyRoles() {
  return await get(`bousers/myroles`);
}

async function fetchUserRoles(userId) {
  return await get(`bousers/${userId}/roles`);
}


async function setUserRoles(userId, data) {
  return await request(`bousers/${userId}/roles`, data, "put");
}

async function deleteUserRoles(userId) {
  return await request(`bousers/${userId}/roles`, null, "delete");
}

async function addUserRoles(userId, data) {
  return await post(`bousers/${userId}/roles/addition`, data);
}

async function subUserRoles(userId, data) {
  return await post(`bousers/${userId}/roles/subtraction`, data);
}

async function register(data) {
  return await post(`bousers/registration`, data);
}

async function updateBoUser(userId, data) {
  return await request(`bousers/${userId}`, data, "put");
}

async function deleteBoUser(userId) {
  return await request(`bousers/${userId}`, null, "delete");
}

async function forceLogout(userId) {
  return await request(
    `bousers/${userId}/authentication`,
    null,
    "delete"
  );
}

async function changePassword(userId, data) {
  return await request(`bousers/${userId}/password`, data, "put");
}

async function changeMyPassword(data) {
  return await request(`bousers/mypassword`, data, "put");
}

async function resetPassword(userId) {
  return await request(`bousers/${userId}/password`, null, "delete");
}

async function forgotPassword(email) {
  return await post(`bousers/forgot-password`, {email: email});
}

async function getProfile(userId) {
  return await get(`bousers/${userId}`);
}

async function updateProfile(userId, data) {
  return await request(`bousers/myself`, data, "put");
}

async function enable(userId) {
  return await request(`bousers/${userId}/enable`, null, 'patch');
}

async function disable(userId) {
  return await request(`bousers/${userId}/disable`, null, 'patch');
}

export default {
  fetch,
  fetchRoles,
  fetchMyRoles,
  fetchUserRoles,
  setUserRoles,
  deleteUserRoles,
  addUserRoles,
  subUserRoles,
  register,
  updateBoUser,
  deleteBoUser,
  forceLogout,
  changePassword,
  changeMyPassword,
  resetPassword,
  forgotPassword,
  getProfile,
  updateProfile,
  enable,
  disable,
};
