import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Spinner,
  Badge,
} from "reactstrap";
import * as Icon from "react-feather";

import DataTable, { rowActionTypes } from "../../components/DataTable";
import PageSizeSelector from "../../components/base/PageSizeSelector";
import Paging from "../../components/base/Paging";
import InputSearch from "../../components/base/InputSearch";
import config from "../../config/config";

import UserUpdate from "./UserUpdate";
import UserAdd from "./UserAdd";
import ConfirmModal from "../../components/base/ConfirmModal";
import {
  userTrustedAction,
  disableAction,
  enableAction,
} from "../../redux/reducers/userTrustedReducer";
import { startActionWithPromise } from "../../helpers/saga-promise-helpers";

import UserRemove from "./UserRemove";
import Avatar from "../../components/Avatar";

const UserList = () => {
  const dispatch = useDispatch();

  const users = useSelector(state => state.userTrusted?.users) || [];
  const fetching = false; // useSelector(state => state.user?.fetching);
  const totalPages = useSelector(state => state.userTrusted.totalPages);
  const pageNumber = useSelector(state => state.userTrusted.pageNumber);

  const [state, setState] = useState({
    modalUpdate: false,
    modalDelete: false,
    modalAdd: false,
    modalConfirm: false,
    action: "",
    message: "",
    row: null,
  });

  const [filters, setFilters] = useState({
    pageSize: config.DEFAULT_PAGESIZE,
    pageNumber: 1,
    sort: "userProfile.userId",
    sortReal: "userProfile.userId",
    order: "desc",
    keyword: "",
    reload: false,
  });

  const onColumnSort = (field, order) => {
    // trigger sort data via api
    const realSort = field === "fullname" ? "firstName" : field;
    if (realSort === filters.sortReal && order === filters.order) return;

    setFilters({
      ...filters,
      sort: field,
      sortReal: realSort,
      order,
      reload: true,
    });
  };

  const tableColumns = [
    {
      dataField: "userProfile.firstName",
      text: "Publisher",
      sort: true,
      onSort: (field, order) => {
        onColumnSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row, rowIndex, formatExtraData) => (
        <div className="d-flex">
          {row.avatar?.image ? (
            <Avatar className="user-avatar d-inline-block align-self-center mr-2" image={row.userProfile?.avatar?.image} fullname={row.userProfile?.fullname} width="40" height="40" />
          ) : (
            <Avatar className="user-avatar d-inline-block align-self-center mr-2" image={row.userProfile?.avatar?.image} fullname={row.userProfile?.fullname} width="40" height="40" />
          )}
          <div className="d-inline-block fullname align-self-center">
            <p className="mb-0">{row.userProfile?.fullname}</p>
            <p className="mb-0">{row.userProfile?.email}</p>
            <p className="mb-0">{row.userProfile?.phone}</p>
          </div>
        </div>
      ),
    },
    {
      dataField: "enabled",
      text: "Status",
      sort: true,
      onSort: (field, order) => {
        onColumnSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div>
          <Badge
            color={row.enabled ? "success" : "secondary"}
            className="mr-1 mb-1"
          >
            {row.enabled ? "Enabled" : "Disabled"}
          </Badge>
        </div>
      ),
      headerStyle: () => {
        return { width: "100px" };
      },
    },
    {
      dataField: "trustedOn",
      text: "Trusted On",
      sort: true,
      onSort: (field, order) => {
        onColumnSort(field, order);
      },
      sortFunc: () => {
      },
      headerStyle: () => {
        return { width: "150px" };
      },
    },
  ];

  const rowActions = [
    {
      name: "View",
      type: rowActionTypes.VIEW,
      classes: "text-dark",
    },
    {
      name: "Remove from Whitelist",
      type: rowActionTypes.DELETE,
      classes: "text-dark",
    },
    {
      name: "Enable",
      type: rowActionTypes.ENABLE,
      classes: "text-dark",
      toggleField: "enabled",
    },
    {
      name: "Disable",
      type: rowActionTypes.DISABLE,
      classes: "text-dark",
      toggleField: "enabled",
    },
  ];

  const onSelectedChange = () => {};

  const onSizeChange = size => {
    setFilters({ ...filters, pageSize: size, pageNumber: 1, reload: true });
  };

  const onPageChange = p => {
    setFilters({ ...filters, pageNumber: p, reload: true });
  };

  const onSearchSubmit = keyword => {
    if (filters.keyword === keyword) return;
    setFilters({ ...filters, pageNumber: 1, keyword, reload: true });
  };

  const onActionButtonClick = (action, row) => {
    switch (action.type) {
      case rowActionTypes.VIEW:
        setState({ ...state, modalUpdate: true, row });
        break;
      case rowActionTypes.EDIT:
        setState({ ...state, modalUpdate: true, row });
        break;
      case rowActionTypes.DELETE:
        setState({ ...state, modalDelete: true, row });
        break;
      case rowActionTypes.ENABLE: {
        const msg = `<p class="font-weight-bold">Following user will be enabled in Trusted Publishers: </p>\
        <p class="font-italic">"${`${row.userProfile?.fullname} / ${row.userProfile?.email}`}"</p>\
        <p class="font-weight-bold">Do you want to continue?</p>`;
        setState({
          ...state,
          modalConfirm: true,
          row,
          action: action.type,
          message: msg,
        });
        break;
      }
      case rowActionTypes.DISABLE: {
        const msg2 = `<p class="font-weight-bold">Following user will be disabled in Trusted Publishers: </p>\
        <p class="font-italic">"${`${row.userProfile?.fullname} / ${row.userProfile?.email}`}"</p>\
        <p class="font-weight-bold">Do you want to continue?</p>`;
        setState({
          ...state,
          modalConfirm: true,
          row,
          action: action.type,
          message: msg2,
        });
        break;
      }
      default:
    }
  };

  const refreshDatatable = () => {
    setFilters({ ...filters, reload: true });
  };

  const onUpdateStatusSuccess = () => {
    setState({ ...state, modalConfirm: false, row: null });
    refreshDatatable();
  };

  const updateStatus = (row, status) => {
    (async () => {
      try {
        if (status)
          await startActionWithPromise(
            enableAction,
            {
              id: row.userProfile?.userId,
              successCallback: () => onUpdateStatusSuccess(),
              failedCallback: () => {},
            },
            dispatch
          );
        else
          await startActionWithPromise(
            disableAction,
            {
              id: row.userProfile?.userId,
              successCallback: () => onUpdateStatusSuccess(),
              failedCallback: () => {},
            },
            dispatch
          );
      } catch {}
    })();
  };

  const onActionConfirm = () => {
    switch (state.action) {
      case rowActionTypes.ENABLE:
        updateStatus(state.row, true);
        break;
      case rowActionTypes.DISABLE:
        updateStatus(state.row, false);
        break;
      default:
    }
  };

  const onAddNew = () => {
    setState({ ...state, modalAdd: true, row: null });
  };

  const toggleModal = modal => {
    if (state[modal]) setState({ ...state, [modal]: !state[modal] });
    else setState({ ...state, [modal]: !state[modal], row: null });
  };

  const fetchData = (params) => {
    (async () => {
      try {
        await startActionWithPromise(
          userTrustedAction.fetch,
          {
            params,
            successCallback: () => {},
            failedCallback: () => {},
          },
          dispatch
        );
      } catch {}
    })();
  };

  useEffect(() => {
    if (filters.reload) {
      const params = {
        pageSize: filters.pageSize,
        pageNumber: filters.pageNumber - 1,
        keyword: filters.keyword,
        orderBy: `${filters.sortReal} ${filters.order.toUpperCase()}`,
      };
      fetchData(params);
      setFilters({ ...filters, reload: false });
    }
  }, [filters.reload]);

  useEffect(() => {
    const params = {
      pageSize: filters.pageSize,
      pageNumber: filters.pageNumber - 1,
    };
    fetchData(params);

    return () => {
      setState({});
    };
  }, []);

  return (
    <>
      <Card className="w-100 mb-0">
        <CardHeader>
          <Row>
            <Col lg="6" md="6" xs="6" className="d-flex">
              <div className="bulk-actions">
                <Button color="success" size="sm" onClick={onAddNew}>
                  <Icon.Plus height="18" />
                  Add New
                </Button>
                <Button color="danger" size="sm" className="ml-1 d-none">
                  <Icon.Trash2 height="18" />
                  Bulk Delete
                </Button>
              </div>
            </Col>
            <Col lg="6" md="6" xs="6" className="d-flex justify-content-end">
              <InputSearch
                value={filters.keyword}
                onSubmit={onSearchSubmit}
                disabled={fetching}
              />
            </Col>
          </Row>
        </CardHeader>
        <CardBody className="pt-0 data-list position-relative">
          {fetching && (
            <div className="text-center w-100 bg-white p-4 position-static h-100">
              <Spinner />
            </div>
          )}
          {!fetching && (
            <>
              <DataTable
                keyField="userProfile.userId"
                data={users}
                columns={tableColumns}
                sort={filters.sort}
                order={filters.order}
                actions={rowActions}
                onActionClick={onActionButtonClick}
                onSelectedChange={onSelectedChange}
                hideSelectColumn={true}
              />
              <Row className="mt-3">
                <Col lg="6" md="6" className="d-flex">
                  <PageSizeSelector
                    size={filters.pageSize}
                    onChange={onSizeChange}
                  />
                </Col>
                <Col lg="6" md="6" className="d-flex justify-content-end">
                  <Paging
                    totalPages={totalPages}
                    current={pageNumber}
                    show={5}
                    onSelect={onPageChange}
                  />
                </Col>
              </Row>
            </>
          )}
        </CardBody>
      </Card>

      <UserUpdate
        modal={state.modalUpdate}
        toggle={toggleModal}
        row={state.row}
      />
      <UserAdd
        modal={state.modalAdd}
        toggle={toggleModal}
        row={state.row}
        refreshData={refreshDatatable}
      />
      <ConfirmModal
        modal={state.modalConfirm}
        toggle={toggleModal}
        row={state.row}
        onConfirm={onActionConfirm}
        message={state.message}
      />
      <UserRemove
        modal={state.modalDelete}
        toggle={toggleModal}
        row={state.row}
        refreshData={refreshDatatable}
      />
    </>
  );
};

export default UserList;
