import React, { useState, useRef } from "react";
import { Row, Col, Button, Input } from "reactstrap";
import _ from 'lodash';
import * as Icon from "react-feather";
import classNames from "classnames";
import moment from "moment";
import styled from "styled-components";
import DatePicker from "react-datepicker";

import "./FormFilter.scss";
import SearchUserInput from "./SearchUserInput";
import CustomInput from "../videos-featured/CustomInput";
import Selectbox from '../../components/Selectbox/Selectbox';
import { TRANSFER_STATUSES, TRANSFER_STATUS_LABELS } from "./constants";

const StyledDatetimeDiv = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker__input-container {
    input {
      width: 230px;
    }
    
  }
`;

let StateOptions = [{
  label: 'Status',
  value: '',
}];
Object.entries(TRANSFER_STATUSES).forEach(([key, value]) => {
  StateOptions.push({
    label: TRANSFER_STATUS_LABELS[value],
    value: value,
  });
});

const FormFilter = ({loading, paging, mergeFilters, user}) => {
  const [minYearMonth, setMinYearMonth] = useState('');
  const [maxYearMonth, setMaxYearMonth] = useState('');

  const [filters, setFilters] = useState({
    startYearMonth: '',
    endYearMonth: '',
    userId: user ? user.userId : '',
    status: '',
    transferDate: '',
    transactionReference: '',
    reload: false,
  });

  const handleUserChange = (option) => {
    setFilters({ ...filters, userId: option ? option.value : ''});
  }

  const handleStatusChange = (option) => {
    if (option && (option.value == true || option.value == false) ) 
        setFilters({ ...filters, status: option.value, reload: true });
    else 
        setFilters({ ...filters, status: '', reload: true });
  }

  const submitFilter = () => {
    // console.log(mergeFilters);
    mergeFilters({...filters, reload: true});
  }

  return (
    <>
      <Row className={classNames('video-review-filter')} style={{transition: 'visibility 0.5s, opacity 0.5s linear'}}>
        <Col lg="12" md="12" xs="12" className="">
        <div className="item-filter float-center">
            <div className="d-flex align-items-center">
              
              <div className="d-flex align-items-center ml-3">
                <label htmlFor="" className="mb-0 text-nowrap text-right" style={{width: '80px'}}>User</label>
                <div className="d-inline-block ml-2 item-filter__control" style={{width: '200px'}}>
                  <SearchUserInput
                    onChange={handleUserChange}
                    className="w-100"
                    isClearable={true}
                    name="userId"
                    />
                </div>
              </div>
              <div className="filter-status ml-3" style={{width: '200px'}}>
                <Selectbox
                  name="search_state"
                  placeholder="Status"
                  options={StateOptions}
                  onChange={handleStatusChange}
                />
              </div>
              <div className="d-flex align-items-center ml-3">
                <label htmlFor="" className="mb-0 text-nowrap text-right" style={{width: '60px'}}>From</label>
                <StyledDatetimeDiv className="d-inline-block item-filter__control ml-2">
                  <DatePicker
                    selected={filters.startYearMonth}
                    onChange={(date) => {setFilters({...filters, startYearMonth: date}); setMinYearMonth(date)}}
                    maxDate={maxYearMonth}
                    dateFormat="MM/yyyy"
                    className="w-100"
                    showMonthYearPicker="true"
                    //style={{width: '230px'}}
                  />
                </StyledDatetimeDiv>
              </div>

              <div className="d-flex align-items-center ml-3">
                <label htmlFor="" className="mb-0 text-nowrap text-right" style={{width: '30px'}}>to</label>
                <StyledDatetimeDiv className="d-inline-block item-filter__control ml-2">
                  <DatePicker
                    selected={filters.endYearMonth}
                    onChange={(date) => {setFilters({...filters, endYearMonth: date}); setMaxYearMonth(date)}}
                    minDate={minYearMonth}
                    dateFormat="MM/yyyy"
                    className="w-100"
                    showMonthYearPicker="true"
                  />
                </StyledDatetimeDiv>
              </div>
              <div className="d-flex align-items-center">
                <Button
                  className="ml-2"
                  size=""
                  disabled={loading}
                  onClick={(e) => submitFilter(e)}
                  style={{minWidth: '86px'}}
                >
                  <Icon.Search style={{ width: '15px', height: '15px', marginTop: '-2px' }} /> Search
                </Button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col lg="12" md="12" xs="12" className="">
          <div className="d-flex align-items-right">
            <div className="d-flex align-items-center ml-3">
              <label htmlFor="" className="mb-0 text-nowrap text-right" style={{width: '80px'}}>Transfer Date</label>
              <StyledDatetimeDiv className="d-inline-block item-filter__control ml-2">
                <DatePicker
                  selected={filters.transferDate}
                  onChange={(date) => {setFilters({...filters, transferDate: date});}}
                  dateFormat="MM/dd/yyyy"
                  className="w-100"
                  //style={{width: '230px'}}
                />
              </StyledDatetimeDiv>
            </div>
            <div className="filter-input d-flex ml-3">
              <div className="d-flex align-items-center">
                <label htmlFor="" className="mb-0 text-nowrap text-right" style={{width: '150px'}}>Transfer Reference</label>
                <div className="d-inline-block ml-2 item-filter__control">
                  <CustomInput
                    name="transactionReference"
                    value={filters.transactionReference}
                    className=""
                    onChange={(value) => setFilters({ ...filters, transactionReference: value})}
                    style={{width: '230px'}}
                    isClearable={true}
                    />
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default React.memo(FormFilter);