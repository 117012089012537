import React, { useState } from "react";
import { useDispatch } from "react-redux";
import AsyncSelect from 'react-select/async';
import PropTypes from "prop-types";

import { searchUsersAction } from "../../redux/reducers/userReducer";
import { startActionWithPromise } from "../../helpers/saga-promise-helpers";

const selectStyles = {
  control: provided => (
    {
      ...provided,
      borderWidth: '0px',
      minHeight: '31px',
      borderRadius: '2px',
      boxShadow: '0 0 0 1px #ced4da',
      "&:hover": {
        borderColor: '#ced4da'
      }
    }),
  option: (provided, state) => {
    return {
      ...provided,
      backgroundColor: state.isSelected ? "#86C9CF" : provided.backgroundColor,
      "&:active": {
        backgroundColor: "#86C9CF"
      },
      padding: '3px 8px'
    }
  },
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '4px'
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '1px 8px'
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: '4px'
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: '3',
    borderRadius: '2px',
  }),
};

const propTypes = {
  userId: PropTypes.string,
  onChange: PropTypes.func
}
const defaultProps = {
  userId: '',
  onChange: () => { }
}

const SearchUserInput = ({ onChange, ...rest }) => {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState(false);

  const successCallback = () => {
  }
  const failedCallback = () => {
  }

  const loadUsersOptions = async (inputValue) => {
    try {
      // await delay(5000);
      const data = await startActionWithPromise(
        searchUsersAction,
        { params: { keywords: inputValue, pageSize: 20, pageNumber: 0 }, successCallback, failedCallback },
        dispatch
      );

      const list = data.content?.filter(user => user.active).map(item => ({
        label: `${item.firstName} ${item.lastName} (${item.username})`,
        value: item.userId
      })) || [];
      return list;
    } catch { }
    return [];
  }

  const handleInputChange = (inputValue) => {
    // console.log(inputValue);
  }

  const onOptionChange = (option) => {
    setSelected(option);
    if (onChange) onChange(option);
  }

  return (
    <>
      <AsyncSelect
        styles={selectStyles}
        // cacheOptions
        // defaultOptions
        isSearchable
        value={selected ? selected : false}
        loadOptions={loadUsersOptions}
        placeholder=""
        onInputChange={handleInputChange}
        onChange={(option) => onOptionChange(option)}
        {...rest}
      />
    </>
  );
}

SearchUserInput.propTypes = propTypes;
SearchUserInput.defaultProps = defaultProps;

export default SearchUserInput;