import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Table,
  UncontrolledTooltip
} from "reactstrap";
import PropTypes from "prop-types";
import moment from "moment";
import { RefreshCw } from "react-feather";

import { SCREENS, SCREEN_COPY } from "./constants";
import { formatNumber } from "../../helpers/utils";
import ConfirmModal from "../../components/base/ConfirmModal";
import { recloneAction } from "../../redux/reducers/orderReducer";
import { startActionWithPromise } from "../../helpers/saga-promise-helpers";

const propTypes = {
  modal: PropTypes.bool,
  toggle: PropTypes.func,
};

const defaultProps = {
  modal: false,
  toggle: () => {},
};

const boldStyles = {
  marginBottom: '0.5rem',
  fontWeight: '600',
  lineHeight: '1.2',
  color: '#000',
}

const orderItems = [
  {
    id: 1,
    item_name: 'Physic 1',
    item_desc: '',
    qty: 10,
    item_price: 100,
    total: 1000
  }
];

const RecloneAction = ({item, ...rest}) => {
  return (
    <span
      className="ml-auto position-absolute"
      id={`reclone-${item.id}`}
      {...rest}
      style={{right: 0, top: 0, cursor: 'pointer'}}
    >
      <RefreshCw width="15" />
      <UncontrolledTooltip target={'reclone-' + item.id}>Re-clone video</UncontrolledTooltip>
    </span>
  )
}

const OrderDetail = props => {
  const dispatch = useDispatch();

  const { modal, toggle, order } = props;
  let [recloneModal, setRecloneModal] = useState(false);
  let [message, setMessage] = useState('');
  let [video, setVideo] = useState(false);
  let [waiting, setWaiting] = useState(false);

  const toggleModal = () => {
    if (toggle) toggle('modalDetail');
  };

  const handleReclone = (item) => {
    console.log(item);
    let msg = `<p class="font-weight-bold">Following video will be re-clone to ${order.buyer?.email}: </p>\
    <p class="font-italic">"${item.item_name}"</p>\
    <p class="font-weight-bold">Do you want to continue?</p>`;
    setMessage(msg);
    setVideo(item.id);
    setRecloneModal(true);
  }

  const onConfirmReclone = async () => {
    console.log('confirm');
    console.log(video);
    const params = {
      orderId: order.id,
      videoId: video,
      userId: order.buyer.uid,
    };

    setWaiting(true);
    try {
      await startActionWithPromise(
        recloneAction,
        {
          params,
          successCallback: () => {},
          failedCallback: () => {},
        },
        dispatch
      );
    } catch (err) { console.log(err) }
    setWaiting(false);
  }

  return (
    <>
    <Modal isOpen={modal} toggle={toggleModal} centered size="lg">
      <ModalHeader toggle={toggleModal}>
        Order #{order.id}
      </ModalHeader>
      <ModalBody className="">
        
        <Row>
          <Col lg="12">
            { order && (
              <div className="request-detail">
                <div className="d-flex">
                  <div className="left w-50">
                  <p className="mb-2"><span style={boldStyles}>No:</span> #{order.id}</p>
                  <p className="mb-2"><span style={boldStyles}>Total:</span> ${formatNumber(order.total)}</p>
                  <p className="mb-2"><span style={boldStyles}>User:</span> {order.buyer?.fullname} ({order.buyer?.email})</p>
                  <p className="mb-2"><span style={boldStyles}>Created at:</span> {order.createdAt ? moment(order.createdAt).utc().format('YYYY/MM/DD HH:mm:ss') : ''}</p>
                  </div>
                  <div className="right w-50">
                    <h5>Payment method</h5>
                    <div className="mb-3">
                      {order.payment_method}
                    </div>
                    <h5>Status</h5>
                    <div className="">
                      {order.status}
                    </div>
                  </div>
                </div>
                
                <div className="order-items mt-3">
                  <Table bordered>
                    <thead>
                      <tr>
                        <th style={{ width: "10" }}>No</th>
                        <th style={{ width: "45%" }}>Name</th>
                        <th style={{ width: "15%", textAlign: 'right' }}>Unit price</th>
                        <th style={{ width: "15%", textAlign: 'right' }}>Quantity</th>
                        <th style={{ width: "15%", textAlign: 'right' }}>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderItems && orderItems.map((item, i) => (
                        <tr key={i}>
                          <td>{i+1}</td>
                          <td>
                            <div className="order-item-name position-relative">
                              {item.item_name} <RecloneAction item={item} onClick={() => handleReclone(item)} />
                            </div>
                            
                          </td>
                          <td className="text-right">${formatNumber(item.item_price)}</td>
                          <td className="text-right">{item.qty}</td>
                          <td className="text-right">${formatNumber(item.total)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>

                <div className="d-flex">
                  <div className="left" style={{width: '60%'}}>
                        
                  </div>
                  <div className="right" style={{padding: '0.625rem', width: '40%'}}>
                    <div className="d-flex">
                      <div className="txt">Discount</div>
                      <div className="sub ml-auto">$1</div>
                    </div>
                    <div className="d-flex">
                      <div className="txt">Sub total</div>
                      <div className="sub ml-auto">$5</div>
                    </div>
                    <hr />
                    <div className="d-flex" style={{fontWeight: '600', fontSize: '1rem'}}>
                      <div className="txt">Total</div>
                      <div className="sub ml-auto">${formatNumber(order.total)}</div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Col>
        </Row>
        
      </ModalBody>
      
    </Modal>
    <ConfirmModal
        modal={recloneModal}
        toggle={() => setRecloneModal(!recloneModal)}
        onConfirm={onConfirmReclone}
        message={message}
      />
    </>
  );
};

OrderDetail.propTypes = propTypes;
OrderDetail.defaultProps = defaultProps;

export default OrderDetail;
