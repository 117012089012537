import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  FormGroup,
  Label,
} from "reactstrap";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";

import {
  userRolesAction,
  fetchRolesAction,
  setRolesAction
} from "../../redux/reducers/roleReducer";
import { startActionWithPromise } from "../../helpers/saga-promise-helpers";

const propTypes = {
  row: PropTypes.object,
  modal: PropTypes.bool,
  toggle: PropTypes.func,
};

const defaultProps = {
  row: {},
  modal: false,
  toggle: () => {},
};

const USERS_ROLE = 'USERS';

const UserRoles = props => {
  const dispatch = useDispatch();
  const submitButton = useRef(null);

  const { modal, toggle, row } = props;

  const { register, handleSubmit } = useForm();

  const list = useSelector(state => state.role?.roles);
  // const userRoles = useSelector(state => state.role?.userRoles);

  const [waiting, setWaiting] = useState(false);
  const [userRoles, setUserRoles] = useState([]);

  const [checkedRoles, setCheckedRoles] = useState([...userRoles]);
  const roles = [USERS_ROLE, ...list.filter(x => x !== USERS_ROLE)];

  const toggleModal = () => {
    if (toggle) toggle("modalRoles");
  };

  const triggerSubmitForm = () => {
    if (submitButton.current) submitButton.current.click();
  };

  const onCheckedChangeRole = (e, role) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    const chk = checkedRoles.find(x => x === role);
    if (chk) {
      const roles = checkedRoles.filter(x => x !== role);
      setCheckedRoles(roles);
    } else {
      setCheckedRoles([...checkedRoles, role]);
    }
  };

  const onSubmit = async () => {
    setWaiting(true);

    try {
      await startActionWithPromise(
        setRolesAction,
        {
          userId: row.userId,
          roles: checkedRoles.length === 0 ? [USERS_ROLE] : checkedRoles,
          successCallback,
          failedCallback,
        },
        dispatch
      );
    } catch (error) {
    }
    setWaiting(false);
  };

  const fetchRoles = () => {
    (async () => {
      try {
        dispatch(fetchRolesAction.start());

        let roles = await startActionWithPromise(
          userRolesAction,
          {
            userId: row.userId,
            successCallback: () => {},
            failedCallback: () => {},
          },
          dispatch
        );
        
        if (roles) {
          setUserRoles(roles);
          setCheckedRoles(roles);
        } else {
          setUserRoles([]);
          setCheckedRoles([]);
        }
      } catch {}
    })();
  }

  const successCallback = () => {
    setWaiting(false);
    toggleModal();
  };

  const failedCallback = () => {
    setWaiting(false);
  };

  useEffect(() => {
    if (modal) fetchRoles();
  }, [dispatch, modal]);

  return (
    <Modal
      isOpen={modal}
      toggle={toggleModal}
      centered
      size="md"
    >
      <ModalHeader toggle={toggleModal}>
        User roles
      </ModalHeader>
      <ModalBody className="">
        <div className="user-detail p-3 border bg-light rounded">
          {row && (
            <>
              <div className="title mb-2">
                <div className="font-weight-bold">Username: {row.username}</div>
                <div className=""></div>
              </div>
              <div className="title mb-2">
                <div className="font-weight-bold">Email: {row.email}</div>
                <div className=""></div>
              </div>
              <div className="title">
                <div className="font-weight-bold">Fullname: {row.fullname}</div>
                <div className=""></div>
              </div>
            </>
          )}
        </div>
        <form onSubmit={handleSubmit(onSubmit)} id="form-bouser" className="mt-3">
          <Row className="d-none">
            <Col lg="12">
              <FormGroup>
                <FormGroup check inline className="">
                  <Label check>
                    <input
                      className="form-check-input"
                      name="enabled"
                      type="checkbox"
                      defaultChecked
                      ref={register}
                    />
                    Enabled
                  </Label>
                </FormGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <FormGroup>
                <div className="d-none">
                  <Label className="font-weight-bold" for="domain">
                    Roles
                  </Label>
                </div>
                {roles &&
                  roles.map(role => (
                    <FormGroup key={`col-${role}`} className="d-inline mr-3">
                      <Label>
                        <input
                          className="mr-1"
                          type="checkbox"
                          disabled={role === USERS_ROLE}
                          checked={role === USERS_ROLE || !!(checkedRoles && checkedRoles.indexOf(role) !== -1)}
                          onChange={e => {
                            onCheckedChangeRole(e, role);
                          }}
                        />
                        <span>{role}</span>
                      </Label>
                    </FormGroup>
                  ))}
              </FormGroup>
            </Col>
          </Row>

          <Button
            className="d-none"
            type="submit"
            innerRef={submitButton}
          >
            Submit
          </Button>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" disabled={waiting} onClick={toggleModal}>
          Cancel
        </Button>
        <Button
          className="ml-2"
          color="primary"
          disabled={waiting}
          onClick={() => triggerSubmitForm(true)}
        >
          Save changes
        </Button>
      </ModalFooter>
    </Modal>
  );
};

UserRoles.propTypes = propTypes;
UserRoles.defaultProps = defaultProps;

export default UserRoles;
