import { takeLatest, call, put } from "redux-saga/effects";
import { sagaPromise } from "../../helpers/saga-promise-helpers";

import productService from "../../services/productService";
import {
    searchProductsAction,
} from "../reducers/productReducer";

function* searchProductsWorker(action) {
    try {
        const { data, error } = yield call(
            productService.searchProducts,
            action.payload?.params
        );
        if (error) {
            yield put(searchProductsAction.error(error));
        } else {
            yield put(searchProductsAction.success({ ...action, data }));
        }
    } catch (err) {
        yield put(searchProductsAction.error(err));
    }
}

function* productSaga() {
    yield takeLatest(
        searchProductsAction.start,
        searchProductsAction.waiterActionForSaga(sagaPromise(searchProductsWorker))
    );
}

export default productSaga;
