import { createReducer, createActionResources } from "redux-waiters";
import { RESET_REDUCER } from "../constants";

export const fetchDomainsAction = createActionResources("domain/fetch");
export const fetchActionsAction = createActionResources("domain/fetch-actions");

const initialState = {
  fetching: false,
  domains: [],
  actions: [],
};

export default createReducer(
  {
    [fetchDomainsAction.start]: state => {
      return { ...state, fetching: true };
    },
    [fetchDomainsAction.success]: (state, data) => {
      return {
        ...state,
        domains: data,
        fetching: false,
      };
    },
    [fetchDomainsAction.error]: state => {
      return { ...state, fetching: false };
    },
    [fetchActionsAction.start]: state => {
      return { ...state, fetching: true };
    },
    [fetchActionsAction.success]: (state, data) => {
      return { ...state, actions: data, fetching: false };
    },
    [fetchActionsAction.error]: state => {
      return { ...state, fetching: false };
    },
    [RESET_REDUCER]: () => {
      return initialState;
    },
  },
  initialState
);
