import React from "react";
import moment from "moment";
import { UncontrolledTooltip, Badge } from "reactstrap";
import * as Icon from "react-feather";

import { SUBSCRIPTION_STATE_LABELS, SUBSCRIPTION_STATE_COLORS } from "./constants";

export const rowActionTypes = {
  CANCEL: "ROW_ACTION_CANCEL",
  SYNC_WITH_KILLBILL: "ROW_ACTION_SYNC_WITH_KILLBILL",
};

const formatPeriod = (type) => {
  return type === 'NO_BILLING_PERIOD' ? 'NONE' : type;
}

export const getTableColumns = (handleSort = () => { }, isBoAdmin, state, setState) => {

  return [
    {
      dataField: "subscriberId",
      text: "Subscriber",
      sort: true,
      onSort: (field, order) => {
        handleSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div id="{row.subscriptionId}" className="d-inline-block fullname align-self-center">
          <div>
            <p className="mb-0">{row.subscriberFullname}</p>
            <p className="mb-0">{row.subscriberEmailAddress}</p>
            <p className="mb-0 font-italic" style={{ fontSize: '0.75rem' }}>({row.subscriberId})</p>
          </div>
        </div>
      ),
      headerStyle: () => {
        return { minWidth: '300px' };
      },
    },
    {
      dataField: "planName",
      text: "Product plan",
      sort: true,
      formatter: (cell, row) => (
        <div className="">
          <div className="order-item">
            <div id="{row.kbProductName}">{row.productName}</div>
            <div id="{row.kbPlanName}">{row.planName}</div>
          </div>
        </div>
      ),
      headerStyle: () => {
        return { width: '240px' };
      },
    },
    {
      dataField: "billingPeriod",
      text: "Billing Period",
      sort: true,
      formatter: (cell, row) => (
        <div>
          {formatPeriod(row.billingPeriod)}
        </div>
      ),
      headerStyle: () => {
        return { width: '120px' };
      },
    },
    {
      dataField: "state",
      text: "State",
      sort: true,
      onSort: (field, order) => {
        handleSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div className="">
          <Badge
            color={SUBSCRIPTION_STATE_COLORS[row.state]}
            className="mr-1 mb-1"
          >
            {SUBSCRIPTION_STATE_LABELS[row.state]}
          </Badge>
          {row.cancelledDate && <div style={{ fontSize: '0.75rem' }}>({moment(row.cancelledDate).format('MM/DD/YYYY')})</div>}
        </div>
      ),
      headerStyle: () => {
        return { width: '120px' };
      },
    },
    {
      dataField: "effectiveDate",
      text: "Valid from",
      sort: true,
      onSort: (field, order) => {
        handleSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div className="">
          {row.effectiveDate ? moment(row.effectiveDate).format('MM/DD/YYYY') : ''}
        </div>
      ),
      headerStyle: () => {
        return { width: "100px" };
      },
    },
    {
      dataField: "validToDate",
      text: "Valid through",
      sort: true,
      onSort: (field, order) => {
        handleSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div className="">
          {row.validToDate ? moment(row.validToDate).format('MM/DD/YYYY') : ''}
        </div>
      ),
      headerStyle: () => {
        return { width: "100px" };
      },
    },
    {
      dataField: "action_col",
      text: "Actions",
      sort: false,
      formatter: (cell, row, rowIndex, formatExtraData) => (
        <div className="actions-col text-center">

          <a href="#" className="mr-2" onClick={e => formatExtraData.handleSync(row, e)} id={'sync-' + row.action_col}>
            <Icon.RefreshCw className="text-muted" style={{ width: "15px" }} />
            <UncontrolledTooltip target={'sync-' + row.action_col}>
              Sync with KillBill
            </UncontrolledTooltip>
          </a>
          {isBoAdmin && (row.state != 'CANCELLED') ? (
            <a href="#" className="mr-2" onClick={e => formatExtraData.handleCancel(row, e)} id={'cancel-' + row.action_col}>
              <Icon.XCircle className="text-dark" style={{ width: "15px" }} />
              <UncontrolledTooltip target={'cancel-' + row.action_col}>
                Cancel the subscription
              </UncontrolledTooltip>
            </a>
          ) : (<span></span>)}
        </div>
      ),
      formatExtraData: {
        handleSync: (row, e) => {
          //setState({ ...state, modalConfirm: true, row });
          let message = `<p class="font-weight-bold">Sync the subscription (id "${`${row.subscriptionId}`}") with the KillBill one. </p>\
                    <p class="font-weight-bold">Do you want to continue?</p>`;
          setState({
            ...state,
            modalConfirm: true,
            row,
            action: rowActionTypes.SYNC_WITH_KILLBILL,
            message: message,
          });
        },
        handleCancel: (row, e) => {
          let message = `<p class="font-weight-bold">Cancel the subscription (id "${`${row.subscriptionId}`}"). </p>\
                    <p class="font-weight-bold">Do you want to continue?</p>`;
          setState({
            ...state,
            modalConfirm: true,
            row,
            action: rowActionTypes.CANCEL,
            message: message,
          });
        },
      },
      headerStyle: () => {
        return { textAlign: "center", width: "100px" };
      },
    },
  ];
}