import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Row,
  Col,
  FormGroup,
  Label,
  Card,
  CardHeader,
  CardBody,
  Spinner,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import PropTypes from "prop-types";
import AsyncSelect from 'react-select/async';

import {
  getProfileAction,
  updateProfileAction,
} from "../../redux/reducers/bouserReducer";
import { searchUsersAction }  from "../../redux/reducers/userReducer";
import { startActionWithPromise } from "../../helpers/saga-promise-helpers";
import "./ProfileForm.scss";

const propTypes = {
  row: PropTypes.object,
  modal: PropTypes.bool,
  toggle: PropTypes.func,
};

const defaultProps = {
  row: {},
  modal: false,
  toggle: () => {},
};

const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const selectStyles = {
  control: provided => (
    { ...provided, 
      borderWidth: '0px', 
      minHeight:'31px', 
      borderRadius: '2px', 
      boxShadow: '0 0 0 1px #ced4da',
      "&:hover": {
        borderColor: '#ced4da'
      }
    }),
  option: (provided, state) => {
    return {
      ...provided,
      backgroundColor: state.isSelected ? "#86C9CF" : provided.backgroundColor,
      "&:active": {
        backgroundColor: "#86C9CF"
      },
      // "&:hover": {
      //   backgroundColor: "#deebff"
      // },
      padding: '3px 8px'
    }
  },
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '4px'
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '1px 8px'
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: '4px'
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: '3',
    borderRadius: '2px',
  }),
};

const ProfileForm = () => {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, control } = useForm();

  const profile = useSelector(state => state.bouser?.profile);

  const [pristine, setPristine] = useState(true);
  const [isWaiting, setWaiting] = useState(false);

  const user = JSON.parse(localStorage.getItem("user"));

  const successCallback = () => {};

  const failedCallback = () => {};

  const onSubmit = async data => {
    const postData = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phone: data.phone,
      userId: user.userId,
      reviewerAccountId: data.reviewAccountLink ? data.reviewAccountLink.value : '',
    };

    setWaiting(true);
    try {
      await startActionWithPromise(
        updateProfileAction,
        { id: user.userId, data: postData, successCallback, failedCallback },
        dispatch
      );
    } catch (error) {}
    setWaiting(false);
  };

  const onReviewAccountLinkChange = (value) => {
    // if (value) setPristine(false);
    // else setPristine(true);
    setPristine(false);
  }

  const loadOptions = async (inputValue) => {
    try {
      const data = await startActionWithPromise(
        searchUsersAction,
        { params: {keywords: inputValue, pageSize: 20, pageNumber: 0}, successCallback, failedCallback },
        dispatch
      );

      const list = data.content?.filter(user => user.active).map(item => ({
        label: `${item.firstName} ${item.lastName} (${item.username})`,
        value: item.userId
      })) || [];
      return list;
    } catch (err) {
    }
    return [];
  }

  const handleInputChange = () => {}

  const isNumberKey = (evt) => {
    if (evt.keyCode === 8 || (evt.charCode >= 48 && evt.charCode <= 57)) {
      return true;
    } else {
      evt.preventDefault();
      return;
    }
  }

  useEffect(() => {
    dispatch(getProfileAction.start({ id: user.userId }));
  }, [dispatch, user.userId]);

  return (
    <Card className="w-100 mb-0">
      <CardHeader className="border-bottom font-weight-bold">
        Update profile
      </CardHeader>
      <CardBody className="pt-3 position-relative">
        <form onSubmit={e => e.preventDefault()} id="form-profile">
          <Row>
            <Col lg="6">
              <FormGroup>
                <Label for="firstName" className="">
                  First name
                </Label>
                <input
                  className="form-control"
                  type="text"
                  id="firstName"
                  name="firstName"
                  placeholder=""
                  defaultValue={profile?.firstName || ""}
                  onChange={() => setPristine(false)}
                  ref={register({ required: "First name is required." })}
                />
                <p className="text-danger">{errors.firstName?.message}</p>
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <Label for="lastName" className="">
                  Last name
                </Label>
                <input
                  className="form-control"
                  type="text"
                  id="lastName"
                  name="lastName"
                  placeholder=""
                  defaultValue={profile?.lastName || ""}
                  onChange={() => setPristine(false)}
                  ref={register({ required: "Last name is required." })}
                />
                <p className="text-danger">{errors.lastName?.message}</p>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <FormGroup>
                <Label for="email" className="">
                  Email
                </Label>
                <input
                  className="form-control"
                  type="text"
                  name="email"
                  id="email"
                  placeholder=""
                  defaultValue={profile.email || ""}
                  onChange={() => setPristine(false)}
                  ref={register({
                    required: true,
                    pattern: pattern,
                  })}
                />
                <p className="text-danger">
                  {errors.email?.type === "required"
                    ? "Email is required."
                    : ""}
                  {errors.email?.type === "pattern"
                    ? "Invalid email address."
                    : ""}
                </p>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <FormGroup>
                <Label for="phone" className="">
                  Phone
                </Label>
                <input
                  className="form-control"
                  type="number"
                  id="phone"
                  name="phone"
                  placeholder=""
                  pattern="^-?[0-9]\d*\.?\d*$"
                  onKeyPress={(e) => isNumberKey(e)}
                  defaultValue={profile?.phone || ""}
                  onChange={() => setPristine(false)}
                  ref={register}
                />
                <p className="text-danger">{errors.phone?.message}</p>
              </FormGroup>
            </Col>
          </Row>
          {profile && profile.userId && (
            <Row>
              <Col lg="12">
                <FormGroup>
                  <Label for="reviewAccountLink" className="">
                    Review account link
                  </Label>
                  <Controller
                    name="reviewAccountLink"
                    control={control}
                    onChange={(a) => {console.log(a)} }
                    defaultValue={profile.reviewAccountLink ? ({
                      value: profile.reviewAccountLink?.userId,
                      label: `${profile.reviewAccountLink?.firstName} ${profile.reviewAccountLink?.lastName} (${profile.reviewAccountLink?.username})`
                    }) : null}
                    rules={{required: 'Review account link is required.'}}
                    render={({onChange, value, isDisabled, ...rest}) => (
                      <AsyncSelect
                        styles={selectStyles}
                        cacheOptions
                        loadOptions={loadOptions}
                        onInputChange={handleInputChange}
                        placeholder=""
                        defaultOptions
                        // isDisabled={profile.reviewAccountLink?true:false}
                        onChange={(item) => {onChange(item); onReviewAccountLinkChange(item)}}
                        value={value ? value : false}
                        {...rest}
                      />
                    )}
                  />
                  <p className="text-danger">{errors.reviewAccountLink?.message}</p>
                </FormGroup>
              </Col>
            </Row>
          )}
          
          <div className="">
            <Button
              className="btn-submit"
              data-test="btn-submit"
              size="md"
              disabled={pristine || isWaiting}
              onClick={handleSubmit(onSubmit)}
            >
              Submit
              {isWaiting && (
                <Spinner color="white" size="sm" className="ml-2" />
              )}
            </Button>
          </div>
        </form>
      </CardBody>
    </Card>
  );
};

ProfileForm.propTypes = propTypes;
ProfileForm.defaultProps = defaultProps;

export default ProfileForm;
