import {
  createReducer,
  createActionResources,
  createActionCRUDResources,
} from "redux-waiters";
import { RESET_REDUCER } from "../constants";

export const subscriptionActions = createActionCRUDResources("subscriptions");
export const searchSubscriptionAction = createActionResources("subscriptions/search");
export const syncWithKillbillAction = createActionResources("subscriptions/syncWithKillbill");
export const cancelSubscriptionAction = createActionResources("subscriptions/cancelSubscription");

export const searchMonthlyRevenueActions = createActionResources("subscriptions/monthlyRevenues/search");
export const recalculateMonthlyRevenuesAction = createActionResources("subscriptions/monthlyRevenues/aggregate");
export const transferRevenueAction = createActionResources("subscriptions/monthlyRevenues/transferRevenue");
export const transferMonthlyRevenuesAction = createActionResources("subscriptions/monthlyRevenues/transferMonthlyRevenues");
export const searchFundsTransfersAction = createActionResources("subscriptions/fundsTransfers/search");

export const searchRevenueRecordsAction = createActionResources("reports/revenues");

export const searchConnectedAccountsAction = createActionResources("subscriptions/connectedAccounts/search");
export const refreshConnectedAccountAction = createActionResources("subscriptions/connectedAccounts/refresh");
export const removeConnectedAccountAction = createActionResources("subscriptions/connectedAccounts/remove");
export const sendConnectedAccountSetupRequestAction = createActionResources("subscriptions/connectedAccounts/sendSetupRequest");

export const getCatalogsAction = createActionResources("subscriptions/catalogs/get");
export const submitJsonCatalogAction = createActionResources("subscriptions/catalogs/submitJsonCatalog");

export const getStoragePackagesAction = createActionResources("subscriptions/storagePackages/get");
export const createStoragePackageAction = createActionResources("subscriptions/storagePackages/create");
export const updateStoragePackageAction = createActionResources("subscriptions/storagePackages/update");

export const getProductsAction = createActionResources("subscriptions/products/get");
export const createProductAction = createActionResources("subscriptions/products/create");
export const updateProductAction = createActionResources("subscriptions/products/update");
export const createProductPlanAction = createActionResources("subscriptions/products/createProductPlan");
export const updateProductPlanAction = createActionResources("subscriptions/products/updateProductPlan");

export const getPlanOptionTypesAction = createActionResources("subscriptions/optionTypes/get");
export const createPlanOptionTypeAction = createActionResources("subscriptions/optionTypes/create");
export const updatePlanOptionTypeAction = createActionResources("subscriptions/optionTypes/update");

const initialState = {
  fetching: false,
  list: [],
  total: 0,
  totalPages: 0,
  pageNumber: 1,
  error: {},
};

export default createReducer(
  {
    [subscriptionActions.fetch.start]: state => {
      return { ...state, fetching: true };
    },
    [subscriptionActions.fetch.success]: (state, action) => {
      return {
        ...state,
        list: action.data?.content,
        pageNumber: action.data?.pageNumber + 1,
        total: action.data?.total,
        totalPages: Math.ceil(action.data?.total / action.data?.pageSize),
        fetching: false,
      };
    },
    [subscriptionActions.fetch.error]: state => {
      return { ...state, fetching: false };
    },

    [RESET_REDUCER]: () => initialState,
  },
  initialState
);
