import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from "reactstrap";
import PropTypes from "prop-types";
import moment from "moment";

import { retranscodeAction } from "../../redux/reducers/toolReducer";
import { startActionWithPromise } from "../../helpers/saga-promise-helpers";
import Avatar from "../../components/Avatar";
import {
  SCOPES_COPY,
  CATEGORY_COPY,
} from "./constants";

const propTypes = {
  modal: PropTypes.bool,
  toggle: PropTypes.func,
};

const defaultProps = {
  modal: false,
  toggle: () => {},
};

const VideoRetranscode = props => {
  const dispatch = useDispatch();
  const { modal, toggle, row: video } = props;
  const [waiting, setWaiting] = useState(false);

  const toggleModal = () => {
    if (toggle) toggle('modalRetranscode');
  };

  const successCallback = () => {
    setWaiting(false);
    toggleModal();
  };

  const failedCallback = () => {
    setWaiting(false);
  };

  const handleSubmit = async () => {
    setWaiting(true);
    try {
      await startActionWithPromise(
        retranscodeAction,
        {
          id: video.uid,
          successCallback,
          failedCallback,
        },
        dispatch
      );
    } catch {}
    setWaiting(false);
  };

  return (
    <Modal isOpen={modal} toggle={toggleModal} centered size="md">
      <ModalHeader toggle={toggleModal}>
        Re-transcode Video
      </ModalHeader>
      <ModalBody className="m-3">
        
        <Row>
          <Col lg="12">
            <p className="font-weight-bold mb-1">Following video will be re-transcode: </p>
            { video && (
              <div className="request-detail p-2 border bg-light rounded">
                <div className="" style={{whiteSpace: 'pre-wrap', wordBreak: 'break-word'}}>
                  <p className="mb-0">Title: <span>{video?.title}</span></p>
                  <p className="mb-0">Subject: <span>{video?.subject}</span></p>
                  <p className="mb-0">Topic: <span>{video?.topic}</span></p>
                  <p className="mb-0">Version: <span>{video?.version}</span></p>
                  <div className="mb-0 d-flex">
                    <div className="info-label mr-2 align-self-center">Author:</div>
                    <Avatar className="mr-1 align-self-center" image={video?.avatar} fullname={video?.author} width="25" height="25" />
                    <span className="align-self-center">{video.author}</span>
                  </div>
                  {video.videoUser && (
                    <div className="mb-0 d-flex">
                      <div className="info-label mr-2 align-self-center">Video user:</div>
                      <Avatar className="mr-1 align-self-center" image={video.videoUser?.avatar?.image} fullname={video.videoUser?.fullName} width="25" height="25" />
                      <span className="align-self-center">{video.videoUser?.fullName}</span>
                    </div>
                  )}
                  <div className="mb-0">Visibility: <span>{SCOPES_COPY[video.visibilityScope]}</span></div>
                  <div className="mb-0">Categories: <span>{video.categories ? video.categories.map(x => CATEGORY_COPY[x]).join(', ') : ''}</span></div>
                  <p className="mb-0">Created at: <span>{video.createdDate ? moment(video.createdDate).utc().format('YYYY/MM/DD HH:mm:ss') : ''}</span></p>
                </div>
              </div>
            )}
            <p className="font-weight-bold mt-2">Do you want to continue?</p>
          </Col>
        </Row>
        
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" disabled={waiting} onClick={toggleModal}>
          Cancel
        </Button>
        <Button
          className="ml-2"
          color="primary"
          // disabled={pristine || waiting}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};

VideoRetranscode.propTypes = propTypes;
VideoRetranscode.defaultProps = defaultProps;

export default VideoRetranscode;
