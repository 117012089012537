import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
} from "reactstrap";
import _ from "lodash";
import PropTypes from "prop-types";

import { updateStoragePackageAction, createStoragePackageAction } from "../../redux/reducers/subscriptionReducer";
import { startActionWithPromise } from "../../helpers/saga-promise-helpers";
import useMergeState from "../../hooks/mergeState";

import Selectbox from '../../components/Selectbox/Selectbox';

const propTypes = {
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  refreshData: PropTypes.func,
};

const defaultProps = {
  modal: false,
  toggle: () => {},
  refreshData: () => {},
};

const StoragePackageModal = props => {
  const dispatch = useDispatch();

  const { modal, toggle, refreshData, row, updateMode, isBoAdmin } = props;

  const fetching = false; // useSelector(state => state.user?.fetching);
  const [waiting, setWaiting] = useState(false);

  const packageTypeOptions = [
    {
      label: 'DEFAULT',
      value: 'DEFAULT',
    },
    {
      label: 'CUSTOM',
      value: 'CUSTOM',
    },
    {
      label: 'UNLIMITED',
      value: 'UNLIMITED',
    }
  ];

  const unitOptions = [
    {
      label: 'GB',
      value: 'GB',
    },
  ];

  const [formState, setFormState] = useMergeState({
    id: row ? row.id : null,
    name: row && row.name ? row.name : '',
    description: row && row.description ? row.description : '',
    size: row && row.size ? row.size : '',
    unit: row && row.unit ? row.unit : '',
    packageType: row && row.packageType ? row.packageType : '',
    enabled: row && row.enabled !== undefined ? row.enabled : false
  });

  const [errorMessage, setErrorMessage] = useState('');

  const handleInputChange = (e) => {
    let inputValue = e.target.type === 'number' ? parseInt(e.target.value) : e.target.value;
    setFormState({[e.target.name]: inputValue});
  }

  const handleSelectboxChange = (selectedOption, actionMeta) => {
    setFormState({[actionMeta.name]: selectedOption.value});
  }

  const addSuccessCallback = () => {
    if (refreshData) refreshData();
    toggle("modalStoragePackage");
  };

  const handleSubmit = async () => {
    setErrorMessage('');

    if (!formState.name) {
      setErrorMessage('Please enter Package Name');
      return;
    }

    if (!formState.size ) {
      setErrorMessage('Please enter Size');
      return;
    }

    if (!formState.description ) {
      setErrorMessage('Please enter Description');
      return;
    }

    if (!formState.unit ) {
      setErrorMessage('Please select Unit');
      return;
    }

    setWaiting(true);
    try {
      await startActionWithPromise(
        updateMode? updateStoragePackageAction : createStoragePackageAction,
        {
          params: formState,
          successCallback: addSuccessCallback,
          failedCallback: () => {},
        },
        dispatch
      );
    } catch {}
  };

  return (
    <Modal
      isOpen={modal}
      toggle={() => toggle("modalStoragePackage")}
      centered
      size="lg"
      className="add-trusted-modal add-featured-video-modal"
    >
      <ModalHeader>{ updateMode? 'Update' : 'Create' } Storage Package</ModalHeader>
      <ModalBody className="pt-0 pb-0">
        <Card className="w-100 mb-0">
          <CardHeader className="">
            
          </CardHeader>
          <CardBody className="pt-0 pb-0 data-list position-relative">
            <Row>
              <Col lg="6">
                <FormGroup>
                  <Label for="name" className="">
                    Pakcage Name <font color="red">*</font>
                  </Label>
                  <input
                    className="form-control"
                    name="name"
                    id="name"
                    type="text"
                    value={formState.name}
                    onChange={handleInputChange}
                    maxLength={255}
                  />
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <Label for="packageType" className="">
                    Package Type <font color="red">*</font>
                  </Label>
                  <input
                    className="form-control"
                    name="packageType"
                    id="packageType"
                    type="text"
                    value={formState.packageType}
                    maxLength={255}
                    readOnly={true}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <FormGroup>
                  <Label for="size" className="">
                    Size <font color="red">*</font>
                  </Label>
                  <input
                    className="form-control"
                    name="size"
                    id="size"
                    type="number"
                    value={formState.size}
                    onChange={handleInputChange}
                    maxLength={255}
                    width="30px"
                  />
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <Label for="unit" className="">
                    Unit <font color="red">*</font>
                  </Label>
                  <Selectbox
                    name="unit"
                    placeholder="Select..."
                    options={unitOptions}
                    onChange={handleSelectboxChange}
                    defaultValue={unitOptions.find(it => it.value === formState.unit)}
                    isClearable={false}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg="6">
                <FormGroup>
                  <Label for="description" className="">
                    Description <font color="red">*</font>
                  </Label>
                  <input
                    className="form-control"
                    name="description"
                    id="description"
                    type="text"
                    value={formState.description}
                    onChange={handleInputChange}
                    maxLength={255}
                  />
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <div>
                    <FormGroup inline check>
                      <Label>
                        <input
                          className="form-check-input"
                          name="enabled"
                          id="enabled"
                          type="checkbox"
                          value={formState.enabled}
                          checked={formState.enabled}
                          onChange={() => setFormState({enabled: !formState.enabled})}
                          disabled={formState.packageType === 'DEFAULT'}
                        />
                        <span>Enabled</span>
                      </Label>
                    </FormGroup>
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </ModalBody>
      <ModalFooter>
        {errorMessage && (
          <div className="float-left text-danger">
            {errorMessage}
          </div>
        )}
        <Button
          color="secondary"
          disabled={!isBoAdmin || fetching}
          onClick={() => toggle("modalStoragePackage")}
        >
          Cancel
        </Button>
        <Button
          className="ml-2"
          color="primary"
          disabled={waiting}
          onClick={handleSubmit}
        >
          { updateMode? 'Update' : 'Create' }
        </Button>
      </ModalFooter>
    </Modal>
  );
};

StoragePackageModal.propTypes = propTypes;
StoragePackageModal.defaultProps = defaultProps;

export default StoragePackageModal;
