import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Spinner
} from "reactstrap";
import moment from "moment";
import classNames from "classnames";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";

import DataTable from "../../components/DataTable";
import PageSizeSelector from "../../components/base/PageSizeSelector";
import Paging from "../../components/base/Paging";
import config from "../../config/config";
import useMergeState from '../../hooks/mergeState';
import { useAuth } from "../../hooks/useAuth";

import FormFilter from "./FormFilter";
import ConfirmModal from "../../components/base/ConfirmModal";

import { searchRevenueRecordsAction } from "../../redux/reducers/subscriptionReducer";
import { startActionWithPromise } from "../../helpers/saga-promise-helpers";
import "./index.scss";
import { getTableColumns, rowActionTypes } from "./TableColumns";

const RevenueRecords = ({ user }) => {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    modalConfirm: false,
    action: '',
    message: '',
    row: null,
  });

  const startDate = moment().subtract(30, 'days').format('MM/DD/YYYY');
  const endDate = moment().format('MM/DD/YYYY');

  const defaultFilters = {
    pageSize: config.DEFAULT_PAGESIZE,
    pageNumber: 1,
    startDate: startDate,
    endDate: endDate,
    sellerId: user ? user.userId : '',
    sort: {
      dir: `desc`,
      fieldName: `purchasedDate`,
    },
    reload: false,
  };
  const [filters, setFilters] = useMergeState(defaultFilters);

  const [waiting, setWaiting] = useState(false);

  const auth = useAuth();
  const isAuthorized = auth.roles &&
    (auth.roles.indexOf('SYSTEM_ADMINS') !== -1 ||
      auth.roles.indexOf('SYSTEM_OPERATORS') !== -1);
  const fetching = false;
  const [revenues, setRevenues] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const isBoAdmin = useSelector(state => state.bouser.isBoAdmin);

  const numItems = totalItems > filters.pageSize ? filters.pageSize : totalItems;
  const beginItem = numItems * (filters.pageNumber - 1);
  const endItem = numItems * filters.pageNumber;

  const onSizeChange = (size) => {
    setFilters({ ...filters, pageSize: size, pageNumber: 1, reload: true });
  };

  const onPageChange = (p) => {
    setFilters({ ...filters, pageNumber: p, reload: true });
  };

  const handleColumnSort = (field, order) => {
    setFilters({
      ...filters,
      sort: {
        dir: order,
        fieldName: field,
      },
      reload: true,
    });
  };

  const tableColumns = getTableColumns(handleColumnSort, isBoAdmin, state, setState);

  const toggleModal = (modal) => {
    if (state[modal]) setState({ ...state, [modal]: !state[modal] });
    else setState({ ...state, [modal]: !state[modal], row: null });
  };

  const refreshDatatable = () => {
    setFilters({ ...filters, reload: true });
  };

  const fetchRevenueRecords = () => {
    (async () => {
      try {
        // setWaiting(true);
        let params = {
          ...filters,
          pageNumber: filters.pageNumber - 1,
          sort: {
            dir: filters.sort.dir.toUpperCase(),
            fieldName: filters.sort.fieldName,
          },
        };
        // startDate and endDate are texts, don't parse them anymore

        delete params.reload;

        if (!filters.sellerId) delete params.sellerId;

        const resp = await startActionWithPromise(
          searchRevenueRecordsAction,
          { params, successCallback: () => { }, failedCallback: () => { } },
          dispatch
        );
        setRevenues(resp.content);
        setTotalItems(resp.total);
        setTotalPages(Math.ceil(resp.total / resp.pageSize) || 0);
        setPageNumber(resp.pageNumber + 1 || 1);
      } catch { }
      setWaiting(false);
      // setFilters({ ...filters, reload: false });
    })();
  };

  useEffect(() => { // state change
    if (filters.reload) {
      fetchRevenueRecords();
      setFilters({ ...filters, reload: false });
    }
  }, [filters.reload]);

  useEffect(() => { // Onload
    console.log("First load");
    fetchRevenueRecords();
  }, []);

  ////////// Table's action handlers ///////////

  const onActionConfirm = () => {
    switch (state.action) {
      case rowActionTypes.TRANSFER_REVENUE:
        //transferRevenue(state.row);
        break;
      case rowActionTypes.AGGREGATE_MONTHLY_REVENUES:
        //aggregateMonthlyRevenues(state.aggregateYearMonth);
        break;
      default:
    }
  };

  ////////////////////

  return (
    <Container fluid className="p-0">
      <h1 className="page-title">Revenue Records</h1>
      <Row>
        <Col lg="12" className="d-flex">
          <>
            <Card className={classNames('w-100 mb-0 datatable-wrap')}>
              <CardHeader className="">
                <FormFilter
                  //loading={waiting}
                  //filters={filters}
                  paging={{
                    beginItem,
                    endItem,
                    totalItems
                  }}
                  mergeFilters={setFilters}
                  user={user}
                  initStart={startDate}
                  initEnd={endDate}
                />
              </CardHeader>
              <CardBody className="pt-0 data-list">
                {waiting ? (
                  <div className="loading-content text-center p-4 border-top">
                    <Spinner size="sm" />
                  </div>
                ) : (
                  <>
                    <DataTable
                      keyField="orderId"
                      data={revenues}
                      columns={tableColumns}
                      order={filters.sort?.dir}
                      sort={filters.sort?.fieldName}
                      hideSelectColumn={true}
                    />
                    <Row className="mt-3">
                      <Col lg="3" md="4" className="d-flex">
                        <PageSizeSelector size={filters.pageSize} onChange={onSizeChange} />
                      </Col>
                      <Col lg="9" md="8" className="d-flex justify-content-end">
                        <Paging totalPages={totalPages} current={pageNumber} show={5} onSelect={onPageChange} />
                      </Col>
                    </Row>
                  </>
                )}
              </CardBody>
            </Card>
            <ConfirmModal
              modal={state.modalConfirm}
              toggle={toggleModal}
              row={state.row}
              onConfirm={onActionConfirm}
              on
              message={state.message}
            />
          </>
        </Col>
      </Row>
    </Container>
  );
};

export default RevenueRecords;
