import React, { useState } from "react";
import {
  Card,
  CardBody,
  Media,
  Spinner,
  Row,
  Col
} from "reactstrap";
import { ShoppingCart, Activity, DollarSign, ShoppingBag, Users, Video } from "react-feather";

import { formatNumber } from "../../helpers/utils";

const OverviewTab = ({statData={}}) => {
  let [waiting, setWaiting] = useState(false);
  return (
    <Row className="overview-tab-content">
      <Col md="6" xl>
        <Card className="flex-fill border">
          <CardBody className="py-4">
            <Media style={{height: '54px'}}>
              <div className="d-inline-block mt-2 mr-3">
                <DollarSign className="feather-lg text-primary" />
              </div>
              <Media body>
                {waiting ? (
                  <div className="loading mb-2"><Spinner size="sm" /></div>
                  ) : (
                    <h3 className="mb-2">{formatNumber(statData.revenueToday || 0)}</h3>
                  )
                }
                <div className="mb-0">Total income</div>
              </Media>
            </Media>
          </CardBody>
        </Card>
      </Col>
      <Col md="6" xl>
        <Card className="flex-fill border">
          <CardBody className="py-4">
            <Media style={{height: '54px'}}>
              <div className="d-inline-block mt-2 mr-3">
                <ShoppingBag className="feather-lg text-primary" />
              </div>
              <Media body>
                {waiting ? (
                  <div className="loading mb-2"><Spinner size="sm" /></div>
                  ) : (
                    <h3 className="mb-2">{formatNumber(statData.revenueToday || 0)}</h3>
                  )
                }
                <div className="mb-0">Total orders</div>
              </Media>
            </Media>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default OverviewTab;