import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  FormGroup,
  Label,
} from "reactstrap";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";

import { settingsAction } from "../../redux/reducers/settingReducer";
import { startActionWithPromise } from "../../helpers/saga-promise-helpers";
import { ucFirst, getMimeTypeFromBuffer } from "../../helpers/utils";

const propTypes = {
  row: PropTypes.object,
  modal: PropTypes.bool,
  toggle: PropTypes.func,
};

const defaultProps = {
  row: null,
  modal: false,
  toggle: () => {},
};

const FIELD_TYPES = {
  TEXT: "TEXT",
  NUMBER: "NUMBER",
  STRING: "STRING",
  BINARY: "BINARY",
};

const convertToBase64 = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

const convertToByteArray = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsArrayBuffer(file);
  reader.onload = () => {
    var arrayBuffer = reader.result;
    var bytes = new Uint8Array(arrayBuffer);
    resolve(bytes);
  }
  reader.onerror = error => reject(error);
});

const SettingUpdate = props => {
  const dispatch = useDispatch();
  const submitButton = useRef(null);

  const { modal, toggle, row, refreshData } = props;

  const { register, handleSubmit, errors } = useForm();

  const services = useSelector(state => state.setting.services) || [];
  const types = useSelector(state => state.setting.types) || [];

  const [pristine, setPristine] = useState(true);
  const [messages, setMessages] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [selectedType, setSelectedType] = useState(
    row ? row.settingType : FIELD_TYPES.STRING
  );

  const toggleModal = () => {
    if (toggle) toggle("modalUpdate");
  };

  const triggerSubmitForm = () => {
    if (submitButton.current) submitButton.current.click();
  };

  const successCallback = () => {
    setWaiting(false);
    if (refreshData) refreshData();
    toggleModal();
  };

  const onSubmit = async data => {
    let settingValue = data.settingValue;
    if (data.settingType === FIELD_TYPES.BINARY) {
      try {
        settingValue = await convertToByteArray(data.settingValue[0]);
      } catch {}
    }

    const values = {
      id: row ? row.id : 0,
      settingType: row ? row.settingType : data.settingType,
      serviceName: row ? row.serviceName : data.serviceName,
      settingGroup: row ? row.settingGroup : data.settingGroup,
      settingName: row ? row.settingName : data.settingName,
      enabled: data.enabled,
    };

    let valueField = `${values.settingType.toLowerCase()}Value`;
    let postData = {...values, [valueField]: settingValue};

    setWaiting(true);
    try {
      if (row) {
        await startActionWithPromise(
          settingsAction.update,
          {
            id: row.id,
            data: postData,
            successCallback,
            failedCallback: () => {},
          },
          dispatch
        );
      } else {
        delete postData.id;
        await startActionWithPromise(
          settingsAction.create,
          { data: postData, successCallback, failedCallback: () => {}},
          dispatch
        );
      }
    } catch {}
    setWaiting(false);
  };

  const handleTypeChange = (e) => {
    setPristine(false);
    setSelectedType(e.target.value);
  };

  const renderStringInput = () => {
    return (
      <>
        <input
          type="text"
          className={`form-control${
            errors.settingValue ? " is-invalid" : ""
          }`}
          id="settingValue"
          name="settingValue"
          placeholder=""
          defaultValue={row ? row.stringValue : ""}
          onChange={() => setPristine(false)}
          ref={register({ required: "Setting value is required." })}
        />
        {errors.settingValue && ( <p className="text-danger">{errors.settingValue?.message}</p> )}
      </>
    )
  }

  const renderNumberInput = () => {
    return (
      <>
        <input
          type="number"
          className={`form-control${
            errors.settingValue ? " is-invalid" : ""
          }`}
          id="settingValue"
          name="settingValue"
          placeholder=""
          defaultValue={row ? row.numberValue : ""}
          onChange={() => setPristine(false)}
          ref={register({ required: "Setting value is required." })}
        />
        {errors.settingValue && ( <p className="text-danger">{errors.settingValue?.message}</p> )}
      </>
    )
  }

  const renderTextInput = () => {
    return (
      <>
        <textarea
          className={`form-control${
            errors.settingValue ? " is-invalid" : ""
          }`}
          id="settingValue"
          name="settingValue"
          placeholder=""
          rows="12"
          defaultValue={row ? row.textValue : ""}
          onChange={() => setPristine(false)}
          ref={register({ required: "Setting value is required." })}
        />
        {errors.settingValue && ( <p className="text-danger">{errors.settingValue?.message}</p> )}
      </>
    )
  }

  const renderBinaryInput = () => {
    return (
      <>
        <input
          type="file"
          className={`form-control-file${
            errors.settingValue ? " is-invalid" : ""
          }`}
          id="settingValue"
          name="settingValue"
          placeholder=""
          onChange={() => setPristine(false)}
          ref={register({ required: "Setting value is required." })}
        />
        {errors.settingValue && ( <p className="text-danger">{errors.settingValue?.message}</p> )}
      </>
    )
  }

  useEffect(() => {
    if (row) setSelectedType(row.settingType);
    else setSelectedType(FIELD_TYPES.STRING);
  }, [row]);

  return (
    <Modal isOpen={modal} toggle={toggleModal} centered size="md">
      <ModalHeader toggle={toggleModal}>
        {props.row?.id ? "Change Setting" : "Create Setting"}
      </ModalHeader>
      <ModalBody className="m-0">
        <form onSubmit={handleSubmit(onSubmit)} id="form-bouser">
          <Row className="">
            <Col lg="12">
              <FormGroup>
                <FormGroup check inline className="">
                  <Label check>
                    <input
                      className="form-check-input"
                      name="enabled"
                      type="checkbox"
                      defaultChecked={row?row.enabled:true}
                      ref={register}
                      onChange={() => setPristine(false)}
                    />
                    Enabled
                  </Label>
                </FormGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <FormGroup>
                <Label for="settingType" className="">
                  Type
                </Label>
                <select
                  className="form-control"
                  name="settingType"
                  id="settingType"
                  disabled={row ? true : false}
                  ref={register({ required: "Setting type is required." })}
                  defaultValue={selectedType}
                  onChange={handleTypeChange}
                >
                  {types &&
                    types.map((item, i) => (
                      <option
                        key={`type-opt-${item.toLowerCase()}-${i}`}
                        value={item}
                      >
                        {ucFirst(item)}
                      </option>
                    ))}
                </select>
                <p className="text-danger">{errors.username?.message}</p>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <FormGroup>
                <Label for="settingName" className="">
                  Name
                </Label>
                <input
                  className={`form-control${
                    errors.settingName ? " is-invalid" : ""
                  }`}
                  type="text"
                  id="settingName"
                  name="settingName"
                  placeholder=""
                  disabled={row ? true : false}
                  defaultValue={row ? row.settingName : ""}
                  onChange={() => setPristine(false)}
                  ref={register({ required: "Setting name is required." })}
                />
                <p className="text-danger">{errors.settingName?.message}</p>
              </FormGroup>
            </Col>
          </Row>
          
          <Row>
            <Col lg="12">
              <FormGroup>
                <Label for="serviceName" className="">
                  Service
                </Label>
                <select
                  className="form-control"
                  name="serviceName"
                  id="serviceName"
                  ref={register}
                  disabled={row ? true : false}
                  defaultValue={row ? row.serviceName : ""}
                  onChange={() => setPristine(false)}
                >
                  <option value=""></option>
                  {services &&
                    services.map((item, i) => (
                      <option
                        key={`service-opt-${item.toLowerCase()}-${i}`}
                        value={item}
                      >
                        {ucFirst(item)}
                      </option>
                    ))}
                </select>
                <p className="text-danger">{errors.username?.message}</p>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <FormGroup>
                <Label for="settingGroup" className="">
                  Group
                </Label>
                <input
                  type="text"
                  className={`form-control${
                    errors.settingGroup ? " is-invalid" : ""
                  }`}
                  name="settingGroup"
                  id="settingGroup"
                  placeholder=""
                  disabled={row ? true : false}
                  defaultValue={row ? row.settingGroup : ""}
                  onChange={() => setPristine(false)}
                  ref={register({
                    required: "Group is required.",
                  })}
                />
                <p className="text-danger">{errors.settingGroup?.message}</p>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <FormGroup className="mb-0">
                <Label for="settingValue" className="">
                  Value
                </Label>
                {selectedType === FIELD_TYPES.STRING && renderStringInput()}
                {selectedType === FIELD_TYPES.NUMBER && renderNumberInput()}
                {selectedType === FIELD_TYPES.TEXT && renderTextInput()}
                {selectedType === FIELD_TYPES.BINARY && renderBinaryInput()}
              </FormGroup>
            </Col>
          </Row>

          <Button
            className="d-none"
            disabled={pristine}
            type="submit"
            innerRef={submitButton}
          >
            Submit
          </Button>
        </form>
      </ModalBody>
      <ModalFooter>
        <div className="">
          {messages &&
            messages.map((error, i) => (
              <span key={`errors-${i}`} className="text-danger mb-0 mr-1">
                {error}
              </span>
            ))}
        </div>
        <Button color="secondary" disabled={waiting} onClick={toggleModal}>
          Cancel
        </Button>
        <Button
          className="ml-2"
          color="primary"
          disabled={pristine || waiting}
          onClick={() => triggerSubmitForm(true)}
        >
          Save changes
        </Button>
      </ModalFooter>
    </Modal>
  );
};

SettingUpdate.propTypes = propTypes;
SettingUpdate.defaultProps = defaultProps;

export default SettingUpdate;
