import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from "reactstrap";
import PropTypes from "prop-types";
import moment from "moment";

import { enableAction, disableAction } from "../../redux/reducers/videoFeaturedReducer";
import { startActionWithPromise } from "../../helpers/saga-promise-helpers";

const propTypes = {
  modal: PropTypes.bool,
  toggle: PropTypes.func,
};

const defaultProps = {
  modal: false,
  toggle: () => {},
};

const VideoFeaturedStatus = props => {
  const dispatch = useDispatch();
  const { modal, toggle, row: video, refreshData } = props;
  const [waiting, setWaiting] = useState(false);

  const toggleModal = () => {
    if (toggle) toggle('modalToggleStatus');
  };

  const successCallback = () => {
    setWaiting(false);
    if (refreshData) refreshData();
    toggleModal();
  };

  const failedCallback = () => {
    setWaiting(false);
  };

  const handleSubmit = async () => {
    setWaiting(true);
    try {
      await startActionWithPromise(
        video.enabled ? disableAction : enableAction,
        {
          videoId: video.uid,
          successCallback,
          failedCallback,
        },
        dispatch
      );
    } catch {}
    setWaiting(false);
  };

  return (
    <Modal isOpen={modal} toggle={toggleModal} centered size="md">
      <ModalHeader toggle={toggleModal}>
      {video.enabled ? 'Disable' : 'Enable'} featured video
      </ModalHeader>
      <ModalBody className="m-3">
        
        <Row>
          <Col lg="12">
            <p className="font-weight-bold mb-1">Following video will be {video.enabled ? 'disable' : 'enable'}: </p>
            { video && (
              <div className="request-detail p-2 border bg-light rounded mt-1">
                <div className="" style={{whiteSpace: 'pre-wrap', wordBreak: 'break-word'}}>
                  <p className="mb-0">Title: <span>{video?.title}</span></p>
                  <p className="mb-0">Subject: <span>{video?.subject}</span></p>
                  <p className="mb-0">Topic: <span>{video?.topic}</span></p>
                  <div className="mb-0 d-flex">
                    <div className="info-label mr-2 align-self-center">Author:</div>
                    <span className="align-self-center">{video.ownerName}</span> - <span className="align-self-center">{video.ownerEmail}</span>
                  </div>
                  <div className="mb-0">Ranking: <span>{video.rankingLevel}</span></div>
                  <div className="mb-0">Markets: <span>{video.markets ? video.markets.join(', ') : ''}</span></div>
                  <p className="mb-0">Valid start: <span>{video.validStartDate ? moment(video.validStartDate).format('YYYY/MM/DD HH:mm:ss') : ''}</span></p>
                  <p className="mb-0">Valid through: <span>{video.validThruDate ? moment(video.validThruDate).format('YYYY/MM/DD HH:mm:ss') : ''}</span></p>
                </div>
              </div>
            )}
            <p className="font-weight-bold mt-2">Do you want to continue?</p>
          </Col>
        </Row>
        
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" disabled={waiting} onClick={toggleModal}>
          No
        </Button>
        <Button
          className="ml-2"
          color="primary"
          // disabled={pristine || waiting}
          onClick={handleSubmit}
        >
          Yes
        </Button>
      </ModalFooter>
    </Modal>
  );
};

VideoFeaturedStatus.propTypes = propTypes;
VideoFeaturedStatus.defaultProps = defaultProps;

export default VideoFeaturedStatus;
