import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Spinner,
  Badge,
} from "reactstrap";
import * as Icon from "react-feather";
import { useParams } from "react-router-dom";

import DataTable, { rowActionTypes } from "../../components/DataTable";
import PageSizeSelector from "../../components/base/PageSizeSelector";
import Paging from "../../components/base/Paging";
import InputSearch from "../../components/base/InputSearch";

import SettingUpdate from "./SettingUpdate";
import ConfirmModal from "../../components/base/ConfirmModal";
import {
  settingsAction,
  fetchTypes,
} from "../../redux/reducers/settingReducer";
import { startActionWithPromise } from "../../helpers/saga-promise-helpers";

const FIELD_TYPES = {
  TEXT: "TEXT",
  NUMBER: "NUMBER",
  STRING: "STRING",
  BINARY: "BINARY",
};

const SettingList = ({ serviceName }) => {
  const dispatch = useDispatch();

  var settings = useSelector(state => state.setting?.settings) || [];
  const fetching = false;
  const totalPages = useSelector(state => state.setting?.totalPages);
  const pageNumber = useSelector(state => state.setting?.pageNumber);

  const [state, setState] = useState({
    modalUpdate: false,
    modalConfirm: false,
    modalRoles: false,
    action: "",
    message: "",
    row: null,
  });

  const [filters, setFilters] = useState({
    pageSize: 100,
    pageNumber: 1,
    sort: "",
    sortReal: "",
    order: "",
    keyword: "",
    reload: false,
  });

  if (serviceName) {
    settings = settings.filter(setting => {
      if (serviceName === "global") {
        return (
          !setting.serviceName ||
          setting.serviceName.toLowerCase() === serviceName
        );
      } else {
        return setting.serviceName.toLowerCase() === serviceName;
      }
    });
  }

  const tableColumns = [
    {
      dataField: "settingName",
      text: "Name",
      sort: true,
      onSort: (field, order) => {
        onColumnSort(field, order);
      },
      sortFunc: () => {
        // for ignore default datable sort
      },
    },
    {
      dataField: "settingGroup",
      text: "Group",
      sort: true,
      onSort: (field, order) => {
        onColumnSort(field, order);
      },
      sortFunc: () => {
        // for ignore default datable sort
      },
    },
    {
      dataField: "stringValue",
      text: "Value",
      sort: true,
      onSort: (field, order) => {
        onColumnSort(field, order);
      },
      sortFunc: () => {
        // for ignore default datable sort
      },
      formatter: (cell, row, rowIndex, formatExtraData) => (
        <div className="text-2-line">
          {formatExtraData.types?.STRING === row.settingType ? row.stringValue : ""}
          {formatExtraData.types?.TEXT === row.settingType ? row.textValue : ""}
          {formatExtraData.types?.NUMBER === row.settingType ? row.numberValue : ""}
        </div>
      ),
      formatExtraData: {
        types: FIELD_TYPES
      }
    },
    {
      dataField: "enabled",
      text: "Status",
      sort: false,
      onSort: (field, order) => {
        onColumnSort(field, order);
      },
      sortFunc: () => {
        // for ignore default datable sort
      },
      formatter: (cell, row, rowIndex, formatExtraData) => (
        <div>
          <Badge
            color={row.enabled ? "success" : "secondary"}
            className="mr-1 mb-1"
          >
            {row.enabled ? "Enabled" : "Disabled"}
          </Badge>
        </div>
      ),
      headerStyle: (colum, colIndex) => {
        return { width: "100px" };
      },
    },
  ];

  const rowActions = [
    {
      name: "Edit",
      type: rowActionTypes.EDIT,
      classes: "text-dark",
    },
    {
      name: "Delete",
      type: rowActionTypes.DELETE,
      classes: "text-dark",
    },
  ];

  const onSelectedChange = () => {
    // console.log(selected);
  };

  const onSizeChange = size => {
    setFilters({ ...filters, pageSize: size, pageNumber: 1, reload: true });
  };

  const onPageChange = p => {
    setFilters({ ...filters, pageNumber: p, reload: true });
  };

  const onColumnSort = (field, order) => {
    // trigger sort data via api
    const realSort = field === "fullname" ? "firstName" : field;
    if (realSort === filters.sortReal && order === filters.order) return;

    setFilters({
      ...filters,
      sort: field,
      sortReal: realSort,
      order,
      reload: true,
    });
  };

  const onSearchSubmit = keyword => {
    // console.log(keyword);
    if (filters.keyword === keyword) return;
    setFilters({ ...filters, pageNumber: 1, keyword, reload: true });
  };

  const onActionButtonClick = (action, row) => {
    let message = "";
    switch (action.type) {
      case rowActionTypes.EDIT:
        setState({ ...state, modalUpdate: true, row });
        break;
      case rowActionTypes.DELETE:
        message = `<p class="font-weight-bold">The following setting will be deleted: </p>\
        <p class="font-italic">"${`${row.settingName}`}"</p>\
        <p class="font-weight-bold">Do you want to continue?</p>`;
        setState({
          ...state,
          modalConfirm: true,
          row,
          message,
          action: rowActionTypes.DELETE,
        });
        break;
      default:
    }
  };

  const refreshDatatable = () => {
    setFilters({ ...filters, reload: true });
  };

  const deleteSuccessCB = () => {
    // close confirm modal and reload the list
    setState({ ...state, modalConfirm: false });
    refreshDatatable();
  };

  const failedCallback = () => {
    // do nothing
  };

  const handleAdd = () => {
    setState({ ...state, modalUpdate: true, row: null });
  };

  const toggleModal = modal => {
    if (state[modal]) setState({ ...state, [modal]: !state[modal] });
    else setState({ ...state, [modal]: !state[modal], row: null });
  };

  const onActionConfirm = () => {
    switch (state.action) {
      case rowActionTypes.DELETE:
        deleteSetting();
        break;
      default:
    }
  };

  const deleteSetting = () => {
    if (!state.row?.id) return;

    (async () => {
      try {
        await startActionWithPromise(
          settingsAction.delete,
          {
            id: state.row?.id,
            successCallback: deleteSuccessCB,
            failedCallback,
          },
          dispatch
        );
      } catch { }
    })();
  };

  const fetchData = (params) => {
    (async () => {
      try {
        await startActionWithPromise(
          settingsAction.fetch,
          {
            params,
            successCallback: () => { },
            failedCallback: () => { },
          },
          dispatch
        );
      } catch { }
    })();
  };

  useEffect(() => {
    const params = {
      pageSize: filters.pageSize,
      pageNumber: filters.pageNumber - 1,
      keyword: filters.keyword,
      orderBy: `${filters.sortReal} ${filters.order.toUpperCase()}`,
      serviceName,
    };
    fetchData(params);
    setFilters({ ...filters, reload: false });
  }, [filters.reload, serviceName]);

  useEffect(() => {
    const params = {
      pageSize: filters.pageSize,
      pageNumber: filters.pageNumber - 1,
      serviceName,
    };
    fetchData(params);
    dispatch(fetchTypes.start());
    return () => setState({});
  }, []);

  return (
    <>
      <Card className="w-100 mb-0">
        <CardHeader>
          <Row>
            <Col lg="6" xs="6" className="d-flex">
              <div className="bulk-actions">
                <Button color="success" size="sm" onClick={handleAdd}>
                  <Icon.Plus height="18" />
                  Add New
                </Button>
                <Button color="danger" size="sm" className="ml-1 d-none">
                  <Icon.Trash2 height="18" />
                  Bulk Delete
                </Button>
              </div>
            </Col>
            <Col lg="6" xs="6" className="d-flex justify-content-end">
              <InputSearch
                className=""
                value={filters.keyword}
                onSubmit={onSearchSubmit}
                disabled={fetching}
              />
            </Col>
          </Row>
        </CardHeader>
        <CardBody className="pt-0 data-list">
          {fetching && (
            <div className="text-center w-100 bg-white p-4 position-static h-100">
              <Spinner />
            </div>
          )}
          {!fetching && (
            <>
              <DataTable
                keyField="id"
                data={settings}
                columns={tableColumns}
                sort={filters.sort}
                order={filters.order}
                actions={rowActions}
                onActionClick={onActionButtonClick}
                onSelectedChange={onSelectedChange}
              />
              <Row className="mt-3">
                <Col lg="6" className="d-flex">
                  <PageSizeSelector
                    size={filters.pageSize}
                    onChange={onSizeChange}
                  />
                </Col>
                <Col lg="6" className="d-flex justify-content-end">
                  <Paging
                    totalPages={totalPages}
                    current={pageNumber}
                    show={5}
                    onSelect={onPageChange}
                  />
                </Col>
              </Row>
            </>
          )}
        </CardBody>
      </Card>
      <SettingUpdate
        modal={state.modalUpdate}
        toggle={toggleModal}
        row={state.row}
        refreshData={refreshDatatable}
      />
      <ConfirmModal
        modal={state.modalConfirm}
        toggle={toggleModal}
        row={state.row}
        message={state.message}
        onConfirm={onActionConfirm}
      />
    </>
  );
};

export default SettingList;
