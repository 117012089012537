// app config

const GLOB_DFLT_PAGE_SIZE = 100;

const dev = {
  endpoints: {
    URL: "http://dev.mm-services.internal.fuvi.vn/backoffice/api/v0/",    
    AUTH_PATH: "authentication",
  },

  DEFAULT_PAGESIZE: GLOB_DFLT_PAGE_SIZE,
  NO_LIMIT_PAGESIZE: 1000,
  AVATAR_PATH: "http://dev.mindmastery.internal.fuvi.vn/avatar/",
  AVATAR_DEFAULT: "avatar.jpg",
  SESSION_TIMEOUT: 10, // minutes
  WEB_URL: 'http://dev.mindmastery.internal.fuvi.vn/review'
};

const int = {
  endpoints: {
    URL: "http://int.mm-services.internal.fuvi.vn/backoffice/api/v0/",
    AUTH_PATH: "authentication",
  },

  DEFAULT_PAGESIZE: GLOB_DFLT_PAGE_SIZE,
  NO_LIMIT_PAGESIZE: 1000,
  AVATAR_PATH: "http://int.mindmastery.internal.fuvi.vn/avatar/",
  AVATAR_DEFAULT: "avatar.jpg",
  SESSION_TIMEOUT: 10,
  WEB_URL: 'http://int.mindmastery.internal.fuvi.vn/review'
};

const stg = {
  endpoints: {
    URL: "https://mm-services.fuviworld.com/backoffice/api/v0/",
    AUTH_PATH: "authentication",
  },

  DEFAULT_PAGESIZE: GLOB_DFLT_PAGE_SIZE,
  NO_LIMIT_PAGESIZE: 1000,
  AVATAR_PATH: "https://mindmastery.fuviworld.com/avatar/",
  AVATAR_DEFAULT: "avatar.jpg",
  SESSION_TIMEOUT: 10,
  WEB_URL: 'https://mindmastery.fuviworld.com/review'
};

const prod = {
  endpoints: {
    URL: "https://services.ifuvi.com/backoffice/api/v0/",
    AUTH_PATH: "authentication",
  },

  DEFAULT_PAGESIZE: GLOB_DFLT_PAGE_SIZE,
  NO_LIMIT_PAGESIZE: 1000,
  AVATAR_PATH: "https://www.ifuvi.com/avatar/",
  AVATAR_DEFAULT: "avatar.jpg",
  SESSION_TIMEOUT: 10,
  WEB_URL: 'https://www.ifuvi.com/review'
};

const config = process.env.REACT_APP_STAGE === "production" ? prod : process.env.REACT_APP_STAGE === "staging" ? stg : process.env.REACT_APP_STAGE === "integration" ? int : dev;

export default {
  ...config,
  VERSION_INTERVAL: 600000
};
