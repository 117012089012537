import { takeLatest, call, put } from "redux-saga/effects";

import auditLogService from "../../services/auditLogService";
import { alertTypes } from "../constants";
import {
  auditLogActions
} from "../reducers/auditLogReducer";
import { sagaPromise } from "../../helpers/saga-promise-helpers";

function* fetchWorker(action) {
  try {
    const { data, error } = yield call(
      auditLogService.fetch,
      action.payload?.params
    );
    if (error) {
      yield put(auditLogActions.fetch.error(error));
    } else {
      yield put(auditLogActions.fetch.success({ ...action, data }));
    }
  } catch (err) {
    console.log(err);
    yield put(auditLogActions.fetch.error(err));
  }
}

function* deleteLogWorker(action) {
  const { data, error } = yield call(
    auditLogService.deleteLog,
    action.payload.id,
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      yield put({
        type: alertTypes.ERROR,
        message: error.headers["x-message-info"],
      });
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    return data;
  }

  return false;
}

function* auditLogSaga() {
  // yield takeLatest(searchVideoAction.start, searchWorker);

  yield takeLatest(
    auditLogActions.fetch.start,
    auditLogActions.fetch.waiterActionForSaga(sagaPromise(fetchWorker))
  );

  yield takeLatest(
    auditLogActions.delete.start,
    auditLogActions.delete.waiterActionForSaga(sagaPromise(deleteLogWorker))
  );
}

export default auditLogSaga;
