import { takeLatest, call, put, delay } from "redux-saga/effects";

import videoServices from "../../services/videoService";
import { alertTypes } from "../constants";
import {
  searchVideoAction,
  deleteVideoAction,
  searchVideo2Action
} from "../reducers/videoReducer";
import { sagaPromise } from "../../helpers/saga-promise-helpers";
import { VIDEO_MESSAGES } from "../../config/messages";

function* searchWorker(action) {
  try {
    const { data, error } = yield call(
      videoServices.search,
      action.payload?.params
    );
    if (error) {
      yield put(searchVideoAction.error(error));
    } else {
      yield put(searchVideoAction.success({ ...action, data }));
    }
  } catch (err) {
    yield put(searchVideoAction.error(err));
  }
  // const { data, error } = yield call(
  //   videoServices.search,
  //   action.payload.params
  // );
  // if (error) {
  //   if (error.headers["x-message-info"]) {
  //     throw new Error(error.headers["x-message-info"]);
  //   }
  // } else {
  //   yield put(searchVideoAction.success({ ...action, data }));
  //   return data;
  // }

  // return false;
}

function* deleteVideoWorker(action) {
  const { data, error } = yield call(
    videoServices.deleteVideo,
    action.payload.videoId,
    action.payload.userId
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      yield put({
        type: alertTypes.ERROR,
        message: error.headers["x-message-info"],
      });
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    yield put({
      type: alertTypes.SUCCESS,
      message: VIDEO_MESSAGES.deleteSuccess,
    });
    return data;
  }

  return false;
}

function* search2VideoWorker(action) {
  yield delay(1000);

  const { data, error } = yield call(
    videoServices.search,
    action.payload?.params
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    return data;
  }

  return false;
}

function* reviewSaga() {
  // yield takeLatest(searchVideoAction.start, searchWorker);

  yield takeLatest(
    searchVideoAction.start,
    searchVideoAction.waiterActionForSaga(sagaPromise(searchWorker))
  );

  yield takeLatest(
    deleteVideoAction.start,
    deleteVideoAction.waiterActionForSaga(sagaPromise(deleteVideoWorker))
  );

  yield takeLatest(
    searchVideo2Action.start,
    searchVideo2Action.waiterActionForSaga(sagaPromise(search2VideoWorker))
  );
}

export default reviewSaga;
