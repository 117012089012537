import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Spinner,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap";
import moment from "moment";
import classNames from "classnames";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";

import useMergeState from '../../hooks/mergeState';
import DataTable from "../../components/DataTable";
import PageSizeSelector from "../../components/base/PageSizeSelector";
import Paging from "../../components/base/Paging";
import config from "../../config/config";

import OrderDetail from "./OrderDetail";
import RecloneVideo from "./RecloneVideo";
import FormFilter from "./FormFilter";
import ConfirmModal from "../../components/base/ConfirmModal";

import { fetchOrdersAction } from "../../redux/reducers/orderReducer";
import { startActionWithPromise } from "../../helpers/saga-promise-helpers";
import "./index.scss";
import { getTableColumns } from "./TableColumns";
import { numberToMoney } from "../../helpers/utils";
import OverviewTab from "./OverviewTab";

const Actions = {
  RETRANSCODE: 'Re-transcode',
  WITHDRAW: 'Withdraw'
};

const tabStyles = {
  revenue: {
    backgroundColor: '#6bbdc4',
    borderBottomColor: '#e4e4e4',
    color: '#fff',
    padding: '0.25rem 0.75rem'
  },
  orders: {
    backgroundColor: 'rgb(255, 99, 132)',
    borderBottomColor: '#e4e4e4',
    color: '#fff',
    padding: '0.25rem 0.75rem'
  },
  active: {
    backgroundColor: '#3B82EC',
    borderBottomColor: '#3B82EC',
    color: '#ffffff',
    padding: '0.25rem 0.75rem'
  },
}

const defaultOrderState = {
  list: [],
  totalItems: 0,
  totalPages: 0,
  pageNumber: 0,
  totalIncome: 0
};

const RECLONE_ACTION = 'RECLONE';

const UserOrders = ({user}) => {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    modalConfirm: false,
    modalDetail: false,
    modalReclone: false,
    action: '',
    message: '',
    row: null,
  });

  const startDate = moment().subtract(7, 'days').format('MM/DD/YYYY');
  const endDate = moment().format('MM/DD/YYYY');

  const defaultFilters = {
    pageSize: config.DEFAULT_PAGESIZE,
    pageNumber: 1,
    startDate: startDate,
    endDate: endDate,
    videoId: '',
    sellerId: user ? user.userId : '',
    buyerId: '',
    status: '',
    sort: {
      dir: `desc`,
      fieldName: `purchasedDate`,
    },
    reload: false,
  };
  const [filters, setFilters] = useMergeState(defaultFilters);

  const [waiting, setWaiting] = useState(false);
  const [waitingClone, setWaitingClone] = useState(false);
  const [activeTab, setActiveTab] = useState(2);
  
  const orderList = useSelector((state) => state.order?.list) || [];
  const fetching = useSelector(state => state.order?.fetching);
  const totalItems = useSelector((state) => state.order?.total);
  const totalPages = useSelector((state) => state.order?.totalPages);
  const pageNumber = useSelector((state) => state.order?.pageNumber);

  const [orderState, mergeOrderState] = useMergeState(defaultOrderState);

  const dataList = orderState.list.map(item => ({
    ...item,
    action_col: uuidv4(),
  }));

  const onSizeChange = (size) => {
    setFilters({ ...filters, pageSize: size, pageNumber: 1, reload: true });
  };

  const onPageChange = (p) => {
    setFilters({ ...filters, pageNumber: p, reload: true });
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  const onColumnSort = (field, order) => {
    setFilters({
      ...filters,
      sort: {
        dir: order,
        fieldName: field,
      },
      reload: true,
    });
  };

  const handleReclone = (row) => {
    let msg = `<p class="font-weight-bold">Video "${row.productTitle}" will be re-clone to ${row.buyerFullname} (${row.buyerEmailAddress}). </p>\
    <p class="font-weight-bold">Do you want to continue?</p>`;
    setState({
      ...state, 
      row: row, 
      message: msg,
      action: RECLONE_ACTION,
      modalReclone: true, 
    });
  }

  const tableColumns = getTableColumns(onColumnSort, handleReclone);

  const onActionButtonClick = (action, row) => {
    switch (action.type) {
      case Actions.RETRANSCODE:
        setState({ ...state, modalRetranscode: true, row });
        break;
      case Actions.WITHDRAW:
        setState({ ...state, modalWithdraw: true, row });
        break;
      default:
    }
  };

  const toggleModal = (modal) => {
    if (state[modal]) setState({ ...state, [modal]: !state[modal] });
    else setState({ ...state, [modal]: !state[modal], row: null });
  };

  const handleActionConfirm = () => {
    switch (state.action) {
      case RECLONE_ACTION:
        break;
      case Actions.WITHDRAW:
        break;
      default:
    }
  };

  const refreshDatatable = () => {
    setFilters({ ...filters, reload: true });
  };

  const fetchOrders = () => {
    (async () => {
      try {
        // setWaiting(true);
        let params = {
          ...filters,
          pageNumber: filters.pageNumber - 1,
          sort: {
            dir: filters.sort.dir.toUpperCase(),
            fieldName: filters.sort.fieldName,
          },
        };
        delete params.reload;

        if (!filters.videoId) delete params.videoId;
        if (!filters.buyerId) delete params.buyerId;

        setWaiting(true);
        let res = await startActionWithPromise(
          fetchOrdersAction,
          {params, successCallback: () => {}, failedCallback: () => {}},
          dispatch
        );
        if (!res) return;

        mergeOrderState({
          list: res.content,
          pageNumber: res.pageNumber + 1,
          total: res.total,
          totalPages: Math.ceil(res.total / res.pageSize),
          totalIncome: res.totalIncome
        });
      } catch {}
      setWaiting(false);
      setFilters({ ...filters, reload: false });
    })();
  };

  useEffect(() => {
    let isCancelled = false;

    if (filters.reload) {
      fetchOrders();
    }

    return () => {
      isCancelled = true;
    }
  }, [filters.reload]);

  useEffect(() => {
    fetchOrders();
  }, []);

  return (
    <div className="tab pt-3 mb-0">
      <Nav tabs>
        {/* <NavItem>
          <NavLink
            className={classNames({ active: activeTab === 1})}
            onClick={() => toggleTab(1)}
            style={{...activeTab === 1 ? tabStyles.active : {}, padding: '0.25rem 0.75rem'}}
          >
            Overview
          </NavLink>
        </NavItem> */}
        <NavItem>
          <NavLink
            className={classNames({ active: activeTab === 2})}
            onClick={() => toggleTab(2)}
            style={{...activeTab === 2 ? tabStyles.active : {}, padding: '0.25rem 0.75rem'}}
          >
            Orders
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent
        activeTab={activeTab}
        style={{
          padding: '1rem 0 0 0',
          borderWidth: '1px 0 0 0', 
          borderStyle: 'solid', 
          borderColor: activeTab === 1 ? tabStyles.revenue.borderBottomColor : tabStyles.orders.borderBottomColor
        }}>
        <TabPane tabId={1}>
          {waiting ? (
            <div className="loading text-center p-2"><Spinner size="sm" /></div>
          ) : activeTab === 1 && (
            <OverviewTab />
          )}
        </TabPane>
        <TabPane tabId={2}>
          { activeTab === 2 && (
            <div className="tab-orders">
              <Card className={classNames('w-100 mb-0 video-review-list', user ? 'orders-modal-card' : '')}>
                <CardHeader className="pt-0">
                  <FormFilter
                    mergeFilters={setFilters}
                    user={user}
                  />
                </CardHeader>
                <CardBody className="pt-0 data-list">
                  { waiting ? (
                    <div className="loading-content text-center p-4 border-top">
                      <Spinner size="sm" />
                    </div>
                  ) : (
                    <>
                      <div className="stat d-flex">
                        {/* <div className="total-gross font-weight-bold mb-2 mr-4">Orders: <span>{formatNumber(orderState.total||0)}</span></div> */}
                        <div className="total-gross font-weight-bold mb-2">Income: <span>${numberToMoney(orderState.totalIncome)}</span></div>
                      </div>
                      <DataTable
                        keyField="orderId"
                        data={dataList}
                        columns={tableColumns}
                        sort={filters.sort.fieldName}
                        order={filters.sort.dir}
                        onActionClick={onActionButtonClick}
                      />
                      <Row className="mt-3">
                        <Col lg="3" md="4" className="d-flex">
                          <PageSizeSelector size={filters.pageSize} onChange={onSizeChange} />
                        </Col>
                        <Col lg="9" md="8" className="d-flex justify-content-end">
                          <Paging totalPages={orderState.totalPages} current={orderState.pageNumber} show={5} onSelect={onPageChange} />
                        </Col>
                      </Row>
                    </>
                  )}
                </CardBody>
              </Card>
            </div>
          )}
        </TabPane>
      </TabContent>
      
      {state.row && (
        <OrderDetail modal={state.modalDetail} toggle={toggleModal} order={state.row} refreshData={refreshDatatable} />
      )}
      {state.modalReclone && (
        <RecloneVideo modal={state.modalReclone} toggle={toggleModal} order={state.row} />
      )}
      
      <ConfirmModal
        modal={state.modalConfirm}
        toggle={toggleModal}
        row={state.row}
        onConfirm={handleActionConfirm}
        message={state.message}
      />
    </div>
  );
};

export default UserOrders;
