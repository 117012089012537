export const ACTIVE_VALUES = {
  yes: true,
  no: false
}

export const ACTIVE_LABELS = {
  [ACTIVE_VALUES.yes]: 'Yes',
  [ACTIVE_VALUES.no]: 'No',
}

export const ACTIVE_COLORS = {
  [ACTIVE_VALUES.yes]: 'success',
  [ACTIVE_VALUES.no]: 'warning',
}

