import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "reactstrap";
import _ from 'lodash';
import Select from 'react-select';
import * as Icon from "react-feather";
import classNames from "classnames";
import moment from "moment";

import "./FormFilter.scss";
import DRP from "../../components/DateRangePicker";
import SearchVideoInput from "./SearchVideoInput";
import SearchUserInput from "./SearchUserInput";

const LogFilter = ({mergeFilters, user}) => {
  const [selectedScreen, setSelectedScreen] = useState('');

  const startDate = moment().subtract(7, 'days').format('MM/DD/YYYY');
  const endDate = moment().format('MM/DD/YYYY');

  const [filters, setFilters] = useState({
    startDate: startDate,
    endDate: endDate,
    videoId: '',
    sellerId: user ? user.userId : '',
    buyerId: '',
    status: '',
    reload: false,
  });

  const handleDateChange = (start, end) => {
    setFilters({ ...filters, startDate: start, endDate: end});
  }

  const handleVideoChange = (option) => {
    setFilters({ ...filters, videoId: option ? option.value : ''});
  }

  const handleBuyerChange = (option) => {
    setFilters({ ...filters, buyerId: option ? option.value : ''});
  }

  const submitFilter = () => mergeFilters({...filters, reload: true});

  return (
    <>
      <Row className={classNames('video-review-filter')} style={{transition: 'visibility 0.5s, opacity 0.5s linear'}}>
        <Col lg="12" md="12" xs="12" className="mt-1 mb-1">
          <div className="item-filter float-right">
            <div className="d-flex align-items-center">
              <div>
                <label htmlFor="" className="mb-0" style={{width: '50px'}}>Video</label>
                <div className="d-inline-block ml-2 item-filter__control" style={{width: '230px'}}>
                  <SearchVideoInput
                    onChange={handleVideoChange}
                    className="w-100"
                    userId={user.userId}
                    initialValue={filters.videoId}
                    isClearable={true}
                    />
                </div>
              </div>
              <div>
                <label htmlFor="" className="mb-0" style={{width: '50px'}}>Buyer</label>
                <div className="d-inline-block ml-2 item-filter__control" style={{width: '230px'}}>
                  <SearchUserInput
                  onChange={handleBuyerChange}
                  className="w-100"
                  isClearable={true}
                  />
                </div>
              </div>
              
            </div>
          </div>
        </Col>
        <Col lg="12" md="12" xs="12" className="mt-1 mb-1">
          <div className="item-filter float-right">
            <div className="d-flex align-items-center">
              
              <div className="d-flex align-items-center">
                <label htmlFor="" className="mb-0" style={{width: '90px'}}>Date range</label>
                <div className="d-inline-block item-filter__control ml-2">
                  <div className="d-flex position-relative">
                    <DRP onChange={handleDateChange} showApplyButton={false} />
                    <Button
                      className="ml-2"
                      size=""
                      // disabled={loading}
                      onClick={(e) => submitFilter(e)}
                      style={{minWidth: '86px'}}
                    >
                      <Icon.Search style={{ width: '15px', height: '15px', marginTop: '-2px' }} /> Search
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default React.memo(LogFilter);