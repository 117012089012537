import {
  createReducer,
  createActionResources,
  createActionCRUDResources,
} from "redux-waiters";
import { RESET_REDUCER } from "../constants";

export const userTrustedAction = createActionCRUDResources("users-trusted");
export const disableAction = createActionResources("users-trusted/disable");
export const enableAction = createActionResources("users-trusted/enable");
export const getAction = createActionResources("users-trusted/get");

const initialState = {
  fetching: false,
  users: [],
  total: 0,
  totalPages: 0,
  pageNumber: 1,
  user: {},
  error: {},
};

export default createReducer(
  {
    [userTrustedAction.fetch.start]: state => {
      return { ...state, fetching: true };
    },
    [userTrustedAction.fetch.success]: (state, action) => {
      const users = action.data?.content;
      let userArr = [];
      if (users) {
        userArr = users.map(user => {
          const u = {
            ...user,
            trustedOn: user.trustedOn?.replace(" +0000", ""),
            userProfile: {
              ...user.userProfile,
              fullname: `${user.userProfile.firstName} ${user.userProfile.lastName}`,
            },
          };
          return u;
        });
      }
      return {
        ...state,
        users: userArr,
        pageNumber: action.data?.pageNumber + 1,
        total: action.data?.total,
        totalPages: Math.ceil(action.data?.total / action.data?.pageSize),
        fetching: false,
      };
    },
    [userTrustedAction.fetch.error]: state => {
      return { ...state, fetching: false };
    },

    [getAction.success]: (state, action) => {
      return {
        ...state,
        user: action.data,
        fetching: false,
      };
    },

    [RESET_REDUCER]: () => initialState,
  },
  initialState
);
