import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Spinner
} from "reactstrap";
import moment from "moment";
import classNames from "classnames";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";

import DataTable from "../../components/DataTable";
import PageSizeSelector from "../../components/base/PageSizeSelector";
import Paging from "../../components/base/Paging";
import config from "../../config/config";
import useMergeState from '../../hooks/mergeState';

import OrderDetail from "./OrderDetail";
import FormFilter from "./FormFilter";
import ConfirmModal from "../../components/base/ConfirmModal";

import { orderActions } from "../../redux/reducers/orderReducer";
import { startActionWithPromise } from "../../helpers/saga-promise-helpers";
import "./index.scss";
import { getTableColumns } from "./TableColumns";
import { orders } from "./orders";

const UserOrders = ({user}) => {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    modalConfirm: false,
    modalDetail: false,
    action: '',
    message: '',
    row: null,
  });

  const startDate = moment().subtract(30, 'days').format('MM/DD/YYYY');
  const endDate = moment().format('MM/DD/YYYY');

  const defaultFilters = {
    pageSize: config.DEFAULT_PAGESIZE,
    pageNumber: 1,
    startDate: startDate,
    endDate: endDate,
    videoId: '',
    sellerId: user ? user.userId : '',
    buyerId: '',
    status: '',
    sort: {
      dir: `desc`,
      fieldName: `purchasedAt`,
    },
    reload: false,
  };
  const [filters, setFilters] = useMergeState(defaultFilters);


  const [waiting, setWaiting] = useState(false);
  
  const orderList = useSelector(state => state.order?.list) || [];
  const fetching = useSelector(state => state.order?.fetching);
  const totalItems = useSelector(state => state.order?.total);
  const totalPages = useSelector(state => state.order?.totalPages);
  const pageNumber = useSelector(state => state.order?.pageNumber);

  const numItems = totalItems > filters.pageSize ? filters.pageSize : totalItems;
  const beginItem = numItems * (filters.pageNumber - 1);
  const endItem = numItems * filters.pageNumber;

  const dataList = orders.data.map(item => ({
    ...item,
    action_col: uuidv4(),
  }));

  const onSizeChange = (size) => {
    setFilters({ ...filters, pageSize: size, pageNumber: 1, reload: true });
  };

  const onPageChange = (p) => {
    setFilters({ ...filters, pageNumber: p, reload: true });
  };

  const handleColumnSort = (field, order) => {
    setFilters({
      ...filters,
      sort: {
        dir: order,
        fieldName: field,
      },
      reload: true,
    });
  };

  const tableColumns = getTableColumns(handleColumnSort, (row) => setState({ ...state, modalDetail: true, row }));

  const toggleModal = (modal) => {
    if (state[modal]) setState({ ...state, [modal]: !state[modal] });
    else setState({ ...state, [modal]: !state[modal], row: null });
  };

  const refreshDatatable = () => {
    setFilters({ ...filters, reload: true });
  };

  const fetchOrders = () => {
    (async () => {
      try {
        // setWaiting(true);
        let params = {
          ...filters,
          pageNumber: filters.pageNumber - 1,
          sort: {
            dir: filters.sort.dir.toUpperCase(),
            fieldName: filters.sort.fieldName,
          },
        };
        delete params.reload;

        if (!filters.videoId) delete params.videoId;
        if (!filters.sellerId) delete params.sellerId;
        if (!filters.buyerId) delete params.buyerId;
        if (!filters.status) delete params.status;

        await startActionWithPromise(
          orderActions.fetch,
          {params, successCallback: () => {}, failedCallback: () => {}},
          dispatch
        );
      } catch {}
      setWaiting(false);
      // setFilters({ ...filters, reload: false });
    })();
  };

  useEffect(() => {
    if (filters.reload) {
      fetchOrders();
      setFilters({ ...filters, reload: false });
    }
  }, [filters.reload]);

  useEffect(() => {
    fetchOrders();
  }, []);

  return (
    <Container fluid className="p-0">
      <h1 className="page-title">Orders</h1>
      <Row>
        <Col lg="12" className="d-flex">
        <>
          <Card className={classNames('w-100 mb-0 datatable-wrap')}>
            <CardHeader className="">
              <FormFilter
                loading={fetching}
                filters={filters}
                mergeFilters={setFilters}
                paging={{
                  beginItem,
                  endItem,
                  totalItems
                }}
                user={user}
                initStart = {startDate}
                initEnd = {endDate}
              />
            </CardHeader>
            <CardBody className="pt-0 data-list">
              { waiting ? (
                <div className="loading-content text-center p-4 border-top">
                  <Spinner size="sm" />
                </div>
              ) : (
                <>
                  <DataTable
                    keyField="orderId"
                    data={orderList}
                    columns={tableColumns}
                    order={filters.sort?.dir}
                    sort={filters.sort?.fieldName}
                  />
                  <Row className="mt-3">
                    <Col lg="3" md="4" className="d-flex">
                      <PageSizeSelector size={filters.pageSize} onChange={onSizeChange} />
                    </Col>
                    <Col lg="9" md="8" className="d-flex justify-content-end">
                      <Paging totalPages={totalPages} current={pageNumber} show={5} onSelect={onPageChange} />
                    </Col>
                  </Row>
                </>
              )}
            </CardBody>
          </Card>
          {state.row && (
            <OrderDetail modal={state.modalDetail} toggle={toggleModal} order={state.row} refreshData={refreshDatatable} />
          )}
        </>
        </Col>
      </Row>
    </Container>
  );
};

export default UserOrders;
