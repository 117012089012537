import React, { useState, useRef } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Spinner,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import { startActionWithPromise } from "../../helpers/saga-promise-helpers";
import { changeMyPwAction } from "../../redux/reducers/bouserReducer";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const password = useRef({});

  const { register, handleSubmit, watch, errors, reset } = useForm();
  password.current = watch("password", "");

  const [pristine, setPristine] = useState(true);
  const [isWaiting, setWaiting] = useState(false);

  const user = JSON.parse(localStorage.getItem("user"));

  const successCallback = () => {
    reset();
  };

  const onSubmit = async values => {
    const postData = {
      newPwd: values.password,
      oldPwd: values.old_password,
    };

    setWaiting(true);
    try {
      await startActionWithPromise(
        changeMyPwAction,
        {
          id: user.userId,
          data: postData,
          successCallback,
          failedCallback: () => {},
        },
        dispatch
      );
    } catch (error) {}
    setWaiting(false);
  };

  // mounted hook
  React.useEffect(() => {}, []);

  return (
    <Card className="w-100 mb-0">
      <CardHeader className="border-bottom font-weight-bold">
        Change password
      </CardHeader>
      <CardBody className="pt-3 position-relative">
        <Form
          onSubmit={e => e.preventDefault()}
          className="change-pw-form"
          method="post"
        >
          <FormGroup className="">
            <Label>Old password</Label>
            <input
              className="form-control"
              size="lg"
              type="password"
              name="old_password"
              placeholder=""
              onChange={() => setPristine(false)}
              ref={register({
                required: 'Old password is required.',
                validate: value => value !== password.current || "New passwords is same as old password."
              })}
            />
            {errors.old_password && (
              <p className="text-danger">{errors.old_password.message}</p>
            )}
          </FormGroup>
          <FormGroup>
            <Label>New password</Label>
            <input
              className="form-control"
              size="lg"
              type="password"
              name="password"
              placeholder=""
              onChange={() => setPristine(false)}
              ref={register({
                required: "New password is required.",
                minLength: 6,
              })}
            />
            {errors.password && (
              <p className="text-danger">
                {errors.password?.type === "required"
                  ? "New password is required."
                  : ""}
                {errors.password?.type === "minLength"
                  ? "Password must have at least 6 characters."
                  : ""}
              </p>
            )}
          </FormGroup>
          <FormGroup>
            <Label>Confirm new password</Label>
            <input
              className="form-control"
              size="lg"
              type="password"
              name="password_confirm"
              placeholder=""
              onChange={() => setPristine(false)}
              ref={register({
                validate: value =>
                  value === password.current || "Passwords do not match.",
              })}
            />
            {errors.password_confirm && (
              <p className="text-danger">{errors.password_confirm.message}</p>
            )}
          </FormGroup>

          <div className="">
            <Button
              className="btn-submit"
              data-test="btn-submit"
              color="primary"
              size="md"
              disabled={pristine || isWaiting}
              onClick={handleSubmit(onSubmit)}
            >
              Submit
              {isWaiting && (
                <Spinner color="white" size="sm" className="ml-2" />
              )}
            </Button>
          </div>
        </Form>
      </CardBody>
    </Card>
  );
};

export default ChangePassword;
