import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Spinner,
  Badge,
} from "reactstrap";
import * as Icon from "react-feather";
import Select from 'react-select';

import DataTable, { rowActionTypes } from "../../../components/DataTable";
import Paging from "../../../components/base/Paging";
import PageSizeSelector from "../../../components/base/PageSizeSelector";
import config from "../../../config/config";
import PermissionUpdate from "./PermissionUpdate";
import PermissionDelete from "./PermissionDelete";
import {
  fetchDomainsAction,
} from "../../../redux/reducers/domainReducer";
import { permissionActions } from "../../../redux/reducers/domainPermissionReducer";
import { startActionWithPromise } from "../../../helpers/saga-promise-helpers";
import ConfirmModal from "../../../components/base/ConfirmModal";
import "./PermissionList.scss";

const selectStyles = {
  control: provided => (
    { ...provided, 
      borderWidth: '0px', 
      minHeight:'31px', 
      borderRadius: '2px', 
      boxShadow: '0 0 0 1px #ced4da',
      "&:hover": {
        borderColor: '#ced4da'
      }
    }),
  option: (provided, state) => {
    return {
      ...provided,
      padding: '3px 8px',
      backgroundColor: state.isSelected ? "#86C9CF" : provided.backgroundColor,
      "&:active": {
        backgroundColor: "#86C9CF"
      },
      // "&:hover": {
      //   backgroundColor: "#deebff"
      // }
    }
  },
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '4px'
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '1px 8px'
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: '4px'
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: '3',
    borderRadius: '2px',
  }),
};

const PermissionList = () => {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    modalUpdate: false,
    modalDetail: false,
    modalDelete: false,
    modalConfirm: false,
    row: { enabled: true },
    action: "",
    confirmMsg: "",
  });

  const [filters, setFilters] = useState({
    pageSize: config.DEFAULT_PAGESIZE,
    pageNumber: 1,
    sort: "id",
    sortReal: "id",
    order: "asc",
    keyword: "",
    reload: false,
  });
  const [filterGroup, setFilterGroup] = useState('');

  const permissions = useSelector(state => state.domainPermissions.permissions);
  const loading = false; // useSelector(state => state.domainPermissions.loading);
  const totalPages = useSelector(state => state.domainPermissions.totalPages);
  const pageNumber = useSelector(state => state.domainPermissions.pageNumber);
  const domains = useSelector(state => state.domain.domains);

  const groupOptions = domains.map(domain => ({
    label: domain.text,
    value: domain.name
  }));

  const arrPermissions = permissions.filter(permission => {
    if (filterGroup)
      return permission.type === filterGroup;
    return true;
  });

  const rowActions = [
    {
      name: "Edit",
      type: rowActionTypes.EDIT,
      classes: "text-dark",
    },
    {
      name: "Delete",
      type: rowActionTypes.DELETE,
      classes: "text-dark",
    },
    {
      name: "Enable",
      type: rowActionTypes.ENABLE,
      classes: "text-dark",
      toggleField: "enabled",
    },
    {
      name: "Disable",
      type: rowActionTypes.DISABLE,
      classes: "text-dark",
      toggleField: "enabled",
    },
  ];

  const tableColumns = [
    {
      dataField: "displayName",
      text: "Name",
      sort: false,
    },
    {
      dataField: "type",
      text: "Type",
      sort: true,
      onSort: (field, order) => {
        onColumnSort(field, order);
      },
      sortFunc: () => {
      },
    },
    {
      dataField: "action",
      text: "Action",
      sort: true,
      onSort: (field, order) => {
        onColumnSort(field, order);
      },
      sortFunc: () => {
      },
      headerStyle: () => {
        return { width: "100px" };
      },
    },
    {
      dataField: "enabled",
      text: "Status",
      sort: false,
      onSort: (field, order) => {
        onColumnSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div>
          <Badge
            color={row.enabled ? "success" : "secondary"}
            className="mr-1 mb-1"
          >
            {row.enabled ? "Enabled" : "Disabled"}
          </Badge>
        </div>
      ),
      headerStyle: () => {
        return { width: "100px" };
      },
    },
  ];

  const onSizeChange = size => {
    setFilters({ ...filters, pageSize: size, pageNumber: 1, reload: true });
  };

  const onPageChange = p => {
    setFilters({ ...filters, pageNumber: p, reload: true });
  };

  const onColumnSort = (field, order) => {
    const realSort = field === "fullname" ? "firstName" : field;
    if (realSort === filters.sortReal && order === filters.order) return;

    setFilters({
      ...filters,
      sort: field,
      sortReal: realSort,
      order,
      reload: true,
    });
  };

  const handleFilterGroupChange = (option) => {
    if (option) {
      if (filters.pageSize !== config.NO_LIMIT_PAGESIZE)
        setFilters({
          ...filters,
          pageSize: config.NO_LIMIT_PAGESIZE,
          pageNumber: 1,
          reload: true,
        });

      setFilterGroup(option.value)
    } else {
      setFilters({
        ...filters,
        pageSize: config.DEFAULT_PAGESIZE,
        reload: true,
      });

      setFilterGroup('');
    }
  };

  const onActionBtnClick = (action, row) => {
    switch (action.type) {
      case rowActionTypes.VIEW:
        setState({ ...state, modalDetail: true, row });
        break;
      case rowActionTypes.EDIT:
        setState({ ...state, modalUpdate: true, row });
        break;
      case rowActionTypes.DELETE:
        setState({ ...state, modalDelete: true, row });
        break;
      case rowActionTypes.ENABLE:
        const msg = `<p class="font-weight-bold">Following permission will be enabled: </p>\
        <p class="font-italic">"${row.displayName}"</p>\
        <p class="font-weight-bold">Do you want to continue?</p>`;
        setState({
          ...state,
          modalConfirm: true,
          row,
          action: rowActionTypes.ENABLE,
          confirmMsg: msg,
        });
        break;
      case rowActionTypes.DISABLE:
        const msg2 = `<p class="font-weight-bold">Following permission will be disabled: </p>\
        <p class="font-italic">"${row.displayName}"</p>\
        <p class="font-weight-bold">Do you want to continue?</p>`;
        setState({
          ...state,
          modalConfirm: true,
          row,
          action: rowActionTypes.DISABLE,
          confirmMsg: msg2,
        });
        break;
      default: 
        break;
    }
  };

  const togglePermissionStatus = (row, status) => {
    const params = {
      id: row.id,
      data: {
        id: row.id,
        type: row.type,
        action: row.action,
        enabled: status,
      },
    };

    (async () => {
      try {
        await startActionWithPromise(
          permissionActions.update,
          { ...params, successCallback: onUpdateStatusSuccess, failedCallback },
          dispatch
        );
      } catch {}
    })();
  };

  const addNewPermission = () => {
    setState({ ...state, modalUpdate: true, row: { enabled: true } });
  };

  const onActionConfirm = () => {
    switch (state.action) {
      case rowActionTypes.ENABLE:
        togglePermissionStatus(state.row, true);
        break;
      case rowActionTypes.DISABLE:
        togglePermissionStatus(state.row, false);
        break;
      default:
    }
  };

  const onUpdateStatusSuccess = () => {
    setState({ ...state, modalConfirm: false, row: null });
    refreshDatatable();
  };

  const toggleModal = modal => {
    if (state[modal]) setState({ ...state, [modal]: !state[modal] });
    else setState({ ...state, [modal]: !state[modal], row: { enabled: true } });
  };

  const refreshDatatable = () => {
    setFilters({ ...filters, reload: true });
  };

  const fetchData = (params) => {
    (async () => {
      try {
        await startActionWithPromise(
          permissionActions.fetch,
          { params, successCallback, failedCallback },
          dispatch
        );
      } catch {}
    })();
  };

  const successCallback = () => {
  };

  const failedCallback = () => {
  };

  useEffect(() => {
    if (filters.reload) {
      const params = {
        pageSize: filters.pageSize,
        pageNumber: filters.pageNumber - 1,
        keyword: filters.keyword,
        orderBy: `${filters.sortReal} ${filters.order.toUpperCase()}`,
      };

      fetchData(params);
      setFilters({ ...filters, reload: false });
    }
  }, [filters.reload]);

  useEffect(() => {
    dispatch(fetchDomainsAction.start());

    const params = {
      pageSize: filters.pageSize,
      pageNumber: filters.pageNumber - 1
    };

    fetchData(params);
  }, []);
  return (
    <>
      <Card className="w-100 mb-0">
        <CardHeader>
          <Row className="data-filter d-flex justify-content-end">
            <Col lg="6" className="d-flex mt-1 mb-1">
              <div className="bulk-actions">
                <Button color="success" size="sm" onClick={addNewPermission}>
                  <Icon.Plus height="18" /> Add New
                </Button>
                <Button color="danger" size="sm" className="ml-1 d-none">
                  <Icon.Trash2 height="18" /> Bulk Delete
                </Button>
              </div>
            </Col>
            <Col lg="6" md="6" className="mt-1 mb-1">
              <div className="item-filter reviewer-filter">
                <label htmlFor="" className="mb-0">Type</label>
                <div className="d-inline-block ml-2 item-filter__control">
                  <Select
                    name="filter_group"
                    options={groupOptions}
                    isClearable={true}
                    styles={selectStyles}
                    placeholder="..."
                    onChange={handleFilterGroupChange}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </CardHeader>
        <CardBody className="pt-0 data-list">
          {loading ? (
            <div className="text-center w-100 bg-white p-4 position-static h-100">
              <Spinner />
            </div>
          ) : (
            <>
              <DataTable
                keyField="displayName"
                data={arrPermissions}
                columns={tableColumns}
                sort={filters.sort}
                order={filters.order}
                onActionClick={onActionBtnClick}
                actions={rowActions}
                hideSelectColumn={true}
              />
              <Row className="mt-3">
                <Col lg="6" className="d-flex">
                  <div className={config.NO_LIMIT_PAGESIZE === filters.pageSize ? 'd-none' : ''}>
                    <PageSizeSelector
                      size={filters.pageSize}
                      onChange={onSizeChange}
                    />
                  </div>
                </Col>
                <Col lg="6" className="d-flex justify-content-end">
                  <Paging
                    totalPages={totalPages}
                    current={pageNumber}
                    show={5}
                    onSelect={onPageChange}
                  />
                </Col>
              </Row>
            </>
          )}
        </CardBody>
      </Card>
      <PermissionUpdate
        modal={state.modalUpdate}
        toggle={toggleModal}
        row={state.row}
        refreshData={refreshDatatable}
      />
      <PermissionDelete
        modal={state.modalDelete}
        toggle={toggleModal}
        row={state.row}
        refreshData={refreshDatatable}
      />
      <ConfirmModal
        modal={state.modalConfirm}
        toggle={toggleModal}
        row={state.row}
        onConfirm={onActionConfirm}
        message={state.confirmMsg}
      />
    </>
  );
};

export default PermissionList;
