import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    Card,
    CardHeader,
    CardBody,
    Container,
    Row,
    Col,
    Spinner,
    Button
} from "reactstrap";
import classNames from "classnames";
import DataTable from "../../components/DataTable";
import PageSizeSelector from "../../components/base/PageSizeSelector";
import Paging from "../../components/base/Paging";
import {
    searchContactMessages,
    updateMessageNoteAndStatus,
} from "../../redux/reducers/userReducer";
import { startActionWithPromise } from "../../helpers/saga-promise-helpers";
import { getGridColumns } from "./gridColumns";
import useMergeState from "../../hooks/mergeState";
import config from "../../config/config";
import styled from "styled-components";
import * as Icon from "react-feather";

import MessageFilter from "./MessageFilter";
import MessageNotes from "./MessageNotes";

const Overlay = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.18);
  z-index: 1;
`;

const ContactUsMessages = () => {
    const dispatch = useDispatch();

    const [data, setData] = useState([]);
    const [fetching, setFetching] = useState(false);
    const [filters, setFilters] = useMergeState({
        pageSize: config.DEFAULT_PAGESIZE,
        pageNumber: 1,
        totalPages: 0,
        total: 0,
        sort: "createdDate",
        order: "asc",
        reload: true,
        selectedStates: ['PENDING', 'PROCESSING'],
    });

    const [modalState, setModalState] = useState(false);
    const [message, setMessage] = useState();

    const collectParams = () => {
        const params = {
            pageSize: filters.pageSize,
            pageNumber: filters.pageNumber - 1,
            selectedStates: [],
        };

        if (filters.selectedStates)
            params.statuses = filters.selectedStates;

        if (filters.keywords)
            params.text = filters.keywords;

        if (filters.sort && filters.order) {
            params.sort = {
                fieldName: filters.sort === "fullname" ? "firstName" : filters.sort,
                dir: filters.order.toUpperCase(),
            };
        }

        if (filters.countries?.length > 0) {
            params.countries = filters.countries.map((c) => c.value);
        }

        return params;
    };

    const fetchData = useCallback((params) => {
        (async () => {
            try {
                setFetching(true);
                await startActionWithPromise(
                    searchContactMessages,
                    {
                        params,
                        successCallback: (response = {}) => {
                            const { content = [], pageNumber, pageSize, total } = response;
                            setData(content);
                            setFilters({
                                pageNumber: pageNumber + 1,
                                pageSize,
                                totalPages: Math.ceil(total / pageSize) || 0,
                                total,
                            });
                            setFetching(false);
                        },
                        failedCallback: () => {
                            setFetching(false);
                        },
                    },
                    dispatch
                );
            } catch { }
        })();
    }, []);

    useEffect(() => {
        if (filters.reload) {
            const params = collectParams();
            fetchData(params);
            setFilters({ reload: false });
        }
    }, [filters.reload]);

    const handleSubmitFilter = useCallback((filtersValues) => {
        setFilters({ pageNumber: 1, ...filtersValues, reload: true });
    }, []);

    const toggleModal = () => {
        setModalState(!modalState);
    };

    const onSizeChange = (size) => {
        setFilters({ ...filters, pageSize: size, pageNumber: 1, reload: true });
    };

    const onPageChange = (p) => {
        setFilters({ ...filters, pageNumber: p, reload: true });
    };

    const handleSaveStatusWithNote = (params) => {
        (async () => {
            try {
                setFetching(true);
                await startActionWithPromise(
                    updateMessageNoteAndStatus,
                    {
                        params,
                        successCallback: (response = {}) => {
                            setFetching(false);
                            setFilters({ reload: true });
                        },
                        failedCallback: () => {
                            setFetching(false);
                            setFilters({ reload: true });
                        },
                    },
                    dispatch
                );
            } catch { }

        })();
    }

    const handleColumnSort = useCallback(
        (field, order) => {
            if (filters.sort !== field || filters.order !== order)
                setFilters({
                    ...filters,
                    sort: field,
                    order: order,
                    reload: true,
                });
        },
        [filters]
    );

    const handleOpenMessage = (row) => {
        setMessage(row);
        setModalState(true);
    }

    const gridColumns = getGridColumns(handleColumnSort, handleOpenMessage);

    return (
        <Container fluid className="p-0 h-100 d-flex flex-column">

            <Row className="h-100 overflow-auto">
                <Col lg="12" className="h-100 d-flex">
                    <Card className={classNames("w-100 mb-0 datatable-wrap")}>
                        <CardHeader>
                            <MessageFilter
                                onSubmitFilter={handleSubmitFilter}
                                loading={fetching}
                                selectedStates={filters.selectedStates}
                            />
                        </CardHeader>
                        <CardBody className="py-0 data-list initial-scrollbar position-relative">
                            <Row className="h-100">
                                {fetching && (
                                    <Overlay>
                                        <Spinner />
                                    </Overlay>
                                )}
                                <Col className="h-100" lg="12" md="12">
                                    <DataTable
                                        keyField="userId"
                                        data={data}
                                        columns={gridColumns}
                                        sort={filters.sort}
                                        order={filters.order}
                                        hideSelectColumn={true}
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                        <Row className="my-3 px-4">
                            <Col lg="2" md="2" className="d-flex">
                                <PageSizeSelector
                                    size={filters.pageSize}
                                    onChange={onSizeChange}
                                />
                            </Col>
                            <Col lg="4" md="4" className="d-flex justify-content-center">
                                {filters.total && <span><b>{filters.total}</b> message(s) found.</span>}
                            </Col>
                            <Col lg="6" md="6" className="d-flex justify-content-end">
                                <Paging
                                    totalPages={filters.totalPages}
                                    current={filters.pageNumber}
                                    show={5}
                                    onSelect={onPageChange}
                                />
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            {modalState && (
                <MessageNotes
                    modal={modalState}
                    message={message}
                    onToggle={toggleModal}
                    onSubmitNote={handleSaveStatusWithNote}
                />
            )}
        </Container>
    );
};

export default ContactUsMessages;
