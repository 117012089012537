import React from "react";
import { Badge, UncontrolledTooltip } from "reactstrap";
import * as Icon from "react-feather";

import { ACTIVE_LABELS, ACTIVE_COLORS } from "./constants";

export const getTableColumns = (isBoAdmin, handleUpdate) => {
  
  return [
    {
      dataField: "name",
      text: "Option Type",
      sort: false,
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div>{row.name}</div>
      ),
      headerStyle: () => {
        return { width: '25%' };
      },
    },
    {
      dataField: "listingOrderNumber",
      text: "Listing Order",
      sort: false,
      formatter: (cell, row) => (
        <div>{row.listingOrderNumber}</div>
      ),
      headerStyle: () => {
        return { textAlign: "center", width: '10%' };
      },
      style: () => {
        return { textAlign: "center" };
      },
    },
    {
      dataField: "description",
      text: "Description",
      sort: false,
      formatter: (cell, row) => (
        <div>{row.description}</div>
      ),
      headerStyle: () => {
        return { width: '30%' };
      },
    },
    {
      dataField: "defaultValue",
      text: "Default Value",
      sort: false,
      formatter: (cell, row) => (
        <div>{row.defaultValue}</div>
      ),
      headerStyle: () => {
        return { width: '7%' };
      },
    },
    {
      dataField: "defaultValueUnit",
      text: "Default Value Unit",
      sort: false,
      formatter: (cell, row) => (
        <div>{row.defaultValueUnit}</div>
      ),
      headerStyle: () => {
        return { width: '13%' };
      },
    },
    {
      dataField: "active",
      text: "Active",
      sort: false,
      formatter: (cell, row) => (
        <div className="">
          <Badge
            color={ACTIVE_COLORS[row.active]}
            className="mr-1 mb-1"
          >
            {ACTIVE_LABELS[row.active]}
          </Badge>
        </div>
      ),
      headerStyle: () => {
        return { textAlign: "center", width: '5%' };
      },
      style: () => {
        return { textAlign: "center" };
      },
    },
    
    {
      dataField: "action_col",
      text: "Actions",
      sort: false,
      formatter: (cell, row, rowIndex, formatExtraData) => (
        <div className="actions-col">
          {isBoAdmin && (
            <a href="#" className="mr-2" onClick={e => handleUpdate(row, e)} id={'edit-' + row.action_col}>
              <Icon.Edit className="text-dark" style={{ width: "15px" }} />
              <UncontrolledTooltip target={'edit-' + row.action_col}>
                Edit
              </UncontrolledTooltip>
            </a>
          )}
        </div>
      ),
      formatExtraData: {
        
      },
      headerStyle: () => {
        return { textAlign: "center", width: "130px" };
      },
      style: () => {
        return { textAlign: "center" };
      },
    },
  ];
}