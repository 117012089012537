import React from "react";
import moment from "moment";
import { Badge } from "reactstrap";

import { TRANSFER_STATUS_LABELS, TRANSFER_STATUS_COLORS } from "./constants";

export const rowActionTypes = {

};

export const getTableColumns = (handleSort = () => { }, isBoAdmin, state, setState) => {

  return [
    {
      dataField: "userFullname",
      text: "User Id",
      sort: false,
      onSort: (field, order) => {
        handleSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div className="d-inline-block fullname align-self-center">
          <p className="mb-0">{row.userFullname}</p>
          <p className="mb-0">{row.userEmailAddress}</p>
          <p className="mb-0 font-italic" style={{ fontSize: '0.75rem' }}>{row.userId}</p>
        </div>
      ),
      headerStyle: () => {
        return { minWidth: '15%' };
      },
    },
    {
      dataField: "yearMonth",
      text: "Month/ Year",
      sort: true,
      formatter: (cell, row) => (
        <div className="">
          <div className="order-item">
            <div>{Math.floor(row.yearMonth % 100)}/{Math.floor(row.yearMonth / 100)}</div>
          </div>
        </div>
      ),
      headerStyle: () => {
        return { width: '100px' };
      },
    },
    {
      dataField: "amount",
      text: "Amount",
      sort: true,
      formatter: (cell, row) => (
        <div>
          <div>{row.amount}&nbsp;{row.currency.toUpperCase()}</div>
        </div>
      ),
      headerStyle: () => {
        return { width: '100px' };
      },
      style: () => {
        return { textAlign: 'Right' }
      }
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
      onSort: (field, order) => {
        handleSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div>{row.description}</div>
      ),
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: "transactionReference",
      text: "Transaction Reference",
      sort: false,
      onSort: (field, order) => {
        handleSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div>{row.transactionReference}</div>
      ),
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: "destinationAccountId",
      text: "Destination Account",
      sort: true,
      onSort: (field, order) => {
        handleSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div>{row.destinationAccountId}</div>
      ),
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: "paymentGateway",
      text: "Payment Gateway",
      sort: true,
      onSort: (field, order) => {
        handleSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div>{row.paymentGateway}</div>
      ),
      headerStyle: () => {
        return { width: '100px' };
      },      
    },
    {
      dataField: "paymentMethod",
      text: "Payment Method",
      sort: true,
      onSort: (field, order) => {
        handleSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div>{row.paymentMethod}</div>
      ),
      headerStyle: () => {
        return { width: '10%' };
      },
    },
    {
      dataField: "transferDate",
      text: "Transfer Date",
      sort: true,
      onSort: (field, order) => {
        handleSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div className="">
          {moment(row.transferDate).format('MM/DD/YYYY')}
        </div>
      ),
      headerStyle: () => {
        return { width: "140px" };
      },
    },
    {
      dataField: "transferBy",
      text: "Transfer By",
      sort: false,
      onSort: (field, order) => {
        handleSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div>{row.transferBy}</div>
      ),
      headerStyle: () => {
        return { width: '7%' };
      },
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      onSort: (field, order) => {
        handleSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div className="">
          <Badge
            color={TRANSFER_STATUS_COLORS[row.status]}
            className="mr-1 mb-1"
          >
            {TRANSFER_STATUS_LABELS[row.status]}
          </Badge>
        </div>
      ),
      headerStyle: () => {
        return { width: '7%' };
      },
    },

  ];
}