import React from "react";
import Avatar from "../../components/Avatar";

export const getGridColumns = (handleSort = () => {}, handleReclone) => {
  return [
    {
      dataField: "firstName",
      text: "User",
      sort: true,
      onSort: (field, order) => {
        handleSort(field, order);
      },
      formatter: (cell, row) => (
        <div className="d-flex">
          <div className="user-avatar d-flex" style={{ minWidth: "48px" }}>
            {row.avatar?.image ? (
              <Avatar
                className="user-avatar d-inline-block align-self-center mr-2"
                image={row?.avatar?.image}
                fullname={row.fullname}
                width="48"
                height="48"
              />
            ) : (
              <Avatar
                className="user-avatar d-inline-block align-self-center mr-2"
                image={row?.avatar?.image}
                fullname={row.fullname}
                width="48"
                height="48"
              />
            )}
          </div>
          <div className="d-inline-block fullname align-self-center">
            <p
              className="mb-0"
              style={{ textDecoration: row.deleted ? "line-through" : "" }}
            >
              {row.fullname}
            </p>
            <p className="mb-0">{row.email}</p>
            <p className="mb-0">{row.phone}</p>
            <p className="mb-0 font-italic" style={{ fontSize: "0.75rem" }}>
              {row.id}
            </p>
          </div>
        </div>
      ),
      headerStyle: () => {
        return { width: "30%" };
      },
    },
    {
      dataField: "country",
      text: "Country",
      sort: true,
      onSort: (field, order) => {
        handleSort(field, order);
      },
      headerStyle: () => {
        return { width: "200px" };
      },
    },
    {
      dataField: "joinAs",
      text: "Registered as",
      sort: false,
      onSort: (field, order) => {
        handleSort(field, order);
      },
      headerStyle: () => {
        return { width: "160px" };
      },
    },
    {
      dataField: "activated",
      text: "Activated",
      sort: false,     
      headerStyle: () => {
        return { width: "160px" };
      },
      formatter: (cell, row) => (
        <span>{row.activated ? "Yes" : "No"}</span>
      ),
    },
    {
      dataField: "enabled",
      text: "Enabled",
      sort: false,     
      headerStyle: () => {
        return { width: "160px" };
      },
      formatter: (cell, row) => (
        <span>{row.enabled ? "Yes" : "No"}</span>
      ),
    },
    {
      dataField: "emailSentCount",
      text: "Email sent",
      sort: false,
      onSort: (field, order) => {
        handleSort(field, order);
      },
      headerStyle: () => {
        return { width: "140px" };
      },
    },
    {
      dataField: "emailReceivedCount",
      text: "Email received",
      sort: false,
      headerStyle: () => {
        return { width: "140px" };
      },
    },
    {
      dataField: "emailFailedCount",
      text: "Email failed",
      sort: false,
      onSort: (field, order) => {
        handleSort(field, order);
      },
      headerStyle: () => {
        return { width: "140px" };
      },
    },
  ];
};
