import React from "react";

import Wrapper from "../components/Wrapper";
import Sidebar from "../components/Sidebar";
import Main from "../components/Main";
import Navbar from "../components/Navbar";
import Content from "../components/Content";
import Notifications from "../components/Notifications";
import { useLocation } from 'react-router-dom';

const DefaultLayout = ({ children }) => {
  const location = useLocation();
  
  return (
    <>
      <Wrapper>
        <Sidebar />
        <Main>
          <Navbar />
          <Content className={location.pathname==='/dashboard' ? 'dashboard' : ''}>{children}</Content>
          {/* <Footer /> */}
        </Main>
        <Notifications />
      </Wrapper>
    </>
  );
};

export default DefaultLayout;
