import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "reactstrap";
import Select from 'react-select';
import * as Icon from "react-feather";
import classNames from "classnames";

import "./LogFilter.scss";
import DRP from "../../components/DateRangePicker";

const SourceSystems = {
  MM_WEB: 'MM_WEB',
  MM_IOS: 'MM_IOS',
  MM_ANDROID: 'MM_ANDROID',
  MM_DESKTOP: 'MM_DESKTOP',
}

const SourceSystemsCopy = {
  MM_WEB: 'Web',
  MM_IOS: 'iOS',
  MM_ANDROID: 'Android',
  MM_DESKTOP: 'Desktop',
}

const sourceSystemOptions = [
  {
    label: SourceSystemsCopy[SourceSystems.MM_WEB],
    value: SourceSystems.MM_WEB,
  },
  {
    label: SourceSystemsCopy[SourceSystems.MM_IOS],
    value: SourceSystems.MM_IOS,
  },
  {
    label: SourceSystemsCopy[SourceSystems.MM_ANDROID],
    value: SourceSystems.MM_ANDROID,
  },
  {
    label: SourceSystemsCopy[SourceSystems.MM_DESKTOP],
    value: SourceSystems.MM_DESKTOP,
  },
];

const selectStyles = {
  control: provided => (
    { ...provided, 
      borderWidth: '1px', 
      minHeight:'31px', 
      borderRadius: '2px', 
      // boxShadow: '0 0 0 1px #ced4da',
      boxShadow: 'none',
      borderColor: '#ced4da',
      "&:hover": {
        borderColor: '#ced4da'
      }
    }),
  option: (provided, state) => {
    return {
      ...provided,
      backgroundColor: state.isSelected ? "#86C9CF" : provided.backgroundColor,
      "&:active": {
        backgroundColor: "#86C9CF"
      },
      padding: '3px 8px'
    }
  },
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '3px'
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0px 8px'
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: '3px'
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: '3',
    borderRadius: '2px',
  }),
};

const LogFilter = ({onSubmitFilter, loading, paging, startDate, endDate}) => {
  const [selectedSource, setSelectedSource] = useState('');

  const [filters, setFilters] = useState({
    sourceSystem: '',
    startDate: startDate,
    endDate: endDate,
    sort: 'occuredTimestamp',
    order: 'desc',
    reload: false
  });

  const handleDateChange = (start, end) => {
    setFilters({ ...filters, startDate: start, endDate: end});
  }

  const onChangeSourceSystem = (option) => {
    setFilters({ ...filters, sourceSystem: option ? option.value : ''});
    setSelectedSource(option);
  }

  const submitFilter = () => {
    if (onSubmitFilter) onSubmitFilter(filters);
  };

  useEffect(() => {
    if (filters.reload) {
      submitFilter();
      setFilters({ ...filters, reload: false });
    }
  }, [filters.reload]);

  return (
    <>
      <Row className={classNames('video-review-filter')} style={{transition: 'visibility 0.5s, opacity 0.5s linear'}}>
        <Col lg="12" md="12" xs="12" className="mt-1 mb-1">
        <div className="item-filter float-right">
            <div className="d-flex align-items-center">
              <div className="">
                <label htmlFor="" className="mb-0" style={{width: '50px'}}>Source</label>
                <div className="d-inline-block ml-2 item-filter__control" style={{width: '130px'}}>
                  <Select
                    name="sourceSystem"
                    options={sourceSystemOptions}
                    isClearable={true}
                    value={selectedSource}
                    styles={selectStyles}
                    placeholder="All sources"
                    onChange={onChangeSourceSystem}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center">
                <label htmlFor="" className="mb-0" style={{width: '50px'}}>Time</label>
                <div className="d-inline-block item-filter__control ml-2">
                  <div className="d-flex position-relative">
                    <DRP onChange={handleDateChange} showApplyButton={false} />
                    <Button
                      className="ml-2"
                      size=""
                      disabled={loading}
                      onClick={(e) => submitFilter(e)}
                      style={{minWidth: '86px'}}
                    >
                      <Icon.Search className="" style={{ width: '15px', height: '15px', marginTop: '-2px' }} /> Search
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default React.memo(LogFilter);