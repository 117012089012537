import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  FormGroup,
  Label
} from "reactstrap";
import PropTypes from "prop-types";
import { useForm, Controller } from "react-hook-form";
import moment from "moment";
import Select from "react-select";

import { assignAction, selfAssignAction } from "../../redux/reducers/reviewReducer";
import { startActionWithPromise } from "../../helpers/saga-promise-helpers";
import { REVIEW_MESSAGES } from "../../config/messages";
import { useAuth } from "../../hooks/useAuth";

const propTypes = {
  row: PropTypes.object,
  modal: PropTypes.bool,
  toggle: PropTypes.func,
};

const customStyles = {
  menuList: (provided) => {
    return {
      ...provided,
      height: '220px'
    }
  },
  control: provided => ({ ...provided, boxShadow: '0 0 0 1px #86C9CF' }),
  option: (provided, state) => {
    return {
      ...provided,
      backgroundColor: state.isSelected ? "#86C9CF" : provided.backgroundColor,
      "&:active": {
        backgroundColor: "#86C9CF"
      },
      // "&:hover": {
      //   backgroundColor: "#deebff"
      // }
    }
  }
};

const VideoAssignment = (props) => {
  const dispatch = useDispatch();
  const auth = useAuth();

  const submitButton = useRef(null);

  const { modal, toggle, row, selfAssign, refreshData } = props || {};
  const { handleSubmit, errors, control, setValue } = useForm();

  const isBoAdmin = useSelector((state) => state.bouser?.isBoAdmin);
  const users = useSelector((state) => state.bouser?.users) || [];
  var profile = useSelector(state => state.bouser?.profile);
  // delete profile.reviewAccountLink;

  const [selectedOption, setSelectedOption] = useState(null);
  const [pristine, setPristine] = useState(true);
  const [waiting, setWaiting] = useState(false);
  const [assignToMe, setAssignToMe] = useState(false);

  var requesterOptions = isBoAdmin ? users.filter(user => auth.user && auth.user.userId !== user.userId).map(user => ({
    label: `${user.fullname} (${user.username})`,
    text: `${user.fullname}`,
    value: user.userId
  })) : [];

  if (auth.user)
    requesterOptions.unshift({
      label: `Myself (${auth.user.firstName} ${auth.user.lastName})`,
      text: `${auth.user.firstName} ${auth.user.lastName}`,
      value: auth.user.userId
    });

  const toggleModal = () => {
    if (toggle) toggle("modalAssign");
  };

  const triggerSubmitForm = () => {
    if (submitButton.current) submitButton.current.click();
  };

  const onSubmit = async (data) => {
    const isSelfAssign = profile && profile.userId === data.assignee?.value;

    if (!row) return;

    setWaiting(true);
    try {
      if (isSelfAssign) {
        await startActionWithPromise(
          selfAssignAction,
          {
            id: row.id,
            successCallback,
            failedCallback,
          },
          dispatch
        );
      } else {
        await startActionWithPromise(
          assignAction,
          { 
            id: row.id,
            userId: data.assignee?.value,
            successCallback,
            failedCallback
          },
          dispatch
        );
      }
    } catch {}
    setWaiting(false);
  };

  const successCallback = () => {
    setWaiting(false);
    if (refreshData) refreshData();
    toggleModal();
  };

  const failedCallback = () => {
    setWaiting(false);
  };

  const onCustomChange = (value) => {
    if (value) setPristine(false);
    else setPristine(true);

    if (!value) {
      setAssignToMe(false);
    } else if (profile && value.value !== profile.userId) {
      setAssignToMe(false);
    }
    setSelectedOption(value);
  }

  const handleAssignToMe = (e) => {
    if (!profile) return;
    setAssignToMe(!assignToMe);

    if (e.target.checked) {
      const selected = {
        label: `${profile.firstName} ${profile.lastName} (${profile.username})`,
        value: profile.userId
      };

      setSelectedOption(selected);
      setValue('assignee', selected);
      setPristine(false);
    } else {
      setSelectedOption(null);
      setPristine(true);
      setValue('assignee', null);
    }
  }

  useEffect(() => {
    if (modal) {
      if (selfAssign) {
        const option = {
          value: auth.user?.userId,
          label: `${auth.user?.firstName} ${auth.user?.lastName} (${auth.user?.username})`
        };
        setSelectedOption(option);
        setValue('assignee', option);
        setAssignToMe(true);
        setPristine(false);
      } else {
        setAssignToMe(false);
        setSelectedOption(null);
      }
    }
  }, [modal]);

  useEffect(() => {
    // dispatch(fetchRoles());
  }, []);

  return (
    <Modal isOpen={modal} toggle={toggleModal} centered size="md">
      <ModalHeader>
        Assign Reviewer To Publishing Request
      </ModalHeader>
      <ModalBody className="">
        <form onSubmit={handleSubmit(onSubmit)} id="form-update">
          <Row>
            <Col lg="12">
              {profile && selectedOption && !profile.reviewAccountLink && profile.userId === selectedOption.value && (
                <div
                  className="font-weight-bold mb-3 rounded"
                  role="alert"
                  style={{ padding: "0.5rem", borderColor: '#DC632A', color: '#DC632A', borderStyle: 'dashed', borderWidth:'1px' }}
                >
                  {REVIEW_MESSAGES.reviewAccountLinkMissing}
                </div>
              )}
              <FormGroup>
                <div className="form-label">
                  <Label for="comment" className="font-weight-bold">
                    Assignee <span className="text-danger">*</span>
                  </Label>
                  <Label className="ml-4"><input type="checkbox" disabled={selfAssign} checked={assignToMe} value={assignToMe} onChange={handleAssignToMe}/> <span>Assign to me</span></Label>
                </div>
                
                <Controller
                  name="assignee"
                  control={control}
                  onChange={(a) => {console.log(a)} }
                  rules={{ required: 'Assignee is required.' }}
                  defaultValue={selectedOption}
                  render={({onChange, value, isDisabled, ...rest}) => (
                  <Select
                    isClearable
                    options={requesterOptions}
                    onChange={(item) => {onChange(item); onCustomChange(item)}}
                    {...rest}
                    styles={customStyles}
                    placeholder="Select reviewer..."
                    value={value}
                    isDisabled={selfAssign}
                    />
                  )}
                />
                <p className="text-danger">{errors.assignee?.message}</p>
              </FormGroup>
            </Col>
          </Row>

          <Button
            className="d-none"
            disabled={pristine}
            type="submit"
            innerRef={submitButton}
          >
            Submit
          </Button>
        </form>
        <div className="font-weight-bold mt-3 d-none">Publishing request detail:</div>
        <div className="request-detail p-2 border bg-light rounded">
          <div className="title mb-2">
            <div className="font-weight-bold">Requester</div>
            <div className="">{row ? row.requester?.fullName : ''}</div>
          </div>
          <div className="title mb-2">
            <div className="font-weight-bold">Video</div>
            <div className="" style={{whiteSpace: 'pre-wrap', wordBreak: 'break-word'}}>
              <p className="mb-0">Title: <span>{row ? row.video?.title : ''}</span></p>
              <p className="mb-0">Subject: <span>{row ? row.video?.subject : ''}</span></p>
              <p className="mb-0">Topic: <span>{row ? row.video?.topic : ''}</span></p>
              <p className="mb-0">Version: <span>{row ? row.video?.version : ''}</span></p>
            </div>
          </div>
          <div className="title mb-2">
            <div className="font-weight-bold">Requested at</div>
            <div className="">{row && row.requestDate ? moment(row.requestDate, 'MM/DD/YYYY HH:mm:ss Z').format('YYYY/MM/DD HH:mm:ss') : ''}</div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" disabled={waiting} onClick={toggleModal}>
          Cancel
        </Button>{" "}
        <Button
          color="primary"
          disabled={pristine || waiting}
          onClick={() => triggerSubmitForm(true)}
        >
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};

VideoAssignment.propTypes = propTypes;

export default VideoAssignment;
