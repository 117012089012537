import React, { useState } from "react";
import { Row, Col, Button, UncontrolledTooltip } from "reactstrap";
import _ from 'lodash';
import * as Icon from "react-feather";
import classNames from "classnames";
import DatePicker from "react-datepicker";
import styled from "styled-components";

import "./FormFilter.scss";

const StyledDatetimeDiv = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker__input-container {
    input {
      width: 230px;
    }
    
  }
`;

const FormFilter = ({loading, submitAction, isBoAdmin}) => {
  
  const [filters, setFilters] = useState({
    effectiveDate: '',
    reload: false,
  });

  const convertToDateTime = (date) => {
    if (!date) return null;
  
    return `${_.padStart(date.getMonth() + 1, 2, 0)}/${_.padStart(date.getDate(), 2, 0)}/${date.getFullYear()} ${_.padStart(date.getHours(), 2, 0)}:${_.padStart(date.getMinutes(), 2, 0)}`;
  }

  return (
    <>
      <Row className={classNames('video-review-filter')} style={{transition: 'visibility 0.5s, opacity 0.5s linear'}}>
        <Col lg="12" md="12" xs="12" className="">
        <div className="item-filter float-right">
            <div className="d-flex align-items-center">
              
              <div className="d-flex align-items-center ml-3">
                <label htmlFor="" className="mb-0 text-nowrap text-right" style={{width: '220px'}}>Effective Date (Catalog version) <font color="red">*</font> </label>
                <StyledDatetimeDiv className="d-inline-block item-filter__control ml-2">
                  <DatePicker
                    selected={filters.effectiveDate}
                    onChange={(date) => {setFilters({...filters, effectiveDate: date}); }}
                    dateFormat="MM/dd/yyyy HH:mm"
                    timeInputLabel="Time:"
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    // showTimeSelect
                    // className="w-100"
                    style={{width: '230px'}}
                    showTimeInput
                  />
                </StyledDatetimeDiv>
              </div>

              <div className="d-flex align-items-center">
                <Button
                  className="ml-2"
                  size=""
                  disabled={loading || !isBoAdmin || !filters.effectiveDate}
                  onClick={(e) => submitAction(convertToDateTime(filters.effectiveDate))}
                  style={{minWidth: '86px'}}
                  id={'submitCatalogBtn'}
                >
                  <Icon.Search style={{ width: '15px', height: '15px', marginTop: '-2px' }} /> Submit Catalog
                  <UncontrolledTooltip target={'submitCatalogBtn'}>
                    Select Effective Date to submit catalog
                  </UncontrolledTooltip>
                </Button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default React.memo(FormFilter);