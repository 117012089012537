import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Badge,
  UncontrolledTooltip
} from "reactstrap";
import * as Icon from "react-feather";
import moment from "moment";
import classNames from "classnames";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import {
  useLocation
} from "react-router-dom";

import DataTable, { rowActionTypes } from "../../components/DataTable";
import PageSizeSelector from "../../components/base/PageSizeSelector";
import Paging from "../../components/base/Paging";
import config from "../../config/config";
import VideoReject from "./VideoReject";
import VideoApprove from "./VideoApprove";
import VideoAssignment from "./VideoAssignment";
import VideoFeedback from "./VideoFeedback";
import VideoReviewFilter from "./VideoReviewFilter";
import ConfirmModal from "../../components/base/ConfirmModal";
import {
  fetchBoUsersAction,
} from "../../redux/reducers/bouserReducer";
import {
  searchPublishingsAction,
  removeAssignAction,
} from "../../redux/reducers/reviewReducer";
import { startActionWithPromise } from "../../helpers/saga-promise-helpers";
import { useAuth } from "../../hooks/useAuth";
import "./VideoReviewList.scss";

const REMOVE_ASSIGN_ACTION = "REMOVE_ASSIGN";
const STATUSES = {
  pending: 'PENDING',
  processing: 'PROCESSING',
  rejected: 'REJECTED',
  approved: 'APPROVED',
  canceled: 'CANCELED',
}

const READONLY_STATUSES = [STATUSES.rejected, STATUSES.approved, STATUSES.canceled];

const FeedbackItem = ({row, onClick}) => {
  const handleClick = () => {
    if (onClick) onClick(row);
  }

  return (
    <div className="feedback_info text-center mt-1">
      <Icon.Info onClick={handleClick} className="text-dark" style={{ width: "15px", cursor: 'pointer' }} />
    </div>
  )
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const UserList = (props) => {
  const dispatch = useDispatch();
  const auth = useAuth();

  let query = useQuery();
  // console.log('query', query.get('reviewer'));

  const [state, setState] = useState({
    modalReject: false,
    modalApprove: false,
    modalConfirm: false,
    modalAssign: false,
    modalFeedback: false,
    selfAssign: false,
    action: "",
    message: "",
    row: null,
  });

  const [filters, setFilters] = useState({
    pageSize: config.DEFAULT_PAGESIZE,
    pageNumber: 1,
    requester: "",
    reviewerId: "",
    statuses: [STATUSES.pending, STATUSES.processing],
    sort: "",
    sortReal: "",
    order: "",
    video: "",
    reload: false,
  });

  const [isShowSearch, setIsShowSearch] = useState(true);
  
  const users = useSelector((state) => state.bouser?.users) || [];
  const fetching = useSelector(state => state.review?.fetching);
  const totalPages = useSelector((state) => state.review.totalPages);
  const pageNumber = useSelector((state) => state.review.pageNumber);
  const requests = useSelector((state) => state.review?.videos) || [];
  const isBoAdmin = useSelector((state) => state.bouser?.isBoAdmin);

  const dataList = requests.map(item => ({
    ...item,
    action_col: uuidv4(),
  }));

  const requesterOptions = users.map(user => {
    return {
      label: `${user.fullname} (${user.username})`,
      text: `${user.fullname}`,
      value: user.userId
    }
  });

  const tableColumns = [
    {
      dataField: "video.title",
      text: "Video",
      sort: true,
      onSort: (field, order) => {
        onColumnSort(field, order);
      },
      sortFunc: () => {
        // for ignore default datable sort
      },
      formatter: (cell, row) => (
        <div>
          <div><span title={row.video?.title}>{_.truncate(row.video?.title, {'length': 80,})}</span></div>
          <div><span title={row.video?.subject}>{_.truncate(row.video?.subject, {'length': 80,})}</span></div>
          <div><span title={row.video?.topic}>{row.video?.topic}</span></div>
          {/* <div><span title={row.video?.uid}>{row.video?.uid}</span></div> */}
          {/* <div><span className="font-weight-bold">Version:</span> {row.video?.version}</div> */}
          {/* <div>{row.video?.topic}</div> */}
        </div>
      ),
    },
    {
      dataField: "requester.fullName",
      text: "Requester",
      sort: true,
      onSort: (field, order) => {
        onColumnSort(field, order);
      },
      sortFunc: () => {
        // for ignore default datable sort
      },
      formatter: (cell, row) => (
        <div title={row.requester?.email}>
          <span className={classNames('mr-1', {'no-avatar': row.requester?.avatar})}>
            <img
              className="rounded-circle"
              width="25"
              src={row.requester?.avatar}
              alt="" />
            </span>
          <span>{row.requester?.fullName}</span>
        </div>
      ),
    },
    {
      dataField: "reviewer.fullName",
      text: "Reviewer",
      sort: true,
      onSort: (field, order) => {
        onColumnSort(field, order);
      },
      sortFunc: () => {
        // for ignore default datable sort
      },
      formatter: (cell, row, rowIndex, formatExtraData) => (
        <div>
          {row.reviewer
            ? (<div className="reviewer-item">
                <div className={classNames('rounded-circle', {'no-avatar': row.reviewer?.avatar})}></div>
                <div className="reviewer-name">{row.reviewer?.fullName}</div>
                {(isBoAdmin || (auth.user?.userId === row.reviewer.userId)) && READONLY_STATUSES.indexOf(row.status) === -1 && (
                  <div>
                    <span className="remove" style={{cursor: 'pointer'}} id={'remove-reviewer-' + row.video?.uid} onClick={e => formatExtraData.onRemove(row, e)}>
                      <Icon.UserX style={{ width: "15px" }} />
                      <UncontrolledTooltip target={'remove-reviewer-' + row.video?.uid}>
                        Remove reviewer
                      </UncontrolledTooltip>
                    </span>
                  </div>
                )}
              </div>)
            : READONLY_STATUSES.indexOf(row.status) === -1 && (
                <div className="">
                  <a className="mr-2" id={'assign-myself-' + row.video?.uid} onClick={e => formatExtraData.onSelfAssign(row, e)} >
                    <Icon.Star style={{ width: "15px" }} />
                    <UncontrolledTooltip target={'assign-myself-' + row.video?.uid}>
                      Assign to me
                    </UncontrolledTooltip>
                  </a>
                  {isBoAdmin && (
                    <a id={'assign-reviewer-' + row.video?.uid} onClick={e => formatExtraData.onAssign(row, e)} >
                      <Icon.UserPlus style={{ width: "15px" }} />
                      <UncontrolledTooltip target={'assign-reviewer-' + row.video?.uid}>
                        Assign reviewer
                      </UncontrolledTooltip>
                    </a>
                  )}
                </div>
              )
          }
          
        </div>
      ),
      formatExtraData: {
        onSelfAssign: (row, e) => onSelfAssignButtonClick(row, e),
        onAssign: (row, e) => onAssignButtonClick(row, e),
        onRemove: (row, e) => onRemoveAssign(row, e)
      },
    },
    {
      dataField: "requestDate",
      text: "Requested At",
      sort: true,
      onSort: (field, order) => {
        onColumnSort(field, order);
      },
      sortFunc: () => {
        // for ignore default datable sort
      },
      formatter: (cell, row) => (
        <div>
          {row.requestDate ? moment(row.requestDate, 'MM/DD/YYYY HH:mm:ss Z').format('HH:mm:ss') : ''}<br/>
          {row.requestDate ? moment(row.requestDate, 'MM/DD/YYYY HH:mm:ss Z').format('YYYY/MM/DD') : ''}
        </div>
      ),
      headerStyle: () => {
        return { width: "140px" };
      },
    },
    
    {
      dataField: "status",
      text: "Status",
      sort: true,
      onSort: (field, order) => {
        onColumnSort(field, order);
      },
      sortFunc: () => {
        // for ignore default datable sort
      },
      formatter: (cell, row, rowIndex, formatExtraData) => (
        <div>
          {row.status === STATUSES.pending && (
            <div className="text-center">
              <Badge
                color="warning"
                className="mr-1 mb-1"
              >
                Pending
              </Badge>
            </div>
          )}
          {row.status === STATUSES.processing && (
            <div className="text-center">
              <Badge
                color="primary"
                className="mr-1 mb-1"
              >
                Processing
              </Badge>
            </div>
          )}
          {row.status === STATUSES.rejected && (
            <>
              <div className="text-center">
                <Badge
                  color="danger"
                  className="mr-1 mb-1"
                >
                  Rejected
                </Badge>
              </div>
              {row.feedback && (
                // <TooltipItem id={row.id} text={row.feedback} />
                <FeedbackItem row={row} onClick={formatExtraData.onFeedbackClick} />
              )}
            </>
          )}
          {row.status === STATUSES.approved && (
            <>
              <div className="text-center">
                <Badge
                  color="success"
                  className="mr-1 mb-1"
                >
                  Approved
                </Badge>
              </div>
              {row.feedback && (
                // <TooltipItem id={row.id} text={row.feedback} />
                <FeedbackItem row={row} onClick={formatExtraData.onFeedbackClick} />
              )}
            </>
          )}
          {row.status === STATUSES.canceled && (
            <>
              <div className="text-center">
                <Badge
                  color="dark"
                  className="mr-1 mb-1"
                >
                  Canceled
                </Badge>
              </div>
              {row.feedback && (
                <FeedbackItem row={row} onClick={formatExtraData.onFeedbackClick} />
              )}
            </>
          )}
        </div>
      ),
      headerStyle: () => {
        return { width: "100px" };
      },
      formatExtraData: {
        onFeedbackClick: (row) => {
          setState({ ...state, modalFeedback: true, row });
        },
      },
    },
    
    {
      dataField: "action_col",
      text: "Actions",
      sort: false,
      formatter: (cell, row, rowIndex, formatExtraData) => (
        <div className="actions-col">
        {row.reviewer && (isBoAdmin || row.reviewer?.userId === auth.user?.userId) && READONLY_STATUSES.indexOf(row.status) === -1 && (
          <>
            <a href={`${formatExtraData.webUrl}?v=${row.video?.uid}`} target="_blank" rel="noopener noreferrer" className="mr-2" id={'preview-' + row.video?.uid}>
              <Icon.Eye className="text-dark" style={{ width: "15px" }} />
              <UncontrolledTooltip target={'preview-' + row.video?.uid}>
                Preview
              </UncontrolledTooltip>
            </a>
            <a className="border-0 mr-2" onClick={e => formatExtraData.onApprove(row, e)} id={'approve-' + row.video?.uid}>
              <Icon.Check className="text-success" style={{ width: "15px" }} />
              <UncontrolledTooltip target={'approve-' + row.video?.uid}>
                Approve
              </UncontrolledTooltip>
            </a>
            <a className="border-0" onClick={e => formatExtraData.onReject(row, e)} id={'reject-' + row.video?.uid}>
              <Icon.X className="text-dark" style={{ width: "15px" }} />
              <UncontrolledTooltip target={'reject-' + row.video?.uid}>
                Reject
              </UncontrolledTooltip>
            </a>
          </>
        )}
        </div>
      ),
      formatExtraData: {
        onReject: (row, e) => {
          setState({ ...state, modalReject: true, row });
        },
        onApprove: (row, e) => {
          setState({ ...state, modalApprove: true, row });
        },
        webUrl: config.WEB_URL,
      },
      headerStyle: () => {
        return { width: "130px" };
      },
    },
  ];

  const onSelectedChange = () => {};

  const onSizeChange = (size) => {
    setFilters({ ...filters, pageSize: size, pageNumber: 1, reload: true });
  };

  const onPageChange = (p) => {
    setFilters({ ...filters, pageNumber: p, reload: true });
  };

  const onColumnSort = (field, order) => {
    // trigger sort data via api
    let realSort = '';
    switch (field) {
      // case 'requester.userId':
      //   realSort = 'requester';
      //   break;
      // case 'reviewer.userId':
      //   realSort = 'reviewer';
      //   break;
      // case 'video.title':
      //   realSort = 'video';
      //   break;
      default:
        realSort = field;
    }
    if (realSort === filters.sortReal && order === filters.order) return;

    setFilters({
      ...filters,
      sort: field,
      sortReal: realSort,
      order,
      reload: true,
    });
  };

  const onAssignButtonClick = (row, e) => {
    setState({ ...state, modalAssign: true, row, selfAssign: false });
  }

  const onRemoveAssign = (row, e) => {
    const msg = `<p class="font-weight-bold">Following reviewer will be removed from this publishing request: </p>\
        <p class="font-italic pl-3 mb-0"><strong>${`${row.reviewer?.fullName}`}</p>\
        <p class="font-weight-bold mt-2">Do you want to continue?</p>`;
    setState({ ...state, modalConfirm: true, row, message: msg, action: REMOVE_ASSIGN_ACTION});
  }

  const onSelfAssignButtonClick = (row, e) => {
    setState({ ...state, modalAssign: true, row, selfAssign: true });
  }

  const onActionButtonClick = (action, row) => {
    switch (action.type) {
      case rowActionTypes.REJECT:
        setState({ ...state, modalReject: true, row });
        break;
      case rowActionTypes.APPROVE:
        setState({ ...state, modalApprove: true, row });
        break;
      default:
    }
  };

  const toggleModal = (modal) => {
    if (state[modal]) setState({ ...state, [modal]: !state[modal] });
    else setState({ ...state, [modal]: !state[modal], row: null });
  };

  const removeReviewer = () => {
    if (!state.row) return;

    (async () => {
      try {
        await startActionWithPromise(
          removeAssignAction,
          { id: state.row.id, successCallback, failedCallback },
          dispatch
        );
      } catch {}
    })();
  }

  const onActionConfirm = () => {
    switch (state.action) {
      case rowActionTypes.REJECT:
        
        break;
      case rowActionTypes.APPROVE:
        alert('approved');
        break;
      case REMOVE_ASSIGN_ACTION:
        removeReviewer();
        break;
      default:
    }
  };

  const successCallback = () => {
    setState({ ...state, modalConfirm: false });
    refreshDatatable();
  };

  const failedCallback = () => {};

  const refreshDatatable = () => {
    setFilters({ ...filters, reload: true });
  };

  const fetchDataSuccess = () => {}

  const fetchData = (params) => {
    (async () => {
      try {
        await startActionWithPromise(
          searchPublishingsAction,
          { params: params, successCallback: fetchDataSuccess, failedCallback },
          dispatch
        );
      } catch {}
    })();
  };

  const fetchBoUsers = () => {
    dispatch(fetchBoUsersAction.start({params: {pageSize: 1000}}));
  }

  const handleSubmitFilter = (filtersValues) => {
    setFilters({ ...filters, pageNumber: 1, ...filtersValues, reload: true });
  }

  useEffect(() => {
    if (filters.reload) {
      const params = {
        pageSize: filters.pageSize,
        pageNumber: filters.pageNumber - 1,
        video: filters.video,
        requester: filters.requester,
        reviewerId: filters.reviewerId,
        statuses: filters.statuses || [STATUSES.pending],
        orderBy: `${filters.sortReal}`,
        dir: `${filters.order.toUpperCase()}`
        // orderBy: `${filters.sortReal} ${filters.order.toUpperCase()}`,
      };

      fetchData(params);
      setFilters({ ...filters, reload: false });
    }
  }, [filters.reload]);

  useEffect(() => {
    const params = {
      pageSize: filters.pageSize,
      pageNumber: filters.pageNumber - 1,
      reviewerId: query.get('reviewer') === 'me'?auth?.user?.userId:'',
      statuses: [STATUSES.pending, STATUSES.processing]
    };
    fetchData(params);
    fetchBoUsers();

    return () => setState({});
  }, []);

  return (
    <>
      <Card className={classNames('w-100 mb-0 video-review-list', { 'search-form-hidden': !isShowSearch })}>
        <CardHeader>
          <VideoReviewFilter
            onSubmitFilter={handleSubmitFilter}
            loading={fetching}
            onToggleShow={(show) => setIsShowSearch(show) }
            activeReviewerId={query.get('reviewer') === 'me'?auth?.user?.userId:''}
          />
        </CardHeader>
        <CardBody className="pt-0 data-list">
          <>
            <DataTable
              keyField="id"
              data={dataList}
              columns={tableColumns}
              sort={filters.sort}
              order={filters.order}
              // actions={rowActions}
              onActionClick={onActionButtonClick}
              onSelectedChange={onSelectedChange}
            />
            <Row className="mt-3">
              <Col lg="3" md="4" className="d-flex">
                <PageSizeSelector
                  size={filters.pageSize}
                  onChange={onSizeChange}
                />
              </Col>
              <Col lg="9" md="8" className="d-flex justify-content-end">
                <Paging
                  totalPages={totalPages}
                  current={pageNumber}
                  show={5}
                  onSelect={onPageChange}
                />
              </Col>
            </Row>
          </>
        </CardBody>
      </Card>
      <VideoReject
        modal={state.modalReject}
        toggle={toggleModal}
        row={state.row}
        refreshData={refreshDatatable}
      />
      <VideoApprove
        modal={state.modalApprove}
        toggle={toggleModal}
        row={state.row}
        refreshData={refreshDatatable}
      />
      <VideoFeedback
        modal={state.modalFeedback}
        toggle={toggleModal}
        row={state.row}
      />
      <VideoAssignment
        modal={state.modalAssign}
        toggle={toggleModal}
        row={state.row}
        selfAssign={state.selfAssign}
        reviewers={requesterOptions}
        refreshData={refreshDatatable}
      />
      <ConfirmModal
        modal={state.modalConfirm}
        toggle={toggleModal}
        row={state.row}
        onConfirm={onActionConfirm}
        message={state.message}
      />
    </>
  );
};

export default UserList;
