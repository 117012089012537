// authentication
export const INVALID_LOGIN = "Invalid username or password";

// users
export const USER_SET_ROLES_SUCCESS = "Update user roles success.";
export const USER_ADD_ROLES_SUCCESS = "Update user roles success.";
export const USER_SUB_ROLES_SUCCESS = "Update user roles success.";

// domain
export const DOMAIN_UPDATE_PERMISSION_ERROR = "";

export const DOMAIN_PERMISSION_MESSAGES = {
  updateSuccess: "Permission is updated successfully.",
  addSuccess: "A new permission is added successfully.",
  deleteSuccess: "Permission is deleted  successfully.",
};

export const DOMAIN_ROLE_MESSAGES = {
  updateSuccess: "Role is updated successfully.",
  addSuccess: "A new role is added successfully.",
  deleteSuccess: "Role is deleted successfully.",
};

export const DOMAIN_ROLEGROUP_MESSAGES = {
  updateSuccess: "Role group is updated successfully.",
  addSuccess: "A new role group is added successfully.",
  deleteSuccess: "Role group is deleted successfully.",
};

export const BO_USER_MESSAGES = {
  updateSuccess: "BO user is updated successfully.",
  addSuccess: "BO user is added successfully.",
  deleteSuccess: "BO user is deleted successfully.",
  logoutSuccess: "BO user is forced logout successfully.",
  changePwSuccess: "Password is changed successfully.",
  changeMyPwSuccess: "Password is changed successfully.",
  resetPwSuccess: "BO user password is reseted successfully.",
  forgotPwSuccess: "A request has been received to change the password for your account.",
  revokeRolesSuccess: "All roles are deleted successfully.",
  updateUserRoleSuccess: "BO user roles are updated successfully.",
  updateProfileSuccess: "Your profile is updated successfully.",
  enableSuccess: "BO user is enabled successfully.",
  disableSuccess: "BO user is disabled successfully.",
};

export const ROLE_MESSAGES = {
  addSuccess: "Add role success.",
  deleteSuccess: "User roles are deleted successfully.",
  revokeRolesSuccess: "All roles are deleted successfully.",
  updateSuccess: "User roles are updated successfully.",
};

export const ACTION_BUTTON_TEXTS = {
  EDIT: "Edit",
  FORCE_LOGOUT: "Force logout",
  RESET_PASSWORD: "Reset password",
  SET_ROLES: "Set roles",
  REVOKE_ALL_ROLES: "Revoke all roles",
};

export const TRUSTED_USER_MESSAGES = {
  addSuccess: "User is added to trusted publishers successfully.",
  updateSuccess: "Update publisher success.",
  deleteSuccess: "User is removed from trusted publishers successfully.",
  enableSuccess: "User is enabled in Trusted Publishers successfully.",
  disableSuccess: "User is disabled in Trusted Publishers successfully.",
};

// user
export const USER_MESSAGES = {
  updateSuccess: "Update user success.",
  addSuccess: "Add user success.",
  deleteSuccess: "User is deleted successfully.",
  logoutSuccess: "User is logged out successfully.",
  changePwSuccess: "User's password is changed successfully.",
  resetPwSuccess: "User's password is reset successfully.",
  revokeRolesSuccess: "User's roles are deleted successfuly.",
  updateUserRoleSuccess: "User's roles are updated successfuly.",
  updateProfileSuccess: "Update profile success.",
  enableSuccess: "User is enabled successfully.",
  disableSuccess: "User is disabled successfully.",
  resendActivationSuccess: "Successfully started resend activation email job.",
  importSuccess: "Users are imported successfully.",
  syncUserStoragePackageSuccess: "Successfully synched the user storage package.",
};

// setting
export const SETTING_MESSAGES = {
  updateSuccess: "Setting updated successfully.",
  addSuccess: "Setting added successfully..",
  deleteSuccess: "Setting deleted successfully.",
};

// tools
export const TOOL_MESSAGES = {
  retranscodeSuccess: "Successfully started video re-transcoding job.",
  unpublishSuccess: "Unpublish video successfully.",
};

// publishings
export const REVIEW_MESSAGES = {
  assignSuccess: "Reviewer is assigned successfully.",
  selfAssignSuccess: "Publishing request is assigned successfully.",
  removeAssignSuccess: "Reviewer is removed from this publishing request successfully.",
  approveSuccess: "Publishing request is approved successfully.",
  rejectSuccess: "Publishing request is rejected successfully.",
  reviewAccountLinkMissing: "Your linked account for playing the reviewed videos is missing. You can select this account via your profile form."
};

// report
export const REPORT_MESSAGES = {
  nodata: "No data.",
  deleteOrphanedVideosSuccess: "Orphaned videos successfully cleaned up",
  deleteOrphanedVideoPartsSuccess: "Orphaned video data successfully cleaned up",
  recalculateUserStorageSuccess: "User storage successfully recalculated"
};

// videos
export const VIDEO_MESSAGES = {
  nodata: "No video found.",
  deleteSuccess: "Video is deleted successfully."
};

// orders
export const ORDER_MESSAGES = {
  addSuccess: "Order is added successfully.",
  updateSuccess: "Order is updated successfully.",
  deleteSuccess: "Order is deleted successfully.",
  updateStatus: "Order status is updated successfully.",
  recloneSuccess: "Successfully started video re-clone job."
};

// orders
export const VIDEO_FEATURED_MESSAGES = {
  resyncSuccess: "Featured videos were synced successfully.",
  pinSuccess: "Video is added to featured list successfully.",
  updateSuccess: "Featured video is updated successfully.",
  unpinSuccess: "Featured video is deleted successfully.",
  enableSuccess: "Featured video is enabled successfully.",
  disableSuccess: "Featured video is disabled successfully.",
};
