import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

import { useAuth } from "../../hooks/useAuth";
import { RESET_REDUCER } from "../../redux/constants";
import { useDispatch } from "../../helpers/react-redux-hook";
import LoginForm from "./LoginForm";
import { INVALID_LOGIN } from "../../config/messages";
import logo from "../../assets/img/fuvi.svg";

const SignIn = () => {
  const history = useHistory();
  const location = useLocation();
  const auth = useAuth();
  const dispatch = useDispatch();

  const [logining, setLogining] = useState(false);
  const [message, setMessage] = useState("");

  const { from } = location.state || { from: { pathname: "/" } };
  // if (from.pathname === '/') from.pathname = "/dashboard";

  const login = (username, password, rememberMe) => {
    setLogining(true);

    auth.signin(username, password).then(res => {
      setLogining(false);
      if (res.user) {
        if (rememberMe) {
          localStorage.setItem("username", username);
          localStorage.setItem("password", password);
          localStorage.setItem("rememberMe", rememberMe);
        } else {
          localStorage.removeItem("username");
          localStorage.removeItem("password");
          localStorage.removeItem("rememberMe");
        }
        history.replace(from);
      } else if (res.error) {
        if (res.error.status === 401) setMessage(INVALID_LOGIN);
        else if (res.error?.headers["x-message-info"]) {
          setMessage(res.error?.headers["x-message-info"]);
        }
      }
    });
  };

  // mounted hook
  React.useEffect(() => {
    dispatch({ type: RESET_REDUCER }); // reset state on login screen
  }, [dispatch]);

  return (
    <>
      

      <Card className="mt-4" style={{borderRadius: '0.5rem', boxShadow: '0 0 0.875rem 0 rgb(53 64 82 / 20%)'}}>
        <CardBody>
          <div className="text-center mt-4">
            <img
              src={logo}
              alt="Fuvi BackOffice"
              className="img-fluid"
              width="200"
            />
            <h3 className="title mt-4" style={{color: '#6E9195', fontWeight: '700'}}>Mindmastery BackOffice</h3>
          </div>
          <div className="m-sm-4">
            <LoginForm onSubmit={login} logining={logining} message={message} />
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default SignIn;
