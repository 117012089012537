import React , { useState } from "react";
import moment from "moment";
import range from "lodash/range";
import { Button } from "reactstrap";
import { getMonth, getYear } from 'date-fns';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./drp.scss";
import Ranges from "./Ranges";

import { toDateString } from "../../helpers/utils";

import PropTypes from "prop-types";

const CustomHeaderDatePicker = ({initialDate, ...rest}) => {
  const years = range(2020, getYear(new Date()) + 1, 1);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  return (
    <DatePicker
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled
      }) => (
        <div
          style={{
            margin: 10,
            display: "flex",
            justifyContent: "center"
          }}
        >
          <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
            {"<"}
          </button>
          <select
            value={getYear(date)}
            onChange={({ target: { value } }) => changeYear(value)}
          >
            {years.map(option => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <select
            value={months[getMonth(date)]}
            onChange={({ target: { value } }) =>
              changeMonth(months.indexOf(value))
            }
          >
            {months.map(option => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
            {">"}
          </button>
        </div>
      )}
      selected={initialDate}
      {...rest}
    />
  );
}

const propTypes = {
  onChange: PropTypes.func
}

const defaultProps = {
  onChange: () => {}
}

const DRP = ({initStart, initEnd, onChange, showApplyButton=true}) => {
  const start = initStart || moment().subtract(30, 'days').format('MM/DD/YYYY');
  const end = initEnd || moment().format('MM/DD/YYYY');

  const [minDate, setMinDate] = useState(new Date(start));
  const [maxDate, setMaxDate] = useState(new Date(end));
  const [filters, setFilters] = useState({
    startDate: minDate,
    endDate: maxDate
  });

  const handleApply = () => {
    if (onChange) {
      onChange(toDateString(filters.startDate), toDateString(filters.endDate));
    }
  }

  const handleStartDateChange = (date) => {
    filters.startDate = date; // do not call setStartDate(date) - it won't work properly
    setMinDate(date);

    if (!showApplyButton && onChange)
      onChange(toDateString(filters.startDate), toDateString(filters.endDate)); // trigger dates changed
  }

  const handleEndDateChange = (date) => {
    filters.endDate = date; // do not call setEndDate(date) - it won't work properly
    setMaxDate(date);

    if (!showApplyButton && onChange)
      onChange(toDateString(filters.startDate), toDateString(filters.endDate)); // trigger dates changed
  }

  const handleChangeDateRange = (start, end) => {
    let newStartDate = new Date(start);
    filters.startDate = newStartDate;  // do not call setStartDate(date) - it won't work properly
    setMinDate(newStartDate);

    let newEndDate = new Date(end);
    filters.endDate = newEndDate; // do not call setEndDate(date) - it won't work properly
    setMaxDate(newEndDate); 

    if (onChange) {
      onChange(toDateString(newStartDate), toDateString(newEndDate)); // trigger dates changed
    }
  }

  return (
    <>
      <Ranges startDate={toDateString(filters.startDate)} endDate={toDateString(filters.endDate)} onChange={handleChangeDateRange} />
      <CustomHeaderDatePicker
        initialDate={filters.startDate}
        maxDate={maxDate}
        popperPlacement="bottom-end"
        onChange={(date) => handleStartDateChange(date)}
      />
      <span style={{lineHeight: '2'}}>&nbsp;-&nbsp;</span>
      <CustomHeaderDatePicker
        className="date-end"
        initialDate={filters.endDate}
        minDate={minDate}
        popperPlacement="bottom-end"
        onChange={(date) => handleEndDateChange(date)}
      />
      
      { showApplyButton && (
        <Button color="primary" className="shadow-sm ml-2" onClick={handleApply}>
          Apply
        </Button>
      )}
      
    </>
  );
}

DRP.propTypes = propTypes;
DRP.defaultProps = defaultProps;

export default DRP;