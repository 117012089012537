import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import PropTypes from "prop-types";
import moment from "moment";

const propTypes = {
  row: PropTypes.object,
  modal: PropTypes.bool,
  toggle: PropTypes.func,
};

const VideoFeedback = (props) => {
  const { modal, toggle, row } = props || {};

  const toggleModal = () => {
    if (toggle) toggle('modalFeedback');
  }

  return (
    <Modal isOpen={modal} toggle={toggleModal} centered size="md">
      <ModalHeader>
        Publishing Request Detail
      </ModalHeader>
      <ModalBody className="">
        <div className="request-detail p-2 border bg-light rounded">
          <div className="title mb-2">
            <div className="font-weight-bold">Requester</div>
            <div className="">{row ? row.requester?.fullName : ''}</div>
          </div>
          <div className="title mb-2">
            <div className="font-weight-bold">Video</div>
            <div className="" style={{whiteSpace: 'pre-wrap', wordBreak: 'break-word'}}>
              <p className="mb-0">Title: <span>{row ? row.video?.title : ''}</span></p>
              <p className="mb-0">Subject: <span>{row ? row.video?.subject : ''}</span></p>
              <p className="mb-0">Topic: <span>{row ? row.video?.topic : ''}</span></p>
              <p className="mb-0">Version: <span>{row ? row.video?.version : ''}</span></p>
            </div>
          </div>
          <div className="title mb-2">
            <div className="font-weight-bold">Request at</div>
            <div className="">{row && row.requestDate ? moment(row.requestDate, 'MM/DD/YYYY HH:mm:ss Z').format('YYYY/MM/DD HH:mm:ss') : ''}</div>
          </div>
          <div className="title mb-2">
            <div className="font-weight-bold">Reviewer</div>
            <div className="">{row ? row.reviewer?.fullName : ''}</div>
          </div>
        </div>
        <div className="feedback-detail p-2 border rounded mt-3">
          <div className="title mb-2">
            <div className="font-weight-bold">Feedback</div>
            <div className="">{row ? row.feedback : ''}</div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggleModal}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

VideoFeedback.propTypes = propTypes;

export default VideoFeedback;
