import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import * as Icon from "react-feather";

import DataTable, { rowActionTypes } from "../../../components/DataTable";
import Paging from "../../../components/base/Paging";
import PageSizeSelector from "../../../components/base/PageSizeSelector";
import config from "../../../config/config";
import GroupUpdate from "./GroupUpdate";
import GroupDelete from "./GroupDelete";
import { roleGroupActions } from "../../../redux/reducers/domainRoleGroupReducer";
import { startActionWithPromise } from "../../../helpers/saga-promise-helpers";

const GroupList = () => {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    modalUpdate: false,
    modalDetail: false,
    modalDelete: false,
    row: { enabled: true },
  });

  const [filters, setFilters] = useState({
    pageSize: config.DEFAULT_PAGESIZE,
    pageNumber: 1,
    sort: "id",
    sortReal: "id",
    order: "asc",
    keyword: "",
    reload: false,
  });

  const groups = useSelector(state => state.domainRoleGroups.groups);
  const totalPages = useSelector(state => state.domainRoleGroups.totalPages);
  const pageNumber = useSelector(state => state.domainRoleGroups.pageNumber);
  const loading = false;

  const rowActions = [
    {
      name: "Edit",
      type: rowActionTypes.EDIT,
      classes: "text-dark",
    },
    {
      name: "Delete",
      type: rowActionTypes.DELETE,
      classes: "text-dark",
    },
  ];

  const tableColumns = [
    {
      dataField: "name",
      text: "Name",
      sort: false,
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
      onSort: (field, order) => {
        onColumnSort(field, order);
      },
      sortFunc: () => {
      },
    },
  ];

  const onSizeChange = size => {
    setFilters({ ...filters, pageSize: size, pageNumber: 1, reload: true });
  };

  const onPageChange = p => {
    setFilters({ ...filters, pageNumber: p, reload: true });
  };

  const onColumnSort = (field, order) => {
    const realSort = field === "fullname" ? "firstName" : field;
    if (realSort === filters.sortReal && order === filters.order) return;

    setFilters({
      ...filters,
      sort: field,
      sortReal: realSort,
      order,
      reload: true,
    });
  };

  const onActionBtnClick = (action, row) => {
    switch (action.type) {
      case rowActionTypes.VIEW:
        setState({ ...state, modalDetail: true, row });
        break;
      case rowActionTypes.EDIT:
        setState({ ...state, modalUpdate: true, row });
        break;
      case rowActionTypes.DELETE:
        setState({ ...state, modalDelete: true, row });
        break;
      case rowActionTypes.ENABLE:
        updatePermissionStatus(row, true);
        break;
      case rowActionTypes.DISABLE:
        updatePermissionStatus(row, false);
        break;
      default:
        break;
    }
  };

  const updatePermissionStatus = (row, status) => {
    const postData = {
      id: row.id,
      name: row.name,
      description: row.description,
      roleGroup: row.roleGroup,
      permissions: row.permissions,
      enabled: status,
    };

    (async () => {
      try {
        await startActionWithPromise(
          roleGroupActions.update,
          {
            id: row.id,
            data: postData,
            successCallback: refreshDatatable,
            failedCallback,
          },
          dispatch
        );
      } catch {}
    })();
  };

  const handleAddNew = () => {
    setState({ ...state, modalUpdate: true, row: { enabled: true } });
  };

  const toggleModal = modal => {
    if (state[modal]) setState({ ...state, [modal]: !state[modal] });
    else setState({ ...state, [modal]: !state[modal], row: { enabled: true } });
  };

  const refreshDatatable = () => {
    setFilters({ ...filters, reload: true });
  };

  const fetchData = (params) => {
    (async () => {
      try {
        await startActionWithPromise(
          roleGroupActions.fetch,
          { params, successCallback, failedCallback },
          dispatch
        );
      } catch {}
    })();
  };

  const successCallback = () => {};

  const failedCallback = () => {};

  useEffect(() => {
    if (filters.reload) {
      const params = {
        pageSize: filters.pageSize,
        pageNumber: filters.pageNumber - 1,
        orderBy: `${filters.sortReal} ${filters.order.toUpperCase()}`,
      };

      fetchData(params);
      setFilters({ ...filters, reload: false });
    }
  }, [filters.reload]);

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <Card className="w-100 mb-0">
        <CardHeader>
          <Row>
            <Col lg="6" className="d-flex">
              <div className="bulk-actions">
                <Button color="success" size="sm" onClick={handleAddNew}>
                  <Icon.Plus height="18" /> Add New
                </Button>
                <Button color="danger" size="sm" className="ml-1 d-none">
                  <Icon.Trash2 height="18" /> Bulk Delete
                </Button>
              </div>
            </Col>
            <Col lg="6" xs="6" className="d-flex justify-content-end">
              {/* <InputSearch value={filters.keyword} onSubmit={onSearchSubmit} disabled={loading} /> */}
            </Col>
          </Row>
          {/* <Button color="primary" size="sm" onClick={getSelectedUsers}>Get Selected Rows</Button> */}
        </CardHeader>
        <CardBody className="pt-0 data-list">
          {loading && (
            <div className="text-center w-100 bg-white p-4 position-static h-100">
              <Spinner />
            </div>
          )}
          {!loading && (
            <>
              <DataTable
                keyField="id"
                data={groups}
                columns={tableColumns}
                sort={filters.sort}
                order={filters.order}
                onActionClick={onActionBtnClick}
                actions={rowActions}
                hideSelectColumn={true}
              />
              <Row className="mt-3">
                <Col lg="6" className="d-flex">
                  <PageSizeSelector
                    size={filters.pageSize}
                    onChange={onSizeChange}
                  />
                </Col>
                <Col lg="6" className="d-flex justify-content-end">
                  <Paging
                    totalPages={totalPages}
                    current={pageNumber}
                    show={5}
                    onSelect={onPageChange}
                  />
                </Col>
              </Row>
            </>
          )}
        </CardBody>
      </Card>
      <GroupUpdate
        modal={state.modalUpdate}
        toggle={toggleModal}
        row={state.row}
        refreshData={refreshDatatable}
      />
      <GroupDelete
        modal={state.modalDelete}
        toggle={toggleModal}
        row={state.row}
        refreshData={refreshDatatable}
      />
    </>
  );
};

export default GroupList;
