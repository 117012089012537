import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  FormGroup,
  Label,
} from "reactstrap";

import { useForm } from "react-hook-form";
import PropTypes from "prop-types";

import {
  fetchDomainsAction,
  fetchActionsAction,
} from "../../../redux/reducers/domainReducer";
import { permissionActions } from "../../../redux/reducers/domainPermissionReducer";
import { startActionWithPromise } from "../../../helpers/saga-promise-helpers";

const propTypes = {
  row: PropTypes.object,
  modal: PropTypes.bool,
  toggle: PropTypes.func,
};

const defaultProps = {
  row: {},
  modal: 'modalUpdate',
  toggle: () => {}
}

const PermissionUpdate = props => {
  const dispatch = useDispatch();

  // selectors
  const domains = useSelector(state => state.domain.domains);
  const domainActions = useSelector(state => state.domain.actions);
  const updating = useSelector(state => state.domainPermissions.updating);

  const submitButton = useRef(null);
  const inputPermission = props.row || {};

  const {
    type: initDomain,
    action: initAction,
    enabled: initEnabled,
  } = inputPermission;

  const { register, handleSubmit } = useForm();

  const [permission, setPermission] = useState(inputPermission);
  const [pristine, setPristine] = useState(true);
  const [messages, setMessages] = useState(false);
  const [waiting, setWaiting] = useState(false);

  const [domain, setDomain] = useState(initDomain);

  const toggle = () => {
    if (props.modal) {
      // reset state when close modal
      setMessages([]);
    }
    if (props.toggle) props.toggle("modalUpdate");
  };

  const onChangeDomain = domain => {
    setDomain(domain);
    setPristine(false);
  };

  const triggerSubmitForm = () => {
    if (submitButton.current) submitButton.current.click();
  };

  const onSubmit = async data => {
    const validate_errors = [];
    if (!data.domain) {
      validate_errors.push("Please select domain type.");
    } else if (!data.action) {
      validate_errors.push("Please select action.");
    }

    if (validate_errors.length) {
      setMessages(validate_errors);
      return;
    }

    setPermission({ ...permission, type: data.domain, action: data.action });

    const params = {
      id: props.row?.id,
      data: {
        id: props.row.id,
        type: data.domain,
        action: data.action,
        enabled: data.enabled,
      },
    };

    setWaiting(true);
    try {
      if (props.row?.id) {
        await startActionWithPromise(
          permissionActions.update,
          { ...params, successCallback, failedCallback },
          dispatch
        );
      } else {
        await startActionWithPromise(
          permissionActions.create,
          {
            type: data.domain,
            action: data.action,
            enabled: data.enabled,
            successCallback,
            failedCallback,
          },
          dispatch
        );
      }
    } catch {}
    setWaiting(false);
  };

  const successCallback = () => {
    if (props.refreshData) props.refreshData();
    toggle();
  };

  const failedCallback = () => {};

  /**
   * get domains when open modal
   */
  useEffect(() => {
    if (props.modal) {
      dispatch(fetchDomainsAction.start());
    }
  }, [dispatch, props.modal]);

  /**
   * get actions when domain changed
   */
  useEffect(() => {
    if (domain) dispatch(fetchActionsAction.start(domain));
  }, [dispatch, domain]);

  /**
   * load data when open modal
   */
  useEffect(() => {
    setPermission(props.row);

    if (props.row?.id) {
      dispatch(fetchDomainsAction.start());
      dispatch(fetchActionsAction.start(props.row?.type));
    }
  }, [dispatch, props.row]);

  return (
    <Modal isOpen={props.modal} toggle={toggle} centered size="lg">
      <ModalHeader toggle={toggle}>
        {props.row?.id ? "Update Permission" : "Add Permission"}
      </ModalHeader>
      <ModalBody className="m-3">
        <form onSubmit={handleSubmit(onSubmit)} id="form-permission">
          <Row>
            <Col lg="12">
              <FormGroup>
                <FormGroup check inline className="">
                  <Label check>
                    <input
                      className="form-check-input"
                      name="enabled"
                      type="checkbox"
                      defaultChecked={initEnabled}
                      ref={register}
                      onChange={() => setPristine(false)}
                    />
                    Enabled
                  </Label>
                </FormGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <FormGroup>
                <div>
                  <Label className="font-weight-bold" for="domain">
                    Domain
                  </Label>
                </div>
                {domains &&
                  domains.map(domainType => (
                    <FormGroup
                      check
                      inline
                      className=""
                      key={`${domainType.name}-frm-group`}
                    >
                      <Label check>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="domain"
                          defaultChecked={domainType.name === initDomain}
                          onChange={() => onChangeDomain(domainType.name)}
                          value={domainType.name}
                          ref={register}
                        />
                        {domainType.text}
                      </Label>
                    </FormGroup>
                  ))}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <FormGroup>
                <div>
                  <Label className="font-weight-bold" for="domain">
                    Action
                  </Label>
                </div>
                {domainActions &&
                  domainActions.map(act => (
                    <FormGroup
                      check
                      inline
                      className=""
                      key={`${act.name}-frm-group`}
                    >
                      <Label check>
                        <input
                          className="form-check-input"
                          name="action"
                          type="radio"
                          defaultChecked={act.name === initAction}
                          value={act.name}
                          ref={register}
                          onChange={() => setPristine(false)}
                        />
                        {act.text}
                      </Label>
                    </FormGroup>
                  ))}
              </FormGroup>
            </Col>
          </Row>

          <Button
            className="d-none"
            disabled={pristine}
            type="submit"
            innerRef={submitButton}
          >
            Submit
          </Button>
        </form>
      </ModalBody>
      <ModalFooter>
        <div className="">
          {messages &&
            messages.map((error, i) => (
              <span key={`errors-${i}`} className="text-danger mb-0 mr-1">
                {error}
              </span>
            ))}
        </div>
        <Button color="secondary" disabled={waiting} onClick={toggle}>
          Cancel
        </Button>
        <Button
          className="ml-2"
          color="primary"
          disabled={pristine || updating}
          onClick={() => triggerSubmitForm(true)}
        >
          Save changes
        </Button>
      </ModalFooter>
    </Modal>
  );
};

PermissionUpdate.propTypes = propTypes;
PermissionUpdate.defaultProps = defaultProps;

export default PermissionUpdate;
