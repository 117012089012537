export const SUBSCRIPTION_STATES = {
  active: 'ACTIVE',
  inactive: 'INACTIVE',
  cancelled: "CANCELLED",
  processing: 'PROCESSING'
}

export const SUBSCRIPTION_STATE_LABELS = {
  [SUBSCRIPTION_STATES.active]: 'Active',
  [SUBSCRIPTION_STATES.inactive]: 'Inactive',
  [SUBSCRIPTION_STATES.cancelled]: 'Cancelled',
  [SUBSCRIPTION_STATES.processing]: 'Processing',
}

export const SUBSCRIPTION_STATE_COLORS = {
  [SUBSCRIPTION_STATES.active]: 'success',
  [SUBSCRIPTION_STATES.inactive]: 'warning',
  [SUBSCRIPTION_STATES.cancelled]: 'dark',
  [SUBSCRIPTION_STATES.processing]: 'info',
}