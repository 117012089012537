import { post, request } from "../helpers/apiHelper";

async function search(params) {
  return await post("videos/search", params);
}

async function deleteVideo(videoId, userId) {
  return await request(`videos/delete`, { videoId, userId }, 'delete');
}

export default {
  search,
  deleteVideo
};
