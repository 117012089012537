import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";

import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Spinner,
} from "reactstrap";

import { startActionWithPromise } from "../../helpers/saga-promise-helpers";
import { forgotPwAction } from "../../redux/reducers/bouserReducer";
import logo from "../../assets/img/fuvi.svg";

const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const ResetPassword = () => {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm();

  const [pristine, setPristine] = useState(true);
  const [isWaiting, setWaiting] = useState(false);

  const successCallback = () => {
  };

  const onSubmit = async values => {
    setWaiting(true);
    try {
      await startActionWithPromise(
        forgotPwAction,
        {
          email: values.email,
          successCallback,
          failedCallback: () => {},
        },
        dispatch
      );
    } catch (error) {}
    setWaiting(false);
  };

  return (
    <>
      <Card>
        <CardBody>
          <div className="text-center mt-4">
            <img
              src={logo}
              alt="Fuvi BackOffice"
              className="img-fluid"
              width="200"
            />
            <h3 className="title mt-4" style={{color: '#6E9195', fontWeight: '700'}}>Mindmastery BackOffice</h3>
          </div>
          <div className="mt-4">
            {/* <h1 className="h2">Reset password</h1> */}
            <p className="lead"></p>
          </div>
          <div className="m-sm-4">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <FormGroup>
                <Label>Enter email to reset your password</Label>
                <input
                  className="form-control"
                  type="text"
                  name="email"
                  id="email"
                  placeholder=""
                  defaultValue={''}
                  onChange={() => setPristine(false)}
                  ref={register({
                    required: true,
                    pattern: pattern,
                  })}
                />
                {errors.email && (
                  <p className="text-danger">
                    {errors.email?.type === "required"
                      ? "Email is required."
                      : ""}
                    {errors.email?.type === "pattern"
                      ? "Invalid email address."
                      : ""}
                  </p>
                )}
              </FormGroup>
              <div className="text-center mt-3">
                <Button color="primary" size="lg" disabled={pristine || isWaiting}>
                  Reset password
                  {isWaiting && <Spinner color="secondary" size="sm" className="ml-2" />}
                </Button>
              </div>
              <div className="text-center mt-2">
                <Link to="/auth/sign-in" className="text-dark">« Back to Login</Link>
              </div>
            </Form>
          </div>
        </CardBody>
      </Card>
    </>
  );
}

export default ResetPassword;
