import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Spinner
} from "reactstrap";
import classNames from "classnames";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import LoadingOverlay from 'react-loading-overlay-ts';

import DataTable, { rowActionTypes } from "../../components/DataTable";
import PageSizeSelector from "../../components/base/PageSizeSelector";
import Paging from "../../components/base/Paging";
import config from "../../config/config";
import useMergeState from '../../hooks/mergeState';
import { useAuth } from "../../hooks/useAuth";

// import UserStorageDetail from "./UserStorageDetail";
import FormFilter from "./FormFilter";
import ConfirmModal from "../../components/base/ConfirmModal";
import InfoModal from "../../components/base/InfoModal";

import {
  fetchUsersAsyncAction,
  fetchUsersWithStorageAction
} from "../../redux/reducers/userReducer";
import { 
  usersStorageAction,
  getUserOrphanedVideosAction,
  getUserOrphanedVideoPartsAction,
  deleteUserOrphanedVideosAction,
  deleteUserOrphanedVideoPartsAction,
  recalculateUserStorageAction
} from "../../redux/reducers/reportReducer";
import { startActionWithPromise } from "../../helpers/saga-promise-helpers";
import { formatBytes } from "../../helpers/utils";
import "./index.scss";
import { getTableColumns } from "./TableColumns";

const UsersStorage = () => {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    modalConfirm: false,
    modalDetail: false,
    modalInfo: false,
    action: '',
    message: '',
    row: null,
  });

  const defaultFilters = {
    pageSize: config.DEFAULT_PAGESIZE,
    pageNumber: 1,
    keywords: '',
    enabled: '',
    userId: '',
    sort: '',
    order: '',
    realSort: '',
    search: false,
    reload: false,
  };
  const [filters, setFilters] = useMergeState(defaultFilters);
  const [isActive, setActive] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [isFormClear, clearForm] = useState(false);
  const [queryInterval, setQueryInterval] = useState(null);
  const [deleteInterval, setDeleteInterval] = useState(null);

  const auth = useAuth();
  const isAuthorized = auth.roles &&
    (auth.roles.indexOf('SYSTEM_ADMINS') !== -1 ||
    auth.roles.indexOf('SYSTEM_OPERATORS') !== -1);
  const fetching = false; // useSelector(state => state.user?.fetching);
  // const isBoAdmin = useSelector(state => state.bouser.isBoAdmin);
  // const users = useSelector(state => state.user?.users) || [];
  // const totalPages = useSelector(state => state.user.totalPages);
  // const pageNumber = useSelector(state => state.user.pageNumber);

  const [users, setUsers] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const isDeleting = useSelector(state => state.report.deleting);

  const handleColumnSort = (field, order) => {
    const realSort = field === 'used' ? 'usedSpace' : field;
    if (realSort === filters.realSort && order === filters.order) return;
    setFilters({
      ...filters,
      sort: field,
      order,
      realSort: realSort,
      search: false,
      reload: true,
    });
    clearForm(true);
  };

  const tableColumns = getTableColumns(handleColumnSort, (row) => setState({ ...state, modalDetail: true, row }));

  const rowActions = [
    {
      name: "Validate user videos",
      type: rowActionTypes.VALIDATE_VIDEOS,
      classes: "text-dark",
    },
    {
      name: "Clean up user orphaned data",
      type: rowActionTypes.CLEANUP_ORPHANED_VIDEO_PARTS,
      classes: "text-dark",
    },
    {
      name: "Recalculate user storage",
      type: rowActionTypes.RECALCULATE_STORAGE,
      classes: "text-dark",
    }
  ];

  const onActionButtonClick = async (action, row) => {
    let message = "";
    switch (action.type) {
      case rowActionTypes.VALIDATE_VIDEOS:
        setActive(true);
        const userId = row?.userId;
        const userOrphanedVideos = await startActionWithPromise(
          getUserOrphanedVideosAction,
          { params: {userId}, successCallback: () => {}, failedCallback: () => {} },
          dispatch
        );
        setActive(false);
        if (userOrphanedVideos.numberOfVideos === 0) {
          message = `<p class="font-weight-bold"> The following user has no orphaned videos:</p>\
            <p class="font-italic">"${`${row.fullname} / ${row.email}`}"</p>`;
          setState({
            ...state,
            modalInfo: true,
            row,
            message,
            action: rowActionTypes.VALIDATE_VIDEOS,
          });
        } else {
          message = `<p class="font-weight-bold">The orphaned videos of the following user will be cleaned up:</p>\
            <p class="font-italic">"${`${row.fullname} / ${row.email}`}"</p>\
            <p class="font-italic">Total cleanup size: ${formatBytes(userOrphanedVideos.videoFoldersSize)} (${userOrphanedVideos.numberOfVideos} videos)</p>\
            <p class="font-weight-bold">Do you want to continue?</p>`;
          setState({
            ...state,
            modalConfirm: true,
            row,
            message,
            action: rowActionTypes.VALIDATE_VIDEOS,
          });
        }
        break;

      case rowActionTypes.CLEANUP_ORPHANED_VIDEO_PARTS:
        message = `<p>The storage of the following user will be scanned for orphaned video data:</p>\
          <p class="font-italic">"${`${row.fullname} / ${row.email}`}"</p>\
          <p>This process can take up to a few minutes or more depending on the total user's storage size.</p>\
          <p class="font-weight-bold">Do you want to continue?</p>`;
          setState({
            ...state,
            modalConfirm: true,
            row,
            message,
            action: "GET_USER_ORPHANED_STORAGE_ACTION",
          });
        break;

      case rowActionTypes.RECALCULATE_STORAGE:
        const userRecalculatedStorage = await startActionWithPromise(
          recalculateUserStorageAction,
          { userId: row?.userId, successCallback: () => {}, failedCallback: () => {} },
          dispatch
        );
        if (userRecalculatedStorage) {
          const userList = users.map(user => {
            if (user.userId === userRecalculatedStorage.userId) {
              return {
                ...user,
                ...userRecalculatedStorage
              }
            } else return user;
          });
          setUsers(userList);
        }
        break;

      default:
    }
  };

  const onActionConfirm = async () => {
    switch (state.action) {
      case rowActionTypes.VALIDATE_VIDEOS:
        try {
          await startActionWithPromise(
            deleteUserOrphanedVideosAction,
            { userId: state.row?.userId,
              successCallback: () => {
                // close confirm modal and reload the list
                setState({ ...state, modalConfirm: false });
                refreshDatatable();
              },
              failedCallback: () => {} },
            dispatch
          );
        } catch (error) {
          console.log('delete orphaned videos error:', error);
        }
        break;

      case "GET_USER_ORPHANED_STORAGE_ACTION":
        let queryVideoPartsInterval = null;
        const queryVideoParts = async () => {
          const userId = state.row?.userId;
          const userOrphanedVideoParts = await startActionWithPromise(
            getUserOrphanedVideoPartsAction,
            { params: {userId}, successCallback: () => {}, failedCallback: () => {} },
            dispatch
          );
          if (userOrphanedVideoParts.status === "COMPLETED" && userOrphanedVideoParts.response.userId) {
            let message = "";
            const videoIds = userOrphanedVideoParts.response.videoIds ? userOrphanedVideoParts.response.videoIds.length : 0;
            const privateVideoParts = Object.keys(userOrphanedVideoParts.response.privateVideoParts).length;
            const privateVideoPartVersions = Object.keys(userOrphanedVideoParts.response.privateVideoPartVersions).length;
            const publicVideoParts = Object.keys(userOrphanedVideoParts.response.publicVideoParts).length;
            setActive(false);
            if (queryVideoPartsInterval) clearInterval(queryVideoPartsInterval);
            if (videoIds + privateVideoParts + privateVideoPartVersions + publicVideoParts == 0) {
              message = `<p class="font-weight-bold"> The following user has no orphaned video data:</p>\
                <p class="font-italic">"${`${state.row.fullname} / ${state.row.email}`}"</p>`;
              setState({
                ...state,
                modalInfo: true,
                modalConfirm: false,
                message,
                action: rowActionTypes.CLEANUP_ORPHANED_VIDEO_PARTS,
              });
            } else {
              message = `<p>The orphaned video data of user<br/><span class="font-italic">"${`${state.row.fullname} / ${state.row.email}`}"</span><br/>\
                will be cleaned up with details as follows:</p>\
                <ul>
                  <li>Number of videos:  <b>${videoIds}</b></li>\
                  <li>Number of private video parts:  <b>${privateVideoParts}</b></li>\
                  <li>Number of private video part versions:  <b>${privateVideoPartVersions}</b></li>\
                  <li>Number of public video parts:  <b>${publicVideoParts}</b></li>\
                </ul>
                <p class="font-weight-bold">Do you want to continue?</p>`;
              setState({
                ...state,
                modalConfirm: true,
                message,
                action: rowActionTypes.CLEANUP_ORPHANED_VIDEO_PARTS,
              });
            }
          } else return;
        };

        setState({ ...state, modalConfirm: false });
        setActive(true);

        queryVideoParts();
        queryVideoPartsInterval = setInterval(() => {
          queryVideoParts();
        }, 20000);
        setQueryInterval(queryVideoPartsInterval);
        break;

      case rowActionTypes.CLEANUP_ORPHANED_VIDEO_PARTS:
        if (queryInterval) clearInterval(queryInterval);
  
        const deleteVideoParts = async () => {
          await startActionWithPromise(
            deleteUserOrphanedVideoPartsAction,
            { userId: state.row?.userId, successCallback: () => {}, failedCallback: () => {} },
            dispatch
          );
        };

        deleteVideoParts();
        const deleteVideoPartsInterval = setInterval(() => {
          deleteVideoParts();
        }, 20000);
        setDeleteInterval(deleteVideoPartsInterval);
        break;

      default:
    }
  };

  const toggleModal = (modal) => {
    if (state[modal]) {
      if (state.action === rowActionTypes.CLEANUP_ORPHANED_VIDEO_PARTS) {
        if (isDeleting) return; // deny closing Confirm modal if deleting is in progress
        if (queryInterval) clearInterval(queryInterval);
        if (deleteInterval) clearInterval(deleteInterval);
      }
      setState({ ...state, [modal]: !state[modal] });
    } else setState({ ...state, [modal]: !state[modal], row: null });
  };

  const refreshDatatable = () => {
    setFilters({ ...filters, reload: true });
  };

  const mergeFilters = (mergedValues) => {
    setFilters({ ...filters, pageNumber: 1, ...mergedValues});
  }

  const fetchUsers = async () => {
    try {
      setWaiting(true);
      if (filters.search) {
        let params = {
          pageSize: filters.pageSize,
          pageNumber: filters.pageNumber - 1,
          userId: filters.userId,
        };
        if (!filters.userId && filters.keywords)
          params.keywords = filters.keywords;
        if (filters.enabled !== '')
          params.enabled = filters.enabled;
        if (filters.deleted)
          params.deleted = filters.deleted;
        if (filters.realSort && filters.realSort !== 'usedSpace' && filters.order)
          params.sort = {
            dir: filters.order.toUpperCase(),
            fieldName: filters.realSort
          };

        const resp = await startActionWithPromise(
          fetchUsersAsyncAction,
          {params, successCallback: () => {}, failedCallback: (err) => {console.log(err);}},
          dispatch
        );

        if (resp) {
          let userList = resp.content || [];

          // get users storage
          const userIds = userList.map(x => x.userId) || [];
          if (userIds.length > 0) {
            const resp2 = await startActionWithPromise(
              usersStorageAction,
              {params: {userIds}, successCallback: () => {}, failedCallback: () => {}},
              dispatch
            );
            if (resp2) {
              userList = userList.map(user => {
                const userStorage = resp2.find(x => x.userId === user.userId);
                return {
                  ...user,
                  ...userStorage
                }
              });
            }
          }
          setUsers(userList);
          setTotalPages(Math.ceil(resp.total / resp.pageSize) || 0);
          setPageNumber(resp.pageNumber + 1 || 1);
        }
      } else {
        const params = {
          pageSize: filters.pageSize,
          pageNumber: filters.pageNumber - 1,
        }
        if (filters.realSort === 'usedSpace')
          params.orderBy = filters.realSort + " " + filters.order.toUpperCase();

        const resp = await startActionWithPromise(
          fetchUsersWithStorageAction,
          {params, successCallback: () => {}, failedCallback: (err) => {console.log(err);}},
          dispatch
        );

        if(resp) {
          const primaryList = filters.realSort === 'usedSpace' ? resp.storages : resp.users;
          const secondaryList = filters.realSort === 'usedSpace' ? resp.users : resp.storages;
          const userList = primaryList.content.map(user => {
            const userProps = secondaryList.content.find(item => item.userId === user.userId);
            return {
              ...user,
              ...userProps
            };
          });
          setUsers(userList);
          setTotalPages(Math.ceil(primaryList.total / primaryList.pageSize) || 0);
          setPageNumber(primaryList.pageNumber + 1 || 1);
        }
      }
    } catch {}
    setWaiting(false);
  };

  useEffect(() => {
    if (filters.reload) {
      fetchUsers();
      setFilters({ ...filters, reload: false });
      clearForm(false);
    }
  }, [filters.reload]);

  useEffect(() => {
    if (!isDeleting) {
      if (deleteInterval) clearInterval(deleteInterval);
      setState({ ...state, modalConfirm: false });
    }
  }, [isDeleting]);

  useEffect(() => {
    fetchUsers();
    return () => {
      if (queryInterval) clearInterval(queryInterval);
      if (deleteInterval) clearInterval(deleteInterval);
      setQueryInterval(null);
      setDeleteInterval(null);
    }
  }, []);

  return (
    <Container fluid className="p-0">
      <h1 className="page-title">Users storage</h1>
      <Row>
        <Col lg="12" className="d-flex">
        <>
          <Card className={classNames('w-100 mb-0 datatable-wrap')}>
            <CardHeader className="">
              <FormFilter
                loading={fetching}
                mergeFilters={mergeFilters}
                clearForm={isFormClear}
              />
            </CardHeader>
            <CardBody className="pt-0 data-list">
              <LoadingOverlay
                active={isActive}
                spinner={<Spinner />}
                styles={{
                  overlay: (base) => ({
                    ...base,
                    background: 'rgba(0, 0, 0, 0.3)'
                  })
                }}
                text=''
              >
              { waiting ? (
                <div className="loading-content text-center p-4 border-top">
                  <Spinner size="sm" />
                </div>
              ) : (
                <>
                  <DataTable
                    keyField="userId"
                    data={users}
                    columns={tableColumns}
                    actions={isAuthorized ? rowActions : []}
                    sort={filters.sort}
                    order={filters.order}
                    onActionClick={onActionButtonClick}
                    hideSelectColumn={true}
                  />
                  <Row className="mt-3">
                    <Col lg="3" md="4" className="d-flex">
                      <PageSizeSelector
                        size={filters.pageSize}
                        onChange={(size) => setFilters({ ...filters, pageSize: size, pageNumber: 1, reload: true })} />
                    </Col>
                    <Col lg="9" md="8" className="d-flex justify-content-end">
                      <Paging
                        totalPages={totalPages}
                        current={pageNumber}
                        show={5}
                        onSelect={(p) => setFilters({ ...filters, pageNumber: p, reload: true })} />
                    </Col>
                  </Row>
                </>
              )}
              </LoadingOverlay>
            </CardBody>
          </Card>
          {/* {state.row && (
            <UserStorageDetail modal={state.modalDetail} toggle={toggleModal} order={state.row} refreshData={refreshDatatable} />
          )} */}
          <ConfirmModal
            modal={state.modalConfirm}
            toggle={toggleModal}
            row={state.row}
            message={state.message}
            onConfirm={onActionConfirm}
          />
          <InfoModal
            modal={state.modalInfo}
            toggle={toggleModal}
            row={state.row}
            message={state.message}
          />
        </>
        </Col>
      </Row>
    </Container>
  );
};

export default UsersStorage;
