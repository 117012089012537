import {
  createReducer,
  createActionCRUDResources,
} from "redux-waiters";
import { RESET_REDUCER } from "../constants";

export const logActions = createActionCRUDResources("logs");

const initialState = {
  fetching: false,
  list: [],
  total: 0,
  totalPages: 0,
  pageNumber: 1,
  error: {},
};

export default createReducer(
  {
    [logActions.fetch.start]: state => {
      return { ...state, fetching: true };
    },
    [logActions.fetch.success]: (state, action) => {
      return {
        ...state,
        list: action.data?.content,
        pageNumber: action.data?.pageNumber + 1,
        total: action.data?.total,
        totalPages: Math.ceil(action.data?.total / action.data?.pageSize),
        fetching: false,
      };
    },
    [logActions.fetch.error]: state => {
      return { ...state, fetching: false };
    },

    [RESET_REDUCER]: () => initialState,
  },
  initialState
);
