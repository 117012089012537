import React, { useState, useContext, createContext } from "react";
import jwt_decode from "jwt-decode";
import userServices from "../services/userService";

const authContext = createContext();

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export function useAuth() {
  return useContext(authContext);
}

function useProvideAuth() {
  const localUser = JSON.parse(localStorage.getItem("user"));
  const isLocked = JSON.parse(localStorage.getItem("isLocked") ? true : false);
  const userRoles = localStorage.getItem("roles");

  const [user, setUser] = useState(localUser);
  const [locked, setLocked] = useState(isLocked);
  const [roles, setRoles] = useState(userRoles ? JSON.parse(userRoles) : []);

  const signin = async (username, password) => {
    const { data: user, error } = await userServices.login(username, password);
    if (user) {
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("last_username", username);
      localStorage.removeItem("isLocked");
      // get and store user's roles from access token
      const jwtPayload = jwt_decode(user.access_token);
      localStorage.setItem("roles", JSON.stringify(jwtPayload.resource_access["MM-MGMT"].roles));
      setRoles(jwtPayload.resource_access["MM-MGMT"].roles);
    }

    setUser(user);
    return { user, error };
  };

  const signout = cb => {
    localStorage.removeItem("user");
    localStorage.removeItem("isLocked");
    localStorage.removeItem("roles");
    setUser(false);
    setLocked(false);
    setRoles([]);
    if (cb) cb();
  };

  const getLocked = cb => {
    localStorage.setItem("isLocked", "1");
    if (cb) cb();
  }

  return {
    user,
    locked,
    roles,
    signin,
    signout,
    getLocked,
  };
}
