import React from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import PropTypes from "prop-types";

import { userTrustedAction } from "../../redux/reducers/userTrustedReducer";
import { startActionWithPromise } from "../../helpers/saga-promise-helpers";
import { useWaitingStatus } from "../../hooks/useWaiter";

const propTypes = {
  row: PropTypes.object,
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  refreshData: PropTypes.func,
};

const defaultProps = {
  row: {},
  modal: false,
  toggle: () => {},
  refreshData: () => {},
};

const UserRemove = props => {
  const dispatch = useDispatch();
  const isWaiting = useWaitingStatus(userTrustedAction.delete.id);

  const { modal, row, refreshData } = props;

  const toggle = () => {
    if (props.toggle) props.toggle("modalDelete");
  };

  const successCallback = () => {
    if (refreshData) refreshData();
    toggle();
  };

  const failedCallback = () => {};

  const handleConfirm = async () => {
    try {
      await startActionWithPromise(
        userTrustedAction.delete,
        { id: row.userProfile?.userId, successCallback, failedCallback },
        dispatch
      );
    } catch {}
  };

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      centered
      size="sm"
      className="comfirmation-modal"
    >
      <ModalHeader toggle={toggle}>Confirmation</ModalHeader>
      <ModalBody className="m-3">
        <div>
          <p className="font-weight-bold">
            Following user will be deleted from Trusted Publishers:
          </p>
          <p className="font-italic">
            &quot;
            {`${row && row.userProfile?.fullname} / ${
              row && row.userProfile?.email
            }`}
            &quot;
          </p>
          <p className="font-weight-bold">Do you want to continue?</p>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" disabled={isWaiting} onClick={toggle}>
          No
        </Button>
        <Button
          className="ml-2"
          color="primary"
          disabled={isWaiting}
          onClick={() => handleConfirm(true)}
        >
          {isWaiting && (
            <>
              <Spinner color="secondary" size="sm" className="ml-2" />
              &nbsp;
            </>
          )}
          Yes
        </Button>
      </ModalFooter>
    </Modal>
  );
};

UserRemove.propTypes = propTypes;
UserRemove.defaultProps = defaultProps;

export default React.memo(UserRemove);
