import React from "react";
import _ from "lodash";
import classNanes from "classnames";

import { getInitials } from "../../helpers/utils";
import { StyledAvatar } from "./Styles";

const Avatar = ({image, fullname, width, height, className}) => {
  let colors = ['rgb(234, 231, 248)', 'rgb(250, 227, 205)', 'rgb(71, 184, 129)', 'rgb(221, 235, 247)', 'rgb(212, 238, 226)', 'rgb(250, 226, 226)'];
  let color = colors[ _.random(0, colors.length - 1)];
  let initial = getInitials(fullname);
  width = width || 40;
  height = height || 40;

  return (
    <StyledAvatar
      className={classNanes('rounded-circle border', className)}
      image={image}
      width={width}
      height={height}
      color={color}
    >
      {/* {!image && <span className="font-weight-bold">{initial}</span>} */} 
    </StyledAvatar>
  );
}

export default Avatar;