import { takeLatest, call, put } from "redux-saga/effects";

import logServices from "../../services/logService";
import { alertTypes } from "../constants";
import {
  logActions
} from "../reducers/logReducer";
import { sagaPromise } from "../../helpers/saga-promise-helpers";

function* fetchWorker(action) {
  try {
    const { data, error } = yield call(
      logServices.fetch,
      action.payload?.params
    );
    if (error) {
      yield put(logActions.fetch.error(error));
    } else {
      yield put(logActions.fetch.success({ ...action, data }));
    }
  } catch (err) {
    console.log(err);
    yield put(logActions.fetch.error(err));
  }
  // const { data, error } = yield call(
  //   logServices.search,
  //   action.payload.params
  // );
  // if (error) {
  //   if (error.headers["x-message-info"]) {
  //     throw new Error(error.headers["x-message-info"]);
  //   }
  // } else {
  //   yield put(searchVideoAction.success({ ...action, data }));
  //   return data;
  // }

  // return false;
}

function* deleteLogWorker(action) {
  const { data, error } = yield call(
    logServices.deleteLog,
    action.payload.id,
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      yield put({
        type: alertTypes.ERROR,
        message: error.headers["x-message-info"],
      });
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    return data;
  }

  return false;
}

function* reviewSaga() {
  // yield takeLatest(searchVideoAction.start, searchWorker);

  yield takeLatest(
    logActions.fetch.start,
    logActions.fetch.waiterActionForSaga(sagaPromise(fetchWorker))
  );

  yield takeLatest(
    logActions.delete.start,
    logActions.delete.waiterActionForSaga(sagaPromise(deleteLogWorker))
  );
}

export default reviewSaga;
