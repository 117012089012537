import React from "react";
import { Container, Row, Col } from "reactstrap";
import UserList from "./UserList";

const Users = () => {
  return (
    <Container fluid className="h-100 d-flex flex-column p-0">
      <h1 className="page-title">Users</h1>
      <Row className="h-100 overflow-auto">
        <Col lg="12" className="h-100 d-flex">
          <UserList />
        </Col>
      </Row>
    </Container>
  );
};

export default Users;
