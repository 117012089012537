import React from "react";
import moment from "moment";
import { UncontrolledTooltip, Badge } from "reactstrap";
import * as Icon from "react-feather";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";

import { TRUE_FALSE_LABELS, TRUE_FALSE_COLORS } from "./constants";

export const rowActionTypes = {
  REFRESH_ACCOUNT: "REFRESH_ACCOUNT",
  REMOVE_ACCOUNT: "REMOVE_ACCOUNT"
}

export const getTableColumns = (isBoAdmin, handleUpdate, handleOption) => {
  
  return [
    
    {
      dataField: "listed",
      text: "Listed",
      formatter: (cell, row) => (
        <div className="">
          <Badge
            color={TRUE_FALSE_COLORS[row.listed]}
            className="mr-1 mb-1"
          >
            {TRUE_FALSE_LABELS[row.listed]}
          </Badge>
        </div>
      ),
      headerStyle: () => {
        return { textAlign: "center", width: "2px" }; // , transform: "rotate(90deg)"
      },
      style: () => {
        return { textAlign: "center" }; // , transform: "rotate(90deg)"
      },
      classes: 'table-bordered',
      headerClasses: 'table-bordered',
    },
    {
      dataField: "headline",
      text: "Headline",
      formatter: (cell, row) => (
        <div>{row.headline}</div>
      ),
      headerStyle: () => {
        return { textAlign: "center", width: "2px" }; // , transform: "rotate(90deg)"
      },
      style: () => {
        return { textAlign: "center" }; // , transform: "rotate(90deg)"
      },
      classes: 'table-bordered',
      headerClasses: 'table-bordered',
    },
    {
      dataField: "billingGroup",
      text: "Billing Group",
      formatter: (cell, row) => (
        <div>{row.billingGroup}</div>
      ),
      headerStyle: () => {
        return { textAlign: "center", width: "2px" }; // , transform: "rotate(90deg)"
      },
      style: () => {
        return { textAlign: "center" }; // , transform: "rotate(90deg)"
      },
      classes: 'table-bordered',
      headerClasses: 'table-bordered',
    },
    {
      dataField: "planGroup",
      text: "Plan Group",
      //editable: true,
      //editor: {type: String},
      formatter: (cell, row) => (
        <div>{row.planGroup}</div>
      ),
      headerStyle: () => {
        return {textAlign: "center", width: 5, margin: '1em',color: 'red',}; // height: '230px', transform: "rotate(90deg)"
      },
      style: () => {
        return {textAlign: "center", color: 'red',}; // height: '150px', transform: "rotate(90deg)"
      },
      classes: 'table-bordered',
      headerClasses: 'table-bordered',
    },
    {
      dataField: "listingOrderNumber",
      text: "Listing Order",
      formatter: (cell, row) => (
        <div>{row.listingOrderNumber}</div>
      ),
      headerStyle: () => {
        return { textAlign: "center", width: "2px" }; // , transform: "rotate(90deg)"
      },
      style: () => {
        return { textAlign: "center" }; // , transform: "rotate(90deg)"
      },
      classes: 'table-bordered',
      headerClasses: 'table-bordered',
    },
    {
      dataField: "displayPrice",
      text: "Display Price",
      sort: false,
      formatter: (cell, row) => (
        <div>{row.displayPrice}</div>
      ),
      headerStyle: () => {
        return { textAlign: "center", width: "2px" }; // , transform: "rotate(90deg)"
      },
      style: () => {
        return { textAlign: "center" }; // , transform: "rotate(90deg)"
      },
      classes: 'table-bordered',
      headerClasses: 'table-bordered',
    },
    {
      dataField: "billingPeriod",
      text: "Billing Period",
      sort: false,
      formatter: (cell, row) => (
        <div>{row.billingPeriod}</div>
      ),
      headerStyle: () => {
        return { textAlign: "center", width: "2px" }; // , transform: "rotate(90deg)"
      },
      style: () => {
        return { textAlign: "center" }; // , transform: "rotate(90deg)"
      },
      classes: 'table-bordered',
      headerClasses: 'table-bordered',
    },
    {
      dataField: "paymentMethodRequired",
      text: "Payment Method Required",
      formatter: (cell, row) => (
        <div className="">
          <Badge
            color={TRUE_FALSE_COLORS[row.paymentMethodRequired]}
            className="mr-1 mb-1"
          >
            {TRUE_FALSE_LABELS[row.paymentMethodRequired]}
          </Badge>
        </div>
      ),
      headerStyle: () => {
        return { textAlign: "center", width: "2px" }; // , transform: "rotate(90deg)"
      },
      style: () => {
        return { textAlign: "center" }; // , transform: "rotate(90deg)"
      },
      classes: 'table-bordered',
      headerClasses: 'table-bordered',
    },
    {
      dataField: "planName",
      text: "Plan Name",
      sort: false,
      formatter: (cell, row) => (
        <div><a href="#" className="mr-2" onClick={e => handleUpdate(row, e)}>{row.planName}</a></div>
      ),
      headerStyle: () => {
        return { textAlign: "center", width: "2px" }; // , transform: "rotate(90deg)"
      },
      style: () => {
        return { textAlign: "center" }; // , transform: "rotate(90deg)"
      },
      classes: 'table-bordered',
      headerClasses: 'table-bordered',
    },
    {
      dataField: "kbPlanName",
      text: "KillBill Plan Name",
      sort: false,
      formatter: (cell, row) => (
        <div>{row.kbPlanName}</div>
      ),
      headerStyle: () => {
        return { textAlign: "center", width: "2px" }; // , transform: "rotate(90deg)"
      },
      style: () => {
        return { textAlign: "center" }; // , transform: "rotate(90deg)"
      },
      classes: 'table-bordered',
      headerClasses: 'table-bordered',
    },
    {
      dataField: "description",
      text: "Description",
      sort: false,
      formatter: (cell, row) => (
        <span className="font-weight-bold" title={row?.description}>{_.truncate(row?.description, {'length': 30,})}</span>
      ),
      headerStyle: () => {
        return { textAlign: "center", width: "2px" }; // , transform: "rotate(90deg)"
      },
      style: () => {
        return { textAlign: "center" }; // , transform: "rotate(90deg)"
      },
      classes: 'table-bordered',
      headerClasses: 'table-bordered',
    },
    {
      dataField: "currency",
      text: "Currency",
      sort: false,
      formatter: (cell, row) => (
        <div>{row.currency}</div>
      ),
      headerStyle: () => {
        return { textAlign: "center", width: "2px" }; // , transform: "rotate(90deg)"
      },
      style: () => {
        return { textAlign: "center" }; // , transform: "rotate(90deg)"
      },
      classes: 'table-bordered',
      headerClasses: 'table-bordered',
    },
    {
      dataField: "trialPeriod",
      text: "Trial Period",
      sort: false,
      formatter: (cell, row) => (
        <div>{row.trialTimeUnit ? row.trialPeriod + ' ' + row.trialTimeUnit : 'N/A'}</div>
      ),
      headerStyle: () => {
        return { textAlign: "center", width: "2px" }; // , transform: "rotate(90deg)"
      },
      style: () => {
        return { textAlign: "center" }; // , transform: "rotate(90deg)"
      },
      classes: 'table-bordered',
      headerClasses: 'table-bordered',
    },
    {
      dataField: "recurringPeriod",
      text: "Recurring Period",
      sort: false,
      formatter: (cell, row) => (
        <div>{row.recurringTimeUnit ? '$' + row.recurringPrice + (row.recurringTimeUnit == 'UNLIMITED' ? '' : ' every ' + row.recurringPeriod + ' ' + row.recurringTimeUnit) : 'N/A'}</div>
      ),
      headerStyle: () => {
        return { textAlign: "center", width: "2px" }; // , transform: "rotate(90deg)"
      },
      style: () => {
        return { textAlign: "center" }; // , transform: "rotate(90deg)"
      },
      classes: 'table-bordered',
      headerClasses: 'table-bordered',
    },
    {
      dataField: "fixedPeriod",
      text: "Fixed Period",
      sort: false,
      formatter: (cell, row) => (
        <div>{row.fixedTimeUnit ? '$' + row.fixedPrice + (row.fixedTimeUnit == 'UNLIMITED' ? '' : ' every ' + row.fixedPeriod + ' ' + row.fixedTimeUnit) : 'N/A'}</div>
      ),
      headerStyle: () => {
        return { textAlign: "center", width: "2px" }; // , transform: "rotate(90deg)"
      },
      style: () => {
        return { textAlign: "center" }; // , transform: "rotate(90deg)"
      },
      classes: 'table-bordered',
      headerClasses: 'table-bordered',
    },
    {
      dataField: "active",
      text: "Active",
      formatter: (cell, row) => (
        <div className="">
          <Badge
            color={TRUE_FALSE_COLORS[row.active]}
            className="mr-1 mb-1"
          >
            {TRUE_FALSE_LABELS[row.active]}
          </Badge>
        </div>
      ),
      headerStyle: () => {
        return { textAlign: "center", width: "2px" }; // , transform: "rotate(90deg)"
      },
      style: () => {
        return { textAlign: "center" }; // , transform: "rotate(90deg)"
      },
      classes: 'table-bordered',
      headerClasses: 'table-bordered',
    },
    {
      dataField: "options",
      text: "Options",
      sort: false,
      formatter: (cell, row) => (
        <div>
          <a href="#" className="mr-2" onClick={e => handleOption(row, e)} id={'option-' + row.id}>
            show Options...
          </a>
        </div>
      ),
      headerStyle: () => {
        return { width: "2px" }; // , transform: "rotate(90deg)"
      },
      classes: 'table-bordered',
      headerClasses: 'table-bordered',
    },
    
  ];
}