import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Spinner
} from "reactstrap";
import moment from "moment";
import classNames from "classnames";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";

import DataTable from "../../components/DataTable";
import PageSizeSelector from "../../components/base/PageSizeSelector";
import Paging from "../../components/base/Paging";
import config from "../../config/config";
import useMergeState from '../../hooks/mergeState';
import { useAuth } from "../../hooks/useAuth";
import { toDateString } from "../../helpers/utils";

import FormFilter from "./FormFilter";
import ConfirmModal from "../../components/base/ConfirmModal";
import InfoModal from "../../components/base/InfoModal";

import { searchMonthlyRevenueActions, recalculateMonthlyRevenuesAction, transferRevenueAction, transferMonthlyRevenuesAction, sendConnectedAccountSetupRequestAction } from "../../redux/reducers/subscriptionReducer";
import { startActionWithPromise } from "../../helpers/saga-promise-helpers";
import "./index.scss";
import { getTableColumns, rowActionTypes } from "./TableColumns";

const MonthlyRevenues = ({user}) => {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    modalConfirm: false,
    modalInfo: false,
    action: '',
    message: '',
    row: null,
  });

  const defaultFilters = {
    pageSize: config.DEFAULT_PAGESIZE,
    pageNumber: 1,
    startYearMonth: '',
    endYearMonth: '',
    userId: user ? user.userId : '',
    state: '',
    transferDate: '',
    sort: {
      dir: `desc`,
      fieldName: `lastModifiedDate`,
    },
    reload: false,
  };
  const [filters, setFilters] = useMergeState(defaultFilters);

  const [waiting, setWaiting] = useState(false);
  
  const auth = useAuth();
  const isAuthorized = auth.roles &&
    (auth.roles.indexOf('SYSTEM_ADMINS') !== -1 ||
    auth.roles.indexOf('SYSTEM_OPERATORS') !== -1);
  const fetching = false;
  const [revenues, setRevenues] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const isBoAdmin = useSelector(state => state.bouser.isBoAdmin);

  const numItems = totalItems > filters.pageSize ? filters.pageSize : totalItems;
  const beginItem = numItems * (filters.pageNumber - 1);
  const endItem = numItems * filters.pageNumber;

  const [nonTransferrableIds, setNonTransferrableIds] = useState([]);
  let monthlyTransferRequest = { // infinite loop if use useState([])
    revenueIds: [],
    totalAmount: 0.0,
  };

  const onSizeChange = (size) => {
    setFilters({ ...filters, pageSize: size, pageNumber: 1, reload: true });
  };

  const onPageChange = (p) => {
    setFilters({ ...filters, pageNumber: p, reload: true });
  };

  const handleColumnSort = (field, order) => {
    setFilters({
      ...filters,
      sort: {
        dir: order,
        fieldName: field,
      },
      reload: true,
    });
  };

  const dateToYearMonth = (date) => {
    if (!date) return;
  
    return parseInt(`${date.getFullYear()}${_.padStart(date.getMonth() + 1, 2, 0)}`);
  }

  const currentYearMonth = dateToYearMonth(new Date());
  const isTransferrable = (revenue, currentYearMonth) => {
    return !revenue.transferred && revenue.readyForTransfer && (revenue.totalAmount >= 1.0) && (revenue.yearMonth < currentYearMonth);
  }

  const handleRequestToSetup = (userId, userFullname, userEmailAddress, event) => {
    event.preventDefault();
    
    let message = `<p class="font-weight-bold">Send a request to user "${userFullname}" at "${userEmailAddress}" to ask them to create a new Connected Account to receive payout.</p>\
                    <p class="font-weight-bold">Do you want to continue?</p>`;
    setState({
      ...state,
      modalConfirm: true,
      userId: userId,
      action: rowActionTypes.REQUEST_TO_SETUP,
      message: message,
    });
  };

  const tableColumns = getTableColumns(handleColumnSort, isBoAdmin, state, setState, isTransferrable, currentYearMonth, handleRequestToSetup);

  const toggleModal = (modal) => {
    if (state[modal]) setState({ ...state, [modal]: !state[modal] });
    else setState({ ...state, [modal]: !state[modal], row: null });
  };

  const refreshDatatable = () => {
    setFilters({ ...filters, reload: true });
  };

  const fetchMonthlyRevenues = () => {
    (async () => {
      try {
        // setWaiting(true);
        let params = {
          ...filters,
          pageNumber: filters.pageNumber - 1,
          sort: {
            dir: filters.sort.dir.toUpperCase(),
            fieldName: filters.sort.fieldName,
          },
        };
        if ( params.startYearMonth ) {
          params.startYearMonth = dateToYearMonth(params.startYearMonth);
        }
        if ( params.endYearMonth ) {
          params.endYearMonth = dateToYearMonth(params.endYearMonth);
        }
        if ( params.transferDate ) {
          params.transferDate = toDateString(params.transferDate);
        }
        delete params.reload;

        if (!filters.userId) delete params.userId;
        if (!filters.state) delete params.state;

        const resp = await startActionWithPromise(
          searchMonthlyRevenueActions,
          {params, successCallback: () => {}, failedCallback: () => {}},
          dispatch
        );
        setRevenues(resp.content);
        setTotalItems(resp.total);
        setTotalPages(Math.ceil(resp.total / resp.pageSize) || 0);
        setPageNumber(resp.pageNumber + 1 || 1);

        // Filter non-transferrable ids
        setNonTransferrableIds(resp.content.filter( revenue => !isBoAdmin || !isTransferrable(revenue, currentYearMonth) ).map(filteredRevenue => filteredRevenue.id));
      } catch {}
      setWaiting(false);
      // setFilters({ ...filters, reload: false });
    })();
  };

  useEffect(() => {
    if (filters.reload) {
      fetchMonthlyRevenues();
      setFilters({ ...filters, reload: false });
    }
  }, [filters.reload]);

  useEffect(() => {
    fetchMonthlyRevenues();
  }, []);

  ////////// Table's action handlers ///////////
  const tableReference = useRef(null); // should not expose table reference if not necessary

  const clearAllSelectedRows = () => {
    tableReference.current.selectionContext.selected = [];
  }

  const onSelectedChange = (selectedRows) => {
    monthlyTransferRequest.revenueIds = selectedRows.map( revenue => revenue.id );
    monthlyTransferRequest.totalAmount = selectedRows.reduce( (a, revenue) =>  a = a + revenue.totalAmount , 0 );
  };

  const handleRecalculateMonthlyRevenues = (yearMonthDate) => {
    let recalculateYearMonthDate;
    if ( yearMonthDate ) {
      recalculateYearMonthDate = yearMonthDate;
    } else {
      recalculateYearMonthDate = moment().subtract(1, 'months').toDate();
    }
    
    let message = `<p class="font-weight-bold">Start aggregating monthly revenues for the month/year "${`${_.padStart(recalculateYearMonthDate.getMonth() + 1, 2, 0)}/${recalculateYearMonthDate.getFullYear()}`}". Set a specific month/year by entering it to the 'Month/Year from' input date field. </p>\
                    <p class="font-weight-bold">Do you want to continue?</p>`;
    setState({
      ...state,
      modalConfirm: true,
      recalculateYearMonth: dateToYearMonth(recalculateYearMonthDate),
      action: rowActionTypes.RECALCULATE_MONTHLY_REVENUES,
      message: message,
    });
  };

  const handleTransferMonthlyRevenues = () => {
    if ( monthlyTransferRequest.revenueIds.length > 0 ) {
      let message = `<p class="font-weight-bold">Start transferring monthly revenues for the <font color=red>${monthlyTransferRequest.revenueIds.length}</font> selected record(s) with total amount <font color=red>$${monthlyTransferRequest.totalAmount}</font>. </p>\
                    <p class="font-weight-bold">Do you want to continue?</p>`;
      setState({
        ...state,
        modalConfirm: true,
        action: rowActionTypes.TRANSFER_MONTHLY_REVENUES,
        message: message,
      });
    } else {
      let message = `<p class="font-weight-bold">Please select at least one monthly revenue record to transfer. </p>`;
      setState({
        ...state,
        modalInfo: true,
        message: message,
      });
    }
  };

  const onActionConfirm = () => {
    switch (state.action) {
      case rowActionTypes.TRANSFER_REVENUE:
        transferRevenue(state.row);
        break;
      case rowActionTypes.RECALCULATE_MONTHLY_REVENUES:
        recalculateMonthlyRevenues(state.recalculateYearMonth);
        break;
      case rowActionTypes.TRANSFER_MONTHLY_REVENUES:
        transferMonthlyRevenues(monthlyTransferRequest);
        break;
      case rowActionTypes.REQUEST_TO_SETUP:
        sendConnectedAccountSetupRequest(state.userId);
        break;
      default:
    }
  };

  const transferRevenue = (row) => {
    const params = {
      userId: row.userId,
      yearMonth: row.yearMonth,
      monthlyPayout: true
    };

    (async () => {
      try {
        await startActionWithPromise(transferRevenueAction,
          { ...params, successCallback: () => {
            setState({ ...state, modalConfirm: false});
          }, failedCallback: () => {} },
          dispatch
        );
      } catch {};
      refreshDatatable();
    })();
  };

  const recalculateMonthlyRevenues = (yearMonth) => {
    const params = {
      yearMonth: yearMonth
    };

    (async () => {
      try {
        await startActionWithPromise(recalculateMonthlyRevenuesAction,
          { ...params, successCallback: () => {
            setState({ ...state, modalConfirm: false, modalInfo: true, message: 'Done. Please search by the month/year (' + Math.floor(yearMonth%100) + '/' + Math.floor(yearMonth/100) + ') to see the result, order by last modified date.'});
          }, failedCallback: () => {} },
          dispatch
        );
      } catch {};
      refreshDatatable();
    })();
  };

  const transferMonthlyRevenues = (monthlyTransferRequest) => {
    (async () => {
      try {
        await startActionWithPromise(transferMonthlyRevenuesAction,
          { ...monthlyTransferRequest, successCallback: (numOfTransferredRecords) => {
            setState({ ...state, modalConfirm: false, modalInfo: true, message: numOfTransferredRecords + ' monthly revenue(s) has/have just been transferred. Please search by "Transfer date" and verify the result.'});
            
            // Clear the selected records
            clearAllSelectedRows();
          }, failedCallback: () => {} },
          dispatch
        );
      } catch {};
      refreshDatatable();
    })();
  };

  const sendConnectedAccountSetupRequest = (userId) => {
    const params = {
      userId: userId
    };
    
    (async () => {
      try {
        await startActionWithPromise(sendConnectedAccountSetupRequestAction,
          { ...params, successCallback: () => {
            setState({ ...state, modalConfirm: false});
          }, failedCallback: () => {} },
          dispatch
        );
      } catch {};
    })();
  }

  ////////////////////

  return (
    <Container fluid className="p-0">
      <h1 className="page-title">Monthly Revenues</h1>
      <Row>
        <Col lg="12" className="d-flex">
        <>
          <Card className={classNames('w-100 mb-0 datatable-wrap')}>
            <CardHeader className="">
              <FormFilter
                handleRecalculate={handleRecalculateMonthlyRevenues}
                handleTransfer={handleTransferMonthlyRevenues}
                loading={fetching}
                filters={filters}
                mergeFilters={setFilters}
                paging={{
                  beginItem,
                  endItem,
                  totalItems
                }}
                user={user}
              />
            </CardHeader>
            <CardBody className="pt-0 data-list">
              { waiting ? (
                <div className="loading-content text-center p-4 border-top">
                  <Spinner size="sm" />
                </div>
              ) : (
                <>
                  <DataTable
                    keyField="id"
                    data={revenues}
                    columns={tableColumns}
                    order={filters.sort?.dir}
                    sort={filters.sort?.fieldName}
                    nonSelects={nonTransferrableIds}
                    onSelectedChange={onSelectedChange}
                    tableRef={tableReference}
                  />
                  <Row className="mt-3">
                    <Col lg="3" md="4" className="d-flex">
                      <PageSizeSelector size={filters.pageSize} onChange={onSizeChange} />
                    </Col>
                    <Col lg="9" md="8" className="d-flex justify-content-end">
                      <Paging totalPages={totalPages} current={pageNumber} show={5} onSelect={onPageChange} />
                    </Col>
                  </Row>
                </>
              )}
            </CardBody>
          </Card>
          <ConfirmModal
            modal={state.modalConfirm}
            toggle={toggleModal}
            row={state.row}
            onConfirm={onActionConfirm}
            on
            message={state.message}
          />
          <InfoModal
            modal={state.modalInfo}
            toggle={toggleModal}
            row={state.row}
            message={state.message}
          />
        </>
        </Col>
      </Row>
    </Container>
  );
};

export default MonthlyRevenues;
