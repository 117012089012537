import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Table
} from "reactstrap";
import _ from "lodash";
import PropTypes from "prop-types";
import Select from 'react-select';

import { updateProductPlanAction, createProductPlanAction } from "../../redux/reducers/subscriptionReducer";
import { startActionWithPromise } from "../../helpers/saga-promise-helpers";
import useMergeState from "../../hooks/mergeState";
import Selectbox from '../../components/Selectbox/Selectbox';

const propTypes = {
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  refreshData: PropTypes.func,
};

const defaultProps = {
  modal: false,
  toggle: () => {},
  refreshData: () => {},
};

const ProductPlanModal = props => {
  const dispatch = useDispatch();

  const { modal, toggle, refreshData, row, updateMode } = props;

  const fetching = false; // useSelector(state => state.user?.fetching);
  const [waiting, setWaiting] = useState(false);

  const billingGroupOptions = [
    {
      label: 'Monthly',
      value: 'Monthly',
    },
    {
      label: 'Annual',
      value: 'Annual',
    }
  ];

  const billingPeriodOptions = [
    {
      label: 'Daily',
      value: 'DAILY',
    },{
      label: 'Weekly',
      value: 'WEEKLY',
    },{
      label: 'Monthly',
      value: 'MONTHLY',
    },{
      label: 'Annual',
      value: 'ANNUAL',
    },{
      label: 'No Billing Period',
      value: 'NO_BILLING_PERIOD',
    }
  ];

  const currencyOptions = [
    {
      label: 'USD',
      value: 'USD',
    }
  ];

  const timeUnitOptions = [
    {
      label: 'N/A',
      value: null,
    },{
      label: 'Days',
      value: 'DAYS',
    },{
      label: 'Weeks',
      value: 'WEEKS',
    },{
      label: 'Months',
      value: 'MONTHS',
    },{
      label: 'Years',
      value: 'YEARS',
    },{
      label: 'Unlimited',
      value: 'UNLIMITED',
    }
  ];

  const activeOptions = [
    {
      label: 'Yes',
      value: true,
    },
    {
      label: 'No',
      value: false,
    },
  ];

  const [formState, setFormState] = useMergeState({ // deep copy the row object
    id: (row && row.id) ? row.id : null,
    parentProductId: (row && row.parentProductId) ? row.parentProductId : null,
    listed: (row && row.listed !== undefined) ? row.listed : false,
    headline: (row && row.headline) ? row.headline : '',
    billingGroup: (row && row.billingGroup) ? row.billingGroup : billingGroupOptions[0].value,
    planGroup: (row && row.planGroup) ? row.planGroup : '',
    listingOrderNumber: (row && row.listingOrderNumber) ? row.listingOrderNumber : 0,
    displayPrice: (row && row.displayPrice) ? row.displayPrice : '',
    paymentMethodRequired: (row && row.paymentMethodRequired !== undefined) ? row.paymentMethodRequired : true,
    planName: (row && row.planName) ? row.planName : '',
    kbPlanName: (row && row.kbPlanName) ? row.kbPlanName : '',
    description: (row && row.description) ? row.description : '',
    currency: (row && row.currency) ? row.currency : currencyOptions[0].value,
    billingPeriod: (row && row.billingPeriod) ? row.billingPeriod : billingPeriodOptions[0].value,

    recurringTimeUnit: (row && row.recurringTimeUnit) ? row.recurringTimeUnit : timeUnitOptions[0].value,
    recurringPeriod: (row && row.recurringPeriod) ? row.recurringPeriod : -1,
    recurringPrice: (row && row.recurringPrice) ? row.recurringPrice : '',

    trialTimeUnit: (row && row.trialTimeUnit) ? row.trialTimeUnit : timeUnitOptions[0].value,
    trialPeriod: (row && row.trialPeriod) ? row.trialPeriod : 0,

    fixedTimeUnit: (row && row.fixedTimeUnit) ? row.fixedTimeUnit : timeUnitOptions[0].value,
    fixedPeriod: (row && row.fixedPeriod) ? row.fixedPeriod : -1,
    fixedPrice: (row && row.fixedPrice) ? row.fixedPrice : 0.00,

    active: (row && row.active !== undefined) ? row.active : true,

    options: (row && row.options) ? _.cloneDeep(row.options) : [], // deep copy an object / an array
  });
  
  const [errorMessage, setErrorMessage] = useState('');

  const handleInputChange = (e) => {
    let inputValue = e.target.type === 'number' ? parseInt(e.target.value) : e.target.value;
    setFormState({[e.target.name]: inputValue});
  }

  const handleSelectboxChange = (selectedOption, actionMeta) => {
    setFormState({[actionMeta.name]: selectedOption.value});
  }

  const handleTimeUnitChange = (selectedOption, actionMeta) => {
    setFormState({[actionMeta.name]: selectedOption.value});
    if ( selectedOption.value === '' || selectedOption.value === 'Unlimited' ) {

    }
  }

  const handlePriceInputChange = (e) => {
    let inputValue = e.target.type === 'number' ? parseFloat(e.target.value).toFixed(2) : e.target.value;
    setFormState({[e.target.name]: inputValue});
  }

  const handleOptionInputChange = (e) => {
    let inputValue = e.target.value;
    const tokens = e.target.name.split("|");
    let option = formState.options.find( it => it.optionType == tokens[0] );
    if ( option ) {
      option[tokens[1]] = inputValue;
    }
  }

  const handleOptionActiveChange = (selectedOption, actionMeta) => {
    const tokens = actionMeta.name.split("|");
    let option = formState.options.find( it => it.optionType == tokens[0] );
    if ( option ) {
      option[tokens[1]] = selectedOption.value;
    }
  }

  const addSuccessCallback = () => {
    if (refreshData) refreshData();
    toggle("modalProductPlan");
  };

  const handleSubmit = async () => {
    setErrorMessage('');

    if (!formState.parentProductId ) {
      setErrorMessage('Missing product Id, something went wrong!');
      return;
    }

    if (!formState.planGroup ) {
      setErrorMessage('Please enter Plan Group');
      return;
    }

    if (!formState.listingOrderNumber ) {
      setErrorMessage('Please enter Listing Order');
      return;
    }

    if (!formState.displayPrice ) {
      setErrorMessage('Please enter Display Price');
      return;
    }

    if (!formState.planName ) {
      setErrorMessage('Please enter Plan Name');
      return;
    }

    if (!formState.kbPlanName ) {
      setErrorMessage('Please enter KillBill Plan Name');
      return;
    }
    
    let regex = new RegExp("^[a-zA-Z-]+$");
    if (!regex.test(formState.kbPlanName)) {
      setErrorMessage('Please just use alphabets and hyphen (-) for KillBill Plan Name!');
      return;
    }

    if (!formState.description ) {
      setErrorMessage('Please enter Description');
      return;
    }

    if (!formState.recurringTimeUnit && !formState.fixedTimeUnit && !formState.trialTimeUnit) {
      setErrorMessage('Please set a price (Recurring or Fixed price) or a trial period');
      return;
    }

    if (formState.recurringTimeUnit && formState.recurringPrice < 0) {
      setErrorMessage('Recurring Price must be greater than or equal to 0');
      return;
    }

    if (formState.fixedTimeUnit && formState.fixedPrice < 0) {
      setErrorMessage('Fixed Price must be greater than or equal to 0');
      return;
    }

    //console.log(formState);

    setWaiting(true);
    try {
      await startActionWithPromise(
        updateMode? updateProductPlanAction : createProductPlanAction,
        {
          params: formState,
          successCallback: addSuccessCallback,
          failedCallback: () => {},
        },
        dispatch
      );
    } catch {}
    
  };

  return (
    <Modal
      isOpen={modal}
      toggle={() => toggle("modalProductPlan")}
      centered
      size="xl"
      className="add-trusted-modal add-featured-video-modal"
    >
      <ModalHeader>{ updateMode? 'Update' : 'Create' } Product Plan { updateMode? '(' + row.planName + ')' : '' }</ModalHeader>
      <ModalBody className="pt-0 pb-0">
        <Card className="w-100 mb-0">
          <CardHeader className="">
            
          </CardHeader>
          <CardBody className="pt-0 pb-0 data-list position-relative">
            <Row>
              <Col lg="12">
                <FormGroup>
                  <div>
                    <FormGroup inline check>
                      <Label>
                        <input
                          className="form-check-input"
                          name="listed"
                          id="listed"
                          type="checkbox"
                          value={formState.listed}
                          checked={formState.listed}
                          onChange={() => setFormState({listed: !formState.listed})}
                        />
                        <span>Listed <font color="red">*</font></span>
                      </Label>
                    </FormGroup>
                    
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="8">
                <FormGroup>
                  <Label for="headline" className="">
                    Headline
                  </Label>
                  <input
                    className="form-control"
                    name="headline"
                    id="headline"
                    type="text"
                    value={formState.headline}
                    onChange={handleInputChange}
                    maxLength={255}
                  />
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <Label for="billingGroup" className="">
                    Billing Group <font color="red">*</font>
                  </Label>
                  <Selectbox
                    name="billingGroup"
                    placeholder="Select..."
                    options={billingGroupOptions}
                    onChange={handleSelectboxChange}
                    defaultValue={billingGroupOptions.find(it => it.value === formState.billingGroup)}
                    isClearable={false}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="8">
                <FormGroup>
                  <Label for="planGroup" className="">
                    Plan Group <font color="red">*</font>
                  </Label>
                  <input
                    className="form-control"
                    name="planGroup"
                    id="planGroup"
                    type="text"
                    value={formState.planGroup}
                    onChange={handleInputChange}
                    maxLength={255}
                  />
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <Label for="listingOrderNumber" className="">
                    Listing Order <font color="red">*</font>
                  </Label>
                  <input
                    className="form-control"
                    name="listingOrderNumber"
                    id="listingOrderNumber"
                    type="number"
                    min="0"
                    value={formState.listingOrderNumber}
                    onChange={handleInputChange}
                    maxLength={3}
                    width="30px"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg="8">
                <FormGroup>
                  <Label for="displayPrice" className="">
                    Display Price <font color="red">*</font>
                  </Label>
                  <input
                    className="form-control"
                    name="displayPrice"
                    id="displayPrice"
                    type="text"
                    value={formState.displayPrice}
                    onChange={handleInputChange}
                    maxLength={255}
                  />
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <div>
                    <FormGroup inline check>
                      <Label>
                        <input
                          className="form-check-input"
                          name="paymentMethodRequired"
                          id="paymentMethodRequired"
                          type="checkbox"
                          value={formState.paymentMethodRequired}
                          checked={formState.paymentMethodRequired}
                          onChange={() => setFormState({paymentMethodRequired: !formState.paymentMethodRequired})}
                        />
                        <span>Payment Method Required <font color="red">*</font></span>
                      </Label>
                    </FormGroup>
                    
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="8">
                <FormGroup>
                  <Label for="planName" className="">
                    Plan Name <font color="red">*</font>
                  </Label>
                  <input
                    className="form-control"
                    name="planName"
                    id="planName"
                    type="text"
                    value={formState.planName}
                    onChange={handleInputChange}
                    maxLength={255}
                  />
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <Label for="billingPeriod" className="">
                    Billing Period <font color="red">*</font>
                  </Label>
                  <Selectbox
                    name="billingPeriod"
                    placeholder="Select..."
                    options={billingPeriodOptions}
                    onChange={handleSelectboxChange}
                    defaultValue={billingPeriodOptions.find(it => it.value === formState.billingPeriod)}
                    isClearable={false}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="8">
                <FormGroup>
                  <Label for="kbPlanName" className="">
                    KillBill Plan Name <font color="red">*</font>
                  </Label>
                  <input
                    className="form-control"
                    name="kbPlanName"
                    id="kbPlanName"
                    type="text"
                    pattern="^[a-zA-Z-]+$"
                    value={formState.kbPlanName}
                    onChange={handleInputChange}
                    maxLength={255}
                  />
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <Label for="currency" className="">
                    Currency <font color="red">*</font>
                  </Label>
                  <Selectbox
                    name="currency"
                    placeholder="Select..."
                    options={currencyOptions}
                    onChange={handleSelectboxChange}
                    defaultValue={currencyOptions.find(it => it.value === formState.currency)}
                    isClearable={false}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg="8">
                <FormGroup>
                  <Label for="description" className="">
                    Description <font color="red">*</font>
                  </Label>
                  <input
                    className="form-control"
                    name="description"
                    id="description"
                    type="text"
                    value={formState.description}
                    onChange={handleInputChange}
                    maxLength={255}
                  />
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <div>
                    <FormGroup inline check>
                      <Label>
                        <input
                          className="form-check-input"
                          name="active"
                          id="active"
                          type="checkbox"
                          value={formState.active}
                          checked={formState.active}
                          onChange={() => setFormState({active: !formState.active})}
                        />
                        <span>Active <font color="red">*</font></span>
                      </Label>
                    </FormGroup>
                    
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg="8">
                <FormGroup>
                  <Label for="trialPeriod" className="">
                    Trial Period
                  </Label>
                  <input
                    className="form-control"
                    name="trialPeriod"
                    id="trialPeriod"
                    type="number"
                    value={formState.trialPeriod}
                    onChange={handleInputChange}
                    maxLength={255}
                  />
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <Label for="trialTimeUnit" className="">
                    Trial Time Unit
                  </Label>
                  <Selectbox
                    name="trialTimeUnit"
                    placeholder="Select..."
                    options={timeUnitOptions}
                    onChange={handleTimeUnitChange}
                    defaultValue={timeUnitOptions.find(it => it.value === formState.trialTimeUnit) || timeUnitOptions[0]}
                    isClearable={false}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg="4">
                <FormGroup>
                  <Label for="recurringPrice" className="">
                    Recurring Price
                  </Label>
                  <input
                    className="form-control"
                    name="recurringPrice"
                    id="recurringPrice"
                    type="number"
                    value={formState.recurringPrice}
                    onChange={handlePriceInputChange}
                    maxLength={255}
                  />
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <Label for="recurringPeriod" className="">
                    Recurring Period
                  </Label>
                  <input
                    className="form-control"
                    name="recurringPeriod"
                    id="recurringPeriod"
                    type="number"
                    value={formState.recurringPeriod}
                    onChange={handleInputChange}
                    maxLength={255}
                  />
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <Label for="recurringTimeUnit" className="">
                    Recurring Time Unit
                  </Label>
                  <Selectbox
                    name="recurringTimeUnit"
                    placeholder="Select..."
                    options={timeUnitOptions}
                    onChange={handleTimeUnitChange}
                    defaultValue={timeUnitOptions.find(it => it.value === formState.recurringTimeUnit) || timeUnitOptions[0]}
                    isClearable={false}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg="4">
                <FormGroup>
                  <Label for="fixedPrice" className="">
                    Fixed Price
                  </Label>
                  <input
                    className="form-control"
                    name="fixedPrice"
                    id="fixedPrice"
                    type="number"
                    value={formState.fixedPrice}
                    onChange={handlePriceInputChange}
                    maxLength={255}
                  />
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <Label for="fixedPeriod" className="">
                  Fixed Period
                  </Label>
                  <input
                    className="form-control"
                    name="fixedPeriod"
                    id="fixedPeriod"
                    type="number"
                    value={formState.fixedPeriod}
                    onChange={handleInputChange}
                    maxLength={255}
                  />
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <Label for="fixedTimeUnit" className="">
                  Fixed Time Unit
                  </Label>
                  <Selectbox
                    name="fixedTimeUnit"
                    placeholder="Select..."
                    options={timeUnitOptions}
                    onChange={handleTimeUnitChange}
                    defaultValue={timeUnitOptions.find(it => it.value === formState.fixedTimeUnit) || timeUnitOptions[0]}
                    isClearable={false}
                  />
                </FormGroup>
              </Col>
            </Row>

            <div className="order-items mt-4">
              <Table bordered>
                <thead>
                  <tr>
                    <th style={{ width: "30%" }}>Option Type</th>
                    <th style={{ width: "10%", textAlign: 'center' }}>Listing Order</th>
                    <th style={{ width: "10%" }}>Value</th>
                    <th style={{ width: "15%" }}>Value Unit</th>
                    <th style={{ width: "25%" }}>Description</th>
                    <th style={{ width: "10%", textAlign: 'center' }}>Active</th>
                  </tr>
                </thead>
                <tbody>
                  {formState.options.map( (option, i) => (
                    <tr key={i}>
                      <td>{option.optionType}</td>
                      <td className="text-center">{option.listingOrderNumber}</td>
                      <td>
                        <input
                          className="form-control"
                          name={option.optionType + '|value'}
                          id={option.optionType + '|value'}
                          type="text"
                          defaultValue={option.value}
                          onBlur={handleOptionInputChange}
                          maxLength={255}
                        />
                      </td>
                      <td>
                        <input
                          className="form-control"
                          name={option.optionType + '|valueUnit'}
                          id={option.optionType + '|valueUnit'}
                          type="text"
                          defaultValue={option.valueUnit}
                          onBlur={handleOptionInputChange}
                          maxLength={255}
                        />
                      </td>
                      <td>
                        <input
                          className="form-control"
                          name={option.optionType + '|description'}
                          id={option.optionType + '|description'}
                          type="text"
                          defaultValue={option.description}
                          onBlur={handleOptionInputChange}
                          maxLength={255}
                        />
                      </td>
                      <td className="text-center">
                        <input
                          className="form-check-input"
                          name={option.optionType + '|active'}
                          id={option.optionType + '|active'}
                          type="checkbox"
                          checked={option.active}
                          onChange={handleOptionInputChange}
                        />
                        <Select
                          name={option.optionType + '|active'}
                          placeholder="Select..."
                          options={activeOptions}
                          isClearable={false}
                          defaultValue={activeOptions.find(it => it.value === option.active) || option[0]}
                          onChange={handleOptionActiveChange}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </ModalBody>
      <ModalFooter>
        {errorMessage && (
          <div className="float-left text-danger">
            {errorMessage}
          </div>
        )}
        <Button
          color="secondary"
          disabled={fetching}
          onClick={() => toggle("modalProductPlan")}
        >
          Cancel
        </Button>
        <Button
          className="ml-2"
          color="primary"
          disabled={waiting}
          onClick={handleSubmit}
        >
          { updateMode? 'Update' : 'Create' }
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ProductPlanModal.propTypes = propTypes;
ProductPlanModal.defaultProps = defaultProps;

export default ProductPlanModal;
