import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Table,
  UncontrolledTooltip
} from "reactstrap";
import PropTypes from "prop-types";
import moment from "moment";
import { RefreshCw } from "react-feather";

import { formatNumber } from "../../helpers/utils";
import ConfirmModal from "../../components/base/ConfirmModal";

const propTypes = {
  modal: PropTypes.bool,
  toggle: PropTypes.func,
};

const defaultProps = {
  modal: false,
  toggle: () => {},
};

const boldStyles = {
  marginBottom: '0.5rem',
  fontWeight: '600',
  lineHeight: '1.2',
  color: '#000',
}

const orderItems = [
  {
    id: 1,
    item_name: 'Physic 1',
    item_desc: '',
    qty: 1,
    item_price: 100,
    total: 1000
  },
  {
    id: 2,
    item_name: 'Physic 2',
    item_desc: '',
    qty: 1,
    item_price: 100,
    total: 100
  }
];

const RecloneAction = ({item, ...rest}) => {
  return (
    <span
      className="ml-auto position-absolute"
      id={`reclone-${item.id}`}
      {...rest}
      style={{right: 0, top: 0, cursor: 'pointer'}}
    >
      <RefreshCw width="15" />
      <UncontrolledTooltip target={'reclone-' + item.id}>Re-clone video</UncontrolledTooltip>
    </span>
  )
}

const OrderDetail = props => {
  const { modal, toggle, order } = props;
  let [recloneModal, setRecloneModal] = useState(false);
  let [message, setMessage] = useState('');
  let [video, setVideo] = useState(false);

  const toggleModal = () => {
    if (toggle) toggle('modalDetail');
  };

  const onConfirmReclone = () => {
    console.log(video);
  }

  return (
    <>
    <Modal isOpen={modal} toggle={toggleModal} centered size="lg">
      <ModalHeader toggle={toggleModal}>
        Order #{order.id}
      </ModalHeader>
      <ModalBody className="">
        
        <Row>
          <Col lg="12">
            { order && (
              <div className="request-detail">
                <div className="d-flex">
                  <div className="left w-50">
                  <p className="mb-2"><span style={boldStyles}>No:</span> #{order.id}</p>
                  <p className="mb-2"><span style={boldStyles}>Total:</span> ${formatNumber(order.income)}</p>
                  <p className="mb-2"><span style={boldStyles}>User:</span> {order.buyerFullname} ({order.buyerEmailAddress})</p>
                  <p className="mb-2"><span style={boldStyles}>Purchased Date:</span> {order.purchasedDate ? moment(order.purchasedDate).utc().format('YYYY/MM/DD') : ''}</p>
                  </div>
                  <div className="right w-50">
                    <h5>Payment method</h5>
                    <div className="mb-3">
                      {order.paymentGateway} {order.paymentMethod}
                    </div>
                  </div>
                </div>
                
                <div className="order-items mt-3">
                  <Table bordered>
                    <thead>
                      <tr>
                        <th style={{ width: "10" }}>No</th>
                        <th style={{ width: "45%" }}>Name</th>
                        <th style={{ width: "15%", textAlign: 'right' }}>Unit price</th>
                        <th style={{ width: "15%", textAlign: 'right' }}>Quantity</th>
                        <th style={{ width: "15%", textAlign: 'right' }}>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>
                          <div className="order-item-name position-relative">
                            {order.productTitle}
                          </div>
                          
                        </td>
                        <td className="text-right">${formatNumber(order.unitPrice)}</td>
                        <td className="text-right">{order.quantity}</td>
                        <td className="text-right">${formatNumber(order.unitPrice)}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            )}
          </Col>
        </Row>
        
      </ModalBody>
      
    </Modal>
    <ConfirmModal
        modal={recloneModal}
        toggle={() => setRecloneModal(!recloneModal)}
        onConfirm={onConfirmReclone}
        message={message}
      />
    </>
  );
};

OrderDetail.propTypes = propTypes;
OrderDetail.defaultProps = defaultProps;

export default OrderDetail;
