import { userTypes, RESET_REDUCER } from "../constants";

const user = JSON.parse(localStorage.getItem("user"));
const initialState = user ? { loggedIn: true, user } : {};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case userTypes.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user,
      };
    case userTypes.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.user,
      };
    case userTypes.LOGIN_FAILURE:
      return {};
    case userTypes.LOGOUT:
    case RESET_REDUCER:
      return {};
    default:
      return state;
  }
}
