import React, { useState } from "react";
import { Row, Col, Button } from "reactstrap";
import _ from 'lodash';
import * as Icon from "react-feather";
import classNames from "classnames";
import moment from "moment";
import styled from "styled-components";
import DatePicker from "react-datepicker";

import "./FormFilter.scss";
import SearchUserInput from "./SearchUserInput";
import Selectbox from '../../components/Selectbox/Selectbox';
import { SUBSCRIPTION_STATES } from "./constants";
import { ucFirst } from "../../helpers/utils";

const StyledDatetimeDiv = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker__input-container {
    input {
      width: 230px;
    }
    
  }
`;

let StateOptions = [{
  label: 'State',
  value: '',
}];
Object.entries(SUBSCRIPTION_STATES).forEach(([key, value]) => {
  StateOptions.push({
    label: ucFirst(value),
    value: value,
  });
});

const FormFilter = ({loading, paging, mergeFilters, user}) => {
  const [minDate, setMinDate] = useState('');
  const [maxDate, setMaxDate] = useState('');

  //const startDate = moment().startOf('month').subtract(1, 'months').toDate();
  //const endDate = moment().endOf('month').toDate();

  const [filters, setFilters] = useState({
    startDate: undefined,
    endDate: undefined,
    subscriberId: user ? user.userId : '',
    state: '',
    reload: false,
  });

  const handleSubscriberChange = (option) => {
    setFilters({ ...filters, subscriberId: option ? option.value : ''});
  }

  const handleStateChange = (option) => {
    if (option && option.value) 
        setFilters({ ...filters, state: option.value, reload: true });
    else 
        setFilters({ ...filters, state: '', reload: true });
  }

  const submitFilter = () => {
    // console.log(mergeFilters);
    mergeFilters({...filters, reload: true});
  }

  return (
    <>
      <Row className={classNames('video-review-filter')} style={{transition: 'visibility 0.5s, opacity 0.5s linear'}}>
        <Col lg="12" md="12" xs="12" className="">
        <div className="item-filter float-right">
            <div className="d-flex align-items-center">
              
              <div className="d-flex align-items-center ml-3">
                <label htmlFor="" className="mb-0 text-nowrap text-right" style={{width: '80px'}}>Subscriber</label>
                <div className="d-inline-block ml-2 item-filter__control" style={{width: '200px'}}>
                  <SearchUserInput
                    onChange={handleSubscriberChange}
                    className="w-100"
                    isClearable={true}
                    name="subscriberId"
                    />
                </div>
              </div>
              <div className="filter-status ml-3" style={{width: '200px'}}>
                <Selectbox
                  name="search_state"
                  placeholder="State"
                  options={StateOptions}
                  onChange={handleStateChange}
                />
              </div>
              <div className="d-flex align-items-center ml-3">
                <label htmlFor="" className="mb-0 text-nowrap text-right" style={{width: '90px'}}>Valid from</label>
                <StyledDatetimeDiv className="d-inline-block item-filter__control ml-2">
                  <DatePicker
                    selected={filters.startDate}
                    onChange={(date) => {setFilters({...filters, startDate: date}); setMinDate(date)}}
                    maxDate={maxDate}
                    dateFormat="MM/dd/yyyy"
                    className="w-100"
                    //style={{width: '230px'}}
                  />
                </StyledDatetimeDiv>
              </div>

              <div className="d-flex align-items-center ml-3">
                <label htmlFor="" className="mb-0 text-nowrap text-right" style={{width: '30px'}}>to</label>
                <StyledDatetimeDiv className="d-inline-block item-filter__control ml-2">
                  <DatePicker
                    selected={filters.endDate}
                    onChange={(date) => {setFilters({...filters, endDate: date}); setMaxDate(date)}}
                    minDate={minDate}
                    dateFormat="MM/dd/yyyy"
                    className="w-100"
                    //style={{width: '230px'}}
                  />
                </StyledDatetimeDiv>
              </div>
              <div className="d-flex align-items-center">
                <Button
                  className="ml-2"
                  size=""
                  disabled={loading}
                  onClick={(e) => submitFilter(e)}
                  style={{minWidth: '86px'}}
                >
                  <Icon.Search style={{ width: '15px', height: '15px', marginTop: '-2px' }} /> Search
                </Button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default React.memo(FormFilter);