import React from "react";
import { Container, Row, Col } from "reactstrap";
import LogList from "./LogList";

const Logs = () => {
  return (
    <Container fluid className="p-0">
      <h1 className="page-title">Platform applications logs</h1>
      <Row>
        <Col lg="12" className="d-flex">
          <LogList />
        </Col>
      </Row>
    </Container>
  );
};

export default Logs;
