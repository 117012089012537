import React from "react";
import { Container, Row, Col } from "reactstrap";
import ChangePassword from "./ChangePassword";
import ProfileForm from "./ProfileForm";

const UserProfile = () => {
  return (
    <Container fluid className="p-0">
      <h1 className="page-title">Profile</h1>
      <Row>
        <Col lg="6" className="d-flex">
          <ProfileForm />
        </Col>
        <Col lg="6" className="d-flex">
          <ChangePassword />
        </Col>
      </Row>
    </Container>
  );
};

export default UserProfile;
