import {
    createReducer,
    createActionResources
  } from "redux-waiters";
  import { RESET_REDUCER } from "../constants";
  
  export const searchVideoSubsAction = createActionResources("videoSubscription/search");

  const initialState = {
    fetching: false,
    videoSubsriptions: [],
    total: 0,
    totalPages: 0,
    pageNumber: 1,
    error: {},
  };
  
  export default createReducer(
    {
      [searchVideoSubsAction.start]: state => {
        return { ...state, fetching: true };
      },
      [searchVideoSubsAction.success]: (state, action) => {
        return {
          ...state,
          videoSubsriptions: action.data?.content,
          pageNumber: action.data?.pageNumber + 1,
          total: action.data?.total,
          totalPages: Math.ceil(action.data?.total / action.data?.pageSize),
          fetching: false,
        };
      },
      [searchVideoSubsAction.error]: state => {
        return { ...state, fetching: false };
      },
  
      [RESET_REDUCER]: () => initialState,
    },
    initialState
  );