import React from "react";
import moment from "moment";
import { UncontrolledTooltip, Badge } from "reactstrap";
import * as Icon from "react-feather";
import { v4 as uuidv4 } from "uuid";

import { SUBMIT_COMPLETED_LABELS, SUBMIT_COMPLETED_COLORS, TRUE_FALSE_LABELS, TRUE_FALSE_COLORS } from "./constants";

export const rowActionTypes = {
  REFRESH_ACCOUNT: "ROW_ACTION_REFRESH_ACCOUNT",
  REMOVE_ACCOUNT: "ROW_ACTION_REMOVE_ACCOUNT",
};

export const getTableColumns = (handleSort = () => { }, isBoAdmin, state, setState) => {

  return [
    {
      dataField: "fullName",
      text: "User",
      sort: false,
      onSort: (field, order) => {
        handleSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div className="d-inline-block fullname align-self-center" id="">
          <p className="mb-0">{row.fullName}</p>
          <p className="mb-0">{row.emailAddress}</p>
          <p className="mb-0 font-italic" style={{ fontSize: '0.75rem' }}>{row.userId}</p>
        </div>
      ),
      headerStyle: () => {
        return { minWidth: '15%' };
      },
    },
    {
      dataField: "gatewayAccountId",
      text: "Account Id",
      sort: true,
      formatter: (cell, row) => (
        <div>
          {row.gatewayAccountId}
        </div>
      ),
      headerStyle: () => {
        return { textAlign: "center", width: '20%' };
      },
      style: () => {
        return { textAlign: "center" };
      },
    },
    {
      dataField: "transfersEnabled",
      text: "Transfer Enabled",
      sort: true,
      formatter: (cell, row) => (
        <div className="">
          <Badge
            color={TRUE_FALSE_COLORS[row.transfersEnabled]}
            className="mr-1 mb-1"
          >
            {TRUE_FALSE_LABELS[row.transfersEnabled]}
          </Badge>
        </div>
      ),
      headerStyle: () => {
        return { textAlign: "center", width: '120px' };
      },
      style: () => {
        return { textAlign: "center" };
      },
    },
    {
      dataField: "payoutsEnabled",
      text: "Payout Enabled",
      sort: true,
      formatter: (cell, row) => (
        <div className="">
          <Badge
            color={TRUE_FALSE_COLORS[row.payoutsEnabled]}
            className="mr-1 mb-1"
          >
            {TRUE_FALSE_LABELS[row.payoutsEnabled]}
          </Badge>
        </div>
      ),
      headerStyle: () => {
        return { textAlign: "center", width: '120px' };
      },
      style: () => {
        return { textAlign: "center" };
      },
    },
    {
      dataField: "submitCompleted",
      text: "Submit Completed",
      sort: true,
      formatter: (cell, row) => (
        <div className="">
          <Badge
            color={SUBMIT_COMPLETED_COLORS[row.submitCompleted]}
            className="mr-1 mb-1"
          >
            {SUBMIT_COMPLETED_LABELS[row.submitCompleted]}
          </Badge>
        </div>
      ),
      headerStyle: () => {
        return { textAlign: "center", width: '120px' };
      },
      style: () => {
        return { textAlign: "center" };
      },
    },
    {
      dataField: "active",
      text: "Active",
      sort: true,
      formatter: (cell, row) => (
        <div className="">
          <Badge
            color={TRUE_FALSE_COLORS[row.active]}
            className="mr-1 mb-1"
          >
            {TRUE_FALSE_LABELS[row.active]}
          </Badge>
        </div>
      ),
      headerStyle: () => {
        return { textAlign: "center", width: '120px' };
      },
      style: () => {
        return { textAlign: "center" };
      },
    },
    {
      dataField: "defaultAccount",
      text: "Default",
      sort: true,
      formatter: (cell, row) => (
        <div className="">
          <Badge
            color={TRUE_FALSE_COLORS[row.defaultAccount]}
            className="mr-1 mb-1"
          >
            {TRUE_FALSE_LABELS[row.defaultAccount]}
          </Badge>
        </div>
      ),
      headerStyle: () => {
        return { textAlign: "center", width: '120px' };
      },
      style: () => {
        return { textAlign: "center" };
      },
    },    
    /*{
      dataField: "lastModifiedDate",
      text: "Last Modified Date",
      sort: true,
      onSort: (field, order) => {
        handleSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div className="">
          {moment(row.lastModifiedDate).format('MM/DD/YYYY')}
        </div>
      ),
      headerStyle: () => {
        return { textAlign: "center", width: "150px" };
      },
      style: () => {
        return { textAlign: "center" };
      },
    },*/
    {
      dataField: "deleted",
      text: "Deleted",
      sort: true,
      formatter: (cell, row) => (
        <div className="">
          <Badge
            color={TRUE_FALSE_COLORS[row.deleted]}
            className="mr-1 mb-1"
          >
            {TRUE_FALSE_LABELS[row.deleted]}
          </Badge>
        </div>
      ),
      headerStyle: () => {
        return { textAlign: "center", width: '10%' };
      },
      style: () => {
        return { textAlign: "center" };
      },
    },

    {
      dataField: "action_col",
      text: "Actions",
      sort: false,
      formatter: (cell, row, rowIndex, formatExtraData) => (
        <div className="actions-col text-center">

          {isBoAdmin ? (
            <a href="#" className="mr-2" onClick={e => formatExtraData.handleRefreshAccount(row, e)} id={'refresh-' + row.action_col}>
              <Icon.RotateCw className="text-dark" style={{ width: "15px" }} />
              <UncontrolledTooltip target={'refresh-' + row.action_col}>
                Refresh Account
              </UncontrolledTooltip>
            </a>
          ) : (<span></span>)
          }

          {isBoAdmin && !row.deleted ? (
            <a href="#" className="mr-2" onClick={e => formatExtraData.handleRemoveAccount(row, e)} id={'remove-' + row.action_col}>
              <Icon.XCircle className="text-dark" style={{ width: "15px" }} />
              <UncontrolledTooltip target={'remove-' + row.action_col}>
                Remove Account
              </UncontrolledTooltip>
            </a>
          ) : (<span></span>)
          }
        </div>
      ),
      formatExtraData: {
        handleRefreshAccount: (row, e) => {
          let message = `<p class="font-weight-bold">Refresh the account (with id "${`${row.gatewayAccountId}`}"). </p>\
                    <p class="font-weight-bold">Do you want to continue?</p>`;
          setState({
            ...state,
            modalConfirm: true,
            row,
            action: rowActionTypes.REFRESH_ACCOUNT,
            message: message,
          });
        },
        handleRemoveAccount: (row, e) => {
          let message = `<p class="font-weight-bold">Remove the account (with id "${`${row.gatewayAccountId}`}"). </p>\
                    <p class="font-weight-bold">Do you want to continue?</p>`;
          setState({
            ...state,
            modalConfirm: true,
            row,
            action: rowActionTypes.REMOVE_ACCOUNT,
            message: message,
          });
        },
      },
      headerStyle: () => {
        return { textAlign: "center", width: "120px" };
      },
    },
  ];
}