import { createReducer, createActionResources } from "redux-waiters";
import { RESET_REDUCER } from "../constants";

export const fetchUsersAction = createActionResources("users/fetch");
export const forceLogoutAction = createActionResources("users/force-logout");
export const changePwAction = createActionResources("users/change-pw");
export const resetPwAction = createActionResources("users/reset-pw");
export const revokeRolesAction = createActionResources("users/revoke-roles");
export const searchUsersAction = createActionResources("users/search");
export const searchMailboxesAction = createActionResources("users/mailboxes");
export const exportMailboxesAction = createActionResources("users/exportMailboxes");
export const sendEmailAction = createActionResources("users/sendEmail");

export const searchContactMessages = createActionResources("users/contactMessages");
export const updateMessageNoteAndStatus = createActionResources("users/contactMessages/notes");


// roles
export const userRolesAction = createActionResources("users/roles");
export const fetchRolesAction = createActionResources("users/fetch-roles");
export const setRolesAction = createActionResources("users/set-roles");
export const addRolesAction = createActionResources("users/add-roles");
export const subRolesAction = createActionResources("users/sub-roles");
export const enableAction = createActionResources("users/enable");
export const disableAction = createActionResources("users/disable");
export const resendActivationAction = createActionResources(
  "users/confirmation"
);
export const importUserAction = createActionResources("users/import");
export const deleteUserAction = createActionResources("users/delete");
export const fetchUsersAsyncAction = createActionResources("users/fetch-async");
export const fetchUsersWithStorageAction = createActionResources(
  "users/fetch-users-storage"
);
export const syncStoragePackageAction = createActionResources(
  "subscriptions/userStorages"
);

const initialState = {
  fetching: false,
  users: [],
  total: 0,
  totalPages: 0,
  pageNumber: 1,
  error: {},
};

export default createReducer(
  {
    [fetchUsersAction.start]: (state) => {
      return { ...state, fetching: true };
    },
    [fetchUsersAction.success]: (state, action) => {
      const users = action.data?.content || [];
      if (users)
        users.map((user) => {
          return {
            ...user,
            fullname: `${user.firstName} ${user.lastName}`,
            joiningDate: user.joiningDate
              ? user.joiningDate.replace(" +0000", "")
              : "",
          };
        });

      return {
        ...state,
        users: action.data?.content || [],
        pageNumber: action.data?.pageNumber + 1 || 1,
        total: action.data?.total || 0,
        totalPages: Math.ceil(action.data?.total / action.data?.pageSize) || 0,
        fetching: false,
      };
    },
    [fetchUsersAction.error]: (state) => {
      return { ...state, fetching: false };
    },
    [RESET_REDUCER]: () => initialState,
  },
  initialState
);
