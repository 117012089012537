import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import moment from "moment";
import classNames from "classnames";
import { v4 as uuidv4 } from "uuid";

import DataTable from "../../components/DataTable";
import PageSizeSelector from "../../components/base/PageSizeSelector";
import Paging from "../../components/base/Paging";
import config from "../../config/config";

import LogDetail from "./AuditLogDetail";
import FormFilter from "./AuditLogFilter";
import ConfirmModal from "../../components/base/ConfirmModal";

import { auditLogActions } from "../../redux/reducers/auditLogReducer";
import { startActionWithPromise } from "../../helpers/saga-promise-helpers";
import "./AuditLogList.scss";
import { getTableColumns } from "./TableColumns";
import { AUDIT_TYPES } from "./constants";

const Actions = {
  RETRANSCODE: 'Re-transcode',
  WITHDRAW: 'Withdraw'
};

let auditTypes = [];
for (const auditType in AUDIT_TYPES) {
  auditTypes.push(AUDIT_TYPES[auditType]);
}

const LogList = () => {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    modalConfirm: false,
    modalDetail: false,
    action: '',
    message: '',
    row: null,
  });

  const startDate = moment().subtract(7, 'days').format('MM/DD/YYYY');
  const endDate = moment().format('MM/DD/YYYY');

  const [filters, setFilters] = useState({
    pageSize: config.DEFAULT_PAGESIZE,
    pageNumber: 1,
    sort: 'createdDate',
    sortReal: '',
    order: 'desc',
    startDate: startDate,
    endDate: endDate,
    entityTypes: auditTypes,
    userName: '',
    reload: false,
  });

  const [waiting, setWaiting] = useState(false);
  
  const logs = useSelector((state) => state.auditLog?.list) || [];
  const fetching = useSelector(state => state.auditLog?.fetching);
  const totalItems = useSelector((state) => state.auditLog.total);
  const totalPages = useSelector((state) => state.auditLog.totalPages);
  const pageNumber = useSelector((state) => state.auditLog.pageNumber);

  const numItems = totalItems > filters.pageSize ? filters.pageSize : totalItems;
  const beginItem = numItems * (filters.pageNumber - 1);
  const endItem = numItems * filters.pageNumber;

  const dataList = logs.map(item => ({
    ...item,
    action_col: uuidv4(),
  }));

  const onSizeChange = (size) => {
    setFilters({ ...filters, pageSize: size, pageNumber: 1, reload: true });
  };

  const onPageChange = (p) => {
    setFilters({ ...filters, pageNumber: p, reload: true });
  };

  const onColumnSort = (field, order) => {
    let realSort = '';
    switch (field) {
      default:
        realSort = field;
    }
    if (realSort === filters.sortReal && order === filters.order) return;

    setFilters({
      ...filters,
      sort: field,
      sortReal: realSort,
      order,
      reload: true,
    });
  };

  const tableColumns = getTableColumns(onColumnSort, (row) => setState({ ...state, modalDetail: true, row }));

  const onActionButtonClick = (action, row) => {
    switch (action.type) {
      case Actions.RETRANSCODE:
        setState({ ...state, modalRetranscode: true, row });
        break;
      case Actions.WITHDRAW:
        setState({ ...state, modalWithdraw: true, row });
        break;
      default:
    }
  };

  const toggleModal = (modal) => {
    if (state[modal]) setState({ ...state, [modal]: !state[modal] });
    else setState({ ...state, [modal]: !state[modal], row: null });
  };

  const onActionConfirm = () => {
    switch (state.action) {
      case Actions.RETRANSCODE:
        break;
      case Actions.WITHDRAW:
        break;
      default:
    }
  };

  const refreshDatatable = () => {
    setFilters({ ...filters, reload: true });
  };

  const fetchData = (params) => {
    (async () => {
      try {
        setWaiting(true);
        await startActionWithPromise(
          auditLogActions.fetch,
          { params, successCallback: () => {}, failedCallback: () => {} },
          dispatch
        );
      } catch {}
      setWaiting(false);
      setFilters({ ...filters, reload: false });
    })();
  };

  useEffect(() => {
    if (filters.reload) {
      const params = {
        pageSize: filters.pageSize,
        pageNumber: filters.pageNumber - 1,
        entityTypes: filters.entityTypes,
        userName: filters.userName,
        startDate: filters.startDate,
        endDate: filters.endDate,
        sort: {
          dir: `${filters.order.toUpperCase()}`,
          fieldName: `${filters.sort}`,
        },
      };

      if (!filters.entityTypes) delete params.entityTypes;
      if (!filters.userName) delete params.userName;

      fetchData(params);
    }
  }, [filters.reload]);

  return (
    <>
      <Card className={classNames('w-100 mb-0 data-list data-list--auditlog')}>
        <CardHeader className="">
          <FormFilter
            loading={fetching}
            filters={filters}
            mergeFilters={setFilters}
            paging={{
              beginItem,
              endItem,
              totalItems
            }}
          />
        </CardHeader>
        <CardBody className="pt-0 data-list">
          { waiting ? (
            <div className="loading-content text-center p-4 border-top">
              <Spinner size="sm" />
            </div>
          ) : (
            <>
              <DataTable
                keyField="action_col"
                data={dataList}
                columns={tableColumns}
                sort={filters.sort}
                order={filters.order}
                onActionClick={onActionButtonClick}
              />
              <Row className="mt-3">
                <Col lg="3" md="4" className="d-flex">
                  <PageSizeSelector size={filters.pageSize} onChange={onSizeChange} />
                </Col>
                <Col lg="9" md="8" className="d-flex justify-content-end">
                  <Paging totalPages={totalPages} current={pageNumber} show={5} onSelect={onPageChange} />
                </Col>
              </Row>
            </>
          )}
        </CardBody>
      </Card>
      <LogDetail
        modal={state.modalDetail}
        toggle={toggleModal}
        row={state.row}
        refreshData={refreshDatatable}
      />
      <ConfirmModal
        modal={state.modalConfirm}
        toggle={toggleModal}
        row={state.row}
        onConfirm={onActionConfirm}
        message={state.message}
      />
    </>
  );
};

export default LogList;
