import React, { useEffect } from "react";
import { toastr } from "react-redux-toastr";
import { useDispatch, useSelector } from "react-redux";
import { alertTypes } from "../redux/constants";

const ToastrComponent = props => {
  return <div dangerouslySetInnerHTML={{ __html: props.message }} />;
};

const Notifications = () => {
  const dispatch = useDispatch();

  const alert = useSelector(state => state.alert);

  const showNofification = alert => {
    const options = {
      timeOut: 5000,
      showCloseButton: true,
      progressBar: true,
      position: "top-right",
      component: <ToastrComponent message={alert.message} />,
    };

    const toastrInstance =
      alert.type === "info"
        ? toastr.info
        : alert.type === "warning"
        ? toastr.warning
        : alert.type === "error"
        ? toastr.error
        : toastr.success;

    toastrInstance("", "", options);
  };

  useEffect(() => {
    if (alert && alert.message) {
      showNofification(alert);
      dispatch({ type: alertTypes.CLEAR });
    }
  }, [alert, dispatch]);

  return <></>;
};

export default Notifications;
