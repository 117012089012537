import React from "react";

import ReduxToastr from "react-redux-toastr";
import Routes from "./routes/Routes";

const App = () => (
  <>
    <Routes />
    <ReduxToastr
      timeOut={5000}
      newestOnTop
      position="top-right"
      transitionIn="fadeIn"
      transitionOut="fadeOut"
      progressBar
      closeOnToastrClick
    />
  </>
);

export default App;
