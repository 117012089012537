import React from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import PropTypes from "prop-types";
import moment from "moment";

import { featuredVideoAction } from "../../redux/reducers/videoFeaturedReducer";
import { startActionWithPromise } from "../../helpers/saga-promise-helpers";
import { useWaitingStatus } from "../../hooks/useWaiter";

const propTypes = {
  row: PropTypes.object,
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  refreshData: PropTypes.func,
};

const defaultProps = {
  row: {},
  modal: false,
  toggle: () => {},
  refreshData: () => {},
};

const VideoFeaturedDelete = props => {
  const dispatch = useDispatch();
  const isWaiting = useWaitingStatus(featuredVideoAction.delete.id);

  const { modal, row, refreshData, toggle: toggleModal } = props;

  const toggle = () => {
    if (toggleModal) toggleModal('modalDelete');
  };

  const successCallback = () => {
    if (refreshData) refreshData();
    toggle();
  };

  const failedCallback = () => {};

  const handleConfirm = async () => {
    try {
      await startActionWithPromise(
        featuredVideoAction.delete,
        { videoId: row.uid, successCallback, failedCallback },
        dispatch
      );
    } catch {}
  };

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      centered
      size="md"
      className="comfirmation-modal"
    >
      <ModalHeader toggle={toggle}>Confirmation</ModalHeader>
      <ModalBody className="">
        <div>
          <p className="font-weight-bold">
            Following video will be deleted from featured list:
          </p>
          { row && (
            <div className="request-detail p-3 border bg-light rounded mt-1">
              <div className="" style={{whiteSpace: 'pre-wrap', wordBreak: 'break-word'}}>
                <p className="mb-0">Title: <span>{row?.title}</span></p>
                <p className="mb-0">Subject: <span>{row?.subject}</span></p>
                <p className="mb-0">Topic: <span>{row?.topic}</span></p>
                <div className="mb-0 d-flex">
                  <div className="info-label mr-2 align-self-center">Author:</div>
                  <span className="align-self-center">{row.ownerName}</span> - <span className="align-self-center">{row.ownerEmail}</span>
                </div>
                <p className="mb-0">Show: <span>{row?.showCount}</span></p>
                <div className="mb-0">Ranking: <span>{row.rankingLevel}</span></div>
                <div className="mb-0">Markets: <span>{row.markets ? row.markets.join(', ') : ''}</span></div>
                <p className="mb-0">Valid start date: <span>{row.validStartDate ? moment(row.validStartDate).format('YYYY/MM/DD HH:mm:ss') : ''}</span></p>
                <p className="mb-0">Valid through date: <span>{row.validThruDate ? moment(row.validThruDate).format('YYYY/MM/DD HH:mm:ss') : ''}</span></p>
              </div>
            </div>
          )}
          <p className="font-weight-bold mt-2">Do you want to continue?</p>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" disabled={isWaiting} onClick={toggle}>
          No
        </Button>
        <Button
          className="ml-2"
          color="primary"
          disabled={isWaiting}
          onClick={() => handleConfirm(true)}
        >
          {isWaiting && (
            <>
              <Spinner color="secondary" size="sm" className="ml-2" />
              &nbsp;
            </>
          )}
          Yes
        </Button>
      </ModalFooter>
    </Modal>
  );
};

VideoFeaturedDelete.propTypes = propTypes;
VideoFeaturedDelete.defaultProps = defaultProps;

export default React.memo(VideoFeaturedDelete);
