import { takeLatest, call, put } from "redux-saga/effects";
import { sagaPromise } from "../../helpers/saga-promise-helpers";

import videoSubsService from "../../services/videoSubsService";
import {
    searchVideoSubsAction,
} from "../reducers/videoSubsReducer";

function* searchProductsWorker(action) {
    try {
        const { data, error } = yield call(
            videoSubsService.searchVideoSubscriptions,
            action.payload?.params
        );
        if (error) {
            yield put(searchVideoSubsAction.error(error));
        } else {
            yield put(searchVideoSubsAction.success({ ...action, data }));
        }
    } catch (err) {
        yield put(searchVideoSubsAction.error(err));
    }
}

function* videoSubsSaga() {
    yield takeLatest(
        searchVideoSubsAction.start,
        searchVideoSubsAction.waiterActionForSaga(sagaPromise(searchProductsWorker))
    );
}

export default videoSubsSaga;
