import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Spinner
} from "reactstrap";
import classNames from "classnames";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";

import DataTable from "../../components/DataTable";
import useMergeState from '../../hooks/mergeState';
import { useAuth } from "../../hooks/useAuth";

import FormFilter from "./FormFilter";
import ConfirmModal from "../../components/base/ConfirmModal";
import Selectbox from '../../components/Selectbox/Selectbox';

import { getCatalogsAction, submitJsonCatalogAction } from "../../redux/reducers/subscriptionReducer";
import { startActionWithPromise } from "../../helpers/saga-promise-helpers";
import "./index.scss";
import { getTableColumns } from "./TableColumns";

const CatalogVersions = ({user}) => {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    modalConfirm: false,
    action: '',
    message: '',
  });

  const defaultFilters = {
    reload: false,
  };

  const SUBMIT_CATALOG_ACTION = "SUBMIT JSON CATALOG";

  const [filters, setFilters] = useMergeState(defaultFilters);

  const [waiting, setWaiting] = useState(false);
  
  const auth = useAuth();
  const isAuthorized = auth.roles &&
    (auth.roles.indexOf('SYSTEM_ADMINS') !== -1 ||
    auth.roles.indexOf('SYSTEM_OPERATORS') !== -1);
  const fetching = false;
  const [selectedCatalogVersionPlans, setSelectedCatalogVersionPlans] = useState([]);
  const [catalogVersionOptions, setCatalogVersionOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const isBoAdmin = useSelector(state => state.bouser.isBoAdmin);

  const tableColumns = getTableColumns();

  const toggleModal = (modal) => {
    if (state[modal]) setState({ ...state, [modal]: !state[modal] });
    else setState({ ...state, [modal]: !state[modal], row: null });
  };

  const refreshDatatable = () => {
    setFilters({ ...filters, reload: true });
  };

  const [catalogVersionMap, setCatalogVersionMap] = useState(new Map());

  const handleCatalogVersionChange = (option) => {
    setSelectedCatalogVersionPlans(catalogVersionMap.get(option.value));
  }
  
  const fetchCatalogVersions = () => {
    (async () => {
      try {
        // setWaiting(true);
        const resp = await startActionWithPromise(
          getCatalogsAction,
          {successCallback: () => {}, failedCallback: () => {}},
          dispatch
        );
                
        catalogVersionMap.clear();
        let options = [];
        if (resp) {
          resp.forEach(catalogVersion => {
            options.push({label: catalogVersion.effectiveDate, value: catalogVersion.effectiveDate});
            catalogVersionMap.set(catalogVersion.effectiveDate, catalogVersion.plans);
          });
          setCatalogVersionOptions(options);
          setSelectedCatalogVersionPlans(catalogVersionMap.get(options[0].value));
          setSelectedOption(options[0]);
        } else {
          setCatalogVersionOptions([]);
          setSelectedCatalogVersionPlans([]);
          setSelectedOption(null);
        }
      } catch {}
      setWaiting(false);
    })();
  };

  useEffect(() => {
    if (filters.reload) {
      fetchCatalogVersions();
      setFilters({ ...filters, reload: false });
    }
  }, [filters.reload]);

  useEffect(() => {
    fetchCatalogVersions();
  }, []);

  ////////// Table's action handlers ///////////
  const handleSubmitButton = (effectiveDate) => {
    if (!effectiveDate) {
      return;
    }

    let message = `<p class="font-weight-bold">Start submitting a Catalog to KillBill systemn with Effective Date (Catalog Version) "${effectiveDate}".</p>\
                    <p class="font-weight-bold">Do you want to continue?</p>`;
    setState({
      ...state,
      modalConfirm: true,
      effectiveDate: effectiveDate,
      action: SUBMIT_CATALOG_ACTION,
      message: message,
    });
  }

  const submitJsonCatalog = (effectiveDate) => {
    const params = {
      effectiveDate: effectiveDate
    };
    
    (async () => {
      try {
        await startActionWithPromise(submitJsonCatalogAction,
          { ...params, successCallback: () => {
            setState({ ...state, modalConfirm: false});
          }, failedCallback: () => {} },
          dispatch
        );
      } catch {};
      refreshDatatable();
    })();
  };

  const onActionConfirm = () => {
    switch (state.action) {
      case SUBMIT_CATALOG_ACTION:
        submitJsonCatalog(state.effectiveDate);
        break;
      default:
    }
  };

  ////////////////////

  return (
    <Container fluid className="p-0">
      <h1 className="page-title">Catalog</h1>
      <Row>
        <Col lg="12" className="d-flex">
        <>
          <Card className={classNames('w-100 mb-0 datatable-wrap')}>
            <CardHeader className="">
              <FormFilter
                loading={fetching}
                submitAction={handleSubmitButton}
                isBoAdmin={isBoAdmin}
              />
            </CardHeader>
            <CardBody className="pt-0 data-list">
              <div className="d-flex align-items-center ml-3">
                <label htmlFor="" className="mb-0 text-nowrap text-right" style={{width: '140px'}}>Catalog Versions: </label>
                <div className="d-inline-block ml-2 item-filter__control" style={{width: '250px'}}>
                  <Selectbox
                    name="catalogVersion"
                    options={catalogVersionOptions}
                    isClearable={false}
                    value={selectedOption}
                    onChange={handleCatalogVersionChange}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center ml-3">
                &nbsp;
              </div>
              { waiting ? (
                <div className="loading-content text-center p-4 border-top">
                  <Spinner size="sm" />
                </div>
              ) : (
                <>
                  <DataTable
                    keyField="planName"
                    data={selectedCatalogVersionPlans}
                    columns={tableColumns}
                    hideSelectColumn={true}
                  />
                </>
              )}
            </CardBody>
          </Card>
          <ConfirmModal
            modal={state.modalConfirm}
            toggle={toggleModal}
            message={state.message}
            onConfirm={onActionConfirm}
          />
        </>
        </Col>
      </Row>
    </Container>
  );
};

export default CatalogVersions;
