import { takeLatest, call, put } from "redux-saga/effects";

import reportService from "../../services/reportService";
import {
  fetchVideoStatisticsAction,
  fetchVideoStatsAggAction
} from "../reducers/videoStatisticsReducer";
import { sagaPromise } from "../../helpers/saga-promise-helpers";
import { alertTypes } from "../constants";


function* fetchVideoStatisticsWorker(action) {
  try {
    const { data, error } = yield call(
      reportService.fetchVideoStatistics,
      action.payload?.params
    );
    if (error) {
      yield put(fetchVideoStatisticsAction.error(error));
    } else {
      yield put(fetchVideoStatisticsAction.success({ ...action, data }));
    }
  } catch (err) {
    yield put(fetchVideoStatisticsAction.error(err));
  }
}

function* fetchVideoStatsAggWorker(action) {
  const { data, error } = yield call(
    reportService.fetchVideoStatsAgg,
    action.payload?.params
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      yield put({
        type: alertTypes.ERROR,
        message: error.headers["x-message-info"],
      });
      throw new Error(error.headers["x-message-info"]);
    }
  }
  return data;
}

function* reviewSaga() {
  yield takeLatest(
    fetchVideoStatisticsAction.start,
    fetchVideoStatisticsAction.waiterActionForSaga(sagaPromise(fetchVideoStatisticsWorker))
  );
  yield takeLatest(
    fetchVideoStatsAggAction.start,
    fetchVideoStatsAggAction.waiterActionForSaga(sagaPromise(fetchVideoStatsAggWorker))
  );

}

export default reviewSaga;
