import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Spinner
} from "reactstrap";
import moment from "moment";
import classNames from "classnames";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";

import DataTable from "../../components/DataTable";
import PageSizeSelector from "../../components/base/PageSizeSelector";
import Paging from "../../components/base/Paging";
import config from "../../config/config";
import useMergeState from '../../hooks/mergeState';

import FormFilter from "./FormFilter";
import ConfirmModal from "../../components/base/ConfirmModal";

import { subscriptionActions, syncWithKillbillAction, cancelSubscriptionAction } from "../../redux/reducers/subscriptionReducer";
import { startActionWithPromise } from "../../helpers/saga-promise-helpers";
import { toDateString } from "../../helpers/utils";
import "./index.scss";
import { getTableColumns, rowActionTypes } from "./TableColumns";

const UserSubscriptions = ({user}) => {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    modalConfirm: false,
    action: '',
    message: '',
    row: null,
  });

  //const startDate = moment().startOf('month').subtract(1, 'months').toDate();
  //const endDate = moment().endOf('month').add(1,'M').toDate();  

  const defaultFilters = {
    pageSize: config.DEFAULT_PAGESIZE,
    pageNumber: 1,
    startDate: undefined,
    endDate: undefined,
    subscriberId: user ? user.userId : '',
    state: '',
    sort: {
      dir: `desc`,
      fieldName: `effectiveDate`,
    },
    reload: false,
  };
  const [filters, setFilters] = useMergeState(defaultFilters);


  const [waiting, setWaiting] = useState(false);
  
  const isBoAdmin = useSelector(state => state.bouser.isBoAdmin);
  const subscriptionList = useSelector(state => state.subscription?.list) || [];
  const fetching = useSelector(state => state.subscription?.fetching);
  const totalItems = useSelector(state => state.subscription?.total);
  const totalPages = useSelector(state => state.subscription?.totalPages);
  const pageNumber = useSelector(state => state.subscription?.pageNumber);

  const numItems = totalItems > filters.pageSize ? filters.pageSize : totalItems;
  const beginItem = numItems * (filters.pageNumber - 1);
  const endItem = numItems * filters.pageNumber;

  const onSizeChange = (size) => {
    setFilters({ ...filters, pageSize: size, pageNumber: 1, reload: true });
  };

  const onPageChange = (p) => {
    setFilters({ ...filters, pageNumber: p, reload: true });
  };

  const handleColumnSort = (field, order) => {
    setFilters({
      ...filters,
      sort: {
        dir: order,
        fieldName: field,
      },
      reload: true,
    });
  };

  const tableColumns = getTableColumns(handleColumnSort, isBoAdmin, state, setState);

  const toggleModal = (modal) => {
    if (state[modal]) setState({ ...state, [modal]: !state[modal] });
    else setState({ ...state, [modal]: !state[modal], row: null });
  };

  const refreshDatatable = () => {
    setFilters({ ...filters, reload: true });
  };

  const fetchUserSubscriptions = () => {
    (async () => {
      try {
        // setWaiting(true);
        let params = {
          ...filters,
          pageNumber: filters.pageNumber - 1,
          sort: {
            dir: filters.sort.dir.toUpperCase(),
            fieldName: filters.sort.fieldName,
          },
        };
        if ( params.startDate ) {
          params.startDate = toDateString(params.startDate);
        }
        if ( params.endDate ) {
          params.endDate = toDateString(params.endDate);
        }
        delete params.reload;

        if (!filters.subscriberId) delete params.subscriberId;
        if (!filters.state) delete params.state;

        await startActionWithPromise(
          subscriptionActions.fetch,
          {params, successCallback: () => {}, failedCallback: () => {}},
          dispatch
        );
      } catch {}
      setWaiting(false);
      // setFilters({ ...filters, reload: false });
    })();
  };

  useEffect(() => {
    if (filters.reload) {
      fetchUserSubscriptions();
      setFilters({ ...filters, reload: false });
    }
  }, [filters.reload]);

  useEffect(() => {
    fetchUserSubscriptions();
  }, []);

  ////////// Table's action handlers ///////////

  const onActionConfirm = () => {
    switch (state.action) {
      case rowActionTypes.CANCEL:
        cancelSubscription(state.row, false);
        break;
      case rowActionTypes.SYNC_WITH_KILLBILL:
        syncWithKillbill(state.row, false);
        break;
      default:
    }
  };

  const syncWithKillbill = (row) => {
    const params = {
      subscriptionId: row.subscriptionId
    };

    (async () => {
      try {
        await startActionWithPromise(syncWithKillbillAction,
          { ...params, successCallback: () => {
            setState({ ...state, modalConfirm: false});
          }, failedCallback: () => {} },
          dispatch
        );
      } catch {}
    })();
  };

  const cancelSubscription = (row) => {
    const params = {
      subscriptionId: row.subscriptionId
    };

    (async () => {
      try {
        await startActionWithPromise(cancelSubscriptionAction,
          { ...params, successCallback: () => {
            setState({ ...state, modalConfirm: false});
          }, failedCallback: () => {} },
          dispatch
        );
      } catch {}
    })();
  };
  ////////////////////

  return (
    <Container fluid className="p-0">
      <h1 className="page-title">User Subscriptions</h1>
      <Row>
        <Col lg="12" className="d-flex">
        <>
          <Card className={classNames('w-100 mb-0 datatable-wrap')}>
            <CardHeader className="">
              <FormFilter
                loading={fetching}
                filters={filters}
                mergeFilters={setFilters}
                paging={{
                  beginItem,
                  endItem,
                  totalItems
                }}
                user={user}
              />
            </CardHeader>
            <CardBody className="pt-0 data-list">
              { waiting ? (
                <div className="loading-content text-center p-4 border-top">
                  <Spinner size="sm" />
                </div>
              ) : (
                <>
                  <DataTable
                    keyField="subscriptionId"
                    data={subscriptionList}
                    columns={tableColumns}
                    order={filters.sort?.dir}
                    sort={filters.sort?.fieldName}
                    hideSelectColumn={true}
                  />
                  <Row className="mt-3">
                    <Col lg="3" md="4" className="d-flex">
                      <PageSizeSelector size={filters.pageSize} onChange={onSizeChange} />
                    </Col>
                    <Col lg="9" md="8" className="d-flex justify-content-end">
                      <Paging totalPages={totalPages} current={pageNumber} show={5} onSelect={onPageChange} />
                    </Col>
                  </Row>
                </>
              )}
            </CardBody>
          </Card>
          <ConfirmModal
            modal={state.modalConfirm}
            toggle={toggleModal}
            row={state.row}
            onConfirm={onActionConfirm}
            message={state.message}
          />
        </>
        </Col>
      </Row>
    </Container>
  );
};

export default UserSubscriptions;
