import React, { useState, useEffect } from "react";
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledButtonDropdown,
} from "reactstrap";
import PropTypes from "prop-types";
import config from "../../config/config";

const propTypes = {
  size: PropTypes.number,
  onChange: PropTypes.func,
};

const PageSizeSelector = props => {
  const [size, setSize] = useState(props.size || config.DEFAULT_PAGESIZE);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);

  const sizes = [5, 10, 20, 30, 50, 100];

  const changeSize = val => {
    setSize(val);
    if (props.onChange) props.onChange(val);
  };

  useEffect(() => {
    setSize(props.size);
  }, [props.size]);

  return (
    <UncontrolledButtonDropdown
      isOpen={dropdownOpen}
      toggle={toggle}
      direction="up"
      className="pageSize mr-1"
    >
      <DropdownToggle caret size="sm">
        {size}
      </DropdownToggle>
      <DropdownMenu>
        {sizes.map(val => (
          <DropdownItem
            key={`ps${val}`}
            onClick={() => changeSize(val)}
            disabled={size === val}
          >
            {val}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  );
};

PageSizeSelector.propTypes = propTypes;

export default PageSizeSelector;
