
import "./themes.scss";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { startActionWithPromise } from "../../helpers/saga-promise-helpers";
import useMergeState from '../../hooks/mergeState';
import {
    Card,
    CardHeader,
    CardBody,
    Container,
    Row,
    Col,
    Spinner,
    CardFooter
} from "reactstrap";

import config from "../../config/config";

import {
    searchVideoSubsAction
} from "../../redux/reducers/videoSubsReducer";

import DataTable from "../../components/DataTable";
import PageSizeSelector from "../../components/base/PageSizeSelector";
import Paging from "../../components/base/Paging";

import VideoSubsFilterForm from "./filterForm";
import { getGridColumns } from "./gridColumns";
import moment from "moment";

const VideoSubscriptions = () => {

    const dispatch = useDispatch();

    const startDate = moment().subtract(30, 'days').format('MM/DD/YYYY');
    const endDate = moment().format('MM/DD/YYYY');

    const [filters, setFilters] = useMergeState({
        pageSize: config.DEFAULT_PAGESIZE,
        pageNumber: 1,
        sort: 'purchaseDate',
        order: 'desc',
        reload: false,
        subscriberId: undefined,
        expired: false, // include expired subscriptions,
        startDate: startDate,
        endDate: endDate
    });

    const fetchData = (params) => {
        (async () => {
            try {
                await startActionWithPromise(
                    searchVideoSubsAction,
                    {
                        params,
                        successCallback: () => { },
                        failedCallback: () => { },
                    },
                    dispatch
                );
            } catch { }
        })();
    };

    useEffect(() => {
        if (filters.reload) {
            const params = {
                pageSize: filters.pageSize,
                pageNumber: filters.pageNumber - 1,
            };

            if (filters.subscriberId)
                params.subscriberId = filters.subscriberId;

            params.expired = filters.expired;

            if(filters.startDate)
                params.startDate = filters.startDate;

            if(filters.endDate)
                params.endDate = filters.endDate;

            if (filters.sort && filters.order) {
                params.fieldName = filters.sort;
                params.dir = filters.order.toUpperCase();
            }
            fetchData(params);
            setFilters({ ...filters, reload: false });
        }
    }, [filters.reload]);

    useEffect(() => {
        const params = {
            pageSize: filters.pageSize,
            pageNumber: filters.pageNumber - 1,
        };
        if (filters.sort && filters.order) {
            params.fieldName = filters.sort;
            params.dir = filters.order.toUpperCase();
        }
        fetchData(params);
    }, []);


    const handleColumnSort = (field, order) => {
        if (filters.sort !== field || filters.order !== order)
            setFilters({
                ...filters,
                sort: field,
                order: order,
                reload: true,
            });
    };


    const handleIncludeExpiredChange = (e) => {
        setFilters({ ...filters, expired: e.target.checked, reload: true });
    }

    const onSizeChange = (size) => {
        setFilters({ ...filters, pageSize: size, pageNumber: 1, reload: true });
    };

    const onPageChange = (p) => {
        setFilters({ ...filters, pageNumber: p, reload: true });
    };

    const gridColumns = getGridColumns(handleColumnSort);

    const videoSubsriptions = useSelector(state => state.videoSubsStates?.videoSubsriptions) || [];
    const fetching = useSelector(state => state.videoSubsStates?.fetching) || false;
    const totalPages = useSelector(state => state.videoSubsStates?.totalPages) || 0;
    const pageNumber = useSelector(state => state.videoSubsStates?.pageNumber) || 0;
    const totalCount = useSelector(state => state.videoSubsStates?.total) || 0;

    return (
        <>
            <h1 className="page-title">Course subscriptions</h1>
            <Card>
                <CardHeader>
                    <VideoSubsFilterForm
                        mergeFilters={setFilters}
                        loading={fetching}
                        subscriberId={filters.subscriberId}
                        expired = {filters.expired}
                        initStart={filters.startDate}
                        initEnd={filters.endDate}
                    />
                </CardHeader>
                <CardBody>
                    <Container fluid className="p-0">
                        <Row>
                            <Col lg="3" className="mt-1 mb-1 align-self-center">
                                <div className="item-filter video-type-filter">
                                    <input
                                        className="ml-1"
                                        name="include_expired"
                                        id="include_expired"
                                        type="checkbox"
                                        defaultChecked={false}
                                        value="INCLUDE_EXPIRED"
                                        onChange={handleIncludeExpiredChange}
                                    />
                                    <label htmlFor="include_expired" className="ml-2">Includes expired video subscriptions</label>
                                </div>
                            </Col>
                            <Col lg="9" className='d-flex'>
                                <div className="ml-auto">
                                    <span >Total video subscriptions found:</span>
                                    <span className="pl-1 totalLine">{totalCount}</span>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                {!fetching && (
                                    <DataTable
                                        keyField="id"
                                        data={videoSubsriptions}
                                        columns={gridColumns}
                                        sort={filters.sort}
                                        order={filters.order}
                                        hideSelectColumn={true}
                                    />
                                )}
                            </Col>
                        </Row>
                        {!fetching && (
                            <Row className="mt-3">
                                <Col lg="3" md="4" className="d-flex">
                                    <PageSizeSelector size={filters.pageSize} onChange={onSizeChange} />
                                </Col>
                                <Col lg="9" md="8" className="d-flex justify-content-end">
                                    <Paging totalPages={totalPages} current={pageNumber} show={5} onSelect={onPageChange} />
                                </Col>
                            </Row>
                        )}
                    </Container>
                </CardBody>
                <CardFooter>
                    <Container fluid className="p-0">
                        <Row>
                            <Col lg="12">
                            </Col>
                        </Row>
                    </Container>
                </CardFooter>
            </Card>
        </>
    );
};

export default VideoSubscriptions;