import { createReducer, createActionCRUDResources } from "redux-waiters";
import { RESET_REDUCER } from "../constants";

export const roleGroupActions = createActionCRUDResources("domain/roleGroups");

const initialState = {
  loading: false,
  groups: [],
  total: 0,
  totalPages: 0,
  pageNumber: 1,
  updating: false,
};

export default createReducer(
  {
    [roleGroupActions.fetch.start]: state => {
      return { ...state, loading: true };
    },
    [roleGroupActions.fetch.success]: (state, data) => {
      return {
        ...state,
        groups: data ? data.content : [],
        pageNumber: data ? data.pageNumber + 1 : 1,
        totalPages: data ? Math.ceil(data.total / data.pageSize) : 0,
        loading: false,
      };
    },
    [roleGroupActions.fetch.error]: state => {
      return { ...state, loading: false };
    },
    [roleGroupActions.create.success]: state => {
      return { ...state, updating: true };
    },
    [roleGroupActions.update.success]: state => {
      return { ...state, updating: true };
    },
    [RESET_REDUCER]: () => initialState,
  },
  initialState
);
