// alert
export const alertConstants = {
  SUCCESS: "ALERT_SUCCESS",
  ERROR: "ALERT_ERROR",
  CLEAR: "ALERT_CLEAR",
};

// Sidebar
export const SIDEBAR_VISIBILITY_TOGGLE = "SIDEBAR_VISIBILITY_TOGGLE";
export const SIDEBAR_VISIBILITY_SHOW = "SIDEBAR_VISIBILITY_SHOW";
export const SIDEBAR_VISIBILITY_HIDE = "SIDEBAR_VISIBILITY_HIDE";
export const SIDEBAR_STICKY_TOGGLE = "SIDEBAR_STICKY_TOGGLE";
export const SIDEBAR_STICKY_ENABLE = "SIDEBAR_STICKY_ENABLE";
export const SIDEBAR_STICKY_DISABLE = "SIDEBAR_STICKY_DISABLE";

// Layout
export const LAYOUT_BOXED_TOGGLE = "LAYOUT_BOXED_TOGGLE";
export const LAYOUT_BOXED_ENABLE = "LAYOUT_BOXED_ENABLE";
export const LAYOUT_BOXED_DISABLE = "LAYOUT_BOXED_DISABLE";

// Theme
export const THEME_TOGGLE = "THEME_TOGGLE";

export const RESET_REDUCER = "RESET_REDUCER";

// Users
export const userTypes = {
  LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USERS_LOGIN_FAILURE",

  GETALL_REQUEST: "USERS_GETALL_REQUEST",
  GETALL_SUCCESS: "USERS_GETALL_SUCCESS",
  GETALL_FAILURE: "USERS_GETALL_FAILURE",

  PROFILE_REQUEST: "USER_PROFILE_REQUEST",
  PROFILE_SUCCESS: "USER_PROFILE_SUCCESS",
  PROFILE_FAILURE: "USER_PROFILE_FAILURE",
};

export const roleTypes = {
  GET_ROLES_REQUEST: "GET_ROLES_REQUEST",
  GET_ROLES_SUCCESS: "GET_ROLES_SUCCESS",
  GET_ROLES_FAILURE: "GET_ROLES_FAILURE",

  USER_ROLES_REQUEST: "USER_ROLES_REQUEST",
  USER_ROLES_SUCCESS: "USER_ROLES_SUCCESS",
  USER_ROLES_FAILURE: "USER_ROLES_FAILURE",

  SET_USER_ROLES_REQUEST: "SET_USER_ROLES_REQUEST",
  SET_USER_ROLES_SUCCESS: "SET_USER_ROLES_SUCCESS",
  SET_USER_ROLES_FAILURE: "SET_USER_ROLES_FAILURE",

  DEL_USER_ROLES_REQUEST: "DEL_USER_ROLES_REQUEST",
  DEL_USER_ROLES_SUCCESS: "DEL_USER_ROLES_SUCCESS",
  DEL_USER_ROLES_FAILURE: "DEL_USER_ROLES_FAILURE",

  ADD_USER_ROLES_REQUEST: "ADD_USER_ROLES_REQUEST",
  ADD_USER_ROLES_SUCCESS: "ADD_USER_ROLES_SUCCESS",
  ADD_USER_ROLES_FAILURE: "ADD_USER_ROLES_FAILURE",

  SUB_USER_ROLES_REQUEST: "SUB_USER_ROLES_REQUEST",
  SUB_USER_ROLES_SUCCESS: "SUB_USER_ROLES_SUCCESS",
  SUB_USER_ROLES_FAILURE: "SUB_USER_ROLES_FAILURE",
};

export const alertTypes = {
  SUCCESS: "ALERT_SUCCESS",
  ERROR: "ALERT_ERROR",
  CLEAR: "ALERT_CLEAR",
};
