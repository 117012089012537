import React from "react";

import './index.scss'
import * as Icon from "react-feather";

export const getGridColumns = (handleSort = () => { }, handleOpenMessage) => {

  return [
    {
      dataField: "firstName",
      text: "Sender",
      sort: true,
      onSort: (field, order) => {
        handleSort(field, order);
      },
      formatter: (cell, row) => (
        <div>
          <p className="mb-0">{row.firstName} {row.lastName}</p>
          <p className="mb-0">{row.emailAddress}</p>
          <p>{row.sendAs}, {row.country}</p>
        </div>
      ),
      headerStyle: () => {
        return { width: "30%" };
      },
    },
    {
      dataField: "message",
      text: "Message",
      sort: false,
      headerStyle: () => {
        return { minWidth: "200px" };
      },
      formatter: (cell, row) => (
        <p className="messageText" >{row.message}</p>
      )
    },
    {
      dataField: "createdDate",
      text: "Status/created at",
      sort: false,
      headerStyle: () => {
        return { width: "160px" };
      },
      formatter: (cell, row) => (
        <div>
          <p>{row.status}</p>
          <p>{row.createdDate}</p>
        </div>
      )
    },
    {
      dataField: "",
      text: "",
      sort: false,
      headerStyle: () => {
        return { width: "160px" };
      },
      formatter: (cell, row) => (
        <div>
          <Icon.Edit style={{ width: "14px", cursor: 'pointer' }} onClick={(e) => handleOpenMessage(row)} />
        </div>
      )
    },
  ];
};
