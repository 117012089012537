import { takeLatest, call, put } from "redux-saga/effects";
import domainService from "../../services/domainService";
import { DOMAIN_PERMISSION_MESSAGES } from "../../config/messages";
import { sagaPromise } from "../../helpers/saga-promise-helpers";
import { permissionActions } from "../reducers/domainPermissionReducer";
import { alertTypes } from "../constants";

function* fetchPermissionsWorker(action) {
  const { data, error } = yield call(
    domainService.getDomainPermissions,
    action.payload?.params
  );
  if (error) {
    yield put(permissionActions.fetch.error(error));
    if (error?.headers["x-message-info"]) {
      throw new Error(error?.headers["x-message-info"]);
    }
  } else {
    yield put(permissionActions.fetch.success(data));
    return data;
  }
  return false;
}

function* addPermissionWorker(action) {
  const { data, error } = yield call(
    domainService.addPermission,
    {
      type: action.payload.type,
      action: action.payload.action,
      enabled: action.payload.enabled,
    }
  );
  if (error) {
    if (error?.headers["x-message-info"]) {
      yield put({
        type: alertTypes.ERROR,
        message: error.headers["x-message-info"],
      });
      throw new Error(error?.headers["x-message-info"]);
    }
  } else {
    yield put({
      type: alertTypes.SUCCESS,
      message: DOMAIN_PERMISSION_MESSAGES.addSuccess,
    });
    return data;
  }
  return false;
}

function* updatePermissionWorker(action) {
  const { data, error } = yield call(
    domainService.updatePermission,
    action.payload.id,
    action.payload.data
  );
  if (error) {
    if (error?.headers["x-message-info"]) {
      yield put({
        type: alertTypes.ERROR,
        message: error.headers["x-message-info"],
      });
      throw new Error(error?.headers["x-message-info"]);
    }
  } else {
    yield put({
      type: alertTypes.SUCCESS,
      message: DOMAIN_PERMISSION_MESSAGES.updateSuccess,
    });
    return data;
  }
  return false;
}

function* deletePermissionWorker(action) {
  const { data, error } = yield call(
    domainService.deletePermission,
    action.payload.id
  );
  if (error) {
    if (error?.headers["x-message-info"]) {
      yield put({
        type: alertTypes.ERROR,
        message: error.headers["x-message-info"],
      });
      throw new Error(error?.headers["x-message-info"]);
    }
  } else {
    yield put({
      type: alertTypes.SUCCESS,
      message: DOMAIN_PERMISSION_MESSAGES.deleteSuccess,
    });
    return data;
  }
  return false;
}

function* domainPermissionSaga() {
  yield takeLatest(permissionActions.fetch.start, fetchPermissionsWorker);
  yield takeLatest(
    permissionActions.create.start,
    permissionActions.create.waiterActionForSaga(
      sagaPromise(addPermissionWorker)
    )
  );
  yield takeLatest(
    permissionActions.update.start,
    permissionActions.update.waiterActionForSaga(
      sagaPromise(updatePermissionWorker)
    )
  );
  yield takeLatest(
    permissionActions.delete.start,
    permissionActions.delete.waiterActionForSaga(
      sagaPromise(deletePermissionWorker)
    )
  );
}

export default domainPermissionSaga;
