import { takeLatest, call, put } from "redux-saga/effects";

import reviewServices from "../../services/reviewService";
import { alertTypes } from "../constants";
import {
  searchPublishingsAction,
  assignAction,
  selfAssignAction,
  removeAssignAction,
  rejectVideoAction,
  approveVideoAction,
} from "../reducers/reviewReducer";
import { sagaPromise } from "../../helpers/saga-promise-helpers";
import { REVIEW_MESSAGES } from "../../config/messages";

function* fetchWorker(action) {
  try {
    const { data, error } = yield call(
      reviewServices.fetch,
      action.payload?.params
    );
    if (error) {
      yield put(searchPublishingsAction.error(error));
    } else {
      yield put(searchPublishingsAction.success({ ...action, data }));
    }
  } catch (err) {
    yield put(searchPublishingsAction.error(err));
  }
  // yield delay(500);
  // const { data, error } = yield call(
  //   reviewServices.fetch,
  //   action.payload.params
  // );
  // if (error) {
  //   if (error.headers["x-message-info"]) {
  //     throw new Error(error.headers["x-message-info"]);
  //   }
  // } else {
  //   yield put(searchPublishingsAction.success({ ...action, data }));
  //   return data;
  // }

  // return false;
}

function* assignWorker(action) {
  const { data, error } = yield call(
    reviewServices.assign,
    action.payload.id,
    action.payload.userId
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      if (!action.payload?.hideAlert) {
        yield put({
          type: alertTypes.ERROR,
          message: error.headers["x-message-info"],
        });
        throw new Error(error.headers["x-message-info"]);
      }
      return { message: error.headers["x-message-info"] };
    }
  } else {
    if (!action.payload?.hideAlert)
      yield put({
        type: alertTypes.SUCCESS,
        message: REVIEW_MESSAGES.assignSuccess,
      });

    return data;
  }

  return false;
}

function* selfAssignWorker(action) {
  const { data, error } = yield call(
    reviewServices.selfAssign,
    action.payload.id,
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      if (!action.payload?.hideAlert) {
        yield put({
          type: alertTypes.ERROR,
          message: error.headers["x-message-info"],
        });
        throw new Error(error.headers["x-message-info"]);
      }
      return { message: error.headers["x-message-info"] };
    }
  } else {
    if (!action.payload?.hideAlert) {
      yield put({
        type: alertTypes.SUCCESS,
        message: REVIEW_MESSAGES.selfAssignSuccess,
      });
    }
      

    return data;
  }

  return false;
}

function* removeAssignWorker(action) {
  const { data, error } = yield call(
    reviewServices.removeAssign,
    action.payload.id
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      yield put({
        type: alertTypes.ERROR,
        message: error.headers["x-message-info"],
      });
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    yield put({
      type: alertTypes.SUCCESS,
      message: REVIEW_MESSAGES.removeAssignSuccess,
    });
    return data;
  }

  return false;
}

function* rejectWorker(action) {
  const { data, error } = yield call(
    reviewServices.reject,
    action.payload.data
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      yield put({
        type: alertTypes.ERROR,
        message: error.headers["x-message-info"],
      });
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    yield put({
      type: alertTypes.SUCCESS,
      message: REVIEW_MESSAGES.rejectSuccess,
    });
    return data;
  }

  return false;
}

function* approveWorker(action) {
  const { data, error } = yield call(
    reviewServices.approval,
    action.payload.data
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      yield put({
        type: alertTypes.ERROR,
        message: error.headers["x-message-info"],
      });
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    yield put({
      type: alertTypes.SUCCESS,
      message: REVIEW_MESSAGES.approveSuccess,
    });
    return data;
  }

  return false;
}

function* reviewSaga() {
  yield takeLatest(searchPublishingsAction.start, fetchWorker);
  // yield takeLatest(
  //   searchPublishingsAction.start,
  //   searchPublishingsAction.waiterActionForSaga(sagaPromise(fetchWorker))
  // );

  yield takeLatest(
    assignAction.start,
    assignAction.waiterActionForSaga(sagaPromise(assignWorker))
  );

  yield takeLatest(
    selfAssignAction.start,
    selfAssignAction.waiterActionForSaga(sagaPromise(selfAssignWorker))
  );

  yield takeLatest(
    removeAssignAction.start,
    removeAssignAction.waiterActionForSaga(sagaPromise(removeAssignWorker))
  );

  yield takeLatest(
    rejectVideoAction.start,
    rejectVideoAction.waiterActionForSaga(sagaPromise(rejectWorker))
  );

  yield takeLatest(
    approveVideoAction.start,
    approveVideoAction.waiterActionForSaga(sagaPromise(approveWorker))
  );
}

export default reviewSaga;
