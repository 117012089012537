import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Badge,
  Spinner,
  UncontrolledTooltip
} from "reactstrap";
import * as Icon from "react-feather";
import moment from "moment";
import classNames from "classnames";
import { v4 as uuidv4 } from "uuid";

import DataTable from "../../components/DataTable";
import PageSizeSelector from "../../components/base/PageSizeSelector";
import Paging from "../../components/base/Paging";
import config from "../../config/config";

import LogDetail from "./LogDetail";
import VideoFilter from "./LogFilter";
import ConfirmModal from "../../components/base/ConfirmModal";

import { logActions } from "../../redux/reducers/logReducer";
import { startActionWithPromise } from "../../helpers/saga-promise-helpers";
import "./LogList.scss";

const Actions = {
  RETRANSCODE: 'Re-transcode',
  WITHDRAW: 'Withdraw'
};

const SourceSystems = {
  MM_WEB: 'MM_WEB',
  MM_IOS: 'MM_IOS',
  MM_ANDROID: 'MM_ANDROID',
  MM_DESKTOP: 'MM_DESKTOP',
}

const SourceSystemsCopy = {
  MM_WEB: 'Web',
  MM_IOS: 'iOs',
  MM_ANDROID: 'Android',
  MM_DESKTOP: 'Desktop',
}

const SourceSystemsColors = {
  [SourceSystems.MM_WEB]: 'success',
  [SourceSystems.MM_IOS]: 'secondary',
  [SourceSystems.MM_ANDROID]: 'info',
  [SourceSystems.MM_DESKTOP]: 'warning',
}

const LogList = () => {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    modalConfirm: false,
    modalDetail: false,
    action: '',
    message: '',
    row: null,
  });

  const startDate = moment().subtract(7, 'days').format('MM/DD/YYYY');
  const endDate = moment().format('MM/DD/YYYY');

  const [filters, setFilters] = useState({
    pageSize: config.DEFAULT_PAGESIZE,
    pageNumber: 1,
    sort: 'occuredTimestamp',
    sortReal: '',
    order: 'desc',
    startDate: startDate,
    endDate: endDate,
    sourceSystem: '',
    reload: false,
  });

  const [waiting, setWaiting] = useState(false);
  
  const logs = useSelector((state) => state.log?.list) || [];
  const fetching = useSelector(state => state.log?.fetching);
  const totalItems = useSelector((state) => state.log.total);
  const totalPages = useSelector((state) => state.log.totalPages);
  const pageNumber = useSelector((state) => state.log.pageNumber);

  const numItems = totalItems > filters.pageSize ? filters.pageSize : totalItems;
  const beginItem = numItems * (filters.pageNumber - 1);
  const endItem = numItems * filters.pageNumber;

  const dataList = logs.map(item => ({
    ...item,
    action_col: uuidv4(),
  }));

  const tableColumns = [
    {
      dataField: "summary",
      text: "Summary",
      sort: false,
      onSort: (field, order) => {
        onColumnSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div>
          <div className="sum">{row.summary}</div>
          <div className="req font-italic">{row.details}</div>
        </div>
      ),
    },
    {
      dataField: "sourceSystem",
      text: "Source",
      sort: true,
      formatter: (cell, row) => (
        <div className=" text-center">
          <div>
            <Badge
              color={SourceSystemsColors[row.sourceSystem]}
              className="mr-1 mb-1"
            >
              {SourceSystemsCopy[row.sourceSystem]}
            </Badge>
          </div>
        </div>
      ),
      headerStyle: () => {
        return { width: "95px", textAlign: "center" };
      },
    },
    {
      dataField: "platformVersion",
      text: "Platform version",
      sort: true,
      formatter: (cell, row) => (
        <div>
          {row.platformVersion}
        </div>
      ),
      headerStyle: () => {
        return { width: "10%" };
      },
    },
    {
      dataField: "errorCode",
      text: "Code",
      sort: true,
      onSort: (field, order) => {
        onColumnSort(field, order);
      },
      sortFunc: () => {
      },
      headerStyle: () => {
        return { width: "85px" };
      },
    },
    {
      dataField: "occuredTimestamp",
      text: "Occured at",
      sort: true,
      onSort: (field, order) => {
        onColumnSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div className=" text-center">
          {row.occuredTimestamp ? moment(row.occuredTimestamp, 'YYYY-MM-DDTHH:mm:ssZ').format('HH:mm:ss') : ''}<br/>
          {row.occuredTimestamp ? moment(row.occuredTimestamp, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY/MM/DD') : ''}
        </div>
      ),
      headerStyle: () => {
        return { width: "140px", textAlign: "center" };
      },
    },
    {
      dataField: "userId",
      text: "User id",
      sort: false,
      onSort: (field, order) => {
        onColumnSort(field, order);
      },
      sortFunc: () => {
      },
      formatter: (cell, row) => (
        <div>
          <div>{row.userId}</div>
        </div>
      ),
      headerStyle: () => {
        return { width: "20%" };
      },
    },
    {
      dataField: "action_col",
      text: "Actions",
      sort: false,
      formatter: (cell, row, rowIndex, formatExtraData) => (
        <div className="actions-col text-center">
          <a href="#" className="mr-2" onClick={e => formatExtraData.showLogDetail(row, e)} id={'detail-' + row.action_col}>
            <Icon.Eye className="text-dark" style={{ width: "15px" }} />
            <UncontrolledTooltip target={'detail-' + row.action_col}>
              Detail
            </UncontrolledTooltip>
          </a>
        </div>
      ),
      formatExtraData: {
        showLogDetail: (row, e) => {
          setState({ ...state, modalDetail: true, row });
        },
      },
      headerStyle: () => {
        return { width: "130px", textAlign: "center" };
      },
    },
  ];

  const onSizeChange = (size) => {
    setFilters({ ...filters, pageSize: size, pageNumber: 1, reload: true });
  };

  const onPageChange = (p) => {
    setFilters({ ...filters, pageNumber: p, reload: true });
  };

  const onColumnSort = (field, order) => {
    let realSort = '';
    switch (field) {
      default:
        realSort = field;
    }
    if (realSort === filters.sortReal && order === filters.order) return;

    setFilters({
      ...filters,
      sort: field,
      sortReal: realSort,
      order,
      reload: true,
    });
  };

  const onActionButtonClick = (action, row) => {
    switch (action.type) {
      case Actions.RETRANSCODE:
        setState({ ...state, modalRetranscode: true, row });
        break;
      case Actions.WITHDRAW:
        setState({ ...state, modalWithdraw: true, row });
        break;
      default:
    }
  };

  const toggleModal = (modal) => {
    if (state[modal]) setState({ ...state, [modal]: !state[modal] });
    else setState({ ...state, [modal]: !state[modal], row: null });
  };

  const onActionConfirm = () => {
    switch (state.action) {
      case Actions.RETRANSCODE:
        break;
      case Actions.WITHDRAW:
        break;
      default:
    }
  };

  const failedCallback = () => {};

  const refreshDatatable = () => {
    setFilters({ ...filters, reload: true });
  };

  const fetchDataSuccess = () => {}

  const fetchData = (params) => {
    (async () => {
      try {
        setWaiting(true);
        await startActionWithPromise(
          logActions.fetch,
          { params: params, successCallback: fetchDataSuccess, failedCallback },
          dispatch
        );
      } catch {}
      setWaiting(false);
    })();
  };

  const handleSubmitFilter = (filtersValues) => {
    setFilters({ ...filters, pageNumber: 1, ...filtersValues, reload: true });
  }

  useEffect(() => {
    if (filters.reload) {
      const params = {
        pageSize: filters.pageSize,
        pageNumber: filters.pageNumber - 1,
        sourceSystem: filters.sourceSystem,
        startDate: filters.startDate,
        endDate: filters.endDate,
        sort: {
          dir: `${filters.order.toUpperCase()}`,
          fieldName: `${filters.sort}`,
        },
      };

      if (!filters.sourceSystem) delete params.sourceSystem;

      fetchData(params);
      setFilters({ ...filters, reload: false });
    }
  }, [filters.reload]);

  return (
    <>
      <Card className={classNames('w-100 mb-0 video-review-list')}>
        <CardHeader className="">
          <VideoFilter
            onSubmitFilter={handleSubmitFilter}
            loading={fetching}
            startDate={startDate}
            endDate={endDate}
            paging={{
              beginItem,
              endItem,
              totalItems
            }}
          />
        </CardHeader>
        <CardBody className="pt-0 data-list">
          { waiting ? (
            <div className="loading-content text-center p-4 border-top">
              <Spinner size="sm" />
            </div>
          ) : (
            <>
              <DataTable
                keyField="action_col"
                data={dataList}
                columns={tableColumns}
                sort={filters.sort}
                order={filters.order}
                onActionClick={onActionButtonClick}
              />
              <Row className="mt-3">
                <Col lg="3" md="4" className="d-flex">
                  <PageSizeSelector
                    size={filters.pageSize}
                    onChange={onSizeChange}
                  />
                  
                </Col>
                <Col lg="9" md="8" className="d-flex justify-content-end">
                  <Paging
                    totalPages={totalPages}
                    current={pageNumber}
                    show={5}
                    onSelect={onPageChange}
                  />
                </Col>
              </Row>
            </>
          )}
        </CardBody>
      </Card>
      <LogDetail
        modal={state.modalDetail}
        toggle={toggleModal}
        row={state.row}
        refreshData={refreshDatatable}
      />
      <ConfirmModal
        modal={state.modalConfirm}
        toggle={toggleModal}
        row={state.row}
        onConfirm={onActionConfirm}
        message={state.message}
      />
    </>
  );
};

export default LogList;
