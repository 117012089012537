import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
} from "reactstrap";
import PropTypes from "prop-types";
import moment from "moment";

import { AUDIT_TYPE_COPY } from "./constants";

const propTypes = {
  modal: PropTypes.bool,
  toggle: PropTypes.func,
};

const defaultProps = {
  modal: false,
  toggle: () => {},
};

const boldStyles = {
  marginBottom: '0.5rem',
  fontWeight: '600',
  lineHeight: '1.2',
  color: '#000',
}

const ObjectView = ({obj}) => {
  try {
    if (typeof obj === 'object') {
      if (Array.isArray(obj)) {
        return (
          <>
            {obj.map((item, i) => 
              <ObjectView key={i} obj={item} />
            )}
          </>
        )
      }
      return (
        <div className="">
          {
            Object.keys(obj).sort().map((key, i) => (
              <ObjectField key={i} fieldName={key} fieldValue={obj[key]} />
            ))
          }
        </div>
      )
    } else {
      return (
        <>
          <div className="field-value">{String(obj)}</div>
        </>
      )
    }
  } catch {}
}
const ObjectField = ({fieldName, fieldValue}) => {
  let valueType = typeof fieldValue;
  switch(valueType) {
    case 'string':
    case 'number':
      return (
        <div className="field-group mb-1">
          <div className="field-name">{fieldName}: <span className="field-value font-italic">{fieldValue}</span></div>
          
        </div>
      )
    case 'boolean':
      return (
        <div className="field-group mb-1">
          <div className="field-name">{fieldName}: <span className="field-value font-italic">{fieldValue ? 'true' : 'false'}</span></div>
          
        </div>
      )
    case 'object':
      return (
        <div className="field-group mb-1">
          <div className="field-name">{fieldName}:</div>
          <div className="field-value sub-values" style={{paddingLeft: '10px'}}>
            <ObjectView obj={fieldValue} />
          </div>
        </div>
      )
    default:
      return <></>
  }
}

const LogDetail = props => {
  const { modal, toggle, row: log } = props;

  const toggleModal = () => {
    if (toggle) toggle('modalDetail');
  };

  return (
    <Modal isOpen={modal} toggle={toggleModal} centered size="lg">
      <ModalHeader toggle={toggleModal}>
        Auditlog detail
      </ModalHeader>
      <ModalBody className="">
        
        <Row>
          <Col lg="12">
            { log && (
              <div className="request-detail">
                <p className="mb-2"><span style={boldStyles}>User:</span> {log.createdBy}</p>
                <p className="mb-2"><span style={boldStyles}>Type:</span> {AUDIT_TYPE_COPY[log.entityType]||log.entityType}</p>
                <p className="mb-2"><span style={boldStyles}>Action:</span> {log.action}</p>
                {log.reason && (
                  <div className="mb-2">
                    <h5>Reason: </h5>
                    <div>
                      <textarea rows="3" className="form-control" readOnly defaultValue={log.reason} />
                    </div>
                  </div>
                )}
                <p className="mb-2"><span style={boldStyles}>Audited at:</span> {log.createdDate ? moment(log.createdDate).utc().format('YYYY/MM/DD HH:mm:ss') : ''}</p>
                
                <div className="mb-3">
                  <h5 className="mb-1">Target: </h5>
                  {/* <TargetInfo row={log} /> */}
                  <ObjectView obj={log.targetInfo} />
                </div>
                {log.fromValue && (
                  <div className="data-values mb-3">
                    <h5 className="mb-1">From value:</h5>
                    {/* <FromValue log={log}/> */}
                    <ObjectView obj={log.fromValue} />
                  </div>
                )}
                {log.toValue && (
                  <div className="data-values">
                  <h5 className="mb-1">To value:</h5>
                  {/* <ToValue log={log} /> */}
                  <ObjectView obj={log.toValue} />
                </div>
                )}
              </div>
            )}
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

LogDetail.propTypes = propTypes;
LogDetail.defaultProps = defaultProps;

export default LogDetail;
