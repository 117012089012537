import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import PropTypes from "prop-types";

const propTypes = {
  row: PropTypes.object,
  modal: PropTypes.bool,
  message: PropTypes.string,
  toggle: PropTypes.func,
  onConfirm: PropTypes.func,
};

const defaultProps = {
  modal: false,
  message: "",
  toggle: () => {},
  onConfirm: () => {},
};

const NewVersionModal = props => {
  const toggle = () => {
    if (props.toggle) props.toggle("newVersionModal");
  };

  const handleConfirm = () => {
    window.location.reload();
  }

  return (
    <Modal
      isOpen={props.modal}
      toggle={toggle}
      centered
      size="sm"
      className="comfirmation-modal"
    >
      <ModalHeader toggle={toggle}>Notification</ModalHeader>
      <ModalBody className="m-3">
        <p className="font-weight-bold">A new version of back office is available.</p>
        <p className="font-weight-bold">Refresh to get latest version.</p>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Later
        </Button>{" "}
        <Button color="primary" onClick={handleConfirm}>
          Refresh
        </Button>
      </ModalFooter>
    </Modal>
  );
};

NewVersionModal.propTypes = propTypes;
NewVersionModal.defaultProps = defaultProps;

export default React.memo(NewVersionModal);
