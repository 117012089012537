import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
} from "reactstrap";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import styled from "styled-components";

import config from "../../config/config";
import { featuredVideoAction } from "../../redux/reducers/videoFeaturedReducer";
import { startActionWithPromise } from "../../helpers/saga-promise-helpers";
import useMergeState from "../../hooks/mergeState";
import {
    MARKETS
  } from "./constants";

const propTypes = {
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  refreshData: PropTypes.func,
};

const defaultProps = {
  modal: false,
  toggle: () => {},
  refreshData: () => {},
};

const StyledDatetimeDiv = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
  }
`;

const getDatetimeStr = (date) => {
  if (!date) return '';
  return `${date.getFullYear()}-${_.padStart(date.getMonth() + 1, 2, 0)}-${_.padStart(date.getDate(), 2, 0)}T${_.padStart(date.getHours(), 2, 0)}:${_.padStart(date.getMinutes(), 2, 0)}:00.000+00:00`;
}

const VideoFeaturedUpdate = props => {
  const dispatch = useDispatch();

  const { modal, toggle, refreshData, row } = props;

  const fetching = false; // useSelector(state => state.user?.fetching);
  const [waiting, setWaiting] = useState(false);
  const [pristine, setPristine] = useState(true);
  const defaultMarkets = MARKETS ? [MARKETS[0].value] : [];

  const [formState, setFormState] = useMergeState({
    enabled: row ? row.enabled : true,
    ownerEmail: row ? row.ownerEmail : '',
    ownerId: row ? row.ownerName : '',
    rankingLevel: row ? row.rankingLevel : 0,
    showCount: row ? row.showCount : 0,
    uid: row ? row.uid : '',
    validStartDate: row ? new Date(moment(row.validStartDate).format('YYYY-MM-DD HH:mm')) : '',
    validThruDate: row && row.validThruDate ? new Date(moment(row.validThruDate).format('YYYY-MM-DD HH:mm')) : '',
    markets: row && row.markets ? row.markets : defaultMarkets,
  });

  const [selectedRows, setSelectedRows] = useState([]);
  const [minDate, setMinDate] = useState(formState.validStartDate);
  const [maxDate, setMaxDate] = useState(formState.validThruDate);
  const [errorMessage, setErrorMessage] = useState('');

  const [filters, setFilters] = useState({
    pageSize: config.DEFAULT_PAGESIZE,
    pageNumber: 1,
    keywords: '',
    videoId: '',
    videoOwner: '',
    videoUser: '',
    reload: false,
  });

  const handleChangeInput = (e) => {
    let inputValue = e.target.type === 'number' ? parseInt(e.target.value) : e.target.value;
    setFormState({[e.target.name]: inputValue});
    setPristine(false);
  }

  const handleCheckedChange = (value) => {
    setPristine(false);
    let isChecked = formState.markets.find(x => x === value);
    let checkedList = isChecked ? formState.markets.filter(x => x !== value) : [...formState.markets, value];

    setFormState({markets: checkedList});
  }

  const addSuccessCallback = () => {
    if (refreshData) refreshData();
    toggle("modalUpdate");
  };

  const handleSubmit = async () => {
    setErrorMessage('');

    if (!formState.validStartDate) {
      setErrorMessage('Please set valid start date.');
      return;
    }

    // if (!formState.validThruDate) {
    //   setErrorMessage('Please set valid to date.');
    //   return;
    // }

    if (!formState.markets || formState.markets.length === 0) {
      setErrorMessage('Please select market.');
      return;
    }
    
    const postData = selectedRows && selectedRows.length > 0 ? {
      enabled: formState.enabled,
      rankingLevel: formState.rankingLevel,
      markets: formState.markets,
      validStartDate: getDatetimeStr(formState.validStartDate),
      validThruDate: formState.validThruDate ? getDatetimeStr(formState.validStartDate) : '',
      uid: selectedRows[0].uid,
      ownerEmail: selectedRows[0].authorEmail,
      ownerId: selectedRows[0].authorId,
      ownerName: selectedRows[0].author,
      subject: selectedRows[0].subject,
      title: selectedRows[0].title,
      topic: selectedRows[0].topic,
    }
    : {
      ...row,
      enabled: formState.enabled,
      rankingLevel: formState.rankingLevel,
      markets: formState.markets,
      validStartDate: getDatetimeStr(formState.validStartDate),
      validThruDate: formState.validThruDate ? getDatetimeStr(formState.validThruDate) : '',
    };

    setWaiting(true);
    try {
      await startActionWithPromise(
        featuredVideoAction.update,
        {
          data: postData,
          successCallback: addSuccessCallback,
          failedCallback: () => {},
        },
        dispatch
      );
    } catch {}
  };

  return (
    <Modal
      isOpen={modal}
      toggle={() => toggle("modalUpdate")}
      centered
      size="lg"
      className="add-trusted-modal add-featured-video-modal"
    >
      <ModalHeader>Update featured video</ModalHeader>
      <ModalBody className="pt-0 pb-0">
        <Card className="w-100 mb-0">
          <CardHeader className="">
            
          </CardHeader>
          <CardBody className="pt-0 pb-0 data-list position-relative">
            <Row>
              <Col>
              <p className="font-weight-bold mb-1">Video information: </p>
              { row && (
                <div className="request-detail p-3 border bg-light rounded mt-1">
                  <div className="" style={{whiteSpace: 'pre-wrap', wordBreak: 'break-word'}}>
                    <p className="mb-0">Title: <span>{row?.title}</span></p>
                    <p className="mb-0">Subject: <span>{row?.subject}</span></p>
                    <p className="mb-0">Topic: <span>{row?.topic}</span></p>
                    <div className="mb-0 d-flex">
                      <div className="info-label mr-2 align-self-center">Author:</div>
                      <span className="align-self-center">{row.ownerName}</span> - <span className="align-self-center">{row.ownerEmail}</span>
                    </div>
                    <p className="mb-0">Show: <span>{row?.showCount}</span></p>
                    {/* <div className="mb-0">Ranking: <span>{row.rankingLevel}</span></div> */}
                    {/* <div className="mb-0">Markets: <span>{row.markets ? row.markets.join(', ') : ''}</span></div> */}
                    {/* <p className="mb-0">Valid start date: <span>{row.validStartDate ? moment(row.validStartDate).format('YYYY/MM/DD HH:mm:ss') : ''}</span></p> */}
                    {/* <p className="mb-0">Valid through date: <span>{row.validThruDate ? moment(row.validThruDate).format('YYYY/MM/DD HH:mm:ss') : ''}</span></p>s */}
                  </div>
                </div>
              )}
              </Col>
            </Row>
            <Row className="mt-4">
              <Col>
                <FormGroup>
                  <div>
                    <FormGroup inline check>
                      <Label>
                        <input
                          className="form-check-input"
                          name="enabled"
                          id="enabled"
                          type="checkbox"
                          value={formState.enabled}
                          checked={formState.enabled}
                          onChange={() => setFormState({enabled: !formState.enabled})}
                        />
                        <span>Enabled</span>
                      </Label>
                    </FormGroup>
                    
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label for="rankingLevel" className="">
                    Ranking level
                  </Label>
                  <input
                    className="form-control"
                    name="rankingLevel"
                    id="rankingLevel"
                    type="number"
                    min="0"
                    value={formState.rankingLevel}
                    onChange={handleChangeInput}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="phone" className="">
                    Valid start date
                  </Label>
                  <StyledDatetimeDiv>
                    <DatePicker
                      selected={formState.validStartDate}
                      onChange={(date) => {setFormState({validStartDate: date}); setMinDate(date); }}
                      maxDate={maxDate}
                      timeInputLabel="Time:"
                      dateFormat="MM/dd/yyyy HH:mm"
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      // showTimeSelect
                      className="w-100"
                      showTimeInput
                    />
                  </StyledDatetimeDiv>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="phone" className="">
                    Valid through date
                  </Label>
                  <StyledDatetimeDiv>
                    <DatePicker
                      selected={formState.validThruDate}
                      onChange={(date) => {setFormState({validThruDate: date}); setMaxDate(date);}}
                      minDate={minDate}
                      timeInputLabel="Time:"
                      dateFormat="MM/dd/yyyy HH:mm"
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      // showTimeSelect
                      className="w-100"
                      showTimeInput
                    />
                  </StyledDatetimeDiv>
                  
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label>
                    Markets
                  </Label>
                  <div>
                    {MARKETS.map(item => (
                      <FormGroup
                        check
                        inline
                        className="mr-4"
                        key={`market-check--${item.value}`}
                      >
                        <Label check>
                          <input
                            className="form-check-input"
                            name="markets"
                            type="checkbox"
                            // defaultChecked={_.find(
                            //   currentRole.permissions,
                            //   {
                            //     id: column.perm.id,
                            //   }
                            // )}
                            value={item.value}
                            checked={formState.markets.find(x => x === item.value) || false}
                            onChange={() => handleCheckedChange(item.value)}
                            // ref={register}
                          />
                          <span>
                          {item.label}
                          </span>
                        </Label>
                      </FormGroup>
                    ))}
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </ModalBody>
      <ModalFooter>
        {errorMessage && (
          <div className="float-left text-danger">
            {errorMessage}
          </div>
        )}
        <Button
          color="secondary"
          disabled={fetching}
          onClick={() => toggle("modalUpdate")}
        >
          Cancel
        </Button>
        <Button
          className="ml-2"
          color="primary"
          disabled={waiting}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};

VideoFeaturedUpdate.propTypes = propTypes;
VideoFeaturedUpdate.defaultProps = defaultProps;

export default VideoFeaturedUpdate;
