import { createReducer, createActionCRUDResources } from "redux-waiters";
import { RESET_REDUCER } from "../constants";

export const roleActions = createActionCRUDResources("domain/roles");
export const roleGroupActions = createActionCRUDResources("domain/roleGroups");

const initialState = {
  loading: false,
  roles: [],
  total: 0,
  totalPages: 0,
  pageNumber: 1,
  permissions: [],
  updating: false,
};

export default createReducer(
  {
    [roleActions.fetch.start]: state => {
      return { ...state, loading: true };
    },
    [roleActions.fetch.success]: (state, data) => {
      return {
        ...state,
        roles: data?.content,
        pageNumber: data?.pageNumber + 1,
        totalPages: Math.ceil(data?.total / data?.pageSize),
        loading: false,
      };
    },
    [roleActions.fetch.error]: state => {
      return { ...state, loading: false };
    },
    [roleActions.create.success]: state => {
      return { ...state, updating: true };
    },
    [roleActions.update.success]: state => {
      return { ...state, updating: true };
    },
    [RESET_REDUCER]: () => initialState,
  },
  initialState
);
