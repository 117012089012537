import React, { useState } from "react";
import { nanoid } from 'nanoid';
import { Tooltip } from 'reactstrap';
import * as Icon from "react-feather";

export const rowActionTypes = {
  EDIT: "ROW_ACTION_EDIT",
  VIEW: "ROW_ACTION_VIEW",
  DELETE: "ROW_ACTION_DELETE",
  APPROVE: "ROW_ACTION_APPROVE",
  REJECT: "ROW_ACTION_REJECT",
  ENABLE: "ROW_ACTION_ENABLE",
  DISABLE: "ROW_ACTION_DISABLE",
  LOGOUT: "ROW_ACTION_LOGOUT",
  RESET_PW: "ROW_ACTION_RESET_PW",
  CHANGE_PW: "ROW_ACTION_CHANGE_PW",
  SET_ROLES: "ROW_ACTION_SET_ROLES",
  REVOKE_ALL_ROLES: "ROW_ACTION_REVOKE_ALL_ROLES",
  STAR: "ROW_ACTION_STAR",
  USER_ADD: "ROW_ACTION_USER_ADD",
  RESEND_EMAIL: "ROW_ACTION_RESEND_EMAIL",
  USER_ORDERS: "ROW_ACTION_USER_ORDERS",
  SYNC_STORAGE_PACKAGE: "ROW_ACTION_SYNC_STORAGE_PACKAGE",
};

const ActionButtonIcons = {
  [rowActionTypes.EDIT]: <Icon.Edit style={{ width: "14px" }} />,
  [rowActionTypes.VIEW]: <Icon.Eye style={{ width: "14px" }} />,
  [rowActionTypes.APPROVE]: <Icon.CheckCircle style={{ width: "14px" }} />,
  [rowActionTypes.REJECT]: <Icon.XCircle style={{ width: "14px" }} />,
  [rowActionTypes.DELETE]: <Icon.Trash2 style={{ width: "14px" }} />,
  [rowActionTypes.LOGOUT]: <Icon.LogOut style={{ width: "14px" }} />,
  [rowActionTypes.CHANGE_PW]: <i className="fuvi-icon fuvi-change-user-password-ro" />,
  [rowActionTypes.RESET_PW]: <i className="fuvi-icon fuvi-change-user-password" />,
  [rowActionTypes.SET_ROLES]: <i className="fuvi-icon fuvi-user-role" />,
  [rowActionTypes.REVOKE_ALL_ROLES]: <i className="fuvi-icon fuvi-remove-user-role" />,
  [rowActionTypes.STAR]: <Icon.Star style={{ width: "14px" }} />,
  [rowActionTypes.USER_ADD]: <Icon.UserPlus style={{ width: "14px" }} />,
  [rowActionTypes.ENABLE]: <Icon.CheckCircle className="text-success" style={{ width: "14px" }} />,
  [rowActionTypes.DISABLE]: <Icon.Slash className="text-muted" style={{ width: "14px" }} />,
  [rowActionTypes.RESEND_EMAIL]: <Icon.Mail className="text-muted" style={{ width: "14px" }} />,
  [rowActionTypes.USER_ORDERS]: <Icon.ShoppingBag className="text-muted" style={{ width: "14px" }} />,
  [rowActionTypes.SYNC_STORAGE_PACKAGE]: <Icon.RefreshCw className="text-muted" style={{ width: "14px" }} />,
}

const TooltipIcon = ({children, tooltipId, row, action, handleClick}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <React.Fragment>
      <a
        className={`col-action-btn d-inline-block p-1 ${action.classes||''}`}
        href="#"
        id={'tooltip_' + tooltipId}
        onClick={e => handleClick(action, row, e)}
      >
        {children}
      </a>
      <Tooltip placement="top" isOpen={tooltipOpen} target={'tooltip_' + tooltipId} toggle={toggle} trigger="hover">
        {action.name}
      </Tooltip>
    </React.Fragment>
  );
}

const ActionListItem = ({action, row, onClick}) => {
  let buttonText = "";

  if (ActionButtonIcons[action.type]) {
    buttonText = ActionButtonIcons[action.type];
  } else {
    buttonText = <span>{action.name}</span>;
  }

  return (
    buttonText && (
      <TooltipIcon
        key={`${nanoid()}-key`}
        handleClick={onClick}
        tooltipId={nanoid()}
        row={row}
        action={action}
      >
        {buttonText}
      </TooltipIcon>
    )
  );
}

const ActionList = ({actions, row, onClick}) => {
  const handleClick = (action, row) => {
    if (onClick) onClick(action, row);
  }

  return (
    <div className="actions-col">
      {actions.map((action, i) => (
        <ActionListItem key={`action-item--${i}`} action={action} row={row} onClick={() => handleClick(action, row)} />
      ))}
    </div>
  )
}

export default ActionList;