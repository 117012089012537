import React, { useState } from "react";
import { Row, Col, Button, UncontrolledTooltip } from "reactstrap";
import _ from 'lodash';
import * as Icon from "react-feather";
import classNames from "classnames";
import styled from "styled-components";
import DatePicker from "react-datepicker";

import "./FormFilter.scss";
import SearchUserInput from "./SearchUserInput";
import Selectbox from '../../components/Selectbox/Selectbox';
import { REVENUE_TRANSFER_STATES, REVENUE_TRANSFER_STATE_LABELS } from "./constants";

const StyledDatetimeDiv = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker__input-container {
    input {
      width: 230px;
    }
    
  }
`;

let StateOptions = [{
  label: 'Transferred?',
  value: '',
}];
Object.entries(REVENUE_TRANSFER_STATES).forEach(([key, value]) => {
  StateOptions.push({
    label: REVENUE_TRANSFER_STATE_LABELS[value], // ucFirst(key)
    value: value,
  });
});

const FormFilter = ({handleRecalculate, handleTransfer, loading, paging, mergeFilters, user}) => {
  const [minYearMonth, setMinYearMonth] = useState('');
  const [maxYearMonth, setMaxYearMonth] = useState('');

  const [filters, setFilters] = useState({
    startYearMonth: '',
    endYearMonth: '',
    userId: user ? user.userId : '',
    transferred: '',
    transferDate: '',
    reload: false,
  });

  const handleUserChange = (option) => {
    setFilters({ ...filters, userId: option ? option.value : ''});
  }

  const handleTransferredChange = (option) => {
    if (option && (option.value == true || option.value == false) ) 
        setFilters({ ...filters, transferred: option.value, reload: true });
    else 
        setFilters({ ...filters, transferred: '', reload: true });
  }

  const submitFilter = () => {
    // console.log(mergeFilters);
    mergeFilters({...filters, reload: true});
  }

  return (
    <>
      <Row className={classNames('video-review-filter')} style={{transition: 'visibility 0.5s, opacity 0.5s linear'}}>
        <Col lg="12" md="12" xs="12" className="">
        <div className="item-filter float-right">
            <div className="d-flex align-items-center">
              
              <div className="d-flex align-items-center ml-3">
                <label htmlFor="" className="mb-0 text-nowrap text-right" style={{width: '80px'}}>User</label>
                <div className="d-inline-block ml-2 item-filter__control" style={{width: '200px'}}>
                  <SearchUserInput
                    onChange={handleUserChange}
                    className="w-100"
                    isClearable={true}
                    name="userId"
                    />
                </div>
              </div>
              <div className="filter-status ml-3" style={{width: '200px'}}>
                <Selectbox
                  name="search_state"
                  placeholder="Transferred?"
                  options={StateOptions}
                  onChange={handleTransferredChange}
                />
              </div>
              <div className="d-flex align-items-center ml-3">
                <label htmlFor="" className="mb-0 text-nowrap text-right" style={{width: '100px'}}>Month/Year from</label>
                <StyledDatetimeDiv className="d-inline-block item-filter__control ml-2">
                  <DatePicker
                    selected={filters.startYearMonth}
                    onChange={(date) => {setFilters({...filters, startYearMonth: date}); setMinYearMonth(date)}}
                    maxDate={maxYearMonth}
                    dateFormat="MM/yyyy"
                    className="w-100"
                    showMonthYearPicker="true"
                    //style={{width: '230px'}}
                  />
                </StyledDatetimeDiv>
              </div>

              <div className="d-flex align-items-center ml-3">
                <label htmlFor="" className="mb-0 text-nowrap text-right" style={{width: '30px'}}>to</label>
                <StyledDatetimeDiv className="d-inline-block item-filter__control ml-2">
                  <DatePicker
                    selected={filters.endYearMonth}
                    onChange={(date) => {setFilters({...filters, endYearMonth: date}); setMaxYearMonth(date)}}
                    minDate={minYearMonth}
                    dateFormat="MM/yyyy"
                    className="w-100"
                    showMonthYearPicker="true"
                  />
                </StyledDatetimeDiv>
              </div>
              <div className="d-flex align-items-center ml-3">
                <label htmlFor="" className="mb-0 text-nowrap text-right" style={{width: '90px'}}>Transfer Date</label>
                <StyledDatetimeDiv className="d-inline-block item-filter__control ml-2">
                  <DatePicker
                    selected={filters.transferDate}
                    onChange={(date) => {setFilters({...filters, transferDate: date});}}
                    dateFormat="MM/dd/yyyy"
                    className="w-100"
                  />
                </StyledDatetimeDiv>
              </div>
              <div className="d-flex align-items-center">
                <Button
                  className="ml-2"
                  size=""
                  disabled={loading}
                  onClick={(e) => submitFilter(e)}
                  style={{minWidth: '86px'}}
                >
                  <Icon.Search style={{ width: '15px', height: '15px', marginTop: '-2px' }} /> Search
                </Button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col lg="12" md="12" xs="12" className="">
        <div className="item-filter float-left">
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-left ml-3">
                <Button color="info" size="sm" onClick={(e) => {handleTransfer();}} id="transferMonthlyRevenuesBtn">
                  <Icon.CornerUpRight height="18" />
                  Transfer Monthly Revenues
                  <UncontrolledTooltip target="transferMonthlyRevenuesBtn">
                    Transfer selected revenues
                  </UncontrolledTooltip>
                </Button>
              </div>
            </div>
          </div>
          <div className="item-filter float-right">
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-right ml-3">
                <Button color="success" size="sm" onClick={(e) => {setFilters({ ...filters, endYearMonth: filters.startYearMonth}); handleRecalculate(filters.startYearMonth);}} id="recalculateMonthlyRevenues">
                  <Icon.Plus height="18" />
                  Re-calculate Monthly Revenues
                  <UncontrolledTooltip target="recalculateMonthlyRevenues">
                    Re-calculate monthly revenues on the month/year specified in the 'From' date input field, default to previous month if missing
                  </UncontrolledTooltip>
                </Button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default React.memo(FormFilter);