import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  FormGroup,
  Label,
} from "reactstrap";
import PropTypes from "prop-types";
import moment from "moment";

import { withdrawAction } from "../../redux/reducers/toolReducer";
import { startActionWithPromise } from "../../helpers/saga-promise-helpers";
import Avatar from "../../components/Avatar";
import {
  SCOPES_COPY,
  CATEGORY_COPY,
} from "./constants";

const propTypes = {
  modal: PropTypes.bool,
  toggle: PropTypes.func,
};

const defaultProps = {
  modal: false,
  toggle: () => {},
};

const VideoWithdraw = props => {
  const dispatch = useDispatch();

  const { modal, toggle, row: video, refreshData } = props;

  const [waiting, setWaiting] = useState(false);
  const [reason, setReason] = useState('');
  const [errors, setErrors] = useState(false);

  const handleTextChange = (event) => {
    setReason(event.target.value);
    if (event.target.value) setErrors({});
  }

  const toggleModal = () => {
    if (toggle) toggle('modalWithdraw');
  };

  const successCallback = () => {
    setWaiting(false);
    toggleModal();
    if (refreshData) refreshData();
  };

  const failedCallback = () => {
    setWaiting(false);
  };

  const handleSubmit = async () => {
    let validateErrors = null;

    if (!reason) {
      validateErrors = {
        reason: 'Reason is required.'
      };
    }

    if (validateErrors) {
      setErrors(validateErrors);
      return;
    }

    setWaiting(true);
    try {
      await startActionWithPromise(
        withdrawAction,
        {
          id: video.uid,
          reason: reason,
          successCallback,
          failedCallback,
        },
        dispatch
      );
    } catch {}
    setWaiting(false);
  };

  useEffect(() => {
    if (!modal) {
      setReason('');
    }
  }, [modal]);

  return (
    <Modal isOpen={modal} toggle={toggleModal} centered size="md">
      <ModalHeader toggle={toggleModal}>
        Un-publish Video
      </ModalHeader>
      <ModalBody className="m-3">
        
        <Row>
          <Col lg="12">
            <p className="font-weight-bold mb-1">Video information: </p>
            { video && (
              <div className="request-detail p-2 border bg-light rounded">
                <div className="" style={{whiteSpace: 'pre-wrap', wordBreak: 'break-word'}}>
                  <p className="mb-0">Title: <span>{video?.title}</span></p>
                  <p className="mb-0">Subject: <span>{video?.subject}</span></p>
                  <p className="mb-0">Topic: <span>{video?.topic}</span></p>
                  <p className="mb-0">Version: <span>{video?.version}</span></p>
                  <div className="mb-0 d-flex">
                    <div className="info-label mr-2 align-self-center">Author:</div>
                    <Avatar className="mr-1 align-self-center" image={video?.avatar} fullname={video?.author} width="25" height="25" />
                    <span className="align-self-center">{video.author}</span>
                  </div>
                  {video.videoUser && (
                    <div className="mb-0 d-flex">
                      <div className="info-label mr-2 align-self-center">Video user:</div>
                      <Avatar className="mr-1 align-self-center" image={video.videoUser?.avatar?.image} fullname={video.videoUser?.fullName} width="25" height="25" />
                      <div className="align-self-center">{video.videoUser?.fullName}</div>
                    </div>
                  )}
                  <div className="mb-0">Visibility: <span>{SCOPES_COPY[video.visibilityScope]}</span></div>
                  <div className="mb-0">Categories: <span>{video.categories ? video.categories.map(x => CATEGORY_COPY[x]).join(', ') : ''}</span></div>
                  <p className="mb-0">Created at: <span>{video.createdDate ? moment(video.createdDate).utc().format('YYYY/MM/DD HH:mm:ss') : ''}</span></p>
                </div>
              </div>
            )}
          </Col>
        </Row>
        <Row className="mt-2">
          <Col lg="12">
            <FormGroup>
              <Label for="videoId" className="">
                Un-publish reason (*)
              </Label>
              <textarea
                className={`form-control${
                  errors.reason ? " is-invalid" : ""
                }`}
                id="withdraw_reason"
                name="withdraw_reason"
                placeholder=""
                rows="5"
                defaultValue={reason}
                onChange={handleTextChange}
              />
              {errors.reason && (<p className="text-danger">{errors.reason}</p>)}
            </FormGroup>
          {/* <p className="font-weight-bold mt-2">Do you want to continue?</p> */}
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" disabled={waiting} onClick={toggleModal}>
          Cancel
        </Button>
        <Button
          className="ml-2"
          color="primary"
          disabled={!reason}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};

VideoWithdraw.propTypes = propTypes;
VideoWithdraw.defaultProps = defaultProps;

export default VideoWithdraw;
