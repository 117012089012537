import { takeLatest, takeEvery, call, put } from "redux-saga/effects";
import { DOMAIN_ROLEGROUP_MESSAGES } from "../../config/messages";
import { sagaPromise } from "../../helpers/saga-promise-helpers";
import { roleGroupActions } from "../reducers/domainRoleGroupReducer";
import domainService from "../../services/domainService";
import { alertTypes } from "../constants";

function* fetchWorker(action) {
  const { data, error } = yield call(
    domainService.fetchGroups,
    action.payload?.params
  );
  if (error) {
    yield put(roleGroupActions.fetch.error(error));
    // yield put(alertError(error?.statusText));
    if (error?.headers["x-message-info"]) {
      throw new Error(error?.headers["x-message-info"]);
    }
  } else {
    yield put(roleGroupActions.fetch.success(data));
    return data;
  }
  return false;
}

function* addWorker(action) {
  const { data, error } = yield call(
    domainService.addGroup,
    action.payload.data
  );
  if (error) {
    if (error?.headers["x-message-info"]) {
      yield put({
        type: alertTypes.ERROR,
        message: error?.headers["x-message-info"],
      });
      throw new Error(error?.headers["x-message-info"]);
    }
  } else {
    yield put({
      type: alertTypes.SUCCESS,
      message: DOMAIN_ROLEGROUP_MESSAGES.addSuccess,
    });
    return data;
  }
  return false;
}

function* updateWorker(action) {
  const { data, error } = yield call(
    domainService.updateGroup,
    action.payload.id,
    action.payload.data
  );
  if (error) {
    if (error?.headers["x-message-info"]) {
      if (!action.payload?.hideAlert) {
        yield put({
          type: alertTypes.ERROR,
          message: error?.headers["x-message-info"],
        });
        throw new Error(error?.headers["x-message-info"]);
      }
      return { message: error?.headers["x-message-info"] };
    }
  } else {
    if (!action.payload?.hideAlert)
      yield put({
        type: alertTypes.SUCCESS,
        message: DOMAIN_ROLEGROUP_MESSAGES.updateSuccess,
      });
    return data;
  }
  return false;
}

function* deleteWorker(action) {
  const { data, error } = yield call(
    domainService.deleteGroup,
    action.payload.id
  );
  if (error) {
    if (error?.headers["x-message-info"]) {
      yield put({
        type: alertTypes.ERROR,
        message: error?.headers["x-message-info"],
      });
      throw new Error(error?.headers["x-message-info"]);
    }
  } else {
    // yield put(deletePermissionSuccess(data));
    yield put({
      type: alertTypes.SUCCESS,
      message: DOMAIN_ROLEGROUP_MESSAGES.deleteSuccess,
    });
    return data;
  }
  return false;
}

function* domainRoleGroupSaga() {
  yield takeLatest(roleGroupActions.fetch.start, fetchWorker);
  yield takeLatest(
    roleGroupActions.create.start,
    roleGroupActions.create.waiterActionForSaga(sagaPromise(addWorker))
  );
  yield takeEvery(
    roleGroupActions.update.start,
    roleGroupActions.update.waiterActionForSaga(sagaPromise(updateWorker))
  );
  yield takeLatest(
    roleGroupActions.delete.start,
    roleGroupActions.delete.waiterActionForSaga(sagaPromise(deleteWorker))
  );
}

export default domainRoleGroupSaga;
