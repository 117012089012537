
import "./productTheme.scss";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { startActionWithPromise } from "../../helpers/saga-promise-helpers";
import useMergeState from '../../hooks/mergeState';
import {
    Card,
    CardHeader,
    CardBody,
    Container,
    Row,
    Col,
    Spinner,
    CardFooter
} from "reactstrap";

import config from "../../config/config";

import {
    searchProductsAction
} from "../../redux/reducers/productReducer";

import DataTable from "../../components/DataTable";
import PageSizeSelector from "../../components/base/PageSizeSelector";
import Paging from "../../components/base/Paging";

import ProductFilterForm from "./filterForm";
import { getGridColumns } from "./gridColumns";

const Products = () => {

    const dispatch = useDispatch();

    const [filters, setFilters] = useMergeState({
        pageSize: config.DEFAULT_PAGESIZE,
        pageNumber: 1,
        resourceTypes: undefined,
        resourceSubtypes: undefined,
        storeOwner: undefined,
        resourceOwner: undefined,
        sort: 'CREATED',
        order: 'DESC',
        reload: false,
    });

    const fetchData = (params) => {
        (async () => {
            try {
                await startActionWithPromise(
                    searchProductsAction,
                    {
                        params,
                        successCallback: () => { },
                        failedCallback: () => { },
                    },
                    dispatch
                );
            } catch { }
        })();
    };

    useEffect(() => {
        if (filters.reload) {

            const params = {
                pageSize: filters.pageSize,
                pageNumber: filters.pageNumber - 1,
                types: undefined,
            };

            if (filters.sort && filters.order) {
                params.sortColumn = filters.sort.toUpperCase();
                params.sortDir = filters.order.toUpperCase();
            }

            if (filters.resourceTypes)
                params.types = [filters.resourceTypes];

            if (filters.resourceSubtypes)
                params.subtypes = [filters.resourceSubtypes];

            if (filters.storeOwner)
                params.storeOwner = filters.storeOwner;

            if (filters.resourceOwner)
                params.resourceOwner = filters.resourceOwner;


            fetchData(params);
            if (filters.reload)
                setFilters({ ...filters, reload: false });
        }
    }, [filters.reload]);

    useEffect(() => {
        const params = {
            pageSize: filters.pageSize,
            pageNumber: filters.pageNumber - 1,
            types: undefined,
        };
        if (filters.sort && filters.order) {
            params.sortColumn = filters.sort.toUpperCase();
            params.sortDir = filters.order.toUpperCase();
        }
        fetchData(params);
    }, []);


    const handleColumnSort = (field, order) => {
        setFilters({
            ...filters,
            sort: {
                dir: order,
                fieldName: field,
            },
            reload: true,
        });
    };


    const onSizeChange = (size) => {
        setFilters({ ...filters, pageSize: size, pageNumber: 1, reload: true });
    };

    const onPageChange = (p) => {
        setFilters({ ...filters, pageNumber: p, reload: true });
    };

    const gridColumns = getGridColumns(handleColumnSort);

    const products = useSelector(state => state.productStates?.products) || [];
    const fetching = useSelector(state => state.productStates?.fetching) || false;
    const totalPages = useSelector(state => state.productStates?.totalPages) || 0;
    const pageNumber = useSelector(state => state.productStates?.pageNumber) || 0;
    const totalCount = useSelector(state => state.productStates?.total) || 0;

    return (
        <>
            <h1 className="page-title">Cognitive resources</h1>
            <Card>
                <CardHeader>
                    <ProductFilterForm
                        mergeFilters={setFilters}
                        loading={fetching}
                        resourceTypes={filters.resourceTypes}
                        resourceSubtypes={filters.resourceSubtypes}
                        storeOwner={filters.storeOwner}
                        resourceOwner={filters.resourceOwner}
                    />
                </CardHeader>
                <CardBody>
                    <Container fluid className="p-0">
                        <Row>
                            <Col lg="12" className='d-flex'>
                                <div className="ml-auto">
                                    <span >Total resources found:</span>
                                    <span className="pl-1 totalLine">{totalCount}</span>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                {!fetching && (
                                    <DataTable
                                        keyField="id"
                                        data={products}
                                        columns={gridColumns}
                                        sort={filters.sort}
                                        order={filters.order}
                                        hideSelectColumn={true}
                                    />
                                )}
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col lg="3" md="4" className="d-flex">
                                <PageSizeSelector size={filters.pageSize} onChange={onSizeChange} />
                            </Col>
                            <Col lg="9" md="8" className="d-flex justify-content-end">
                                <Paging totalPages={totalPages} current={pageNumber} show={5} onSelect={onPageChange} />
                            </Col>
                        </Row>
                    </Container>
                </CardBody>
                <CardFooter>
                    <Container fluid className="p-0">
                        <Row>
                            <Col lg="12">
                            </Col>
                        </Row>
                    </Container>
                </CardFooter>
            </Card>
        </>
    );
};

export default Products;