import React from "react";

export const getGridColumns = (handleSort = () => { }, handleReclone) => {
    return [
        {
            dataField: "subscriberName",
            text: "Subscriber",
            sort: true,
            onSort: (field, order) => {
                handleSort(field, order);
            },
            formatter: (_, row) => (
                <div>
                    <div><b>{row.subscriberName}</b></div>
                    <div>{row.subscriberEmail}</div>
                    <div><small>{row.subscriberId}</small></div>
                </div>
            ),
            headerStyle: () => {
                return {};
            },
        },
        {
            dataField: "videoTitle",
            text: "Video",
            sort: true,
            onSort: (field, order) => {
                handleSort(field, order);
            },
            formatter: (_, row) => (
                <div>
                    <div ><b>{row.videoTitle}</b></div>
                    <div ><small>{row.videoId}</small></div>
                </div>
            ),
            headerStyle: () => {
                return {};
            },
        },
        {
            dataField: "purchaseDate",
            text: "Purchased on",
            sort: true,
            onSort: (field, order) => {
                handleSort(field, order);
            },
            formatter: (_, row) => (
                <div>{row.purchaseDate}</div>
            ),
            headerStyle: () => {
                return { width: '140px' };
            },
        },
        {
            dataField: "cost",
            text: "Price",
            sort: false,            
            formatter: (_, row) => (
                <div>${row.cost}</div>
            ),
            headerStyle: () => {
                return { width: '100px' };
            },
        },
        {
            dataField: "plan",
            text: "Plan",
            sort: false,            
            formatter: (_, row) => (
                <div>{row.plan}</div>
            ),
            headerStyle: () => {
                return { width: '60px' };
            },
        },
        {
            dataField: "months",
            text: "months",
            sort: false,
            formatter: (_, row) => (
                <div>{row.months}</div>
            ),
            headerStyle: () => {
                return { width: '100px' };
            },
        },
        {
            dataField: "validStart",
            text: "Start date",
            sort: true,
            onSort: (field, order) => {
                handleSort(field, order);
            },
            formatter: (_, row) => (
                <div>{row.validStart}</div>
            ),
            headerStyle: () => {
                return { width: '120px' };
            },
        },
        {
            dataField: "validEnd",
            text: "End date",
            sort: true,
            onSort: (field, order) => {
                handleSort(field, order);
            },
            formatter: (_, row) => (
                <div>{row.validEnd}</div>
            ),
            headerStyle: () => {
                return { width: '120px' };
            },
        },
        {
            dataField: "expired",
            text: "Expired",
            sort: false,
            formatter: (_, row) => (
                <div>{row.expired ? "Yes" : "no"}</div>
            ),
            headerStyle: () => {
                return { width: '100px' };
            },
        },
    ]
}