export const ORDER_STATUS = {
  completed: 'COMPLETED',
  pending: 'PENDING',
  cancelled: 'CANCELED',
  paid: 'PAID',
}

export const ORDER_STATUS_COPY = {
  [ORDER_STATUS.completed]: 'Completed',
  [ORDER_STATUS.pending]: 'Pending',
  [ORDER_STATUS.cancelled]: 'Cancelled',
  [ORDER_STATUS.paid]: 'Paid',
}

export const ORDER_STATUS_COLOR = {
  [ORDER_STATUS.completed]: 'success',
  [ORDER_STATUS.pending]: 'warning',
  [ORDER_STATUS.cancelled]: 'dark',
  [ORDER_STATUS.paid]: 'secondary',
}