import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Spinner,
  Badge,
} from "reactstrap";
import * as Icon from "react-feather";
import DataTable, { rowActionTypes } from "../../components/DataTable";
import PageSizeSelector from "../../components/base/PageSizeSelector";
import Paging from "../../components/base/Paging";
import InputSearch from "../../components/base/InputSearch";
import config from "../../config/config";
import UserUpdate from "./UserUpdate";
import UserLogout from "./UserLogout";
import ConfirmModal from "../../components/base/ConfirmModal";
import UserRoles from "./UserRoles";
import UserChangePassword from "./UserChangePassword";
import {
  fetchBoUsersAction,
  forceLogoutAction,
  resetPwAction,
  revokeRolesAction,
  enableAction,
  disableAction,
  bouserActions
} from "../../redux/reducers/bouserReducer";
import { startActionWithPromise } from "../../helpers/saga-promise-helpers";
import { roleTypes } from "../../redux/constants";

const UserList = props => {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    modalUpdate: false,
    modalDelete: false,
    modalLogout: false,
    modalConfirm: false,
    modalRoles: false,
    modalChangePassword: false,
    action: "",
    message: "",
    row: null,
  });

  const [filters, setFilters] = useState({
    pageSize: config.DEFAULT_PAGESIZE,
    pageNumber: 1,
    sort: "",
    sortReal: "",
    order: "",
    keyword: "",
    reload: false,
  });

  const users = useSelector(state => state.bouser?.users) || [];
  const fetching = false; // useSelector(state => state.bouser?.fetching);
  const totalPages = useSelector(state => state.bouser.totalPages);
  const pageNumber = useSelector(state => state.bouser.pageNumber);
  const isBoAdmin = useSelector((state) => state.bouser?.isBoAdmin);
  const profile = useSelector(state => state.bouser?.profile);

  const tableColumns = [
    {
      dataField: "fullname",
      text: "Fullname/Phone",
      sort: true,
      onSort: (field, order) => {
        onColumnSort(field, order);
      },
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        // for ignore default datable sort
      },
      formatter: (cell, row, rowIndex, formatExtraData) => (
        <div>
          <p className="mb-0">{row.fullname}</p>
          <p className="mb-0">{row.phone}</p>
        </div>
      ),
    },
    {
      dataField: "email",
      text: "Email/Username",
      sort: true,
      onSort: (field, order) => {
        onColumnSort(field, order);
      },
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        // for ignore default datable sort
      },
      formatter: (cell, row, rowIndex, formatExtraData) => (
        <div>
          <p className="mb-0">{row.email}</p>
          <p className="mb-0">{row.username}</p>
        </div>
      ),
    },
    {
      dataField: "enabled",
      text: "Status",
      sort: false,
      onSort: (field, order) => {
        onColumnSort(field, order);
      },
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        // for ignore default datable sort
      },
      formatter: (cell, row, rowIndex, formatExtraData) => (
        <div>
          <Badge
            color={row.enabled ? "success" : "secondary"}
            className="mr-1 mb-1"
          >
            {row.enabled ? "Enabled" : "Disabled"}
          </Badge>
        </div>
      ),
      headerStyle: (colum, colIndex) => {
        return { width: "100px" };
      },
    },
    {
      dataField: "createdDate",
      text: "Created At",
      sort: false,
      onSort: (field, order) => {
        onColumnSort(field, order);
      },
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        // for ignore default datable sort
      },
      headerStyle: (colum, colIndex) => {
        return { width: "150px" };
      },
    },
  ];

  const rowActions = [
    // {
    //   name: "Edit",
    //   type: rowActionTypes.EDIT,
    //   classes: "text-dark",
    // },
    {
      name: "Force logout",
      type: rowActionTypes.LOGOUT,
      classes: "text-dark",
    },
    {
      name: "Reset password",
      type: rowActionTypes.RESET_PW,
      classes: "text-dark",
    },
    {
      name: "Change password",
      type: rowActionTypes.CHANGE_PW,
      classes: "text-dark",
    },
    {
      name: "Set roles",
      type: rowActionTypes.SET_ROLES,
      classes: "text-dark",
    },
    {
      name: "Revoke all roles",
      type: rowActionTypes.REVOKE_ALL_ROLES,
      classes: "text-dark",
    },
    {
      name: "Enable",
      type: rowActionTypes.ENABLE,
      classes: "text-dark",
      toggleField: "enabled",
    },
    {
      name: "Disable",
      type: rowActionTypes.DISABLE,
      classes: "text-dark",
      toggleField: "enabled",
    },
    {
      name: "Delete",
      type: rowActionTypes.DELETE,
      classes: "text-dark",
    },
  ];


  const onSelectedChange = selected => {
    // console.log(selected);
  };

  const onSizeChange = size => {
    setFilters({ ...filters, pageSize: size, pageNumber: 1, reload: true });
  };

  const onPageChange = p => {
    setFilters({ ...filters, pageNumber: p, reload: true });
  };

  const onColumnSort = (field, order) => {
    // trigger sort data via api
    const realSort = field === "fullname" ? "firstName" : field;
    if (realSort === filters.sortReal && order === filters.order) return;

    setFilters({
      ...filters,
      sort: field,
      sortReal: realSort,
      order,
      reload: true,
    });
  };

  const onSearchSubmit = keyword => {
    // console.log(keyword);
    if (filters.keyword === keyword) return;
    setFilters({ ...filters, pageNumber: 1, keyword, reload: true });
  };

  const isActionVisibled = (action, row) => {
    if (row.id == profile?.id)
      return false;
    if (action.name == 'Delete') {      
      return !row.enabled && isBoAdmin;
    }
    return true;
  }

  const onActionButtonClick = (action, row) => {
    let message = "";
    switch (action.type) {
      case rowActionTypes.EDIT:
        setState({ ...state, modalUpdate: true, row });
        break;
      case rowActionTypes.LOGOUT:
        setState({ ...state, modalLogout: true, row });
        break;
      case rowActionTypes.SET_ROLES:
        setState({ ...state, modalRoles: true, row });
        break;
      case rowActionTypes.CHANGE_PW:
        setState({ ...state, modalChangePassword: true, row });
        break;
      case rowActionTypes.RESET_PW:
        message = `<p class="font-weight-bold">The password of following user will be resetted: </p>\
        <p class="font-italic">"${`${row.username} / ${row.email}`}"</p>\
        <p class="font-weight-bold">Do you want to continue?</p>`;
        setState({
          ...state,
          modalConfirm: true,
          row,
          message,
          action: rowActionTypes.RESET_PW,
        });
        break;
      case rowActionTypes.REVOKE_ALL_ROLES:
        message = `<p class="font-weight-bold">All roles of following user will be deleted: </p>\
        <p class="font-italic">"${`${row.username} / ${row.email}`}"</p>\
        <p class="font-weight-bold">Do you want to continue?</p>`;
        setState({
          ...state,
          modalConfirm: true,
          row,
          message,
          action: rowActionTypes.REVOKE_ALL_ROLES,
        });
        break;
      case rowActionTypes.ENABLE:
        message = `<p class="font-weight-bold">The following BO user will be enabled: </p>\
        <p class="font-italic">"${`${row.username} / ${row.email}`}"</p>\
        <p class="font-weight-bold">Do you want to continue?</p>`;
        setState({
          ...state,
          modalConfirm: true,
          row,
          action: rowActionTypes.ENABLE,
          message: message,
        });
        break;
      case rowActionTypes.DISABLE:
        message = `<p class="font-weight-bold">The following BO user will be disabled: </p>\
        <p class="font-italic">"${`${row.username} / ${row.email}`}"</p>\
        <p class="font-weight-bold">Do you want to continue?</p>`;
        setState({
          ...state,
          modalConfirm: true,
          row,
          action: rowActionTypes.DISABLE,
          message: message,
        });
        break;
      case rowActionTypes.DELETE:
        message = `<p class="font-weight-bold">The following BO user will be deleted: </p>\
        <p class="font-italic">"${`${row.username} / ${row.email}`}"</p>\
        <p class="font-weight-bold">Do you want to continue?</p>`;
        setState({
          ...state,
          modalConfirm: true,
          row,
          action: rowActionTypes.DELETE,
          message: message,
        });
        break;
      default:
    }
  };

  const handleAdd = () => {
    setState({ ...state, modalUpdate: true, row: {} });
  };

  const toggleModal = modal => {
    if (state[modal]) setState({ ...state, [modal]: !state[modal] });
    else setState({ ...state, [modal]: !state[modal], row: null });
  };

  const onActionConfirm = () => {
    switch (state.action) {
      case rowActionTypes.LOGOUT:
        forceLogoutUser();
        break;
      case rowActionTypes.RESET_PW:
        resetUserPassword();
        break;
      case rowActionTypes.REVOKE_ALL_ROLES:
        revokeUserRoles();
        break;
      case rowActionTypes.ENABLE:
        toggleBoUserStatus(state.row, true);
        break;
      case rowActionTypes.DISABLE:
        toggleBoUserStatus(state.row, false);
        break;
      case rowActionTypes.DELETE:
        deleteBOUser(state.row);
        break;
      default:
    }
  };

  const onToggleStatusSuccess = () => {
    setState({ ...state, modalConfirm: false });
    refreshDatatable();
  }
  const toggleBoUserStatus = (row, status) => {
    const params = {
      userId: row.userId,
      status: status
    };

    (async () => {
      try {
        await startActionWithPromise(
          status ? enableAction : disableAction,
          { ...params, successCallback: onToggleStatusSuccess, failedCallback },
          dispatch
        );
      } catch (error) {
        console.log("update status error", error);
      }
    })();
  };

  const forceLogoutUser = () => {
    if (!state.row?.userId) return;

    (async () => {
      try {
        await startActionWithPromise(
          forceLogoutAction,
          { id: state.row?.userId, successCallback, failedCallback },
          dispatch
        );
      } catch (error) {
        // console.log('delete error', error);
      }
    })();
  };
  const resetUserPassword = () => {
    if (!state.row?.userId) return;

    (async () => {
      try {
        await startActionWithPromise(
          resetPwAction,
          { id: state.row?.userId, successCallback, failedCallback },
          dispatch
        );
      } catch (error) {
        // console.log('delete error', error);
      }
    })();
  };
  const revokeUserRoles = () => {
    if (!state.row?.userId) return;

    (async () => {
      try {
        await startActionWithPromise(
          revokeRolesAction,
          { id: state.row?.userId, successCallback, failedCallback },
          dispatch
        );
      } catch (error) {
        // console.log('delete error', error);
      }
    })();
  };

  const deleteBOUser = (row) => {
    console.log("Deleting user ", row?.userId);

    if (!row?.userId) return;

    (async () => {
      try {
        await startActionWithPromise(
          bouserActions.delete,
          { id: row?.userId, successCallback, failedCallback },
          dispatch
        );
      } catch (error) {
        console.log('delete error', error);
      }
    })();
  };

  const successCallback = () => {
    // close confirm modal and reload the list
    setState({ ...state, modalConfirm: false });
    refreshDatatable();
  };

  const failedCallback = () => {
    // do nothing
  };

  const refreshDatatable = () => {
    setFilters({ ...filters, reload: true });
  };

  const fetchData = (params) => {
    (async () => {
      try {
        await startActionWithPromise(
          fetchBoUsersAction,
          {
            params,
            successCallback: () => { },
            failedCallback: () => { },
          },
          dispatch
        );
      } catch (error) { }
    })();
  };

  useEffect(() => {
    if (filters.reload) {
      const params = {
        pageSize: filters.pageSize,
        pageNumber: filters.pageNumber - 1,
        keyword: filters.keyword,
        orderBy: `${filters.sortReal} ${filters.order.toUpperCase()}`,
      };
      fetchData(params);
      setFilters({ ...filters, reload: false });
    }
  }, [filters.reload]);

  // loading data when component mounted
  useEffect(() => {
    const params = {
      pageSize: filters.pageSize,
      pageNumber: filters.pageNumber - 1,
    };
    fetchData(params);

    return () => setState({});
  }, []);


  return (
    <>
      <Card className="w-100 mb-0">
        <CardHeader>
          <Row>
            <Col lg="6" xs="6" className="d-flex">
              <div className="bulk-actions">
                {isBoAdmin && (
                  <Button color="success" size="sm" onClick={handleAdd}>
                    <Icon.Plus height="18" /> Add New
                  </Button>
                )}
                <Button color="danger" size="sm" className="ml-1 d-none">
                  <Icon.Trash2 height="18" /> Bulk Delete
                </Button>
              </div>
            </Col>
            <Col lg="6" xs="6" className="d-flex justify-content-end">
              <InputSearch
                className=""
                value={filters.keyword}
                onSubmit={onSearchSubmit}
                disabled={fetching}
              />
            </Col>
          </Row>
          {/* <Button color="primary" size="sm" onClick={getSelectedUsers}>Get Selected Rows</Button> */}
        </CardHeader>
        <CardBody className="pt-0 data-list">
          {fetching && (
            <div className="text-center w-100 bg-white p-4 position-static h-100">
              <Spinner />
            </div>
          )}
          {!fetching && (
            <>
              <DataTable
                keyField="userId"
                data={users}
                columns={tableColumns}
                sort={filters.sort}
                order={filters.order}
                actions={rowActions}
                onActionButton={isActionVisibled}
                onActionClick={onActionButtonClick}
                onSelectedChange={onSelectedChange}
                hideSelectColumn={true}
              />
              <Row className="mt-3">
                <Col lg="6" className="d-flex">
                  <PageSizeSelector
                    size={filters.pageSize}
                    onChange={onSizeChange}
                  />
                </Col>
                <Col lg="6" className="d-flex justify-content-end">
                  <Paging
                    totalPages={totalPages}
                    current={pageNumber}
                    show={5}
                    onSelect={onPageChange}
                  />
                </Col>
              </Row>
            </>
          )}
        </CardBody>
      </Card>
      <UserUpdate
        modal={state.modalUpdate}
        toggle={toggleModal}
        row={state.row}
        refreshData={refreshDatatable}
      />
      <UserRoles
        modal={state.modalRoles}
        toggle={toggleModal}
        row={state.row}
        refreshData={refreshDatatable}
      />
      <UserLogout
        modal={state.modalLogout}
        toggle={toggleModal}
        row={state.row}
        refreshData={refreshDatatable}
      />
      <UserChangePassword
        modal={state.modalChangePassword}
        toggle={toggleModal}
        row={state.row}
      />
      <ConfirmModal
        modal={state.modalConfirm}
        toggle={toggleModal}
        row={state.row}
        message={state.message}
        onConfirm={onActionConfirm}
      />
    </>
  );
};

export default UserList;
