import {
  createReducer,
  createActionResources,
} from "redux-waiters";
import { RESET_REDUCER } from "../constants";

export const assignAction = createActionResources("publishings/assignment");
export const selfAssignAction = createActionResources("publishings/assignment-to-me");
export const removeAssignAction = createActionResources("publishings/assignment-remove");
export const searchPublishingsAction = createActionResources("publishings/search");
export const rejectVideoAction = createActionResources("publishings/reject");
export const approveVideoAction = createActionResources("publishings/approval");

const initialState = {
  fetching: false,
  videos: [],
  total: 0,
  totalPages: 0,
  pageNumber: 1,
  user: {},
  error: {},
};

export default createReducer(
  {
    [searchPublishingsAction.start]: state => {
      return { ...state, fetching: true };
    },
    [searchPublishingsAction.success]: (state, action) => {
      return {
        ...state,
        videos: action.data?.content,
        pageNumber: action.data?.pageNumber + 1,
        total: action.data?.total,
        totalPages: Math.ceil(action.data?.total / action.data?.pageSize),
        fetching: false,
      };
    },
    [searchPublishingsAction.error]: state => {
      return { ...state, fetching: false };
    },

    [RESET_REDUCER]: () => initialState,
  },
  initialState
);
