import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
} from "reactstrap";
import _ from "lodash";
import PropTypes from "prop-types";

import { updatePlanOptionTypeAction, createPlanOptionTypeAction } from "../../redux/reducers/subscriptionReducer";
import { startActionWithPromise } from "../../helpers/saga-promise-helpers";
import useMergeState from "../../hooks/mergeState";
const propTypes = {
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  refreshData: PropTypes.func,
};

const defaultProps = {
  modal: false,
  toggle: () => {},
  refreshData: () => {},
};

const OptionTypeModal = props => {
  const dispatch = useDispatch();

  const { modal, toggle, refreshData, row, updateMode, isBoAdmin } = props;

  const fetching = false; // useSelector(state => state.user?.fetching);
  const [waiting, setWaiting] = useState(false);

  const [formState, setFormState] = useMergeState({
    optionTypeName: row ? row.name : '',
    listingOrderNumber: row && row.listingOrderNumber ? row.listingOrderNumber : 0,
    description: row && row.description ? row.description : '',
    defaultValue: row ? row.defaultValue : '',
    defaultValueUnit: row && row.defaultValueUnit ? row.defaultValueUnit : '',
    active: row && row.active !== undefined ? row.active : false
  });

  const [errorMessage, setErrorMessage] = useState('');

  const handleInputChange = (e) => {
    let inputValue = e.target.type === 'number' ? parseInt(e.target.value) : e.target.value;
    setFormState({[e.target.name]: inputValue});
  }

  const addSuccessCallback = () => {
    if (refreshData) refreshData();
    toggle("modalOptionType");
  };

  const handleSubmit = async () => {
    setErrorMessage('');

    if (!formState.optionTypeName) {
      setErrorMessage('Please enter Option Type');
      return;
    }

    if (!formState.listingOrderNumber ) {
      setErrorMessage('Please enter Listing Order');
      return;
    }

    if (!formState.description ) {
      setErrorMessage('Please enter Description');
      return;
    }
    
    const optionType = {
      name: formState.optionTypeName,
      listingOrderNumber: formState.listingOrderNumber,
      description: formState.description,
      defaultValue: formState.defaultValue,
      defaultValueUnit: formState.defaultValueUnit,
      active: formState.active
    };

    setWaiting(true);
    try {
      await startActionWithPromise(
        updateMode? updatePlanOptionTypeAction : createPlanOptionTypeAction,
        {
          params: optionType,
          successCallback: addSuccessCallback,
          failedCallback: () => {},
        },
        dispatch
      );
    } catch {}
  };

  return (
    <Modal
      isOpen={modal}
      toggle={() => toggle("modalOptionType")}
      centered
      size="lg"
      className="add-trusted-modal add-featured-video-modal"
    >
      <ModalHeader>{ updateMode? 'Update' : 'Create' } Option Type</ModalHeader>
      <ModalBody className="pt-0 pb-0">
        <Card className="w-100 mb-0">
          <CardHeader className="">
            
          </CardHeader>
          <CardBody className="pt-0 pb-0 data-list position-relative">
            <Row>
              <Col lg="9">
                <FormGroup>
                  <Label for="listingOrderNumber" className="">
                    Option Type
                  </Label>
                  <input
                    className="form-control"
                    name="optionTypeName"
                    id="optionTypeName"
                    type="text"
                    value={formState.optionTypeName}
                    onChange={handleInputChange}
                    maxLength={255}
                  />
                </FormGroup>
              </Col>
              <Col lg="3">
                <FormGroup>
                  <Label for="listingOrderNumber" className="">
                    Listing Order
                  </Label>
                  <input
                    className="form-control"
                    name="listingOrderNumber"
                    id="listingOrderNumber"
                    type="number"
                    min="0"
                    value={formState.listingOrderNumber}
                    onChange={handleInputChange}
                    maxLength={3}
                    width="30px"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg="9">
                <FormGroup>
                  <Label for="description" className="">
                    Description
                  </Label>
                  <input
                    className="form-control"
                    name="description"
                    id="description"
                    type="text"
                    value={formState.description}
                    onChange={handleInputChange}
                    maxLength={255}
                  />
                </FormGroup>
              </Col>
              <Col lg="3">
                <FormGroup>
                  <div>
                    <FormGroup inline check>
                      <Label>
                        <input
                          className="form-check-input"
                          name="active"
                          id="active"
                          type="checkbox"
                          value={formState.active}
                          checked={formState.active}
                          onChange={() => setFormState({active: !formState.active})}
                        />
                        <span>Active</span>
                      </Label>
                    </FormGroup>
                    
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="9">
                <FormGroup>
                  <Label for="defaultValueUnit" className="">
                    Default Value Unit
                  </Label>
                  <input
                    className="form-control"
                    name="defaultValueUnit"
                    id="defaultValueUnit"
                    type="text"
                    value={formState.defaultValueUnit}
                    onChange={handleInputChange}
                    maxLength={255}
                  />
                </FormGroup>
              </Col>
              <Col lg="3">
              <FormGroup>
                  <Label for="defaultValue" className="">
                    Default Value
                  </Label>
                  <input
                    className="form-control"
                    name="defaultValue"
                    id="defaultValue"
                    type="text"
                    value={formState.defaultValue}
                    onChange={handleInputChange}
                    maxLength={255}
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </ModalBody>
      <ModalFooter>
        {errorMessage && (
          <div className="float-left text-danger">
            {errorMessage}
          </div>
        )}
        <Button
          color="secondary"
          disabled={!isBoAdmin || fetching}
          onClick={() => toggle("modalOptionType")}
        >
          Cancel
        </Button>
        <Button
          className="ml-2"
          color="primary"
          disabled={waiting}
          onClick={handleSubmit}
        >
          { updateMode? 'Update' : 'Create' }
        </Button>
      </ModalFooter>
    </Modal>
  );
};

OptionTypeModal.propTypes = propTypes;
OptionTypeModal.defaultProps = defaultProps;

export default OptionTypeModal;
