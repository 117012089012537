import { post, get, request } from "../helpers/apiHelper";

async function fetch(parrams) {
  if (parrams && parrams.keyword) {
    const data = {
      callerId: "",
      keywords: parrams.keyword,
      pageNumber: parrams.pageNumber,
      pageSize: parrams.pageSize,
      // sort: {
      //   dir: parrams.order,
      //   fieldName: parrams.sort
      // }
    };
    const result = await post("settings/search", data);
    return result;
  }
  const result = await get("settings", parrams);
  return result;
}

async function create(data) {
  const result = await post(`settings`, data);
  return result;
}

async function find(id) {
  const result = await get(`settings/${id}`);
  return result;
}

async function update(id, data) {
  const result = await request(`settings/${id}`, data, "put");
  return result;
}

async function deleteSetting(id) {
  const result = await request(`settings/${id}`, null, "delete");
  return result;
}

async function getServices() {
  const result = await get("settings/services");
  return result;
}

async function getTypes() {
  const result = await get("settings/types");
  return result;
}

export default {
  fetch,
  find,
  create,
  update,
  deleteSetting,
  getServices,
  getTypes,
};
