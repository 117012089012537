import React, { useState } from "react";
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import PropTypes from "prop-types";
import useDeepCompareEffect from "use-deep-compare-effect";

const propTypes = {
  label: PropTypes.string,
  items: PropTypes.array,
  keyField: PropTypes.string,
  textField: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
};

const CustomDropdown = ({
  label,
  items,
  keyField,
  textField,
  onChange,
  color,
  size,
  ...rest
}) => {
  const [selected, setSelected] = useState();

  const handleClick = item => {
    if (!item) return;

    setSelected(item);

    if (onChange && typeof onChange === "function") {
      item === label ? onChange(false) : onChange(item);
    }
  };

  /**
   * reset to default value when items changed
   */
  useDeepCompareEffect(() => {
    setSelected(label);
  }, [items]);

  return (
    <UncontrolledButtonDropdown {...rest}>
      <DropdownToggle caret size={size || ""} color={color} className="border">
        {selected
          ? textField && selected[textField]
            ? selected[textField]
            : selected
          : label}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={() => handleClick(label)}>{label}</DropdownItem>
        {items &&
          items.map((item, i) => (
            <DropdownItem key={`dd${i}`} onClick={() => handleClick(item)}>
              {textField ? item[textField] : item}
            </DropdownItem>
          ))}
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  );
};

CustomDropdown.propTypes = propTypes;

export default CustomDropdown;
