import {
  createReducer,
  createActionResources,
  createActionCRUDResources,
} from "redux-waiters";
import moment from "moment";

import { RESET_REDUCER } from "../constants";
import _ from "lodash";

export const bouserActions = createActionCRUDResources("bousers");
export const fetchBoUsersAction = createActionResources("bousers/fetch");
export const searchBoUsersAction = createActionResources("bousers/search");

export const forceLogoutAction = createActionResources("bousers/force-logout");
export const changePwAction = createActionResources("bousers/change-pw");
export const changeMyPwAction = createActionResources("bousers/change-my-pw");
export const resetPwAction = createActionResources("bousers/reset-pw");
export const revokeRolesAction = createActionResources("bousers/revoke-roles");
export const fetchRolesAction = createActionResources("bousers/roles/fetch");
export const bouserRoleActions = createActionCRUDResources(
  "bousers/user-roles"
);
export const forgotPwAction = createActionResources("bousers/forgot-pw");

export const getProfileAction = createActionResources("bousers/get");
export const updateProfileAction = createActionResources("bousers/update");
export const fetchLoggedUserRolesAction = createActionResources("bousers/fetch-user-roles");
export const enableAction = createActionResources("bousers/enable");
export const disableAction = createActionResources("bousers/disable");

const BO_ADMINS_ROLE = 'BO_ADMINS';
const BO_ADMIN_ROLES = ['BO_ADMINS', 'SYSTEM_ADMINS', 'SYSTEM_OPERATORS'];

const initialState = {
  fetching: false,
  users: [],
  total: 0,
  totalPages: 0,
  pageNumber: 1,
  roles: [],
  userRoles: [],
  loggedUserRoles: [],
  isBoAdmin: false,
  profile: {},
  error: {},
};

export default createReducer(
  {
    [fetchBoUsersAction.start]: state => {
      return { ...state, fetching: true };
    },
    [fetchBoUsersAction.success]: (state, action) => {
      const users = action.data?.content;
      let usersArr = [];
      if (users) {
        usersArr = users.map(user => {
          const u = {
            ...user,
            fullname: `${user.firstName} ${user.lastName}`,
            createdDate: user.createdDate
              ? moment(user.createdDate, 'MM/DD/YYYY HH:mm:ss').format("YYYY-MM-DD HH:mm:ss")
              : "",
          };
          return u;
        });
      }

      return {
        ...state,
        users: usersArr,
        pageNumber: action.data?.pageNumber + 1,
        total: action.data?.total,
        totalPages: Math.ceil(action.data?.total / action.data?.pageSize),
        fetching: false,
      };
    },
    [fetchBoUsersAction.error]: state => {
      return { ...state, fetching: false };
    },

    // all available roles
    [fetchRolesAction.start]: state => {
      return { ...state, fetching: true };
    },
    [fetchRolesAction.success]: (state, action) => {
      return {
        ...state,
        roles: action.data,
        fetching: false,
      };
    },

    // user roles
    [bouserRoleActions.fetch.start]: state => {
      return { ...state, fetching: true };
    },
    [bouserRoleActions.fetch.success]: (state, action) => {
      return {
        ...state,
        userRoles: action.data,
        fetching: false,
      };
    },

    // user profile
    [getProfileAction.start]: state => {
      return { ...state, fetching: true };
    },
    [getProfileAction.success]: (state, action) => {
      return {
        ...state,
        profile: action.data,
        fetching: false,
      };
    },

    // user roles
    [fetchLoggedUserRolesAction.start]: state => {
      return { ...state, fetching: true };
    },
    [fetchLoggedUserRolesAction.success]: (state, action) => {
      var isBoAdmin = false;
      if (action.data && _.indexOf(action.data, BO_ADMINS_ROLE) !== -1) {
        isBoAdmin = true;
      }
      if (action.data && _.find(action.data, function(o) { 
        return BO_ADMIN_ROLES.indexOf(o) !== -1; 
      })) {
        isBoAdmin = true;
      }

      return {
        ...state,
        loggedUserRoles: action.data,
        isBoAdmin: isBoAdmin,
        fetching: false,
      };
    },

    [RESET_REDUCER]: () => initialState,
  },
  initialState
);
