import React from "react";
import moment from "moment";
import { printPrice } from "../../helpers/utils";

const formatOrdersTooltip = (number, suffixText) => {
    if (number === 0)
        return "No " + suffixText;
    if (number == 1)
        return number + " " + suffixText;
    return number + " " + suffixText + "s";
}


const formatOrderCount = (number) => {
    if (number == 0)
        return "--";
    return number;
}

const printResourceType = (type, subtype) => {
    if (type === 'LIVE_CLASS')
        return 'Live class';
    if (subtype)
        return subtype;
    return 'PDF - PPT';
}

export const getGridColumns = (handleSort = () => { }, handleReclone) => {
    return [
        {
            dataField: "title",
            text: "Title",
            sort: true,
            formatter: (_, row) => (
                <div className="d-flex justify-content-start" id={row.id}>
                    <div >
                        <div>
                            <img width={120} src={row.thumbnailUrl}></img>
                        </div>
                        <div className="text-center font-weight-bold">
                            <b>{printResourceType(row.resourceType, row.resourceSubtype)}</b>
                        </div>
                    </div>
                    <div className="ml-2">
                        <p className="mb-0 mainTitle"><b>{row.title}</b></p>
                        <p className="mb-0">{row.titleLine2}</p>
                        <p className="mb-0">{row.titleLine3}</p>
                        <p className="mb-0 titleLine4">{row.titleLine4}</p>
                    </div>
                </div>
            ),
            headerStyle: () => {
                return {};
            },
        },
        {
            dataField: "description",
            text: "Description",
            sort: false,
            formatter: (cell, row) => (
                <p className="u-truncate-5">{row.description}</p>
            ),
            headerStyle: () => {
                return {};
            },
        },
        {
            dataField: "owner",
            text: "Created by",
            sort: false,
            formatter: (cell, row) => (
                <>
                    <p className="mb-0"><img height={40} src={row.ownerDetail.avatar}></img><span className="ml-2">{row.ownerDetail.fullName}</span></p>
                    <p className="mb-0">
                        {row.creationDate ? moment(row.creationDate).format('YYYY/MM/DD') : ''} &nbsp; {row.creationDate ? moment(row.creationDate).format('HH:mm:ss') : ''}<br />
                        {row.free ? (<span className="withNoPrice">Free</span>) : (<span className="withPrice">{printPrice(row.price)}</span>)}
                    </p>
                </>
            ),
            headerStyle: () => {
                return { width: '200px' };
            },
        },
        {
            dataField: "completedOrders",
            text: "All time purchases",
            sort: false,
            formatter: (_, row) => row.free ? null : (
                <div className={row.purchases > 0 ? 'clickableOrders' : ''}>
                    <div className="d-flex row.todayPendingOrders" title={formatOrdersTooltip(row.pendingOrders, "pending order")}>
                        <div className="pendingRec"></div><span className="pendingOrders">{formatOrderCount(row.pendingOrders)}</span>
                    </div>
                    <div className="d-flex" title={formatOrdersTooltip(row.completedOrders, "completed order")}>
                        <div className="completedRec"></div><span className="completedOrders">{formatOrderCount(row.completedOrders)}</span>
                    </div>
                    <div className="d-flex" title={formatOrdersTooltip(row.canceledOrders, "canceled order")}>
                        <div className="canceledRec"></div><span className="canceledOrders">{formatOrderCount(row.canceledOrders)}</span>
                    </div>
                </div>
            ),
            headerStyle: () => {
                return { width: '100px' };
            },
        },
        {
            dataField: "todayCompletedOrders",
            text: "Today Purchases",
            sort: false,
            formatter: (_, row) => row.free ? null : (
                <div className={row.purchases > 0 ? 'clickableOrders' : ''}>
                    <div className="d-flex" title={formatOrdersTooltip(row.todayPendingOrders, "today pending order")}>
                        <div className="pendingRec"></div><span className="pendingOrders">{formatOrderCount(row.todayPendingOrders)}</span>
                    </div>
                    <div className="d-flex" title={formatOrdersTooltip(row.todayCompletedOrders, "today completed order")}>
                        <div className="completedRec"></div><span className="completedOrders">{formatOrderCount(row.todayCompletedOrders)}</span>
                    </div>
                    <div className="d-flex" title={formatOrdersTooltip(row.todayCanceledOrders, "today canceled order")}>
                        <div className="canceledRec"></div><span className="canceledOrders">{formatOrderCount(row.todayCanceledOrders)}</span>
                    </div>
                </div>
            ),
            headerStyle: () => {
                return { width: '100px' };
            },
        },
        {
            dataField: "downloads",
            text: "Downloads",
            sort: false,
            formatter: (cell, row) => (
                <span>{row.downloads}</span>
            ),
            headerStyle: () => {
                return { width: '100px' };
            },
        },
        {
            dataField: "views",
            text: "Views",
            sort: false,
            formatter: (cell, row) => (
                <span>{row.views}</span>
            ),
            headerStyle: () => {
                return { width: '100px' };
            },
        },
        {
            dataField: "likes",
            text: "Likes",
            sort: false,
            formatter: (cell, row) => (
                <span>{row.likes}</span>
            ),
            headerStyle: () => {
                return { width: '100px' };
            },
        }
    ];

}