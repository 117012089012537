import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Button } from "reactstrap";
import _ from 'lodash';
import * as Icon from "react-feather";
import classNames from "classnames";

import DebounceInput from "../../components/DebounceInput";
import { isUUID } from "../../helpers/utils";

const FormFilter = ({loading, mergeFilters, clearForm}) => {
  var userIdInput = useRef(null);
  var keywordInput = useRef(null);

  const [cancelDebounce, setCancelDebounce] = useState(false);
  const [validateErrors, setValidateErrors] = useState({});

  const [filters, setFilters] = useState({
    keywords: '',
    userId: '',
    enabled: '',
    sort: '',
    order: '',
    deleted: false,
    search: false,
    reload: false,
  });

  const handleUserIdChange = (value, name, target) => {
    if (value && !isUUID(value)) {
      setValidateErrors({
        ...validateErrors,
        userId: 'Please enter user id in uuid format.'
      });
      if (target) target.setCustomValidity('Please enter user id in uuid format.');
    } else {
      let errors = Object.assign({}, validateErrors);
      delete errors.userId;

      setValidateErrors({
        ...errors
      });
    }

    setFilters({ ...filters, userId: value});
    setCancelDebounce(false);
  }

  const handleUserIdSubmit = (value, name, target) => {
    if (value && !isUUID(value)) {
      if (target) target.setCustomValidity('Please enter user id in uuid format.');
      return;
    }
    setFilters({...filters, userId: value});
    if (mergeFilters) mergeFilters({...filters, userId: value, search: true, reload: true});
  };

  const handleInputChange = (value, name) => {
    setCancelDebounce(false);
    setFilters({ ...filters, [name]: value});
  }

  const handleInputSubmit = (value, name) => {
    setFilters({...filters, [name]: value});
    if (mergeFilters) mergeFilters({...filters, [name]: value, search: true, reload: true});
  }

  const submitFilter = () => {
    if (validateErrors && validateErrors.userId) {
      if (userIdInput.current) userIdInput.current.focus();
      return;
    }
    setCancelDebounce(true);
    if (mergeFilters) mergeFilters({...filters, search: true, reload: true});
  };

  useEffect(() => {
    if (clearForm) {
      setFilters({...filters, userId: '', keywords: ''});
    }
  }, [clearForm]);

  return (
    <>
      <Row className={classNames('video-review-filter')} style={{transition: 'visibility 0.5s, opacity 0.5s linear'}}>
        <Col lg="12" xs="12" className="d-flex justify-content-end">
          <div className="filter-input filter-video-id d-flex ml-3 align-items-center">
            <label htmlFor="" className="mb-0 mr-1 d-none d-xl-block">User id</label>
            <div className="input-wrap d-flex" style={{width: '320px'}}>
              <DebounceInput
                name="userId"
                className={classNames('', {'is-invalid': validateErrors.userId ? true : false})}
                placeholder="Search by user id (uuid format)"
                debounceTime={3000}
                cancelDebounce={cancelDebounce}
                onChange={handleUserIdChange}
                onSubmit={handleUserIdSubmit}
                innerRef={userIdInput}
                clearInput={clearForm}
                maxLength="36"
                required
                // pattern="/^[0-9A-Fa-f]{8}(?:-[0-9A-Fa-f]{4}){3}-[0-9A-Fa-f]{12}$/"
              />
            </div>
          </div>
          <div className="filter-input d-flex ml-3">
            <div className="d-flex align-items-center">
              <label htmlFor="" className="mb-0 mr-1 d-none d-xl-block">Keywords</label>
              <div className="input-wrap d-flex" style={{width: '220px'}}>
                <DebounceInput
                  name="keywords"
                  className={classNames('w-100', 'd-flex', {'is-invalid': validateErrors.keywords ? true : false})}
                  placeholder="Search by name, email"
                  debounceTime={3000}
                  cancelDebounce={cancelDebounce}
                  onChange={handleInputChange}
                  onSubmit={handleInputSubmit}
                  innerRef={keywordInput}
                  clearInput={clearForm}
                />
              </div>
            </div>
            <Button
              className="ml-3 d-flex"
              size=""
              disabled={loading}
              onClick={(e) => {setCancelDebounce(true); submitFilter(e);}}
            >
              <Icon.Search className="" style={{ width: '15px', height: '15px', marginTop: '3px', marginRight: '2px' }} /> Search
            </Button>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default React.memo(FormFilter);