import { post, get, request } from "../helpers/apiHelper";

async function fetch(parrams) {
  // await delay(1000);
  if (parrams && parrams.keyword) {
    const data = {
      callerId: "",
      keywords: parrams.keyword,
      pageNumber: parrams.pageNumber,
      pageSize: parrams.pageSize,
      // sort: {
      //   dir: parrams.order,
      //   fieldName: parrams.sort
      // }
    };
    const result = await post("surveillance/whitelist/search", data);
    return result;
  }
  const result = await get("surveillance/whitelist", parrams);
  return result;
}

async function create(data) {
  const result = await post(`surveillance/whitelist`, data);
  return result;
}

async function update(userId, data) {
  const result = await request(`surveillance/whitelist/${userId}`, data, "put");
  return result;
}

async function remove(userId) {
  const result = await request(
    `surveillance/whitelist/${userId}`,
    null,
    "delete"
  );
  return result;
}

async function disable(userId) {
  const result = await request(
    `surveillance/whitelist/${userId}/no`,
    null,
    "patch"
  );
  return result;
}

async function enable(userId) {
  const result = await request(
    `surveillance/whitelist/${userId}/yes`,
    null,
    "patch"
  );
  return result;
}

async function getUserTrusted(userId) {
  const result = await get(`surveillance/whitelist/${userId}`);
  return result;
}

export default {
  fetch,
  create,
  update,
  remove,
  disable,
  enable,
  getUserTrusted,
};
