import { takeLatest, call, put } from "redux-saga/effects";

import advertisingService from "../../services/advertisingService";
import { alertTypes } from "../constants";
import {
  featuredVideoAction,
  enableAction,
  disableAction,
  resyncAction,
} from "../reducers/videoFeaturedReducer";
import { sagaPromise } from "../../helpers/saga-promise-helpers";
import { VIDEO_FEATURED_MESSAGES } from "../../config/messages";

function* searchWorker(action) {
  try {
    const { data, error } = yield call(
      advertisingService.search,
      action.payload?.params
    );
    if (error) {
      yield put(featuredVideoAction.fetch.error(error));
    } else {
      yield put(featuredVideoAction.fetch.success({ ...action, data }));
    }
  } catch (err) {
    yield put(featuredVideoAction.fetch.error(err));
  }
}

function* setFeaturedVideoWorker(action) {
  const { data, error } = yield call(
    advertisingService.setFeatured,
    action.payload.data
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      yield put({
        type: alertTypes.ERROR,
        message: error.headers["x-message-info"],
      });
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    yield put({
      type: alertTypes.SUCCESS,
      message: VIDEO_FEATURED_MESSAGES.pinSuccess,
    });
    return data;
  }

  return false;
}

function* updateFeaturedVideoWorker(action) {
  const { data, error } = yield call(
    advertisingService.updateFeatured,
    action.payload.data
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      yield put({
        type: alertTypes.ERROR,
        message: error.headers["x-message-info"],
      });
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    yield put({
      type: alertTypes.SUCCESS,
      message: VIDEO_FEATURED_MESSAGES.updateSuccess,
    });
    return data;
  }

  return false;
}

function* removeFeaturedVideoWorker(action) {
  const { data, error } = yield call(
    advertisingService.removeFeatured,
    action.payload.videoId
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      yield put({
        type: alertTypes.ERROR,
        message: error.headers["x-message-info"],
      });
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    yield put({
      type: alertTypes.SUCCESS,
      message: VIDEO_FEATURED_MESSAGES.unpinSuccess,
    });
    return data;
  }

  return false;
}

function* enableWorker(action) {
  const { data, error } = yield call(
    advertisingService.enable,
    action.payload.videoId
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      yield put({
        type: alertTypes.ERROR,
        message: error.headers["x-message-info"],
      });
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    yield put({
      type: alertTypes.SUCCESS,
      message: VIDEO_FEATURED_MESSAGES.enableSuccess,
    });
    return data;
  }

  return false;
}

function* disableWorker(action) {
  const { data, error } = yield call(
    advertisingService.disable,
    action.payload.videoId
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      yield put({
        type: alertTypes.ERROR,
        message: error.headers["x-message-info"],
      });
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    yield put({
      type: alertTypes.SUCCESS,
      message: VIDEO_FEATURED_MESSAGES.disableSuccess,
    });
    return data;
  }

  return false;
}

function* resyncWorker(action) {
  const { error } = yield call(
    advertisingService.resync
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      yield put({
        type: alertTypes.ERROR,
        message: error.headers["x-message-info"],
      });
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    yield put({
      type: alertTypes.SUCCESS,
      message: VIDEO_FEATURED_MESSAGES.resyncSuccess,
    });
  }
  return true;
}

function* reviewSaga() {
  // yield takeLatest(searchVideoAction.start, searchWorker);

  yield takeLatest(
    featuredVideoAction.fetch.start,
    featuredVideoAction.fetch.waiterActionForSaga(sagaPromise(searchWorker))
  );

  yield takeLatest(
    featuredVideoAction.create.start,
    featuredVideoAction.create.waiterActionForSaga(sagaPromise(setFeaturedVideoWorker))
  );

  yield takeLatest(
    featuredVideoAction.update.start,
    featuredVideoAction.update.waiterActionForSaga(sagaPromise(updateFeaturedVideoWorker))
  );

  yield takeLatest(
    featuredVideoAction.delete.start,
    featuredVideoAction.delete.waiterActionForSaga(sagaPromise(removeFeaturedVideoWorker))
  );

  yield takeLatest(
    enableAction.start,
    enableAction.waiterActionForSaga(sagaPromise(enableWorker))
  );

  yield takeLatest(
    disableAction.start,
    disableAction.waiterActionForSaga(sagaPromise(disableWorker))
  );

  yield takeLatest(
    resyncAction.start,
    resyncAction.waiterActionForSaga(sagaPromise(resyncWorker))
  );

}

export default reviewSaga;
