import { takeLatest, call, put, delay } from "redux-saga/effects";

import { alertTypes } from "../constants";
import { sagaPromise } from "../../helpers/saga-promise-helpers";
import subscriptionServices from "../../services/subscriptionService";
import {
  subscriptionActions,
  searchSubscriptionAction,
  syncWithKillbillAction,
  cancelSubscriptionAction,
  searchMonthlyRevenueActions,
  recalculateMonthlyRevenuesAction,
  transferRevenueAction,
  searchRevenueRecordsAction,
  transferMonthlyRevenuesAction,
  searchFundsTransfersAction,
  searchConnectedAccountsAction,
  refreshConnectedAccountAction,
  removeConnectedAccountAction,
  sendConnectedAccountSetupRequestAction,
  getCatalogsAction,
  submitJsonCatalogAction,

  getPlanOptionTypesAction,
  createPlanOptionTypeAction,
  updatePlanOptionTypeAction,

  getStoragePackagesAction,
  createStoragePackageAction,
  updateStoragePackageAction,

  getProductsAction,
  createProductAction,
  updateProductAction,
  createProductPlanAction,
  updateProductPlanAction,
} from "../reducers/subscriptionReducer";
import { USER_MESSAGES } from "../../config/messages";

function* fetchUsersWorker(action) {
  try {
    const { data, error } = yield call(
      subscriptionServices.searchUserSubscriptions,
      action.payload?.params
    );
    if (error) {
      yield put(subscriptionActions.fetch.error(error));
    } else {
      yield put(subscriptionActions.fetch.success({ ...action, data }));
    }
  } catch (err) {
    yield put(subscriptionActions.fetch.error(err));
  }
}

function* searchUserSubscriptionsWorker(action) {
  yield delay(1000);
  
  const { data, error } = yield call(
    subscriptionServices.searchUserSubscriptions,
    action.payload.params
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    return data;
  }

  return false;
}

function* syncWithKillbillWorker(action) {
  yield delay(1000);
  console.log("Action payload: " + JSON.stringify(action.payload));

  const { data, error } = yield call(
    subscriptionServices.syncWithKillbill,
    action.payload.subscriptionId,
    action.payload.params
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    return data;
  }

  return false;
}

function* cancelUserSubscriptionWorker(action) {
  yield delay(1000);
  console.log("Action payload: " + JSON.stringify(action.payload));
  
  const { data, error } = yield call(
    subscriptionServices.cancelUserSubscription,
    action.payload.subscriptionId
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    return data;
  }

  return false;
}

function* searchMonthlyRevenuesWorker(action) {
  yield delay(1000);
  
  const { data, error } = yield call(
    subscriptionServices.searchMonthlyRevenues,
    action.payload.params
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    return data;
  }

  return false;
}

function* recalculateMonthlyRevenuesWorker(action) {
  yield delay(1000);
  
  const { data, error } = yield call(
    subscriptionServices.aggregateMonthlyRevenues,
    action.payload.yearMonth
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    return data;
  }

  return false;
}

function* transferRevenueWorker(action) {
  yield delay(1000);
  
  const { data, error } = yield call(
    subscriptionServices.transferRevenue,
    action.payload
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    return data;
  }

  return false;
}

function* searchRevenueRecordsWorker(action) {
  yield delay(1000);
  
  const { data, error } = yield call(
    subscriptionServices.searchRevenueRecords,
    action.payload.params
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    return data;
  }

  return false;
}

function* transferMonthlyRevenuesWorker(action) {
  yield delay(1000);
  const { data, error } = yield call(
    subscriptionServices.transferMonthlyRevenues,
    action.payload
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    return data;
  }

  return false;
}

function* searchFundsTransfersWorker(action) {
  yield delay(1000);
  
  const { data, error } = yield call(
    subscriptionServices.searchFundsTransfers,
    action.payload.params
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    return data;
  }

  return false;
}

function* searchConnectedAccountsWorker(action) {
  yield delay(1000);
  
  const { data, error } = yield call(
    subscriptionServices.searchConnectedAccounts,
    action.payload.params
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    return data;
  }

  return false;
}

function* refreshConnectedAccountWorker(action) {
  yield delay(1000);
  
  const { data, error } = yield call(
    subscriptionServices.refreshConnectedAccount,
    action.payload.accountId
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    return data;
  }

  return false;
}

function* removeConnectedAccountWorker(action) {
  yield delay(1000);
  
  const { data, error } = yield call(
    subscriptionServices.removeConnectedAccount,
    action.payload.accountId
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    return data;
  }

  return false;
}

function* getCatalogsActionWorker(action) {
  yield delay(1000);
  
  const { data, error } = yield call(
    subscriptionServices.getCatalogs,
    null
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    return data;
  }

  return false;
}

function* submitJsonCatalogActionWorker(action) {
  yield delay(1000);
  
  const { data, error } = yield call(
    subscriptionServices.submitJsonCatalog,
    action.payload
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    return data;
  }

  return false;
}

function* getPlanOptionTypesActionWorker(action) {
  yield delay(1000);
  
  const { data, error } = yield call(
    subscriptionServices.getPlanOptionTypes,
    null
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    return data;
  }

  return false;
}

function* createPlanOptionTypeActionWorker(action) {
  yield delay(1000);
  
  const { data, error } = yield call(
    subscriptionServices.createPlanOptionType,
    action.payload.params
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    return data;
  }

  return false;
}

function* updatePlanOptionTypeActionWorker(action) {
  yield delay(1000);
  
  const { data, error } = yield call(
    subscriptionServices.updatedPlanOptionType,
    action.payload.params
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    return data;
  }

  return false;
}

function* getProductsActionWorker(action) {
  yield delay(1000);
  
  const { data, error } = yield call(
    subscriptionServices.getProducts,
    null
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    return data;
  }

  return false;
}

function* createProductActionWorker(action) {
  yield delay(1000);
  
  const { data, error } = yield call(
    subscriptionServices.createProduct,
    action.payload.params
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    return data;
  }

  return false;
}

function* updateProductActionWorker(action) {
  yield delay(1000);
  
  const { data, error } = yield call(
    subscriptionServices.updateProduct,
    action.payload.params
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    return data;
  }

  return false;
}

function* createProductPlanActionWorker(action) {
  yield delay(1000);
  
  const { data, error } = yield call(
    subscriptionServices.createProductPlan,
    action.payload.params
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    return data;
  }

  return false;
}

function* updateProductPlanActionWorker(action) {
  yield delay(1000);
  
  const { data, error } = yield call(
    subscriptionServices.updateProductPlan,
    action.payload.params
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    return data;
  }

  return false;
}

function* getStoragePackagesWorker(action) {
  yield delay(1000);
  
  const { data, error } = yield call(
    subscriptionServices.getStoragePackages,
    null
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    return data;
  }

  return false;
}

function* createStoragePackageActionWorker(action) {
  yield delay(1000);
  
  const { data, error } = yield call(
    subscriptionServices.createStoragePackage,
    action.payload.params
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    return data;
  }

  return false;
}

function* updateStoragePackageActionWorker(action) {
  yield delay(1000);
  
  const { data, error } = yield call(
    subscriptionServices.updateStoragePackage,
    action.payload.params
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    return data;
  }

  return false;
}

function* sendConnectedAccountSetupRequestActionWorker(action) {
  yield delay(1000);
  
  const { data, error } = yield call(
    subscriptionServices.sendConnectedAccountSetupRequest,
    action.payload.userId
  );
  if (error) {
    if (error.headers["x-message-info"]) {
      throw new Error(error.headers["x-message-info"]);
    }
  } else {
    return data;
  }

  return false;
}

function* userSaga() {

  yield takeLatest(
    subscriptionActions.fetch.start,
    subscriptionActions.fetch.waiterActionForSaga(sagaPromise(fetchUsersWorker))
  );

  yield takeLatest(
    searchSubscriptionAction.start,
    searchSubscriptionAction.waiterActionForSaga(sagaPromise(searchUserSubscriptionsWorker))
  );

  yield takeLatest(
    syncWithKillbillAction.start,
    syncWithKillbillAction.waiterActionForSaga(sagaPromise(syncWithKillbillWorker))
  );

  yield takeLatest(
    cancelSubscriptionAction.start,
    cancelSubscriptionAction.waiterActionForSaga(sagaPromise(cancelUserSubscriptionWorker))
  );

  yield takeLatest(
    searchMonthlyRevenueActions.start,
    searchMonthlyRevenueActions.waiterActionForSaga(sagaPromise(searchMonthlyRevenuesWorker))
  );

  yield takeLatest(
    transferRevenueAction.start,
    transferRevenueAction.waiterActionForSaga(sagaPromise(transferRevenueWorker))
  );

  yield takeLatest(
    recalculateMonthlyRevenuesAction.start,
    recalculateMonthlyRevenuesAction.waiterActionForSaga(sagaPromise(recalculateMonthlyRevenuesWorker))
  );

  yield takeLatest(
    searchRevenueRecordsAction.start,
    searchRevenueRecordsAction.waiterActionForSaga(sagaPromise(searchRevenueRecordsWorker))
  );

  yield takeLatest(
    transferMonthlyRevenuesAction.start,
    transferMonthlyRevenuesAction.waiterActionForSaga(sagaPromise(transferMonthlyRevenuesWorker))
  );

  yield takeLatest(
    searchFundsTransfersAction.start,
    searchFundsTransfersAction.waiterActionForSaga(sagaPromise(searchFundsTransfersWorker))
  );

  yield takeLatest(
    searchConnectedAccountsAction.start,
    searchConnectedAccountsAction.waiterActionForSaga(sagaPromise(searchConnectedAccountsWorker))
  );

  yield takeLatest(
    refreshConnectedAccountAction.start,
    refreshConnectedAccountAction.waiterActionForSaga(sagaPromise(refreshConnectedAccountWorker))
  );

  yield takeLatest(
    removeConnectedAccountAction.start,
    removeConnectedAccountAction.waiterActionForSaga(sagaPromise(removeConnectedAccountWorker))
  );

  yield takeLatest(
    getCatalogsAction.start,
    getCatalogsAction.waiterActionForSaga(sagaPromise(getCatalogsActionWorker))
  );

  yield takeLatest(
    submitJsonCatalogAction.start,
    submitJsonCatalogAction.waiterActionForSaga(sagaPromise(submitJsonCatalogActionWorker))
  );

  yield takeLatest(
    getPlanOptionTypesAction.start,
    getPlanOptionTypesAction.waiterActionForSaga(sagaPromise(getPlanOptionTypesActionWorker))
  );

  yield takeLatest(
    createPlanOptionTypeAction.start,
    createPlanOptionTypeAction.waiterActionForSaga(sagaPromise(createPlanOptionTypeActionWorker))
  );

  yield takeLatest(
    updatePlanOptionTypeAction.start,
    updatePlanOptionTypeAction.waiterActionForSaga(sagaPromise(updatePlanOptionTypeActionWorker))
  );

  yield takeLatest(
    getProductsAction.start,
    getProductsAction.waiterActionForSaga(sagaPromise(getProductsActionWorker))
  );

  yield takeLatest(
    createProductPlanAction.start,
    createProductPlanAction.waiterActionForSaga(sagaPromise(createProductPlanActionWorker))
  );

  yield takeLatest(
    updateProductPlanAction.start,
    updateProductPlanAction.waiterActionForSaga(sagaPromise(updateProductPlanActionWorker))
  );

  yield takeLatest(
    createProductAction.start,
    createProductAction.waiterActionForSaga(sagaPromise(createProductActionWorker))
  );

  yield takeLatest(
    updateProductAction.start,
    updateProductAction.waiterActionForSaga(sagaPromise(updateProductActionWorker))
  );

  yield takeLatest(
    getStoragePackagesAction.start,
    getStoragePackagesAction.waiterActionForSaga(sagaPromise(getStoragePackagesWorker))
  );

  yield takeLatest(
    createStoragePackageAction.start,
    createStoragePackageAction.waiterActionForSaga(sagaPromise(createStoragePackageActionWorker))
  );

  yield takeLatest(
    updateStoragePackageAction.start,
    updateStoragePackageAction.waiterActionForSaga(sagaPromise(updateStoragePackageActionWorker))
  );

  yield takeLatest(
    sendConnectedAccountSetupRequestAction.start,
    sendConnectedAccountSetupRequestAction.waiterActionForSaga(sagaPromise(sendConnectedAccountSetupRequestActionWorker))
  );
}

export default userSaga;
