import React, { useState } from "react";
import { Row, Col, Button, Label, FormGroup } from "reactstrap";
import * as Icon from "react-feather";
import classNames from "classnames";

import "./AuditLogFilter.scss";
import SearchBoUserInput from "./SearchBoUserInput";
import DRP from "../../components/DateRangePicker";
import { AUDIT_TYPES, AUDIT_TYPE_COPY } from "./constants";

let typeOptions = [];
for (const auditType in AUDIT_TYPES) {
  typeOptions.push({
    label: AUDIT_TYPE_COPY[AUDIT_TYPES[auditType]],
    value: AUDIT_TYPES[auditType],
  });
}

const LogFilter = ({loading, paging, filters: initFilters, mergeFilters}) => {
  const [filters, setFilters] = useState(initFilters);

  const handleDateChange = (start, end) => {
    setFilters({ ...filters, startDate: start, endDate: end});
  }

  const handleUserChange = (option) => {
    setFilters({ ...filters, userName: option ? option.value : ''});
  }

  const handleTypeChange = (e) => {
    if (e.target.checked) {
      const list = [...filters.entityTypes, e.target.value];
      setFilters({ ...filters, entityTypes: list});
    } else {
      const list = filters.entityTypes.filter(item => item !== e.target.value);
      setFilters({ ...filters, entityTypes: list});
    }
  }

  const submitFilter = () => mergeFilters({...filters, reload: true});

  return (
    <div className="form-filter form-filter--auditlog">
      
      <Row className={classNames('video-review-filter')} style={{transition: 'visibility 0.5s, opacity 0.5s linear'}}>
        <Col lg="12" md="12" xs="12" className="mt-1 mb-1">
          <div className="item-filter float-right w-100">
            <div className="d-flex align-items-center">
              <div className="ml-auto">
                <label htmlFor="" className="mb-0" style={{width: '70px', fontWeight: '600'}}>User</label>
                <div className="d-inline-block ml-2 item-filter__control" style={{width: '250px'}}>
                  <SearchBoUserInput
                    onChange={handleUserChange}
                    className="w-100"
                    isClearable={true}
                    />
                </div>
              </div>
              <div className="d-flex align-items-center">
                <label htmlFor="" className="mb-0" style={{width: '50px', fontWeight: '600'}}>Time</label>
                <div className="d-inline-block item-filter__control ml-2">
                  <div className="d-flex position-relative">
                    <DRP onChange={handleDateChange} showApplyButton={false} />
                    {/* <Button
                      className="ml-2"
                      size=""
                      disabled={loading}
                      onClick={(e) => submitFilter(e)}
                      style={{minWidth: '86px'}}
                    >
                      <Icon.Search style={{ width: '15px', height: '15px', marginTop: '-2px' }} /> Search
                    </Button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg="12" className="mt-1 mb-1">
          <div className="item-filter float-right w-100">
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center justify-content-end" style={{ marginLeft: 'auto'}}>
                <label htmlFor="" className="mb-0 mr-2" style={{fontWeight: '600'}}>Entity Types: </label>
                {typeOptions.map(option => (
                  <FormGroup check inline key={`status-item--${option.value}`}>
                    <Label check style={{width: 'auto'}}>
                      <input
                          className="align-middle"
                          name={`types[]`}
                          type="checkbox"
                          defaultChecked={true}
                          value={option.value}
                          onChange={handleTypeChange}
                        /> <span style={{verticalAlign: '-2px'}}>{option.label}</span>
                    </Label>
                  </FormGroup>
                ))}
                <Button
                  size=""
                  disabled={loading}
                  onClick={(e) => {submitFilter(e);}}
                >
                  <Icon.Search className="" style={{ width: '15px', height: '15px', marginTop: '-2px' }} /> Search
                </Button>
                </div>
            </div>
          </div>
        </Col>
      </Row>
      
    </div>
  )
}

export default React.memo(LogFilter);