import React, { useState, useEffect } from "react";
import {
    Container,
    Row,
    Col,
    Button
} from "reactstrap";

import * as Icon from "react-feather";

import { RESOURCE_TYPE_PDF, RESOURCE_TYPES, RESOURCE_SUBTYPES } from "./constants"

import SearchUserInput from "./SearchUserInput";
import Selectbox from '../../components/Selectbox/Selectbox';

const resourceTypeOptions = [];

RESOURCE_TYPES.map(type => {
    resourceTypeOptions.push({ label: type.name, value: type.code, });
});

const resourceSubtypeOptions = [];

RESOURCE_SUBTYPES.map(subtype => {
    resourceSubtypeOptions.push({ label: subtype.name, value: subtype.code, });
});

const ProductFilterForm = ({ loading, resourceTypes, resourceSubtypes, storeOwner, resourceOwner, mergeFilters }) => {

    const [filters, setFilters] = useState({
        resourceTypes: resourceTypes,
        resourceSubtypes: resourceSubtypes,
        storeOwner: storeOwner,
        resourceOwner: resourceOwner,
        reload: false,
    });


    const handleResourceTypesChange = (option) => {
        if (option && option.value) {
            if (option.value === RESOURCE_TYPE_PDF.code)
                setFilters({ ...filters, resourceTypes: option.value });
            else
                setFilters({ ...filters, resourceTypes: option.value, resourceSubtypes: undefined });
        }
        else
            setFilters({ ...filters, resourceTypes: undefined });
    }

    const handleResourceSubtypesChange = (option) => {
        if (filters.resourceTypes === RESOURCE_TYPE_PDF.code) {
            if (option && option.value)
                setFilters({ ...filters, resourceSubtypes: option.value });
            else
                setFilters({ ...filters, resourceSubtypes: undefined });
        } else {
            setFilters({ ...filters, resourceSubtypes: undefined });
        }
    }

    const handleResourceOwnerChange = (option) => {
        setFilters({ ...filters, resourceOwner: option ? option.value : undefined });
    }

    const handleStoreOwnerChange = (option) => {
        setFilters({ ...filters, storeOwner: option ? option.value : undefined });
    }

    const handleFilterFormSubmit = () => {
        mergeFilters && mergeFilters({ ...filters, reload: true });
    }

    return (
        <Container fluid className="p-0">
            <Row>
                <h3>Filters</h3>
            </Row>
            <Row className="align-items-end">
                <Col lg="2">
                    <label htmlFor="resourceTypes" className="mb-1">Types</label>
                    <Selectbox
                        name="resourceTypes"
                        placeholder="Resource types"
                        options={resourceTypeOptions}
                        onChange={handleResourceTypesChange}
                    />
                </Col>
                <Col lg="2">
                    <label htmlFor="resourceSubtypes" className="mb-1">Subtypes</label>
                    <Selectbox
                        name="resourceSubtypes"
                        placeholder="subtypes"
                        options={resourceSubtypeOptions}
                        value={resourceSubtypeOptions.filter(option => option.value === filters.resourceSubtypes)}
                        isDisabled={filters.resourceTypes !== RESOURCE_TYPE_PDF.code}
                        onChange={handleResourceSubtypesChange}
                    />
                </Col>
                <Col lg="3">
                    <label htmlFor="storeOwner" className="mb-1">Store owner</label>
                    <SearchUserInput
                        onChange={handleStoreOwnerChange}
                        className="w-100"
                        isClearable={true}
                        name="storeOwner"
                    />
                </Col>
                <Col lg="3">
                    <label htmlFor="resourceOwner" className="mb-1">Resource owner</label>
                    <SearchUserInput
                        onChange={handleResourceOwnerChange}
                        className="w-100"
                        isClearable={true}
                        name="resourceOwner"
                    />
                </Col>
                <Col lg="2" style={{}}>
                    <Button
                        className=""
                        size=""
                        disabled={loading}
                        onClick={handleFilterFormSubmit}
                        style={{ minWidth: '86px' }}
                    >
                        <Icon.Search style={{ width: '15px', height: '15px', marginTop: '-2px' }} /> Search
                    </Button>
                </Col>
            </Row>
        </Container>
    );
};

export default ProductFilterForm;
