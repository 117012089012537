import React, { useEffect, useState, useCallback } from "react";
import { Button, Input, InputGroup, InputGroupAddon } from "reactstrap";
import PropTypes from "prop-types";
import * as Icon from "react-feather";
import _ from "lodash";

const propTypes = {
  value: PropTypes.string,
  placeHolder: PropTypes.string,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
};

const InputSearch = props => {
  const [keyword, setKeyword] = useState(props.value);

  const debounceSearch = useCallback(
    _.debounce(() => {
      if (props.onSubmit) {
        props.onSubmit(keyword);
      }
    }, 3000),
    [keyword]
  );

  const handleSubmit = e => {
    e.preventDefault();
    if (props.onSubmit) props.onSubmit(keyword);
  };

  const handleChange = e => {
    setKeyword(e.target.value);
  };

  const handleKeyUp = e => {
    if (e.keyCode === 13) {
      handleSubmit(e);
    }
  };

  useEffect(() => {
    debounceSearch();

    return debounceSearch.cancel;
  }, [keyword]);

  InputSearch.propTypes = propTypes;

  return (
    <InputGroup
      className={
        props.className ? `search-input ${props.className}` : "search-input "
      }
    >
      <Input
        placeholder={
          props.placeHolder ? `${props.placeHolder}` : "Search for..."
        }
        value={keyword}
        onChange={handleChange}
        onKeyUp={e => handleKeyUp(e)}
      />
      <InputGroupAddon addonType="append" color="">
        <Button
          className="btn-search"
          color="light"
          disabled={props.disabled}
          onClick={e => handleSubmit(e)}
        >
          <Icon.Search height="18" />
        </Button>
      </InputGroupAddon>
    </InputGroup>
  );
};

export default InputSearch;
