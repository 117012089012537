import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    Card,
    CardHeader,
    CardBody,
    Container,
    Row,
    Col,
    Spinner,
    Button
} from "reactstrap";
import classNames from "classnames";
import DataTable from "../../components/DataTable";
import PageSizeSelector from "../../components/base/PageSizeSelector";
import Paging from "../../components/base/Paging";
import {
    searchMailboxesAction,
    exportMailboxesAction,
    sendEmailAction,
} from "../../redux/reducers/userReducer";
import { startActionWithPromise } from "../../helpers/saga-promise-helpers";
import { getGridColumns } from "./gridColumns";
import useMergeState from "../../hooks/mergeState";
import config from "../../config/config";
import styled from "styled-components";
import * as Icon from "react-feather";

import MailFilter from "./MailFilter";
import MailComposer from "./MailComposer";

const Overlay = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.18);
  z-index: 1;
`;

const MailMessaging = () => {
    const dispatch = useDispatch();

    const [data, setData] = useState([]);
    const [fetching, setFetching] = useState(false);
    const [filters, setFilters] = useMergeState({
        pageSize: config.DEFAULT_PAGESIZE,
        pageNumber: 1,
        totalPages: 0,
        total: 0,
        sort: "firstName",
        order: "asc",
        reload: true,
    });

    const [modalStates, setModalStates] = useState({
        emailModal: false,
    });

    const collectParams = () => {
        const params = {
            pageSize: filters.pageSize,
            pageNumber: filters.pageNumber - 1,
            activationStatus: "ACTIVE",
            userStatus: "ENABLED",
        };

        if (filters.activationStatus)
            params.activationStatus = filters.activationStatus;

        if (filters.userStatus)
            params.userStatus = filters.userStatus;

        if (filters.userId)
            params.userId = filters.userId;

        if (filters.keywords)
            params.text = filters.keywords;

        if (filters.sort && filters.order) {
            params.sort = {
                fieldName: filters.sort === "fullname" ? "firstName" : filters.sort,
                dir: filters.order.toUpperCase(),
            };
        }

        if (filters.countries?.length > 0) {
            params.countries = filters.countries.map((c) => c.value);
        }

        return params;
    };

    const fetchData = useCallback((params) => {
        (async () => {
            try {
                setFetching(true);
                await startActionWithPromise(
                    searchMailboxesAction,
                    {
                        params,
                        successCallback: (response = {}) => {
                            const { content = [], pageNumber, pageSize, total } = response;
                            const data = content.map((item) => ({
                                ...item,
                                fullname: item.firstName + " " + item.lastName,
                            }));

                            setData(data);
                            setFilters({
                                pageNumber: pageNumber + 1,
                                pageSize,
                                totalPages: Math.ceil(total / pageSize) || 0,
                                total,
                            });
                            setFetching(false);
                        },
                        failedCallback: () => {
                            setFetching(false);
                        },
                    },
                    dispatch
                );
            } catch { }
        })();
    }, []);

    useEffect(() => {
        if (filters.reload) {
            const params = collectParams();
            fetchData(params);
            setFilters({ reload: false });
        }
    }, [filters.reload]);

    const handleSubmitFilter = useCallback((filtersValues) => {
        setFilters({ pageNumber: 1, ...filtersValues, reload: true });
    }, []);

    const exportMailboxes = (params) => {
        (async () => {
            try {
                setFetching(true);
                await startActionWithPromise(
                    exportMailboxesAction,
                    {
                        params,
                        successCallback: (response = {}) => {
                            const href = URL.createObjectURL(response);
                            const link = document.createElement("a");
                            try {
                                link.href = href;
                                link.setAttribute(
                                    "download",
                                    "Contacts-" + Math.random().toString(36).substr(2, 5) + ".csv"
                                );
                                document.body.appendChild(link);
                                link.click();
                            } finally {
                                // clean up "a" element & remove ObjectURL
                                document.body.removeChild(link);
                                URL.revokeObjectURL(href);
                                setFetching(false);
                            }
                        },
                        failedCallback: () => {
                            setFetching(false);
                        },
                    },
                    dispatch
                );
            } catch { }
        })();
    };

    const handleExportCSV = (e) => {
        const params = collectParams();
        params.pageSize = 500; // adjust pagesize to improve performance
        exportMailboxes(params);
    };

    const handleNewEmail = (e) => {
        setModalStates({ ...modalStates, emailModal: true });
    };

    const handleSendEmail = (emailMessage) => {
        (async () => {
            try {
                setFetching(true);
                await startActionWithPromise(
                    sendEmailAction,
                    {
                        emailMessage,
                        successCallback: (response = {}) => {
                            console.log(response);
                            setFetching(false);
                        },
                        failedCallback: () => {
                            setFetching(false);
                        },
                    },
                    dispatch
                );
            } catch { }
        })();
    };

    const toggleModal = (modelFlag) => {
        setModalStates({ ...modalStates, [modelFlag]: !modalStates[modelFlag] });
    };

    const handleColumnSort = useCallback(
        (field, order) => {
            if (filters.sort !== field || filters.order !== order)
                setFilters({
                    ...filters,
                    sort: field,
                    order: order,
                    reload: true,
                });
        },
        [filters]
    );

    const onSizeChange = (size) => {
        setFilters({ ...filters, pageSize: size, pageNumber: 1, reload: true });
    };

    const onPageChange = (p) => {
        setFilters({ ...filters, pageNumber: p, reload: true });
    };

    const gridColumns = getGridColumns(handleColumnSort);

    return (
        <Container fluid className="p-0 h-100 d-flex flex-column">
            <Row>
                <Col lg="4"><h1 className="page-title">Users emails</h1></Col>
                <Col></Col>
                <Col lg="4" >
                    <div class="d-flex align-items-center justify-content-end flex-wrap w-100 mr-4">
                        <Button
                            className="mr-3 mb-2 d-flex"
                            color="info"
                            size=""
                            disabled={fetching}
                            onClick={(e) => {
                                handleNewEmail(e);
                            }}
                        >
                            Send new emails
                        </Button>
                        <Button
                            className="ml-3 mb-2 d-flex"
                            color="info"
                            size=""
                            disabled={fetching}
                            onClick={(e) => {
                                handleExportCSV(e)
                            }}
                        >
                            <Icon.Download
                                className=""
                                style={{
                                    width: "15px",
                                    height: "15px",
                                    marginTop: "3px",
                                    marginRight: "2px",
                                }}
                            />
                            Download
                        </Button>
                    </div>
                </Col>
            </Row>

            <Row className="h-100 overflow-auto">
                <Col lg="12" className="h-100 d-flex">
                    <Card className={classNames("w-100 mb-0 datatable-wrap")}>
                        <CardHeader>
                            <MailFilter
                                onSubmitFilter={handleSubmitFilter}
                                onExportCSV={handleExportCSV}
                                loading={fetching}
                            />
                        </CardHeader>
                        <CardBody className="py-0 data-list initial-scrollbar position-relative">
                            <Row className="h-100">
                                {fetching && (
                                    <Overlay>
                                        <Spinner />
                                    </Overlay>
                                )}
                                <Col className="h-100" lg="12" md="12">
                                    <DataTable
                                        keyField="userId"
                                        data={data}
                                        columns={gridColumns}
                                        sort={filters.sort}
                                        order={filters.order}
                                        hideSelectColumn={true}
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                        <Row className="my-3 px-4">
                            <Col lg="2" md="2" className="d-flex">
                                <PageSizeSelector
                                    size={filters.pageSize}
                                    onChange={onSizeChange}
                                />
                            </Col>
                            <Col lg="4" md="4" className="d-flex justify-content-center">
                                {filters.total && <span><b>{filters.total}</b> contact(s) found.</span>}
                            </Col>
                            <Col lg="6" md="6" className="d-flex justify-content-end">
                                <Paging
                                    totalPages={filters.totalPages}
                                    current={filters.pageNumber}
                                    show={5}
                                    onSelect={onPageChange}
                                />
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            {modalStates.emailModal && (
                <MailComposer
                    modal={modalStates.emailModal}
                    onToggle={toggleModal}
                    onSubmitEmailMessage={handleSendEmail}
                />
            )}
        </Container>
    );
};

export default MailMessaging;
