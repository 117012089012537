import { post, get, request } from "../helpers/apiHelper";

async function newUsers(dateRange) {
  return await post(`reports/users`, dateRange);
}

async function purchases(dateRange) {
  return await post(`reports/purchases`, dateRange);
}

async function revenues(params) {
  return await post(`reports/revenues`, params);
}

async function totalRevenues(params) {
  return await post(`reports/revenues/total`, params);
}

async function systemStorage() {
  return await get(`reports/storages/system`);
}

async function topPurchasedVideos(params) {
  return await post(`reports/purchases/video`, params);
}

async function totalUsers() {
  return await get(`reports/users`);
}

async function topSellers(params) {
  return await post(`reports/seller`, params);
}

async function topViewedVideos(params) {
  return await post(`reports/video`, params);
}

async function usersStorage(params) {
  return await post(`reports/storages/users`, params);
}

async function getUserOrphanedVideos(params) {
  return await get(`videos/orphan`, params);
}

async function getUserOrphanedVideoParts(params) {
  return await get(`videos/orphanVideosParts`, params);
}

async function deleteUserOrphanedVideos(userId) {
  return await request(`videos/orphan?userId=${userId}`, null, "delete");
}

async function deleteUserOrphanedVideoParts(userId) {
  return await request(`videos/orphanVideosParts?userId=${userId}`, null, "delete");
}

async function fetchVideoStatistics(params) {
  return await post(`reports/statistics`, params);
}

async function fetchVideoStatsAgg(params) {
  return await post(`reports/statsAgg`, params);
}

async function recalculateUserStorage(userId) {
  return await request(`videos/recalculateUserStorage/${userId}`, null, "put");
}

async function getHourlyActiveSessions(params) {
  return await request(`reports/sessionState`, params, "post");
}

export default {
  newUsers,  
  purchases,  
  revenues,
  totalRevenues,
  systemStorage,
  topPurchasedVideos,
  totalUsers,
  topSellers,
  topViewedVideos,
  usersStorage,
  getUserOrphanedVideos,
  getUserOrphanedVideoParts,
  deleteUserOrphanedVideos,
  deleteUserOrphanedVideoParts,
  fetchVideoStatistics,
  fetchVideoStatsAgg,
  recalculateUserStorage,
  getHourlyActiveSessions,
};
