import { get, request } from "../helpers/apiHelper";

async function retranscodeVideo(videoId) {
  return await request(`videos/transcoding/${videoId}`, null, "put");
}

async function getVideoInfo(videoId) {
  return await get(`videos/${videoId}`);
}

async function withdrawVideo(videoId, reason) {
  return await request(`videos/unpublish/${videoId}`, {reason: reason}, "put");
}

export default {
  retranscodeVideo,
  getVideoInfo,
  withdrawVideo,
};
