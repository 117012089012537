export const SCREENS = {
  publishingRequest: 'PUBLISHING_REQUEST',
  conigtiveVideo: 'CONIGTIVE_VDIEO',
  mmUser: 'MM_USER',
  trustedPublisher: 'TRUSTED_PUBLISHER',
  boUser: 'BO_USER',
  permission: 'PERMISSION',
  role: 'ROLES',
  roleGroup: 'ROLE_GROUP',
  setting: 'SETTING',
}

export const SCREEN_COPY = {
  [SCREENS.publishingRequest]: 'Publishing request',
  [SCREENS.conigtiveVideo]: 'Conigtive video',
  [SCREENS.mmUser]: 'MM Users',
  [SCREENS.trustedPublisher]: 'Trusted publisher',
  [SCREENS.boUser]: 'BO Users',
  [SCREENS.permission]: 'Permissions',
  [SCREENS.roleGroup]: 'Role groups',
  [SCREENS.role]: 'Roles',
  [SCREENS.setting]: 'Settings',
}

export const AUDIT_TYPES = {
  userProfile: 'USER_PROFILE',
  // trustedPublisher: 'TRUSTED_PUBLISHER',
  // cognitiveVideo: 'COGNITIVE_VIDEO',
  publishingRequest: 'PUBLISHING_REQUEST',
  setting: 'SETTING',
  // video: 'VIDEO',
}

export const AUDIT_TYPE_COPY = {
  [AUDIT_TYPES.userProfile]: 'User profile',
  [AUDIT_TYPES.trustedPublisher]: 'Trusted publisher',
  [AUDIT_TYPES.cognitiveVideo]: 'Cognitive video',
  [AUDIT_TYPES.publishingRequest]: 'Publishing request',
  [AUDIT_TYPES.setting]: 'Setting',
}