import React, { useState, useEffect, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import BootstrapTable from "react-bootstrap-table-next";
import * as Icon from "react-feather";
import _ from "lodash";
import PropTypes from "prop-types";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import { Tooltip } from 'reactstrap';
import { nanoid } from 'nanoid';

const TooltipIcon = ({children, tooltipId, row, action, handleClick}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <React.Fragment>
      <a
        className={`d-inline-block p-1 ${action.classes||''}`}
        href="#"
        id={'tooltip_' + tooltipId}
        onClick={e => handleClick(action, row, e)}
      >
        {children}
      </a>
      <Tooltip placement="top" isOpen={tooltipOpen} target={'tooltip_' + tooltipId} toggle={toggle} trigger="hover">
        {action.name}
      </Tooltip>
    </React.Fragment>
  );
}

export const rowActionTypes = {
  EDIT: "ROW_ACTION_EDIT",
  VIEW: "ROW_ACTION_VIEW",
  DELETE: "ROW_ACTION_DELETE",
  APPROVE: "ROW_ACTION_APPROVE",
  REJECT: "ROW_ACTION_REJECT",
  ENABLE: "ROW_ACTION_ENABLE",
  DISABLE: "ROW_ACTION_DISABLE",
  LOGOUT: "ROW_ACTION_LOGOUT",
  RESET_PW: "ROW_ACTION_RESET_PW",
  CHANGE_PW: "ROW_ACTION_CHANGE_PW",
  SET_ROLES: "ROW_ACTION_SET_ROLES",
  REVOKE_ALL_ROLES: "ROW_ACTION_REVOKE_ALL_ROLES",
  STAR: "ROW_ACTION_STAR",
  USER_ADD: "ROW_ACTION_USER_ADD",
  RESEND_EMAIL: "ROW_ACTION_RESEND_EMAIL",
  VALIDATE_VIDEOS: "ROW_ACTION_VALIDATE_VIDEOS",
  CLEANUP_ORPHANED_VIDEO_PARTS: "ROW_ACTION_CLEANUP_ORPHANED_VIDEO_PARTS",
  RECALCULATE_STORAGE: "ROW_ACTION_RECALCULATE_STORAGE"
};

const ActionButtonIcons = {
  [rowActionTypes.EDIT]: <Icon.Edit style={{ width: "14px" }} />,
  [rowActionTypes.VIEW]: <Icon.Eye style={{ width: "14px" }} />,
  [rowActionTypes.APPROVE]: <Icon.CheckCircle style={{ width: "14px" }} />,
  [rowActionTypes.REJECT]: <Icon.XCircle style={{ width: "14px" }} />,
  [rowActionTypes.DELETE]: <Icon.Trash2 style={{ width: "14px" }} />,
  [rowActionTypes.LOGOUT]: <Icon.LogOut style={{ width: "14px" }} />,
  [rowActionTypes.CHANGE_PW]: <i className="fuvi-icon fuvi-change-user-password-ro" />,
  [rowActionTypes.RESET_PW]: <i className="fuvi-icon fuvi-change-user-password" />,
  [rowActionTypes.SET_ROLES]: <i className="fuvi-icon fuvi-user-role" />,
  [rowActionTypes.REVOKE_ALL_ROLES]: <i className="fuvi-icon fuvi-remove-user-role" />,
  [rowActionTypes.STAR]: <Icon.Star style={{ width: "14px" }} />,
  [rowActionTypes.USER_ADD]: <Icon.UserPlus style={{ width: "14px" }} />,
  [rowActionTypes.ENABLE]: [
    <Icon.CheckCircle className="text-success" style={{ width: "14px" }} />,
    false
  ],
  [rowActionTypes.DISABLE]: [
    false,
    <Icon.Slash className="text-muted" style={{ width: "14px" }} />
  ],
  [rowActionTypes.RESEND_EMAIL]: <Icon.Mail className="text-muted" style={{ width: "14px" }} />,
  [rowActionTypes.VALIDATE_VIDEOS]: <Icon.CheckCircle style={{ width: "14px" }} />,
  [rowActionTypes.CLEANUP_ORPHANED_VIDEO_PARTS]: <Icon.XCircle style={{ width: "14px" }} />,
  [rowActionTypes.RECALCULATE_STORAGE]: <Icon.RefreshCw style={{ width: "14px" }} />
}

const DataTable = props => {
  const table = useRef(null);
  const [selected, setSelected] = useState([]);

  const keyField = props.keyField || "id";
  let items = props.data || [];
  const columns = props.columns || [];
  const { single, hideSelectColumn, hideSelectAll, onActionButton } = props;
  const actionColWidth =
    props.actions && props.actions.length > 3
      ? `${props.actions.length * 30}px`
      : "130px";
  const actionCol = columns.find(x => x.text === "Actions");  
  if (props.actions && props.actions.length > 0 && !actionCol) {
    const actionColumn = {
      text: "Actions",
      sort: false,
      isDummyField: true,
      dataField: "action_col",
      formatter: (cell, row, rowIndex, formatExtraData) => (
        <div>
          {formatExtraData?.actions?.map((action) =>
            renderActionButton(action, row, onActionButton)
          )}
        </div>
      ),
      formatExtraData: {
        actions: props.actions,
        onActionEdit: props.onActionEdit,
        onActionDelete: props.onActionDelete,
      },
      classes: "col-action",
      headerStyle: () => {
        return { width: actionColWidth };
      },
    };

    columns.push(actionColumn);

    items = items.map(item => ({
      ...item,
      action_col: uuidv4(),
    }));
  }

  const renderActionButton = (action, row, onActionButton) => {
    
    if(onActionButton && !onActionButton(action,row))
       return;

    let buttonText = "";

    if (ActionButtonIcons[action.type]) {
      if (action.toggleField && ActionButtonIcons[action.type].length > 1) {
        buttonText = row[action.toggleField] ? ActionButtonIcons[action.type][1] : ActionButtonIcons[action.type][0];
      } else {
        buttonText = action.type === rowActionTypes.RESEND_EMAIL ? (!row.active && row.enabled ? ActionButtonIcons[action.type] : false) : ActionButtonIcons[action.type];
      }
    } else {
      buttonText = <span>{action.name}</span>;
    }

    return (
      buttonText && (
        <TooltipIcon
          key={`${nanoid()}-key`}
          handleClick={handleActionClick}
          tooltipId={nanoid()}
          row={row}
          action={action}
        >
          {buttonText}
        </TooltipIcon>
      )
    );
  };

  const selectRow = {
    hideSelectColumn: hideSelectColumn,
    hideSelectAll: hideSelectAll,
    mode: single ? "radio" : "checkbox",
    clickToSelect: false,
    bgColor: "#f8f9fa",
    nonSelectable: props.nonSelects ? props.nonSelects : [],
    onSelect: (row, isSelect) => {
      updateSelected(row, isSelect);
    },
    onSelectAll: (isSelect, rows) => {
      updateSelectedAll(rows, isSelect);
    },
  };

  const handleActionClick = (action, row, e) => {
    e.preventDefault();
    if (props.onActionClick) props.onActionClick(action, row);
  };

  const updateSelected = (row, isSelect) => {
    let selectedClone = single ? [] : _.cloneDeep(selected);
    const f = selectedClone.find(x => x[keyField] === row[keyField]);
    if (f && !isSelect) {
      selectedClone = selectedClone.filter(x => x[keyField] !== f[keyField]);
    } else if (!f && isSelect) {
      selectedClone.push(row);
    }

    setSelected(selectedClone);
  };

  const updateSelectedAll = (rows, isSelect) => {
    let selectedClone = _.cloneDeep(selected);
    _.forEach(rows, function (row) {
      const f = selectedClone.find(x => x[keyField] === row[keyField]);
      if (f && !isSelect) {
        selectedClone = selectedClone.filter(x => x[keyField] !== f[keyField]);
      } else if (!f && isSelect) {
        selectedClone.push(row);
      }
    });

    setSelected(selectedClone);
  };

  const fireSelectedChangeEvent = () => {
    if (props.onSelectedChange) props.onSelectedChange(selected);
  };

  useEffect(() => {
    fireSelectedChangeEvent();
  }, [fireSelectedChangeEvent, selected]);

  return (
    <SimpleBar className="custom-scrollbar table-striped" autoHide={false}>
      <BootstrapTable
        bootstrap4
        bordered={false}
        keyField={keyField}
        data={items}
        columns={columns}
        selectRow={selectRow}
        ref={props.tableRef === undefined ? table : props.tableRef}
        sort={
          props.sort
            ? {
                dataField: props.sort,
                order: props.order ? props.order : "asc",
              }
            : {}
        }
      />
    </SimpleBar>
  );
};

DataTable.propTypes = {
  keyField: PropTypes.string.isRequired,
  data: PropTypes.array,
  nonSelects: PropTypes.array,
  columns: PropTypes.array,
  actions: PropTypes.array,
  onActionClick: PropTypes.func,
  onSelectedChange: PropTypes.func,
  onActionButton: PropTypes.func,
  sort: PropTypes.string,
  order: PropTypes.string,
  single: PropTypes.bool,
  hideSelectColumn: PropTypes.bool,
  hideSelectAll: PropTypes.bool,
  tableRef: PropTypes.object,
};

DataTable.defaultProps = {
  keyField: "id",
  data: [],
  nonSelects: [],
  columns: [],
  actions: [],
  onActionButton: (action,row) => true,
  onActionClick: () => {},
  onSelectedChange: () => {},  
  sort: "",
  order: "",
  single: false,
  hideSelectColumn: false,
  hideSelectAll: false,
  tableRef: undefined,
};

export default DataTable;
